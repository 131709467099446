class Config {
  private configuration: IClientConfiguration | null = null;

  public async telemetry(): Promise<IClientTelemetryConfiguration> {
    const configuration = await this.fetchConfiguration();

    return configuration.telemetry;
  }
  public async planViewerApiClient(): Promise<IClientPlanViewerConfiguration> {
    const configuration = await this.fetchConfiguration();

    return configuration.planViewerApiClient;
  }

  private async fetchConfiguration(): Promise<IClientConfiguration> {
    if (this.configuration) {
      return this.configuration;
    }

    const response = await fetch("/api/config", {
      cache: "no-cache",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const configuration = (this.configuration = (await response.json()) as IClientConfiguration);
    return configuration;
  }
}

interface IClientConfiguration {
  telemetry: IClientTelemetryConfiguration;
  planViewerApiClient: IClientPlanViewerConfiguration;
}

export interface IClientTelemetryConfiguration {
  key: string;
  sendTelemetry: boolean;
}

interface IClientPlanViewerConfiguration {
  baseApiUri: string;
}

export default new Config();
