import { useRouteMatch } from "react-router-dom";
import { ITopologyItem, TopologyObjectType, useTopology } from "../../components/TopologyContext";

/**
 * Retrieves the currently selected target from the URL parameters.
 * Returns null if no target is found.
 */
export function useCurrentTarget(): ITopologyItem | null {
  const topology = useTopology();
  const instanceMatch = useRouteMatch<{ instanceId: string }>("*/instances/:instanceId");
  const targetMatch = useRouteMatch<{ targetId: string }>("*/targets/:targetId");

  if (instanceMatch) {
    // Classic routing structure for instance
    const instanceId = Number(instanceMatch.params.instanceId);
    return topology.findByItemId(instanceId, TopologyObjectType.eventSourceConnection) ?? null;
  } else if (targetMatch) {
    if (targetMatch.params.targetId.match(/^[0-9]+$/)) {
      // Classic routing structure for device
      const targetId = Number(targetMatch.params.targetId);
      return topology.findByItemId(targetId, TopologyObjectType.device) ?? null;
    } else {
      // New routing structure for target (device or event source connection)
      return topology.findByObjectId(targetMatch.params.targetId) ?? null;
    }
  } else {
    return null;
  }
}
