import { Theme, useTheme } from "@material-ui/core/styles";
import { DateObjectUnits, DateTime, DurationObject } from "luxon";
import { useCallback, useMemo } from "react";
import {
  IProcedureQueryHistoryChartPoints,
  IQueryHistoryCriteria,
  IQueryHistoryPoints,
  ITraceDataQueryHistoryChartDataPoints,
} from "../../../../../api/models/QueryHistory";
import TopSqlService from "../../../../../api/TopSqlService";
import { useTopSqlContext } from "../../../../../contexts/topSqlContext";
import { AsyncState, useAsync } from "../../../../../hooks/Async";
import { DataDisplay, Grouping, IQueryHistoryOptions, Metric, Mode } from "../types";

/**
 * Builds and returns an array of categories (x-axis) based on the specified date range and grouping.
 * Returns null when no grouping is specified.
 * @param dateRange
 * @param grouping
 */
function buildCategories(dateRange: { from: Date; to: Date }, grouping: Grouping): Date[] | null {
  let step: DurationObject;
  switch (grouping) {
    case Grouping.day:
      step = { days: 1 };
      break;
    case Grouping.hour:
      step = { hours: 1 };
      break;
    case Grouping.week:
      step = { weeks: 1 };
      break;
    default:
      return null;
  }

  const categories: Date[] = [];
  let date = dateRange.from;
  while (date <= dateRange.to) {
    categories.push(date);
    date = DateTime.fromJSDate(date).plus(step).toJSDate();
  }

  return categories;
}

function buildProcedureCategories(
  normalizedPoints: readonly IProcedureQueryHistoryChartPoints[],
  grouping: Grouping,
): Date[] | null {
  if (normalizedPoints.length === 0) {
    return null;
  }

  const dates = normalizedPoints.map((d) => DateTime.fromJSDate(d.startTimeUtc));
  const range = {
    from: DateTime.min(...dates).toJSDate(),
    to: DateTime.max(...dates).toJSDate(),
  };
  return buildCategories(range, grouping);
}

function buildTraceCategories(
  normalizedPoints: readonly ITraceDataQueryHistoryChartDataPoints[],
  grouping: Grouping,
): Date[] | null {
  if (normalizedPoints.length === 0) {
    return null;
  }

  const dates = normalizedPoints.map((d) => DateTime.fromJSDate(d.normalizedStartTime));
  const range = {
    from: DateTime.min(...dates).toJSDate(),
    to: DateTime.max(...dates).toJSDate(),
  };
  return buildCategories(range, grouping);
}

function getMetricProcedureField(metric: Metric): "workerTimeDelta" | "elapsedTimeDelta" | "logicalReadsDelta" {
  switch (metric) {
    case Metric.cpu:
      return "workerTimeDelta";
    case Metric.duration:
      return "elapsedTimeDelta";
    case Metric.io:
      return "logicalReadsDelta";
  }
}

function getMetricTraceField(metric: Metric): "cpu" | "duration" | "reads" {
  switch (metric) {
    case Metric.cpu:
      return "cpu";
    case Metric.duration:
      return "duration";
    case Metric.io:
      return "reads";
  }
}

/**
 * Groups a set of normalized points by their plan number and normalized timestamp.
 * Fills in null values for missing points to align all groups with categories if provided.
 * @param normalizedPoints Array of points with already normalized timestamps.
 * @param categories Array of categories. If null, points are only grouped by plan number and not timestamp.
 */
function groupProcedureResults(
  normalizedPoints: readonly IProcedureQueryHistoryChartPoints[],
  categories: Date[] | null,
): Map<number, Array<IProcedureQueryHistoryChartPoints | null>> {
  if (categories) {
    const cats = categories.map((c) => c.toISOString());
    return normalizedPoints.reduce((prev, curr) => {
      let dates = prev.get(curr.planNumber);
      if (!dates) {
        dates = categories.map(() => null);
        prev.set(curr.planNumber, dates);
      }
      const index = cats.indexOf(curr.startTimeUtc.toISOString());
      const currentDay = dates[index];
      if (currentDay) {
        dates[index] = {
          ...currentDay,
          elapsedTimeDelta: currentDay.elapsedTimeDelta + curr.elapsedTimeDelta,
          endTimeUtc: DateTime.max(
            DateTime.fromJSDate(currentDay.endTimeUtc),
            DateTime.fromJSDate(curr.endTimeUtc),
          ).toJSDate(),
          executionCountDelta: currentDay.executionCountDelta + curr.executionCountDelta,
          logicalReadsDelta: currentDay.logicalReadsDelta + curr.logicalReadsDelta,
          logicalWritesDelta: currentDay.logicalWritesDelta + curr.logicalWritesDelta,
          physicalReadsDelta: (currentDay.physicalReadsDelta ?? 0) + (curr.physicalReadsDelta ?? 0),
          startTimeUtc: DateTime.min(
            DateTime.fromJSDate(currentDay.startTimeUtc),
            DateTime.fromJSDate(curr.startTimeUtc),
          ).toJSDate(),
          workerTimeDelta: currentDay.workerTimeDelta + curr.workerTimeDelta,
        };
      } else if (index >= 0) {
        dates[index] = curr;
      }
      return prev;
    }, new Map<number, Array<IProcedureQueryHistoryChartPoints | null>>());
  } else {
    return normalizedPoints.reduce((prev, curr) => {
      const points = prev.get(curr.planNumber);
      if (!points) {
        prev.set(curr.planNumber, [curr]);
      } else {
        points.push(curr);
      }
      return prev;
    }, new Map<number, IProcedureQueryHistoryChartPoints[]>());
  }
}

/**
 * Groups a set of normalized points by their plan number and normalized timestamp.
 * Fills in null values for missing points to align all groups with categories if provided.
 * @param normalizedPoints Array of points with already normalized timestamps.
 * @param categories Array of categories. If null, points are only grouped by plan number and not timestamp.
 */
function groupTraceResults(
  normalizedPoints: readonly ITraceDataQueryHistoryChartDataPoints[],
  categories: Date[] | null,
): Map<number, Array<ITraceDataQueryHistoryChartDataPoints | null>> {
  if (categories) {
    const cats = categories.map((c) => c.toISOString());
    return normalizedPoints.reduce((prev, curr) => {
      let dates = prev.get(curr.planNumber);
      if (!dates) {
        dates = categories.map(() => null);
        prev.set(curr.planNumber, dates);
      }

      const index = cats.indexOf(curr.normalizedStartTime.toISOString());
      const currentDay = dates[index];
      if (currentDay) {
        dates[index] = {
          ...currentDay,
          cpu: currentDay.cpu + curr.cpu,
          duration: currentDay.duration + curr.duration,
          normalizedEndTime: DateTime.max(
            DateTime.fromJSDate(currentDay.normalizedEndTime),
            DateTime.fromJSDate(curr.normalizedEndTime),
          ).toJSDate(),
          normalizedStartTime: DateTime.min(
            DateTime.fromJSDate(currentDay.normalizedStartTime),
            DateTime.fromJSDate(curr.normalizedStartTime),
          ).toJSDate(),
          reads: currentDay.reads + curr.reads,
          recCount: (currentDay.recCount ?? 1) + (curr.recCount ?? 1),
          writes: currentDay.writes + curr.writes,
        };
      } else {
        dates[index] = curr;
      }
      return prev;
    }, new Map<number, Array<ITraceDataQueryHistoryChartDataPoints | null>>());
  } else {
    return normalizedPoints.reduce((prev, curr) => {
      const points = prev.get(curr.planNumber);

      if (!points) {
        prev.set(curr.planNumber, [curr]);
      } else {
        points.push(curr);
      }
      return prev;
    }, new Map<number, ITraceDataQueryHistoryChartDataPoints[]>());
  }
}

/**
 * Returns a function that accepts a date as input and returns a normalized version of that date
 * based on the specified grouping.
 * @param grouping Selected grouping option.
 */
function makeGroupingDateNormalizer(grouping: Grouping): (date: Date) => Date {
  let delta: DateObjectUnits;
  switch (grouping) {
    case Grouping.day:
      delta = { hour: 0, millisecond: 0, minute: 0, second: 0 };
      break;
    case Grouping.hour:
      delta = { millisecond: 0, minute: 0, second: 0 };
      break;
    case Grouping.week:
      delta = { hour: 0, millisecond: 0, minute: 0, second: 0, weekday: 0 };
      break;
    default:
      return (date) => date;
  }
  return (date) => DateTime.fromJSDate(date).set(delta).toJSDate();
}

function mergeResultsToPoints(
  procedureResults: Map<number, Array<IProcedureQueryHistoryChartPoints | null>>,
  traceResults: Map<number, Array<ITraceDataQueryHistoryChartDataPoints | null>>,
): IQueryHistoryPoint[] {
  const series: Record<number, IQueryHistoryPoint> = {};
  Array.from(procedureResults).forEach(([planNumber, points]) => {
    points
      .filter((x): x is NonNullable<typeof x> => x !== null)
      .forEach((point) => {
        const timestamp = point.startTimeUtc.getTime();
        if (!series[timestamp]) {
          series[timestamp] = { timestamp };
        }
        series[timestamp][`p-${planNumber}`] = {
          cpu: point.workerTimeDelta,
          data: point,
          duration: point.elapsedTimeDelta,
          io: point.logicalReadsDelta,
        };
      });
  });
  Array.from(traceResults).forEach(([planNumber, points]) => {
    points
      .filter((x): x is NonNullable<typeof x> => x !== null)
      .forEach((point) => {
        const timestamp = point.normalizedStartTime.getTime();
        if (!series[timestamp]) {
          series[timestamp] = { timestamp };
        }
        series[timestamp][`t-${planNumber}`] = {
          cpu: point.cpu,
          data: point,
          duration: point.duration,
          io: point.reads,
        };
      });
  });
  return Object.values(series);
}

function mergeResultsToSeries(
  procedureResults: Map<number, Array<IProcedureQueryHistoryChartPoints | null>>,
  traceResults: Map<number, Array<ITraceDataQueryHistoryChartDataPoints | null>>,
  theme: Theme,
): IQueryHistorySeries[] {
  const colors = theme.palette.getGenericColorGenerator();
  const colorMap = new Map<number, string>();
  function getOrAddColor(planNumber: number): string {
    if (planNumber === 0) {
      return theme.palette.grey[500];
    } else {
      const color = colorMap.get(planNumber) ?? colors.next().value;
      colorMap.set(planNumber, color);
      return color;
    }
  }
  return [
    ...Array.from(
      procedureResults,
      ([planNumber]): IQueryHistorySeries => {
        return {
          color: getOrAddColor(planNumber),
          key: `p-${planNumber}`,
          shape: "circle",
        };
      },
    ),
    ...Array.from(
      traceResults,
      ([planNumber]): IQueryHistorySeries => {
        return {
          color: getOrAddColor(planNumber),
          key: `t-${planNumber}`,
          shape: "triangle",
        };
      },
    ),
  ];
}

/**
 * Normalizes procedure result dates using the specified grouping option.
 * @param data
 * @param options
 */
function normalizeProcedureResults(
  data: readonly IProcedureQueryHistoryChartPoints[],
  { grouping }: IQueryHistoryOptions,
): IProcedureQueryHistoryChartPoints[] {
  const normalizer = makeGroupingDateNormalizer(grouping);
  return data.map<IProcedureQueryHistoryChartPoints>((d) => {
    return {
      ...d,
      startTimeUtc: normalizer(d.startTimeUtc),
    };
  });
}

/**
 * Normalizes trace result dates using the specified grouping option.
 * @param data
 * @param options
 */
function normalizeTraceResults(
  data: readonly ITraceDataQueryHistoryChartDataPoints[],
  { dataDisplay, grouping }: IQueryHistoryOptions,
): ITraceDataQueryHistoryChartDataPoints[] {
  const normalizer = makeGroupingDateNormalizer(grouping);
  if (dataDisplay === DataDisplay.totals) {
    // If the recCount is undefined, there is no aggregation taking place, and the initial numbers are correct
    return data.map((d) => {
      return {
        ...d,
        cpu: d.cpu * (d.recCount ?? 1),
        duration: d.duration * (d.recCount ?? 1),
        normalizedStartTime: normalizer(d.normalizedStartTime),
        reads: d.reads * (d.recCount ?? 1),
        writes: d.writes * (d.recCount ?? 1),
      };
    });
  } else {
    return data.map<ITraceDataQueryHistoryChartDataPoints>((d) => {
      return {
        ...d,
        normalizedStartTime: normalizer(d.normalizedStartTime),
      };
    });
  }
}

/**
 * Trims procedure results to the top N items specified by count.
 * Does not apply grouping when categories is null or grouping selection is ungrouped.
 * @param groupedResults Map of normalized procedure results by plan number.
 * @param categories Array of categories. If null, points are only grouped by plan number and not timestamp.
 * @param options
 * @param count Number of plans to return per category (point in time).
 */
function trimProcedureResults(
  groupedResults: Map<number, Array<IProcedureQueryHistoryChartPoints | null>>,
  categories: Date[] | null,
  { grouping, metric }: IQueryHistoryOptions,
  count: number,
): Map<number, Array<IProcedureQueryHistoryChartPoints | null>> {
  if (grouping === Grouping.ungrouped || !categories) {
    return groupedResults;
  }

  const field = getMetricProcedureField(metric);
  const allValues = Array.from(groupedResults.values());
  const topByIndex = categories.map((_, i) => {
    return allValues
      .map((z) => z[i])
      .filter((x): x is NonNullable<typeof x> => x !== null)
      .sort((a, b) => b[field] - a[field])
      .slice(0, count);
  });

  const copy = new Map<number, Array<IProcedureQueryHistoryChartPoints | null>>();
  groupedResults.forEach((points, plan) => {
    const trimmedPoints = points.map((p, i) => {
      // If the point is not in the top X for this index, return null in its place to hide the point
      return p && topByIndex[i].includes(p) ? p : null;
    });
    if (trimmedPoints.some((x) => x !== null)) {
      copy.set(plan, trimmedPoints);
    }
  });
  return copy;
}

/**
 * Trims trace results to the top N items specified by count.
 * Does not apply grouping when categories is null or grouping selection is ungrouped.
 * @param groupedResults Map of normalized procedure results by plan number.
 * @param categories Array of categories. If null, points are only grouped by plan number and not timestamp.
 * @param options
 * @param count Number of plans to return per category (point in time).
 */
function trimTraceResults(
  groupedResults: Map<number, Array<ITraceDataQueryHistoryChartDataPoints | null>>,
  categories: Date[] | null,
  { grouping, metric }: IQueryHistoryOptions,
  count: number,
): Map<number, Array<ITraceDataQueryHistoryChartDataPoints | null>> {
  if (grouping === Grouping.ungrouped || !categories) {
    return groupedResults;
  }

  const field = getMetricTraceField(metric);
  const allValues = Array.from(groupedResults.values());
  const topByIndex = categories.map((_, i) => {
    return allValues
      .map((z) => z[i])
      .filter((x): x is NonNullable<typeof x> => x !== null)
      .sort((a, b) => b[field] - a[field])
      .slice(0, count);
  });

  const copy = new Map<number, Array<ITraceDataQueryHistoryChartDataPoints | null>>();
  groupedResults.forEach((points, plan) => {
    const trimmedPoints = points.map((p, i) => {
      // If the point is not in the top X for this index, return null in its place to hide the point
      return p && topByIndex[i].includes(p) ? p : null;
    });
    if (trimmedPoints.some((x) => x !== null)) {
      copy.set(plan, trimmedPoints);
    }
  });
  return copy;
}

/**
 * Builds criteria for query history data fetch based on specified options.
 * @param options
 */
function useQueryHistoryCriteria({
  eventSourceConnectionId,
  options: { dataDisplay, dateRange, mode },
}: IUseQueryHistoryOptions): IQueryHistoryCriteria | null {
  const { isTotals, selectedEvent, selectedStatement, selectedStatementEvent, selectedTotal } = useTopSqlContext();
  const isAverage = dataDisplay === DataDisplay.average;
  const selectedTraceEvent = !isTotals ? (mode === Mode.statement ? selectedStatementEvent : selectedEvent) : null;

  const traceEventCriteria = useMemo<IQueryHistoryCriteria | null>(() => {
    if (!selectedTraceEvent || selectedTotal) {
      return null;
    } else {
      return {
        databaseName: selectedTraceEvent.databaseName ?? undefined,
        deviceId: 0,
        endDateTimeUtc: dateRange.to,
        eventSourceConnectionId,
        isAverage,
        objectNameHash: mode === Mode.procedure ? selectedTraceEvent.textMd5 : "",
        parentTextMd5: selectedTraceEvent.parentTextMd5,
        queryType: selectedTraceEvent.type,
        startDateTimeUtc: dateRange.from,
        textMd5String: selectedTraceEvent.textMd5,
      };
    }
  }, [eventSourceConnectionId, isAverage, dateRange, mode, selectedTotal, selectedTraceEvent]);

  const procedureCriteria = useMemo<IQueryHistoryCriteria | null>(() => {
    if (!selectedTotal) {
      return null;
    } else {
      return {
        databaseName: selectedTotal.databaseName,
        deviceId: selectedTotal.deviceId,
        endDateTimeUtc: dateRange.to,
        eventSourceConnectionId,
        isAverage,
        objectNameHash: selectedTotal.textMd5,
        queryType: selectedTotal.type,
        startDateTimeUtc: dateRange.from,
        textMd5String: selectedTotal.textMd5,
      };
    }
  }, [eventSourceConnectionId, isAverage, dateRange, selectedTotal]);

  const statementCriteria = useMemo<IQueryHistoryCriteria | null>(() => {
    if (!selectedStatement || !selectedTotal) {
      return null;
    }

    return {
      databaseName: selectedTotal.databaseName,
      deviceId: selectedTotal.deviceId,
      endDateTimeUtc: dateRange.to,
      eventSourceConnectionId,
      isAverage,
      objectNameHash: "",
      parentTextMd5: selectedTotal.textMd5,
      queryType: selectedStatement.type,
      startDateTimeUtc: dateRange.from,
      textMd5String: selectedStatement.textMd5,
    };
  }, [eventSourceConnectionId, isAverage, dateRange, selectedStatement, selectedTotal]);

  // Select between procedure, statement, or trace criteria
  if (traceEventCriteria) {
    return traceEventCriteria;
  } else if (mode === Mode.procedure) {
    return procedureCriteria;
  } else {
    return statementCriteria;
  }
}

/**
 * Fetches query history data with the specified criteria.
 * @param criteria
 */
function useQueryHistoryData(criteria: IQueryHistoryCriteria | null): AsyncState<IQueryHistoryPoints> {
  const { statementResults, totalsResults } = useTopSqlContext();

  const isLoading = statementResults.isLoading || totalsResults.isLoading;
  const promiseFn = useCallback(
    (_: never, controller: AbortController): Promise<IQueryHistoryPoints> => {
      if (isLoading) {
        // Return a promise that never resolves until prerequisite data loading is complete
        return new Promise<IQueryHistoryPoints>(() => null);
      } else if (criteria === null) {
        return Promise.resolve<IQueryHistoryPoints>({
          procedureChartPoints: [],
          traceDataChartPoints: [],
        });
      } else {
        const service = new TopSqlService();
        return service.fetchQueryHistory(criteria, controller.signal);
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [
      //eslint-disable-next-line react-hooks/exhaustive-deps
      criteria?.databaseName,
      //eslint-disable-next-line react-hooks/exhaustive-deps
      criteria?.deviceId,
      //eslint-disable-next-line react-hooks/exhaustive-deps
      criteria?.endDateTimeUtc,
      //eslint-disable-next-line react-hooks/exhaustive-deps
      criteria?.eventSourceConnectionId,
      //eslint-disable-next-line react-hooks/exhaustive-deps
      criteria?.isAverage,
      //eslint-disable-next-line react-hooks/exhaustive-deps
      criteria?.objectNameHash,
      //eslint-disable-next-line react-hooks/exhaustive-deps
      criteria?.parentTextMd5,
      //eslint-disable-next-line react-hooks/exhaustive-deps
      criteria?.queryType,
      //eslint-disable-next-line react-hooks/exhaustive-deps
      criteria?.startDateTimeUtc,
      //eslint-disable-next-line react-hooks/exhaustive-deps
      criteria?.textMd5String,
      isLoading,
    ],
  );

  return useAsync(promiseFn);
}

/**
 * Fetches query history data and prepares data for display.
 * @param hookOptions
 */
export default function useQueryHistory(hookOptions: IUseQueryHistoryOptions): IUseQueryHistoryResults {
  const { options } = hookOptions;
  const theme = useTheme();
  const criteria = useQueryHistoryCriteria(hookOptions);
  const { data, error, isLoading } = useQueryHistoryData(criteria);

  const [points, series] = useMemo<[IQueryHistoryPoint[], IQueryHistorySeries[]]>(
    () => {
      const normalizedProcedureResults = normalizeProcedureResults(data?.procedureChartPoints ?? [], options);
      const procedureCategories = buildProcedureCategories(normalizedProcedureResults, options.grouping);
      const groupedProcedureResults = groupProcedureResults(normalizedProcedureResults, procedureCategories);
      const trimmedProcedureResults = trimProcedureResults(groupedProcedureResults, procedureCategories, options, 5);

      const normalizedTraceResults = normalizeTraceResults(data?.traceDataChartPoints ?? [], options);
      const traceCategories = buildTraceCategories(normalizedTraceResults, options.grouping);
      const groupedTraceResults = groupTraceResults(normalizedTraceResults, traceCategories);
      const trimmedTraceResults = trimTraceResults(groupedTraceResults, traceCategories, options, 5);

      return [
        mergeResultsToPoints(trimmedProcedureResults, trimmedTraceResults),
        mergeResultsToSeries(trimmedProcedureResults, trimmedTraceResults, theme),
      ];
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [data, options.dataDisplay, options.dateRange, options.grouping, options.metric, options.mode],
  );

  return {
    data: points,
    error,
    isLoading,
    series,
  };
}

export interface IQueryHistoryPoint {
  /** number is only included as a type here because TypeScript cannot model an indexer with additional fields of a different return type (timestamp) */
  [key: string]: number | IQueryHistoryPointValues;
  readonly timestamp: number;
}

export interface IQueryHistoryPointValues {
  readonly data: IProcedureQueryHistoryChartPoints | ITraceDataQueryHistoryChartDataPoints;
  readonly cpu: number;
  readonly duration: number;
  readonly io: number;
}

export interface IQueryHistorySeries {
  readonly color: string;
  readonly key: string;
  readonly shape: "circle" | "triangle";
}

export interface IUseQueryHistoryOptions {
  eventSourceConnectionId: number;
  options: IQueryHistoryOptions;
}

export interface IUseQueryHistoryResults {
  readonly data: readonly IQueryHistoryPoint[];
  readonly error?: Error;
  readonly isLoading: boolean;
  readonly series: readonly IQueryHistorySeries[];
}
