import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import * as React from "react";
import { useIntl } from "react-intl";
import QueryText from "../../../../components/QueryText";
import { IBlockingSelection } from "../../types";

export interface IBlockDetailsProps {
  className?: string;
  fullScreen: boolean;
  onToggleFullScreen: () => void;
  selection: IBlockingSelection | null;
}

const useStyles = makeStyles({
  detailsContent: {
    overflow: "auto",
  },
  fullScreenToggle: {
    flexGrow: "inherit",
    minWidth: "4rem",
  },
});

const BlockDetails: React.FunctionComponent<IBlockDetailsProps> = ({
  fullScreen,
  onToggleFullScreen,
  selection,
}) => {
  const intl = useIntl();
  const classes = useStyles({});
  return (
    <>
      <Tabs
        scrollButtons="auto"
        value={1}
        variant="scrollable"
      >
        <Tab
          className={classes.fullScreenToggle}
          icon={fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          onClick={onToggleFullScreen}
          title={intl.formatMessage({ id: fullScreen ? "exitFullScreen" : "fullScreen" })}
        />
        <Tab
          label={selection
            ? intl.formatMessage({ id: "spidTextData" }, { spid: selection.spid })
            : intl.formatMessage({ id: "textData" })
          }
          value={1}
        />
      </Tabs>
      <CardContent className={classes.detailsContent} data-testid="BlockDetails">
        <QueryText textData={selection?.queryText} />
      </CardContent>
    </>
  );
};

export default BlockDetails;
