import { IPageResponse } from "../../api/BaseService";

export interface IApolloRequestType {
  _typename?: string;
}

export interface IDeadlocksInfo extends IApolloRequestType {
  id: number;
  deadlocks: IDeadlockResponse[];
  deadlockXml: string;
  startTime: Date;
}

export interface IDeadlocksQuery extends IApolloRequestType {
  deadlocks: {
    list: IPageResponse<IDeadlocksInfo>;
  };
}

export interface IDeadlockByIdQuery extends IApolloRequestType {
  deadlocks: {
    getById: IDeadlocksInfo;
  };
}

export interface IDeadlockResponse {
  processes: readonly IDeadlockProcessResponse[];
  victimsIds: readonly string[];
}

export interface IDeadlock {
  deadlockXml: string;
  processes: readonly IDeadlockProcess[];
  resources: readonly IDeadlockResource[];
  victimIds: readonly string[];
}

export interface IResources {
  [key: string]: IResourceLockBase[];
  appLocks: IResourceLockBase[];
  databaseLocks: IResourceLockBase[];
  exchangeEvents: IResourceLockBase[];
  extentLocks: IResourceLockBase[];
  keyLocks: IResourceLockBase[];
  pageLocks: IResourceLockBase[];
  rIDLocks: IResourceLockBase[];
  threadpools: IResourceLockBase[];
  objectLocks: IResourceLockBase[];
}

export interface IResourceLockBase extends IApolloRequestType {
  id: string | null;
  indexName?: string;
  objectName: string | null;
  owners: IResourceItem[];
  waiters: IResourceItem[];
}

export interface IResourceItem extends IApolloRequestType {
  id: string;
  mode: string;
}

export interface IDeadlockResourceItem {
  id: string;
  /** Randomly generated, to use for React key */
  key: string;
  mode: string;
  process: IDeadlockProcess;
  requestType: string | null;
  resource: IDeadlockResource;
  sequence: number;
}

export enum DeadlockResourceType {
  AppLock = "applock",
  DatabaseLock = "databaselock",
  ExchangeEvent = "exchangeEvent",
  ExtentLock = "extentlock",
  KeyLock = "keylock",
  ObjectLock = "objectlock",
  PageLock = "pagelock",
  ResourceWait = "resourceWait",
  RidLock = "ridlock",
  Threadpool = "threadpool",
}

export interface IDeadlockResource {
  id: string;
  indexName: string | null;
  objectName: string;
  mode: string | null;
  /** Type of resource node. Only a string if the received node is not one of the expected types. */
  type: DeadlockResourceType | string;
  owners: readonly IDeadlockResourceItem[];
  waiters: readonly IDeadlockResourceItem[];
}

export interface IDeadlockProcess {
  clientApp: string;
  databaseName: string;
  id: string;
  hostName: string;
  ecid: number;
  executionStack: IDeadlockExecutionStack[];
  inputBufferText: string | null;
  isolationLevel: string | null;
  lastTranStarted: string;
  lockMode: string;
  logUsed: number;
  loginName: string;
  ownerOf: readonly IDeadlockResourceItem[];
  priority: number;
  spid: number;
  transactionName: string;
  type: "victim" | "process";
  waitResource: string;
  waitTime: number;
  waiterOf: readonly IDeadlockResourceItem[];
  /** Transaction ID */
  xactid: number;
}

export interface IDeadlockProcessResponse {
  eCID: number;
  sPID: number;
  hostName: string;
  clientApplication: string;
  databaseName: string;
  executionStack: IDeadlockExecutionStack[];
  inputBufferText: string | null;
  lastTransactionStartDateTime: string;
  processId: string;
}

export interface IDeadlockExecutionStack {
  line: number;
  processName: string;
  sqlHandle: string;
  text: string;
}

export interface IDeadlocksVariables {
  dr: {
    endDate: string;
    eventSourceConnectionId: number;
    limit: number;
    offset: number;
    startDate: string;
  };
}

export interface IDeadlockSelection {
  startTime: Date;
  victimSpid: string;
}

export interface IDeadlocksContext {
  deadlockInfo?: IDeadlocksInfo[];
  error?: Error;
  loading: boolean;
  loadingDeadlock: boolean;
  onChangeDeadlock: (newSelectNodeId: string, newDeadlockId: number) => void;
  onUploadDeadlock: (uploadedDeadlock: IDeadlock | undefined) => void;
  selectedDeadlock: IDeadlock | undefined;
  selectedDeadlockId: number | undefined;
  selectedNode: IDeadlockProcess | IDeadlockResource | undefined;
  selectedNodeId: string | undefined;
  setSelectedNodeId: (newSelection: string) => void;
}
