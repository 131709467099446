import { alpha, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    overflowX: "hidden",
    position: "relative",
  },
  header: {
    backgroundColor: "#444",
    color: "rgba(255, 255, 255, 0.9)", // #ffffffe6
  },
  popover: {
    "&::-webkit-scrollbar": {
      width: theme.spacing(),
    },
    "&::-webkit-scrollbar-thumb": {
      background: alpha(theme.palette.common.white, 0.5),
      borderRadius: theme.shape.borderRadius,
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    backgroundColor: "#444",
    color: theme.palette.common.white, // topology items inherit text color so it needs to be set here
    overflowX: "hidden",
  },
  root: {
    backgroundColor: theme.palette.background.default,
  },
  selector: {
    "&:hover": {
      backgroundColor: "#333",
      boxShadow: "none",
    },
    backgroundColor: "#333",
    color: theme.palette.common.white,
    fontWeight: 400,
  },
  selectorCaption: {
    color: "rgba(255, 255, 255, 0.7)", // #ffffffb3
    fontSize: theme.typography.caption.fontSize,
    marginLeft: theme.spacing(2),
  },
}));

export default useStyles;
