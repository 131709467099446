import { makeStyles } from "@material-ui/core/styles";
import { Column, ColumnPropsAuto } from "@sentryone/react-datatable";
import { Duration } from "luxon";
import * as React from "react";
import { formatTimeSpan, isIso8601Interval, parseTimeSpan } from "../../../utilities/Formatters";

const useStyles = makeStyles((theme) => ({
  blur: {},
  focus: {},
  root: {
    "& $blur": {
      color: theme.palette.grey[400],
    },
    "& $focus": {
      fontWeight: "bold",
    },
    fontFamily: `'Roboto', sans-serif`,
    whiteSpace: "nowrap",
  },
}));

const formatDurationUnit = (n: number, pad?: number, d?: number): string => {
  const ret = d ? n.toFixed(d) : n.toString();
  // Padding with a non breaking space character since regular space will not render properly.
  return ret.padStart(pad ? pad : 2, `\u00A0`);
};

interface IDurationUnitProps {
  focus: boolean;
  text: string;
}

function DurationUnit(props: IDurationUnitProps): React.ReactElement {
  const classes = useStyles();
  return <span className={props.focus ? classes.focus : classes.blur}>{props.text}</span>;
}

interface IFormattedDurationProps {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
  showMilliseconds?: boolean;
}

export function FormattedDuration(props: IFormattedDurationProps): React.ReactElement {
  const classes = useStyles();
  const days = props.days;
  const hours = formatDurationUnit(props.hours);
  const minutes = formatDurationUnit(props.minutes);
  const seconds = formatDurationUnit(props.seconds);
  const milliseconds = formatDurationUnit(props.milliseconds, 6, 2);

  let focusDays = false;
  let focusHours = false;
  let focusMinutes = false;
  let focusSeconds = false;
  let focusMilliseconds = false;

  if (props.days > 0) {
    focusDays = focusHours = true;
  } else if (props.hours > 0) {
    focusHours = focusMinutes = true;
  } else if (props.minutes > 0) {
    focusMinutes = focusSeconds = true;
  } else if (props.seconds > 0) {
    focusSeconds = focusMilliseconds = true;
  } else {
    focusMilliseconds = true;
  }

  let durationUnits;

  if (!props.showMilliseconds && props.seconds === 0 && props.minutes === 0 && props.hours === 0 && props.days === 0) {
    durationUnits = <DurationUnit focus text="< 1s" />;
  } else {
    durationUnits = (
      <>
        <DurationUnit focus={focusDays} text={`${days}d`} />
        <DurationUnit focus={focusHours} text={` ${hours}h`} />
        <DurationUnit focus={focusMinutes} text={` ${minutes}m`} />
        <DurationUnit focus={focusSeconds} text={` ${seconds}s`} />
        {props.showMilliseconds && <DurationUnit focus={focusMilliseconds} text={` ${milliseconds}ms`} />}
      </>
    );
  }

  return <span className={classes.root}>{durationUnits}</span>;
}

export interface ITimespanColumnProps<TEntity> extends ColumnPropsAuto<TEntity> {
  /**
   * Whether to show milliseconds on this column.
   * @default false
   */
  showMilliseconds?: boolean;
  /**
   * Whether to show the new duration format on this column.
   * @default false
   */
  showAsDuration?: boolean;
}

export default function TimespanColumn<TEntity>({
  showAsDuration = false,
  showMilliseconds = false,
  ...props
}: ITimespanColumnProps<TEntity>): React.ReactElement | null {
  const renderCell = (d: TEntity): React.ReactElement | null => {
    const value = d[props.field];
    if (value instanceof Duration) {
      const normalizedValue = value.shiftTo("days", "hours", "minutes", "seconds", "milliseconds");
      if (showAsDuration) {
        return (
          <FormattedDuration
            days={normalizedValue.days}
            hours={normalizedValue.hours}
            milliseconds={normalizedValue.milliseconds}
            minutes={normalizedValue.minutes}
            seconds={normalizedValue.seconds}
            showMilliseconds={showMilliseconds}
          />
        );
      } else {
        if (normalizedValue.days > 0) return <>{normalizedValue.toFormat("d.hh:mm:ss.SSS")}</>;
        else return <>{normalizedValue.toFormat("hh:mm:ss.SSS")}</>;
      }
    } else if (typeof value === "string") {
      if (!showAsDuration) {
        return <>{formatTimeSpan(value)}</>;
      } else if (isIso8601Interval(value)) {
        // ISO-8601 duration
        const duration = Duration.fromISO(value).shiftTo("days", "hours", "minutes", "seconds", "milliseconds");
        return (
          <FormattedDuration
            days={duration.days}
            hours={duration.hours}
            milliseconds={duration.milliseconds}
            minutes={duration.minutes}
            seconds={duration.seconds}
            showMilliseconds={showMilliseconds}
          />
        );
      } else {
        // .NET TimeSpan duration
        const timeSpan = parseTimeSpan(value);
        return (
          <FormattedDuration
            days={timeSpan.days}
            hours={timeSpan.hours}
            milliseconds={timeSpan.milliseconds}
            minutes={timeSpan.minutes}
            seconds={timeSpan.seconds}
            showMilliseconds={showMilliseconds}
          />
        );
      }
    } else if (typeof value === "number") {
      const duration = Duration.fromMillis(value).shiftTo("milliseconds", "seconds", "minutes", "hours", "days");

      return (
        <FormattedDuration
          days={duration.days}
          hours={duration.hours}
          milliseconds={duration.milliseconds}
          minutes={duration.minutes}
          seconds={duration.seconds}
          showMilliseconds={showMilliseconds}
        />
      );
    } else if (value) {
      throw new Error(`Value in TimespanColumn is not of the correct type. Value is of type ${typeof value}.`);
    } else {
      return null;
    }
  };
  return (
    <Column<TEntity>
      align="left"
      defaultSortDirection="desc"
      renderCell={renderCell}
      sort={(a, b) => {
        // we can't compare time as strings because '.' and ':' may throw off comparisons (ASCII representation)
        // convert each field to a string, then remove all non-numeric characters, then convert result back to a
        // number for simple comparison
        const valueA = a[props.field];
        const valueB = b[props.field];
        let aTime: number;
        let bTime: number;
        if (valueA instanceof Duration && valueB instanceof Duration) {
          aTime = valueA.valueOf();
          bTime = valueB.valueOf();
        } else if (typeof valueA === "number" && typeof valueB === "number") {
          aTime = valueA;
          bTime = valueB;
        } else if (typeof valueA === "string" && isIso8601Interval(valueA) && typeof valueB === "string") {
          aTime = Duration.fromISO(valueA).valueOf();
          bTime = Duration.fromISO(valueB).valueOf();
        } else {
          aTime = parseInt(String(valueA).replace(/\D/g, ""));
          bTime = parseInt(String(valueB).replace(/\D/g, ""));
        }
        return aTime - bTime;
      }}
      {...props}
    />
  );
}
