import { makeStyles } from "@material-ui/core/styles";
import { CardTable } from "@sentryone/material-ui";
import { SortDescriptor } from "@sentryone/react-datatable";
import * as React from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { IExecutedQueryTraceEventsResponse } from "../../../../api/models/IExecutedQueryTraceEventsCriteria";
import { styles } from "../../../../components/ThemeProvider/grids";
import { useState } from "react";
import CustomSnackbar from "../../components/CustomSnackbar";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import QueryTooltip from "../QueryTooltip";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import {
  DataTable,
  DataTablePFSEvent,
  DataTableFilterMetaData,
  DataTableOperatorFilterMetaData,
} from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { useTopSqlContext } from "../../../../contexts/topSqlContext";
import { FilterMatchMode } from "primereact/api";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { GRID_PAGINATIONROW_OPTIONS } from "../../../../utilities/PrimeReactUtility";
import { ON_PAGE, ON_SORT, DEFAULT_PAGE, CLEAR_FILTER } from "../../../../contexts/topSqlContext/types";
import TraceWaitsModal from "./TraceWaitsModal";

interface ISelectedStatement {
  databaseId?: number | null;
  objectName?: string | null;
  textMd5: string;
  cpu?: number | null;
  reads?: number | null;
  textData: string;
}

export interface ITopSqlFilterDropDownData {
  databaseNames: Array<{ id: string | number; name: string }>;
  hostNames: Array<{ id: string | number; name: string }>;
  applicationNames: Array<{ id: string | number; name: string }>;
  loginNames: Array<{ id: string | number; name: string }>;
  sPIDs: Array<{ id: string | number; name: string }>;
  errors: Array<{ id: string | number; name: string }>;
  eventClasses: Array<{ id: string | number; name: string }>;
}

export interface ITraceEventsGridProps {
  backButtonToolTip?: string;
  className?: string;
  data: readonly IExecutedQueryTraceEventsResponse[];
  error?: Error;
  eventSourceConnectionId: number;
  topsqlFilterDropDownData: ITopSqlFilterDropDownData;
  isLoading: boolean;
  isTraceStatement: boolean;
  isTotals: boolean;
  totalCount?: number;
  onBackClick?: () => void;
  onChangePage: (pageIndex: number, pageSize: number) => void;
  onHandleSort: (sort: readonly SortDescriptor[]) => void;
  onRowSelected: (row: IExecutedQueryTraceEventsResponse) => void;
  pageIndex: number;
  pageSize: number;
  rowsPerPage: number;
  selectedRow: ISelectedStatement | null;
  sort: readonly SortDescriptor[];
  style?: React.CSSProperties;
}

const useStyles = makeStyles({
  ...styles,
  filterDropDown: {
    width: "17em",
  },
  header: {
    backgroundColor: "#FFFFFF",
    padding: "5px",
  },
  searchBoxWidth: {
    minWidth: "15rem",
  },
});

function instanceOfFilter(
  object: DataTableFilterMetaData | DataTableOperatorFilterMetaData,
): object is DataTableOperatorFilterMetaData {
  return "constraints" in object;
}

type sortOrder = 1 | 0 | -1 | undefined | null;

export interface ILazyParams {
  first: number;
  page: number;
  rows: number;
  sortField: string;
  sortOrder: sortOrder;
  filters?: any;
}

export const handleSortOrder = (state: ILazyParams, event: DataTablePFSEvent): sortOrder => {
  let sortOrder: 1 | 0 | -1 | undefined | null = -1;
  if (event?.sortField === state.sortField) {
    sortOrder = state.sortOrder && state.sortOrder > 0 ? -1 : 1;
  }
  return sortOrder;
};

function reducer(state: ILazyParams, action: { type: string; event?: DataTablePFSEvent }): ILazyParams {
  switch (action.type) {
    case ON_PAGE:
      return {
        ...state,
        first: action.event?.first ?? state.first,
        page: action.event?.page ?? state.page,
        rows: action.event?.rows ?? state.rows,
      };
    case ON_SORT:
      return {
        ...state,
        sortField: action.event?.sortField ?? state.sortField,
        sortOrder: action.event && handleSortOrder(state, action.event),
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filters: {
          applicationName: {
            constraints: [
              {
                matchMode: FilterMatchMode.STARTS_WITH,
                value: null,
              },
            ],
            operator: "and",
          },
          databaseName: {
            constraints: [
              {
                matchMode: FilterMatchMode.STARTS_WITH,
                value: null,
              },
            ],
            operator: "and",
          },
          error: {
            constraints: [
              {
                matchMode: FilterMatchMode.EQUALS,
                value: null,
              },
            ],
            operator: "and",
          },
          eventClass: {
            constraints: [
              {
                matchMode: FilterMatchMode.EQUALS,
                value: null,
              },
            ],
            operator: "and",
          },
          hostName: {
            constraints: [
              {
                matchMode: FilterMatchMode.STARTS_WITH,
                value: null,
              },
            ],
            operator: "and",
          },
          login: {
            constraints: [
              {
                matchMode: FilterMatchMode.STARTS_WITH,
                value: null,
              },
            ],
            operator: "and",
          },
          spid: {
            constraints: [
              {
                matchMode: FilterMatchMode.STARTS_WITH,
                value: null,
              },
            ],
            operator: "and",
          },
        },
        first: 0,
        page: 1,
        rows: GRID_PAGINATIONROW_OPTIONS[0],
        sortField: "reads",
        sortOrder: -1,
      };
    case DEFAULT_PAGE:
      return {
        ...state,
        first: 0,
        page: 1,
      };
    default:
      return {
        first: 0,
        page: 1,
        rows: GRID_PAGINATIONROW_OPTIONS[0],
        sortField: "reads",
        sortOrder: -1,
      };
  }
}
const TraceEventsGrid = React.forwardRef<HTMLElement, ITraceEventsGridProps>(
  (
    {
      backButtonToolTip,
      className,
      data,
      error,
      eventSourceConnectionId,
      topsqlFilterDropDownData,
      isLoading,
      isTotals,
      isTraceStatement,
      totalCount,
      onBackClick,
      onChangePage,
      onHandleSort,
      onRowSelected,
      pageIndex,
      pageSize,
      rowsPerPage,
      selectedRow,
      sort,
      style = {},
      ...props
    },
    ref,
  ) => {
    const classes = useStyles();
    const { handleSearchTotals, handleTraceEventFilter, searchKeyword } = useTopSqlContext();

    const timeoutId = React.useRef<ReturnType<typeof setInterval>>();
    const [open, setOpen] = useState(false);
    const [keywordSeach, setKeywordSearch] = React.useState(searchKeyword ?? "");
    const [isSearchDisabled, setIsSearchDisabled] = React.useState(true);
    const [isClearBtnDisabled, setIsClearBtnDisabled] = React.useState(true);
    const [state, dispatch] = React.useReducer(reducer, {
      filters: {
        applicationName: {
          constraints: [
            {
              matchMode: FilterMatchMode.STARTS_WITH,
              value: null,
            },
          ],
          operator: "and",
        },
        databaseName: {
          constraints: [
            {
              matchMode: FilterMatchMode.STARTS_WITH,
              value: null,
            },
          ],
          operator: "and",
        },
        error: {
          constraints: [
            {
              matchMode: FilterMatchMode.EQUALS,
              value: null,
            },
          ],
          operator: "and",
        },
        eventClass: {
          constraints: [
            {
              matchMode: FilterMatchMode.EQUALS,
              value: null,
            },
          ],
          operator: "and",
        },
        hostName: {
          constraints: [
            {
              matchMode: FilterMatchMode.STARTS_WITH,
              value: null,
            },
          ],
          operator: "and",
        },
        login: {
          constraints: [
            {
              matchMode: FilterMatchMode.STARTS_WITH,
              value: null,
            },
          ],
          operator: "and",
        },
        spid: {
          constraints: [
            {
              matchMode: FilterMatchMode.STARTS_WITH,
              value: null,
            },
          ],
          operator: "and",
        },
      },
      first: 0,
      page: 1,
      rows: GRID_PAGINATIONROW_OPTIONS[0],
      sortField: "reads",
      sortOrder: -1,
    });

    const copyQuery = (d: IExecutedQueryTraceEventsResponse | undefined): void => {
      navigator.clipboard.writeText(d?.textData ?? "");
      setOpen(true);
    };

    const handleClose = (): void => {
      setOpen(false);
    };
    const onPage = (event: DataTablePFSEvent): void => {
      dispatch({ event, type: ON_PAGE });
      const page = event.first > -1 ? event.first : 5;
      const rows = event.rows === state.rows ? state.rows : event.rows;
      onChangePage(page, rows);
    };
    const onSort = (event: DataTablePFSEvent): void => {
      const sortOrder = handleSortOrder(state, event);
      onHandleSort([
        {
          direction: sortOrder && sortOrder > 0 ? "asc" : "desc",
          id: event.sortField,
        },
      ]);
      dispatch({ event, type: ON_SORT });
    };

    const onSearch = React.useCallback(
      (event): void => {
        if (timeoutId?.current) clearTimeout(timeoutId?.current);
        const searchKeyword = event.target.value;
        setKeywordSearch(searchKeyword);
        setIsClearBtnDisabled(false);
        timeoutId.current = setTimeout(() => {
          onChangePage(0, state.rows);
          dispatch({ type: DEFAULT_PAGE });
          handleSearchTotals({ ...state.filters, searchKeyword: searchKeyword.trim() });
        }, 1000);
      },
      [handleSearchTotals, state.filters, onChangePage, state.rows],
    );

    const clearFilter = (): void => {
      setKeywordSearch("");
      handleSearchTotals({ searchKeyword: "" });
      onHandleSort([
        {
          direction: "desc",
          id: "reads",
        },
      ]);
      handleTraceEventFilter({
        applicationName: null,
        databaseName: null,
        errorKeyword: null,
        eventClass: null,
        hostName: null,
        loginName: null,
        spid: null,
      });
      onChangePage(0, GRID_PAGINATIONROW_OPTIONS[0]);
      setIsClearBtnDisabled(true);
      dispatch({ type: CLEAR_FILTER });
    };

    const renderHeader = (): JSX.Element => {
      return (
        <>
          <p className="MuiTypography-h5">
            <FormattedMessage id={isTraceStatement ? "traceEventStatements" : "traceEvents"} />
          </p>
          <div className="flex justify-content-between">
            <Button
              className="p-button-contained"
              disabled={isClearBtnDisabled}
              icon="pi pi-filter-slash"
              label="Clear"
              onClick={clearFilter}
              type="button"
            />
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                autoComplete="off"
                disabled={isSearchDisabled}
                name="searchKey"
                onChange={(e) => {
                  e.persist();
                  onSearch(e);
                }}
                placeholder="Search"
                type="search"
                value={keywordSeach}
              />
            </span>
          </div>
        </>
      );
    };

    const header = renderHeader();

    React.useEffect(() => {
      setIsSearchDisabled(isLoading);
    }, [isLoading]);

    const hostNameFilterTemplate = (options: ColumnFilterElementTemplateOptions): JSX.Element => {
      return (
        <MultiSelect
          className={classes.filterDropDown}
          data-testid="hostName-options"
          onChange={(e) => options.filterCallback(e.value, options.index)}
          options={topsqlFilterDropDownData.hostNames.map((action) => action.name)}
          placeholder="Select Host"
          value={options.value}
        />
      );
    };

    const databaseNameFilterTemplate = (options: ColumnFilterElementTemplateOptions): JSX.Element => {
      return (
        <MultiSelect
          className={classes.filterDropDown}
          data-testid="databaseName-options"
          onChange={(e) => options.filterCallback(e.value, options.index)}
          options={topsqlFilterDropDownData.databaseNames.map((action) => action.name)}
          placeholder="Select Database Name"
          value={options.value}
        />
      );
    };

    const loginNameFilterTemplate = (options: ColumnFilterElementTemplateOptions): JSX.Element => {
      return (
        <MultiSelect
          className={classes.filterDropDown}
          data-testid="loginName-options"
          onChange={(e) => options.filterCallback(e.value, options.index)}
          options={topsqlFilterDropDownData.loginNames.map((action) => action.name)}
          placeholder="Select Login"
          value={options.value}
        />
      );
    };

    const applicationNameFilterTemplate = (options: ColumnFilterElementTemplateOptions): JSX.Element => {
      return (
        <MultiSelect
          className={classes.filterDropDown}
          data-testid="applicationName-options"
          onChange={(e) => options.filterCallback(e.value, options.index)}
          options={topsqlFilterDropDownData.applicationNames.map((action) => action.name)}
          placeholder="Select Application Name"
          value={options.value}
        />
      );
    };

    const spidFilterTemplate = (options: ColumnFilterElementTemplateOptions): JSX.Element => {
      return (
        <MultiSelect
          className={classes.filterDropDown}
          data-testid="spid-options"
          onChange={(e) => options.filterCallback(e.value, options.index)}
          options={topsqlFilterDropDownData.sPIDs.map((action) => action.name)}
          placeholder="Select SPID"
          value={options.value}
        />
      );
    };

    const errorFilterTemplate = (options: ColumnFilterElementTemplateOptions): JSX.Element => {
      return (
        <MultiSelect
          className={classes.filterDropDown}
          data-testid="error-options"
          onChange={(e) => options.filterCallback(e.value, options.index)}
          options={topsqlFilterDropDownData.errors.map((action) => action.name)}
          placeholder="Select Error"
          value={options.value}
        />
      );
    };

    const eventClassFilterTemplate = (options: ColumnFilterElementTemplateOptions): JSX.Element => {
      return (
        <MultiSelect
          className={classes.filterDropDown}
          data-testid="severity-options"
          onChange={(e) => options.filterCallback(e.value, options.index)}
          options={topsqlFilterDropDownData.eventClasses.map((action) => action.name)}
          placeholder="Select Event Class"
          value={options.value}
        />
      );
    };

    const onFilter = async (event: DataTablePFSEvent): Promise<void> => {
      let hostFilter: string[] = [];
      const eventClassFilter: number[] = [];
      let databaseNameFilter: string[] = [];
      let loginFilter: string[] = [];
      let applicationName: string[] = [];
      const spidFilter: number[] = [];
      const errorFilter: number[] = [];

      Object.keys(event.filters).map((key) => {
        let filterEnum: Array<{ id: string | number; name: string }> = topsqlFilterDropDownData.eventClasses;
        let arrayToPush: Array<string | number> = [];
        const dataObj = event.filters[key];
        switch (key) {
          case "eventClass":
            filterEnum = topsqlFilterDropDownData.eventClasses;
            arrayToPush = eventClassFilter;
            break;
          case "hostName":
            filterEnum = topsqlFilterDropDownData.hostNames;
            arrayToPush = hostFilter;
            hostFilter = instanceOfFilter(dataObj) && dataObj?.constraints[0]?.value;
            break;
          case "databaseName":
            filterEnum = topsqlFilterDropDownData.databaseNames;
            arrayToPush = databaseNameFilter;
            databaseNameFilter = instanceOfFilter(dataObj) && dataObj?.constraints[0]?.value;
            break;
          case "login":
            filterEnum = topsqlFilterDropDownData.loginNames;
            arrayToPush = loginFilter;
            loginFilter = instanceOfFilter(dataObj) && dataObj?.constraints[0]?.value;
            break;
          case "applicationName":
            filterEnum = topsqlFilterDropDownData.applicationNames;
            arrayToPush = applicationName;
            applicationName = instanceOfFilter(dataObj) && dataObj?.constraints[0]?.value;
            break;
          case "spid":
            filterEnum = topsqlFilterDropDownData.sPIDs;
            arrayToPush = spidFilter;
            break;
          case "error":
            filterEnum = topsqlFilterDropDownData.errors;
            arrayToPush = errorFilter;
            break;
        }
        if (key === "eventClass" || key === "spid" || key === "error") {
          if (instanceOfFilter(dataObj) && dataObj.constraints[0]?.value) {
            for (const value of dataObj.constraints[0]?.value) {
              arrayToPush.push(filterEnum.filter((raw) => raw.name === value)[0]?.id);
            }
          }
        }
      });

      onChangePage(0, state.rows);
      dispatch({ type: DEFAULT_PAGE });
      handleTraceEventFilter({
        ...state.filters,
        applicationName: applicationName,
        databaseName: databaseNameFilter,
        errorKeyword: errorFilter,
        eventClass: eventClassFilter,
        hostName: hostFilter,
        loginName: loginFilter,
        spid: spidFilter,
      });
      setIsClearBtnDisabled(false);
    };

    const columns = [
      { field: "grantedMemoryKB", header: "grantedMemoryKB" },
      { field: "grantedQueryMemoryKB", header: "grantedQueryMemoryKB" },
      { field: "requestedMemoryKB", header: "requestedMemoryKB" },
      { field: "sessionMemoryKB", header: "sessionMemoryKB" },
      { field: "tempdbInternalKB", header: "tempdbInternalKB" },
      { field: "tempdbInternalKBDealloc", header: "tempdbInternalKBDealloc" },
      { field: "tempdbUserKB", header: "tempdbUserKB" },
      { field: "tempdbUserKBDealloc", header: "tempdbUserKBDealloc" },
    ];

    const tempdbMemoryRelatedColumns = columns.map((col) => {
      return <Column field={col.field} header={<FormattedMessage id={col.header} />} key={col.field} sortable />;
    });

    return (
      <div className={className} ref={ref as React.Ref<HTMLDivElement>}>
        <CustomSnackbar
          animation="Slide"
          autoHideDuration={2000}
          handleClose={handleClose}
          icon={<FileCopyIcon />}
          message="Query copied successfully!"
          open={open}
          slideDirection="right"
        />
        <CardTable
          backButtonProps={
            isTotals
              ? {
                  onClick: onBackClick,
                  title: backButtonToolTip,
                }
              : undefined
          }
          style={style}
          title={isTraceStatement ? <FormattedMessage id="traceEventStatements" /> : ""}
          {...props}
        >
          <DataTable
            columnResizeMode="expand"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
            dataKey="textData"
            filterDisplay="menu"
            filters={state.filters}
            first={state.first}
            header={isTraceStatement ? <></> : header}
            lazy={!isTraceStatement}
            loading={isLoading}
            onFilter={onFilter}
            onPage={onPage}
            onSelectionChange={(e) => onRowSelected(e.value)}
            onSort={isTraceStatement ? undefined : onSort}
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            resizableColumns
            responsiveLayout="scroll"
            rows={state.rows}
            rowsPerPageOptions={GRID_PAGINATIONROW_OPTIONS}
            selectionMode="single"
            size="small"
            sortField={state.sortField}
            sortOrder={state.sortOrder}
            totalRecords={totalCount}
            value={[...data]}
          >
            <Column
              field="eventClass"
              filter={isTraceStatement ? false : true}
              filterElement={eventClassFilterTemplate}
              header={<FormattedMessage id="eventClass" />}
              showAddButton={false}
              showFilterMatchModes={false}
              showFilterOperator={false}
              sortable
            />
            <Column
              body={(d) => {
                return navigator.clipboard ? (
                  <div className={classes.copybtn}>
                    <QueryTooltip placement="top-start" title={d.textData}>
                      <span className={classes.queryWrap}>{d.textData}</span>
                    </QueryTooltip>
                    <FileCopyOutlinedIcon
                      aria-label="copy-btn"
                      className={classes.cursorPointer}
                      onClick={() => copyQuery(d)}
                    />
                  </div>
                ) : (
                  <QueryTooltip placement="top-start" title={d.textData}>
                    <span>{d.textData}</span>
                  </QueryTooltip>
                );
              }}
              field="textData"
              header={<FormattedMessage id="textData" />}
              sortable
            />
            <Column
              field="hostName"
              filter={isTraceStatement ? false : true}
              filterElement={hostNameFilterTemplate}
              header={<FormattedMessage id="hostName" />}
              showAddButton={false}
              showFilterMatchModes={false}
              showFilterOperator={false}
              sortable
            />
            <Column
              field="applicationName"
              filter={isTraceStatement ? false : true}
              filterElement={applicationNameFilterTemplate}
              header={<FormattedMessage id="applicationName" />}
              showAddButton={false}
              showFilterMatchModes={false}
              showFilterOperator={false}
              sortable
            />
            <Column
              field="databaseName"
              filter={isTraceStatement ? false : true}
              filterElement={databaseNameFilterTemplate}
              header={<FormattedMessage id="databaseName" />}
              showAddButton={false}
              showFilterMatchModes={false}
              showFilterOperator={false}
              sortable
            />
            <Column
              field="login"
              filter={isTraceStatement ? false : true}
              filterElement={loginNameFilterTemplate}
              header={<FormattedMessage id="login" />}
              showAddButton={false}
              showFilterMatchModes={false}
              showFilterOperator={false}
              sortable
            />
            <Column field="duration" header={<FormattedMessage id="duration" />} sortable />
            <Column
              align="center"
              body={(d: IExecutedQueryTraceEventsResponse) =>
                d.waitMS ? (
                  <TraceWaitsModal
                    eventSourceConnectionId={eventSourceConnectionId}
                    trace_record={d}
                    wait_ms={d.waitMS}
                  ></TraceWaitsModal>
                ) : (
                  0
                )
              }
              field="waitMS"
              header={<FormattedMessage id="waitsMS" />}
              sortable
            />
            <Column field="cpu" header={<FormattedMessage id="cpuMs" />} sortable />
            <Column field="reads" header={<FormattedMessage id="readsL" />} sortable />
            <Column field="writes" header={<FormattedMessage id="writesP" />} sortable />
            <Column
              body={(alert) => {
                if (alert.startTime && Date.parse(alert.startTime)) {
                  return (
                    <FormattedDate
                      day="numeric"
                      hour="2-digit"
                      minute="numeric"
                      month="numeric"
                      second="numeric"
                      value={alert.startTime}
                      year="numeric"
                    />
                  );
                }
                return null;
              }}
              className={classes.noWrap}
              field="startTime"
              header={<FormattedMessage id="startTime" />}
              sortable
            />
            <Column
              body={(alert) => {
                if (alert.endTime && Date.parse(alert.endTime)) {
                  return (
                    <FormattedDate
                      day="numeric"
                      hour="2-digit"
                      minute="numeric"
                      month="numeric"
                      second="numeric"
                      value={alert.endTime}
                      year="numeric"
                    />
                  );
                }
                return null;
              }}
              className={classes.noWrap}
              field="endTime"
              header={<FormattedMessage id="endTime" />}
              sortable
            />
            <Column field="integerData" header={<FormattedMessage id="integerData" />} sortable />
            <Column
              body={(d) => {
                switch (d.error) {
                  case -1:
                    return <FormattedMessage id="requestCompleted" />;
                  case 0:
                    return <FormattedMessage id="ok" />;
                  case 1:
                    return <FormattedMessage id="error" />;
                  case 2:
                    return <FormattedMessage id="abort" />;
                  case 3:
                    return <FormattedMessage id="skipped" />;
                  default:
                    return <FormattedMessage id="unknown" />;
                }
              }}
              field="error"
              filter={isTraceStatement ? false : true}
              filterElement={errorFilterTemplate}
              header={<FormattedMessage id="error" />}
              showAddButton={false}
              showFilterMatchModes={false}
              showFilterOperator={false}
              sortable
            />
            <Column
              field="spid"
              filter={isTraceStatement ? false : true}
              filterElement={spidFilterTemplate}
              header={<FormattedMessage id="spid" />}
              showAddButton={false}
              showFilterMatchModes={false}
              showFilterOperator={false}
              sortable
            />
            <Column field="hostProcessId" header={<FormattedMessage id="hostProcessId" />} sortable />
            {
              // render tempdb and memory-related columns only in trace events grid.
              !isTraceStatement && tempdbMemoryRelatedColumns
            }
          </DataTable>
        </CardTable>
      </div>
    );
  },
);

export default TraceEventsGrid;
