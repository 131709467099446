import NoDataIndicator from "../../../../components/NoDataIndicator";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { LoadingIndicator } from "@sentryone/material-ui";
import * as React from "react";
import { useIntl } from "react-intl";
import { useDeadlocksContext, IDeadlockProcess } from "../../../../contexts/deadlocksContext";

const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: 0,
  },
  loading: {
    flexGrow: 1,
    height: 0,
    maxWidth: "100%",
    minHeight: "5rem",
  },
  noWrap: {
    maxWidth: theme.spacing() * 30,
    minWidth: 0,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  nodeText: {
    fontFamily: `'Roboto', sans-serif`,
    lineHeight: "1.3rem",
    minHeight: "3rem",
    resize: "vertical",
    whiteSpace: "pre-wrap",
    width: "100%",
  },
  tableRoot: {
    "& td": {
      verticalAlign: "middle",
    },
  },
  title: {
    alignSelf: "center",
    display: "flex",
    fontWeight: 600,
    paddingLeft: theme.spacing() * 2,
    verticalAlign: "bottom",
  },
}));

const DeadlockNodeGrid: React.FunctionComponent = () => {
  const classes = useStyles();
  const { deadlockInfo, selectedNode, loading, loadingDeadlock, error } = useDeadlocksContext();
  const intl = useIntl();

  // If selectedNode is a resource,. we will simply display a no data message
  const data = (selectedNode as IDeadlockProcess)?.executionStack ?? [];
  return (
    <Table className={classes.tableRoot} size="small">
      <caption>{intl.formatMessage({ id: "callStack" })}</caption>
      <TableHead>
        <TableRow>
          <TableCell>{intl.formatMessage({ id: "object" })}</TableCell>
          <TableCell>{intl.formatMessage({ id: "lineNumber" })}</TableCell>
          <TableCell>{intl.formatMessage({ id: "textData" })}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loading || !deadlockInfo || loadingDeadlock ? (
          <TableRow>
            <TableCell colSpan={3}>
              <LoadingIndicator className={classes.loading} variant="table" />
            </TableCell>
          </TableRow>
        ) : error || !data.length ? (
          <TableRow>
            <TableCell colSpan={3}>
              <NoDataIndicator />
            </TableCell>
          </TableRow>
        ) : (
          data.map((row) => (
            <TableRow key={row.sqlHandle}>
              <TableCell className={classes.noWrap} title={row.processName}>
                {row.processName}
              </TableCell>
              <TableCell>{intl.formatNumber(row.line)}</TableCell>
              <TableCell>
                <textarea className={classes.nodeText} disabled value={row.text} />
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
};

export default DeadlockNodeGrid;
