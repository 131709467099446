import { ApolloClient } from "apollo-boost";
import { DateTime } from "luxon";
import { TOTAL_INSTANCE } from "../../../../../../components/MetricChart";
import { ITopologyItemDevice, ITopologyItemEventSourceConnection } from "../../../../../../components/TopologyContext";
import { MoRef } from "../../../../../../utilities/TopologyUtility";
import { ICustomChartConfig, ICustomDashboardChartSeries } from "../../../../types";
import { fetchMetricInstances } from "../../../CustomChartModal/CustomChartQueries";
import { getColorGenerator } from "./TemplateColorGenerator";

export interface ICustomChartTemplate extends Omit<ICustomChartConfig, "performanceCounters"> {
  performanceCounters: PerformanceCounterThunk;
}

function getSortOrder(series: ICustomDashboardChartSeries): number {
  switch (series.instance) {
    case "Other":
      return Number.POSITIVE_INFINITY;
    default:
      return 0;
  }
}

async function getMetricInstances(
  apolloClient: ApolloClient<any>,
  metric: string,
  moRef: MoRef,
  signal: AbortSignal,
): Promise<Array<string>> {
  const instances = await fetchMetricInstances(
    apolloClient,
    {
      dateRange: {
        from: DateTime.utc().minus({ hour: 1 }).toJSDate().toISOString(),
        to: DateTime.utc().toJSDate().toISOString(),
      },
      key: metric,
      moRef: moRef.toString(),
    },
    signal,
  );
  return instances;
}

const defaultMoRef = new MoRef("connection", "0");

export type PerformanceCounterThunk =
  | ((
      target: ITopologyItemDevice | ITopologyItemEventSourceConnection,
      client: ApolloClient<any>,
      signal: AbortSignal,
    ) => Promise<ICustomDashboardChartSeries[]>)
  | ICustomDashboardChartSeries[];

//moref id will not be provided for these templates since the user will select that after adding a template
export const CustomChartTemplates: Record<string, ICustomChartTemplate> = {
  ["azureSqlDbActivity"]: {
    chartName: "sqlServerActivity",
    customTimeRange: null,
    performanceCounters: [
      {
        axisLabel: null,
        chartType: "line",
        color: "#3CB371",
        instance: null,
        label: "transactions",
        metric: "sqlserver.transactions",
        moRef: defaultMoRef,
      },
    ],
  },
  ["azureSqlDbDatabaseIO"]: {
    chartName: "databaseIO",
    customTimeRange: null,
    performanceCounters: [
      {
        axisLabel: null,
        chartType: "line",
        color: "#98FB98",
        instance: "Total",
        label: "msRead",
        metric: "sqlserver.reads",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "line",
        color: "#20B2AA",
        instance: "Total",
        label: "msWrite",
        metric: "sqlserver.writes",
        moRef: defaultMoRef,
      },
    ],
  },
  ["azureSqlDbDatabaseSize"]: {
    chartName: "databaseSize",
    customTimeRange: null,
    performanceCounters: [
      {
        axisLabel: null,
        chartType: "stackedArea",
        color: "#A62DC4",
        instance: null,
        label: "usedSpace",
        metric: "azure.sqldb.size.used",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "stackedArea",
        color: "#DDDDDD",
        instance: null,
        label: "freeSpace",
        metric: "azure.sqldb.size.free",
        moRef: defaultMoRef,
      },
    ],
  },
  ["azureSqlDbMemoryUsage"]: {
    chartName: "memoryUsage",
    customTimeRange: null,
    performanceCounters: [
      {
        axisLabel: null,
        chartType: "stackedArea",
        color: "#4169E1",
        instance: null,
        label: "allocatedMemoryUsage",
        metric: "azure.sqldb.dtu.memory.pct",
        moRef: defaultMoRef,
      },
    ],
  },
  ["azureSqlDbResourceUsage"]: {
    chartName: "resourceUsage",
    customTimeRange: null,
    performanceCounters: [
      {
        axisLabel: null,
        chartType: "line",
        color: "#0D47A1",
        instance: null,
        label: "totalDtuPercent",
        metric: "azure.sqldb.dtu.total.pct",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "line",
        color: "#FF8C00",
        instance: null,
        label: "dataIO",
        metric: "azure.sqldb.dtu.dataio.pct",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "line",
        color: "#FF4500",
        instance: null,
        label: "logIO",
        metric: "azure.sqldb.dtu.logio.pct",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "line",
        color: "#32CD32",
        instance: null,
        label: "cpuPercent",
        metric: "azure.sqldb.dtu.cpu.pct",
        moRef: defaultMoRef,
      },
    ],
  },
  ["azureSqlDbWaits"]: {
    chartName: "sqlDatabaseWaits",
    customTimeRange: null,
    performanceCounters: async (
      defaultTarget: ITopologyItemDevice | ITopologyItemEventSourceConnection,
      client: ApolloClient<any>,
      signal: AbortSignal,
    ): Promise<ICustomDashboardChartSeries[]> => {
      const knownColorMap: ReadonlyMap<string, string> = new Map<string, string>([
        ["CPU", "#00C853"],
        ["Disk", "#FF8C00"],
        ["Memory", "#4169E1"],
        ["Network", "#00FFFF"],
        ["Other", "#808080"],
      ]);
      const colors = getColorGenerator();
      const metric = "sqlserver.waits.category.ms";
      const moRef = MoRef.fromTopologyItem(defaultTarget);
      const instances = await getMetricInstances(client, metric, moRef, signal);
      const performanceCounters: ICustomDashboardChartSeries[] =
        instances.length > 0
          ? instances
              .map((instance) => {
                const series: ICustomDashboardChartSeries = {
                  axisLabel: null,
                  chartType: "stackedArea",
                  color: knownColorMap.get(instance) ?? colors.next().value,
                  instance: instance,
                  label: `${instance}`,
                  metric: metric,
                  moRef: moRef,
                };
                return series;
              })
              .sort((a, b) => {
                return getSortOrder(a) - getSortOrder(b);
              })
          : [
              {
                axisLabel: null,
                chartType: "stackedArea",
                color: colors.next().value,
                instance: null,
                label: "waits",
                metric: metric,
                moRef: moRef,
              },
            ];
      return performanceCounters;
    },
  },
  ["blankTemplate"]: {
    chartName: "customChart",
    customTimeRange: null,
    performanceCounters: [],
  },
  ["osCPU"]: {
    chartName: "cpu",
    customTimeRange: null,
    performanceCounters: async (
      defaultTarget: ITopologyItemDevice | ITopologyItemEventSourceConnection,
      client: ApolloClient<any>,
      signal: AbortSignal,
    ): Promise<ICustomDashboardChartSeries[]> => {
      const knownColorMap: ReadonlyMap<string, string> = new Map<string, string>([["Other", "#00C853"]]);
      const colors = getColorGenerator();
      const metric = "os.cpu.processorTime.sqlInstances.pct";
      const moRef = MoRef.fromTopologyItem(defaultTarget);
      const instances = await getMetricInstances(client, metric, moRef, signal);
      return instances.length > 0
        ? instances
            .map((instance) => {
              const series: ICustomDashboardChartSeries = {
                axisLabel: null,
                chartType: "stackedArea",
                color: knownColorMap.get(instance) ?? colors.next().value,
                instance: instance,
                label: `${instance}`,
                metric: metric,
                moRef: moRef,
              };
              return series;
            })
            .sort((a, b) => {
              return getSortOrder(a) - getSortOrder(b);
            })
        : [
            {
              axisLabel: null,
              chartType: "stackedArea",
              color: colors.next().value,
              instance: null,
              label: "cpu",
              metric: metric,
              moRef: moRef,
            },
          ];
    },
  },
  ["osDiskIO"]: {
    chartName: "disk",
    customTimeRange: null,
    performanceCounters: [
      {
        axisLabel: null,
        chartType: "line",
        color: "#98FB98",
        instance: "_Total",
        label: "msRead",
        metric: "os.disk.read.ms",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "line",
        color: "#20B2AA",
        instance: "_Total",
        label: "msWrite",
        metric: "os.disk.write.ms",
        moRef: defaultMoRef,
      },
    ],
  },
  ["osSystemMemory"]: {
    chartName: "systemMemory",
    customTimeRange: null,
    performanceCounters: [
      {
        axisLabel: null,
        chartType: "stackedArea",
        color: "#5F9EA0",
        instance: TOTAL_INSTANCE,
        label: "sqlServer",
        metric: "os.memory.sqlInstances.size",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "stackedArea",
        color: "#B0C4DE",
        instance: null,
        label: "fileCache",
        metric: "os.memory.fileCache.size",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "stackedArea",
        color: "#808080",
        instance: null,
        label: "other",
        metric: "os.memory.other.size",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "stackedArea",
        color: "#194537",
        instance: null,
        label: "available",
        metric: "os.memory.available.size",
        moRef: defaultMoRef,
      },
    ],
  },
  ["sqlServerActivity"]: {
    chartName: "sqlServerActivity",
    customTimeRange: null,
    performanceCounters: [
      {
        axisLabel: null,
        chartType: "line",
        color: "#00BFFF",
        instance: null,
        label: "batches",
        metric: "sqlserver.sqlstatistics.batches.ps",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "line",
        color: "#FF7F50",
        instance: null,
        label: "compiles",
        metric: "sqlserver.sqlstatistics.compiles.ps",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "line",
        color: "#FF4500",
        instance: null,
        label: "recompiles",
        metric: "sqlserver.sqlstatistics.recompiles.ps",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "line",
        color: "#3CB371",
        instance: null,
        label: "transactions",
        metric: "sqlserver.transactions",
        moRef: defaultMoRef,
      },
    ],
  },
  ["sqlServerDatabaseIO"]: {
    chartName: "databaseIO",
    customTimeRange: null,
    performanceCounters: [
      {
        axisLabel: null,
        chartType: "stackedArea",
        color: "#46f3c6",
        instance: "Total",
        label: "msRead",
        metric: "sqlserver.reads",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "stackedArea",
        color: "#e9a05d",
        instance: "Total",
        label: "msWrite",
        metric: "sqlserver.writes",
        moRef: defaultMoRef,
      },
    ],
  },
  ["sqlServerMemory"]: {
    chartName: "sqlServerMemory",
    customTimeRange: null,
    performanceCounters: [
      {
        axisLabel: null,
        chartType: "stackedArea",
        color: "#A7A1F1",
        instance: TOTAL_INSTANCE,
        label: "buffer",
        metric: "sqlserver.bufferNode.database.size",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "stackedArea",
        color: "#B34700",
        instance: TOTAL_INSTANCE,
        label: "stolenBuffer",
        metric: "sqlserver.memoryNode.stolen.size",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "stackedArea",
        color: "#FF7F50",
        instance: TOTAL_INSTANCE,
        label: "inMemOltp",
        metric: "sqlserver.memoryClerks.inMemoryOltp.size",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "stackedArea",
        color: "#194537",
        instance: TOTAL_INSTANCE,
        label: "free",
        metric: "sqlserver.memoryNode.free.size",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "stackedArea",
        color: "#4169E1",
        instance: "SQL Plans",
        label: "planSql",
        metric: "sqlserver.planCache.size",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "stackedArea",
        color: "#00BFFF",
        instance: "Object Plans",
        label: "planObjects",
        metric: "sqlserver.planCache.size",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "stackedArea",
        color: "#FFA500",
        instance: TOTAL_INSTANCE,
        label: "columnStore",
        metric: "sqlserver.memoryClerks.columnstore.size",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "stackedArea",
        color: "#808080",
        instance: null,
        label: "other",
        metric: "sqlserver.memory.other",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "line",
        color: "#FF0000",
        instance: null,
        label: "queryGrants",
        metric: "sqlserver.memoryManager.grants.size",
        moRef: defaultMoRef,
      },
    ],
  },
  ["sqlServerWaits"]: {
    chartName: "sqlServerWaits",
    customTimeRange: null,
    performanceCounters: async (
      defaultTarget: ITopologyItemDevice | ITopologyItemEventSourceConnection,
      client: ApolloClient<any>,
      signal: AbortSignal,
    ): Promise<ICustomDashboardChartSeries[]> => {
      const knownColorMap: ReadonlyMap<string, string> = new Map<string, string>([
        ["CPU", "#00C853"],
        ["Disk", "#FF8C00"],
        ["Memory", "#4169E1"],
        ["Network", "#00FFFF"],
        ["Other", "#808080"],
      ]);
      const colors = getColorGenerator();
      const metric = "sqlserver.waits.category.ms";
      const moRef = MoRef.fromTopologyItem(defaultTarget);
      const instances = await getMetricInstances(client, metric, moRef, signal);
      const performanceCounters: ICustomDashboardChartSeries[] =
        instances.length > 0
          ? instances
              .map((instance) => {
                const series: ICustomDashboardChartSeries = {
                  axisLabel: null,
                  chartType: "stackedArea",
                  color: knownColorMap.get(instance) ?? colors.next().value,
                  instance: instance,
                  label: `${instance}`,
                  metric: metric,
                  moRef: moRef,
                };
                return series;
              })
              .sort((a, b) => {
                return getSortOrder(a) - getSortOrder(b);
              })
          : [
              {
                axisLabel: null,
                chartType: "stackedArea",
                color: colors.next().value,
                instance: null,
                label: "waits",
                metric: metric,
                moRef: moRef,
              },
            ];
      return performanceCounters;
    },
  },
  ["vmwareCPUGuest"]: {
    chartName: "vmwareCPUGuest",
    customTimeRange: null,
    performanceCounters: async (
      defaultTarget: ITopologyItemDevice | ITopologyItemEventSourceConnection,
      client: ApolloClient<any>,
      signal: AbortSignal,
    ): Promise<ICustomDashboardChartSeries[]> => {
      const knownColorMap: ReadonlyMap<string, string> = new Map<string, string>([["All", "#46f3c6"]]);
      const colors = getColorGenerator();
      const metric = "vmware.guests.processorTime.pct";
      const moRef = MoRef.fromTopologyItem(defaultTarget);
      const instances = await getMetricInstances(client, metric, moRef, signal);
      return instances.length > 0
        ? instances
            .map((instance) => {
              const series: ICustomDashboardChartSeries = {
                axisLabel: null,
                chartType: "stackedArea",
                color: knownColorMap.get(instance) ?? colors.next().value,
                instance: instance,
                label: `${instance}`,
                metric: metric,
                moRef: moRef,
              };
              return series;
            })
            .sort((a, b) => {
              return getSortOrder(a) - getSortOrder(b);
            })
        : [
            {
              axisLabel: null,
              chartType: "stackedArea",
              color: colors.next().value,
              instance: null,
              label: "vmwareProcessorTime",
              metric: metric,
              moRef: moRef,
            },
          ];
    },
  },
  ["vmwareCPUHost"]: {
    chartName: "vmwareCPUHost",
    customTimeRange: null,
    performanceCounters: [
      {
        axisLabel: null,
        chartType: "line",
        color: "#32CD32",
        instance: TOTAL_INSTANCE,
        label: "vmwareProcessorTime",
        metric: "vmware.host.processorTime.pct",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "line",
        color: "#B22222",
        instance: TOTAL_INSTANCE,
        label: "vmwareReadyTime",
        metric: "vmware.host.readyTime.pct",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "line",
        color: "#FFFF00",
        instance: TOTAL_INSTANCE,
        label: "vmwareCostop",
        metric: "vmware.host.costop.pct",
        moRef: defaultMoRef,
      },
    ],
  },
  ["vmwareDiskGuest"]: {
    chartName: "vmwareDiskGuest",
    customTimeRange: null,
    performanceCounters: [
      {
        axisLabel: null,
        chartType: "stackedArea",
        color: "#98FB98",
        instance: "All",
        label: "msRead",
        metric: "vmware.guests.disk.read.ms",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "stackedArea",
        color: "#20B2AA",
        instance: "All",
        label: "msWrite",
        metric: "vmware.guests.disk.write.ms",
        moRef: defaultMoRef,
      },
    ],
  },
  ["vmwareDiskHost"]: {
    chartName: "vmwareDiskHost",
    customTimeRange: null,
    performanceCounters: [
      {
        axisLabel: null,
        chartType: "stackedArea",
        color: "#98FB98 ",
        instance: "Total",
        label: "msRead",
        metric: "vmware.host.disk.read.ms",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "stackedArea",
        color: "#20B2AA",
        instance: "Total",
        label: "msWrite",
        metric: "vmware.host.disk.write.ms",
        moRef: defaultMoRef,
      },
    ],
  },
  ["vmwareSystemMemoryGuest"]: {
    chartName: "vmwareSystemMemoryGuest",
    customTimeRange: null,
    performanceCounters: async (
      defaultTarget: ITopologyItemDevice | ITopologyItemEventSourceConnection,
      client: ApolloClient<any>,
      signal: AbortSignal,
    ): Promise<ICustomDashboardChartSeries[]> => {
      const knownColorMap: ReadonlyMap<string, string> = new Map<string, string>([["All", "#46f3c6"]]);
      const colors = getColorGenerator();
      const metric = "vmware.guests.memory.size";
      const moRef = MoRef.fromTopologyItem(defaultTarget);
      const instances = await getMetricInstances(client, metric, moRef, signal);
      return instances.length > 0
        ? instances
            .map((instance) => {
              const series: ICustomDashboardChartSeries = {
                axisLabel: null,
                chartType: "stackedArea",
                color: knownColorMap.get(instance) ?? colors.next().value,
                instance: instance,
                label: `${instance}`,
                metric: metric,
                moRef: moRef,
              };
              return series;
            })
            .sort((a, b) => {
              return getSortOrder(a) - getSortOrder(b);
            })
        : [
            {
              axisLabel: null,
              chartType: "stackedArea",
              color: colors.next().value,
              instance: null,
              label: "msRead",
              metric: metric,
              moRef: moRef,
            },
          ];
    },
  },
  ["vmwareSystemMemoryHost"]: {
    chartName: "vmwareSystemMemoryHost",
    customTimeRange: null,
    performanceCounters: [
      {
        axisLabel: null,
        chartType: "stackedArea",
        color: "#7B68EE",
        instance: TOTAL_INSTANCE,
        label: "vmwareActiveSize",
        metric: "vmware.host.memory.active.size",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "stackedArea",
        color: "#FFA07A",
        instance: TOTAL_INSTANCE,
        label: "vmwareConsumedSize",
        metric: "vmware.host.memory.consumed.size",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "stackedArea",
        color: "#F0E68C",
        instance: TOTAL_INSTANCE,
        label: "vmwareOverheadSize",
        metric: "vmware.host.memory.overhead.size",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "stackedArea",
        color: "#5F9EA0",
        instance: "Available",
        label: "vmwareAvailableSize",
        metric: "vmware.host.memory.available.size",
        moRef: defaultMoRef,
      },
      {
        axisLabel: null,
        chartType: "stackedArea",
        color: "#B0C4DE",
        instance: TOTAL_INSTANCE,
        label: "vmwareGrantedSize",
        metric: "vmware.host.memory.granted.size",
        moRef: defaultMoRef,
      },
    ],
  },
};
