import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { useIntl } from "react-intl";
import { IPrincipal, IUser, IUserGroup } from "../../types";
import { makeStyles } from "@material-ui/core/styles";

export interface IPrincipalConfirmationDialogProps {
  handleCloseUser: (confirmed: boolean, principalId: string) => void;
  handleCloseUserGroup: (confirmed: boolean, principalId: string) => void;
  open: boolean;
  principal: IPrincipal;
}

const useStyles = makeStyles((theme) => ({
  confirmationText: {
    marginBottom: `${theme.spacing(1)}px`,
  },
  principalNameText: {
    fontWeight: "bold",
    marginBottom: `${theme.spacing(4)}px`,
  },
  textContent: {
    paddingBottom: `${theme.spacing(1)}px`,
  },
}));

const PrincipalConfirmationDialog: React.FC<IPrincipalConfirmationDialogProps> = ({
  handleCloseUser,
  handleCloseUserGroup,
  open,
  principal,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const principalType = "name" in principal ? "groups" : "users";
  const handleClosePrincipal = (principalType: "groups" | "users", confirmed: boolean, principalId: string):void => {
    if (principalType === "users") {
      handleCloseUser(confirmed, principalId);
    } else {
      handleCloseUserGroup(confirmed, principalId);
    }
  };
  const confirmationMessage =
    principalType === "groups"
      ? `${intl.formatMessage({ id: "areYouSureDeleteGroup" })} `
      : `${intl.formatMessage({ id: "areYouSureDeleteUser" })}`;
  const principalName =
    principalType === "groups"
      ? (principal as IUserGroup).name
      : `${(principal as IUser).firstName} ${(principal as IUser).lastName}`;
  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle>
        {principalType === "users"
          ? intl.formatMessage({ id: "deleteUser" })
          : intl.formatMessage({ id: "deleteGroup" })}
      </DialogTitle>
      <DialogContent className={classes.textContent}>
        <DialogContentText className={classes.confirmationText}>{confirmationMessage}</DialogContentText>
        <DialogContentText className={classes.principalNameText}>{principalName}</DialogContentText>
        <DialogContentText className={classes.confirmationText}>
          {intl.formatMessage({ id: "thisActionCannotBeUndone" })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClosePrincipal(principalType, false, principal.id)}>
          {intl.formatMessage({ id: "cancel" })}
        </Button>
        <Button
          color="secondary"
          onClick={() => handleClosePrincipal(principalType, true, principal.id)}
          variant="contained"
        >
          {intl.formatMessage({ id: "delete" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PrincipalConfirmationDialog;
