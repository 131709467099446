import React from "react";
import { paletteOptions } from "../../components/ThemeProvider/palette";

const MiniHealthScoreCard: React.FC<{
  score: number,
  score_size: 'small' | 'large'
}> = ({score, score_size}) => {
  
  let font_size = '4.5rem';
  let show_title = true;
  let height = '50%';

  if (score_size == 'small'){
    font_size = '2.8rem';
    show_title = false;
    height = '100%';
  }
  
  return (
    <div style={{
        backgroundColor: paletteOptions.getColorForHealthScore(score),
        color: 'white',
        height: height,
        textAlign: 'center',
        textShadow: '1px 1px gray',
    }}>
        {show_title && (<div style={{fontSize: '0.7rem', paddingTop: '10px'}}>Overall Health Score</div>)}
        <div style={{padding: 0}}>
          <span style={{
              fontSize: font_size,
              textShadow: '2px 2px gray',
          }}>{score}</span>/100
        </div>
    </div>
  );
};

export default MiniHealthScoreCard;