import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { OverrideProps } from "@material-ui/core/OverridableComponent";
import { makeStyles } from "@material-ui/core/styles";
import { TypographyTypeMap } from "@material-ui/core/Typography/Typography";
import * as React from "react";
import { useLocation, useRouteMatch } from "react-router";
import { NavLink, NavLinkProps } from "react-router-dom";

interface IFeatureLinkButton {
  className: string;
  disableNavigation?: boolean;
  icon: React.ReactElement;
  iconClassName: string;
  label?: React.ReactNode;
  listItemTypographyProps: Partial<OverrideProps<TypographyTypeMap<unknown, "span">, "span">>;
  to: string;
}

const useStyles = makeStyles({
  activeTab: {},
  root: {
    "&$activeTab$activeTab": {
      "& .MuiListItemIcon-root": {
        color: "var(--sidebar-dark-item-active-color)",
      },
      "& .MuiListItemText-root.MuiListItemText-dense": {
        "& .MuiTypography-root": {
          color: "var(--sidebar-dark-item-active-color) !important",
        },
      },
      backgroundColor: "var(--sidebar-dark-item-active-background)",
      borderColor: "var(--sidebar-dark-item-active-border)",
    },
    borderLeft: "solid 4px transparent",
    borderRadius: "0",
  },
});

const LinkComponent = React.forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => {
  return <NavLink {...props} innerRef={ref} />;
});

const FeatureLinkButton: React.FunctionComponent<IFeatureLinkButton> = ({
  disableNavigation,
  icon,
  label,
  listItemTypographyProps,
  to,
  ...props
}) => {
  const classes = useStyles();
  const location = useLocation();
  const match = useRouteMatch(to);

  const aria: Record<string, string> = {};
  if (match) {
    aria["aria-current"] = "page";
    aria["aria-selected"] = "true";
  } else {
    aria["aria-selected"] = "false";
  }

  const navLinkProps = {
    to: {
      pathname: disableNavigation ? undefined : to,
      search: location.search,
    },
  };

  return (
    <ListItem
      button
      className={props.className}
      classes={{
        root: classes.root,
        selected: classes.activeTab,
      }}
      component={LinkComponent as any}
      selected={!!match}
      {...aria}
      {...navLinkProps}
    >
      <ListItemIcon className={props.iconClassName}>{icon}</ListItemIcon>
      <ListItemText primaryTypographyProps={listItemTypographyProps}>{label}</ListItemText>
    </ListItem>
  );
};

export default FeatureLinkButton;
