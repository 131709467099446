import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import Alert from "@material-ui/lab/Alert";
import { DateTime, DurationObject } from "luxon";
import * as React from "react";
import { useIntl } from "react-intl";
import { IDateRange } from "../../../../components/DateContext";
import ErrorBoundary from "../../../../components/ErrorBoundary";
import MetricChart, { useMetrics } from "../../../../components/MetricChart";
import {
  ITopologyItemDevice,
  ITopologyItemEventSourceConnection,
  TopologyDeviceType,
  TopologyEventSourceConnectionType,
  TopologyObjectType,
} from "../../../../components/TopologyContext";
import { MoRef } from "../../../../utilities/TopologyUtility";
import { ICustomChartConfig, ICustomDashboardChartSeries } from "../../types";
import { CustomTimeRangeUnit } from "../../useDashboards";
import CustomChartModalTooltip from "./components/CustomChartModalTooltip";
import PerformanceCounterSelection from "./PerformanceCounterSelection";

export interface ICustomDashboardChartSeriesComplete extends ICustomDashboardChartSeries {
  moRef: MoRef;
}

export interface ICustomChartConfigComplete extends ICustomChartConfig {
  performanceCounters: ICustomDashboardChartSeriesComplete[];
}

export interface ICustomChartModalProps {
  handleClose: (newChartConfig: ICustomChartConfigComplete | null) => void;
  initialChartConfig: ICustomChartConfig;
  open: boolean;
  targets: Array<ITopologyItemDevice | ITopologyItemEventSourceConnection>;
}

const useStyles = makeStyles((theme) => ({
  actionBtns: {
    padding: theme.spacing(2, 4),
  },
  addMetricBtn: {
    marginLeft: theme.spacing(3),
  },
  chartDetails: {
    "& > :first-child": {
      flex: 1,
    },
    "& > :last-child": {
      flex: 2,
    },
    display: "flex",
    gap: `${theme.spacing(2)}px`,
  },
  content: {
    overflowX: "hidden",
    padding: theme.spacing(2),
  },
  customTimeRangeSwitch: {
    marginLeft: theme.spacing(1),
    marginRight: 0,
  },
  dialogTitle: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  extraInfoIcon: {
    margin: theme.spacing(0, 2, 0, 1),
  },
  flexAlignCenter: {
    alignItems: "center",
    display: "flex",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: `${theme.spacing(2)}px`,
  },
  infoAlertText: {
    margin: "auto",
  },
  lastLabel: {
    marginLeft: theme.spacing(5),
  },
  metricChartContainer: {
    display: "grid",
    gridTemplateRows: "250px",
  },
  performanceCounterHelpText: {
    color: theme.palette.text.hint,
    marginLeft: theme.spacing(2),
  },
  performanceCountersSection: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(1, 0, 2, 0),
  },
  performanceCountersToolbar: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(0, 2),
  },
  previewHeaderOptionsSelect: {
    paddingTop: "10px",
  },
  sectionLabel: {
    color: theme.typography.h5.color,
    fontSize: "1.1rem",
    fontWeight: theme.typography.h2.fontWeight,
  },
  targetIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  timeRangeAmountTextField: {
    margin: theme.spacing(0, 2),
    width: "6rem",
  },
}));

const defaultChartType = "line";

function getDefaultPerformanceCounterForTargetType(
  target: ITopologyItemDevice | ITopologyItemEventSourceConnection,
): ICustomDashboardChartSeries[] {
  if (target.type === TopologyObjectType.eventSourceConnection) {
    if (target.eventSourceConnectionType === TopologyEventSourceConnectionType.AzureSqlDatabase) {
      return [
        {
          chartType: defaultChartType,
          color: "#0D47A1",
          instance: null,
          label: null,
          metric: "azure.sqldb.dtu.total.pct",
          moRef: MoRef.fromTopologyItem(target),
        },
      ];
    } else {
      return [
        {
          chartType: defaultChartType,
          color: "#00BFFF",
          instance: null,
          label: null,
          metric: "sqlserver.sqlstatistics.batches.ps",
          moRef: MoRef.fromTopologyItem(target),
        },
      ];
    }
  } else {
    if (target.deviceType === TopologyDeviceType.VMWare) {
      return [
        {
          chartType: defaultChartType,
          color: "#32CD32",
          instance: null,
          label: null,
          metric: "vmware.host.processorTime.pct",
          moRef: MoRef.fromTopologyItem(target),
        },
      ];
    } else {
      return [
        {
          chartType: defaultChartType,
          color: "#00C853",
          instance: null,
          label: null,
          metric: "os.cpu.processorTime.pct",
          moRef: MoRef.fromTopologyItem(target),
        },
      ];
    }
  }
}

const CustomChartModal: React.FC<ICustomChartModalProps> = ({ handleClose, initialChartConfig, open, targets }) => {
  const classes = useStyles();
  const intl = useIntl();
  const genericChartColors = useTheme().palette.genericChartColors;

  // helper state
  const [loadingMetrics, setLoadingMetrics] = React.useState<boolean>(false);
  const [metricsError, setMetricsError] = React.useState<boolean>(false);
  const [metricDateRange, setMetricDateRange] = React.useState<IDateRange>({
    from: DateTime.utc().minus({ hour: 1 }).toJSDate(),
    to: DateTime.utc().toJSDate(),
  });

  const [chartName, setChartName] = React.useState<string>(initialChartConfig.chartName ?? "");
  const [target, setTarget] = React.useState<ITopologyItemDevice | ITopologyItemEventSourceConnection>(targets[0]);
  const [performanceCounters, setPerformanceCounters] = React.useState<ICustomDashboardChartSeries[]>(
    initialChartConfig.performanceCounters.length > 0
      ? initialChartConfig.performanceCounters.slice(0, 10) //Added this slice because we need to show first 10 performanceCounters only
      : getDefaultPerformanceCounterForTargetType(initialChartConfig.target ?? target),
  );

  const [useCustomTimeRange, setUseCustomTimeRange] = React.useState<boolean>(!!initialChartConfig.customTimeRange);
  const [customTimeRange, setCustomTimeRange] = React.useState<{ unit: CustomTimeRangeUnit; value: number }>({
    unit: initialChartConfig.customTimeRange?.unit ?? CustomTimeRangeUnit.Hours,
    value: initialChartConfig.customTimeRange?.value ?? 1,
  });
  const [metricTimeRange, setMetricTimeRange] = React.useState<"1hr" | "1day" | "7days">("1hr");

  const getInactiveColors = (): string[] =>
    genericChartColors.filter((color) => !performanceCounters.some((counter) => counter.color === color));
  const availableColors = React.useRef(getInactiveColors());

  const metricData = useMetrics({
    dateRange: metricDateRange,
    isCustomizeChart: true,
    metrics: performanceCounters.map((counter) => ({ ...counter, axisLabel: null })),
    target,
  });

  function handleNewChartSubmit(e: React.FormEvent): void {
    e.preventDefault();

    if (chartName.trim() === "") {
      return alert("Chart must have a name.");
    } else if (metricsError) {
      return alert(
        "Not all selected metrics are valid for the selected target. Please select a different metric and try again.",
      );
    }

    const newChartConfig: ICustomChartConfigComplete = {
      chartName: chartName.trim(),
      customTimeRange: useCustomTimeRange ? customTimeRange : null,
      performanceCounters: performanceCounters.map((counter) => ({
        ...counter,
        label: counter.label?.trim() || null,
        moRef: counter.moRef ?? MoRef.fromTopologyItem(target),
      })),
    };

    // close dialog after saving
    handleClose(newChartConfig);
  }

  function handleMetricTimeRangeChange(metricTimeRange: "1hr" | "1day" | "7days"): void {
    setMetricTimeRange(metricTimeRange);
    let duration: DurationObject;
    switch (metricTimeRange) {
      case "7days":
        duration = { week: 1 };
        break;
      case "1day":
        duration = { day: 1 };
        break;
      case "1hr":
        duration = { hour: 1 };
        break;
    }
    setMetricDateRange({
      from: DateTime.utc().minus(duration).toJSDate(),
      to: DateTime.utc().toJSDate(),
    });
  }

  function handleAddMetricClick(rowData?: Partial<ICustomDashboardChartSeries>): void {
    const newColor = availableColors.current.shift() ?? "#FF5349"; // this shouldn't happen but shift can be undefined so fallback to Coral
    if (availableColors.current.length === 0) availableColors.current = getInactiveColors();

    //if you have selected multiple instance from dropdown and click on ok button it will create multiple rows for selected instances
    if (rowData?.instanceList) {
      rowData.instanceList.map((instance: string) => {
        const newColor = availableColors.current.shift() ?? "#FF5349";
        setPerformanceCounters((prev) => {
          const prevRows = Array.from(prev);
          const lastPrevRow = prevRows[prevRows.length - 1];
          prevRows.push({
            ...rowData,
            axisLabel: rowData.axisLabel ?? lastPrevRow.axisLabel,
            chartType: rowData.chartType ?? lastPrevRow.chartType,
            color: newColor,
            instance: instance,
            instanceList: [],
            label: null,
            metric: rowData.metric ?? lastPrevRow.metric,
            moRef: rowData.moRef ?? lastPrevRow.moRef,
            strokeColor: rowData.strokeColor ?? lastPrevRow.strokeColor,
          });
          return prevRows;
        });
      });
    } else {
      setPerformanceCounters((prev) => {
        const prevRows = Array.from(prev);
        const lastPrevRow = prevRows[prevRows.length - 1];
        prevRows.push({
          ...lastPrevRow,
          color: newColor,
          instance: null,
          instanceList: [],
          label: null,
        });
        return prevRows;
      });
    }
  }

  const targetMoRef = React.useMemo(() => MoRef.fromTopologyItem(target), [target]);

  return (
    <Dialog fullWidth maxWidth="lg" open={open}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography component="h1" variant="h6">
          {intl.formatMessage({ id: "customizeChart" })}
        </Typography>
        <CustomChartModalTooltip placement="left" title="close">
          <IconButton onClick={() => handleClose(null)} size="small">
            <CloseIcon />
          </IconButton>
        </CustomChartModalTooltip>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <form className={classes.form} id="newCustomChartForm" method="POST" onSubmit={handleNewChartSubmit}>
          <Alert classes={{ message: classes.infoAlertText }} icon={false} severity="info">
            {intl.formatMessage({ id: "publishedChartsInfoMessage" })}
          </Alert>
          {/* Preview Chart */}
          <div>
            <div className={classes.metricChartContainer}>
              <ErrorBoundary key={MoRef.fromTopologyItem(target).toString()} variant="chart">
                <MetricChart
                  dateRange={metricDateRange}
                  disableContextMenu
                  metricData={{ ...metricData, isLoading: metricData.isLoading || loadingMetrics }}
                  onSelectedRangeChange={() => null}
                  selectedRange={[null, null] as const}
                  target={target}
                />
              </ErrorBoundary>
            </div>
          </div>
          {/* Chart Details */}
          <div className={classes.chartDetails}>
            <TextField
              InputLabelProps={{ htmlFor: "chartName", shrink: true }}
              autoFocus
              fullWidth
              inputProps={{ id: "chartName", maxLength: 128, name: "chartName" }}
              label={intl.formatMessage({ id: "chartName" })}
              onChange={(e) => setChartName(e.target.value)}
              required
              value={chartName}
              variant="filled"
            />
          </div>
          {/* Performance Counters Section */}
          <div>
            <Paper className={classes.performanceCountersSection} elevation={0}>
              <div className={classes.performanceCountersToolbar}>
                <Typography className={classes.sectionLabel} component="h2" display="inline" id="performanceCounters">
                  {intl.formatMessage({ id: "performanceMetrics" })}
                  <FormLabel className={classes.performanceCounterHelpText}>
                    {intl.formatMessage({ id: "addUpTo10Metrics" })}
                  </FormLabel>
                </Typography>
                <div className={classes.flexAlignCenter}>
                  <InputLabel id="historicalMetricsSelect">{intl.formatMessage({ id: "samplePeriod" })}</InputLabel>
                  <CustomChartModalTooltip title="moreHistoricalMetricsInfoTooltip">
                    <InfoIcon className={classes.extraInfoIcon} color="primary" fontSize="small" />
                  </CustomChartModalTooltip>
                  <Select
                    classes={{ select: classes.previewHeaderOptionsSelect }}
                    inputProps={{ "data-testid": "historicalMetricsSelect" }}
                    labelId="historicalMetricsSelect"
                    onChange={(e) => handleMetricTimeRangeChange(e.target.value as "1hr" | "1day" | "7days")}
                    value={metricTimeRange}
                    variant="filled"
                  >
                    <MenuItem value="1hr">{intl.formatMessage({ id: "oneHour" })}</MenuItem>
                    <MenuItem value="1day">{intl.formatMessage({ id: "oneDay" })}</MenuItem>
                    <MenuItem value="7days">{intl.formatMessage({ id: "oneWeek" })}</MenuItem>
                  </Select>
                  <Button
                    className={classes.addMetricBtn}
                    color="primary"
                    disabled={performanceCounters.length >= 10}
                    onClick={() => handleAddMetricClick()}
                    size="small"
                    variant="contained"
                  >
                    {intl.formatMessage({ id: "addMetric" })}
                  </Button>
                </div>
              </div>
              <Divider className={classes.divider} />
              <ErrorBoundary variant="generic">
                <PerformanceCounterSelection
                  activeTarget={target}
                  dateRange={metricDateRange}
                  handleAddMultipleInstances={(value: Partial<ICustomDashboardChartSeries>): void =>
                    value && handleAddMetricClick(value)
                  }
                  handleLoadingMetrics={setLoadingMetrics}
                  handleMetricsChange={setPerformanceCounters}
                  handleMetricsError={setMetricsError}
                  handleTargetChange={(newTarget) => setTarget(newTarget)}
                  initialChartConfig={initialChartConfig}
                  moRef={targetMoRef}
                  performanceCounters={performanceCounters}
                  targets={targets}
                />
              </ErrorBoundary>
            </Paper>
          </div>
          {/* Chart Options */}
          <div className={classes.flexAlignCenter}>
            <FormControlLabel
              className={classes.customTimeRangeSwitch}
              control={
                <Switch
                  checked={useCustomTimeRange}
                  color="primary"
                  inputProps={{ id: "useCustomTimeRange" }}
                  onChange={(_, value) => setUseCustomTimeRange(value)}
                />
              }
              label={intl.formatMessage({ id: "customTimeRange" })}
            />
            <CustomChartModalTooltip title="customTimeRangeInfoTooltip">
              <InfoIcon className={classes.extraInfoIcon} color="primary" fontSize="small" />
            </CustomChartModalTooltip>
            <FormLabel className={classes.lastLabel} disabled={!useCustomTimeRange}>
              {intl.formatMessage({ id: "last" })}
            </FormLabel>
            <TextField
              InputLabelProps={{ htmlFor: "customTimeRangeAmount", shrink: true }}
              InputProps={{ id: "customTimeRangeAmount", inputProps: { min: 0 } }}
              className={classes.timeRangeAmountTextField}
              disabled={!useCustomTimeRange}
              label={intl.formatMessage({ id: "amount" })}
              onChange={(e) => {
                const input = parseInt(e.target.value);
                setCustomTimeRange((prev) => ({ ...prev, value: isNaN(input) ? 0 : input }));
              }}
              required={useCustomTimeRange}
              type="number"
              value={customTimeRange.value}
              variant="filled"
            />
            <FormControl disabled={!useCustomTimeRange} variant="filled">
              <InputLabel id="customTimeRangeUnit">{intl.formatMessage({ id: "units" })}</InputLabel>
              <Select
                inputProps={{ "data-testid": "customTimeRangeUnitSelect" }}
                labelId="customTimeRangeUnit"
                onChange={(e) => {
                  const newUnits = e.target.value as CustomTimeRangeUnit;
                  setCustomTimeRange((prev) => ({ ...prev, unit: newUnits }));
                }}
                value={customTimeRange.unit}
              >
                <MenuItem value={CustomTimeRangeUnit.Minutes}>{intl.formatMessage({ id: "minutes" })}</MenuItem>
                <MenuItem value={CustomTimeRangeUnit.Hours}>{intl.formatMessage({ id: "hours" })}</MenuItem>
                <MenuItem value={CustomTimeRangeUnit.Days}>{intl.formatMessage({ id: "days" })}</MenuItem>
              </Select>
            </FormControl>
          </div>
        </form>
      </DialogContent>
      <Divider />
      <DialogActions className={classes.actionBtns}>
        <Button onClick={() => handleClose(null)} size="large">
          {intl.formatMessage({ id: "cancel" })}
        </Button>
        <Button
          color="primary"
          disabled={metricsError}
          form="newCustomChartForm"
          size="large"
          type="submit"
          variant="contained"
        >
          {intl.formatMessage({ id: "save" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomChartModal;
