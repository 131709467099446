import MenuList from "@material-ui/core/MenuList";
import * as React from "react";
import { PerformanceAnalysisAggregateType, QueryAggregateType, TopSqlChartType } from "../../types";
import ChartSelectorItem from "./ChartSelectorItem";
import { chartList, ChartSubMenuEnum } from "./types";
const { useCallback, useState } = React;

const LOCAL_STORAGE_KEY = "topSqlChartSelector";

type ChartSelectorPreferences = {
  [key in TopSqlChartType]?: ChartSubMenuEnum;
};

interface IChartSelectorProps {
  chart: TopSqlChartType;
  className?: string;
  onChange: (chart: TopSqlChartType, subMenuSelection?: ChartSubMenuEnum | null) => void;
  subMenuSelection: ChartSubMenuEnum | null;
}

function fetchChartSelectorPreferences(): ChartSelectorPreferences | undefined {
  try {
    const serializedValue = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (serializedValue) {
      const results = JSON.parse(serializedValue);
      return results;
    }
  } catch (e) {
    // TODO: PBI 49050 Report error to logging service
  }
}

function getPreferenceUpdates(
  preferences: ChartSelectorPreferences,
  perfSubMenu: PerformanceAnalysisAggregateType,
  querySubMenu: QueryAggregateType,
): ChartSelectorPreferences {
  return {
    ...preferences,
    [TopSqlChartType.ByApp]: perfSubMenu,
    [TopSqlChartType.ByDatabase]: perfSubMenu,
    [TopSqlChartType.ByHost]: perfSubMenu,
    [TopSqlChartType.ByLogin]: perfSubMenu,
    [TopSqlChartType.Queries]: querySubMenu,
  };
}

/**
 * Merges and existing preferences object with a new selection.
 * If the submenu selection is common to other charts, it will apply across them all.
 * If the submenu is only applicable to the newly selected chart, it will only affect that chart.
 * @param preferences The current preferences value.
 * @param chart The new chart being selected.
 * @param subMenuSelection The new chart sub menu selection.
 */
function mergePreferences(
  preferences: ChartSelectorPreferences,
  chart: TopSqlChartType,
  subMenuSelection: ChartSubMenuEnum,
): ChartSelectorPreferences {
  switch (subMenuSelection) {
    case PerformanceAnalysisAggregateType.cpu:
    case QueryAggregateType.cpu:
      return getPreferenceUpdates(preferences, PerformanceAnalysisAggregateType.cpu, QueryAggregateType.cpu);
    case PerformanceAnalysisAggregateType.duration:
    case QueryAggregateType.duration:
      return getPreferenceUpdates(preferences, PerformanceAnalysisAggregateType.duration, QueryAggregateType.duration);
    case PerformanceAnalysisAggregateType.execCount:
    case QueryAggregateType.execCount:
      return getPreferenceUpdates(
        preferences,
        PerformanceAnalysisAggregateType.execCount,
        QueryAggregateType.execCount,
      );
    case PerformanceAnalysisAggregateType.reads:
    case QueryAggregateType.readsL:
      return getPreferenceUpdates(preferences, PerformanceAnalysisAggregateType.reads, QueryAggregateType.readsL);
    case PerformanceAnalysisAggregateType.writes:
      // Apply to all By * charts, but skip Queries chart
      return {
        ...preferences,
        [TopSqlChartType.ByApp]: subMenuSelection,
        [TopSqlChartType.ByDatabase]: subMenuSelection,
        [TopSqlChartType.ByHost]: subMenuSelection,
        [TopSqlChartType.ByLogin]: subMenuSelection,
      };
    case QueryAggregateType.readsP:
    case QueryAggregateType.writesL:
    default:
      // Setting was not common and only affects this chart
      return {
        ...preferences,
        [chart]: subMenuSelection,
      };
  }
}

function saveChartSelectorPreferences(value: ChartSelectorPreferences): void {
  try {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(value));
  } catch (e) {
    // TODO: PBI 49050 Report error to logging service
  }
}

/**
 * Custom hook for managing chart selector preferences and persisting to storage.
 */
function useChartSelectorPreferences(): [
  ChartSelectorPreferences,
  (chart: TopSqlChartType, subMenuSelection: ChartSubMenuEnum) => void,
] {
  const [preferences, setPreferences] = useState<ChartSelectorPreferences>(() => {
    const restoredPreferences = fetchChartSelectorPreferences();
    return restoredPreferences || {};
  });

  const setPreference = useCallback(
    (chart: TopSqlChartType, subMenuSelection: ChartSubMenuEnum) => {
      const newPreferences = mergePreferences(preferences, chart, subMenuSelection);

      setPreferences(newPreferences);

      // Defer saving the preferences to let the UI proceed and settle
      setTimeout(() => saveChartSelectorPreferences(newPreferences), 0);
    },
    [preferences],
  );

  return [preferences, setPreference];
}

const ChartSelector: React.FunctionComponent<IChartSelectorProps> = ({
  chart,
  className,
  onChange,
  subMenuSelection,
}) => {
  const [preferences, setPreference] = useChartSelectorPreferences();

  function handleChangeWithSubmenu(c: TopSqlChartType, s?: ChartSubMenuEnum | null): void {
    if (s) {
      // Explicit submenu selection was made
      onChange(c, s);
      setPreference(c, s);
    } else {
      // Chart title was selected (no submenu)
      const defaultSubMenu = preferences[c] || chartList.find((x) => x.id === c)?.subMenuDefault;
      onChange(c, defaultSubMenu);
    }
  }

  return (
    <MenuList className={className} dense>
      {chartList.map((item) => (
        <ChartSelectorItem
          key={item.id}
          onChange={item.subMenu.length > 0 ? handleChangeWithSubmenu : onChange}
          selection={chart}
          subMenuSelection={chart === item.id ? subMenuSelection : null}
          type={item.id}
        />
      ))}
    </MenuList>
  );
};

export default ChartSelector;
