import { Column, ColumnPropsAuto, ColumnRenderCallback } from "@sentryone/react-datatable";
import * as React from "react";
import { formatDigitalStorage } from "../../utilities/Formatters";

export default function DigitalStorageColumn<TEntity>(props: ColumnPropsAuto<TEntity>): React.ReactElement | null {
  const renderCell: ColumnRenderCallback<TEntity> = (d) => {
    const value = d[props.field];
    if (typeof value === "number") {
      return <>{formatDigitalStorage(value)}</>;
    } else if (value) {
      throw new Error(`Value in DigitalStorageColumn is not of type number. Value is of type ${typeof value}.`);
    } else {
      return null;
    }
  };
  return <Column<TEntity> align="left" defaultSortDirection="desc" renderCell={renderCell} {...props} />;
}
