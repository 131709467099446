import * as React from "react";
import MetricChart, { IMetricChartSeriesOptions, useMetrics } from "../../../../components/MetricChart";
import { IPerformanceAnalysisChartProps } from "../../types";
import { usePerformanceAnalysisContext } from "../../context";
import useVmGuestContextMenuOptions from "./useVmGuestContextMenuOptions";
import { ITopologyItemDevice } from "../../../../components/TopologyContext/types";

const ametrics = Object.freeze<IMetricChartSeriesOptions>([
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#98FB98",
    instance: "All",
    label: "ms/Read",
    metric: "vmware.guests.disk.read.ms",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#20B2AA",
    instance: "All",
    label: "ms/Write",
    metric: "vmware.guests.disk.write.ms",
  }),
]);

const bmetrics = Object.freeze<IMetricChartSeriesOptions>([
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#FF8C00",
    instance: "All",
    label: "Read",
    metric: "vmware.guests.disk.read.throughput",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#FF4500",
    instance: "All",
    label: "Write",
    metric: "vmware.guests.disk.write.throughput",
  }),
]);

const DiskIOChart: React.FC<IPerformanceAnalysisChartProps> = ({ className }) => {
  const { contextMenuItems, dateRange, selectedRange, setSelectedRange, target } = usePerformanceAnalysisContext();
  //this type cast is safe because vmware hosts are always devices
  const { guestContextMenuOptions } = useVmGuestContextMenuOptions(target as ITopologyItemDevice, selectedRange);
  //contextMenuItems should always be valid since the "zoom" context menu option always exists in the performance context menus
  const combinedContextMenuOptions =
    guestContextMenuOptions && contextMenuItems ? [...contextMenuItems, ...guestContextMenuOptions] : contextMenuItems;
  const ametricData = useMetrics({
    dateRange,
    metrics: ametrics,
    target,
  });

  const bmetricData = useMetrics({
    dateRange,
    metrics: bmetrics,
    target,
  });

  return (
    <>
      <MetricChart
        className={className}
        contextMenuItems={combinedContextMenuOptions}
        dateRange={dateRange}
        metricData={ametricData}
        onSelectedRangeChange={setSelectedRange}
        selectedRange={selectedRange}
        target={target}
      />
      <MetricChart
        className={className}
        contextMenuItems={combinedContextMenuOptions}
        dateRange={dateRange}
        metricData={bmetricData}
        onSelectedRangeChange={setSelectedRange}
        selectedRange={selectedRange}
        target={target}
      />
    </>
  );
};

export default DiskIOChart;
