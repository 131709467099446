import { makeStyles } from "@material-ui/core/styles";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  input: {
    paddingBottom: "10.5px",
    paddingTop: "10.5px",
  },
}));

const SearchTextField: React.FC<TextFieldProps> = ({ ...props }) => {
  const classes = useStyles();

  return (
    <TextField
      InputProps={{
        classes: { input: classes.input },
      }}
      inputProps={{
        "aria-label": props["aria-label"],
      }}
      size="small"
      type="search"
      variant="outlined"
      {...props}
    />
  );
};

export default SearchTextField;
