import * as React from "react";
import { IDeadlocksContext, IDeadlock } from "./types";

const DeadlocksContext = React.createContext<IDeadlocksContext>({
  deadlockInfo: undefined,
  error: undefined,
  loading: true,
  loadingDeadlock: true,
  onChangeDeadlock: (newSelectedNodeId: string, newSelectedDeadlockId: number) => null,
  onUploadDeadlock: (uploadedDeadlock: IDeadlock | undefined) => null,
  selectedDeadlock: undefined,
  selectedDeadlockId: 0,
  selectedNode: undefined,
  selectedNodeId: "",
  setSelectedNodeId: (newSelection: string) => null,
});

export default DeadlocksContext;
