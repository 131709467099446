import * as Sentry from "@sentry/browser";
import * as React from "react";
import { ApiError, NetworkError } from "../../api/Errors";
import ErrorDisplay, { ErrorBoundaryVariant } from "./ErrorDisplay";

interface ICustomFallbackProps {
  onTryAgain: () => void;
}

interface IErrorBoundaryCustomProps {
  children: React.ReactNode;
  fallback: (props: ICustomFallbackProps) => React.ReactNode;
  onRetry?: () => void;
  variant: "custom";
}

interface IErrorBoundaryPresetProps {
  children: React.ReactNode;
  fallbackClassName?: string;
  fallbackStyle?: React.CSSProperties;
  onRetry?: () => void;
  variant: ErrorBoundaryVariant;
}

type IErrorBoundaryProps = IErrorBoundaryCustomProps | IErrorBoundaryPresetProps;

type IErrorBoundaryState = {
  hasError: true;
  error: Error;
} | {
  hasError: false;
  error: null;
}

/**
 * Checks if an error should be logged.
 * Some errors are ignored because there is no action to take or they are logged elsewhere.
 * @param error The error that may be logged.
 * @returns True if the error should be logged, false otherwise.
 */
export function shouldLogError(error: Error): boolean {
  if (error instanceof ApiError && error.status >= 500 && error.status < 600) {
    // Server errors are logged by the backend and can be ignored here    
    return false;
  } else if (error instanceof NetworkError) {
    // Network errors can't be controlled so they are ignored
    return false;
  } else {
    return true;
  }
}

export default class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  public state: IErrorBoundaryState = {
    error: null,
    hasError: false,
  };

  public static getDerivedStateFromError(error: Error): IErrorBoundaryState {
    return {
      error: error,
      hasError: true,
    };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ error });
    if (shouldLogError(error)) {
      Sentry.withScope((scope) => {
        scope.setExtras({
          ...errorInfo,
        });
        Sentry.captureException(error);
      });
    }
  }

  public render(): React.ReactNode {
    if (!this.state.hasError) {
      return this.props.children;
    } else if (this.props.variant === "custom") {
      return this.props.fallback({
        onTryAgain: this.clearError,
      });
    } else {
      return (
        <ErrorDisplay
          className={this.props.fallbackClassName}
          error={this.state.error}
          onTryAgain={this.clearError}
          style={this.props.fallbackStyle}
          variant={this.props.variant}
        />
      );
    }
  }

  private clearError = (): void => {
    this.setState({
      hasError: false,
    });
    this.props.onRetry?.();
  };
}
