import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { PopoverOrigin } from "@material-ui/core/Popover";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import * as React from "react";
import { useIntl } from "react-intl";
import { IMetricChartSeries, useLegend } from "../../../../../components/MetricChart";
import { TopSqlChartType } from "../types";

interface ITopSqlChartCardMenuProps {
  chart: TopSqlChartType;
  onReset: () => void;
  onToggleAxisLabels: () => void;
  series: readonly IMetricChartSeries[];
  showAxisLabels: boolean;
}

const subMenuOrigin: PopoverOrigin = { horizontal: "right", vertical: "top" };

const TopSqlChartCardMenu: React.FC<ITopSqlChartCardMenuProps> = ({
  chart,
  onReset,
  onToggleAxisLabels,
  series,
  showAxisLabels,
}) => {
  const intl = useIntl();
  const [open, setOpen] = React.useState(false);
  const anchorEl = React.useRef<HTMLButtonElement>(null);
  const [seriesOpen, setSeriesOpen] = React.useState(false);
  const legend = useLegend();

  return (
    <>
      <IconButton onClick={() => setOpen(true)} ref={anchorEl} title={intl.formatMessage({ id: "options" })}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl.current}
        anchorOrigin={subMenuOrigin}
        onClick={() => setOpen(false)}
        onClose={() => setOpen(false)}
        open={open}
      >
        <MenuItem onClick={onReset}>{intl.formatMessage({ id: "reset" })}</MenuItem>
        <MenuItem onClick={onToggleAxisLabels}>
          {intl.formatMessage({ id: showAxisLabels ? "hideAxisLabels" : "showAxisLabels" })}
        </MenuItem>
        {chart === TopSqlChartType.Resources && (
          <MenuItem onClick={() => setSeriesOpen(true)}>{intl.formatMessage({ id: "showHideAxes" })}</MenuItem>
        )}
      </Menu>
      {chart === TopSqlChartType.Resources && (
        <Menu
          anchorEl={anchorEl.current}
          anchorOrigin={subMenuOrigin}
          onClose={() => setSeriesOpen(false)}
          open={seriesOpen}
        >
          {series.map((s) => (
            <MenuItem
              aria-selected={!legend.isHidden(s)}
              disabled={s.points.length === 0}
              key={s.key}
              onClick={() => legend.toggleSeries(s)}
              selected={!legend.isHidden(s)}
            >
              {s.label
                ? intl.formatMessage({ defaultMessage: s.label, id: s.label })
                : intl.formatMessage({ id: "(unknown)" })}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

export default TopSqlChartCardMenu;
