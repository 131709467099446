import { useApolloClient } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Skeleton from "@material-ui/lab/Skeleton";
import { ApolloClient } from "apollo-boost";
import classNames from "classnames";
import * as React from "react";
import { useDrag } from "react-dnd";
import { useIntl } from "react-intl";
import { useAsync } from "../../../../../../hooks/Async";
import { ICustomChartConfig, ICustomDashboardChartSeries, WidgetDragItem, WidgetDragItemType } from "../../../../types";
import { ICustomChartTemplate, PerformanceCounterThunk } from "./CustomChartTemplates";
import { ITopologyItemDevice, ITopologyItemEventSourceConnection } from "../../../../../../components/TopologyContext";

export interface IWidgetListItemProps {
  addWidget: (newWidget: ICustomChartConfig) => void;
  widgetInfo: ICustomChartTemplate;
}

const useStyles = makeStyles((theme) => ({
  customChart: {
    "&:hover": {
      boxShadow: `0px 0px 0px 1px ${theme.palette.primary.dark}`,
    },
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  gutters: {
    paddingLeft: "7px",
    paddingRight: "4px",
  },
  icon: {
    marginRight: theme.spacing(1),
    verticalAlign: "middle",
  },
  itemContainer: {
    cursor: "move",
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(1),
    transition: "box-shadow linear 100ms",
  },
  notCustomChart: {
    "&:hover": {
      boxShadow: `0px 0px 0px 1px ${theme.palette.primary.main}`,
    },
    backgroundColor: theme.palette.grey[200],
    color: theme.typography.body1.color,
  },
}));

export async function flattenThunk(
  thunk: PerformanceCounterThunk,
  target: ITopologyItemDevice | ITopologyItemEventSourceConnection,
  apolloClient: ApolloClient<any>,
  signal: AbortSignal,
): Promise<ICustomDashboardChartSeries[]> {
  return typeof thunk === "function" ? await thunk(target, apolloClient, signal) : thunk;
}

const WidgetListItem: React.FC<IWidgetListItemProps> = ({ addWidget, widgetInfo }) => {
  const intl = useIntl();
  const classes = useStyles();
  const client = useApolloClient();

  /*Currently this will run a network call for each template (that dynamically creates performance counters using instances)
  This only makes 3 network calls currently, but if we expand the number of templates that are created dynamically, we
  likely want to revisit this*/
  const getPerformanceCounters = React.useCallback(
    async ({ signal }: AbortController): Promise<ICustomDashboardChartSeries[]> => {
      if (!widgetInfo.target) throw new Error("Templates need a default target");
      return flattenThunk(widgetInfo.performanceCounters, widgetInfo.target, client, signal);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { data: performanceCounters = [], isLoading } = useAsync(getPerformanceCounters);
  const chartConfig: ICustomChartConfig = {
    chartName: widgetInfo.chartName,
    customTimeRange: null,
    performanceCounters: performanceCounters,
    target: widgetInfo.target,
  };

  const [, drag] = useDrag<WidgetDragItem, never, never>({
    item: {
      type: WidgetDragItemType.add,
      widgetTemplate: chartConfig,
    },
    type: WidgetDragItemType.add,
  });

  const isCustomChart = widgetInfo.chartName === intl.formatMessage({ id: "customChart" });

  return !isLoading ? (
    <ListItem
      className={classNames(classes.itemContainer, {
        [classes.customChart]: isCustomChart,
        [classes.notCustomChart]: !isCustomChart,
      })}
      classes={{ gutters: classes.gutters }}
      data-testid={`addWidget-${widgetInfo.chartName}`}
      ref={drag}
    >
      <Typography color="inherit">
        {isCustomChart && <EditIcon className={classes.icon} fontSize="small" />}
        {widgetInfo.chartName}
      </Typography>
      <IconButton
        aria-label={`add-widget-${widgetInfo.chartName}`}
        color={isCustomChart ? "inherit" : "primary"}
        onClick={() => addWidget(chartConfig)}
        size="small"
        title={intl.formatMessage({ id: "add" })}
      >
        <AddIcon fontSize="medium" />
      </IconButton>
    </ListItem>
  ) : (
    <Skeleton className={classes.itemContainer} height="38px" variant="rect" width="191px" />
  );
};

export default WidgetListItem;
