import * as React from "react";

interface IFocusedDialogSelectorWithPopover {
  onPopoverClose: () => void;
  onSelectorClick: () => void;
  popoverAnchorEl: HTMLButtonElement | null;
  popoverOpen: boolean;
  selectorRef: React.RefObject<HTMLButtonElement>;
  setPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useFocusedDialogSelectorWithPopover = (): IFocusedDialogSelectorWithPopover => {
  const popoverRef = React.useRef<HTMLButtonElement>(null);
  const [popoverOpen, setPopoverOpen] = React.useState<boolean>(false);

  const onSelectorClick = React.useCallback((): void => {
    setPopoverOpen((prev) => !prev);
  }, []);

  const onPopoverClose = React.useCallback((): void => {
    setPopoverOpen(false);
  }, []);

  return {
    onPopoverClose,
    onSelectorClick,
    popoverAnchorEl: popoverRef.current,
    popoverOpen,
    selectorRef: popoverRef,
    setPopoverOpen,
  };
};

export default useFocusedDialogSelectorWithPopover;
