import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import ErrorBoundary from "../../components/ErrorBoundary";
import Top5Charts from "./Top5Charts";
import { Alerts } from "../../ui/alerts/alerts";
import EnvironmentHeader from "../../ui/EnvironmentHeader/EnvironmentHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 4),
  },
  topServers: {
    marginBottom: "6px",
  },
}));

const Advisory: React.FunctionComponent = () => {
  const classes = useStyles();
  const [selectedChartCell, setSelectedChartCell] = React.useState<any>();

  const handleContextMenu = (e: React.MouseEvent): void => {
    e.preventDefault();
  };

  return (
    <div className={classes.root} onContextMenu={handleContextMenu}>
      <EnvironmentHeader />
      <ErrorBoundary variant="generic">
        <Top5Charts className={classes.topServers} onClickChart={(cell) => setSelectedChartCell(cell)} />
      </ErrorBoundary>
      <Alerts
        onClearFilter={() => setSelectedChartCell(null)}
        selectedChartCell={selectedChartCell}
      />
    </div>
  );
};
export default Advisory;
