import LinearProgress from "@material-ui/core/LinearProgress";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import PlaceHolderBackground, { IPlaceHolderBackgroundProps } from "../PlaceHolderBackground";

// CreateStyles has as Type defined and this is infering. 
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type 
const styles = (theme: Theme) => createStyles({
  background: {
    gridColumn: 1,
    gridRow: 1,
    maxHeight: theme.typography.pxToRem(200),
    maxWidth: theme.typography.pxToRem(340),
    width: "100%",
  },
  progress: {
    gridColumn: 1,
    gridRow: 1,
    width: "100%",
  },
  root: {
    alignContent: "center",
    alignItems: "center",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr",
    justifyContent: "center",
    justifyItems: "center",
  },
});

const LoadingIndicator: React.FunctionComponent<IPlaceHolderBackgroundProps & WithStyles<typeof styles>> = ({
  className,
  classes,
  ...props
}) => {
  return (
    <div className={classNames(classes.root, className)}>
      <PlaceHolderBackground className={classes.background} {...props} />
      <LinearProgress className={classes.progress} />
    </div>
  );
};

export default withStyles(styles)(LoadingIndicator);
