import MenuItem from "@material-ui/core/MenuItem";
import * as React from "react";
import { Link, useRouteMatch } from "react-router-dom";

export interface INavigationMenuItemProps {
  children?: React.ReactNode;
  onClick?: () => void;
  to: string;
}

// forwardRef is needed for the custom MenuItem to play nicely with Material-UI's Menu parent
const NavigationMenuItem = React.forwardRef<unknown, INavigationMenuItemProps>(({ children, onClick, to }, ref) => {
  const match = useRouteMatch({
    // Match up to the query string
    path: to.includes("?") ? to.substring(0, to.indexOf("?")) : to,
  });

  return (
    <MenuItem
      aria-current={match ? "page" : undefined}
      component={Link}
      innerRef={ref}
      onClick={onClick}
      selected={!!match}
      to={to}
    >
      {children}
    </MenuItem>
  );
});

export default NavigationMenuItem;
