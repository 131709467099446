import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { useIntl } from "react-intl";

export type DashboardConfirmationType = "delete" | "discard" | undefined;

export interface IDashboardConfirmationDialogProps {
  handleClose: (confirmed: boolean) => void;
  open: DashboardConfirmationType;
}

const DashboardConfirmationDialog: React.FC<IDashboardConfirmationDialogProps> = ({ open, handleClose }) => {
  const intl = useIntl();

  return (
    <Dialog fullWidth maxWidth="xs" open={!!open}>
      {open === "delete" && (
        <>
          <DialogTitle>{intl.formatMessage({ id: "deleteDashboardConfirmation" })}</DialogTitle>
          <DialogContent>
            <DialogContentText>{intl.formatMessage({ id: "areYouSureDeleteDashboard" })}</DialogContentText>
            <DialogContentText>{intl.formatMessage({ id: "dashboardDeletionCannotBeUndone" })}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose(false)}>{intl.formatMessage({ id: "cancel" })}</Button>
            <Button color="secondary" onClick={() => handleClose(true)} variant="contained">
              {intl.formatMessage({ id: "deleteDashboard" })}
            </Button>
          </DialogActions>
        </>
      )}
      {open === "discard" && (
        <>
          <DialogTitle>{intl.formatMessage({ id: "discardChangesAndExit" })}</DialogTitle>
          <DialogContent>
            <DialogContentText>{intl.formatMessage({ id: "unsavedEditsWillNotBeSaved" })}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose(false)}>{intl.formatMessage({ id: "goBack" })}</Button>
            <Button color="primary" onClick={() => handleClose(true)} variant="contained">
              {intl.formatMessage({ id: "exitWithoutSaving" })}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default DashboardConfirmationDialog;
