import { ApiResponse } from "./";

interface IResponseBody {
  StatusCode: number;
  Message: string;
}

export default class ApiError extends Error {

  private _errorMessage: string = "";
  private _errorCode: number = -1;

  constructor(private readonly response: ApiResponse) {
    super(`API error: ${response.status}: ${response.statusText}`);
    // see: typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }

  get status(): number {
    return this.response.status;
  }

  get statusText(): string {
    return this.response.statusText;
  }

  get ErrorCode(): number {
    return this._errorCode;
  }

  get ErrorMessage(): string {
    return this._errorMessage;
  }

  public static CreateAsync = async (response: ApiResponse): Promise<ApiError> => {
    const apiError: ApiError = new ApiError(response);
    if (response.status === 400 ||
      response.status === 500 ||
      response.status === 409) {
      const body: IResponseBody = await response.json();
      apiError._errorMessage = body.Message;
      apiError._errorCode = body.StatusCode;
    }

    return apiError;
  }
}
