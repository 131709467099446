import * as React from "react";
import MetricChart, { TOTAL_INSTANCE, useMetrics, IMetricChartSeriesOptions } from "../../../../components/MetricChart";
import { IPerformanceAnalysisChartProps } from "../../types";
import { usePerformanceAnalysisContext } from "../../context";

const CpuChart: React.FC<IPerformanceAnalysisChartProps> = ({ className }) => {
  const { contextMenuItems, dateRange, selectedRange, setSelectedRange, target } = usePerformanceAnalysisContext();

  const VMMetrics = Object.freeze<IMetricChartSeriesOptions>([
    Object.freeze<IMetricChartSeriesOptions>({
      axisLabel: null,
      chartType: "stackedArea",
      color: "#32CD32",
      instance: TOTAL_INSTANCE,
      label: "Total",
      metric: "vmware.host.processorTime.pct",
    }),
    Object.freeze<IMetricChartSeriesOptions>({
      axisLabel: null,
      chartType: "line",
      color: "#B22222",
      instance: TOTAL_INSTANCE,
      label: "Ready-Time %",
      metric: "vmware.host.readyTime.pct",
    }),
    Object.freeze<IMetricChartSeriesOptions>({
      axisLabel: null,
      chartType: "line",
      color: "#FFFF00",
      instance: TOTAL_INSTANCE,
      label: "Co-Stop %",
      metric: "vmware.host.costop.pct",
    }),
  ]);

  const VMData = useMetrics({
    dateRange,
    metrics: VMMetrics,
    target,
  });


  return (
    <MetricChart
      className={className}
      contextMenuItems={contextMenuItems}
      dateRange={dateRange}
      metricData={VMData}
      onSelectedRangeChange={setSelectedRange}
      selectedRange={selectedRange}
      target={target}
    />
  );
};

export default CpuChart;
