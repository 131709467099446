/**
 * Preset date ranges.
 */
export enum DatePreset {
  // TODO 51737: Import from DateSelector area
  LastDay = "lastDay",
  Last4Hours = "last4Hours",
  Last8Hours = "last8Hours",
  LastHour = "lastHour",
  LastMonth = "lastMonth",
  LastWeek = "lastWeek",
}

export interface IDateRange {
  from: Date;
  to: Date;
}

export interface IDateContext {
  /**
   * Indicates whether the dates can be changed within the current context.
   */
  allowDateNavigation: Readonly<boolean>;
  /**
   * Indicates whether automatic refresh is supported on this view.
   * This will push updates to dateRange on a rolling interval when liveEnabled is true.
   */
  autoRefreshSupported: Readonly<boolean>;
  /**
   * The current date range to display.
   */
  dateRange: Readonly<IDateRange>;
  liveEnabled: Readonly<boolean>;
  /**
   * Sets the context value's date range and disables live mode.
   * @param dateRange
   */
  setDateRange(dateRange: IDateRange): void;

  /**
   * Sets the date range to the specified preset and disables live mode.
   * @param preset
   */
  setPreset(preset: DatePreset): void;
  /**
   * Enables live mode and updates to the date range to the live mode fixed range.
   */
  switchToLive(): void;
  /**
   * Returns path with custom date range correctly formatted.
   * @param path
   * @param dateRange
   */
  getUrlWithDateRange(path: string, dateRange: IDateRange | null): string;

}

export interface IDateComponentProps {
  dateContext: IDateContext;
}
