import { IPageResponse } from "../../api/BaseService";
import IPageRequest from "../../api/models/IPageRequest";

export interface IBlockingSelection {
  key: string;
  queryText: string;
  spid: number;
}

export interface IBlockChainDetail {
  blockedBySPID: number | null;
  commandText: string;
  databaseName: string;
  ecid: number;
  hostName: string | null;
  hostProcessId: number | null;
  id: number;
  loginName: string | null;
  objectName: string | null;
  programName: string | null;
  spid: number;
  waitResource: string | null;
  waitText: string | null;
  waitTime: string;
}

export interface IBlockChainGroup {
  databaseName: string | null;
  hostName: string | null;
  id: number;
  lastBatch: Date | string;
  loginName: string | null;
  programName: string | null;
  spid: number;
  versions: IBlockChainVersion[];
}

export interface IBlockChainRequestFilters {
  applicationContains?: string;
  applicationStartsWith?: string;
  applicationEndsWith?: string;
  applications?: string[];
  databaseNameContains?: string;
  databaseNameStartsWith?: string;
  databaseNameEndsWith?: string;
  databaseNames?: string[];
  hostContains?: string;
  hostStartsWith?: string;
  hostEndsWith?: string;
  hosts?: string[];
  waitResourceContains?: string;
  waitResourceStartsWith?: string;
  waitResourceEndsWith?: string;
  waitResources?: string[];
  waitTextContains?: string[];
  waitTextStartsWiths?: string[];
  waitTextEndsWiths?: string[];
  waitTexts?: string[];
}

export interface IBlockChainRequest extends IPageRequest {
  endDate: string;
  eventSourceConnectionId: number;
  filters: IBlockChainRequestFilters;
  startDate: string;
}

export interface IBlockChainVersion {
  details: IBlockChainDetail[];
  endTime: string | null;
  id: number;
  startTime: string;
  versionNumber: number;
}

export interface IParsedBlockChainVersion {
  details: IBlockChainDetail[];
  endTime: Date | null;
  id: number;
  startTime: Date;
  versionNumber: number;
}

export interface IBlockingQuery {
  chain?: IPageResponse<IBlockChainGroup> | null;
  blockersByApplication?: IBlockingByApplication[] | null;
  blockersByResourceType?: IBlockingByResourceType[] | null;
  blockersByResource?: IBlockingByResource[] | null;
  blockersByUser?: IBlockingByUser[] | null;
}

export interface IBlockingReportingChartQuery {
  blocking: {
    AvgTime: Array<IReportingChartPoint>;
    TotalCount: Array<IReportingChartPoint>;
    TotalTime: Array<IReportingChartPoint>;
  };
}

export interface IReportingChartPoint {
  readonly name: string | null;
  readonly value: number;
}

export interface IBlockChainGraphResponse {
  blocking: IBlockingQuery;
}

export interface IBlockChainVars {
  bcr: IBlockChainRequest;
}

export interface IBlockingByApplication extends IBlockingReport {
  application: string;
  programRank: number;
}

export interface IBlockingByResourceType extends IBlockingReport {
  waitResource: string;
  connectionRank: number;
}

export interface IBlockingByResource extends IBlockingReport {
  waitResource: string;
  connectionRank: number;
}

export interface IBlockingByUser extends IBlockingReport {
  loginName: string;
  userRank: number;
}

export interface IBlockingReport {
  avgTimeSec: number;
  id: number;
  objectName: string;
  friendlyName: string;
  totalCount: number;
  totalTimeSec: number;
}

export enum BlockingReportType {
  application = "application",
  resource = "resource",
  waitType = "waitType",
}
