import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import * as React from "react";
import { useIntl } from "react-intl";
import { v4 as uuid } from "uuid";
import { IDateRange, useDateContext } from "../../../../components/DateContext";
import ErrorBoundary from "../../../../components/ErrorBoundary";
import FeatureDisabled from "../../../../components/FeatureDisabled";
import MetricChart, { IMetricChartSeriesOptions, useMetrics } from "../../../../components/MetricChart";
import { ITopologyItemEventSourceConnection } from "../../../../components/TopologyContext";
import { ITranslation } from "../../../../intl/types";

export interface ITempDbSummaryProps {
  activeRange: IDateRange | null;
  className?: string;
  objectCollectionEnabled: boolean;
  onActiveRangeChange: (value: IDateRange | null) => void;
  target: ITopologyItemEventSourceConnection;
}

const useStyles = makeStyles((theme) => ({
  chart: {
    gridArea: "chart",
  },
  content: {
    display: "grid",
    gridGap: theme.spacing(),
    gridTemplateAreas: `
      "chart selector"
    `,
    gridTemplateColumns: "1fr 100px",
    gridTemplateRows: "250px",
  },
  menuItem: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },
  objectsMenuItemAction: {
    right: theme.spacing(1),
  },
  selector: {
    gridArea: "selector",
  },
}));

const enum ChartType {
  ObjectsByObjectCount,
  ObjectsByReservedSpace,
  ObjectsByRowCount,
  ObjectsByUsedSpace,
  Summary,
}

function makeObjectChartMetrics(metric: string): readonly IMetricChartSeriesOptions[] {
  // The order of these is important. They are rendered in the same order
  return Object.freeze<IMetricChartSeriesOptions>([
    Object.freeze<IMetricChartSeriesOptions>({
      axisLabel: "usage",
      chartType: "stackedArea",
      color: "#20B2AA",
      instance: "USER_TABLE",
      label: "USER_TABLE",
      metric,
    }),
    Object.freeze<IMetricChartSeriesOptions>({
      axisLabel: "usage",
      chartType: "stackedArea",
      color: "#ADD8E6",
      instance: "USER_TEMP_TABLE",
      label: "USER_TEMP_TABLE",
      metric,
    }),
    Object.freeze<IMetricChartSeriesOptions>({
      axisLabel: "usage",
      chartType: "stackedArea",
      color: "#00BFFF",
      instance: "GLOBAL_TEMP_TABLE",
      label: "GLOBAL_TEMP_TABLE",
      metric,
    }),
    Object.freeze<IMetricChartSeriesOptions>({
      axisLabel: "usage",
      chartType: "stackedArea",
      color: "#FF7F50",
      instance: "INTERNAL_TABLE",
      label: "INTERNAL_TABLE",
      metric,
    }),
    Object.freeze<IMetricChartSeriesOptions>({
      axisLabel: "usage",
      chartType: "stackedArea",
      color: "#DEB887",
      instance: "SYSTEM_TABLE",
      label: "SYSTEM_TABLE",
      metric,
    }),
    Object.freeze<IMetricChartSeriesOptions>({
      axisLabel: "usage",
      chartType: "stackedArea",
      color: "#B8860B",
      instance: "QUERY_OBJECT",
      label: "QUERY_OBJECT",
      metric,
    }),
  ]);
}

const freeSpacePatternId = `free-space-pattern-${uuid()}`;
const chartMetrics = Object.freeze<Record<ChartType, readonly IMetricChartSeriesOptions[]>>({
  [ChartType.ObjectsByObjectCount]: makeObjectChartMetrics("sqlserver.tempdbObjects.objectCount"),
  [ChartType.ObjectsByReservedSpace]: makeObjectChartMetrics("sqlserver.tempdbObjects.reserved.size"),
  [ChartType.ObjectsByRowCount]: makeObjectChartMetrics("sqlserver.tempdbObjects.rowCount"),
  [ChartType.ObjectsByUsedSpace]: makeObjectChartMetrics("sqlserver.tempdbObjects.used.size"),
  [ChartType.Summary]: Object.freeze<IMetricChartSeriesOptions>([
    Object.freeze<IMetricChartSeriesOptions>({
      axisLabel: "usage",
      chartType: "stackedArea",
      color: "#DEB887",
      instance: null,
      label: "versionStore",
      metric: "sqlserver.tempdbUsage.versionStore.size",
    }),
    Object.freeze<IMetricChartSeriesOptions>({
      axisLabel: "usage",
      chartType: "stackedArea",
      color: "#FF7F50",
      instance: null,
      label: "internalObjects",
      metric: "sqlserver.tempdbUsage.internalObjects.size",
    }),
    Object.freeze<IMetricChartSeriesOptions>({
      axisLabel: "usage",
      chartType: "stackedArea",
      color: "#20B2AA",
      instance: null,
      label: "userObjects",
      metric: "sqlserver.tempdbUsage.userObjects.size",
    }),
    Object.freeze<IMetricChartSeriesOptions>({
      axisLabel: "usage",
      chartType: "stackedArea",
      color: "#194537",
      instance: null,
      label: "mixedExtents",
      metric: "sqlserver.tempdbUsage.mixedExtents.size",
    }),
    Object.freeze<IMetricChartSeriesOptions>({
      axisLabel: "usage",
      chartType: "stackedArea",
      color: `url(#${freeSpacePatternId})`,
      instance: null,
      label: "freeSpace",
      metric: "sqlserver.tempdbUsage.free.size",
      strokeColor: "#7F7F7F",
    }),
  ]),
});

function getCardTitleIds(type: ChartType): Array<keyof ITranslation> {
  switch (type) {
    case ChartType.ObjectsByObjectCount:
      return ["tempDbObjects", "objectCount"];
    case ChartType.ObjectsByReservedSpace:
      return ["tempDbObjects", "reservedSpace"];
    case ChartType.ObjectsByRowCount:
      return ["tempDbObjects", "rowCount"];
    case ChartType.ObjectsByUsedSpace:
      return ["tempDbObjects", "usedSpace"];
    case ChartType.Summary:
      return ["tempDbSummary"];
  }
}

const TempDbSummary: React.FC<ITempDbSummaryProps> = ({
  activeRange,
  className,
  objectCollectionEnabled,
  onActiveRangeChange,
  target,
}) => {
  const intl = useIntl();
  const { dateRange } = useDateContext();
  const classes = useStyles();
  const [chartSelection, setChartSelection] = React.useState<ChartType>(ChartType.Summary);
  const [selectedDateRange, setSelectedDateRange] = React.useState<IDateRange | null>(activeRange);
  const [open, setOpen] = React.useState(false);
  const objectMenuRef = React.useRef<HTMLButtonElement>(null);
  const metricData = useMetrics({
    dateRange,
    metrics: chartMetrics[chartSelection],
    target,
  });

  React.useEffect(() => {
    setSelectedDateRange(activeRange);
  }, [activeRange]);

  function makeChartSelect(type: ChartType): () => void {
    return () => {
      setChartSelection(type);
      setOpen(false);
    };
  }

  return (
    <Card className={className} data-testid="tempDbSummary">
      <CardHeader
        title={
          !objectCollectionEnabled && chartSelection !== ChartType.Summary
            ? intl.formatMessage({ id: "tempDbObjects" })
            : getCardTitleIds(chartSelection)
                .map((id) => intl.formatMessage({ id }))
                .join(" - ")
        }
      />
      <CardContent className={classes.content}>
        {(objectCollectionEnabled || chartSelection === ChartType.Summary) && (
          <ErrorBoundary fallbackClassName={classes.chart} variant="chart">
            <MetricChart
              className={classes.chart}
              contextMenuItems={[
                <MenuItem key="filter" onClick={() => onActiveRangeChange(selectedDateRange)}>
                  {intl.formatMessage({ id: "filter" })}
                </MenuItem>,
              ]}
              dateRange={dateRange}
              key={chartSelection}
              metricData={metricData}
              onSelectedRangeChange={([from, to]) => setSelectedDateRange(from && to ? { from, to } : null)}
              selectedRange={[selectedDateRange?.from ?? null, selectedDateRange?.to ?? null]}
              target={target}
            >
              <defs>
                <pattern height="6" id={freeSpacePatternId} patternUnits="userSpaceOnUse" width="6" x="0" y="0">
                  <circle cx="2" cy="2" fill="#7F7F7F" r="1" stroke="none" />
                  <circle cx="5" cy="5" fill="#7F7F7F" r="1" stroke="none" />
                </pattern>
              </defs>
            </MetricChart>
          </ErrorBoundary>
        )}
        {!objectCollectionEnabled && chartSelection !== ChartType.Summary && (
          <FeatureDisabled
            action={
              <Button
                color="primary"
                href="https://docs.sentryone.com/help/monitor-tempdb#tempdb-objects"
                rel="noopener noreferrer"
                startIcon={<OpenInNewIcon />}
                target="_blank"
                variant="text"
              >
                {intl.formatMessage({ id: "viewDocumentation" })}
              </Button>
            }
            message={intl.formatMessage({ id: "pleaseSeeTheDocumentationToEnableColleciton" })}
            title={intl.formatMessage({ id: "tempDbObjectsNotEnabledMessage" })}
          />
        )}
        <MenuList className={classes.selector}>
          <MenuItem
            button
            className={classes.menuItem}
            onClick={makeChartSelect(ChartType.Summary)}
            selected={chartSelection === ChartType.Summary}
          >
            {intl.formatMessage({ id: "summary" })}
          </MenuItem>
          <MenuItem
            button
            className={classes.menuItem}
            onClick={makeChartSelect(ChartType.ObjectsByReservedSpace)}
            selected={chartSelection !== ChartType.Summary}
          >
            <ListItemText>{intl.formatMessage({ id: "objects" })}</ListItemText>
            {objectCollectionEnabled && (
              <ListItemSecondaryAction className={classes.objectsMenuItemAction}>
                <IconButton
                  disableRipple
                  edge="end"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen(true);
                  }}
                  ref={objectMenuRef}
                  size="small"
                  title={intl.formatMessage({ id: "clickToExpand" })}
                >
                  <ChevronRightIcon />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </MenuItem>
        </MenuList>
        <Menu anchorEl={objectMenuRef.current} onClose={() => setOpen(false)} open={open}>
          <MenuItem
            button
            className={classes.menuItem}
            onClick={makeChartSelect(ChartType.ObjectsByReservedSpace)}
            selected={chartSelection === ChartType.ObjectsByReservedSpace}
          >
            {intl.formatMessage({ id: "reservedSpace" })}
          </MenuItem>
          <MenuItem
            button
            className={classes.menuItem}
            onClick={makeChartSelect(ChartType.ObjectsByUsedSpace)}
            selected={chartSelection === ChartType.ObjectsByUsedSpace}
          >
            {intl.formatMessage({ id: "usedSpace" })}
          </MenuItem>
          <MenuItem
            button
            className={classes.menuItem}
            onClick={makeChartSelect(ChartType.ObjectsByRowCount)}
            selected={chartSelection === ChartType.ObjectsByRowCount}
          >
            {intl.formatMessage({ id: "rowCount" })}
          </MenuItem>
          <MenuItem
            button
            className={classes.menuItem}
            onClick={makeChartSelect(ChartType.ObjectsByObjectCount)}
            selected={chartSelection === ChartType.ObjectsByObjectCount}
          >
            {intl.formatMessage({ id: "objectCount" })}
          </MenuItem>
        </Menu>
      </CardContent>
    </Card>
  );
};

export default TempDbSummary;
