import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import * as AzureSqlDb from "../AzureSqlDbCharts";
import ChartCard from "./ChartCard";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridAutoRows: theme.spacing(45),
    gridGap: theme.spacing(2),
    gridTemplateColumns: "1fr",
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "1fr 1fr",
    },
  },
}));

const AzureSqlDbPerformanceAnalysis: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ChartCard chartComponent={AzureSqlDb.ResourceUsage} title="resourceUsage" vertical />
      <ChartCard chartComponent={AzureSqlDb.Activity} title="sqlServerActivity" />
      <ChartCard chartComponent={AzureSqlDb.MemoryUsage} title="memoryUsage" />
      <ChartCard chartComponent={AzureSqlDb.Waits} title="sqlDatabaseWaits" />
      <ChartCard chartComponent={AzureSqlDb.DatabaseSize} title="databaseSize" />
      <ChartCard chartComponent={AzureSqlDb.DatabaseIO} title="databaseIO" />
    </div>
  );
};

export default AzureSqlDbPerformanceAnalysis;
