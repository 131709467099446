import { createStyles } from "@material-ui/core/styles";
import { spacing } from "./spacing";

export const defaults = {
  aggregatedEventLogsGridRowsPerPage: 10,
  calculateMinTableHeight: (rowsPerPage: number): number => {
    return (
      rowsPerPage * defaults.rowHeight +
      defaults.tableHeadRowHeight +
      defaults.tableToolbarHeight +
      defaults.showMoreButtonGroupHeight
    );
  },
  eventLogsRowsPerPage: 25,
  rowHeight: spacing * 5,
  showMoreButtonGroupHeight: 56,
  statementsGridRowsPerPage: 15,
  tableHeadRowHeight: 37,
  tableToolbarHeight: 42,
  totalsGridRowsPerPage: 15,
  traceEventGridRowsPerPage: 15,
};

export const styles = createStyles({
  backIcon: {
    marginTop: "-3.5px",
    minWidth: "0px",
    padding: 0,
  },
  chevronRight: {
    fontSize: "0.6125rem",
    height: "22px",
    width: "22px",
  },
  copybtn: {
    "& $cursorPointer": {
      display: "none",
    },
    "&:hover": {
      "& $cursorPointer": {
        display: "inline-block",
        verticalAlign: "middle",
      },
    },
    display: "flex",
    position: "relative",
    width: "auto",
  },
  cursorPointer: {
    cursor: "pointer",
    paddingBottom: "5px",
    position: "absolute",
    right: "0",
    zIndex: 1,
  },

  eventZeroChevron: {
    visibility: "hidden",
  },
  events: {
    fontSize: "0.857rem",
    minWidth: "0px",
    padding: "0px 0px 0px 6px",
  },
  forceRightAlign: {
    flexDirection: "row-reverse",
    textAlign: "right",
  },
  iconHover: {
    "&:hover": {
      display: "block",
    },
    display: "none",
  },
  noWrap: {
    maxWidth: "21vw",
    minWidth: "0px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  queryWrap: {
    display: "block",
    marginRight: "26px",
    maxWidth: "39vw",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});
