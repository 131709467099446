import * as React from "react";
import MetricChart, { IMetricChartSeriesOptions, useMetrics } from "../../../../components/MetricChart";
import { IPerformanceAnalysisChartProps } from "../../types";
import { usePerformanceAnalysisContext } from "../../context";

const metrics = Object.freeze<IMetricChartSeriesOptions>([
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#98FB98",
    instance: "_Total",
    label: "ms/Read",
    metric: "os.disk.read.ms",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#20B2AA",
    instance: "_Total",
    label: "ms/Write",
    metric: "os.disk.write.ms",
  }),
]);

const DiskIOChart: React.FC<IPerformanceAnalysisChartProps> = ({ className }) => {
  const { contextMenuItems, dateRange, selectedRange, setSelectedRange, target } = usePerformanceAnalysisContext();
  const metricData = useMetrics({
    dateRange,
    metrics,
    target,
  });
  return (
    <MetricChart
      className={className}
      contextMenuItems={contextMenuItems}
      dateRange={dateRange}
      metricData={metricData}
      onSelectedRangeChange={setSelectedRange}
      selectedRange={selectedRange}
      target={target}
    />
  );
};

export default DiskIOChart;
