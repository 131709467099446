import * as React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useStyles from "./utils/styles";

export interface IFocusedDialogSelectorProps extends ButtonProps {
  text: string;
  secondaryText?: string;
  startIcon: React.ReactElement;
}

const FocusedDialogSelector = React.forwardRef<HTMLButtonElement, IFocusedDialogSelectorProps>(
  ({ secondaryText, startIcon, text, ...props }, ref) => {
    const classes = useStyles();

    return (
      <Button
        classes={{
          root: classes.selector,
          ...props.classes,
        }}
        disableElevation
        endIcon={<ExpandMoreIcon />}
        ref={ref}
        startIcon={startIcon}
        {...props}
      >
        {text}
        {secondaryText && (
          <Typography className={classes.selectorCaption} component="span">
            {secondaryText}
          </Typography>
        )}
      </Button>
    );
  },
);

export default FocusedDialogSelector;
