import * as React from "react";
import { ExecutedQueryTotalsOrderBy, IExecutedQueryTotalsResponse } from "../../api/models/ExecutedQueryTotals";
import { IExecutedStatementsResponse } from "../../api/models/ExecutedStatements";
import {
  ExecutedQueryTraceEventsOrderBy,
  IExecutedQueryTraceEventsResponse,
} from "../../api/models/IExecutedQueryTraceEventsCriteria";
import TopSqlService from "../../api/TopSqlService";
import { IDateRange } from "../../components/DateContext";
import { useSortContext } from "../sortContext";
import TopSqlContext from "./topSqlContext";
import {
  dataInfoDefault,
  totalsGridPageInfoDefault,
  traceEventGridPageInfoDefault,
  selectElementsDefault,
  statementGridPageInfoDefault,
} from "./topSqlDefaults";
import { topSqlReducer } from "./topSqlReducer";
import {
  CHANGE_CHART_FILTER,
  CHANGE_TOTALS_GRID_PAGE,
  CHANGE_TRACE_EVENT_GRID_PAGE,
  CHANGE_SELECTED_EVENT,
  CHANGE_SELECTED_STATEMENT,
  CHANGE_SELECTED_STATEMENT_EVENT,
  CHANGE_SELECTED_TOTAL,
  CHANGE_STATEMENT_GRID_PAGE,
  ISearchParams,
  ITopSqlContextProps,
  ITopSqlState,
  SET_CACHE_PLAN_ID,
  SET_IS_TOTALS,
  SET_STATEMENT_RESULTS,
  SET_STATEMENT_TRACE_EVENT_RESULTS,
  SET_TOTALS_RESULTS,
  SET_TOTALS_TRACE_EVENT_RESULTS,
  STATEMENT_EVENT_BACK_CLICK,
  STATEMENT_EVENT_CLICK,
  TOTALS_EVENT_BACK_CLICK,
  TOTALS_EVENT_CLICK,
  SEARCH_TOTALS,
  TOTALS_FILTER,
  TRACE_EVENT_FILTER,
  ITotalsParams,
  ITraceEventParams,
} from "./types";

const TopSqlContextProvider: React.FunctionComponent<ITopSqlContextProps> = ({
  children,
  deviceId,
  eventSourceConnectionId,
  visibleDates,
}) => {
  const topSqlService = new TopSqlService();
  const sortContext = useSortContext();

  const [state, dispatch] = React.useReducer(topSqlReducer, {
    ...selectElementsDefault,
    cachePlanId: null,
    chartFilter: null,
    eventSourceConnectionId,
    searchKey: "",
    statementGridPageInfo: statementGridPageInfoDefault,
    statementResults: dataInfoDefault,
    statementsTraceEventsResults: dataInfoDefault,
    totalsGridPageInfo: totalsGridPageInfoDefault,
    totalsResults: dataInfoDefault,
    totalsTraceEventsResults: dataInfoDefault,
    traceEventGridPageInfo: traceEventGridPageInfoDefault,
  } as ITopSqlState);

  const handleTotalsGridPageChange = React.useCallback(
    (pageIndex: number, pageSize: number): void => {
      dispatch({
        payload: {
          pageIndex,
          pageSize,
        },
        type: CHANGE_TOTALS_GRID_PAGE,
      });
    },
    [dispatch],
  );
  const handleTraceEventGridPageChange = React.useCallback(
    (pageIndex: number, pageSize: number): void => {
      dispatch({
        payload: {
          pageIndex,
          pageSize,
        },
        type: CHANGE_TRACE_EVENT_GRID_PAGE,
      });
    },
    [dispatch],
  );

  const handleStatementGridPageChange = (pageIndex: number, pageSize: number): void =>
    dispatch({
      payload: {
        pageIndex,
        pageSize,
      },
      type: CHANGE_STATEMENT_GRID_PAGE,
    });

  const handleSelectedStatementChange = (row: IExecutedStatementsResponse | null): void =>
    dispatch({ payload: row, type: CHANGE_SELECTED_STATEMENT });

  const handleSearchTotals = (keyword: ISearchParams): void =>
    dispatch({
      payload: {
        searchKey: keyword.searchKeyword ?? "",
      },
      type: SEARCH_TOTALS,
    });

  const handleTotalsFilter = (params: ITotalsParams): void =>
    dispatch({
      payload: {
        totalsDatabase: params.totalsDatabase ?? [],
      },
      type: TOTALS_FILTER,
    });

  const handleTraceEventFilter = (params: ITraceEventParams): void =>
    dispatch({
      payload: {
        applicationName: params.applicationName ?? [],
        databaseName: params.databaseName ?? [],
        errorKeyword: params.errorKeyword ?? [],
        eventClass: params.eventClass ?? [],
        hostName: params.hostName ?? [],
        loginName: params.loginName ?? [],
        spid: params.spid ?? [],
      },
      type: TRACE_EVENT_FILTER,
    });

  const handleSelectedTotalChange = (row: IExecutedQueryTotalsResponse): void =>
    dispatch({
      payload: {
        ...row,
        deviceId,
      },
      type: CHANGE_SELECTED_TOTAL,
    });

  const handleSelectedEventChange = (row: IExecutedQueryTraceEventsResponse): void =>
    dispatch({ payload: row, type: CHANGE_SELECTED_EVENT });

  const handleSelectedEventStatementChange = (row: IExecutedQueryTraceEventsResponse): void =>
    dispatch({ payload: row, type: CHANGE_SELECTED_STATEMENT_EVENT });

  const handleTotalsEventsBackClick = (): void => dispatch({ type: TOTALS_EVENT_BACK_CLICK });

  const handleTotalsEventsClick = (row: IExecutedQueryTotalsResponse): void =>
    dispatch({
      payload: row,
      type: TOTALS_EVENT_CLICK,
    });

  const handleStatementsEventsBackClick = (): void => dispatch({ type: STATEMENT_EVENT_BACK_CLICK });

  const handleStatementsEventsClick = (row: IExecutedStatementsResponse): void =>
    dispatch({
      payload: row,
      type: STATEMENT_EVENT_CLICK,
    });

  const handleChangeIsTotals = (): void => dispatch({ type: SET_IS_TOTALS });

  const handleChangeChartFilter = (newChartFilter: IDateRange | null): void =>
    dispatch({ payload: newChartFilter, type: CHANGE_CHART_FILTER });

  React.useEffect(() => {
    dispatch({ payload: null, type: CHANGE_CHART_FILTER });
  }, [visibleDates]);

  const gridDates = state.chartFilter || visibleDates;

  // Load Totals only if in totals mode
  React.useEffect(
    () => {
      if (!state.isTotals) {
        return;
      }

      if (!state.totalsResults.isLoading && !state.totalsResults.pageLoading) {
        dispatch({
          payload: {
            deviceId,
            results: {
              ...state.totalsResults,
              isLoading: true,
            },
          },
          type: SET_TOTALS_RESULTS,
        });
      }

      const limit = state.totalsGridPageInfo.pageSize;
      const offset = state.totalsGridPageInfo.pageIndex;
      const sortIsDescending = sortContext.sort.totals[0] ? sortContext.sort.totals[0].direction === "desc" : false;
      const sortProperty: ExecutedQueryTotalsOrderBy = sortContext.sort.totals[0]
        ? (sortContext.sort.totals[0].id as ExecutedQueryTotalsOrderBy)
        : "cpu";
      const controller = new AbortController();
      topSqlService
        .fetchExecutedQueryTotals(
          {
            databaseName: state.totalsDatabase,
            endDate: gridDates.to,
            eventSourceConnectionId,
            limit,
            offset,
            searchKeyword: state.searchKeyword,
            sortIsDescending,
            sortProperty,
            startDate: gridDates.from,
          },
          controller,
        )
        .then((results) => {
          if (!controller.signal.aborted) {
            dispatch({
              payload: {
                deviceId,
                results: {
                  data: results.items,
                  error: undefined,
                  isLoading: false,
                  pageLoading: false,
                  totalCount: results.rowCount,
                },
              },
              type: SET_TOTALS_RESULTS,
            });
          }
        })
        .catch((err) => {
          if (!controller.signal.aborted) {
            dispatch({
              payload: {
                deviceId,
                results: {
                  data: [],
                  error: err,
                  isLoading: false,
                  pageLoading: false,
                  totalCount: 0,
                },
              },
              type: SET_TOTALS_RESULTS,
            });
          }
        });
      return () => {
        controller.abort();
      };
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [
      state.totalsGridPageInfo,
      state.isTotals,
      gridDates.to,
      eventSourceConnectionId,
      gridDates.from,
      sortContext.sort.totals,
      state.searchKeyword,
      state.totalsDatabase,
    ],
  );

  // Load Statements only if totals have finished loading and it is in totals mode
  React.useEffect(
    () => {
      if (!state.isTotals || state.totalsResults.isLoading) {
        return;
      }

      if (!state.statementResults.isLoading && !state.statementResults.pageLoading) {
        dispatch({
          payload: {
            ...state.statementResults,
            isLoading: true,
          },
          type: SET_STATEMENT_RESULTS,
        });
      }

      const controller = new AbortController();
      topSqlService
        .fetchExecutedStatements(
          {
            databaseId: state.selectedTotal?.databaseId,
            endDate: gridDates.to,
            eventSourceConnectionId,
            parentId: state.selectedEvent?.parentId ?? undefined,
            startDate: gridDates.from,
            textMd5: state.selectedTotal?.textMd5,
          },
          controller,
        )
        .then((results) => {
          if (!controller.signal.aborted) {
            dispatch({
              payload: {
                data: results,
                error: undefined,
                isLoading: false,
                pageLoading: false,
                totalCount: results.length,
              },
              type: SET_STATEMENT_RESULTS,
            });
          }
        })
        .catch((err) => {
          if (!controller.signal.aborted) {
            dispatch({
              payload: {
                data: [],
                error: err,
                isLoading: false,
                pageLoading: false,
                totalCount: 0,
              },
              type: SET_STATEMENT_RESULTS,
            });
          }
        });
      return () => {
        controller.abort();
      };
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [
      state.totalsResults.isLoading,
      state.isTotals,
      //eslint-disable-next-line react-hooks/exhaustive-deps
      state.selectedTotal ? state.selectedTotal.databaseId : null,
      gridDates.to,
      eventSourceConnectionId,
      //eslint-disable-next-line react-hooks/exhaustive-deps
      state.selectedEvent ? state.selectedEvent.parentId : null,
      gridDates.from,
      //eslint-disable-next-line react-hooks/exhaustive-deps
      state.selectedTotal ? state.selectedTotal.textMd5 : null,
    ],
  );

  // Load the totals trace under the same conditions that the grid would be shown
  React.useEffect(
    () => {
      if (state.isTotals && !state.totalsTraceEventCriteria) {
        return;
      }
      let searchKeyword, eventClass, hostName, applicationName, databaseName, loginName, spid, errorKeyword;
      if (state.isTotals && state.totalsTraceEventCriteria) {
        searchKeyword = state.searchKeyword ? state.searchKeyword : null;
        eventClass = state.eventClass ? state.eventClass : null;
        hostName = state.hostName ? state.hostName : null;
        applicationName = state.applicationName ? state.applicationName : null;
        databaseName = state.databaseName ? state.databaseName : null;
        loginName = state.loginName ? state.loginName : null;
        spid = state.spid ? state.spid : null;
        errorKeyword = state.errorKeyword ? state.errorKeyword : null;
      }
      if (!state.totalsTraceEventsResults.isLoading && !state.totalsTraceEventsResults.pageLoading) {
        dispatch({
          payload: {
            ...state.totalsTraceEventsResults,
            isLoading: true,
          },
          type: SET_TOTALS_TRACE_EVENT_RESULTS,
        });
      }
      const limit = state.traceEventGridPageInfo.pageSize;
      const offset = state.traceEventGridPageInfo.pageIndex;

      const controller = new AbortController();

      const sortIsDescending = sortContext.sort.totalsTrace[0]
        ? sortContext.sort.totalsTrace[0].direction === "desc"
        : false;
      const sortProperty: ExecutedQueryTraceEventsOrderBy = sortContext.sort.totalsTrace[0]
        ? (sortContext.sort.totalsTrace[0].id as ExecutedQueryTraceEventsOrderBy)
        : "cpu";

      topSqlService
        .fetchExecutedQueriesTraceEvents(
          {
            applicationName: applicationName ?? state.applicationName,
            databaseId:
              state.isTotals && state.totalsTraceEventCriteria ? state.totalsTraceEventCriteria.databaseId : undefined,
            databaseName: databaseName ?? state.databaseName,
            endDate: gridDates.to,
            errorKeyword: errorKeyword ?? state.errorKeyword,
            eventClass: eventClass ?? state.eventClass,
            eventSourceConnectionId,
            hostName: hostName ?? state.hostName,
            isTraceStatement: !state.isTraceStatement,
            limit,
            loginName: loginName ?? state.loginName,
            offset,
            parentId: undefined,
            parentTextMd5: null,
            searchKeyword: searchKeyword ?? state.searchKeyword,
            sortIsDescending,
            sortProperty,
            spid: spid ?? state.spid,
            startDate: gridDates.from,
            textMd5:
              state.isTotals && state.totalsTraceEventCriteria ? state.totalsTraceEventCriteria.textMd5 : undefined,
          },
          controller,
        )
        .then((results) => {
          if (!controller.signal.aborted) {
            dispatch({
              payload: {
                data: results.items,
                error: undefined,
                isLoading: false,
                pageLoading: false,
                totalCount: results.rowCount,
              },
              type: SET_TOTALS_TRACE_EVENT_RESULTS,
            });
          }
        })
        .catch((err) => {
          if (!controller.signal.aborted) {
            dispatch({
              payload: {
                data: [],
                error: err,
                isLoading: false,
                pageLoading: false,
                totalCount: 0,
              },
              type: SET_TOTALS_TRACE_EVENT_RESULTS,
            });
          }
        });
      return () => {
        controller.abort();
      };
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [
      //eslint-disable-next-line react-hooks/exhaustive-deps
      state.isTotals && state.totalsTraceEventCriteria ? state.totalsTraceEventCriteria.databaseId : null,
      gridDates.to,
      eventSourceConnectionId,
      state.searchKeyword,
      state.eventClass,
      state.hostName,
      state.applicationName,
      state.databaseName,
      state.loginName,
      state.spid,
      state.errorKeyword,
      state.isTraceStatement,
      state.traceEventGridPageInfo,
      sortContext.sort.totalsTrace,
      gridDates.from,
      //eslint-disable-next-line react-hooks/exhaustive-deps
      state.isTotals && state.selectedTotal ? state.selectedTotal.textMd5 : null,
    ],
  );

  const statementDatabaseId = state.isTotals
    ? state.statementsTraceEventCriteria
      ? state.statementsTraceEventCriteria.databaseId
      : null
    : state.selectedEvent
    ? state.selectedEvent.databaseId
    : null;

  const statementParentTextMd5 = state.isTotals
    ? state.selectedTotal
      ? state.selectedTotal.textMd5
      : null
    : state.selectedEvent
    ? state.selectedEvent.textMd5
    : null;

  React.useEffect(
    () => {
      if (state.isTotals && !state.statementsTraceEventCriteria) {
        return;
      }
      if (!state.statementsTraceEventsResults.isLoading && !state.statementsTraceEventsResults.pageLoading) {
        dispatch({
          payload: {
            ...state.statementsTraceEventsResults,
            isLoading: true,
          },
          type: SET_STATEMENT_TRACE_EVENT_RESULTS,
        });
      }

      const limit = state.statementGridPageInfo.pageSize;
      const offset = state.statementGridPageInfo.pageIndex;
      const sortIsDescending = sortContext.sort.statementsTrace[0]
        ? sortContext.sort.statementsTrace[0].direction === "desc"
        : false;
      const sortProperty: ExecutedQueryTraceEventsOrderBy = sortContext.sort.statementsTrace[0]
        ? (sortContext.sort.statementsTrace[0].id as ExecutedQueryTraceEventsOrderBy)
        : "cpu";
      const controller = new AbortController();

      topSqlService
        .fetchExecutedQueriesTraceEvents(
          {
            applicationName: state.applicationName,
            databaseId: statementDatabaseId ?? undefined,
            databaseName: state.databaseName,
            endDate: gridDates.to,
            errorKeyword: state.errorKeyword,
            eventClass: state.eventClass,
            eventSourceConnectionId,
            hostName: state.hostName,
            isTraceStatement: state.isTotals ? !state.isTraceStatement : state.isTraceStatement,
            limit,
            loginName: state.loginName,
            offset,
            parentId: !state.isTotals && state.selectedEvent ? state.selectedEvent.parentId ?? undefined : undefined,
            parentTextMd5: statementParentTextMd5,
            searchKeyword: state.searchKeyword,
            sortIsDescending,
            sortProperty,
            spid: state.spid,
            startDate: gridDates.from,
            textMd5:
              state.isTotals && state.statementsTraceEventCriteria
                ? state.statementsTraceEventCriteria.textMd5
                : undefined,
          },
          controller,
        )
        .then((results) => {
          if (!controller.signal.aborted) {
            dispatch({
              payload: {
                data: results.items,
                error: undefined,
                isLoading: false,
                pageLoading: false,
                totalCount: results.rowCount,
              },
              type: SET_STATEMENT_TRACE_EVENT_RESULTS,
            });
          }
        })
        .catch((err) => {
          if (!controller.signal.aborted) {
            dispatch({
              payload: {
                data: [],
                error: err,
                isLoading: false,
                pageLoading: false,
                totalCount: 0,
              },
              type: SET_STATEMENT_TRACE_EVENT_RESULTS,
            });
          }
        });
      return () => {
        controller.abort();
      };
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [
      state.isTotals,
      state.isTraceStatement,
      //eslint-disable-next-line react-hooks/exhaustive-deps
      !state.isTotals && state.selectedEvent ? state.selectedEvent.parentId : null,
      statementDatabaseId,
      state.databaseName,
      state.eventClass,
      state.hostName,
      state.applicationName,
      state.loginName,
      state.spid,
      state.errorKeyword,
      gridDates.to,
      eventSourceConnectionId,
      state.statementGridPageInfo,
      //eslint-disable-next-line react-hooks/exhaustive-deps
      state.isTotals && state.statementsTraceEventCriteria ? state.statementsTraceEventCriteria.textMd5 : null,
      state.searchKeyword,
      statementParentTextMd5,
      sortContext.sort.statementsTrace,
      gridDates.from,
    ],
  );

  return (
    <TopSqlContext.Provider
      value={{
        ...state,
        handleChangeChartFilter,
        handleChangeIsTotals,
        handleSearchTotals,
        handleSelectedEventChange,
        handleSelectedEventStatementChange,
        handleSelectedStatementChange,
        handleSelectedTotalChange,
        handleStatementGridPageChange,
        handleStatementsEventsBackClick,
        handleStatementsEventsClick,
        handleTotalsEventsBackClick,
        handleTotalsEventsClick,
        handleTotalsFilter,
        handleTotalsGridPageChange,
        handleTraceEventFilter,
        handleTraceEventGridPageChange,
        setCachePlanId: (cachePlanId) => dispatch({ payload: cachePlanId, type: SET_CACHE_PLAN_ID }),
      }}
    >
      {children}
    </TopSqlContext.Provider>
  );
};

export default TopSqlContextProvider;
