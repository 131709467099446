import * as React from "react";
import { IUserTenant } from "../../../../../../../api/AccountService/AccountService";
import { useUserContext } from "../../../../../../../contexts/userContext";
import { renderTopologyTreeItem, ITreeItem } from "../TopologyTreeItem";
import useTopologyRouteMatch from "../../hooks/useTopologyRouteMatch";
import { ITopologyViewProps } from "../../TopologyList";
import { useLocation } from "react-router";

const TopologyFlatList = ({ filter, topology }: ITopologyViewProps): React.ReactElement => {
  const { tenants } = useUserContext();
  const { search } = useLocation();
  const { tenantMatch, targetMatch, featureMatch, groupMatch } = useTopologyRouteMatch();
  const { eventSourceConnections, devices } = topology;

  function buildUrl(tenantId: string, targetId: string): string {
    const urlItems = [tenantId, "targets", targetId];

    if (featureMatch?.params.feature) {
      urlItems.push(featureMatch?.params.feature);
    }

    return `/${urlItems.join("/")}${search}`;
  }

  const getTenantDevices = (tenant: IUserTenant): Array<ITreeItem> => {
    const devicesWithNulls: Array<ITreeItem | null> = devices
      .filter((device) => device.tenantId === tenant.id && device.isWatched)
      .map((device) => {
        const deviceChildren = eventSourceConnections.filter((esc) => esc.parentObjectId === device.objectId);

        return deviceChildren.length > 0
          ? null
          : {
              children: null,
              deviceType:  device.deviceType,
              id: device.objectId,
              isSelected: Boolean(targetMatch && targetMatch.params.targetId === device.objectId),
              isWatched: device.isWatched,
              name: device.name,
              to: buildUrl(device.tenantId, device.objectId),
              type: "device",
            };
      });
    const cleanedDevices: Array<ITreeItem> = devicesWithNulls.filter((device): device is ITreeItem => Boolean(device));
    return cleanedDevices;
  };

  const getTenantEsc = (tenant: IUserTenant): Array<ITreeItem> => {
    return eventSourceConnections
      .filter((esc) => esc.tenantId === tenant.id && esc.isWatched)
      .map((esc) => ({
        children: null,
        eventSourceConnectionType: esc.eventSourceConnectionType,
        id: esc.objectId,
        isSelected: Boolean(targetMatch && targetMatch.params.targetId === esc.objectId),
        isWatched: esc.isWatched,
        name: esc.name,
        to: buildUrl(esc.tenantId, esc.objectId),
        type: "esc",
      }));
  };

  const tenantItems: Array<ITreeItem> = tenants.map((tenant) => {
    return {
      children: [...getTenantDevices(tenant), ...getTenantEsc(tenant)],
      hasErrors: tenant.state === "failedToConnect" || topology.tenantErrors.has(tenant.id),
      id: tenant.id,
      isParentOfSelectedItem: Boolean(
        tenantMatch && (groupMatch || targetMatch) && tenantMatch.params.tenantId === tenant.id,
      ),
      isSelected: Boolean(tenantMatch && !groupMatch && !targetMatch && tenantMatch.params.tenantId === tenant.id),
      name: tenant.name,
      to: `/${tenant.id}`,
      type: "tenant",
    };
  });

  return <>{tenantItems.map((tenantItem) => renderTopologyTreeItem(tenantItem, filter))}</>;
};

export default TopologyFlatList;
