import * as React from "react";
import { useSnackbar, SnackbarKey } from "notistack";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useIntl } from "react-intl";
import { useRef } from "react";

const useStyles = makeStyles((theme) => ({
  action: {
    alignSelf: "flex-end",
    display: "flex",
  },
  body: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  header: {
    color: theme.palette.primary.light,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "700px",
  },
}));

function getInitialDismissed(): boolean {
  const isSupported = // If it says it's Chrome, we have to check if it also says that it's Opera
    (window.navigator.userAgent.includes("Chrome") && !window.navigator.userAgent.includes("OPR")) ||
    window.navigator.userAgent.includes("Edge");

  try {
    return isSupported || sessionStorage.getItem("browserAlert") === "true";
  } catch {
    return false;
  }
}

const BrowserAlert: React.FC = () => {
  const [dismissed] = React.useState<boolean>(getInitialDismissed());
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const classes = useStyles({});
  const intl = useIntl();
  const key = useRef<SnackbarKey>();

  const handleClick = (): void => {
    closeSnackbar(key.current);
    try {
      sessionStorage.setItem("browserAlert", "true");
    } catch {
      // Fail silently if sessionStorage is not available. It does not affect the success of the dismissal.
    }
  };

  const action = (
    <Button className={classes.action} color="primary" onClick={handleClick}>
      {intl.formatMessage({ id: "browserAlertDismiss" })}
    </Button>
  );

  const message = (
    <div className={classes.wrapper}>
      <Typography className={classes.header} variant="subtitle1">
        {intl.formatMessage({ id: "browserAlertHeader" })}
      </Typography>
      <p className={classes.body}>{intl.formatMessage({ id: "browserAlertMain" })}</p>
    </div>
  );

  React.useEffect(
    () => {
      if (!dismissed) {
        key.current = enqueueSnackbar(message, {
          action,
          anchorOrigin: { horizontal: "center", vertical: "top" },
          persist: true,
          preventDuplicate: true,
        });
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return null;
};

export default BrowserAlert;
