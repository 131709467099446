import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { EventLogSeverity } from "../../../../api/PerformanceAdvisor";

export interface INotificationItem {
  itemId: number;
  name: string;
  deviceId?: number;
  conditionId: number;
  conditionName: string;
  severity: EventLogSeverity;
}

interface INotificationItemProps {
  item: INotificationItem;

  styleSheet: { span?: string; typography?: string };
}

export const NotificationItem: React.FunctionComponent<INotificationItemProps> = ({ styleSheet, item }) => {
  return (
    <span className={styleSheet.span}>
      <Typography className={styleSheet.typography}>{item.name}</Typography>
      <Typography className={styleSheet.typography}>{item.conditionName}</Typography>
      <Typography className={styleSheet.typography}>
        <FormattedMessage id={item.severity} />
      </Typography>
    </span>
  );
};
