import * as React from "react";
import { MuiDataTable } from "@sentryone/material-ui";
import { Column } from "@sentryone/react-datatable";
import { filterPrincipal } from "../../../utils";
import { useIntl } from "react-intl";
import type { IUserGroup, IUser, IPrincipalSelection } from "../../../types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

export interface IUserGroupDetailsViewProps {
  selectedGroup: IUserGroup;
  handleSelection: (newSelection: IPrincipalSelection) => void;
  usersFilter: string;
}

const useStyles = makeStyles((theme) => ({
  cardTable: {
    "& table": {
      "& tr": {
        "& td": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
        cursor: "pointer",
      },
      tableLayout: "fixed",
    },
  },
}));

const UserGroupDetailsView: React.FC<IUserGroupDetailsViewProps> = ({
  selectedGroup,
  handleSelection,
  usersFilter,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Box className={classes.cardTable}>
      <MuiDataTable
        data={selectedGroup.users.filter((u) => filterPrincipal(u, usersFilter))}
        defaultPageSize={10}
        defaultSort={[{ direction: "asc", id: "firstName" }]}
        keySelector={(d) => d.id}
        pageVariant="rolling"
        tableRowProps={(d) =>
          d
            ? {
                onClick: () => handleSelection({ category: "users", id: d.id }),
              }
            : {}
        }
      >
        <Column<IUser>
          cellProps={(user) => ({ title: user.firstName })}
          field="firstName"
          header={intl.formatMessage({ id: "firstName" })}
        />
        <Column<IUser>
          cellProps={(user) => ({ title: user.lastName })}
          field="lastName"
          header={intl.formatMessage({ id: "lastName" })}
        />
        <Column<IUser>
          cellProps={(user) => ({ title: user.email ?? intl.formatMessage({ id: "notAvailable" }) })}
          field="email"
          header={intl.formatMessage({ id: "email" })}
          renderCell={(user) => user.email ?? intl.formatMessage({ id: "notAvailable" })}
        />
        <Column<IUser>
          cellProps={(user) => ({ title: user.login ?? intl.formatMessage({ id: "notAvailable" }) })}
          field="login"
          header={intl.formatMessage({ id: "login" })}
          renderCell={(user) => user.login ?? intl.formatMessage({ id: "notAvailable" })}
        />
      </MuiDataTable>
    </Box>
  );
};

export default UserGroupDetailsView;
