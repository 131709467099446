import * as React from "react";
import FocusedDialog, {
  FocusedDialogContent,
  FocusedDialogHeader,
  FocusedDialogSelector,
  FocusedDialogSelectorPopover,
  FocusedDialogToolbar,
  useFocusedDialogSelectorWithPopover,
} from "../../components/FocusedDialog";
import StorageIcon from "@material-ui/icons/Storage";
import { useCurrentTenant } from "../../hooks/useCurrentTenant";
import { useHistory } from "react-router";
import { useUserContext } from "../../contexts/userContext";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { alpha, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useIntl } from "react-intl";
import DocumentTitle from "../../components/DocumentTitle";
import Container from "@material-ui/core/Container";
import PermissionsGrid from "./components/PermissionsGrid";
import PrincipalList from "./components/PrincipalList";
import PrincipalDetails from "./components/PrincipalDetails";
import type { IModalCloseOptions, IPrincipal, IPrincipalSelection, IUser, IUserGroup } from "./types";
import Box from "@material-ui/core/Box";
import { useAllPrincipals, usePrincipalActions } from "./useFeatureAccess";
import ErrorBoundary from "../../components/ErrorBoundary";
import FeatureLoadingSpinner from "../../components/FeatureLoadingSpinner";
import { PrincipalConfirmationDialog, UserGroupModal, UserModal } from "./components/modals";
import CustomSnackbar from "../TopSql/components/CustomSnackbar";
import type { Color } from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  contentGrid: {
    alignItems: "start",
    display: "grid",
    gap: `${theme.spacing(2)}px`,
    gridTemplateAreas: `
    'list details'
    'list grid'
    `,
    gridTemplateColumns: "30% 70%",
    gridTemplateRows: "auto 1fr",
    marginTop: theme.spacing(2),
  },
  headerText: {
    color: theme.palette.common.white,
    fontSize: "1.45rem",
    letterSpacing: "0.5px",
    margin: theme.spacing(0, 4),
  },
  selected: {},
  tenantItemButton: {
    "&:focus": {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
    },
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
    },
  },
  tenantItemRoot: {
    "&$selected$selected": {
      backgroundColor: alpha(theme.palette.primary.main, 0.4),
    },
  },
  tenantList: {
    ...theme.typography.body1,
    color: theme.palette.common.white,
  },
}));

const Permissions: React.FC = () => {
  const classes = useStyles();
  const intl = useIntl();
  const currentTenant = useCurrentTenant()?.tenant;
  const { tenants } = useUserContext();
  const history = useHistory();
  const {
    onPopoverClose,
    onSelectorClick,
    popoverAnchorEl,
    popoverOpen,
    selectorRef,
  } = useFocusedDialogSelectorWithPopover();

  if (!currentTenant) throw Error("No tenant provided.");

  const { data, error, isLoading } = useAllPrincipals();

  const [selectedPrincipal, setSelectedPrincipal] = React.useState<IPrincipalSelection>();
  const [openUserGroupModal, setOpenUserGroupModal] = React.useState(false);
  const [openUserModal, setOpenUserModal] = React.useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [openToast, setOpenToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState<string>("test message");
  const [toastTitle, setToastTitle] = React.useState<string>("test title");
  const [toastType, setToastType] = React.useState<Color>("success");
  const [newPrincipal, setNewPrincipal] = React.useState<IPrincipalSelection>();

  const { deleteUser, deleteUserGroup } = usePrincipalActions();

  async function handleDeleteUser(confirmed: boolean, principalId: string): Promise<void> {
    setOpenDeleteConfirmation(false);
    if (confirmed) {
      if (data && data.users.size > 0)
        setSelectedPrincipal({
          category: "users",
          id: data.users.keys().next().value,
        });
      const { isSuccess } = await deleteUser({ objectId: principalId });

      if (isSuccess) {
        setToastMessage(intl.formatMessage({ id: "userDeletedSuccessfully" }));
        setToastTitle(intl.formatMessage({ id: "Success" }));
        setToastType("success");
        setOpenToast(true);
      } else {
        setToastMessage(intl.formatMessage({ id: "userDeletedWithError" }));
        setToastTitle(intl.formatMessage({ id: "Error" }));
        setToastType("error");
        setOpenToast(true);
      }
    }
  }

  async function handleDeleteUserGroup(confirmed: boolean, principalId: string): Promise<void> {
    setOpenDeleteConfirmation(false);
    if (confirmed) {
      // The default value for setSelectedPrincipal(undefined) is the first user
      // This avoids the PrincipalList jumps to "User" when a group is deleted
      if (data && data.userGroups.size > 0)
        setSelectedPrincipal({
          category: "groups",
          id: data.userGroups.keys().next().value,
        });
      const { isSuccess } = await deleteUserGroup({ objectId: principalId });

      if (isSuccess) {
        setToastMessage(intl.formatMessage({ id: "userGroupDeletedSuccessfully" }));
        setToastTitle(intl.formatMessage({ id: "Success" }));
        setToastType("success");
        setOpenToast(true);
      } else {
        setToastMessage(intl.formatMessage({ id: "userDeletedWithError" }));
        setToastTitle(intl.formatMessage({ id: "Error" }));
        setToastType("error");
        setOpenToast(true);
      }
    }
  }

  function handleEdit(category: string): void {
    category === "users" ? setOpenUserModal(true) : setOpenUserGroupModal(true);
    setEditing(true);
  }

  function handleUserModalClose(modalCloseResult: IModalCloseOptions): void {
    setOpenUserModal(false);
    setEditing(false);

    if (modalCloseResult.status === "SUCCESS" && data) {
      if (editing) {
        setToastMessage(intl.formatMessage({ id: "userEditedSuccessfully" }));
      } else if (!editing && modalCloseResult.id) {
        setToastMessage(intl.formatMessage({ id: "userAddedSuccessfully" }));
        setNewPrincipal({ category: "users", id: modalCloseResult.id });
      }
      setToastTitle(intl.formatMessage({ id: "Success" }));
      setToastType("success");
      setOpenToast(true);
    } else if (modalCloseResult.status === "ERROR") {
      setToastTitle(intl.formatMessage({ id: "Error" }));
      editing
        ? setToastMessage(intl.formatMessage({ id: "userEditedWithError" }))
        : setToastMessage(intl.formatMessage({ id: "userAddedWithError" }));
      setToastType("error");
      setOpenToast(true);
    }
    // If CLOSED, don't create a toast
  }

  function handleUserGroupModalClose(modalCloseResult: IModalCloseOptions): void {
    setOpenUserGroupModal(false);
    setEditing(false);

    if (modalCloseResult.status === "SUCCESS" && data && modalCloseResult.id) {
      if (editing) {
        setToastMessage(intl.formatMessage({ id: "userGroupEditedSuccessfully" }));
      } else {
        setToastMessage(intl.formatMessage({ id: "userGroupAddedSuccessfully" }));
        setNewPrincipal({ category: "groups", id: modalCloseResult.id });
      }
      setToastTitle(intl.formatMessage({ id: "Success" }));
      setToastType("success");
      setOpenToast(true);
    } else if (modalCloseResult.status === "ERROR") {
      editing
        ? setToastMessage(intl.formatMessage({ id: "userGroupEditedWithError" }))
        : setToastMessage(intl.formatMessage({ id: "userGroupAddedWithError" }));
      setToastTitle(intl.formatMessage({ id: "Error" }));
      setToastType("error");
      setOpenToast(true);
    }
    // If CLOSED, don't create a toast
  }

  const handleCloseToast = (): void => {
    setOpenToast(false);
  };

  React.useEffect(() => {
    if (data && !isLoading && !error && !selectedPrincipal) {
      if (data.users.size > 0)
        setSelectedPrincipal({
          category: "users",
          id: data.users.keys().next().value,
        });
      else if (data.userGroups.size > 0)
        setSelectedPrincipal({
          category: "groups",
          id: data.userGroups.keys().next().value,
        });
      // what happens if there are no users or user groups ?
    }
  }, [data, isLoading, error, selectedPrincipal]);

  React.useEffect(() => {
    if (data && !isLoading && !error && newPrincipal) {
      if (newPrincipal.category === "users") {
        setSelectedPrincipal({
          category: "users",
          id:
            Array.from(data.users.keys()).find((e) => data.users.get(e)?.email === newPrincipal.id) ??
            data.users.keys().next().value,
        });
      } else if (newPrincipal.category === "groups") {
        setSelectedPrincipal({
          category: "groups",
          id:
            Array.from(data.userGroups.keys()).find((e) => data.userGroups.get(e)?.name === newPrincipal.id) ??
            data.userGroups.keys().next().value,
        });
      }
      setNewPrincipal(undefined);
    }
  }, [data, isLoading, error, newPrincipal, setNewPrincipal]);

  const listOfUserGroups = React.useMemo(() => Array.from(data?.userGroups.values() ?? []), [data]);
  const listOfUsers = React.useMemo(() => Array.from(data?.users.values() ?? []), [data]);

  if (error) throw error;
  else if (isLoading || !selectedPrincipal || !data) return <FeatureLoadingSpinner />;
  else {
    const getSelectedPrincipalObject = (): IPrincipal => {
      if (selectedPrincipal.category === "users" && data.users.has(selectedPrincipal.id))
        // validated by the 'has' in the if that the entry does exist
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return data.users.get(selectedPrincipal.id)!;
      else if (selectedPrincipal.category === "groups" && data.userGroups.has(selectedPrincipal.id))
        // validated by the 'has' in the if that the entry does exist
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return data.userGroups.get(selectedPrincipal.id)!;
      else throw new Error(`No ${selectedPrincipal.category} was found with the id ${selectedPrincipal.id}.`);
    };

    return (
      <>
        <CustomSnackbar
          animation="Slide"
          aria-label="custom-snackbar"
          autoHideDuration={20000}
          handleClose={() => handleCloseToast()}
          message={toastMessage}
          open={openToast}
          slideDirection="right"
          title={toastTitle}
          type={toastType}
        />
        <DocumentTitle title="permissions" />
        <FocusedDialog onClose={() => history.push(`/${currentTenant.id}/health`)} open>
          <FocusedDialogHeader>
            <FocusedDialogToolbar>
              <Typography className={classes.headerText} component="h1" variant="h5">
                {intl.formatMessage({ id: "usersAndGroups" })}
              </Typography>
              <FocusedDialogSelector
                onClick={onSelectorClick}
                ref={selectorRef}
                startIcon={<StorageIcon />}
                text={currentTenant.name}
              />
              <FocusedDialogSelectorPopover anchorEl={popoverAnchorEl} onClose={onPopoverClose} open={popoverOpen}>
                <List className={classes.tenantList}>
                  {tenants.map((tenant) => (
                    <ListItem
                      button
                      classes={{
                        button: classes.tenantItemButton,
                        root: classes.tenantItemRoot,
                        selected: classes.selected,
                      }}
                      component={Link}
                      key={tenant.id}
                      selected={tenant.id === currentTenant.id}
                      to={`/${tenant.id}/permissions`}
                    >
                      <ListItemText disableTypography>{tenant.name}</ListItemText>
                    </ListItem>
                  ))}
                </List>
              </FocusedDialogSelectorPopover>
            </FocusedDialogToolbar>
          </FocusedDialogHeader>
          <FocusedDialogContent>
            <Container maxWidth="lg">
              <div className={classes.contentGrid}>
                <Box gridArea="list">
                  <PrincipalList
                    groups={listOfUserGroups}
                    handleSelection={(newSelection) => setSelectedPrincipal(newSelection)}
                    onNewUserClick={() => setOpenUserModal(true)}
                    onNewUserGroupClick={() => setOpenUserGroupModal(true)}
                    selectedPrincipal={selectedPrincipal}
                    users={listOfUsers}
                  />
                </Box>
                <Box gridArea="details">
                  <PrincipalDetails
                    handleDelete={() => setOpenDeleteConfirmation(true)}
                    handleEdit={() => handleEdit(selectedPrincipal.category)}
                    handleSelection={(newSelection) => setSelectedPrincipal(newSelection)}
                    selectedPrincipal={getSelectedPrincipalObject()}
                  />
                </Box>
                <Box gridArea="grid">
                  <ErrorBoundary variant="chart">
                    <PermissionsGrid selectedPrincipal={selectedPrincipal} />
                  </ErrorBoundary>
                </Box>
              </div>
            </Container>
            {openUserModal && (
              <UserModal
                handleClose={handleUserModalClose}
                open={openUserModal}
                selectedUser={editing ? (getSelectedPrincipalObject() as IUser) : null}
                userGroups={listOfUserGroups}
              />
            )}
            {openUserGroupModal && (
              <UserGroupModal
                handleClose={handleUserGroupModalClose}
                open={openUserGroupModal}
                //update this when we enabled editing
                selectedUserGroup={editing ? (getSelectedPrincipalObject() as IUserGroup) : null}
                users={listOfUsers}
              />
            )}
            {openDeleteConfirmation && (
              <PrincipalConfirmationDialog
                handleCloseUser={(confirmed, principalId) => handleDeleteUser(confirmed, principalId)}
                handleCloseUserGroup={(confirmed, principalId) => handleDeleteUserGroup(confirmed, principalId)}
                open={openDeleteConfirmation}
                principal={getSelectedPrincipalObject()}
              />
            )}
          </FocusedDialogContent>
        </FocusedDialog>
      </>
    );
  }
};

export default Permissions;
