import { CardTable, MuiDataTable, MuiTreeTable, TreeToggleColumn } from "@sentryone/material-ui";
import { Column } from "@sentryone/react-datatable";
import * as React from "react";
import type {
  IPrincipalSelection,
  ITargetDeviceResponse,
  ITargetEventSourceConnectionResponse,
  ITargetUnionResponse,
  ITargetParentResponse,
  ITargetResponse,
} from "../../types";
import Checkbox from "@material-ui/core/Checkbox";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import SearchTextField from "../../../../components/SearchTextField";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TargetGroupIcon from "@material-ui/icons/GroupWork";
import { MoRef, MoRefType } from "../../../../utilities/TopologyUtility";
import { useAvailableRoles, usePermissionActions, usePrincipal } from "../../useFeatureAccess";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import CustomSnackbar from "../../../TopSql/components/CustomSnackbar";
import TargetIcon from "../../../../components/icons/TargetIcon";

export interface IPermissionsGridProps {
  selectedPrincipal: IPrincipalSelection;
}

const useStyles = makeStyles((theme) => ({
  checkbox: {
    marginTop: "-2px",
    padding: 0,
  },
  iconSize20: {
    height: "20px",
    width: "20px",
  },
  infoIconSVG: {
    margin: theme.spacing(0, 0, 0, 1),
  },
  infoIconTooltip: {
    alignItems: "center",
    display: "inline-flex",
    padding: theme.spacing(0, 1),
  },
  roleHeader: {
    width: "15%",
  },
  root: {
    "& .MuiTable-root": {
      borderCollapse: "separate",
    },
  },
  table: {
    "& th:not(:first-child) > span:first-child": {
      justifyContent: "center",
    },
  },
  targetCellContent: {
    "& svg": {
      color: theme.palette.text.secondary,
    },
    alignItems: "center",
    display: "inline-flex",
    gap: `${theme.spacing(1)}px`,
  },
  targetCellText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "205px",
  },
  treeToggleBtn: {
    "& button": {
      marginTop: -theme.spacing(0.5),
    },
    display: "flex",
    height: "auto",
  },
}));

interface IPermissionsGridData {
  target: ITargetResponse;
  children: Array<IPermissionsGridData>;
  roles: Record<string, boolean>;
}

const PermissionsGrid: React.FC<IPermissionsGridProps> = ({ selectedPrincipal }) => {
  const intl = useIntl();
  const classes = useStyles();
  const [targetsFilter, setTargetsFilter] = React.useState<string>("");
  const [showHierarchy, setShowHierarchy] = React.useState<boolean>(false);

  const { data: asyncData, error, isLoading } = usePrincipal(selectedPrincipal);
  const { data: availableRoles = [], error: rolesError, isLoading: isLoadingRoles } = useAvailableRoles();
  const { addRole, removeRole } = usePermissionActions(selectedPrincipal.category);

  const data: Array<IPermissionsGridData> = React.useMemo(() => {
    if (!asyncData || !availableRoles) return [];

    const targets: ITargetUnionResponse[] = asyncData.targets.filter(
      (t) => !targetsFilter || t.name.toLowerCase().includes(targetsFilter.toLowerCase()),
    );

    //? For when the user is searching for a specific target
    const filterTargetGroup = (
      targetGroupType: MoRefType,
      groups: Array<ITargetParentResponse> = [],
    ): Array<ITargetParentResponse> => {
      let targetGroups = [...asyncData.targetGroups].filter((g) => g.moRef.type === targetGroupType);

      // filter the list multiple times to make sure empty groups are properly removed
      targetGroups.forEach(() => {
        targetGroups = targetGroups
          .map((d) => ({
            ...d,
            children: d.children
              ? d.children.filter(
                  (c) =>
                    // remove any groups from children that were themselves removed from the list
                    [...groups, ...targetGroups].some((g) => g.moRef.equals(c)) ||
                    targets.some((t) => t.moRef.equals(c)),
                )
              : [],
          }))
          // only keep groups that either have children or match the filter
          .filter((d) => d.children.length !== 0 || d.name.toLowerCase().includes(targetsFilter.toLowerCase()));
      });
      return targetGroups;
    };

    const devices: ITargetDeviceResponse[] = filterTargetGroup("device") as ITargetDeviceResponse[];
    const groups = filterTargetGroup("group", devices);
    const sites = filterTargetGroup("site", [...devices, ...groups]);

    const targetMap = new Map<string, IPermissionsGridData>();
    const targetsToAdd: Array<ITargetParentResponse | ITargetResponse> = [...targets, ...devices, ...groups, ...sites];
    const targetsToRemove = new Set<MoRef>();

    targetsToAdd.forEach((target: ITargetResponse) => {
      targetMap.set(target.moRef.toString(), {
        children: [],
        roles: Object.fromEntries(
          Array.from(availableRoles, ([role_id, role_name]) => [
            role_id,
            asyncData.roleAccess.some(
              (roleAccess) => roleAccess.topologyItem.moRef.equals(target.moRef) && roleAccess.role.name === role_name,
            ),
          ]),
        ),
        target: {
          ...target,
        },
      });
    });

    if (showHierarchy) {
      targetMap.forEach((value) => {
        const foundTarget: ITargetResponse | ITargetParentResponse | undefined = targetsToAdd.find((t) =>
          t.moRef.equals(value.target.moRef),
        );

        if (foundTarget && "children" in foundTarget) {
          // get all of the children from the targetMap and filter out any undefineds
          const targetChildren = foundTarget.children
            ? foundTarget.children
                .map((c) => targetMap.get(c.toString()))
                .filter((t): t is NonNullable<IPermissionsGridData> => Boolean(t))
            : [];

          // modify the object to populate children from targetMap
          value.children = targetChildren;

          // add child targets to be removed from root of map
          foundTarget.children ? foundTarget.children.forEach((c) => targetsToRemove.add(c)) : null;
        }
      });

      // remove child items from root of tree
      targetsToRemove.forEach((moRef) => {
        targetMap.delete(moRef.toString());
      });
    } else if (targetsFilter)
      targetMap.forEach((value, key) => {
        if (!value.target.name.toLowerCase().includes(targetsFilter.toLowerCase())) {
          targetMap.delete(key);
        }
      });

    // reverse so that sites appear above groups appear above targets
    return Array.from(targetMap.values()).reverse();
  }, [availableRoles, asyncData, showHierarchy, targetsFilter]);

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleRoleChange = async (checked: boolean, targetObjectId: string, roleId: string): Promise<void> => {
    if (checked) {
      const { isSuccess } = await addRole({ objectId: targetObjectId, principalId: selectedPrincipal.id, roleId });
      if (isSuccess) {
        setMessage(intl.formatMessage({ id: "roleAssignedSuccess" }));
        setOpen(true);
      }
    } else {
      const { isSuccess } = await removeRole({ objectId: targetObjectId, principalId: selectedPrincipal.id, roleId });
      if (isSuccess) {
        setMessage(intl.formatMessage({ id: "roleRemovedSuccess" }));
        setOpen(true);
      }
    }
  };

  const renderTargetGroup = (dataCell: IPermissionsGridData): JSX.Element => {
    switch (dataCell?.target?.moRef?.type) {
      case "site":
      case "group":
        return (
          <span className={classes.targetCellContent}>
            <TargetGroupIcon className={classes.iconSize20} fontSize="small" />
            <Tooltip placement="top" title={dataCell.target.name}>
              <span className={classes.targetCellText}>{dataCell.target.name}</span>
            </Tooltip>
          </span>
        );
      case "connection":
      case "device":
        return (
          <span className={classes.targetCellContent}>
            <TargetIcon
              activeTargetType={
                dataCell?.target?.moRef?.type === "connection"
                  ? (dataCell.target as ITargetEventSourceConnectionResponse).eventSourceConnectionType
                  : (dataCell.target as ITargetDeviceResponse).deviceType
              }
              className={classes.iconSize20}
              fontSize="inherit"
              sizeIcon="medium"
            />
            <Tooltip placement="top" title={dataCell.target.name}>
              <span className={classes.targetCellText}>{dataCell.target.name}</span>
            </Tooltip>
          </span>
        );
    }
  };

  const getRoleInfo = React.useCallback(
    (roleName: string) => {
      switch (roleName) {
        case "Administrator":
          return intl.formatMessage({ id: "administratorInfoTT" });
        case "Alerts":
          return intl.formatMessage({ id: "alertsInfoTT" });
        case "Performance":
          return intl.formatMessage({ id: "performanceInfoTT" });
        case "Query Tuner":
          return intl.formatMessage({ id: "queryTunerInfoTT" });
        default:
          return roleName;
      }
    },
    [intl],
  );

  const sortedColumns = React.useMemo(
    () =>
      Array.from(availableRoles, ([role_id, role_name]) => ({
        id: role_id,
        info: getRoleInfo(role_name),
        name: role_name,
      })).sort((a, b) => a.name.localeCompare(b.name)),
    [availableRoles, getRoleInfo],
  );

  const renderColumns = (): JSX.Element[] =>
    sortedColumns.map(({ id: role_id, info: role_info, name: role_name }) => (
      <Column<IPermissionsGridData>
        align="center"
        header={
          <span className={classes.infoIconTooltip}>
            {role_name}
            <Tooltip data-testid="Permission-Roleinfo-Tooltip" placement="top" title={role_info}>
              <InfoIcon className={classes.infoIconSVG} fontSize="small" />
            </Tooltip>
          </span>
        }
        headerProps={{ className: classes.roleHeader }}
        id={role_id}
        key={role_id}
        renderCell={(d) => (
          <Checkbox
            checked={d.roles[role_id]}
            className={classes.checkbox}
            color="primary"
            inputProps={{ "aria-label": `${d.target.name} - ${role_name}` }}
            onChange={(_, checked) => handleRoleChange(checked, d.target.objectId, role_id)}
          />
        )}
        sort={(a, b) => +a.roles[role_id] - +b.roles[role_id]}
      />
    ));

  const commonTableProps = {
    data,
    defaultPageSize: 10,
    isLoading: isLoading || isLoadingRoles,
    keySelector: (x: IPermissionsGridData) => x.target.moRef.toString(),
    pageVariant: "rolling" as const,
  };

  if (error) throw error;
  if (rolesError) throw rolesError;

  return (
    <>
      <CustomSnackbar
        animation="Slide"
        aria-label="custom-snackbar"
        autoHideDuration={20000}
        handleClose={handleClose}
        message={message}
        open={open}
        slideDirection="right"
      />
      <CardTable
        actions={
          <>
            <SearchTextField
              aria-label={intl.formatMessage({ id: "filterTargets" })}
              hiddenLabel
              onChange={(e) => setTargetsFilter(e.target.value)}
              placeholder={intl.formatMessage({ id: "filterMsg" })}
              value={targetsFilter}
            />
            <FormControlLabel
              control={
                <Switch checked={showHierarchy} color="primary" onChange={(_, value) => setShowHierarchy(value)} />
              }
              label={intl.formatMessage({ id: "showHierarchy" })}
              labelPlacement="start"
            />
          </>
        }
        aria-label={intl.formatMessage({ id: "featureAccess" })}
        className={classes.table}
        key={`${"name" in selectedPrincipal ? "group" : "user"}-${selectedPrincipal.id}`}
        title={intl.formatMessage({ id: "featureAccess" })}
      >
        {!showHierarchy && (
          <MuiDataTable {...commonTableProps}>
            <Column<IPermissionsGridData>
              header={intl.formatMessage({ id: "target" })}
              id="target"
              renderCell={renderTargetGroup}
              sort={(a, b) => a.target.name.localeCompare(b.target.name)}
            />
            {renderColumns()}
          </MuiDataTable>
        )}
        {showHierarchy && (
          <div className={classes.root}>
            <MuiTreeTable {...commonTableProps} childDataSelector={(d) => d.children}>
              <TreeToggleColumn<IPermissionsGridData>
                buttonTitle={(d, open) =>
                  open
                    ? intl.formatMessage({ id: "collapseTarget" }, { MoRefID: d.target.name })
                    : intl.formatMessage({ id: "expandTarget" }, { MoRefID: d.target.name })
                }
                cellProps={() => ({ className: classes.treeToggleBtn })}
                header={intl.formatMessage({ id: "target" })}
                id="target"
                renderCell={renderTargetGroup}
              />
              {renderColumns()}
            </MuiTreeTable>
          </div>
        )}
      </CardTable>
    </>
  );
};

export default PermissionsGrid;
