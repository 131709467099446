import * as AGGREGATE_METRIC_QUERY from "./MetricAggregateDataQuery.graphql";
import type {
  IAggregateMetricDataQueryResult,
  IAggregateMetricDataQueryResultMetric,
  IAggregateMetricDataQueryVariables,
} from "./MetricAggregateDataQuery.types";
import * as React from "react";
import { v4 as uuid } from "uuid";
import { MetricInstanceKey, ALL_INSTANCES } from "./types";
import { AggregateTypes, MetricUnitOfMeasure } from "./types";
import { useAsync } from "../../hooks/Async";
import { MoRef } from "../../utilities/TopologyUtility";
import { IDateRange } from "../DateContext";
import { ITopologyItemDevice, ITopologyItemEventSourceConnection } from "../TopologyContext";
import { useApolloClient } from "@apollo/react-hooks";

interface IAggregateMetricDataQueryResultWithUUID extends IAggregateMetricDataQueryResultMetric {
  uuid: ReturnType<typeof uuid>;
}

export interface IAggregateMetricOptions {
  readonly label: string;
  readonly metricKey: string;
  readonly instanceName: string | null;
  readonly aggregateType: AggregateTypes;
}

export interface IAggregateMetricDataResult {
  readonly key: string;
  readonly label: string;
  readonly metricKey: string;
  readonly instanceName: string | null;
  readonly value: number;
  readonly unitOfMeasure: MetricUnitOfMeasure;
}

export interface IUseAggregateMetricsParams {
  readonly dateRange: IDateRange;
  readonly metrics: IAggregateMetricOptions[];
  readonly target: ITopologyItemDevice | ITopologyItemEventSourceConnection;
}

export interface IUseAggregateMetricsResult {
  error: Error | null;
  isLoading: boolean;
  data: IAggregateMetricDataResult[];
}

type AggregateMetricMap = ReadonlyMap<string, Set<MetricInstanceKey>>;

function formatAggregateMapKey(metricName: string, aggregateType: AggregateTypes): string {
  return `${metricName}_${aggregateType}`;
}
function parseAggregateMapKey(mapKey: string): [string, AggregateTypes] {
  const [metric, aggregateType] = mapKey.split("_");
  const typedAggregateType = aggregateType as keyof typeof AggregateTypes;
  return [metric, AggregateTypes[typedAggregateType]];
}

function aggregateMetricMapEquals(a: AggregateMetricMap, b: AggregateMetricMap): boolean {
  if (a === b) {
    // References are equal, so equality is certain
    return true;
  } else if (a.size !== b.size) {
    // Size is different, so inequality is certain
    return false;
  } else {
    return Array.from(a.entries()).every(([metric, aInstances]) => {
      const bInstances = b.get(metric);
      if (!bInstances || aInstances.size !== bInstances.size) {
        return false;
      } else {
        return Array.from(aInstances).every((x) => bInstances.has(x));
      }
    });
  }
}

/**
 * Normalizes and memoizes the metrics.
 * @param metrics
 */
function useNormalizedAggregateMetrics(metrics: readonly IAggregateMetricOptions[]): [AggregateMetricMap, boolean] {
  const newValue: AggregateMetricMap = metrics.reduce((prev, curr) => {
    const mapKey: string = formatAggregateMapKey(curr.metricKey, curr.aggregateType);
    const existing = prev.get(mapKey);
    if (existing && curr.instanceName) {
      existing.add(curr.instanceName);
    } else {
      prev.set(
        mapKey,
        new Set<MetricInstanceKey>([curr.instanceName]),
      );
    }
    return prev;
  }, new Map<string, Set<MetricInstanceKey>>());

  const valueRef = React.useRef(newValue);

  const hasNewMetrics = !aggregateMetricMapEquals(newValue, valueRef.current);
  if (hasNewMetrics) {
    valueRef.current = newValue;
  }

  return [valueRef.current, hasNewMetrics];
}

export function useAggregateMetrics({
  dateRange,
  metrics,
  target,
}: IUseAggregateMetricsParams): IUseAggregateMetricsResult {
  const client = useApolloClient();
  const [normalizedAggregateMetrics, hasNewAggregateMetrics] = useNormalizedAggregateMetrics(metrics);
  const promiseFn = React.useCallback(
    async (_: unknown, { signal }: AbortController) => {
      const moRef = MoRef.fromTopologyItem(target).toString();
      const metricPromises = Array.from(
        normalizedAggregateMetrics.entries(),
        async ([metric_aggregate, instances]): Promise<IAggregateMetricDataQueryResultMetric> => {
          const [metric, aggregateType] = parseAggregateMapKey(metric_aggregate);

          const instanceNames = Array.from(instances).filter((x): x is string => typeof x === "string");
          const results = await client.query<IAggregateMetricDataQueryResult, IAggregateMetricDataQueryVariables>({
            context: {
              fetchOptions: { signal },
            },
            query: AGGREGATE_METRIC_QUERY,
            variables: {
              aggregateType: aggregateType,
              dateRange,
              instances: instances.has(ALL_INSTANCES) || instanceNames.length === 0 ? null : instanceNames,
              metric,
              moRef,
            },
          });
          if (results.data.target?.metric) {
            return {
              ...results.data.target.metric,
              // If alias was matched, key could be upgraded
              // To continue matching to metric config when building series we need to revert the key
              key: formatAggregateMapKey(metric, aggregateType),
            };
          } else {
            return {
              aggregate: [],
              key: formatAggregateMapKey(metric, aggregateType),
              unitOfMeasure: MetricUnitOfMeasure.Unspecified,
            };
          }
        },
      );
      const metricResults = await Promise.all(metricPromises);
      const map: ReadonlyMap<string, IAggregateMetricDataQueryResultWithUUID> = new Map(
        metricResults.map((x) => [x.key, { ...x, uuid: uuid() }]),
      );
      return map;
    },
    [client, dateRange, normalizedAggregateMetrics, target],
  );

  const { data = new Map<string, IAggregateMetricDataQueryResultWithUUID>(), error = null, isLoading } = useAsync(
    promiseFn,
  );

  const aggregateMetricData = metrics.flatMap<IAggregateMetricDataResult>((config, i) => {
    const metricData = data.get(formatAggregateMapKey(config.metricKey, config.aggregateType));
    if (!metricData) {
      return [];
    } else {
      const instanceData = metricData.aggregate.find((x) => x.instanceName === config.instanceName);
      if (!instanceData) {
        return {
          instanceName: config.instanceName,
          // Key uses `uuid` to change on data reload and `i` to be distinct between duplicate metric configs
          key: `${metricData.key}-${config.instanceName}-${metricData.uuid}-${i}`,
          label: config.label,
          metricKey: config.metricKey,
          unitOfMeasure: metricData.unitOfMeasure,
          value: 0,
        };
      } else {
        return {
          instanceName: instanceData.instanceName,
          // Key uses `uuid` to change on data reload and `i` to be distinct between duplicate metric configs
          key: `${metricData.key}-${instanceData.instanceName}-${metricData.uuid}-${i}`,
          label: config.label,
          metricKey: config.metricKey,
          unitOfMeasure: metricData.unitOfMeasure,
          value: instanceData.value,
        };
      }
    }
  });

  return {
    data: aggregateMetricData,
    error,
    // useAsync returns does not update isLoading until the next rerender after dependencies changed
    isLoading: isLoading || hasNewAggregateMetrics,
  };
}
