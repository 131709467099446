import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tooltip from "@material-ui/core/Tooltip";
import JumpToSQLSentryIcon from "@material-ui/icons/ScreenShare";
import Alert from "@material-ui/lab/Alert";
import * as React from "react";
import { useIntl } from "react-intl";
import { useRouteMatch } from "react-router-dom";
import DateSelection from "../../../../components/DateSelection";
import { FocusedDialogHeader, FocusedDialogToolbar } from "../../../../components/FocusedDialog";
import { ITargetFeatures } from "../../../../components/TopologyContext";
import { Flag } from "../../../../contexts/featuresContext";
import { useUserContext } from "../../../../contexts/userContext";
import { useCurrentTarget } from "../../../../hooks/useCurrentTarget";
import DateRangeSelector from "../DateRangeSelector/DateRangeSelector";
import TargetDialogHeaderLink from "./TargetDialogHeaderLink";
import TargetDialogHeaderSelector from "./TargetDialogHeaderSelector";

const useStyles = makeStyles((theme) => ({
  addDashboardIcon: {
    marginLeft: theme.spacing(2),
  },
  addDashboardMenuItem: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  alert: {
    borderRadius: 0,
    justifyContent: "center",
    padding: theme.spacing(1),
  },
  appBar: {
    backgroundColor: "#444",
    color: "rgba(255, 255, 255, 0.9019607843137255)", // #ffffffe6
  },
  caption: {
    color: "rgba(255, 255, 255, 0.7019607843137254)", // #ffffffb3
  },
  dashboardMenu: {
    borderRadius: "0px 0px 4px 4px",
    boxShadow: "0px 20px 20px 5px rgba(0, 0, 0, 0.14)",
    marginTop: "-2px", // this moves the menu up to cover the active tab indicator
    padding: theme.spacing(1, 2),
  },
  dashboardMenuItem: {
    fontSize: "1.05rem",
    justifyContent: "center",
    marginBottom: "2px",
  },
  // handling long dashboard names
  dashboardMenuItemName: {
    overflow: "hidden",
    textAlign: "center",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
  },
  dashboardTab: {
    backgroundColor: "#fff",
  },
  toolbarContent: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
}));

const enum TabValues {
  blocking = "blocking",
  deadlocks = "deadlocks",
  health = "health",
  performance = "performance",
  storage = "storage",
  tempdb = "tempdb",
  topsql = "topsql",
}

const TargetDialogHeader: React.FC = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { userInfo } = useUserContext();
  const currentMatch = useRouteMatch();
  const targetMatch = useRouteMatch<{ targetId: string }>(`${currentMatch.url}/targets/:targetId`);
  const featureMatch = useRouteMatch<{ feature?: string }>(`${currentMatch.url}/targets/:targetId/:feature?`);
  const target = useCurrentTarget();

  const targetFeatures: ITargetFeatures = target?.features ?? {
    alerts: false,
    blocking: false,
    deadlocks: false,
    health: true,
    performance: false,
    permissionAdministration: false,
    storage: false,
    tempdb: false,
    topsql: false,
  };

  // if the user switched to a target that does not support the current selected feature, redirect to Health
  const tabValue =
    featureMatch?.params.feature && featureMatch.params.feature in targetFeatures
      ? featureMatch.params.feature
      : "health";

  const tenant = target ? userInfo.tenants.find((x) => x.id === target.tenantId) : undefined;

  const isForHeader = true;
  return (
    <>
      <FocusedDialogHeader data-testid="targetDialogHeader">
        <FocusedDialogToolbar>
          <div className={classes.toolbarContent}>
            {target && (
              <span>
                <TargetDialogHeaderSelector target={target} />
                <Flag flag="jumpToSqlSentry">
                  <>
                    {tenant?.sentryOneClientUri && (
                      <Tooltip arrow placement="right" title={intl.formatMessage({ id: "openTargetInSqlSentry" })}>
                        <IconButton color="inherit" href={tenant.sentryOneClientUri} size="small">
                          <JumpToSQLSentryIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                </Flag>
              </span>
            )}
            {target && <span>{tabValue === "health" ? <></>: <DateSelection color="inherit"/>}</span>}
          </div>
        </FocusedDialogToolbar>
        {target && "isWatched" in target && !target.isWatched && (
          <Alert className={classes.alert} severity="warning" square>
            {intl.formatMessage({ id: "targetUnwatched" })}
          </Alert>
        )}
        {targetMatch && featureMatch && (
          <>
            <Tabs value={tabValue} variant="fullWidth">
              {targetFeatures.health && (
                <TargetDialogHeaderLink
                  label={intl.formatMessage({ id: "health" })}
                  to={`${targetMatch.url}/health`}
                  value={TabValues.health}
                />
              )}
              {targetFeatures.performance && (
                <TargetDialogHeaderLink
                  label={intl.formatMessage({ id: "performance" })}
                  to={`${targetMatch.url}/performance`}
                  value={TabValues.performance}
                />
              )}
              {targetFeatures.storage && (
                <TargetDialogHeaderLink
                  label={intl.formatMessage({ id: "storage" })}
                  to={`${targetMatch.url}/storage`}
                  value={TabValues.storage}
                />
              )}
              {targetFeatures.topsql && (
                <TargetDialogHeaderLink
                  label={intl.formatMessage({ id: "topSql" })}
                  to={`${targetMatch.url}/topsql`}
                  value={TabValues.topsql}
                />
              )}
              {targetFeatures.blocking && (
                <TargetDialogHeaderLink
                  label={intl.formatMessage({ id: "blocking" })}
                  to={`${targetMatch.url}/blocking`}
                  value={TabValues.blocking}
                />
              )}
              {targetFeatures.deadlocks && (
                <TargetDialogHeaderLink
                  label={intl.formatMessage({ id: "deadlocks" })}
                  to={`${targetMatch.url}/deadlocks`}
                  value={TabValues.deadlocks}
                />
              )}
              {targetFeatures.tempdb && (
                <TargetDialogHeaderLink
                  label={intl.formatMessage({ id: "tempDb" })}
                  to={`${targetMatch.url}/tempdb`}
                  value={TabValues.tempdb}
                />
              )}
            </Tabs>
          </>
        )}
      </FocusedDialogHeader>
    </>
  );
};

export default TargetDialogHeader;
