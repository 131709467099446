import { useApolloClient } from "@apollo/react-hooks";
import * as React from "react";
import { v4 as uuid } from "uuid";
import { useAsync } from "../../hooks/Async";
import { MoRef } from "../../utilities/TopologyUtility";
import { IDateRange } from "../DateContext";
import { ITopologyItemDevice, ITopologyItemEventSourceConnection, useTopology } from "../TopologyContext";
import * as METRIC_QUERY from "./MetricDataQuery.graphql";
import type {
  IMetricDataQueryResult,
  IMetricDataQueryResultMetric,
  IMetricDataQueryResultMetricSeries,
  IMetricDataQueryVariables,
} from "./MetricDataQuery.types";
import { ALL_INSTANCES, MetricUnitOfMeasure } from "./types";
import type {
  IMetricChartSeries,
  IMetricChartSeriesOptions,
  MetricChartInstanceThunk,
  MetricInstanceKey,
} from "./types";
import { mapQueryResultPoints } from "./utils";

interface IMetricDataResult extends IMetricDataQueryResultMetric {
  uuid: ReturnType<typeof uuid>;
}

export interface IUseMetricsParams {
  readonly dateRange: IDateRange;
  /**
   * Metrics to retrieve data for.
   * Should be memoized.
   * A new array instance will trigger the data to be reloaded.
   */
  readonly metrics: readonly IMetricChartSeriesOptions[];
  readonly target: ITopologyItemDevice | ITopologyItemEventSourceConnection;
  isCustomizeChart?: boolean;
}

export interface IUseMetricsResult {
  error: Error | null;
  isLoading: boolean;
  series: readonly IMetricChartSeries[];
}

function flattenThunk<T extends boolean | number | string | null | undefined>(
  thunk: MetricChartInstanceThunk<T>,
  series: IMetricDataQueryResultMetricSeries,
): T {
  return typeof thunk === "function" ? thunk(series.instance) : thunk;
}

type MetricMap = ReadonlyMap<string, ReadonlySet<MetricInstanceKey>>;

type MetricMapForMultiTarget = ReadonlyMap<string, { target: MoRef; instances: ReadonlySet<MetricInstanceKey> }>;

function metricMapEquals(a: MetricMap, b: MetricMap): boolean {
  if (a === b) {
    // References are equal, so equality is certain
    return true;
  } else if (a.size !== b.size) {
    // Size is different, so inequality is certain
    return false;
  } else {
    return Array.from(a.entries()).every(([metric, aInstances]) => {
      const bInstances = b.get(metric);
      if (!bInstances || aInstances.size !== bInstances.size) {
        return false;
      } else {
        return Array.from(aInstances).every((x) => bInstances.has(x));
      }
    });
  }
}

function metricMapEqualsForMultiTarget(a: MetricMapForMultiTarget, b: MetricMapForMultiTarget): boolean {
  if (a === b) {
    // References are equal, so equality is certain
    return true;
  } else if (a.size !== b.size) {
    // Size is different, so inequality is certain
    return false;
  } else {
    return Array.from(a.entries()).every(([metric, aInstances]) => {
      const bInstances = b.get(metric);
      if (!bInstances?.instances || aInstances.instances.size !== bInstances.instances.size) {
        return false;
      }
      return Array.from(aInstances.instances).every((x) => bInstances.instances.has(x));
    });
  }
}

function formatMetricMapKey(metricName: string, instance: string, target: string): string {
  return `${metricName}++${instance}++${target}`;
}

function parseMetricMapKey(mapKey: string): [string, string, string] {
  const [metric, instance, target] = mapKey.split("++");
  return [metric, instance, target];
}
/**
 * Normalizes and memoizes the metrics.
 * @param metrics
 */
function useNormalizedMetrics(metrics: readonly IMetricChartSeriesOptions[]): [MetricMap, boolean] {
  const newValue: MetricMap = metrics.reduce((prev, curr) => {
    const existing = prev.get(curr.metric);
    if (existing && curr.instance) {
      existing.add(curr.instance);
    } else {
      prev.set(
        curr.metric,
        new Set<MetricInstanceKey>([curr.instance]),
      );
    }
    return prev;
  }, new Map<string, Set<MetricInstanceKey>>());

  const valueRef = React.useRef(newValue);

  const hasNewMetrics = !metricMapEquals(newValue, valueRef.current);
  if (hasNewMetrics) {
    valueRef.current = newValue;
  }

  return [valueRef.current, hasNewMetrics];
}

function useNormalizedMetricsForTargets(
  metrics: readonly IMetricChartSeriesOptions[],
): [MetricMapForMultiTarget, boolean] {
  const newValue = metrics.reduce((prev, curr) => {
    const mapKey: string = formatMetricMapKey(
      curr.metric,
      curr.instance && typeof curr.instance === "string" ? curr.instance : "NA",
      curr.moRef ? curr.moRef.toString() : "NA",
    );

    const existing = prev.get(curr.metric);
    if (existing && curr.instance) {
      existing.instances.add(curr.instance);
    } else {
      curr.moRef &&
        prev.set(mapKey, {
          instances: new Set([curr.instance]),
          target: curr.moRef,
        });
    }
    return prev;
  }, new Map<string, { target: MoRef; instances: Set<MetricInstanceKey> }>());

  const valueRef = React.useRef(newValue);

  const hasNewMetricsForMultiTarget = !metricMapEqualsForMultiTarget(newValue, valueRef.current);
  if (hasNewMetricsForMultiTarget) {
    valueRef.current = newValue;
  }

  return [valueRef.current, hasNewMetricsForMultiTarget];
}

export default function useMetrics({
  dateRange,
  metrics,
  target,
  isCustomizeChart = false,
}: IUseMetricsParams): IUseMetricsResult {
  const client = useApolloClient();

  const [normalizedMetrics, hasNewMetrics] = useNormalizedMetrics(metrics);
  const [normalizedMetricsForMultiTarget, hasNewMetricsForMultiTarget] = useNormalizedMetricsForTargets(metrics);
  const { findByMoRef } = useTopology();

  const promiseFn = React.useCallback(
    async (_: unknown, { signal }: AbortController) => {
      const moRefVar = MoRef.fromTopologyItem(target).toString();
      const metricPromises = Array.from(
        normalizedMetrics.entries(),
        async ([metric, instances]): Promise<IMetricDataQueryResultMetric> => {
          const instanceNames = Array.from(instances).filter((x): x is string => typeof x === "string");

          const results = await client.query<IMetricDataQueryResult, IMetricDataQueryVariables>({
            context: {
              fetchOptions: { signal },
            },
            query: METRIC_QUERY,
            variables: {
              dateRange,
              instances: instances.has(ALL_INSTANCES) || instanceNames.length === 0 ? null : instanceNames,
              metric,
              moRef: moRefVar,
            },
          });
          if (results.data.target?.metric) {
            return {
              ...results.data.target.metric,
              // If alias was matched, key could be upgraded
              // To continue matching to metric config when building series we need to revert the key
              key: metric,
            };
          } else {
            return {
              data: [],
              defaultColor: "#000",
              key: metric,
              unitOfMeasure: MetricUnitOfMeasure.Unspecified,
            };
          }
        },
      );
      const metricResults = await Promise.all(metricPromises);
      const map: ReadonlyMap<string, IMetricDataResult> = new Map(
        metricResults.map((x) => [x.key, { ...x, uuid: uuid() }]),
      );
      return map;
    },
    [client, dateRange, normalizedMetrics, target],
  );

  const promiseFnForMultiTarget = React.useCallback(
    async (_: unknown, { signal }: AbortController) => {
      const metricPromises = Array.from(
        normalizedMetricsForMultiTarget.entries(),
        async ([metric, value]): Promise<IMetricDataQueryResultMetric> => {
          const instanceNames = Array.from(value.instances).filter((x): x is string => typeof x === "string");
          const [metricValue, instance, target] = parseMetricMapKey(metric);

          const results = await client.query<IMetricDataQueryResult, IMetricDataQueryVariables>({
            context: {
              fetchOptions: { signal },
            },
            query: METRIC_QUERY,
            variables: {
              dateRange,
              instances: value.instances.has(ALL_INSTANCES) || instanceNames.length === 0 ? null : instanceNames,
              metric: metricValue,
              moRef: value.target.toString(),
            },
          });
          if (results.data.target?.metric) {
            return {
              ...results.data.target.metric,
              // If alias was matched, key could be upgraded
              // To continue matching to metric config when building series we need to revert the key
              key: formatMetricMapKey(metricValue, instance, target),
            };
          }
          return {
            data: [],
            defaultColor: "#000",
            key: formatMetricMapKey(metricValue, instance, target),
            unitOfMeasure: MetricUnitOfMeasure.Unspecified,
          };
        },
      );
      const metricResults = await Promise.all(metricPromises);
      const map: ReadonlyMap<string, IMetricDataResult> = new Map(
        metricResults.map((x) => [x.key, { ...x, uuid: uuid() }]),
      );
      return map;
    },
    [client, dateRange, normalizedMetricsForMultiTarget],
  );

  const { data = new Map<string, IMetricDataResult>(), error = null, isLoading } = useAsync(
    isCustomizeChart ? promiseFnForMultiTarget : promiseFn,
  );

  const series = metrics.flatMap<IMetricChartSeries>((config, i) => {
    let metricKey: string = "";

    const metricData = isCustomizeChart
      ? data.get(
          formatMetricMapKey(
            config.metric,
            config.instance && typeof config.instance === "string" ? config.instance : "NA",
            config.moRef ? config.moRef.toString() : "NA",
          ),
        )
      : data.get(config.metric);

    if (isCustomizeChart && metricData) {
      const [metric] = parseMetricMapKey(metricData.key);
      metricKey = metric;
    }

    if (!metricData) {
      return [];
    } else if (config.instance === ALL_INSTANCES) {
      return metricData.data.map<IMetricChartSeries>((instanceData) => {
        return {
          axisLabel: flattenThunk(config.axisLabel, instanceData),
          chartType: flattenThunk(config.chartType, instanceData),
          color: flattenThunk(config.color, instanceData),
          enableDefaultScale: flattenThunk(config.enableDefaultScale ?? true, instanceData),
          instance: instanceData.instance,
          interval: instanceData.interval,
          // Key uses `uuid` to change on data reload and `i` to be distinct between duplicate metric configs
          key: `${isCustomizeChart ? metricKey : metricData.key}-${instanceData.instance}-${metricData.uuid}-${i}`,
          label: flattenThunk(config.label, instanceData),
          metric: isCustomizeChart ? metricKey : metricData.key,
          points: mapQueryResultPoints(instanceData),
          strokeColor: flattenThunk(config.strokeColor, instanceData),
          targetName: config.moRef ? findByMoRef(config.moRef)?.name : "",
          unitOfMeasure: metricData.unitOfMeasure,
        };
      });
    } else {
      const instanceData = metricData.data.find((x) => x.instance === config.instance);
      if (!instanceData) {
        return {
          axisLabel: config.axisLabel,
          chartType: config.chartType,
          color: config.color,
          enableDefaultScale: config.enableDefaultScale ?? true,
          instance: config.instance,
          interval: "PT1M",
          // Key uses `uuid` to change on data reload and `i` to be distinct between duplicate metric configs
          key: `${isCustomizeChart ? metricKey : metricData.key}-${config.instance}-${metricData.uuid}-${i}`,
          label: config.label,
          metric: isCustomizeChart ? metricKey : metricData.key,
          points: [],
          strokeColor: config.strokeColor,
          targetName: config.moRef ? findByMoRef(config.moRef)?.name : "",
          unitOfMeasure: metricData.unitOfMeasure,
        };
      } else {
        return {
          axisLabel: config.axisLabel,
          chartType: config.chartType,
          color: config.color,
          enableDefaultScale: config.enableDefaultScale ?? true,
          instance: instanceData.instance,
          interval: instanceData.interval,
          // Key uses `uuid` to change on data reload and `i` to be distinct between duplicate metric configs
          key: `${isCustomizeChart ? metricKey : metricData.key}-${instanceData.instance}-${metricData.uuid}-${i}`,
          label: config.label,
          metric: isCustomizeChart ? metricKey : metricData.key,
          points: mapQueryResultPoints(instanceData),
          strokeColor: config.strokeColor,
          targetName: config.moRef ? findByMoRef(config.moRef)?.name : "",
          unitOfMeasure: metricData.unitOfMeasure,
        };
      }
    }
  });

  return {
    error,
    // useAsync returns does not update isLoading until the next rerender after dependencies changed
    isLoading: isLoading || hasNewMetrics || hasNewMetricsForMultiTarget,
    series,
  };
}
