import * as React from "react";
import AppBar, { AppBarProps } from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  header: {
    backgroundColor: "#444",
    color: "rgba(255, 255, 255, 0.9)", // #ffffffe6
  },
});

const FocusedDialogHeader: React.FC<AppBarProps> = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <AppBar className={classes.header} elevation={2} position="relative" {...props}>
      {children}
    </AppBar>
  );
};

export default FocusedDialogHeader;
