import * as React from "react";
import { IGridPlaceHolderProps } from "./types";

const GridPlaceHolder: React.FunctionComponent<IGridPlaceHolderProps> = ({
  className,
  color,
}) => {
  return (
    <svg
      className={className}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 340 200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill={color} height="23" rx="4" ry="4" width="275" x="23" y="23" />
      <rect fill={color} height="23" rx="3" ry="3" width="295" x="23" y="56" />
      <rect fill={color} height="23" rx="3" ry="3" width="230" x="23" y="89" />
      <rect fill={color} height="23" rx="3" ry="3" width="280" x="23" y="122" />
      <rect fill={color} height="23" rx="3" ry="3" width="215" x="23" y="155" />
    </svg>
  );
};

export default GridPlaceHolder;
