import { grey } from "@material-ui/core/colors";
import { ThemeOptions } from "@material-ui/core/styles";
import { paletteOptions } from "./palette";
import { spacing } from "./spacing";
import { typographyOptions } from "./typography";

const theme: ThemeOptions = {
  overrides: {
    MuiBadge: {
      anchorOriginBottomRightRectangle: {
        transform: "scale(1) translate(15%, 15%)",
      },
      dot: {
        // border-radius 50% only works for circles, but dot can contain content
        borderRadius: spacing / 2,
        height: spacing,
        minWidth: spacing,
      },
    },
    MuiDialogContent: {
      root: {
        paddingBottom: spacing * 2,
        paddingLeft: spacing * 4,
        paddingRight: spacing * 4,
        paddingTop: spacing * 2,
      },
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: grey[100],
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        // Try to target the indicator color. @material-ui/pickers internally sets this and we can't override it
        "&>div>span": {
          backgroundColor: "#2196F3",
        },
        backgroundColor: "#f5f5f5",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "transparent",
        color: "#2196F3",
      },
      switchHeader: {
        backgroundColor: "transparent",
        color: "#757575",
      },
    },
    MuiPickersDay: {
      daySelected: {
        "&:hover": {
          color: "#fff",
        },
        backgroundColor: "#BBDEFB",
        color: "#333333",
      },
    },
    MuiPickersMonth: {
      monthDisabled: {
        color: "rgba(51, 51, 51, 0.38)",
      },
      monthSelected: {
        "&:hover,&:focus": {
          backgroundColor: "#2196F3",
          color: "#fff",
        },
        backgroundColor: "#BBDEFB",
        color: "#333333",
        fontWeight: "bold",
      },
      root: {
        "&:hover,&:focus": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
          color: "#333333",
          fontWeight: "bold",
        },
        borderRadius: "4px",
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#f5f5f5",
        color: "#757575",
      },
    },
    MuiPickersToolbarText: {
      toolbarBtnSelected: {
        color: "#757575",
      },
      toolbarTxt: {
        color: "rgba(117, 117, 117, 0.54)",
      },
    },
    MuiTab: {
      root: {
        "&$selected": {
          "&:hover": {
            backgroundColor: "inherit",
          },
        },
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      },
    },
    MuiTable: {
      root: {
        "& caption": {
          captionSide: "unset",
          color: "rgba(0, 0, 0, 0.54)", // textSecondary
          fontSize: "1rem",
          fontWeight: 400,
          lineHeight: 1.5,
        },
      },
    },
    MuiTableCell: {
      body: {
        paddingLeft: 6,
        paddingRight: 6,
      },
      head: {
        paddingLeft: 6,
        paddingRight: 6,
      },
    },
    MuiTooltip: {
      arrow: {
        color: "#666",
      },
      tooltip: {
        backgroundColor: "#666",
        fontSize: "0.9rem",
        letterSpacing: "1px",
      },
    },
  },
  palette: paletteOptions,
  props: {
    MuiLink: {
      underline: "none",
    },
  },
  spacing,
  typography: typographyOptions,
};
export default theme;
