import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import * as React from "react";
import { useIntl } from "react-intl";
import { IParsedBlockChainVersion } from "../../types";

interface IVersionSelectorProps {
  onChange: (version: IParsedBlockChainVersion) => void;
  selectedVersionNumber: number;
  versions: readonly IParsedBlockChainVersion[];
}

const VersionSelector: React.FunctionComponent<IVersionSelectorProps> = ({
  onChange,
  selectedVersionNumber,
  versions,
}) => {
  const intl = useIntl();
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Button
        color={versions.length > 1 ? "primary" : "default"}
        disabled={versions.length <= 1}
        onClick={() => setOpen(true)}
        ref={buttonRef}
        size="small"
        style={{ lineHeight: 1, padding: 0 }}
        title={intl.formatMessage({ id: "changeVersion" })}
        variant="text"
      >
        {selectedVersionNumber}
      </Button>
      <Menu anchorEl={buttonRef.current} onClose={() => setOpen(false)} open={open}>
        {versions.map((v) => (
          <MenuItem
            key={v.id}
            onClick={() => {
              setOpen(false);
              onChange(v);
            }}
            selected={v.versionNumber === selectedVersionNumber}
          >
            {v.versionNumber}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default VersionSelector;
