import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useIntl } from "react-intl";
import { Prompt, useHistory } from "react-router";
import {
  ITopologyItemDevice,
  ITopologyItemEventSourceConnection,
  TopologyObjectType,
  useTopology,
} from "../../components/TopologyContext";
import { SelectionProvider } from "../../contexts/selectionContext";
import CustomChartModal, { ICustomChartConfigComplete } from "./components/CustomChartModal";
import CustomDashboardsHeader from "./components/CustomDashboardHeader";
import DashboardConfirmationDialog, { DashboardConfirmationType } from "./components/DashboardConfirmationDialog";
import DashboardGrid from "./components/DashboardGrid";
import WidgetTemplateSidebar from "./components/WidgetTemplateSidebar";
import {
  getHighestRowValue,
  positionAndExpandWidgets,
  shiftRowsBelowWidget,
  updateWidgetInformation,
  validateDashboardFormat,
} from "./CustomDashboardUtilities";
import { ICustomChartConfig, ICustomDashboardWidgetPosition } from "./types";
import { DashboardWidgetType, ICustomDashboard, ICustomDashboardWidget, useDashboardActions } from "./useDashboards";

const useStyles = makeStyles((theme) => ({
  editing: {
    "& $widgetsArea": {
      overflowX: "hidden",
      overflowY: "auto",
    },
    "&$root": {
      marginRight: theme.spacing(40),
      maxHeight: "100%",
    },
  },
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2, 4),
    transition: theme.transitions.create("all", { duration: 225, easing: theme.transitions.easing.easeOut }),
  },
  sidebar: {
    width: theme.spacing(44),
  },
  widgetsArea: {
    flex: 1,
    paddingBottom: "5rem",
  },
}));

export interface ICustomDashboardContentProps {
  customDashboard: ICustomDashboard;
  editMode: boolean;
}

const CustomDashboardContent: React.FC<ICustomDashboardContentProps> = ({ customDashboard, editMode }) => {
  const intl = useIntl();
  const history = useHistory();
  const classes = useStyles();
  const { devices, eventSourceConnections, findByMoRef } = useTopology();
  const containerRef = React.createRef<HTMLDivElement>();
  const [dirtyState, setDirtyState] = React.useState<boolean>(false);
  const [dashboard, setDashboard] = React.useState<ICustomDashboard>({ ...customDashboard });
  const [editing, setEditing] = React.useState<boolean>(editMode);
  const [showDashboardConfirmation, setShowDashboardConfirmation] = React.useState<DashboardConfirmationType>();
  const [showCustomChartModal, setShowCustomChartModal] = React.useState<boolean>(false);
  const [customChartModalInitialConfig, setCustomChartModalInitialConfig] = React.useState<ICustomChartConfig>({
    chartName: "customChart",
    customTimeRange: null,
    performanceCounters: [],
  });
  const [newCustomChartPosition, setNewCustomChartPosition] = React.useState<ICustomDashboardWidgetPosition>();
  const [editedWidgetId, setEditedWidgetId] = React.useState<number>(-1);
  const { deleteDashboard, updateDashboard } = useDashboardActions();

  async function handleDeleteConfirm(): Promise<void> {
    await deleteDashboard(dashboard.id);
    history.replace(history.location.pathname.replace(`/dashboards/${dashboard.id}`, "/dashboards"));
  }

  function handleDiscardChanges(): void {
    setDashboard(customDashboard);
    setDirtyState(false);
    setEditing(false);
  }

  function handleEditWidget(widget: ICustomDashboardWidget): void {
    setCustomChartModalInitialConfig({
      chartName: widget.title,
      customTimeRange: widget.options.customTimeRange,
      performanceCounters: widget.options.series.slice(),
    });
    setEditedWidgetId(widget.id);
    setShowCustomChartModal(true);
  }

  function handleCopyWidget(widget: ICustomDashboardWidget): void {
    const newCustomChartInSameRow: ICustomDashboardWidgetPosition = {
      column: widget.position.column + 1,
      height: 1,
      row: widget.position.row,
      width: 1,
    };
    const newCustomChartInNewRow: ICustomDashboardWidgetPosition = {
      column: 1,
      height: 1,
      row: widget.position.row + 1,
      width: 12,
    };
    const relevantWidgets = dashboard.widgets.filter((w) => w.position.row === widget.position.row); //get the list of widgets on the row being added to
    const customChartModalInitialConfigData: ICustomChartConfig = {
      chartName: widget.title,
      customTimeRange: widget.options.customTimeRange,
      performanceCounters: widget.options.series.slice(),
    };
    const newCustomChartPosition = relevantWidgets.length < 4 ? newCustomChartInSameRow : newCustomChartInNewRow;
    if (validateDashboardFormat(dashboard, newCustomChartPosition)) {
      setEditedWidgetId(-1);
      setNewCustomChartPosition(newCustomChartPosition);
      setCustomChartModalInitialConfig(customChartModalInitialConfigData);
      setShowCustomChartModal(true);
    }
  }

  async function handleSaveChanges(): Promise<void> {
    await updateDashboard(customDashboard.id, dashboard);
    setDirtyState(false);
    setEditing(false);
  }

  // available targets for the target selector
  const availableTargets: Array<ITopologyItemDevice | ITopologyItemEventSourceConnection> = [
    // filter out 'fake' devices and devices that have children connections
    ...devices.filter(
      (d) => !!d.description && !eventSourceConnections.some((conn) => conn.parentObjectId === d.objectId),
    ),
    ...eventSourceConnections,
  ]
    .filter((d) => d.isWatched && d.features.performance)
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <>
      <div className={classNames(classes.root, { [classes.editing]: editing })}>
        <CustomDashboardsHeader
          dashboard={dashboard}
          editing={editing}
          handleDeleteDashboard={() => setShowDashboardConfirmation("delete")}
          handleDiscard={dirtyState ? () => setShowDashboardConfirmation("discard") : handleDiscardChanges}
          handleSave={handleSaveChanges}
          handleStartEditing={() => setEditing(true)}
        />
        <DndProvider backend={HTML5Backend}>
          <div className={classes.widgetsArea} ref={containerRef}>
            <SelectionProvider>
              <DashboardGrid
                dashboard={dashboard}
                editing={editing}
                handleEditing={(value) => setEditing(value)}
                onAddWidget={addWidget}
                onChangeLayout={updateDashboardWidgets}
                onCopyWidget={handleCopyWidget}
                onEditWidget={handleEditWidget}
              />
            </SelectionProvider>
          </div>
          <WidgetTemplateSidebar
            addWidget={addWidget}
            dashboardName={dashboard.name}
            onDashboardNameChange={(name) => onChangeName(name)}
            open={editing}
            targets={availableTargets}
          />
          <Prompt message={intl.formatMessage({ id: "confirmNavigation" })} when={dirtyState} />
        </DndProvider>
      </div>
      <DashboardConfirmationDialog
        handleClose={(confirmed) => {
          if (confirmed) {
            if (showDashboardConfirmation === "delete") handleDeleteConfirm();
            else if (showDashboardConfirmation === "discard") handleDiscardChanges();
          }
          setShowDashboardConfirmation(undefined);
        }}
        open={showDashboardConfirmation}
      />
      <CustomChartModal
        handleClose={handleCustomChartModalClose}
        initialChartConfig={customChartModalInitialConfig}
        key={showCustomChartModal.toString()} //forces full rerender when modal is opened/closed
        open={showCustomChartModal}
        targets={availableTargets}
      />
    </>
  );

  function handleCustomChartModalClose(newChartConfig: ICustomChartConfigComplete | null): void {
    if (newChartConfig) {
      //indicates the modal was saved
      if (editedWidgetId !== -1) {
        //updated existing widget
        const adjustedDashboard = updateWidgetInformation(dashboard, editedWidgetId, newChartConfig);
        updateDashboardWidgets(adjustedDashboard);
      } else {
        //add new widget
        const highestRow = getHighestRowValue(dashboard);
        const defaultWidgetPosition: ICustomDashboardWidgetPosition = {
          column: 1,
          height: 1,
          row: highestRow + 1,
          width: 12,
        };
        const widgetToAdd: ICustomDashboardWidget = {
          id: Math.random(),
          options: {
            customTimeRange: newChartConfig.customTimeRange,
            series: newChartConfig.performanceCounters,
          },
          position: newCustomChartPosition ?? defaultWidgetPosition,
          title: newChartConfig.chartName,
          type: DashboardWidgetType.Chart,
        };
        let newDashboard: ICustomDashboard = {
          ...dashboard,
          widgets: [...dashboard.widgets, widgetToAdd],
        };
        let newHighestRow = getHighestRowValue(newDashboard);
        if (widgetToAdd.position.width === 12) {
          //indicates a new row
          newDashboard = shiftRowsBelowWidget(newDashboard, widgetToAdd);
          newHighestRow++;
        }
        const adjustedDashboard = positionAndExpandWidgets(newDashboard, newHighestRow);
        if (adjustedDashboard) {
          updateDashboardWidgets(adjustedDashboard);
        }
      }
    }
    setShowCustomChartModal(false);
  }

  function onChangeName(newName: string): void {
    setDashboard((prevDashboard) => ({ ...prevDashboard, name: newName }));
    setDirtyState(true);
  }

  function updateDashboardWidgets(newLayout: ICustomDashboard): void {
    setDashboard(newLayout);
    setDirtyState(true);
  }

  function addWidget(newWidget: ICustomChartConfig, newWidgetPosition?: ICustomDashboardWidgetPosition): void {
    if (validateDashboardFormat(dashboard, newWidgetPosition)) {
      setEditedWidgetId(-1);
      setNewCustomChartPosition(newWidgetPosition);
      setCustomChartModalInitialConfig(newWidget);
      setShowCustomChartModal(true);
    }
    //TODO: indicate to the user they are trying to add a widget to an invalid location
  }
};

export default CustomDashboardContent;
