import { CustomFormats } from "react-intl";

const formats: CustomFormats = {
  number: {
    detailedPercent: {
      // Increases maximum fraction digits from 0 to 2
      // Formats 0.9999 to 99.99%, 0.999 to 99.9%, and 0.99 to 99%
      maximumFractionDigits: 2,
      style: "percent",
    },
  },
};

export default formats;
