import * as React from "react";
import { IMonitorFeatures } from "../../api/FeatureFlags";
import featureContext from "./featureContext";
import { IFeatureContext } from "./types";
const { useContext } = React;

/**
 * Hook for accessing current feature context in a React function component.
 * Relies on feature flag context provider to exist in the component hierarchy.
 * @returns Current feature context value from nearest provider.
 */
export function useFeatureContext(): IFeatureContext {
  return useContext(featureContext);
}

/**
 * Hook for retrieving the value of a specified feature flag in a React function component.
 * Retrieves the feature flag value frmo the current feature flag context value.
 * Relies on feature flag context provider to exist in the component hierarchy.
 * @param feature The feature flag to retrieve the value of.
 * @returns True if the feature flag is enabled, false otherwise.
 */
export function useFeatureFlag(feature: keyof IMonitorFeatures): boolean {
  const { hasFeature } = useFeatureContext();
  return hasFeature(feature);
}
