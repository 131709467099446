import * as React from "react";
import ChartPlaceHolder from "./ChartPlaceHolder";
import GridPlaceHolder from "./GridPlaceHolder";
import { IPlaceHolderBackgroundProps } from "./types";

const PlaceHolderBackground: React.FunctionComponent<IPlaceHolderBackgroundProps> = ({
  variant,
  ...props }) => {
  const defaultColor = "#f2f2f2";
  switch (variant) {
    case "chart":
      return <ChartPlaceHolder color={defaultColor} {...props} variant="chart" />;
    case "grid":
      return <GridPlaceHolder color={defaultColor} {...props} variant="grid" />;
    default:
      // Fallback to GridLoading for now
      return <GridPlaceHolder color={defaultColor} {...props} variant="grid" />;
  }
};

export default PlaceHolderBackground;
