import Chip from "@material-ui/core/Chip";
import * as React from "react";
import { FormattedDate } from "react-intl";

interface ICardFilterChipProps {
  onRemove: () => void;
  title?: string;
  value: {
    from: Date;
    to: Date;
  };
}

const CardFilterChip: React.FunctionComponent<ICardFilterChipProps> = ({
  onRemove,
  title,
  value
}) => {
  return (
    <Chip
      color="primary"
      label={<>
        <FormattedDate
          day="numeric"
          hour="2-digit"
          minute="numeric"
          month="numeric"
          second="numeric"
          value={value.from}
          year="numeric" />
        {" - "}
        <FormattedDate
          day="numeric"
          hour="2-digit"
          minute="numeric"
          month="numeric"
          second="numeric"
          value={value.to}
          year="numeric" />
      </>}
      onDelete={onRemove}
      title={title}
    />
  );
};

export default CardFilterChip;
