import { useTheme } from "@material-ui/core/styles";
import * as React from "react";
import { getAngle, IDiagramEdge } from "../../../../components/Diagram";
import { IDeadlockProcess, IDeadlockResource } from "../../../../contexts/deadlocksContext";

interface IDeadlockDiagramEdgeProps extends React.SVGProps<SVGGElement> {
  allEdges: readonly IDiagramEdge<IDeadlockProcess | IDeadlockResource>[];
  edge: IDiagramEdge<IDeadlockProcess | IDeadlockResource>;
}

const DeadlockDiagramEdge: React.FC<IDeadlockDiagramEdgeProps> = ({ allEdges, edge, ...props }) => {
  const theme = useTheme();
  const { sequence, x1, x2, y1, y2 } = edge;

  // Determine edge midpoint and shift it if there are overlapping edges
  let cx = x1 + (x2 - x1) / 2;
  let cy = y1 + (y2 - y1) / 2;
  const similarEdges = allEdges.filter(
    e =>
      (e.x1 === x1 && e.x2 === x2 && e.y1 === y1 && e.y2 === y2) ||
      (e.x1 === x2 && e.x2 === x1 && e.y1 === y2 && e.y2 === y1),
  );
  if (similarEdges.indexOf(edge) > 0) {
    const direction = similarEdges.indexOf(edge) % 2 > 0 ? 1 : -1;
    const shift = Math.ceil(similarEdges.indexOf(edge) / 2);
    cx = cx + 6 * shift * direction;
    cy = cy + 6 * shift * direction;
  }

  const angle = getAngle([x1, y1], [x2, y2]);
  const ax1 = x1 - 1.15 * Math.cos(angle);
  const ay1 = y1 - 1.25 * Math.sin(angle);
  const ax2 = x2 - 1.15 * Math.cos(angle);
  const ay2 = y2 - 1.25 * Math.sin(angle);

  return (
    <g {...props}>
      <path
        d={`M ${ax1} ${ay1} L ${cx} ${cy} L ${ax2} ${ay2}`}
        fill="none"
        markerEnd="url(#arrow)"
        stroke={theme.palette.grey[800]}
        strokeWidth={0.8}
      />
      <circle cx={cx} cy={cy} fill={theme.palette.grey[800]} r={3} />
      <text fill={theme.palette.common.white} fontSize={3} textAnchor="middle" x={cx} y={cy + 1}>
        {sequence}
      </text>
    </g>
  );
};

export default DeadlockDiagramEdge;
