import Chip from "@material-ui/core/Chip";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useIntl } from "react-intl";
import * as React from "react";
import { Checkbox, Divider, Tooltip } from "@material-ui/core";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";

export interface ISeverityMultipleSelectProps {
  setSelectedSeverities: (entities: string[]) => void;
  handleCalculateDelta: (value: boolean) => void;
}

export enum Severitys {
  Healthy = "Healthy",
  AtRisk = "AtRisk",
  Unhealthy = "Unhealthy",
  Critical = "Critical",
}

export interface ISeverityList {
  AtRisk: boolean;
  Critical: boolean;
  Healthy: boolean;
  Unhealthy: boolean;
}

const defaultSeverities: ISeverityList = {
  AtRisk: false,
  Critical: true,
  Healthy: false,
  Unhealthy: true,
};

const useStyles = makeStyles(() => ({
  avatar: {
    color: "#2196F3 !important",
  },
  chip: {
    color: "#6c757d",
    margin: 5,
    padding: 15,
  },
  deltaFilterTooltip: {
    maxWidth: 500,
  },
}));

const SeverityMultipleSelect: React.FC<ISeverityMultipleSelectProps> = ({
  handleCalculateDelta,
  setSelectedSeverities,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const [filterSeverity, setFilterSeverity] = React.useState<ISeverityList>(defaultSeverities);
  const [deltaFilter, setDeltaFilter] = React.useState(false);

  const onChangeFilterList = (name: string): void => {
    const newSeveritys = Object.keys(filterSeverity).filter((value) => filterSeverity[value as keyof ISeverityList]);
    if (newSeveritys.includes(name)) {
      const index = newSeveritys.indexOf(name);
      index > -1 && newSeveritys.splice(index, 1);
    } else newSeveritys.push(name);
    if (newSeveritys.length > 0) {
      setDeltaFilter(false);
      handleCalculateDelta(false);
    }
    setSelectedSeverities(newSeveritys);
  };

  const onFilterClick = (name: Severitys): void => {
    switch (name) {
      case Severitys.Critical:
        setFilterSeverity({
          ...filterSeverity,
          Critical: !filterSeverity.Critical,
        });
        onChangeFilterList(Severitys.Critical);
        break;
      case Severitys.Unhealthy:
        setFilterSeverity({
          ...filterSeverity,
          Unhealthy: !filterSeverity.Unhealthy,
        });
        onChangeFilterList(Severitys.Unhealthy);
        break;
      case Severitys.AtRisk:
        setFilterSeverity({
          ...filterSeverity,
          AtRisk: !filterSeverity.AtRisk,
        });
        onChangeFilterList(Severitys.AtRisk);
        break;
      case Severitys.Healthy:
        setFilterSeverity({
          ...filterSeverity,
          Healthy: !filterSeverity.Healthy,
        });
        onChangeFilterList(Severitys.Healthy);
        break;
    }
  };

  const onChangeDeltaFiler = (): void => {
    handleCalculateDelta(!deltaFilter);
    setDeltaFilter(!deltaFilter);
    setSelectedSeverities([]);
    setFilterSeverity({
      AtRisk: false,
      Critical: false,
      Healthy: false,
      Unhealthy: false,
    });
  };

  return (
    <>
      <Chip
        avatar={
          <Checkbox
            checked={filterSeverity.Critical}
            checkedIcon={<CircleCheckedFilled />}
            className={classes.avatar}
            icon={<CircleUnchecked />}
            size="small"
          />
        }
        className={classes.chip}
        label={intl.formatMessage({ id: "critical" })}
        onClick={(e) => onFilterClick(Severitys.Critical)}
        size="small"
        variant="outlined"
      />
      <Chip
        avatar={
          <Checkbox
            checked={filterSeverity.Unhealthy}
            checkedIcon={<CircleCheckedFilled />}
            className={classes.avatar}
            color="primary"
            icon={<CircleUnchecked />}
            size="small"
          />
        }
        className={classes.chip}
        label={intl.formatMessage({ id: "unhealthy" })}
        onClick={(e) => onFilterClick(Severitys.Unhealthy)}
        size="small"
        variant="outlined"
      />
      <Chip
        avatar={
          <Checkbox
            checked={filterSeverity.AtRisk}
            checkedIcon={<CircleCheckedFilled />}
            className={classes.avatar}
            color="primary"
            icon={<CircleUnchecked />}
            size="small"
          />
        }
        className={classes.chip}
        label={intl.formatMessage({ id: "atRisk" })}
        onClick={(e) => onFilterClick(Severitys.AtRisk)}
        size="small"
        variant="outlined"
      />
      <Chip
        avatar={
          <Checkbox
            checked={filterSeverity.Healthy}
            checkedIcon={<CircleCheckedFilled />}
            className={classes.avatar}
            color="primary"
            icon={<CircleUnchecked />}
            size="small"
          />
        }
        className={classes.chip}
        label={intl.formatMessage({ id: "healthy" })}
        onClick={(e) => onFilterClick(Severitys.Healthy)}
        size="small"
        variant="outlined"
      />
      <Divider flexItem orientation="vertical" />
      <Tooltip
        arrow
        classes={{
          tooltip: classes.deltaFilterTooltip,
        }}
        placement="right"
        title={intl.formatMessage({ id: "deltaFilter" })}
      >
        <Chip
          avatar={
            <Checkbox
              checked={deltaFilter}
              checkedIcon={<CircleCheckedFilled />}
              className={classes.avatar}
              color="primary"
              icon={<CircleUnchecked />}
              size="small"
            />
          }
          className={classes.chip}
          label={intl.formatMessage({ id: "delta" })}
          onClick={onChangeDeltaFiler}
          size="small"
          variant="outlined"
        />
      </Tooltip>
    </>
  );
};
export default SeverityMultipleSelect;
