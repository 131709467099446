import * as React from "react";
import { IMonitorFeatures } from "../../api/FeatureFlags";
import { useFeatureFlag } from "./hooks";

interface IFlagRenderProps {
  children: (value: boolean) => React.ReactElement;
  flag: keyof IMonitorFeatures;
}

interface IFlagMultiRenderProps {
  children: (value: (key: keyof IMonitorFeatures) => boolean) => React.ReactElement;
}

interface IFlagProps {
  children: React.ReactElement;
  fallback?: React.ReactElement;
  flag: keyof IMonitorFeatures;
}

const isMultiFlagConsumer = (
  props: IFlagProps | IFlagRenderProps | IFlagMultiRenderProps
): props is IFlagMultiRenderProps => {
  return !Object.prototype.hasOwnProperty.call(props, "flag");
};

/**
 * Utility component to wrap any section of JSX in a feature flag condition.
 * Only renders `children` if the feature specified by `flag` is true.
 * Optionally accepts a `fallback` component to render if `flag` is false.
 * Relies on feature flag context provider to exist in the component hierarchy.
 * @param props
 */
const Flag: React.FunctionComponent<IFlagProps | IFlagRenderProps | IFlagMultiRenderProps> = props => {
  if (isMultiFlagConsumer(props)) {
    return props.children(useFeatureFlag);
  } else if (typeof props.children === "function") {
    return props.children(useFeatureFlag(props.flag)); //eslint-disable-line react-hooks/rules-of-hooks
  } else {
    const flagProps = props as IFlagProps;
    if (useFeatureFlag(props.flag)) { //eslint-disable-line react-hooks/rules-of-hooks
      return flagProps.children;
    } else {
      return flagProps.fallback ?? null;
    }
  }
};

export default Flag;
