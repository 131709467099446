import { faFilter } from "@fortawesome/free-solid-svg-icons/faFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useBlockingContext } from "../../../../contexts/blockingContext";
import FilterForm from "../FilterForm";

interface IControlOptionsProps {
  className?: string;
}

function getFilterIconColor(filter: any): "primary" | "default" {
  if (filter) {
    return "primary";
  } else {
    return "default";
  }
}

const ControlOptions: React.FunctionComponent<IControlOptionsProps> = ({ className }) => {
  const { filterApplied } = useBlockingContext();
  const [showFilter, setShowFilter] = React.useState(false);
  const filterButtonRef = React.useRef<HTMLButtonElement>(null);

  return (
    <div className={className}>
      <FormattedMessage id="filter">
        {(message: string) => (
          <Tooltip title={message}>
            <IconButton
              aria-label={message}
              color={getFilterIconColor(filterApplied)}
              data-testid="blockingFilter"
              onClick={() => setShowFilter(true)}
              ref={filterButtonRef}
            >
              <FontAwesomeIcon icon={faFilter} size="xs" />
            </IconButton>
          </Tooltip>
        )}
      </FormattedMessage>
      <Drawer anchor="right" onClose={() => setShowFilter(false)} open={showFilter}>
        <FilterForm closeDrawer={() => setShowFilter(false)} />
      </Drawer>
    </div>
  );
};

export default ControlOptions;
