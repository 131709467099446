import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiTableSortLabel-root": {
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: "0.900rem",
      fontWeight: "bold",
    },
    ".gsfFeedback": {
      borderColor: "#7786a8",
      fontSize: 14,
      marginBottom: theme.spacing(),
    },
    ".p-button": {
      padding: "0.5rem 0.75rem",
    },
    ".p-button, .p-component, .p-datatable, .p-inputtext, .p-link": {
      fontFamily: `'Roboto', sans-serif`,
    },
    ".p-button, .p-component, .p-datatable, .p-inputtext, .p-link, .pi": {
      fontSize: "0.857rem",
    },
    ".p-datatable .p-datatable-thead > tr > th": {
      background: "#f5f5f5",
      color: "rgba(0, 0, 0, 0.87)",
      fontFamily: `'Roboto', sans-serif`,
      fontSize: "0.900rem",
      fontWeight: "bold",
      whiteSpace: "normal",
    },
    ".p-datatable .p-sortable-column .p-sortable-column-icon, .pi.pi-filter-icon.pi-filter": {
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: "0.900rem",
    },
    ".p-datatable-tbody": {
      color: "#333333",
      fontFamily: `'Roboto', sans-serif`,
      lineHeight: 1.43,
    },
    ".p-dropdown-panel, .p-multiselect-panel, .p-column-filter-overlay": {
      zIndex: "1301 !important",
    },
    ".p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last, .p-paginator .p-paginator-current, .p-paginator .p-paginator-pages .p-paginator-page, .p-paginator .p-dropdown": {
      height: "2.8rem",
      minWidth: "2.8rem",
    },
    ".recharts-cartesian-axis-tick": {
      ...theme.typography.caption,
    },
    ".recharts-default-legend": {
      maxHeight: "1.5em",
      overflowX: "hidden",
      overflowY: "auto",
      scrollSnapType: "y mandatory",
    },
    ".recharts-legend-item": {
      scrollSnapAlign: "start",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    ".recharts-legend-item-text": {
      ...theme.typography.body2,
      cursor: "pointer",
    },
    ".recharts-surface": {
      userSelect: "none",
    },
    ".recharts-tooltip-wrapper": {
      backgroundColor: "#333333",
      borderColor: "#333333",
      borderRadius: theme.shape.borderRadius,
      color: "#f2f2f2",
      zIndex: theme.zIndex.tooltip,
    },
    ".recharts-yAxis .recharts-label": {
      ...theme.typography.body2,
    },
    ":root": {
      // css color variables for the dark sidebar
      "--sidebar-dark-background": "#444",
      "--sidebar-dark-divider": "#555",
      "--sidebar-dark-item-active-background": "#333",
      "--sidebar-dark-item-active-border": "#f99d1c",
      "--sidebar-dark-item-active-color": "#f99d1c",
      "--sidebar-dark-item-hover-background": "rgba(33, 150, 243, 0.4)",
      "--sidebar-dark-item-hover-soft-background": "rgba(33, 149, 243, 0.25)",
      "--sidebar-dark-light-blue": "#60b8ff",
      "--sidebar-dark-switch-color": "#f99d1c",
      "--sidebar-dark-text-grey": "rgba(255, 255, 255, 0.59)",
      "--sidebar-dark-text-white": "rgba(255, 255, 255, 0.95)",
      "--sidebar-dark-treeItem-active-background": "#f99d1c",
    },
    a: {
      textDecoration: "none",
    },
    "body ::selection": {
      backgroundColor: "#83bff7",
    },
    html: {
      fontSize: 14,
    },
    "html,body,#root": {
      height: "100%",
      margin: 0,
      padding: 0,
    },
  },
}));

const GlobalCss: React.FC = () => {
  useStyles();
  return null;
};

export default GlobalCss;
