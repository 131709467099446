import * as React from "react";
import Toolbar, { ToolbarProps } from "@material-ui/core/Toolbar";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useFocusedDialog } from "./utils/FocusedDialogContext";
import { useIntl } from "react-intl";

export interface IFocusedDialogToolbarProps extends ToolbarProps {
  CloseIconProps?: Partial<IconButtonProps>;
}

const FocusedDialogToolbar: React.FC<IFocusedDialogToolbarProps> = ({ CloseIconProps, children, ...props }) => {
  const { onClose } = useFocusedDialog();
  const intl = useIntl();

  return (
    <Toolbar variant="dense" {...props}>
      <IconButton
        color="inherit"
        edge="start"
        onClick={onClose}
        title={intl.formatMessage({ id: "close" })}
        {...CloseIconProps}
      >
        <ArrowBackIcon />
      </IconButton>
      {children}
    </Toolbar>
  );
};

export default FocusedDialogToolbar;
