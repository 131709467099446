import { useQuery } from "@apollo/react-hooks";
import * as GET_ALL_STORAGE_ENTITIES from "./getAllStorageEntities.graphql";
import * as GET_ALL_WARNING_ENTITIES from "./getAllWarningEntities.graphql";
import { MoRef } from "../../../utilities/TopologyUtility";
import StorageEntityMap, { IAllStorageEntitiesResponse } from "../StorageEntityMap";
import {
  IAsyncResult,
  IStorageDiskController,
  IStorageLogicalVolume,
  IStoragePhysicalDisk,
  IStorageSqlServerFile,
  StorageEntityType,
  IWarningResponse,
} from "../types";
import { useMemo } from "react";
import { IntlShape, useIntl } from "react-intl";

export type IStorageEntityResponse =
  | (IStorageDiskController & { __typename: "DiskController" })
  | (IStoragePhysicalDisk & { __typename: "PhysicalDisk" })
  | (IStorageLogicalVolume & { __typename: "LogicalVolume" })
  | (IStorageSqlServerFile & { __typename: "SqlServerFile"; fileType: "DATA" | "LOG" });

export const isSameWarningEntity = (oldWarningEntity: Array<IWarningResponse>, newWarningEntity: IWarningResponse): boolean => {
  let sameEntity = false;
  oldWarningEntity?.map(warning => {
    if (
      warning.warningType == newWarningEntity.warningType &&
      warning.warningValue === newWarningEntity.warningValue &&
      warning.warningEntity.name === newWarningEntity.warningEntity.name
    ) sameEntity = true;
  })
  return sameEntity;
}

const formatWarningMessages = (warningEntity: IWarningResponse, intl: IntlShape): IWarningResponse => {
  if (warningEntity.warningType) {
    const typeOfUnit = warningEntity.warningType.substr(warningEntity.warningType.lastIndexOf("_") + 1);
    warningEntity.warningMessage = intl.formatMessage({id: `storageWarningMessage_${warningEntity.warningType}` });

    if (warningEntity.warningValue) {
      switch (typeOfUnit)
      {
        case "PERCENTAGE":
          warningEntity.warningValueWithUnit = intl.formatNumber(parseFloat(warningEntity.warningValue) / 100, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: "percent",
          }); 
          break;
        case "MEGABYTES":
          warningEntity.warningValueWithUnit = intl.formatNumber(parseFloat(warningEntity.warningValue) / 1024 ** 2, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: "unit",
            unit: "megabyte",
          });
          break;
        case "GIGABYTES":
          warningEntity.warningValueWithUnit = intl.formatNumber(parseFloat(warningEntity.warningValue) / 1024 ** 3, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: "unit",
            unit: "gigabyte",
          });
          break;
        default:
          warningEntity.warningValueWithUnit = warningEntity.warningValue;
          break;
      }
    }
  }
  return warningEntity;
}

export const setWarningEntity = (warningEntity: IWarningResponse, storageEntity: IStorageEntityResponse): void => {
  if (Object.prototype.hasOwnProperty.call(storageEntity, "warnings") &&
    (storageEntity?.warnings)) {
    if (isSameWarningEntity(storageEntity?.warnings, warningEntity)) return;
    storageEntity["warnings"]?.push(warningEntity)
  }
  else {
    storageEntity["warnings"] = [];
    storageEntity["warnings"].push(warningEntity)
  }
}

export const EntityContainsWarning = (warning: IWarningResponse, storageEntity: IStorageEntityResponse): boolean => {
  if (warning.warningEntity.id === storageEntity.id) {
    return true;
  } else if (warning.warningEntity.__typename === "SqlServerFile") {
    return (
      Boolean(
        warning.warningEntity?.logicalVolume?.physicalDisks?.filter(
          (pdisk) => pdisk?.diskController?.id === storageEntity.id || pdisk?.id === storageEntity.id,
        ).length,
      ) || warning.warningEntity?.logicalVolume?.id === storageEntity.id
    );
  } else if (warning.warningEntity.__typename === "LogicalVolume") {
    return Boolean(
      warning.warningEntity?.physicalDisks?.filter(
        (pdisk) => pdisk?.diskController?.id === storageEntity.id || pdisk?.id === storageEntity.id,
      ).length,
    );
  } else if (warning.warningEntity.__typename === "PhysicalDisk") {
    return warning.warningEntity?.diskController?.id === storageEntity.id;
  } else {
    return false;
  }
};

export const updateStorageEntityWithWarnings = (warningData: IWarningResponse[], storageEntity: IStorageEntityResponse[], intl: IntlShape): void => {
  warningData?.map(warning => {
    formatWarningMessages(warning, intl)
    return storageEntity?.map((storageEntity => {
      if (EntityContainsWarning(warning, storageEntity)) {
        setWarningEntity(warning, storageEntity)
      }
    }))
  })
}

export function useAllStorageEntities(moRef: MoRef): IAsyncResult<StorageEntityMap> {
  const intl = useIntl();

  const { data: warningData = { target: { storage: { warnings: [] } } }, error: warningError, loading: warningLoading } = useQuery<{
    target: {
      storage: {
        warnings: Array<IWarningResponse>;
      };
    };
  }>(GET_ALL_WARNING_ENTITIES, { variables: { moRef: moRef.toString() } });

  const { data = { target: { storage: { entities: [] } } }, error, loading } = useQuery<{
    target: {
      storage: {
        entities: Array<IStorageEntityResponse>;
      };
    };
  }>(GET_ALL_STORAGE_ENTITIES, { variables: { moRef: moRef.toString() } });

  return useMemo(() => {
    if (error) {
      return {
        data: undefined,
        error: error,
        isLoading: false,
        state: "error",
      };
    } else if (warningError) {
      return {
        data: undefined,
        error: warningError,
        isLoading: false,
        state: "error",
      };
    }
    else if (loading || warningLoading) {
      return {
        data: undefined,
        error: null,
        isLoading: true,
        state: "loading",
      };
    } else {
      const allStorageEntitiesResponse: IAllStorageEntitiesResponse = {
        controllers: [],
        disks: [],
        files: [],
        volumes: [],
      };

      updateStorageEntityWithWarnings(warningData.target.storage.warnings, data.target.storage.entities, intl)

      data.target.storage.entities.forEach((entity) => {
        switch (entity.__typename) {
          case "DiskController":
            allStorageEntitiesResponse.controllers.push({
              id: entity.id,
              manufacturer: entity.manufacturer,
              maxInboundRate: entity.maxInboundRate,
              maxOutboundRate: entity.maxOutboundRate,
              name: entity.name,
              type: StorageEntityType.DiskController,
              warnings: entity?.warnings ? entity.warnings : null,
            });
            break;
          case "PhysicalDisk":
            allStorageEntitiesResponse.disks.push({
              diskController: entity.diskController,
              diskIndex: entity.diskIndex,
              id: entity.id,
              name: entity.name,
              sizeBytes: entity.sizeBytes,
              status: entity.status,
              type: StorageEntityType.PhysicalDisk,
              warnings: entity?.warnings ? entity.warnings : null,
            });
            break;
          case "LogicalVolume":
            allStorageEntitiesResponse.volumes.push({
              fileSystem: entity.fileSystem,
              forecastedExhaustionDate: entity.forecastedExhaustionDate,
              freeSpaceBytes: entity.freeSpaceBytes,
              friendlyName: entity.friendlyName,
              id: entity.id,
              name: entity.name,
              otherSpaceBytes: entity.otherSpaceBytes,
              physicalDisks: entity.physicalDisks,
              sizeBytes: entity.sizeBytes,
              type: StorageEntityType.LogicalVolume,
              warnings: entity?.warnings ? entity.warnings : null,
            });
            break;
          case "SqlServerFile": {
            allStorageEntitiesResponse.files.push({
              activeVLFs: entity.activeVLFs,
              autogrowth: entity.autogrowth,
              databaseName: entity.databaseName,
              fileName: entity.fileName,
              filegroupName: entity.filegroupName,
              id: entity.id,
              lastBackupTime: entity.lastBackupTime ? new Date(entity.lastBackupTime) : null,
              lastBackupType: entity.lastBackupType,
              logReuseWait: entity.logReuseWait,
              logicalVolume: entity.logicalVolume,
              maxFileSizeBytes: entity.maxFileSizeBytes,
              maxVLFSizeBytes: entity.maxVLFSizeBytes,
              minVLFSizeBytes: entity.minVLFSizeBytes,
              name: entity.name,
              sizeBytes: entity.sizeBytes,
              sqlServerName: entity.sqlServerName,
              totalVLFs: entity.totalVLFs,
              type:
                entity.fileType === "LOG" ? StorageEntityType.SqlServerLogFile : StorageEntityType.SqlServerDataFile,
              usedPercentage: entity.usedSizeBytes ? +(entity.usedSizeBytes / entity.sizeBytes).toFixed(5) : null,
              usedSizeBytes: entity.usedSizeBytes,
              warnings: entity?.warnings ? entity.warnings : null,
            });
            break;
          }
        }
      });

      return {
        data: new StorageEntityMap(allStorageEntitiesResponse),
        error: null,
        isLoading: false,
        state: "success",
      };
    }
  }, [data, intl, loading, error, warningData, warningLoading, warningError]);
}
