import { objectMap } from "../../utilities/ObjectUtility";
import BaseService from "../BaseService";
import {
  IPlanDataLink,
  IPlanDataLinkInfo,
  IPlanDataNode,
  IPlanDataNodeAdditionalIndexes,
  IPlanDataNodeTooltip,
  IPlanDataOpacityInfo,
  IPlanDataOperationCosts,
  IPlanDataOperationCostValue,
  IPlanDataRequest,
  IPlanDataResponse,
  IRawPlanXMLResponse,
} from "./models/PlanViewer";

function toArgbString(color: IArgbColor): string | null {
  if (!color) {
    return null;
  }

  const alpha = color.a / 255; // Alpha in the API response is 0-255 rather than 0-99
  return `rgba(${color.r}, ${color.g}, ${color.b}, ${alpha})`;
}

function parseNumericKeys<V>(values: Record<string, V>): Record<number, V> {
  const response = Object.keys(values).reduce<Record<number, V>>((p, k) => {
    p[Number(k)] = values[k];
    return p;
  }, {});

  return response;
}

function getPlanDataLinkInfoFromResult(result: any): IPlanDataLinkInfo {
  const response: IPlanDataLinkInfo = {
    costType: result.costType,
    links: parseNumericKeys(
      objectMap<any, IPlanDataLink>(result.links, (c) => ({
        text: c.text,
        textBackgroundColor: toArgbString(c.textBackgroundColor),
        textForegroundColor: toArgbString(c.textForegroundColor),
        toNodeId: c.toNodeId,
        widthPercent: Number(c.widthPercent),
      })),
    ),
    widthBy: result.widthBy,
  };

  return response;
}

function getPlanDataNodeAdditionalIndexesFromResult(result: any): IPlanDataNodeAdditionalIndexes | null {
  if (!result) {
    return null;
  }

  const response: IPlanDataNodeAdditionalIndexes = {
    backgroundColor: toArgbString(result.backgroundColor),
    foregroundColor: toArgbString(result.foregroundColor),
    text: result.text,
  };

  return response;
}

function getPlanDataNodeFromResult(result: any): IPlanDataNode {
  const response: IPlanDataNode = {
    additionalIndexes: getPlanDataNodeAdditionalIndexesFromResult(result.additionalIndexes),
    children: result.children.map(getPlanDataNodeFromResult),
    id: result.id,
    indexName: result.indexName,
    nodeId: result.nodeId,
    objectName: result.objectName,
    operationName: result.operationName,
    operationTextBackgroundColor: toArgbString(result.operationTextBackgroundColor),
    operationTextForegroundColor: toArgbString(result.operationTextForegroundColor),
    overlay: result.overlay,
    shortName: result.shortName,
    table: result.table,
    totalOperatorCost: Number(result.totalOperatorCost),
  };

  return response;
}

function getPlanDataNodeTooltipsFromResult(result: any): Record<number, IPlanDataNodeTooltip> | null {
  if (!result) {
    return null;
  }

  const response = parseNumericKeys(
    objectMap<any, IPlanDataNodeTooltip>(result, (c) => ({
      description: c.description,
      nodeId: c.nodeId,
      title: c.title,
      tooltipGroups: parseNumericKeys(c.tooltipGroups),
    })),
  );

  return response;
}

function getPlanDataOpacityInfoFromResult(result: any): IPlanDataOpacityInfo {
  const response: IPlanDataOpacityInfo = {
    costType: result.costType,
    isImmaterial: parseNumericKeys<boolean>(result.isImmaterial),
  };

  return response;
}

function getPlanDataOperationCostsFromResult(result: any): IPlanDataOperationCosts {
  const response: IPlanDataOperationCosts = {
    calculationStyle: result.calculationStyle,
    costType: result.costType,
    costs: parseNumericKeys(
      objectMap<any, IPlanDataOperationCostValue>(result.costs, (c) => ({
        backgroundColor: toArgbString(c.backgroundColor),
        costPercent: Number(c.costPercent),
        foregroundColor: toArgbString(c.foregroundColor),
        nodeId: c.nodeId,
      })),
    ),
    costsBy: result.costsBy,
  };

  return response;
}

export interface IArgbColor {
  a: number;
  r: number;
  g: number;
  b: number;
}

export interface IPlanViewerService {
  fetchPlanData(request: IPlanDataRequest): Promise<IPlanDataResponse>;
}

export default class PlanViewerService extends BaseService implements IPlanViewerService {
  constructor() {
    super("/api/planViewer");
  }

  public async fetchPlanData(request: IPlanDataRequest, signal?: AbortSignal): Promise<IPlanDataResponse> {
    const response = await this.get<any>("planStatement", {
      query: {
        planToken: request.planToken,
        statementId: request.statementId?.toString(),
      },
      signal,
    });

    const result: IPlanDataResponse = {
      hasActuals: response.hasActuals,
      hasMissingIndexes: response.hasMissingIndexes,
      linkInfo: getPlanDataLinkInfoFromResult(response.linkInfo),
      linkTooltips: parseNumericKeys(response.linkTooltips),
      opacityInfo: getPlanDataOpacityInfoFromResult(response.opacityInfo),
      operationCosts: getPlanDataOperationCostsFromResult(response.operationCosts),
      planToken: response.planToken,
      rootRelOp: getPlanDataNodeFromResult(response.rootRelOp),
      statementText: response.statementText,
      tooltips: getPlanDataNodeTooltipsFromResult(response.tooltips),
    };

    return result;
  }

  public async fetchRawPlanXML(request: IPlanDataRequest, signal?: AbortSignal): Promise<IRawPlanXMLResponse | null> {
    const result = await this.get<string | null>("planStatementXml", {
      defaultValue: null,
      query: {
        planToken: request.planToken,
        statementId: request.statementId?.toString(),
      },
      signal,
    });

    if (!result) {
      return null;
    }

    const rawPlanXMLResponse: IRawPlanXMLResponse = {
      rawXML: result,
    };

    return rawPlanXMLResponse;
  }
}
