import { useApolloClient, useQuery } from "@apollo/react-hooks";
import * as CLOSE_ALERT from "./closeAlert.graphql";
import * as ALERT_LOG_FILTER from "./AlertLogFilterQuery.graphql";
import { IEventLogResponse } from "../../../api/AdvisoryEventsService/AdvisoryEventsService";
import { IUser, IUserGroup } from "../../../features/Permissions/types";
import { INotesGridResponse } from "../../../api/models/AdvisoryConditionEvents";
import * as GET_ALERTLOG_NOTES from "./alertLogNoteQuery.graphql";
import * as ADD_NOTE from "./insertUpdateNoteToAlert.graphql";
import * as DELETE_NOTE from "./deleteAlertNote.graphql";
import * as GET_CONTACTS_LIST from "./getContactsList.graphql";
import * as ASSIGN_CONTACT_TO_ALERT from "./assignContactToAlert.graphql";
import * as GET_ALL_TAGS from "./GetAllTags.graphql";

// TYPES //
export type IAsyncResult<T> =
  | {
      data: undefined;
      error: Error;
      isLoading: false;
      refetch?: any;
      state: "error";
    }
  | {
      data: undefined;
      error: null;
      isLoading: true;
      refetch?: any;
      state: "loading";
    }
  | {
      data: T;
      error: null;
      isLoading: false;
      refetch?: any;
      state: "success";
    };

export interface IUsePermissionActionResponse {
  isSuccess: boolean;
}

type alertsLogParams = {
  alertLogFilterOptions: AlertLogFilterOption;
  startDateTimeUtc?: string;
  endDateTimeUtc?: string;
  limit?: number;
  offset?: number;
  sortProperty?: string;
  sortIsDescending?: boolean;
};

type AlertLogFilterOption = {
  conditionTypeFilter: string[];
  conditionFilter?: string[];
  tagsFilter?: string[];
  actionFilter: string[];
  severityFilter: number[];
  serverFilter: string[];
  searchKey: string;
  closeFilter: number[];
  targetFilter?: string[] | null;
};

export interface IUseAlertsLogActions {
  addUpdateNote: (params: IUseAddUpdateNoteActionParams) => Promise<IUseAlertActionParams>;
  closeSpecificAlert: (params: IUseCloseSpecificAlertActionParams) => Promise<IUseAlertActionParams>;
  deleteNote: (params: IUseDeleteNoteActionParams) => Promise<IUseAlertActionParams>;
  saveAssignedContact: (params: IUseSaveAssignedContactActionParams) => Promise<IUseAlertActionParams>;
}

export interface IAlertsLogData {
  items: IEventLogResponse[];
  rowCount: number;
}

export enum AlertClosingType {
  CloseAlertByAlertId = 1,
  CloseAlertByCondition = 2,
  CloseAlertByObject = 3,
}
export interface IUseAddUpdateNoteActionParams {
  alertLogId: number;
  includeInNotification: boolean;
  noteId: number;
  noteText: string;
  userName: string;
}
export interface IUseDeleteNoteActionParams {
  alertId: number;
  noteId: number;
}
export interface IUseCloseSpecificAlertActionParams {
  alertClosingType: AlertClosingType;
  alertId: number;
  conditionId: string;
  objectId: string;
  isResolved: boolean;
}

export interface IUseSaveAssignedContactActionParams {
  alertId: number;
  contactObjectId: string;
}

export interface IUseAlertActionParams {
  isSuccess: boolean;
}
export interface IUseAllContactsResponse {
  users: Map<string, string>;
  userGroups: Map<string, string>;
}

export interface IContactDetails {
  id: string;
  name: string;
}

// MUTATIONS //
export function useAlertsLogActions(): IUseAlertsLogActions {
  const apolloClient = useApolloClient();
  return {
    async addUpdateNote({ alertLogId, includeInNotification, noteId, noteText, userName }) {
      try {
        await apolloClient.mutate<
          unknown,
          { noteId: number; alertLogId: number; noteText: string; includeInNotification: boolean; userName: string }
        >({
          awaitRefetchQueries: true,
          mutation: ADD_NOTE,
          refetchQueries: [{ query: GET_ALERTLOG_NOTES, variables: { alertId: alertLogId } }],
          variables: {
            alertLogId,
            includeInNotification,
            noteId,
            noteText,
            userName,
          },
        });
        return { isSuccess: true };
      } catch (error) {
        return { isSuccess: false };
      }
    },
    async closeSpecificAlert({ alertId, conditionId, objectId, isResolved, alertClosingType }) {
      try {
        await apolloClient.mutate<
          unknown,
          {
            alertId: number;
            conditionId: string;
            objectId: string;
            isResolved: boolean;
            alertClosingType: AlertClosingType;
          }
        >({
          mutation: CLOSE_ALERT,
          variables: {
            alertClosingType,
            alertId,
            conditionId,
            isResolved,
            objectId,
          },
        });
        return { isSuccess: true };
      } catch (error) {
        return { isSuccess: false };
      }
    },
    async deleteNote({ noteId, alertId }) {
      try {
        await apolloClient.mutate<unknown, { id: number }>({
          awaitRefetchQueries: true,
          mutation: DELETE_NOTE,
          refetchQueries: [{ query: GET_ALERTLOG_NOTES, variables: { alertId } }],
          variables: {
            id: noteId,
          },
        });
        return { isSuccess: true };
      } catch (error) {
        return { isSuccess: false };
      }
    },
    async saveAssignedContact({ alertId, contactObjectId }) {
      try {
        await apolloClient.mutate<
          unknown,
          {
            alertId: number;
            contactObjectId: string;
          }
        >({
          mutation: ASSIGN_CONTACT_TO_ALERT,
          variables: {
            alertId,
            contactObjectId,
          },
        });
        return { isSuccess: true };
      } catch (error) {
        return { isSuccess: false };
      }
    },
  };
}

// QUERIES //
export function useAlertLogNotes(alertID: number): IAsyncResult<Array<INotesGridResponse>> {
  const {
    data = {
      alertLogNotes: {
        alertLogNotesByAlertId: [],
      },
    },
    error,
    loading,
  } = useQuery<
    {
      alertLogNotes: {
        alertLogNotesByAlertId: Array<INotesGridResponse>;
      };
    },
    { alertId: number }
  >(GET_ALERTLOG_NOTES, {
    variables: { alertId: alertID },
  });

  if (error) {
    return {
      data: undefined,
      error: error,
      isLoading: false,
      state: "error",
    };
  } else if (loading) {
    return {
      data: undefined,
      error: null,
      isLoading: true,
      state: "loading",
    };
  } else {
    return {
      data: data.alertLogNotes.alertLogNotesByAlertId.map<INotesGridResponse>((item) => ({
        ...item,
        logDateTimeUtc: item.logDateTimeUtc ? new Date(item.logDateTimeUtc) : null,
      })),
      error: null,
      isLoading: false,
      state: "success",
    };
  }
}
export function useGetAlertsLogQuery(alertsLogParams: alertsLogParams): IAsyncResult<IAlertsLogData> {
  const {
    data = { alertLogFilter: { getAlertLogByFilter: { items: [], rowCount: 0 } } },
    error,
    loading,
    refetch,
  } = useQuery<any>(ALERT_LOG_FILTER, { variables: { dr: alertsLogParams } });
  if (loading) {
    return {
      data: undefined,
      error: null,
      isLoading: true,
      state: "loading",
    };
  } else if (error) {
    return {
      data: undefined,
      error: error,
      isLoading: false,
      state: "error",
    };
  } else {
    return {
      data: data.alertLogFilter.getAlertLogByFilter,
      error: null,
      isLoading: false,
      refetch: refetch,
      state: "success",
    };
  }
}
export function useAllContactsList(): IAsyncResult<IUseAllContactsResponse> {
  const { data = { featureSecurity: { userGroups: [], users: [] } }, error, loading } = useQuery<{
    featureSecurity: {
      users: readonly IUser[];
      userGroups: readonly IUserGroup[];
    };
  }>(GET_CONTACTS_LIST);
  if (error) {
    return {
      data: undefined,
      error: error,
      isLoading: false,
      state: "error",
    };
  } else if (loading) {
    return {
      data: undefined,
      error: null,
      isLoading: true,
      state: "loading",
    };
  } else {
    return {
      data: {
        userGroups: new Map<string, string>(
          data.featureSecurity.userGroups.map((userGroup) => [userGroup.id, userGroup.name]),
        ),
        users: new Map<string, string>(
          data.featureSecurity.users.map((user) => [user.id, user.firstName + " " + user.lastName]),
        ),
      },
      error: null,
      isLoading: false,
      state: "success",
    };
  }
}

export function useGetAllTags(): IAsyncResult<Array<string>>  {
  const { data = { alertLogTags: { getAllAlertLogTags: [] } }, error, loading } = useQuery<{
    alertLogTags: {
      getAllAlertLogTags: Array<string>;
    };
  }>(GET_ALL_TAGS);
  if (error) {
    return {
      data: undefined,
      error: error,
      isLoading: false,
      state: "error",
    };
  } else if (loading) {
    return {
      data: undefined,
      error: null,
      isLoading: true,
      state: "loading",
    };
  } else {
    return {
      data: data.alertLogTags.getAllAlertLogTags,
      error: null,
      isLoading: false,
      state: "success",
    };
  }
}
