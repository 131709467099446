import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
import useStorageActivityStats from "./useStorageActivityStats";
import DataDisplay, { DataDisplayGroup } from "../../../../components/DataDisplay";
import { Skeleton } from "@material-ui/lab";
import { IStorageEntity } from "../../types";

export interface IStorageActivityMetricGroupProps {
    entity: IStorageEntity;
    metricType: "reads" | "writes";
}

const useStyles = makeStyles((theme) => ({
    activityStats: {
        display: "flex",
        flexDirection: "column",
        gridGap: `${theme.spacing(2)}px`,
    },
    skeleton: {
        borderRadius: theme.shape.borderRadius,
        height: `${theme.spacing(11)}px`,
        //this matches the container on data display
        width: `${theme.spacing(24)}px`,
    },
}));


const StorageActivityMetricGroup: React.FC<IStorageActivityMetricGroupProps> = ({ entity, metricType }) => {
    const classes = useStyles();
    const intl = useIntl();
    const { data, isLoading, error, expectedNumberOfMetrics } = useStorageActivityStats({ entity, metricType });

    if (error) {
        throw error;
    }

    return (
        <>
            {isLoading ? (
                <div className={classes.activityStats}>
                    {Array.from({ length: expectedNumberOfMetrics }, () => (
                        <Skeleton
                            className={classes.skeleton}
                            data-testid="DataDisplay-loading"
                            key={Math.random()}
                            role="progressbar"
                            variant="rect"
                        />
                    ))}
                </div>
            ) : (
                <DataDisplayGroup className={classes.activityStats} variant="filled">
                    {data.map((d) => (
                        <DataDisplay
                            key={`${d.label}-${d.value}`}
                            label={intl.formatMessage({ id: d.label })}
                            value={d.value.toString()}
                        />
                    ))}
                </DataDisplayGroup>
            )}
        </>
    );
};

export default StorageActivityMetricGroup;