import * as React from "react";
import { FeatureFlagService, IMonitorFeatures } from "../../api/FeatureFlags";
import { useAsync } from "../../hooks/Async";
import featureContext from "./featureContext";
import { IFeatureContext } from "./types";
import FullScreenLoad from "../../components/FullScreenLoad";

function fetchFeatureFlags(_: Record<string, unknown>, controller: AbortController): Promise<IMonitorFeatures> {
  const service = new FeatureFlagService();
  return service.fetchFeatureFlags(controller);
}

const defaultFeatures: IMonitorFeatures = {
  advisoryEdit: false,
  alertConfiguration: false,
  authenticationService: false,
  demoMode: false,
  jumpToSqlSentry: false,
  notifications: false,
  showAlertCategory: false,
  userAndGroupCreation: false,
  vmWareDashboard: false,
};

/**
 * Context provider component that retrieves feature flags from service and provides an `IFeatureContext`
 * instance for children to consume.
 * This component should be rendered only once at the top of the component tree.
 */
const FeatureFlagProvider: React.FunctionComponent = ({ children }) => {
  const { data = defaultFeatures, isLoading, reload } = useAsync(fetchFeatureFlags);

  const contextValue = React.useMemo<IFeatureContext>(() => {
    return {
      features: data,
      hasFeature: (feature) => data[feature],
      reload,
    };
  }, [data, reload]);

  if (isLoading) return <FullScreenLoad disableFadeAnimation message="almostDone" />;

  return <featureContext.Provider value={contextValue}>{children}</featureContext.Provider>;
};

export default FeatureFlagProvider;
