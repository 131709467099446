import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

const SqlIcon: React.FunctionComponent<SvgIconProps> = (props) => {
    return (
        <SvgIcon height="24" viewBox="0 0 6.35 6.35" width="24"{...props}>
            <path d="M5.295 1.341s-.957-.031-2.138-.031c-1.18 0-2.138.031-2.138.031 0-.507.957-.918 2.138-.918s2.138.411 2.138.918zM5.295 5.016c0 .508-.957.936-2.138.936-1.18 0-2.138-.427-2.138-.934 0 0 .932-.002 2.113-.002h2.163z" /><path d="M1.02 1.317V5.07M1.05 3.186V1.568l.028.044c.124.19.406.368.77.487a4.24 4.24 0 001.39.187 4.186 4.186 0 001.135-.159c.436-.128.74-.315.884-.544.005-.008.007.352.007 1.605v1.615l-.028-.043c-.124-.184-.386-.353-.735-.472-.673-.231-1.6-.268-2.364-.093-.3.069-.591.183-.78.307a.936.936 0 00-.287.271l-.02.032z" fill="none" /><path d="M1.019 1.317h4.276v3.716H1.02z" /><path d="M4.867 1.142a1.71.555 0 01-1.71.554 1.71.555 0 01-1.71-.554 1.71.555 0 011.71-.555 1.71.555 0 011.71.555z" fill="#fff" /><g aria-label="SQL" fill="#fff" fontFamily="Segoe UI" fontSize="2.236" fontWeight="700" letterSpacing="-.062" strokeWidth=".248"><path d="M1.259 4.218v-.35q.095.08.206.12.111.04.225.04.066 0 .116-.012t.083-.033q.033-.022.05-.05.016-.03.016-.064 0-.046-.026-.082-.026-.036-.072-.066-.045-.03-.107-.06-.062-.028-.134-.057-.184-.077-.274-.187-.09-.11-.09-.266 0-.123.05-.21.048-.088.132-.145.086-.057.197-.083.111-.027.236-.027.122 0 .216.015.095.014.175.045v.326q-.04-.027-.087-.048-.045-.02-.095-.034-.049-.014-.098-.02-.048-.007-.092-.007-.06 0-.109.012-.049.01-.083.032-.034.02-.052.05-.019.028-.019.064 0 .04.021.071.02.03.059.06.038.027.093.054.054.026.123.054.094.04.168.084.076.044.13.1.053.056.081.128.028.07.028.166 0 .13-.05.22-.049.089-.134.144-.085.055-.199.079-.112.024-.238.024-.129 0-.246-.022-.115-.022-.2-.065zM3.184 4.305q-.337 0-.548-.218-.212-.221-.212-.572 0-.37.215-.6.214-.23.57-.23.335 0 .541.22.208.218.208.579 0 .368-.215.595l-.014.015-.015.013.397.381h-.494l-.207-.21q-.103.027-.226.027zm.015-1.316q-.185 0-.295.14-.109.139-.109.368 0 .232.11.368Q3.012 4 3.19 4q.183 0 .29-.13.107-.134.107-.367 0-.242-.104-.378-.103-.136-.284-.136zM5.088 4.278h-.932V2.712h.352v1.28h.58z" /></g>
        </SvgIcon>
    );
};

export default SqlIcon;