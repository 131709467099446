import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { PopoverOrigin } from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import * as React from "react";
import { useIntl } from "react-intl";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { formatDate } from "../../../../components/FormattedDate";
import { useDemoMode } from "../../../../hooks/useDemoMode";
import { IDigestDashboard } from "../../useDashboards";
import DashboardThumbnail from "./DashboardThumbnail";

export interface IDashboardCardProps {
  dashboard: IDigestDashboard;
  onDeleteDashboard(): void;
}

const useStyles = makeStyles((theme) => ({
  actionArea: {
    "&:hover $focusHighlight": {
      opacity: 0,
    },
  },
  content: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: ".5rem .25rem .5rem 1rem",
  },
  focusHighlight: {},
  iconButton: {
    marginLeft: "auto",
  },
  menuIcon: {
    minWidth: theme.spacing(5),
  },
  root: {
    "&:hover": {
      boxShadow: "0px 3px 10px 0px rgba(0, 0, 0, 0.2)",
      transform: "scale(1.025)",
    },
    boxShadow: "0px 3px 6px 0px rgba(0, 0, 0, 0.1)",
    transition: "transform linear 50ms, box-shadow linear 50ms",
    width: "17rem",
  },
  typography: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const subMenuOrigin: PopoverOrigin = { horizontal: "left", vertical: "top" };

const DashboardCard: React.FC<IDashboardCardProps> = ({ dashboard, onDeleteDashboard }) => {
  const classes = useStyles();
  const intl = useIntl();
  const match = useRouteMatch();
  const { search: searchParams } = useLocation();
  const anchorEl = React.useRef<HTMLButtonElement>(null);
  const [open, setOpen] = React.useState(false);
  const { isDemoMode } = useDemoMode();

  return (
    <Card className={classes.root} key={dashboard.id}>
      <CardActionArea
        classes={{
          focusHighlight: classes.focusHighlight,
          root: classes.actionArea,
        }}
        component={Link}
        data-testid={`DashboardCard-${dashboard.id}`}
        disableTouchRipple
        to={`${match.url}/${dashboard.id}${searchParams}`}
      >
        <DashboardThumbnail />
        <CardContent className={classes.content}>
          <ListItemText
            primary={dashboard.name}
            primaryTypographyProps={{ className: classes.typography, title: dashboard.name }}
            secondary={
              intl.formatMessage({ id: "created" }) +
              ":" +
              formatDate(new Date(dashboard.dateModified), {
                day: "2-digit",
                hour: "2-digit",
                hour12: true,
                minute: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
            }
            secondaryTypographyProps={{ className: classes.typography }}
          />
          <IconButton
            className={classes.iconButton}
            onClick={(e) => {
              e.preventDefault();
              setOpen(true);
            }}
            ref={anchorEl}
            title={intl.formatMessage({ id: "options" })}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl.current}
            anchorOrigin={subMenuOrigin}
            onClick={(e) => {
              e.preventDefault();
              setOpen(false);
            }}
            onClose={() => setOpen(false)}
            open={open}
          >
            <MenuItem component={Link} to={`${match.url}/${dashboard.id}/edit${searchParams}`}>
              <ListItemIcon className={classes.menuIcon}>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={intl.formatMessage({ id: "edit" })} />
            </MenuItem>
            {!isDemoMode && (
              <MenuItem onClick={() => onDeleteDashboard()}>
                <ListItemIcon className={classes.menuIcon}>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: "delete" })} />
              </MenuItem>
            )}
          </Menu>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default DashboardCard;
