import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { ITopologyItemEventSourceConnection } from "../../components/TopologyContext";
import { BlockingContextProvider } from "../../contexts/blockingContext";
import BlockDetails from "./components/BlockDetails";
import BlockingDetailGrid from "./components/BlockingDetailGrid";
import ControlOptions from "./components/ControlOptions";
import ReportingChartCard from "./components/ReportingChart";
import { IBlockingSelection } from "./types";

export interface IBlockingContentProps {
  target: ITopologyItemEventSourceConnection;
  visibleDates: Readonly<{
    from: Date;
    to: Date;
  }>;
}

const useStyles = makeStyles((theme) => ({
  chart: {
    gridArea: "chart",
  },
  controlOptions: {
    gridArea: "controlOptions",
    textAlign: "right",
  },
  details: {
    display: "grid",
    gridArea: "details",
    gridTemplateRows: "min-content 1fr",
  },
  grid: {
    gridArea: "grid",
  },
  root: {
    display: "grid",
    gridGap: theme.spacing(2),
    gridTemplateAreas: `
      "controlOptions controlOptions"
      "chart chart"
      "grid grid"
      "details details"
    `,
    gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr)",
    gridTemplateRows: `min-content min-content min-content minmax(${theme.spacing(50)}px, 1fr)`,
    height: "100%",
  },
}));

const BlockingContent: React.FC<IBlockingContentProps> = ({ target, visibleDates }) => {
  const classes = useStyles();
  const [fullScreen, setFullScreen] = React.useState(false);
  const [selection, setSelection] = React.useState<IBlockingSelection | null>(null);
  return (
    <div className={classes.root}>
      <BlockingContextProvider>
        <ControlOptions className={classes.controlOptions} />
        <ReportingChartCard
          className={classes.chart}
          eventSourceConnectionId={target.itemId}
          visibleDates={visibleDates}
        />
        <BlockingDetailGrid
          className={classes.grid}
          eventSourceConnectionId={target.itemId}
          onChangeSelection={setSelection}
          selection={selection}
          visibleDates={visibleDates}
        />
        <Card className={classes.details}>
          <BlockDetails
            fullScreen={fullScreen}
            onToggleFullScreen={() => setFullScreen((prev) => !prev)}
            selection={selection}
          />
        </Card>
        <Dialog
          PaperProps={{ className: classes.details }}
          fullScreen
          onClose={() => setFullScreen(false)}
          open={fullScreen}
        >
          <BlockDetails
            fullScreen={fullScreen}
            onToggleFullScreen={() => setFullScreen((prev) => !prev)}
            selection={selection}
          />
        </Dialog>
      </BlockingContextProvider>
    </div>
  );
};

export default BlockingContent;
