import * as React from "react";
import sortContext from "./sortContext";
import { ISortContext } from "./types";
const { useContext } = React;

/**
 * Hook for accessing sort context in a React function component.
 * Relies on sort context provider to exist in the component heirarchy.
 * @returns Current sort context value from the nearest ancestor provider.
 */
export function useSortContext(): ISortContext {
  return useContext(sortContext);
}
