import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { ITopologyItemEventSourceConnection } from "../../components/TopologyContext";
import { DeadlocksContextProvider } from "../../contexts/deadlocksContext";
import DeadlockDetails from "./components/DeadlockDetails";
import VictimsGrid from "./components/VictimsGrid";

export interface IDeadlocksContentProps {
  target: ITopologyItemEventSourceConnection;
}

const useStyles = makeStyles((theme) => ({
  details: {
    gridArea: "details",
  },
  root: {
    display: "grid",
    gridGap: theme.spacing(2),
    gridTemplateAreas: `
      "grid grid"
      "details details"
    `,
    gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr)",
    gridTemplateRows: `min-content minmax(${theme.spacing(100)}px, 1fr)`,
    height: "100%",
  },
}));

const DeadlocksContent: React.FC<IDeadlocksContentProps> = ({ target }) => {
  const classes = useStyles();
  const [fullScreen, setFullScreen] = React.useState(false);

  function toggleFullScreen(): void {
    setFullScreen((prev) => !prev);
  }

  return (
    <div className={classes.root}>
      <DeadlocksContextProvider eventSourceConnectionId={target.itemId}>
        <VictimsGrid />
        <DeadlockDetails className={classes.details} fullScreen={fullScreen} onToggleFullScreen={toggleFullScreen} />
        <Dialog fullScreen onClose={toggleFullScreen} open={fullScreen}>
          <DeadlockDetails className={classes.details} fullScreen={fullScreen} onToggleFullScreen={toggleFullScreen} />
        </Dialog>
      </DeadlocksContextProvider>
    </div>
  );
};

export default DeadlocksContent;
