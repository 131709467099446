import { MuiLocalizationProvider } from "@sentryone/material-ui";
import * as React from "react";
import { useIntl } from "react-intl";

const MonitorMuiLocalizationProvider: React.FunctionComponent = ({ children }) => {
  const intl = useIntl();
  return (
    <MuiLocalizationProvider
      value={{
        dataTable: {
          collapseAction: intl.formatMessage({ id: "collapse" }),
          nextPageAction: intl.formatMessage({ id: "nextButton" }),
          noDataMessage: intl.formatMessage({ id: "noDataToDisplay" }),
          openMenuAction: intl.formatMessage({ id: "openMenu" }),
          previousPageAction: intl.formatMessage({ id: "previousButton" }),
          rowsPerPageLabel: intl.formatMessage({ id: "rowsPerPage" }),
          showMoreAction: intl.formatMessage({ id: "showMore" }),
        },
        profile: {
          changePassword: intl.formatMessage({ id: "changePassword" }),
          loading: intl.formatMessage({ id: "loading" }),
          logout: intl.formatMessage({ id: "logout" }),
          personalAccessToken: intl.formatMessage({ id: "personalAccessToken" }),
          profile: intl.formatMessage({ id: "profile" }),
          profileEmail: intl.formatMessage({ id: "profileEmail" }),
          profileUsername: intl.formatMessage({ id: "profileUsername" }),
          userProfile: intl.formatMessage({ id: "userProfile" }),
        },
      }}
    >
      {children}
    </MuiLocalizationProvider>
  );
};

export default MonitorMuiLocalizationProvider;
