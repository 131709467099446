import * as React from "react";
import { TopologyObjectType } from "../../api/PerformanceAdvisor";
import { useDateContext } from "../../components/DateContext";
import DocumentTitle from "../../components/DocumentTitle";
import { useTopology } from "../../components/TopologyContext";
import { TopSqlContextProvider } from "../../contexts/topSqlContext";
import { useCurrentTarget } from "../../hooks/useCurrentTarget";
import TopSqlContent from "./components/TopSqlContent";

const TopSql: React.FC = () => {
  const topology = useTopology();
  const { dateRange } = useDateContext();
  const target = useCurrentTarget();
  if (!target) {
    throw new Error("Top SQL can only be used with a target");
  } else if (target.type !== TopologyObjectType.eventSourceConnection) {
    throw new Error(`Top SQL can only be used with event source connections. Received ${target.type}`);
  }

  const device = topology.findByObjectId(target.parentObjectId, TopologyObjectType.device);
  if (!device) {
    throw new Error(`No device found with ID ${target.parentObjectId}`);
  }

  return (
    <TopSqlContextProvider
      deviceId={device.itemId}
      eventSourceConnectionId={target.itemId}
      // key will cause TopSqlContextProvider to completely reset its state when switching targets
      key={target.objectId}
      visibleDates={dateRange}
    >
      <TopSqlContent eventSourceConnectionId={target.itemId} visibleDates={dateRange} />
      <DocumentTitle title="topSql" topologyItem={device} />
    </TopSqlContextProvider>
  );
};

export default TopSql;
