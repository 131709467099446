import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { Route, useRouteMatch } from "react-router-dom";
import { useTopology, ITopologyItem } from "../../components/TopologyContext";
import { HealthContextProvider } from "./context";
import DocumentTitle from "../../components/DocumentTitle";
import EnvironmentHeader from "../../ui/EnvironmentHeader/EnvironmentHeader";
import HealthScore from "../../ui/HealthScore/HealthScore";
import EnvironmentScoreSheet from "../../ui/EnvironmentScoreSheet/EnvironmentScoreSheet";
import { DateTime } from "luxon";
import { Alerts } from "../../ui/alerts/alerts";
import { Card, CardContent } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  content: {
    gridArea: "content",
    overflow: "auto",
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
  },
  root: {
    display: "grid",
    gridTemplateAreas: `
      "tabs"
      "content"
    `,
    gridTemplateColumns: "1fr",
    gridTemplateRows: "min-content 1fr",
    height: "100%",
  },
}));

export interface IEnvironmentHealthProps {
  className?: string;
  objectId?: string;
}

const HealthOverview: React.FC<IEnvironmentHealthProps> = ({ className, objectId }) => {
  const topology = useTopology();
  const currentMatch = useRouteMatch();
  const topLevelObject: ITopologyItem | undefined = objectId
    ? topology.findByObjectId(objectId) ?? undefined
    : undefined;

  const classes = useStyles();

  const now = DateTime.local();
  const end_date = now.toJSDate();
  const start_date = now.minus({ day: 1 }).toJSDate();

  return (
    <HealthContextProvider>
      <div className={classNames(classes.root, className)}>
        <div className={classes.content}>
          <EnvironmentHeader topLevelObject={topLevelObject} />
          <Route exact path={currentMatch.url}>
            <DocumentTitle title="healthOverview" />
            <Card>
              <CardContent>
                <HealthScore topLevelObject={topLevelObject}></HealthScore>
              </CardContent>
            </Card>
            <br></br>
            <EnvironmentScoreSheet topLevelObject={topLevelObject}></EnvironmentScoreSheet>
            <br></br>
            <Alerts endDate={end_date} onClearFilter={null} selectedChartCell={null} startDate={start_date}></Alerts>
          </Route>
        </div>
      </div>
    </HealthContextProvider>
  );
};

export default HealthOverview;
