import { TopologyObjectType } from "./HealthStatus";

export enum EventLogSeverity {
  critical = "critical",
  high = "high",
  low = "low",
  medium = "medium",
  info = "info",
}

export interface IAggregateEventLog {
  activeCount: number;
  alertType: string;
  conditionId: number;
  conditionName: string;
  itemId: number;
  itemType: TopologyObjectType;
  lastEndDateTimeUtc?: Date;
  lastStartDateTimeUtc: Date;
  objectId: string;
  severity: EventLogSeverity;
  tags?: string;
  value: number;
  weightSum: number;
}

export interface IAggregateEventLogRequest {
  endDate: Date;
  objectId?: number;
  objectType: TopologyObjectType;
  startDate: Date;
  scoreThreshold?: number;
}

export interface IEventLogEntry {
  conditionId: number;
  conditionName: string;
  duration: string;
  endDate?: Date;
  isResolved: boolean;
  objectId: string;
  severity: EventLogSeverity;
  startDate: Date;
  tags: string;
}

export interface IEventLogRequest {
  conditionId: number;
  endDate: Date;
  objectId?: string;
  startDate: Date;
}
