import React, { ReactElement, useEffect } from "react";
import * as d3 from "d3";
import { Box, Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { AggregateWaitsData, useTraceWaitsChartData } from "./useTraceWaitsChartData";
import LoadingIndicator from "../../../../components/LoadingIndicator";

const pie_width = 350;
const pie_height = 250;
const margin = 10;

const radius = pie_width / 2 - margin;
const pie_inner_radius = 0.54 * radius;
const pie_outer_radius = 0.72 * radius;

const slice_opacity = 0.6;

export default function TraceWaitsChart (props: {eventSourceConnectionId: number, trace_id: number}): ReactElement {
    const {data = [], error, isLoading} = useTraceWaitsChartData(props.eventSourceConnectionId, props.trace_id);
    
    const wait_types = Object.keys(data[0] ?? {});
    const pie_slice_color = d3.scaleOrdinal()
            .domain(wait_types)
            .range(["#1f77b4","#ff7f0e","#2ca02c","#d62728","#9467bd","#8c564b","#e377c2","#7f7f7f","#bcbd22","#17becf"]);

    const mouse_enter = (waitType: string): void => {
        d3.select(`[key=pie-${waitType}`)
            .style("opacity", 1);
        document.querySelector(`#row-${waitType}`)
            ?.classList.toggle('Mui-selected');
    };

    const mouse_leave = (waitType: string): void => {
        d3.select(`[key=pie-${waitType}`)
            .style("opacity", slice_opacity);
        document.querySelector(`#row-${waitType}`)
            ?.classList.toggle('Mui-selected');
    };

    const total_waits_duration = data.reduce((total, cur) => total = total + cur.durationMs, 0);

    useEffect(() => {
        const svg = d3.select("#trace_waits_chart")
            .append("svg")
            .attr("width", pie_width)
            .attr("height", pie_height)
            .append("g")
                .attr("transform", `translate(${pie_width / 2}, ${pie_height / 2})`);
        
        const pie_data = d3.pie<AggregateWaitsData>()
            .sort(null)
            .value((d) => d.durationMs)(data);
        
        const pie_arc = d3.arc<d3.PieArcDatum<AggregateWaitsData>>()
            .innerRadius(pie_inner_radius)
            .outerRadius(pie_outer_radius);
        
        svg
            .selectAll('allSlices')
            .data(pie_data)
            .enter()
            .append('path')
                .attr('d', pie_arc)
                .attr('key', ({data}) => `pie-${data.waitType}`)
                .attr('key-data', ({data}) => data.waitType)
                .attr('fill', ({data}): string => pie_slice_color(data.waitType) as string)
                .attr("stroke", "white")
                .style("stroke-width", "2px")
                .style("opacity", slice_opacity)
                .style('cursor', 'pointer')
                .on('mouseenter', function(d, {data}) {
                    mouse_enter(data.waitType);
                })
                .on('mouseleave', function (d) {
                    const elm = d3.select(this);
                    mouse_leave(elm.attr('key-data'));
                });
        return () => {
            d3.select("#trace_waits_chart svg").remove();
        }
    }, [data, pie_slice_color]);

    if (error) {
        throw error;
    }

    if(isLoading){
        return(
            <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                <LoadingIndicator variant="chart"></LoadingIndicator>
            </Box>
        );
    }

    return (
        <Box height='100%' id="trace_waits_chart_container" width='100%'>
            <Grid container spacing={2}>
                <Grid id="trace_waits_chart" item></Grid>
                <Box id="trace_waits_chart_legend" sx={{
                    alignItems: 'flex-start',
                    display: 'flex',
                    flexDirection: 'column',
                    m: 1,
                    maxHeight: 440,
                    minWidth: 500,
                    p: 1,
                }}>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Wait Type</TableCell>
                                <TableCell>%</TableCell>
                                <TableCell>Duration (ms)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{data.map((row) => (
                            <TableRow
                                hover
                                id={`row-${row.waitType}`}
                                key={`row-${row.waitType}`}
                                onMouseEnter={() => { mouse_enter(row.waitType) }}
                                onMouseLeave={() => { mouse_leave(row.waitType) }}
                            >
                                <TableCell style={{backgroundColor: `${pie_slice_color(row.waitType)}`, width: 5}}> </TableCell>
                                <TableCell style={{ verticalAlign: 'middle'}}>
                                    {row.waitType}
                                </TableCell>
                                <TableCell style={{ verticalAlign: 'middle'}}>{(row.durationMs / total_waits_duration * 100).toFixed()}%</TableCell>
                                <TableCell style={{ verticalAlign: 'middle'}}>{row.durationMs}</TableCell>
                            </TableRow>
                        ))}</TableBody>
                    </Table>
                </Box>
            </Grid>
        </Box>
    );
};