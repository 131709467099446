import Button from "@material-ui/core/Button";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { CardTable } from "@sentryone/material-ui";
import { SortDescriptor } from "@sentryone/react-datatable";
import * as React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { IExecutedStatementsResponse } from "../../../../api/models/ExecutedStatements";
import { styles } from "../../../../components/ThemeProvider/grids";
import { useTopSqlContext } from "../../../../contexts/topSqlContext";
import { useState } from "react";
import CustomSnackbar from "../../components/CustomSnackbar";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import QueryTooltip from "../QueryTooltip";
import { DataTable, DataTablePFSEvent } from "primereact/datatable";
import { Column } from "primereact/column";

export interface IStatementsGridProps extends WithStyles<typeof styles> {
  className?: string;
  style?: React.CSSProperties;
  onHandleSort: (sort: readonly SortDescriptor[]) => void;
}

const StatementsGrid = React.forwardRef<HTMLElement, IStatementsGridProps>(
  ({ classes, className, onHandleSort, style = {}, ...props }, ref) => {
    const {
      handleStatementGridPageChange,
      handleSelectedStatementChange,
      handleStatementsEventsClick,
      statementResults,
    } = useTopSqlContext();
    const { data, isLoading } = statementResults;
    const [open, setOpen] = useState(false);
    const copyQuery = (d: IExecutedStatementsResponse | undefined): void => {
      navigator.clipboard?.writeText(d?.textData ?? "");
      setOpen(true);
    };

    const handleClose = (): void => {
      setOpen(false);
    };

    const onSort = (event: DataTablePFSEvent): void => {
      onHandleSort([
        {
          direction: event.sortOrder && event.sortOrder > 0 ? "asc" : "desc",
          id: event.sortField,
        },
      ]);
    };
    return (
      <div className={className} ref={ref as React.Ref<HTMLDivElement>}>
        <CustomSnackbar
          animation="Slide"
          autoHideDuration={2000}
          handleClose={handleClose}
          icon={<FileCopyIcon />}
          message="Query copied successfully!"
          open={open}
          slideDirection="right"
        />

        <CardTable style={style} title={<FormattedMessage id="statements" />} {...props}>
          <DataTable
            columnResizeMode="expand"
            dataKey="textData"
            loading={isLoading}
            onSelectionChange={(e) => handleSelectedStatementChange(e.value)}
            resizableColumns
            responsiveLayout="scroll"
            selectionMode="single"
            size="small"
            sortField="readsL"
            sortOrder={-1}
            value={[...data]}
          >
            <Column
              body={(d) => {
                if (d.eventCount > 0) {
                  return (
                    <FormattedMessage id="showTraceEvents">
                      {(showTraceEventsMessage: string) => (
                        <Button
                          className={classes.events}
                          color="primary"
                          onClick={() => {
                            handleStatementGridPageChange(0, 15);
                            handleStatementsEventsClick(d);
                          }}
                          title={showTraceEventsMessage}
                          variant="text"
                        >
                          <FormattedNumber value={d.eventCount} />
                        </Button>
                      )}
                    </FormattedMessage>
                  );
                } else {
                  return (
                    <Button className={classes.events} color="primary" disabled variant="text">
                      <FormattedNumber value={d.eventCount} />
                    </Button>
                  );
                }
              }}
              field="eventCount"
              header={<FormattedMessage id="events" />}
              sortable
            />
            <Column
              body={(d) => {
                return navigator.clipboard ? (
                  <div className={classes.copybtn}>
                    <QueryTooltip placement="top-start" title={d.textData.replace(/\t/g, "  ")}>
                      <span className={classes.queryWrap}>{d.textData.replace(/\t/g, "  ")}</span>
                    </QueryTooltip>
                    <FileCopyOutlinedIcon
                      aria-label="copy-btn"
                      className={classes.cursorPointer}
                      onClick={() => copyQuery(d)}
                    />
                  </div>
                ) : (
                  <QueryTooltip placement="top-start" title={d.textData.replace(/\t/g, "  ")}>
                    <span>{d.textData.replace(/\t/g, "  ")}</span>
                  </QueryTooltip>
                );
              }}
              className={classes.noWrap}
              field="textData"
              header={<FormattedMessage id="textData" />}
              sortable
              style={{ minWidth: "12rem" }}
            />
            <Column field="duration" header={<FormattedMessage id="duration" />} sortable />
            <Column field="execCount" header={<FormattedMessage id="count" />} sortable />
            <Column field="cpu" header={<FormattedMessage id="cpuMs" />} sortable />
            <Column field="readsL" header={<FormattedMessage id="readsL" />} sortable />
            <Column field="writesL" header={<FormattedMessage id="writesL" />} sortable />
          </DataTable>
        </CardTable>
      </div>
    );
  },
);

export default withStyles(styles)(StatementsGrid);
