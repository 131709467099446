import * as React from "react";
import { useTooltipInPortal } from "@visx/tooltip";
import { UseTooltipParams } from "@visx/tooltip/lib/hooks/useTooltip";
import { TooltipInPortalProps } from "@visx/tooltip/lib/hooks/useTooltipInPortal";
import { IDataPoint, IForecastDataPoint } from "./ForecastChart";
import { makeStyles } from "@material-ui/core/styles";
import { formatDigitalStorage } from "../../../../utilities/Formatters";
import { formatDate } from "../../../../components/FormattedDate";
import { ITranslation } from "../../../../intl/types";
import { useIntl } from "react-intl";

export interface IForecastChartTooltipProps extends TooltipInPortalProps {
  tooltip: UseTooltipParams<IDataPoint>;
  tooltipContainerRef: React.MutableRefObject<HTMLElement | SVGElement | null>;
}

export const isForecastedDataTooltip = (tooltip: UseTooltipParams<IDataPoint>): boolean =>
  Boolean(tooltip.tooltipData && "high" in tooltip.tooltipData && "low" in tooltip.tooltipData);

const useStyles = makeStyles((theme) => ({
  tooltipRoot: {
    "& caption": {
      color: theme.palette.common.white,
      fontSize: theme.typography.caption,
      paddingBottom: theme.spacing(1),
    },
    "& td": {
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightBold,
      textAlign: "right",
    },
    "& th": {
      color: "#aaa",
      textAlign: "left",
    },
    backgroundColor: "#333",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    whiteSpace: "nowrap",
    zIndex: 1301,
  },
}));

const formatTooltipTimestamp = (timestamp: number): string =>
  formatDate(new Date(timestamp), {
    day: "2-digit",
    hour: "2-digit",
    hour12: true,
    minute: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

interface ITooltipRowProps {
  label: keyof ITranslation;
  value: string | null;
}

const TooltipRow = ({ label, value }: ITooltipRowProps): React.ReactElement | null => {
  const intl = useIntl();
  return value ? (
    <tr>
      <th scope="row">{intl.formatMessage({ id: label })}</th>
      <td>{value}</td>
    </tr>
  ) : null;
};

const ForecastChartTooltip: React.FC<IForecastChartTooltipProps> = ({ tooltipContainerRef, tooltip, ...props }) => {
  const classes = useStyles();
  const { containerRef, TooltipInPortal } = useTooltipInPortal();
  containerRef(tooltipContainerRef.current);

  if (!tooltip.tooltipData || !tooltip.tooltipOpen) {
    return null;
  }
  const isForecastedValue = isForecastedDataTooltip(tooltip);
  const forecastedFreeSpaceValue = isForecastedValue
    ? formatDigitalStorage(+tooltip.tooltipData.value.toFixed(2))
    : null;
  //this cast is safe because we verify the tooltip data is for forecast data
  const forecastedHigh = isForecastedValue
    ? formatDigitalStorage(+(tooltip.tooltipData as IForecastDataPoint).high.toFixed(2))
    : null;
  const forecastedLow = isForecastedValue
    ? formatDigitalStorage(+(tooltip.tooltipData as IForecastDataPoint).low.toFixed(2))
    : null;
  const freeSpaceValue = isForecastedValue ? null : formatDigitalStorage(+tooltip.tooltipData.value.toFixed(2));
  const timestamp = formatTooltipTimestamp(tooltip.tooltipData.timestamp);

  return (
    <TooltipInPortal
      applyPositionStyle
      className={classes.tooltipRoot}
      // set this to random so it correctly updates with parent bounds
      key={Math.random()}
      left={tooltip.tooltipLeft}
      top={tooltip.tooltipTop}
      unstyled
      {...props}
    >
      <table>
        <caption>{timestamp}</caption>
        <tbody>
          <TooltipRow label="freeSpace" value={freeSpaceValue} />
          <TooltipRow label="upperBound" value={forecastedHigh} />
          <TooltipRow label="forecastedFreeSpace" value={forecastedFreeSpaceValue} />
          <TooltipRow label="lowerBound" value={forecastedLow} />
        </tbody>
      </table>
    </TooltipInPortal>
  );
};

export default ForecastChartTooltip;
