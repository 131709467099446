import { IExecutedQueryTotalsResponse } from "../../api/models/ExecutedQueryTotals";
import { IExecutedStatementsResponse } from "../../api/models/ExecutedStatements";
import { IExecutedQueryTraceEventsResponse } from "../../api/models/IExecutedQueryTraceEventsCriteria";
import { IDateRange } from "../../components/DateContext";

export interface ISearchParams {
  searchKeyword?: string | null;
}

export interface ITotalsParams {
  totalsDatabase?: string[] | null;
}

export interface ITraceEventParams {
  eventClass?: number[] | null;
  hostName?: string[] | null;
  applicationName?: string[] | null;
  databaseName?: string[] | null;
  loginName?: string[] | null;
  spid?: number[] | null;
  errorKeyword?: number[] | null;
}

export interface IDataResults {
  data: readonly any[];
  error?: Error;
  isLoading: boolean;
  pageLoading: boolean;
  totalCount: number;
}

export interface ITopSqlContextProps {
  deviceId: number;
  eventSourceConnectionId: number;
  visibleDates: Readonly<{
    from: Date;
    to: Date;
  }>;
}

export interface ISelectedStatement {
  cpu?: number | null;
  databaseId?: number | null;
  queryHash?: string | null;
  objectName?: string | null;
  reads?: number | null;
  textData: string;
  textMd5: string;
  type: string;
}

export interface ISelectedTotal {
  databaseId: number;
  databaseName: string;
  deviceId: number;
  textData: string;
  textMd5: string;
  traceDataId: number;
  type: string;
}

export interface ISelectedTraceEvent {
  cpu?: number | null;
  databaseId: number;
  databaseName: string | null;
  parentId: number | null;
  parentTextMd5?: string;
  textData: string;
  textMd5: string;
  type?: string;
}

export interface ITotalsTraceEventCriteria {
  databaseId: number;
  textMd5: string;
}

export interface IStatementsTraceEventCriteria {
  databaseId?: number;
  objectName: string;
  textMd5: string;
}

export interface ITopSqlContentProps {
  eventSourceConnectionId: number;
  visibleDates: Readonly<{
    from: Date;
    to: Date;
  }>;
  versionNumber?: string;
}

export interface ITopSqlContentState {
  isTotals: boolean;
  isTraceStatement: boolean;
  selectedEvent: ISelectedTraceEvent | null;
  selectedStatement: ISelectedStatement | null;
  selectedStatementEvent: ISelectedTraceEvent | null;
  selectedTotal: ISelectedTotal | null;
  statementsTraceEventCriteria: IStatementsTraceEventCriteria | null;
  totalsTraceEventCriteria: ITotalsTraceEventCriteria | null;
}

export interface IGridPageInfo {
  pageIndex: number;
  pageSize: number;
}

export interface IUseExecutedStatementsResult extends IDataResults {
  data: readonly IExecutedStatementsResponse[];
}

export interface IUseExecutedQueryTotalsResults extends IDataResults {
  data: readonly IExecutedQueryTotalsResponse[];
}

export interface IExecutedQueryTraceEventsResults extends IDataResults {
  data: readonly IExecutedQueryTraceEventsResponse[];
}

export interface ITopSqlContext extends ISearchParams {
  /**
   * The cachePlanId to display plan information for, including plan XML, diagram, and parameters.
   * If `undefined`, the cachePlanId is still loading and not yet available.
   * If `null`, the cachePlanId is not available for the selection.
   */
  cachePlanId: number | null | undefined;
  chartFilter: IDateRange | null;
  eventSourceConnectionId: number;
  isTotals: boolean;
  isTraceStatement: boolean;
  selectedEvent: ISelectedTraceEvent | null;
  selectedStatement: ISelectedStatement | null;
  selectedStatementEvent: ISelectedTraceEvent | null;
  selectedTotal: ISelectedTotal | null;
  statementsTraceEventCriteria: IStatementsTraceEventCriteria | null;
  totalsTraceEventCriteria: ITotalsTraceEventCriteria | null;
  totalsGridPageInfo: IGridPageInfo;
  traceEventGridPageInfo: IGridPageInfo;
  statementGridPageInfo: IGridPageInfo;
  statementResults: IUseExecutedStatementsResult;
  statementsTraceEventsResults: IExecutedQueryTraceEventsResults;
  totalsResults: IUseExecutedQueryTotalsResults;
  totalsTraceEventsResults: IExecutedQueryTraceEventsResults;
  handleChangeChartFilter: (newChartFilter: IDateRange | null) => void;
  handleChangeIsTotals: () => void;
  handleTotalsGridPageChange: (pageIndex: number, pageSize: number) => void;
  handleTraceEventGridPageChange: (pageIndex: number, pageSize: number) => void;
  handleStatementGridPageChange: (pageIndex: number, pageSize: number) => void;
  handleSelectedStatementChange: (row: IExecutedStatementsResponse | null) => void;
  handleSelectedTotalChange: (row: IExecutedQueryTotalsResponse) => void;
  handleSelectedEventChange: (row: IExecutedQueryTraceEventsResponse) => void;
  handleSelectedEventStatementChange: (row: IExecutedQueryTraceEventsResponse) => void;
  handleTotalsEventsBackClick: () => void;
  handleTotalsEventsClick: (row: IExecutedQueryTotalsResponse) => void;
  handleStatementsEventsBackClick: () => void;
  handleStatementsEventsClick: (row: IExecutedStatementsResponse) => void;
  handleSearchTotals: (keyword: ISearchParams) => void;
  handleTotalsFilter: (keyword: ITotalsParams) => void;
  handleTraceEventFilter: (keyword: ITraceEventParams) => void;
  setCachePlanId(cachePlanId: number | null | undefined): void;
}

export interface ITopSqlState extends ISearchParams, ITotalsParams, ITraceEventParams {
  cachePlanId: number | null | undefined;
  chartFilter: IDateRange | null;
  eventSourceConnectionId: number;
  isTotals: boolean;
  isTraceStatement: boolean;
  selectedEvent: ISelectedTraceEvent | null;
  selectedStatement: ISelectedStatement | null;
  selectedStatementEvent: ISelectedTraceEvent | null;
  selectedTotal: ISelectedTotal | null;
  statementsTraceEventCriteria: IStatementsTraceEventCriteria | null;
  totalsTraceEventCriteria: ITotalsTraceEventCriteria | null;
  totalsGridPageInfo: IGridPageInfo;
  traceEventGridPageInfo: IGridPageInfo;
  statementGridPageInfo: IGridPageInfo;
  statementResults: IUseExecutedStatementsResult;
  statementsTraceEventsResults: IExecutedQueryTraceEventsResults;
  totalsResults: IUseExecutedQueryTotalsResults;
  totalsTraceEventsResults: IExecutedQueryTraceEventsResults;
}

export const CHANGE_CHART_FILTER = "CHANGE_CHART_FILTER";
export const CHANGE_TOTALS_GRID_PAGE = "CHANGE_TOTALS_GRID_PAGE";
export const CHANGE_TRACE_EVENT_GRID_PAGE = "CHANGE_TRACE_EVENT_GRID_PAGE";
export const CHANGE_STATEMENT_GRID_PAGE = "CHANGE_STATEMENT_GRID_PAGE";
export const CHANGE_SELECTED_STATEMENT = "CHANGE_SELECTED_STATEMENT";
export const CHANGE_SELECTED_TOTAL = "CHANGE_SELECTED_TOTAL";
export const CHANGE_SELECTED_EVENT = "CHANGE_SELECTED_EVENT";
export const CHANGE_SELECTED_STATEMENT_EVENT = "CHANGE_SELECTED_STATEMENT_EVENT";
export const SET_CACHE_PLAN_ID = "SET_CACHE_PLAN_ID";
export const SET_IS_TOTALS = "SET_IS_TOTALS";
export const SET_STATEMENT_RESULTS = "SET_STATEMENT_RESULTS";
export const SET_STATEMENT_TRACE_EVENT_RESULTS = "SET_STATEMENT_TRACE_EVENT_RESULTS";
export const SET_TOTALS_RESULTS = "SET_TOTALS_RESULTS";
export const SET_TOTALS_TRACE_EVENT_RESULTS = "SET_TOTALS_TRACE_EVENT_RESULTS";
export const STATEMENT_EVENT_BACK_CLICK = "STATEMENT_EVENT_BACK_CLICK";
export const STATEMENT_EVENT_CLICK = "STATEMENT_EVENT_CLICK";
export const TOTALS_EVENT_BACK_CLICK = "TOTALS_EVENT_BACK_CLICK";
export const TOTALS_EVENT_CLICK = "TOTALS_EVENT_CLICK";
export const SEARCH_TOTALS = "SEARCH_TOTALS";
export const TOTALS_FILTER = "TOTALS_FILTER";
export const TRACE_EVENT_FILTER = "TRACE_EVENT_FILTER";
export const ON_PAGE = "ON_PAGE" as const;
export const ON_SORT = "ON_SORT";
export const ON_FILTER = "ON_FILTER";
export const ON_SEARCH = "ON_SEARCH";
export const ON_DATE_CHANGED = "ON_DATE_CHANGED";
export const ON_SUCCESS = "ON_SUCCESS";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const DEFAULT_PAGE = "DEFAULT_PAGE";
interface IChangeChartFilterAction {
  type: typeof CHANGE_CHART_FILTER;
  payload: IDateRange | null;
}

interface IChangeMainGridPageAction {
  type: typeof CHANGE_TOTALS_GRID_PAGE | typeof CHANGE_TRACE_EVENT_GRID_PAGE;
  payload: IGridPageInfo;
}

interface IChangeStatementGridPageAction {
  type: typeof CHANGE_STATEMENT_GRID_PAGE;
  payload: IGridPageInfo;
}

interface IChangeSelectedStatementAction {
  type: typeof CHANGE_SELECTED_STATEMENT;
  payload: IExecutedStatementsResponse | null;
}

interface IChangeSelectedTotalAction {
  type: typeof CHANGE_SELECTED_TOTAL;
  payload: IExecutedQueryTotalsResponse & { deviceId: number };
}

interface IChangeSelectedEventAction {
  type: typeof CHANGE_SELECTED_EVENT;
  payload: IExecutedQueryTraceEventsResponse;
}

interface IChangeSelectedStatementEventAction {
  type: typeof CHANGE_SELECTED_STATEMENT_EVENT;
  payload: IExecutedQueryTraceEventsResponse;
}

interface ISetCachePlan {
  payload: ITopSqlContext["cachePlanId"];
  type: typeof SET_CACHE_PLAN_ID;
}

interface ISetIsTotalsAction {
  type: typeof SET_IS_TOTALS;
}

interface ISetStatementResultsAction {
  type: typeof SET_STATEMENT_RESULTS;
  payload: IUseExecutedStatementsResult;
}

interface ISetStatementTraceEventResultsAction {
  type: typeof SET_STATEMENT_TRACE_EVENT_RESULTS;
  payload: IExecutedQueryTraceEventsResults;
}

interface ISetTotalsResultsAction {
  type: typeof SET_TOTALS_RESULTS;
  payload: {
    results: IUseExecutedQueryTotalsResults;
    deviceId: number;
  };
}

interface ISetTotalsTraceEventResultsAction {
  type: typeof SET_TOTALS_TRACE_EVENT_RESULTS;
  payload: IExecutedQueryTraceEventsResults;
}

interface IStatementEventBackClickAction {
  type: typeof STATEMENT_EVENT_BACK_CLICK;
}

interface IStatementEventClickAction {
  type: typeof STATEMENT_EVENT_CLICK;
  payload: IExecutedStatementsResponse;
}

interface ITotalsEventBackClickAction {
  type: typeof TOTALS_EVENT_BACK_CLICK;
}

interface ITotalsEventClickAction {
  type: typeof TOTALS_EVENT_CLICK;
  payload: IExecutedQueryTotalsResponse;
}

interface ISearchTotals {
  type: typeof SEARCH_TOTALS;
  payload: {
    searchKey: string | null;
  };
}

interface ITotalsFilter {
  type: typeof TOTALS_FILTER;
  payload: {
    totalsDatabase: string[] | null;
  };
}

interface ITraceEventFilter {
  type: typeof TRACE_EVENT_FILTER;
  payload: {
    eventClass?: number[] | null;
    hostName?: string[] | null;
    applicationName?: string[] | null;
    databaseName?: string[] | null;
    loginName?: string[] | null;
    spid?: number[] | null;
    errorKeyword?: number[] | null;
  };
}

export type TopSqlActionTypes =
  | IChangeChartFilterAction
  | IChangeMainGridPageAction
  | IChangeStatementGridPageAction
  | IChangeSelectedStatementAction
  | IChangeSelectedTotalAction
  | IChangeSelectedEventAction
  | IChangeSelectedStatementEventAction
  | ISetCachePlan
  | ISetIsTotalsAction
  | ISetStatementResultsAction
  | ISetStatementTraceEventResultsAction
  | ISetTotalsResultsAction
  | ISetTotalsTraceEventResultsAction
  | IStatementEventBackClickAction
  | IStatementEventClickAction
  | ITotalsEventBackClickAction
  | ITotalsEventClickAction
  | ISearchTotals
  | ITotalsFilter
  | ITraceEventFilter;
