import { IDateRange } from "../../components/DateContext";
import { defaults } from "../../components/ThemeProvider/grids";
import { IDataResults, IGridPageInfo, ITopSqlContentState } from "./types";

export const totalsGridPageInfoDefault: IGridPageInfo = { pageIndex: 0, pageSize: defaults.totalsGridRowsPerPage };
export const traceEventGridPageInfoDefault: IGridPageInfo = {
  pageIndex: 0,
  pageSize: defaults.traceEventGridRowsPerPage,
};
export const statementGridPageInfoDefault: IGridPageInfo = {
  pageIndex: 0,
  pageSize: defaults.statementsGridRowsPerPage,
};
export const chartFilterDefault: IDateRange | null = null;
export const dataInfoDefault: IDataResults = {
  data: [],
  error: undefined,
  isLoading: true,
  pageLoading: false,
  totalCount: 0,
};
export const selectElementsDefault: ITopSqlContentState = {
  isTotals: true,
  isTraceStatement: true,
  selectedEvent: null,
  selectedStatement: null,
  selectedStatementEvent: null,
  selectedTotal: null,
  statementsTraceEventCriteria: null,
  totalsTraceEventCriteria: null,
};
