import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { useIntl } from "react-intl";
import { useUID } from "react-uid";
import ErrorBoundary from "../../../../components/ErrorBoundary";
import { ITranslation } from "../../../../intl/types";
import { IPerformanceAnalysisChartProps } from "../../types";

export interface IChartCardProps {
  chartComponent: React.ComponentType<IPerformanceAnalysisChartProps>;
  className?: string;
  title: keyof ITranslation;
  vertical?: boolean;
}

const useStyles = makeStyles({
  card: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "min-content 1fr",
    overflow: "visible",
  },
  cardContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  cardContentVertical: {
    "&$cardContent": {
      flexDirection: "column",
    },
  },
  chart: {
    flex: 1,
  },
});

const ChartCard: React.FC<IChartCardProps> = ({ chartComponent: Chart, className, title, vertical = false }) => {
  const classes = useStyles();
  const intl = useIntl();
  const titleId = `performanceAnalysis-card-${useUID()}`;
  return (
    <Card aria-labelledby={titleId} className={classNames(classes.card, className)} elevation={0}>
      <CardHeader title={intl.formatMessage({ id: title })} titleTypographyProps={{ id: titleId }} />
      <CardContent className={classNames(classes.cardContent, { [classes.cardContentVertical]: vertical })}>
        <ErrorBoundary variant="chart">
          <Chart className={classes.chart} />
        </ErrorBoundary>
      </CardContent>
    </Card>
  );
};

export default ChartCard;
