import WarningIcon from "@material-ui/icons/Error";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { MuiTreeTable, TreeToggleColumn } from "@sentryone/material-ui";
import { Column } from "@sentryone/react-datatable";
import * as React from "react";
import { IntlShape, useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import AutoExpandTreeTableRows from "../../../../components/AutoExpandTreeTableRows";
import { DigitalStorageColumn, NumericColumn } from "../../../../components/DataTable";
import SearchTextField from "../../../../components/SearchTextField";
import { useStorageContext } from "../../../../contexts/storageContext";
import StorageEntityMap from "../../StorageEntityMap";
import { IStorageEntity, IWarningResponse } from "../../types";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  searchInput: {
    margin: theme.spacing(2),
  },
  selectedTableRow: {
    "& a": {
      color: theme.palette.common.black,
    },
  },
  table: {
    "& div:first-of-type": {
      overflow: "auto",
    },
    "& div:last-of-type": {
      display: "none",
    },
    "& thead": {
      position: "sticky",
      top: 0,
      zIndex: theme.zIndex.appBar,
    },
    overflowY: "hidden",
  },
  tableRow: {
    "& a": {
      color: theme.palette.primary.main,
    },
  },
}));

interface IStorageEntityTreeItem {
  id: string;
  name: string;
  warning: IWarningResponse[] | null | undefined;
  type: string | null;
  size: number | null;
  href: string | null;
  usedPercent: number | null;
  children: Array<IStorageEntityTreeItem>;
}

function transformDataToTreeItems(
  rawData: StorageEntityMap,
  filter: string,
  getUrlForEntity: (entityId: string) => string,
  intl: IntlShape,
): Array<IStorageEntityTreeItem> {
  const entityHierarchyMap = new Map<string, IStorageEntityTreeItem>();

  const getChildren = (rawEntities: Array<IStorageEntity>): Array<IStorageEntityTreeItem> => {
    return rawEntities
      .map((rawEntity) => entityHierarchyMap.get(rawEntity.id))
      .filter((rawEntity): rawEntity is IStorageEntityTreeItem => Boolean(rawEntity));
  };

  // verifies the entity either has children or matches the filter before adding to map
  const entityIsValid = (entity: IStorageEntityTreeItem): boolean => {
    return entity.children.length > 0 || !filter || entity.name.toLowerCase().includes(filter.toLowerCase());
  };

  rawData.files.forEach((file) => {
    const splitFileName = file.name.split("\\");

    const newFile: IStorageEntityTreeItem = {
      children: [],
      href: getUrlForEntity(file.id),
      id: file.id,
      name: splitFileName[splitFileName.length - 1],
      size: file.sizeBytes,
      type: file.type,
      usedPercent: file.usedSizeBytes ? Math.max(0, Math.min(1, file.usedSizeBytes / file.sizeBytes)) : null,
      warning: file.warnings,
    };

    if (entityIsValid(newFile)) {
      entityHierarchyMap.set(newFile.id, newFile);
    }
  });
  rawData.volumes.forEach((volume) => {
    const filesInVolume = rawData.files.filter((file) => file.logicalVolume.id === volume.id);
    const otherSpaceRow: IStorageEntityTreeItem = {
      children: [],
      href: null,
      id: `${volume.id}-other`,
      name: intl.formatMessage({ id: "other" }),
      size: volume.otherSpaceBytes,
      type: null,
      usedPercent: null,
      warning: null,
    };
    const freeSpaceRow: IStorageEntityTreeItem = {
      children: [],
      href: null,
      id: `${volume.id}-free`,
      name: intl.formatMessage({ id: "freeSpace" }),
      size: volume.freeSpaceBytes,
      type: null,
      usedPercent: null,
      warning: null,
    };

    const volumeDetailChildren: Array<IStorageEntityTreeItem> = [freeSpaceRow, otherSpaceRow].filter((e) =>
      entityIsValid(e),
    );

    const newVolume: IStorageEntityTreeItem = {
      children: [...volumeDetailChildren, ...getChildren(filesInVolume)],
      href: getUrlForEntity(volume.id),
      id: volume.id,
      name: volume.friendlyName,
      size: volume.sizeBytes,
      type: "Volume",
      usedPercent: Math.max(0, Math.min(1, 1 - volume.freeSpaceBytes / volume.sizeBytes)),
      warning: volume.warnings,
    };

    if (entityIsValid(newVolume)) {
      entityHierarchyMap.set(newVolume.id, newVolume);
    }
  });
  rawData.disks.forEach((disk) => {
    const volumesInDisk = rawData.volumes.filter((volume) => volume.physicalDisks.some((d) => d.id === disk.id));

    const newDisk: IStorageEntityTreeItem = {
      children: getChildren(volumesInDisk),
      href: getUrlForEntity(disk.id),
      id: disk.id,
      name: disk.name,
      size: disk.sizeBytes,
      type: "Disk",
      usedPercent: null,
      warning: disk.warnings,
    };

    if (entityIsValid(newDisk)) {
      entityHierarchyMap.set(newDisk.id, newDisk);
    }
  });
  const controllerTreeItems: Array<IStorageEntityTreeItem | undefined> = rawData.controllers.map((controller) => {
    const disksInController = rawData.disks.filter((disk) => disk.diskController.id === controller.id);

    const newController: IStorageEntityTreeItem = {
      children: getChildren(disksInController),
      href: getUrlForEntity(controller.id),
      id: controller.id,
      name: controller.name,
      size: null,
      type: null,
      usedPercent: null,
      warning: controller.warnings,
    };

    if (entityIsValid(newController)) {
      entityHierarchyMap.set(newController.id, newController);
      return newController;
    }
  });

  return controllerTreeItems.filter((item): item is IStorageEntityTreeItem => Boolean(item));
}

export const sortingWarningsColumn = (a: IStorageEntityTreeItem, b: IStorageEntityTreeItem, property: string): number => {

  if (a.name === 'Free Space') return 1;
  if (b.name === 'Free Space') return 0;
  if (a.name === 'Other') return 1;
  if (b.name === 'Other') return 0;

  switch (property) {
    case "name":
      return a.name.localeCompare(b.name);
    case "warning":
      if (a.warning && b.warning) {
        if (a.warning.length < b.warning.length) return 1;
        else if (a.warning.length > b.warning.length) return -1;
        else return 0;
      }
      else if (a.warning && !b.warning) return -1;
      else if (!a.warning && b.warning) return 1;
      else return 0;
    case "type":
      if (a.type && b.type) {
        if (a.type < b.type) return -1
        else if (a.type > b.type) return 1
      }
      return 0;
    case "usedPercent": {
      if (a.usedPercent && b.usedPercent) {
        if (a.usedPercent < b.usedPercent) return -1
        else if (a.usedPercent > b.usedPercent) return 1
      }
      return 0
    }
    case "size":
      if (a.size && b.size) {
        if (a.size < b.size) return -1
        if (a.size > b.size) return 1
      }
      return 0
    default:
      return 0
  }
}

const StorageEntityTree = (): React.ReactElement => {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [filter, setFilter] = React.useState<string>("");
  const { storageEntityMap: rawData, getUrlForEntity, isEntitySelected } = useStorageContext();

  const data = React.useMemo(() => {
    return transformDataToTreeItems(rawData, filter, getUrlForEntity, intl);
  }, [rawData, filter, getUrlForEntity, intl]);

  return (
    <div className={classes.container}>
      <SearchTextField
        aria-label={intl.formatMessage({ id: "filterMsg" })}
        className={classes.searchInput}
        hiddenLabel
        onChange={(e) => setFilter(e.target.value)}
        placeholder={intl.formatMessage({ id: "filterMsg" })}
        value={filter}
      />
      <MuiTreeTable
        childDataSelector={(d) => d.children}
        data={data}
        defaultPageSize={150}
        disablePaging
        keySelector={(d) => d.id}
        tableProps={{ className: classes.table }}
        tableRowProps={(d) => ({
          className: isEntitySelected(d?.id) ? classes.selectedTableRow : classes.tableRow,
          onClick: () => (d && d.href ? history.push(d.href) : null),
          style: { backgroundColor: isEntitySelected(d?.id) ? theme.palette.primary.light : undefined },
        })}
      >
        <AutoExpandTreeTableRows<IStorageEntityTreeItem> equalIf={(a, b) => a.id === b.id} tableData={data} />
        <TreeToggleColumn<IStorageEntityTreeItem>
          buttonTitle={(d, open) =>
            open
              ? intl.formatMessage({ id: "collapseItem" }, { item: d.name })
              : intl.formatMessage({ id: "expandItem" }, { item: d.name })
          }
          field="name"
          header={intl.formatMessage({ id: "name" })}
          id="name"
          renderCell={(d) => (d.href ? <Link to={d.href}>{d.name}</Link> : d.name)}
          sort={(a, b) => sortingWarningsColumn(a, b, "name")}
        />
        <Column<IStorageEntityTreeItem>
          header={<WarningIcon data-testid="warning-icon" fontSize="small" />}
          id="warning"
          key="entity-warning"
          renderCell={(d) => (d.warning) ? <WarningIcon aria-label={intl.formatMessage({ id: "warningIcon" })} color="error" fontSize="small" /> : null}
          sort={(a, b) => sortingWarningsColumn(a, b, "warning")}
        />
        <Column<IStorageEntityTreeItem>
          field="type"
          header={intl.formatMessage({ id: "type" })}
          id="type"
          key="entity-type"
          sort={(a, b) => sortingWarningsColumn(a, b, "type")}
        />
        <NumericColumn<IStorageEntityTreeItem>
          field="usedPercent"
          format={{ style: "percent" }}
          header={intl.formatMessage({ id: "usedPercentage" })}
          sort={(a, b) => sortingWarningsColumn(a, b, "usedPercent")}
        />
        <DigitalStorageColumn<IStorageEntityTreeItem>
          field="size"
          header={intl.formatMessage({ id: "size" })}
          key="entity-size"
          sort={(a, b) => sortingWarningsColumn(a, b, "size")}
        />
      </MuiTreeTable>
    </div>
  );
};

export default StorageEntityTree;
