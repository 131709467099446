import { IntlShape } from "react-intl";
import { ForecastDateFormatOptions } from "../../types";

function isDateCSharpDateTimeMaxValue(date: Date): boolean {
  const maxDate = cSharpDateTimeMaxValue;
  const dateAsDateObject = new Date(date);
  return dateAsDateObject.getTime() === maxDate.getTime();
}

export function formatForecastExhaustionDate(forecastedExhaustionDate: Date, intlObject: IntlShape): string {
  return isDateCSharpDateTimeMaxValue(forecastedExhaustionDate)
    ? intlObject.formatMessage({ id: "none" })
    : intlObject.formatDate(forecastedExhaustionDate, ForecastDateFormatOptions);
}

export const cSharpDateTimeMaxValue : Date = new Date("9999-12-31T00:00:00");
