import { DateTime } from "luxon";
import BaseService from "../BaseService";
import { IPageResponse } from "../BaseService";
import ITimeSeriesCriteria from "../models/ITimeSeriesCriteria";
import {
  IPerformanceAnalysisTimeSeriesCriteria,
  IPerformanceAnalysisTimeSeriesGroup,
} from "../models/PerformanceAnalysisTimeSeries";
import { IQueriesTimeSeriesCriteria, IQueriesTimeSeriesResponse } from "../models/QueryTimeSeries";
import { IResourceTimeSeriesPoint, IResourceTimeSeriesResponse } from "../models/ResourceTimeSeries";
import sqlTraceEvents from "../sqlTraceEvents";

import { IExecutedQueryTotalsCriteria, IExecutedQueryTotalsResponse } from "../models/ExecutedQueryTotals";
import { IExecutedStatementsCriteria, IExecutedStatementsResponse } from "../models/ExecutedStatements";
import {
  IExecutedQueryTraceEventsCriteria,
  IExecutedQueryTraceEventsResponse,
} from "../models/IExecutedQueryTraceEventsCriteria";
import { IQueryHistoryCriteria, IQueryHistoryPoints } from "../models/QueryHistory";
import { IPlanData, IPlanStatementsCriteria } from "./models/PlanData";

function fillMissingResourcePoints(data: IResourceTimeSeriesResponse): IResourceTimeSeriesResponse {
  // Max number of points to fill. Any gaps larger than this will be left untouched
  const maxMissingPoints = 1;

  const filledPoints = data.points.reduce<IResourceTimeSeriesPoint[]>((prev, curr) => {
    // First item requires no action
    if (prev.length > 0) {
      const lastPoint = prev[prev.length - 1];
      const lastDate = DateTime.fromJSDate(lastPoint.startMinute);
      const currentDate = DateTime.fromJSDate(curr.startMinute);
      const diff = currentDate.diff(lastDate, "minute").minutes;

      const missingPointCount = Math.floor((diff - data.rollupLevelMinutes) / data.rollupLevelMinutes);
      if (missingPointCount > 0 && missingPointCount <= maxMissingPoints) {
        for (let i = 1; i <= missingPointCount; i++) {
          const timestamp = lastDate.plus({ minutes: i * data.rollupLevelMinutes }).toJSDate();
          const stepFactor = i / (missingPointCount + 1);

          const fakePoint: IResourceTimeSeriesPoint = {
            avgDuration:
              (lastPoint.avgDuration ?? 0) + ((curr.avgDuration ?? 0) - (lastPoint.avgDuration ?? 0)) * stepFactor,
            cpu: (lastPoint.cpu ?? 0) + ((curr.cpu ?? 0) - (lastPoint.cpu ?? 0)) * stepFactor,
            execCount: (lastPoint.execCount ?? 0) + ((curr.execCount ?? 0) - (lastPoint.execCount ?? 0)) * stepFactor,
            logicalReads:
              (lastPoint.logicalReads ?? 0) + ((curr.logicalReads ?? 0) - (lastPoint.logicalReads ?? 0)) * stepFactor,
            logicalWrites:
              (lastPoint.logicalWrites ?? 0) +
              ((curr.logicalWrites ?? 0) - (lastPoint.logicalWrites ?? 0)) * stepFactor,
            physicalReads:
              (lastPoint.physicalReads ?? 0) +
              ((curr.physicalReads ?? 0) - (lastPoint.physicalReads ?? 0)) * stepFactor,
            startMinute: timestamp,
          };
          prev.push(fakePoint);
        }
      }
    }
    prev.push(curr);
    return prev;
  }, []);

  if (filledPoints.length === data.points.length) {
    return data;
  } else {
    return {
      ...data,
      points: filledPoints,
    };
  }
}

export interface ITopSqlService {
  fetchExecutedQueryTotals(criteria: ITimeSeriesCriteria): Promise<IPageResponse<IExecutedQueryTotalsResponse>>;
  fetchExecutedStatements(criteria: IExecutedStatementsCriteria): Promise<IExecutedStatementsResponse[]>;
  fetchQueriesTimeSeries(
    criteria: IQueriesTimeSeriesCriteria,
    signal?: AbortSignal,
  ): Promise<IQueriesTimeSeriesResponse[]>;
  fetchResourceTimeSeries(criteria: ITimeSeriesCriteria): Promise<IResourceTimeSeriesResponse>;
  fetchExecutedQueriesTraceEvents(
    criteria: IExecutedQueryTraceEventsCriteria,
  ): Promise<IPageResponse<IExecutedQueryTraceEventsResponse>>;
  fetchTracePlans(criteria: IPlanStatementsCriteria): Promise<IPlanData>;
  fetchQueryHistory(criteria: IQueryHistoryCriteria): Promise<IQueryHistoryPoints>;
}

export default class TopSqlService extends BaseService implements ITopSqlService {
  constructor() {
    super("/api/topSql");
  }

  public async fetchExecutedStatements(
    criteria: IExecutedStatementsCriteria,
    controller?: AbortController,
  ): Promise<IExecutedStatementsResponse[]> {
    const results = await this.get<IExecutedStatementsResponse[]>("executedStatements", {
      query: {
        ...this.getBaseTimeSeries(criteria),
        databaseId: criteria.databaseId?.toString(),
        parentId: criteria.parentId?.toString(),
        textMd5: criteria.textMd5,
      },
      signal: controller?.signal,
    });

    return results.map((x) => ({
      ...x,
      // id doesn't come back from the API, so make one up for uniqueness in the UI
      id: Math.random(),
    }));
  }

  public async fetchExecutedQueriesTraceEvents(
    criteria: IExecutedQueryTraceEventsCriteria,
    controller?: AbortController,
  ): Promise<IPageResponse<IExecutedQueryTraceEventsResponse>> {
    const results = await this.get<any>("queryTraceEvents", {
      query: {
        ...this.getBaseTimeSeries(criteria),
        applicationNames: criteria?.applicationName ?? [],
        databaseId: criteria.databaseId?.toString(),
        databaseNames: criteria?.databaseName ?? [],
        errors: criteria?.errorKeyword?.map(String) ?? [],
        eventClasses: criteria?.eventClass?.map(String) ?? [],
        hostNames: criteria?.hostName ?? [],
        isTraceStatement: criteria.isTraceStatement.toString(),
        limit: criteria.limit.toString(),
        loginNames: criteria.loginName ?? [],
        normalizedTextMd5: criteria.textMd5 || undefined,
        offset: criteria.offset.toString(),
        parentId: criteria.parentId?.toString(),
        parentTextMd5: criteria.parentTextMd5 || undefined,
        sPIDs: criteria.spid?.map(String) ?? [],
        searchKey: criteria?.searchKeyword ?? "",
        sortIsDescending: criteria.sortIsDescending.toString(),
        sortProperty: criteria.sortProperty,
      },
      signal: controller?.signal,
    });

    return {
      ...results,
      items: results.items.map((x: any) => ({
        ...x,
        endTime: new Date(x.endTime),
        eventClass: sqlTraceEvents[x.eventClass as keyof typeof sqlTraceEvents] || "RequestCompleted",
        // id doesn't come back from the API, so make one up for uniqueness in the UI
        id: Math.random(),
        startTime: new Date(x.startTime),
      })),
    };
  }

  public async fetchExecutedQueryTotals(
    criteria: IExecutedQueryTotalsCriteria,
    controller?: AbortController,
  ): Promise<IPageResponse<IExecutedQueryTotalsResponse>> {
    const results = await this.get<IPageResponse<IExecutedQueryTotalsResponse>>("executedQueryTotals", {
      query: {
        ...this.getBaseTimeSeries(criteria),
        databaseNamesFilter: criteria?.databaseName ?? [],
        limit: criteria.limit.toString(),
        offset: criteria.offset.toString(),
        searchKey: criteria?.searchKeyword ?? "",
        sortIsDescending: criteria.sortIsDescending.toString(),
        sortProperty: criteria.sortProperty,
      },
      signal: controller?.signal,
    });
    return {
      ...results,
      items: results.items.map((x) => ({
        ...x,
        // id doesn't come back from the API, so make one up for uniqueness in the UI
        id: Math.random(),
      })),
    };
  }

  public async fetchResourceTimeSeries(
    criteria: ITimeSeriesCriteria,
    signal?: AbortSignal,
  ): Promise<IResourceTimeSeriesResponse> {
    const results = await this.get<any>("resourceTimeSeries", {
      query: this.getBaseTimeSeries(criteria),
      signal,
    });

    return fillMissingResourcePoints({
      ...results,
      points: results.points.map((y: any) => ({
        ...y,
        startMinute: new Date(y.startMinute),
      })),
    });
  }

  public async fetchQueriesTimeSeries(
    criteria: IQueriesTimeSeriesCriteria,
    signal?: AbortSignal,
  ): Promise<IQueriesTimeSeriesResponse[]> {
    const results = await this.get<any[]>("queriesTimeSeries", {
      query: {
        aggregateBy: criteria.aggregateBy,
        ...this.getBaseTimeSeries(criteria),
      },
      signal,
    });

    return results.map((x) => ({
      ...x,
      points: x.points.map((y: any) => ({
        ...y,
        startMinute: new Date(y.startMinute),
        textData: x.textData,
      })),
    }));
  }

  public async fetchPerformanceAnalysisTimeSeries(
    criteria: IPerformanceAnalysisTimeSeriesCriteria,
    signal?: AbortSignal,
  ): Promise<IPerformanceAnalysisTimeSeriesGroup[]> {
    const results = await this.get<any[]>("performanceAnalysisTimeSeries", {
      query: {
        aggregateBy: criteria.aggregateBy,
        endDate: criteria.endDate.toISOString(),
        eventSourceConnectionId: criteria.eventSourceConnectionId.toString(),
        groupType: criteria.groupType,
        startDate: criteria.startDate.toISOString(),
      },
      signal,
    });

    return results.map((group) => ({
      ...group,
      points: group.timeSeries.map((point: any) => ({
        ...point,
        startMinute: new Date(point.startMinute),
      })),
    }));
  }

  public async fetchQueryHistory(criteria: IQueryHistoryCriteria, signal?: AbortSignal): Promise<IQueryHistoryPoints> {
    const results = await this.get<any>("queryProcedureHistory", {
      query: {
        databaseName: criteria.databaseName,
        deviceId: criteria.deviceId.toString(),
        endDateTimeUtc: criteria.endDateTimeUtc.toISOString(),
        eventSourceConnectionId: criteria.eventSourceConnectionId.toString(),
        isAverage: criteria.isAverage.toString(),
        objectNameHash: criteria.objectNameHash,
        parentTextMd5: criteria.parentTextMd5,
        queryType: criteria.queryType,
        startDateTimeUtc: criteria.startDateTimeUtc.toISOString(),
        textMd5String: criteria.textMd5String,
      },
      signal,
    });

    return {
      ...results,
      procedureChartPoints: results.procedureChartPoints.map((y: any) => ({
        ...y,
        endTimeUtc: new Date(y.endTimeUtc),
        startTimeUtc: new Date(y.startTimeUtc),
      })),
      traceDataChartPoints: results.traceDataChartPoints.map((y: any) => ({
        ...y,
        normalizedEndTime: new Date(y.normalizedEndTime),
        normalizedStartTime: new Date(y.normalizedStartTime),
      })),
    };
  }

  public async fetchTracePlans(criteria: IPlanStatementsCriteria, controller?: AbortController): Promise<IPlanData> {
    const results = await this.get<IPlanData>("planDiagramData", {
      query: {
        eventSourceConnectionId: criteria.eventSourceConnectionId.toString(),
        planId: criteria.planId.toString(),
      },
      signal: controller?.signal,
    });

    return results;
  }

  private getBaseTimeSeries(criteria: ITimeSeriesCriteria): { [key: string]: string } {
    return {
      endDate: criteria.endDate.toISOString(),
      eventSourceConnectionId: criteria.eventSourceConnectionId.toString(),
      startDate: criteria.startDate.toISOString(),
    };
  }
}

/**
 * Fills gap data in an IResourceTimeSeriesResponse by averaging the difference between neighboring points.
 */
