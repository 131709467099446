import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Collapse from "@material-ui/core/Collapse";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useUID } from "react-uid";
import { IDateRange } from "../../../../components/DateContext";
import ErrorBoundary from "../../../../components/ErrorBoundary";
import MetricChart, {
  IMetricChartSeriesOptions,
  LegendProvider,
  useLegend,
  useMetrics,
} from "../../../../components/MetricChart";
import { ITopologyItemEventSourceConnection } from "../../../../components/TopologyContext";
import { makeByOptions } from "./by";
import ChartSelector from "./components/ChartSelector";
import TopSqlChartCardMenu from "./components/TopSqlChartCardMenu";
import { makeQueriesOptions } from "./queries";
import { resourcesMetrics } from "./resources";
import { IChartSelection, PerformanceAnalysisAggregateType, QueryAggregateType, TopSqlChartType } from "./types";
import { makeWaitsOptions } from "./waits";

export interface ITopSqlChartProps {
  className?: string;
  currentFilter: Readonly<IDateRange> | null;
  onSeriesClick: (filter: IDateRange) => void;
  target: ITopologyItemEventSourceConnection;
  style?: Readonly<React.CSSProperties>;
  visibleDates: Readonly<IDateRange>;
}

export type ResourceSeriesType =
  | "cpu"
  | "avgDuration"
  | "execCount"
  | "logicalReads"
  | "logicalWrites"
  | "physicalReads";

const useStyles = makeStyles((theme) => ({
  chart: {
    gridArea: "chart",
  },
  container: {
    display: "grid",
    gridGap: theme.spacing(),
    gridTemplateAreas: `"chart menu"`,
    gridTemplateColumns: "1fr 100px",
    gridTemplateRows: "min-content",
    padding: theme.spacing(),
  },
  menu: {
    gridArea: "menu",
  },
  title: {
    cursor: "pointer",
  },
}));

function getMetrics(theme: Theme, selection: IChartSelection): readonly IMetricChartSeriesOptions[] {
  const colors = theme.palette.getGenericColorGenerator();
  switch (selection.chart) {
    case TopSqlChartType.ByApp:
    case TopSqlChartType.ByDatabase:
    case TopSqlChartType.ByHost:
    case TopSqlChartType.ByLogin:
      return [makeByOptions(selection.chart, selection.subMenu, colors)];
    case TopSqlChartType.Queries:
      return [makeQueriesOptions(selection.subMenu, colors)];
    case TopSqlChartType.Resources:
      return resourcesMetrics;
    case TopSqlChartType.Waits:
      return [makeWaitsOptions()];
  }
}

const TopSqlChart: React.FunctionComponent<ITopSqlChartProps> = ({
  className,
  onSeriesClick,
  style,
  target,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();
  const collapseId = `chart-collapse-${useUID()}`;
  const [collapse, setCollapse] = React.useState(false);
  const [selection, setSelection] = React.useState<IChartSelection>({
    chart: TopSqlChartType.Waits,
    subMenu: null,
  });
  const [showAxisLabels, setShowAxisLabels] = React.useState(true);
  const [selectedDateRange, setSelectedDateRange] = React.useState<IDateRange | null>(props.currentFilter);

  const legend = useLegend();
  const metrics = getMetrics(theme, selection);
  const metricData = useMetrics({
    dateRange: props.visibleDates,
    metrics,
    target,
  });
  const resetLegendsRef = React.useRef(false);
  React.useEffect(() => {
    if (metricData.isLoading || !resetLegendsRef.current) {
      // Skip because data is still loading
      return;
    } else if (selection.chart === TopSqlChartType.Resources) {
      const hide = [
        "sqlserver.topsql.resources.logicalWrites",
        "sqlserver.topsql.resources.executions",
        "sqlserver.topsql.resources.physicalReads",
      ];
      legend.setHiddenSeries(metricData.series.filter((x) => hide.includes(x.metric)));
    } else {
      legend.setHiddenSeries([]);
    }
    resetLegendsRef.current = false;
  });

  function handleChartChanged(
    chart: TopSqlChartType,
    subMenu?: QueryAggregateType | PerformanceAnalysisAggregateType | null,
  ): void {
    resetLegendsRef.current = true;
    setSelection({
      chart,
      subMenu: subMenu ?? null,
    } as any);
  }

  // Clears the selected area of the chart on filter clear
  React.useEffect(() => {
    setSelectedDateRange(props.currentFilter);
  }, [props.currentFilter]);

  return (
    <LegendProvider {...legend}>
      <Card className={classNames(className, classes)} data-testid="topSqlChart">
        <CardHeader
          action={
            <TopSqlChartCardMenu
              chart={selection.chart}
              onReset={() => setShowAxisLabels(true)}
              onToggleAxisLabels={() => setShowAxisLabels((prev) => !prev)}
              series={metricData.series}
              showAxisLabels={showAxisLabels}
            />
          }
          title={
            <div
              aria-controls={collapseId}
              aria-expanded={!collapse}
              className={classes.title}
              data-testid="topSqlChartTitle"
              onClick={() => setCollapse((prev) => !prev)}
            >
              <FormattedMessage id={selection.chart} />
              {selection.subMenu && " - "}
              {selection.subMenu && <FormattedMessage id={selection.subMenu} />}
            </div>
          }
        />
        <Collapse in={!collapse}>
          <CardContent className={classes.container} id={collapseId}>
            <ErrorBoundary fallbackClassName={classes.chart} key={selection.chart} variant="chart">
              <MetricChart
                backgroundColor="#f2f2f2"
                className={classes.chart}
                contextMenuItems={[
                  <MenuItem
                    key="filter"
                    onClick={() => {
                      if (selectedDateRange) onSeriesClick(selectedDateRange);
                    }}
                  >
                    {intl.formatMessage({ id: "filter" })}
                  </MenuItem>,
                ]}
                dateRange={props.visibleDates}
                disableAxisMerge={selection.chart === TopSqlChartType.Resources}
                hideAxisLabels={!showAxisLabels}
                hideLegend
                metricData={metricData}
                onSelectedRangeChange={([from, to]) => setSelectedDateRange(from && to ? { from, to } : null)}
                selectedRange={[selectedDateRange?.from ?? null, selectedDateRange?.to ?? null]}
                target={target}
              />
            </ErrorBoundary>
            <ChartSelector
              chart={selection.chart}
              className={classes.menu}
              onChange={handleChartChanged}
              subMenuSelection={selection.subMenu}
            />
          </CardContent>
        </Collapse>
      </Card>
    </LegendProvider>
  );
};

export default TopSqlChart;
