import * as React from "react";
import { v4 as uuid } from "uuid";
import MetricChart, { IMetricChartSeriesOptions, useMetrics } from "../../../../components/MetricChart";
import { IPerformanceAnalysisChartProps } from "../../types";
import { usePerformanceAnalysisContext } from "../../context";

const freeSpacePatternId = `free-space-pattern-${uuid()}`;
const metrics = Object.freeze<IMetricChartSeriesOptions>([
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "stackedArea",
    color: "#A62DC4",
    instance: null,
    label: "usedSpace",
    metric: "azure.sqldb.size.used",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "stackedArea",
    color: `url(#${freeSpacePatternId})`,
    instance: null,
    label: "freeSpace",
    metric: "azure.sqldb.size.free",
    strokeColor: "#7F7F7F",
  }),
]);

const DatabaseSize: React.FC<IPerformanceAnalysisChartProps> = ({ className }) => {
  const { contextMenuItems, dateRange, selectedRange, setSelectedRange, target } = usePerformanceAnalysisContext();
  const metricData = useMetrics({
    dateRange,
    metrics,
    target,
  });
  return (
    <MetricChart
      className={className}
      contextMenuItems={contextMenuItems}
      dateRange={dateRange}
      metricData={metricData}
      onSelectedRangeChange={setSelectedRange}
      selectedRange={selectedRange}
      target={target}
    >
      <defs>
        <pattern height="6" id={freeSpacePatternId} patternUnits="userSpaceOnUse" width="6" x="0" y="0">
          <circle cx="2" cy="2" fill="#7F7F7F" r="1" stroke="none" />
          <circle cx="5" cy="5" fill="#7F7F7F" r="1" stroke="none" />
        </pattern>
      </defs>
    </MetricChart>
  );
};

export default DatabaseSize;
