import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useTopology } from "../../components/TopologyContext";
import { useCurrentTenant } from "../../hooks/useCurrentTenant";
import { useLocalStorageState } from "../../hooks/useLocalStorageState";
import Advisory from "../Advisory";
import CustomDashboard from "../CustomDashboard";
import DashboardDigest from "../CustomDashboard/components/DashboardDigest";
import HealthOverview from "../EnvironmentHealth/HealthOverview";
import Permissions from "../Permissions";
import BrowserAlert from "./components/BrowserAlert";
import NavBar from "./components/NavBar";
import Sidebar from "./components/Sidebar";
import TargetDialog from "./components/TargetDialog";
import TenantError from "./components/TenantError";
import NoTargets from "./components/NoTargets";
import { getFeatureFromRoute } from "../../components/DateContext";

const useStyles = makeStyles({
  content: {
    gridArea: "content",
    overflowX: "hidden",
    overflowY: "auto",
    position: "relative",
    scrollBehavior: "smooth",
  },
  navbar: {
    gridArea: "navbar",
  },
  root: {
    display: "grid",
    gridTemplateAreas: `
      "navbar navbar"
      "sidebar content"
    `,
    gridTemplateColumns: "min-content 1fr",
    gridTemplateRows: "min-content 1fr",
    height: "100%",
  },
  sidebar: {
    gridArea: "sidebar",
    minWidth: "60px",
    overflow: "hidden",
  },
});

const Layout: React.FC = () => {
  const classes = useStyles();
  const [drawerVisible, setDrawerVisible] = useLocalStorageState<boolean>("navigation-drawer-open", true);
  const location = useLocation();
  const { shouldShowFeature, devices, eventSourceConnections } = useTopology();
  const currentTenant = useCurrentTenant();
  const goodTenant = !!(currentTenant && currentTenant.tenant.state === "ready" && !currentTenant.error);

  const [showAlerts, showDashboards, showPermissions, showNoTargetPage] = React.useMemo(() => {
    return [
      shouldShowFeature("alerts"),
      shouldShowFeature("performance"),
      shouldShowFeature("permissionAdministration"),
      [...devices, ...eventSourceConnections].length === 0 && goodTenant,
    ];
  }, [shouldShowFeature, devices, eventSourceConnections, goodTenant]);

  return (
    <div className={classes.root}>
      <NavBar
        className={classes.navbar}
        drawerVisible={drawerVisible}
        onDrawerToggle={() => setDrawerVisible((prev) => !prev)}
        path={getFeatureFromRoute(window.location.pathname)}
      />
      <BrowserAlert />
      <Route
        path="/:tenant"
        render={({ match }) => (
          <>
            <Sidebar className={classes.sidebar} open={drawerVisible} />
            <div className={classes.content}>
              {!goodTenant && currentTenant && <TenantError currentTenant={currentTenant} />}
              {showNoTargetPage && currentTenant && <NoTargets />}
              {goodTenant && !showNoTargetPage && (
                <Switch>
                  {showDashboards && (
                    <Route
                      path={`${match.url}/dashboards/:dashboardId/edit`}
                      render={({ match }) => <CustomDashboard dashboardId={match.params.dashboardId} editMode />}
                    />
                  )}
                  {showDashboards && (
                    <Route
                      path={`${match.url}/dashboards/:dashboardId`}
                      render={({ match }) => (
                        <CustomDashboard dashboardId={match.params.dashboardId} editMode={false} />
                      )}
                    />
                  )}
                  {showDashboards && (
                    <Route path={`${match.url}/dashboards`}>
                      <DashboardDigest />
                    </Route>
                  )}
                  {showAlerts && (
                    <Route path={`${match.url}/alerts`}>
                      <Advisory />
                    </Route>
                  )}
                  {showPermissions && (
                    <Route path={`${match.url}/permissions`}>
                      <Permissions />
                    </Route>
                  )}
                  <Route
                    path={`${match.url}/health/groups/:objectId`}
                    render={({ match }) => <HealthOverview objectId={match.params.objectId} />}
                  />
                  <Route path={[`${match.url}/health`, `${match.url}/targets/:targetId`]}>
                    {/* Target dialog will be displayed over content if in view */}
                    <HealthOverview />
                  </Route>
                  <Redirect
                    to={{
                      pathname: `${match.url}/health`,
                      search: location.search,
                    }}
                  />
                </Switch>
              )}
            </div>
            <TargetDialog />
          </>
        )}
      />
    </div>
  );
};

export default Layout;
