import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";
import { DayProps } from "@material-ui/pickers/views/Calendar/Day";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { DateTime } from "luxon";
import * as React from "react";
import { useIntl } from "react-intl";
import { Nullable } from "../../utilities/UtilityTypes";
import { IDateRange, useDateContext } from "../DateContext";
import DateRangeTab from "./DateRangeTab";

const useStyles = makeStyles((theme) => ({
  datePicker: {
    // @material-ui/pickers doesn't apply className or styles properly, but we need to center it
    "&>div": {
      alignItems: "center",
    },
  },
  tab: {
    minWidth: theme.spacing(12),
  },
}));

export interface IDateSelectionPopoverProps {
  onClose: () => void;
}

enum DateSelectionTabs {
  Range,
  Days,
  Weeks,
  Months,
}

const DateSelectionPopover: React.FC<IDateSelectionPopoverProps> = ({ onClose }) => {
  const intl = useIntl();
  const { dateRange, setDateRange } = useDateContext();
  const classes = useStyles();
  const [tab, setTab] = React.useState<DateSelectionTabs>(DateSelectionTabs.Range);
  const [value, setValue] = React.useState<Nullable<IDateRange>>(dateRange);

  function renderDay(
    day: MaterialUiPickersDate,
    _selectedDate: MaterialUiPickersDate,
    _dayInCurrentMonth: boolean,
    dayComponent: JSX.Element,
  ): JSX.Element {
    return React.cloneElement<DayProps>(dayComponent, {
      // Select every date in the currently selected range
      selected: !!day && day.toJSDate() >= dateRange.from && day.toJSDate() <= dateRange.to,
    });
  }

  return (
    <>
      <Tabs onChange={(_, v) => setTab(v)} value={tab} variant="fullWidth">
        <Tab className={classes.tab} label={intl.formatMessage({ id: "range" })} value={DateSelectionTabs.Range} />
        <Tab className={classes.tab} label={intl.formatMessage({ id: "days" })} value={DateSelectionTabs.Days} />
        <Tab className={classes.tab} label={intl.formatMessage({ id: "weeks" })} value={DateSelectionTabs.Weeks} />
        <Tab className={classes.tab} label={intl.formatMessage({ id: "months" })} value={DateSelectionTabs.Months} />
      </Tabs>
      {tab === DateSelectionTabs.Range && (
        <DateRangeTab
          onCancel={onClose}
          onChange={setValue}
          onConfirm={(v) => {
            setDateRange(v);
            onClose();
          }}
          value={value}
        />
      )}
      {tab === DateSelectionTabs.Days && (
        <div className={classes.datePicker}>
          <DatePicker
            disableFuture
            disableToolbar
            onChange={(d) => {
              if (d) {
                setDateRange({
                  from: d.startOf("day").toJSDate(),
                  to: DateTime.min(d.endOf("day"), DateTime.local()).toJSDate(),
                });
                onClose();
              }
            }}
            openTo="date"
            orientation="landscape"
            renderDay={renderDay}
            value={null}
            variant="static"
          />
        </div>
      )}
      {tab === DateSelectionTabs.Weeks && (
        <div className={classes.datePicker}>
          <DatePicker
            disableFuture
            disableToolbar
            onChange={(d) => {
              if (d) {
                setDateRange({
                  from: d.startOf("week").toJSDate(),
                  to: DateTime.min(d.endOf("week"), DateTime.local()).toJSDate(),
                });
                onClose();
              }
            }}
            openTo="date"
            orientation="landscape"
            renderDay={renderDay}
            value={null}
            variant="static"
          />
        </div>
      )}
      {tab === DateSelectionTabs.Months && (
        <DatePicker
          disableFuture
          // onChange is called when the year is changed as well, so we don't want it to call onClose
          onChange={(d) => {
            setValue({
              from: d?.startOf("month").toJSDate() ?? null,
              to: d ? DateTime.min(d.endOf("month"), DateTime.local()).toJSDate() : null,
            });
          }}
          // onMonthChange is only called when the month is picked, so update the context and close
          onMonthChange={(d) => {
            if (d) {
              setDateRange({
                from: d.startOf("month").toJSDate(),
                to: DateTime.min(d.endOf("month"), DateTime.local()).toJSDate(),
              });
              onClose();
            }
          }}
          openTo="month"
          orientation="landscape"
          value={value.from}
          variant="static"
          views={["year", "month"]}
        />
      )}
    </>
  );
};

export default DateSelectionPopover;
