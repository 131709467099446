import * as React from "react";
import MetricChart, { IMetricChartSeriesOptions, useMetrics } from "../../../../components/MetricChart";
import { IPerformanceAnalysisChartProps } from "../../types";
import { usePerformanceAnalysisContext } from "../../context";

const metrics = Object.freeze<IMetricChartSeriesOptions>([
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "stackedArea",
    color: "#4169E1",
    instance: null,
    label: "allocatedMemoryUsage",
    metric: "azure.sqldb.dtu.memory.pct",
  }),
]);

const MemoryUsage: React.FC<IPerformanceAnalysisChartProps> = ({ className }) => {
  const { contextMenuItems, dateRange, selectedRange, setSelectedRange, target } = usePerformanceAnalysisContext();
  const metricData = useMetrics({
    dateRange,
    metrics,
    target,
  });
  return (
    <MetricChart
      className={className}
      contextMenuItems={contextMenuItems}
      dateRange={dateRange}
      metricData={metricData}
      onSelectedRangeChange={setSelectedRange}
      selectedRange={selectedRange}
      target={target}
    />
  );
};

export default MemoryUsage;
