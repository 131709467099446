import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import classNames from "classnames";
import { useIntl } from "react-intl";
import TreeView from "@material-ui/lab/TreeView";
import CollapseIcon from "@material-ui/icons/ExpandMore";
import ExpandIcon from "@material-ui/icons/ChevronRight";
import useTopologyRouteMatch from "../../hooks/useTopologyRouteMatch";
import { renderTopologyTreeItem, ITreeItem } from "../TopologyTreeItem";
import { DarkModeDivider } from "../../../SidebarContent/SidebarContent";
import { ITopologyViewProps } from "../../TopologyList";

const useStyles = makeStyles((theme) => ({
  unWatchedSectionBtn: {
    color: "var(--sidebar-dark-text-grey)",
    display: "flex",
    fontWeight: theme.typography.button.fontWeight,
    height: theme.spacing(9),
    justifyContent: "space-between",
    marginTop: "auto",
    padding: theme.spacing(4),
  },
  unWatchedSectionBtnIcon: {
    transition: "transform 100ms",
  },
  unWatchedSectionBtnIconRotate: {
    transform: "rotate(180deg)",
  },
  unWatchedSectionContent: {
    marginBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

const TopologyUnwatchedSection: React.FC<ITopologyViewProps> = ({ topology, filter }) => {
  const classes = useStyles();
  const intl = useIntl();
  const { targetMatch, featureMatch } = useTopologyRouteMatch();
  const [expandUnwatched, setExpandUnwatched] = React.useState<boolean>(false);

  const currentFeature = featureMatch?.params.feature;
  const targetId = targetMatch?.params.targetId;

  const unWatchedEventSourceConnections = React.useMemo<Array<ITreeItem>>(() => {
    return [...topology.eventSourceConnections]
      .filter((esc) => !esc.isWatched && (!filter || esc.name.toLowerCase().includes(filter.toLowerCase())))
      .map((filteredEsc) => ({
        children: null,
        eventSourceConnectionType: filteredEsc.eventSourceConnectionType,
        id: filteredEsc.objectId,
        isSelected: Boolean(targetId === filteredEsc.objectId),
        isWatched: false,
        name: filteredEsc.name,
        to: `/${filteredEsc.tenantId}/targets/${filteredEsc.objectId}/${currentFeature}`,
        type: "esc",
      }));
  }, [topology, currentFeature, targetId, filter]);

  const unWatchedDevices = React.useMemo<Array<ITreeItem>>(() => {
    return [...topology.devices]
      .filter(
        (device) =>
          !device.isWatched &&
          (!filter || device.name.toLowerCase().includes(filter.toLowerCase())) &&
          device.objectId !=
            topology.eventSourceConnections.find((esc) => esc.parentObjectId === device.objectId && esc.isWatched)
              ?.parentObjectId,
      )
      .map((filteredDevice) => ({
        children: null,
        deviceType: filteredDevice.deviceType,
        id: filteredDevice.objectId,
        isSelected: Boolean(targetId === filteredDevice.objectId),
        isWatched: false,
        name: filteredDevice.name,
        to: `/${filteredDevice.tenantId}/targets/${filteredDevice.objectId}/${currentFeature}`,
        type: "device",
      }));
  }, [topology, currentFeature, targetId, filter]);

  const unWatchedTargets: Array<ITreeItem> = [...unWatchedDevices, ...unWatchedEventSourceConnections];

  if (unWatchedTargets.length === 0) {
    return null;
  } else {
    return (
      <Box marginTop="auto">
        <DarkModeDivider />
        <Button
          classes={{
            text: classes.unWatchedSectionBtn,
          }}
          disableRipple
          endIcon={
            <CollapseIcon
              className={classNames(classes.unWatchedSectionBtnIcon, {
                [classes.unWatchedSectionBtnIconRotate]: expandUnwatched,
              })}
            />
          }
          fullWidth
          onClick={() => setExpandUnwatched((prev) => !prev)}
          variant="text"
        >
          {intl.formatMessage({ id: "unwatchedServers" })}
        </Button>
        <Collapse in={expandUnwatched} mountOnEnter unmountOnExit>
          <TreeView
            className={classes.unWatchedSectionContent}
            defaultCollapseIcon={<CollapseIcon />}
            defaultExpandIcon={<ExpandIcon />}
            disableSelection
            selected={[targetMatch?.params.targetId ?? ""]}
          >
            {unWatchedTargets.map((unwatchedTarget) => renderTopologyTreeItem(unwatchedTarget, filter))}
          </TreeView>
        </Collapse>
      </Box>
    );
  }
};

export default TopologyUnwatchedSection;
