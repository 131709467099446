import { useQuery } from "@apollo/react-hooks";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Popper, { PopperProps } from "@material-ui/core/Popper";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import ErrorIcon from "@material-ui/icons/Error";
import Autocomplete, { AutocompleteRenderOptionState } from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";
import classNames from "classnames";
import { ColorPicker } from "material-ui-color";
import * as React from "react";
import { useIntl } from "react-intl";
import { IDateRange } from "../../../../components/DateContext";
import { ChartDisplayType } from "../../../../components/MetricChart";
import { MoRef } from "../../../../utilities/TopologyUtility";
import { ICustomChartConfig, ICustomDashboardChartSeries } from "../../types";
import CustomChartModalTooltip from "./components/CustomChartModalTooltip";
import * as INSTANCES_QUERY from "./customChartInstancesQuery.graphql";
import { IMetric } from "./CustomChartQueries";
import { IPerformanceCounterMetricRowStatus, NoBorderTableCell } from "./PerformanceCounterSelection";
import TargetAutocomplete from "./components/TargetAutocomplete";
import {
  ITopologyItem,
  ITopologyItemDevice,
  ITopologyItemEventSourceConnection,
  useTopology,
} from "../../../../components/TopologyContext";
import * as METRICS_QUERY from "./customChartMetricsQuery.graphql";
import { Button, Checkbox, Chip } from "@material-ui/core";

export interface IPerformanceCounterMetricRowProps {
  initialChartConfig: ICustomChartConfig;
  availableMetrics: IMetric[];
  dateRange: IDateRange;
  metricData: ICustomDashboardChartSeries;
  nonLineUnitOfMeasure: string | null;
  status: IPerformanceCounterMetricRowStatus;
  handleStatusChange: React.Dispatch<(prev: IPerformanceCounterMetricRowStatus) => IPerformanceCounterMetricRowStatus>;
  onRowUpdate: (values: Partial<ICustomDashboardChartSeries>) => void;
  rowCount: number;
  moRef: MoRef;
  targets: (ITopologyItemDevice | ITopologyItemEventSourceConnection)[];
}

interface IInstancesQueryResponse {
  target: {
    metric: {
      instances: Array<string>;
    } | null;
  } | null;
}

interface IInstancesRequestVariables {
  dateRange: {
    from: string;
    to: string;
  };
  key: string;
  moRef: string;
}

interface IAvailableMetric {
  aliases: string[];
  defaultColor: string;
  hasInstances: boolean;
  key: string;
  unitOfMeasure: string;
}

const useStyles = makeStyles((theme) => ({
  colorColumn: {
    width: "5%",
  },
  colorPicker: {
    "& button, & button>span>div": {
      borderRadius: "100%",
      boxShadow: "none",
      // necessary to override super-specific class set by 3rd party library
      margin: "0 !important",
      transition: "box-shadow linear 100ms",
    },
  },
  instanceColumn: {
    "& .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot": { paddingRight: "0px" },
    "& .MuiChip-root": { height: theme.spacing(4.3), margin: "0px 4px 3px 0px" },
    width: "20%",
  },
  legendLabelColumn: {
    width: "20%",
  },
  metricsColumn: {
    "& .MuiAutocomplete-inputRoot #targetSelect": {
      marginBottom: theme.spacing(0.7),
    },
    width: "25%",
  },
  placeCenter: {
    display: "grid",
    height: "100%",
    placeItems: "center",
  },
  typeColumn: {
    width: "10%",
  },
}));

const PerformanceCounterMetricRow: React.FC<IPerformanceCounterMetricRowProps> = ({
  initialChartConfig,
  targets,
  dateRange,
  nonLineUnitOfMeasure,
  metricData,
  status,
  handleStatusChange,
  onRowUpdate,
  rowCount,
  moRef,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const {
    errors: { metricError, instanceError },
  } = status;
  const [target, setTarget] = React.useState<ITopologyItemDevice | ITopologyItemEventSourceConnection>(targets[0]);
  const [selectedInstanceList, setSelectedInstanceList] = React.useState<string[]>([]);
  const [isOkButtonVisible, setIsOkButtonVisible] = React.useState<boolean>(false);
  const { findByMoRef } = useTopology();
  const [availableMetrics, setAvailableMetrics] = React.useState<IAvailableMetric[]>([]);
  const [hasInstances, setHasInstance] = React.useState<boolean>(false);
  const [instanceList, setInstanceList] = React.useState<string[]>([]);
  const [instanceOptionsForDrd, setInstanceOptionsForDrd] = React.useState<string[]>([]);
  const [selectedInstanceOfDrd, setSelectedInstanceOfDrd] = React.useState<string>("N/A");
  const [openInstanceDropdown, setOpenInstanceDropdown] = React.useState(false);

  //To get the list of metrics of selected target
  const { data: metricsListData, error: metricsError, loading: loadingMetrics } = useQuery(METRICS_QUERY, {
    variables: {
      moRef: metricData.moRef?.toString(),
    },
  });

  const formatSeriesDefaultName = (series: ICustomDashboardChartSeries): string => {
    const target = series.moRef && findByMoRef(series.moRef);
    return series.instance
      ? `${target ? `${target.name}: ` : ""} ${series.metric}: ${series.instance}`
      : `${target ? `${target.name}: ` : ""} ${series.metric}`;
  };

  React.useEffect(() => {
    if (metricData && metricsListData && metricsListData.target && metricsListData.target.metrics) {
      //when you get list of metrics of selected target
      setAvailableMetrics(metricsListData.target.metrics);

      // if you get record of existing row it will compare here and shows pre-selected instance
      const hasInstance =
        metricsListData.target.metrics.find((metric: IAvailableMetric) => metricData.metric === metric.key)
          ?.hasInstances ?? false;

      setHasInstance(hasInstance);
    }
  }, [metricsListData, metricData]);

  //To get the instance list of selected metrics of that target
  const { data, error, loading: loadingInstances } = useQuery<IInstancesQueryResponse, IInstancesRequestVariables>(
    INSTANCES_QUERY,
    {
      fetchPolicy: "no-cache",
      onCompleted(data) {
        // onCompleted runs with undefined data when skip is true
        // onCompleted also runs after the initial state update for the query result
        // the handleChange calls here will not happen on the initial render, so the value is also fixed below
        const rawInstances = data?.target?.metric?.instances ?? [];

        if (rawInstances.length > 0 && !metricData.instance) {
          const availableInstances = metricError || loadingInstances ? new Set<string>() : new Set(rawInstances);
          const sortedInstances = Array.from(availableInstances).sort((a, b) => a.localeCompare(b));
          // No current instance selection, default to first option
          onRowUpdate({ instance: sortedInstances[0] });
        }
      },
      skip: !hasInstances,
      variables: {
        dateRange: {
          from: dateRange.from.toISOString(),
          to: dateRange.to.toISOString(),
        },
        key: metricData.metric,
        moRef: metricData.moRef ? metricData.moRef?.toString() : "",
      },
    },
  );

  //When you get instanceList of selected metrics we need to set that list for dropdown
  React.useEffect(() => {
    setInstanceList(data?.target?.metric?.instances || []);
  }, [data]);

  if (error) {
    throw error;
  }

  //On target change it will set that and get the metrics list for selected metrics
  const onTargetUpdate = (newTarget: ITopologyItemDevice | ITopologyItemEventSourceConnection): void => {
    setTarget(newTarget);
    onRowUpdate({ moRef: MoRef.fromTopologyItem(newTarget) });
  };

  const onMetricUpdate = (value: string): void => {
    onRowUpdate({ instance: null, metric: value });
  };

  // Effect to push status changes to parent and upgrade alias matches
  React.useEffect(() => {
    handleStatusChange((prev) => {
      let updates: IPerformanceCounterMetricRowStatus = prev;

      if (status.loading !== loadingInstances) {
        updates = { ...updates, loading: loadingInstances };
      }

      const invalidMetric = !availableMetrics.some((metric: IAvailableMetric) => metric.key === metricData.metric);
      if (invalidMetric) {
        const aliasMatch = availableMetrics.find((metric: IAvailableMetric) =>
          metric.aliases.includes(metricData.metric),
        );
        if (aliasMatch) {
          onRowUpdate({ metric: aliasMatch.key });
        } else if (!status.errors.metricError) {
          updates = { ...updates, errors: { ...updates.errors, metricError: true } };
        }
      } else if (status.errors.metricError) {
        updates = { ...updates, errors: { ...updates.errors, metricError: false } };
      }

      if (!loadingInstances) {
        const invalidInstance =
          !!metricData.instance &&
          instanceList.length > 0 &&
          !instanceList.some((instance) => instance === metricData.instance);
        if (status.errors.instanceError !== invalidInstance) {
          updates = { ...updates, errors: { ...updates.errors, instanceError: invalidInstance } };
        }
      }

      // updates was initialized to equal status, so this only triggers a rerender if it was reassigned to a new value
      return updates;
    });
  });

  //Effect to set pre-selected instance from available instance for new row and pre-selected instance for existing row
  React.useEffect(() => {
    const availableInstances = metricError || loadingInstances ? new Set<string>() : new Set(instanceList);
    const sortedInstances = Array.from(availableInstances).sort((a, b) => a.localeCompare(b));

    setInstanceOptionsForDrd(sortedInstances.length > 0 ? sortedInstances : ["N/A"]);
    setSelectedInstanceOfDrd(sortedInstances.length > 0 ? metricData.instance ?? sortedInstances[0] : "N/A");
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceList, metricData]);

  // prepare metric options for autocomplete
  const metricKeys = new Set(availableMetrics.map((metric: IAvailableMetric) => metric.key));
  metricKeys.add(metricData.metric);
  const metricOptions = Array.from(metricKeys).sort((a: string, b: string) => a.localeCompare(b));

  const unitOfMeasure = availableMetrics.find((metric: IAvailableMetric) => metric.key === metricData.metric)
    ?.unitOfMeasure;
  const disableChartSelect = !!nonLineUnitOfMeasure && !!unitOfMeasure && unitOfMeasure !== nonLineUnitOfMeasure;

  //Effect to set chart type and get latest chart preview
  React.useEffect(() => {
    if (disableChartSelect && metricData.chartType !== "line") onRowUpdate({ chartType: "line" });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Effect to show first available instance if they change metrics or target otherwise set selected instance
  React.useEffect(
    () => {
      if (instanceError) onRowUpdate({ instance: instanceOptionsForDrd[0] });
      else setSelectedInstanceList([selectedInstanceOfDrd]);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedInstanceOfDrd, instanceError],
  );

  //On multiple instance selection when you click on OK button it will pass list of instances and create multiple rows respectively
  const handleOkButton = (): void => {
    const [first, ...rest] = selectedInstanceList;
    onRowUpdate({ ...metricData, instance: first, instanceList: rest });
    setSelectedInstanceList([selectedInstanceList[0]]);
    setOpenInstanceDropdown(false);
    setIsOkButtonVisible(false);
  };

  //To show active target based on selected target from the list in the target dropdown
  const dynamicActiveTarget: ITopologyItem | null = metricData.moRef && findByMoRef(metricData.moRef);

  return (
    <>
      <NoBorderTableCell className={classes.metricsColumn} headers="targets">
        <TargetAutocomplete
          activeTarget={dynamicActiveTarget ?? target}
          handleTargetChange={(newTarget) => onTargetUpdate(newTarget)}
          targets={targets}
        />
      </NoBorderTableCell>
      <NoBorderTableCell className={classes.metricsColumn} headers="metrics">
        <Autocomplete
          disableClearable
          getOptionDisabled={(option) => metricError && option === metricData.metric}
          onChange={(_, value: string) => onMetricUpdate(value)}
          options={metricOptions}
          renderInput={(params) => (
            <Tooltip title={metricData.metric}>
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: metricError ? (
                    <InputAdornment position="start">
                      <Tooltip title={intl.formatMessage({ id: "selectedMetricIsInvalid" })}>
                        <ErrorIcon color="error" />
                      </Tooltip>
                    </InputAdornment>
                  ) : (
                    params.InputProps.startAdornment
                  ),
                }}
                error={!metricData.metric || metricError}
                hiddenLabel
                inputProps={{
                  ...params.inputProps,
                  "aria-describedby": metricError ? intl.formatMessage({ id: "selectedMetricIsInvalid" }) : undefined,
                  "aria-label": intl.formatMessage({ id: "metrics" }),
                }}
                name="metricAutocomplete"
                variant="standard"
              />
            </Tooltip>
          )}
          value={metricData.metric}
        />
      </NoBorderTableCell>
      <NoBorderTableCell className={classes.instanceColumn} headers="instance">
        {!loadingInstances && (
          <Autocomplete
            disableClearable
            disableCloseOnSelect
            disabled={!metricData.instance || metricError}
            getOptionLabel={(option) => option}
            limitTags={2}
            multiple
            onChange={(_, value) => {
              setSelectedInstanceList(value);
            }}
            onClose={() => {
              setOpenInstanceDropdown(false);
              setIsOkButtonVisible(false);
            }}
            onOpen={() => {
              setOpenInstanceDropdown(true);
              setIsOkButtonVisible(true);
            }}
            open={openInstanceDropdown}
            options={instanceOptionsForDrd}
            renderInput={(params) => (
              <>
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: isOkButtonVisible ? (
                      <Button
                        color="primary"
                        disabled={
                          !metricData.instance ||
                          selectedInstanceList.length === 0 ||
                          rowCount + selectedInstanceList.length - 1 > 10
                        }
                        onClick={handleOkButton}
                        size="small"
                        variant="text"
                      >
                        {intl.formatMessage({ id: "ok" })}
                      </Button>
                    ) : null,
                  }}
                  data-testid="instances-options"
                  hiddenLabel
                  inputProps={{
                    ...params.inputProps,
                    "aria-label": intl.formatMessage({ id: "instance" }),
                  }}
                  name="instanceAutocomplete"
                  variant="standard"
                />
              </>
            )}
            renderOption={(option: unknown, { selected }: AutocompleteRenderOptionState) => (
              <>
                <Checkbox checked={selected} color="primary" />
                {option}
              </>
            )}
            renderTags={(values) =>
              values.map((value, index) => (
                <Tooltip key={index} title={value}>
                  <Chip key={value} label={value} />
                </Tooltip>
              ))
            }
            value={selectedInstanceList}
          />
        )}
        {loadingInstances && (
          <Skeleton animation="wave" data-testid="loadingInstancesSkeleton" height="30px" variant="rect" />
        )}
      </NoBorderTableCell>
      <NoBorderTableCell className={classes.legendLabelColumn} headers="legendLabel">
        <TextField
          defaultValue={metricData.label ?? ""}
          fullWidth
          hiddenLabel
          inputProps={{ "aria-label": intl.formatMessage({ id: "legendLabel" }) }}
          key={metricData.label}
          name="metricLabel"
          onBlur={(e) => onRowUpdate({ label: e.target.value || null })}
          placeholder={formatSeriesDefaultName(metricData)}
          variant="standard"
        />
      </NoBorderTableCell>
      <NoBorderTableCell className={classes.colorColumn} headers="color">
        <div className={classNames(classes.colorPicker, classes.placeCenter)}>
          <ColorPicker
            deferred // renders a "Set" button in the popup
            hideTextfield
            onChange={(color) => onRowUpdate({ color: `#${color.hex}` })}
            value={metricData.color}
          />
        </div>
      </NoBorderTableCell>
      <NoBorderTableCell className={classes.typeColumn} headers="chartType">
        <CustomChartModalTooltip title={disableChartSelect ? "disabledChartSelectMessage" : undefined}>
          <Select
            disabled={disableChartSelect}
            fullWidth
            inputProps={{ "aria-label": intl.formatMessage({ id: "chartType" }), "data-testid": "chartTypeSelect" }}
            name="chartType"
            onChange={(e) => {
              const newChartType = e.target.value as ChartDisplayType;
              onRowUpdate({ chartType: newChartType });
            }}
            value={metricData.chartType}
            variant="standard"
          >
            <MenuItem value="line">{intl.formatMessage({ id: "line" })}</MenuItem>
            <MenuItem value="stackedArea">{intl.formatMessage({ id: "area" })}</MenuItem>
            <MenuItem value="stackedBar">{intl.formatMessage({ id: "column" })}</MenuItem>
          </Select>
        </CustomChartModalTooltip>
      </NoBorderTableCell>
    </>
  );
};

export default PerformanceCounterMetricRow;
