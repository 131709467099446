import * as React from "react";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import RoomIcon from "@material-ui/icons/Room";
import { TopologyObjectType, ITopologyItem } from "../../components/TopologyContext";
import StorageIcon from "@material-ui/icons/Storage";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { useCurrentTenant } from "../../hooks/useCurrentTenant";
import { makeStyles } from "@material-ui/core/styles";
import { useIntl } from "react-intl";

export interface IEnvironmentHeaderProps {
  topLevelObject?: ITopologyItem;
}

const useStyles = makeStyles((theme) => ({
  headerText: {
    "& > h1": {
      fontSize: "1.45rem",
      letterSpacing: "0.5px",
    },
    "& > svg": {
      marginRight: theme.spacing(1),
    },
    alignItems: "center",
    color: theme.palette.text.secondary,
    display: "flex",
    margin: theme.spacing(2, 0),
  },
}));

const EnvironmentHeader: React.FC<IEnvironmentHeaderProps> = ({ topLevelObject }) => {
  const intl = useIntl();
  const tenantName = useCurrentTenant()?.tenant.name;
  const classes = useStyles();

  const getHeaderIcon = (): JSX.Element => {
    switch (topLevelObject?.type) {
      case TopologyObjectType.site:
        return <RoomIcon color="inherit" />;
      case TopologyObjectType.group:
        return <GroupWorkIcon color="inherit" />;
      default:
        return <StorageIcon color="inherit" />;
    }
  };

  return (
    <div className={classes.headerText}>
      {getHeaderIcon()}
      {topLevelObject ? (
        <>
          <Typography color="inherit" component="h1" variant="h5">
            {intl.formatMessage({ id: topLevelObject.type })}
          </Typography>
          <Divider flexItem orientation="vertical" variant="middle" />
          <Typography color="inherit" component="h1" variant="h5">
            {topLevelObject.name}
          </Typography>
        </>
      ) : (
        <Typography color="inherit" component="h1" variant="h5">
          {tenantName}
        </Typography>
      )}
    </div>
  );
};

export default EnvironmentHeader;
