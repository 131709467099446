export function objectMap<V, T = V>(
  obj: { [key: string]: V },
  callback: (currentValue: V, key: string, obj: { [key: string]: V }) => T,
): { [key: string]: T } {
  return Object.keys(obj).reduce((p: { [key: string]: T }, c) => {
    p[c] = callback(obj[c], c, obj);
    return p;
  }, {});
}

/**
 * Performs a shallow compare of the two objects provided.
 * A shallow compare iterates through the first layer of the keys of an object and compares them.
 * Nested objects are only compared by reference.
 * Also supports null, undefined, and scalar values with fallback to Object.is.
 * @param a The first object to compare
 * @param b The second object to compare
 */
export function shallowCompare(a: Record<string, unknown>, b: Record<string, unknown>): boolean {
  if (a && b && typeof a === "object" && typeof b === "object") {
    // Objects and arrays fall into this category when both a and b have a value
    return Object.keys(a).length === Object.keys(b).length && Object.keys(a).every((k) => a[k] === b[k]);
  } else {
    // Scalar values and null/undefined fall into this category
    return Object.is(a, b);
  }
}
