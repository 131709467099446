import { ALL_INSTANCES, IMetricChartSeriesOptions } from "../../../../components/MetricChart";
import { ITranslation } from "../../../../intl/types";
import { QueryAggregateType } from "./types";

function getQueriesOptions(aggregateBy: QueryAggregateType): [keyof ITranslation, string] {
  switch (aggregateBy) {
    case QueryAggregateType.cpu:
      return ["cpu", "cpu"];
    case QueryAggregateType.duration:
      return ["duration", "duration"];
    case QueryAggregateType.execCount:
      return ["execCount", "executions"];
    case QueryAggregateType.readsL:
      return ["readsL", "logicalReads"];
    case QueryAggregateType.readsP:
      return ["readsP", "physicalReads"];
    case QueryAggregateType.writesL:
      return ["writesL", "logicalWrites"];
  }
}

export function makeQueriesOptions(
  aggregateBy: QueryAggregateType,
  colors: Generator<string, never, void>,
): IMetricChartSeriesOptions {
  const [axisLabel, metric] = getQueriesOptions(aggregateBy);
  return {
    axisLabel,
    chartType: "stackedBar",
    color: () => colors.next().value,
    instance: ALL_INSTANCES,
    label: (s) => s ?? `topsql.queries.${metric}`,
    metric: `sqlserver.topsql.queries.${metric}`,
  };
}
