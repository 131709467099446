import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import classNames from "classnames";
import * as React from "react";
import { useIntl } from "react-intl";

interface IFilterSectionProps {
  title: string;
  selectedFilters: string[];
  setSelectedFilters: (newSelection: string[]) => void;
  filterOptions: string[];
}
const useStyles = makeStyles((theme) => ({
  checkbox: {
    padding: "5px",
  },
  collapsed: {
    transform: "rotate(0deg)",
    transition: "transform .2s linear",
  },
  expanded: {
    transform: "rotate(180deg)",
    transition: "transform .2s linear",
  },
  labelRoot: {
    width: "100%",
  },
  labelText: {
    fontSize: "0.9rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  listItem: {
    padding: "0",
  },
  root: {
    borderTop: "1px #EEE solid",
    paddingLeft: "2em",
  },
  title: {
    fontSize: "1.1rem",
    padding: "0 0.25em",
  },
  titleListItem: {
    cursor: "pointer",
    marginLeft: "-1.5em",
  },
}));

const FilterSection: React.FunctionComponent<IFilterSectionProps> = ({
  selectedFilters,
  setSelectedFilters,
  filterOptions,
  title,
}) => {
  const classes = useStyles({});
  const intl = useIntl();
  const [collapse, setCollapse] = React.useState(false);
  const handleCollapseToggle = (): void => {
    setCollapse((prev) => !prev);
  };

  const handleChange = (id: string): void => {
    if (id === "(All)") {
      setSelectedFilters(selectedFilters.length === filterOptions.length ?
        [] :
        [...filterOptions],
      );
      return;
    }
    const arrayLoc = selectedFilters.indexOf(id);
    if (arrayLoc !== -1) {
      const newArray = [...selectedFilters];
      newArray.splice(arrayLoc, 1);
      setSelectedFilters([...newArray]);
    } else {
      setSelectedFilters([...selectedFilters, id]);
    }
  };

  return (

    <List
      classes={{
        root: classes.root,
      }}
      dense
    >
      <ListItem
        aria-expanded={!collapse}
        className={classNames(classes.listItem, classes.titleListItem)}
        onClick={handleCollapseToggle}
        title={title}>
        <KeyboardArrowDown className={collapse ? classes.expanded : classes.collapsed} />
        <ListItemText
          aria-hidden
          // Hide the text content to screen readers since it is already in the title
          className={classes.title}
          disableTypography
          primary={title}
        />
      </ListItem>
      <Collapse in={!collapse}>
        <ListItem
          className={classes.listItem}>
          <FormControlLabel
            classes={{
              label: classes.labelText,
            }}
            control={
              selectedFilters.length === filterOptions.length ? <Checkbox
                checked={selectedFilters.length === filterOptions.length}
                classes={{
                  root: classes.checkbox,
                }}
                color="primary"
                data-testid="All"
                onChange={() => handleChange("(All)")}
                value="(All)"
              /> :
                <Checkbox
                  checked={!!selectedFilters.length}
                  classes={{
                    root: classes.checkbox,
                  }}
                  color="primary"
                  data-testid="indeterminateAll"
                  indeterminate={!!selectedFilters.length}
                  onChange={() => handleChange("(All)")}
                  value="(All)"
                />
            }
            key="(All)"
            label={`(${intl.formatMessage({ id: "all" })})`}
          />
        </ListItem>
        {filterOptions.map((filterOption) => (
          <ListItem
            className={classes.listItem}
            key={filterOption}>
            <FormControlLabel
              classes={{
                label: classes.labelText,
                root: classes.labelRoot,
              }}
              control={
                <Checkbox
                  checked={selectedFilters.indexOf(filterOption) !== -1}
                  classes={{
                    root: classes.checkbox,
                  }}
                  color="primary"
                  data-testid={filterOption + "_checkbox"}
                  onChange={() => handleChange(filterOption)}
                  value={filterOption}
                />
              }
              label={filterOption}
              title={filterOption}
            />
          </ListItem>
        ))}
      </Collapse>
    </List>);
};

export default FilterSection;
