import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";

type SWIconProps = {
  className: string;
};

const useStyles = makeStyles(() => ({
  "@keyframes skeletonLoading": {
    "0%": {
      fill: "#f99e12",
    },
    "100%": {
      fill: "#f5dcb7",
    },
  },
  skeleton: {
    animation: "$skeletonLoading 1s linear infinite alternate",
    opacity: "0.7",
  },
}));

const SWIcon: React.FunctionComponent<SWIconProps> = (props) => {
  const classes = useStyles();
  return (
    <svg {...props} fill="none" height="64" viewBox="0 0 64 64" width="64" xmlns="http://www.w3.org/2000/svg">
      <g className={classes.skeleton} fill="#F99E12" id="SW-Icon">
        <path
          d="M39.3929 12.2877C27.0179 12.2877 14.9643 16.2329 5 24.1233C16.3693 26.7122 19.3036 29.8767 23.6429 33C44.6964 27.0822 55.8726 21.4584 59 9C55.3036 13.274 47.5893 15.5753 35.5357 16.2329C37.3758 14.8432 38.2805 14.0067 39.3929 12.2877Z"
          id="Vector-1"
        />
        <path
          d="M59 30C44.4344 30.0655 36.7403 30.7139 25 34.439C27.4702 37.3005 28.8088 39.2356 31.1524 43C41.8925 36.6757 47.9743 33.6865 59 30Z"
          id="Vector-2"
        />
        <path
          d="M33 45.9333C34.0435 48.7538 34.5011 50.6443 34.9115 55C42.136 48.0603 45.5601 44.3696 51 38C44.1371 41.1293 40.2629 42.8788 33 45.9333Z"
          id="Vector-3"
        />
      </g>
    </svg>
  );
};

export { SWIcon };
