import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card/Card";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useIntl } from "react-intl";
import { IDateRange } from "../../components/DateContext";
import { ITopologyItemEventSourceConnection } from "../../components/TopologyContext";
import TempDbActivity from "./components/TempDbActivity";
import TempDbSessionsGrid from "./components/TempDbSessionsGrid";
import TempDbSummary from "./components/TempDbSummary";
import CardContent from "@material-ui/core/CardContent/CardContent";
import FeatureDisabled from "../../components/FeatureDisabled";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

export interface ITempDbContentProps {
  target: ITopologyItemEventSourceConnection;
}

const useStyles = makeStyles((theme) => ({
  activity: {
    gridArea: "activity",

    // prevents tooltip from being cutoff by Mui-Card
    overflow: "visible",
  },
  root: {
    display: "grid",
    gridGap: theme.spacing(2),
    gridTemplateAreas: `
      "summary"
      "activity"
      "sessionGrid"
    `,
    gridTemplateColumns: "1fr",
    gridTemplateRows: "min-content min-content min-content",
  },
  sessionGrid: {
    gridArea: "sessionGrid",
  },
  summary: {
    gridArea: "summary",

    // prevents tooltip from being cutoff by Mui-Card
    overflow: "visible",
  },
}));

const TempDbContent: React.FC<ITempDbContentProps> = ({ target }) => {
  const intl = useIntl();
  const classes = useStyles();
  const [activeRange, setActiveRange] = React.useState<IDateRange | null>(null);
  return (
    <div className={classes.root}>
      {target.features.performance && (
        <>
          <TempDbSummary
            activeRange={activeRange}
            className={classes.summary}
            objectCollectionEnabled={target.settings?.collectTempDbObjects ?? false}
            onActiveRangeChange={setActiveRange}
            target={target}
          />
          <TempDbActivity
            activeRange={activeRange}
            className={classes.activity}
            onActiveRangeChange={setActiveRange}
            target={target}
          />
        </>
      )}
      {(target.settings?.collectTempDbSessions ?? false) || !target.isWatched ? (
        <TempDbSessionsGrid
          activeRange={activeRange}
          className={classes.sessionGrid}
          onActiveRangeChange={setActiveRange}
          target={target}
        />
      ) : (
        <Card className={classes.sessionGrid} data-testid="tempDbSessionsGridDisabled">
          <CardHeader title={intl.formatMessage({ id: "tempDbSessionUsage" })} />
          <CardContent>
            <FeatureDisabled
              action={
                <Button
                  color="primary"
                  href="https://docs.sentryone.com/help/monitor-tempdb#tempdb-session-usage"
                  rel="noopener noreferrer"
                  startIcon={<OpenInNewIcon />}
                  target="_blank"
                  variant="text"
                >
                  {intl.formatMessage({ id: "viewDocumentation" })}
                </Button>
              }
              message={intl.formatMessage({ id: "pleaseSeeTheDocumentationToEnableColleciton" })}
              title={intl.formatMessage({ id: "tempDbSessionUsageNotEnabledMessage" })}
            />
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default TempDbContent;
