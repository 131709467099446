import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

const ErrorIcon: React.FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 80 80" {...props}>
      <path
        d="M6.72 62.8529L3.08979 19.0827C3.04145 18.4998 3.50149 18 4.08637 18H12.96H20.4092C20.7736 18 21.1092 18.1983 21.2851 18.5175L24.2149 23.8355C24.3908 24.1547 24.7264 24.3529 25.0908 24.3529H65.66C66.2123 24.3529 66.66 24.8007 66.66 25.3529V32.6765M6.72 62.8529L16.2167 33.3699C16.3498 32.9566 16.7343 32.6765 17.1685 32.6765H66.66M6.72 62.8529H65.9343C66.3671 62.8529 66.7508 62.5745 66.885 62.163L76.0728 33.9865C76.2836 33.3402 75.8019 32.6765 75.1221 32.6765H66.66"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M29 38L34.5 43.5M29 43.5L34.5 38M50 38L55.5 43.5M50 43.5L55.5 38"
        stroke="currentColor"
        strokeWidth="2"
      />
      <circle cx="42.5" cy="52.5" r="4.5" stroke="currentColor" strokeWidth="2" />
    </SvgIcon>
  );
};

export default ErrorIcon;
