import { useTreeTable } from "@sentryone/react-datatable";
import { useEffect, useRef } from "react";

export interface IAutoExpandTreeTableRowsProps<T> {
  tableData: Array<T>;
  equalIf: (a: T, b: T) => boolean;
}

/**
 * This component auto-expands every row in a MuiTreeTable
 * @param tableData The same data object that is passed into MuiTreeTable
 * @param equalIf A comparison function to determine whether two objects of type T are equal
 * @returns null
 */
function AutoExpandTreeTableRows<T>({ tableData, equalIf }: IAutoExpandTreeTableRowsProps<T>): null {
  const treeTableContext = useTreeTable<T>();
  const prevTableData = useRef<Array<T> | null>();
  if (!treeTableContext) throw new Error("Cannot use AutoExpandTreeTableRows outside of a MuiTreeTable.");

  useEffect(() => {
    const getChildren = (x: T): Array<T> => {
      return treeTableContext.childDataSelector(x).flatMap((child) => {
        return [x, child, ...getChildren(child)];
      });
    };

    const flatItemsList: Array<T> = tableData.flatMap((x) => getChildren(x));

    const treeTableDataChanged = (): boolean => {
      if (!prevTableData.current || prevTableData.current.length !== flatItemsList.length) return true;
      else {
        for (let i = 0; i < flatItemsList.length; i++) {
          if (!equalIf(prevTableData.current[i], flatItemsList[i])) return true;
        }
        return false;
      }
    };

    if (treeTableDataChanged()) {
      prevTableData.current = flatItemsList;
      treeTableContext.setExpandedRows(flatItemsList);
    }
    // it wants me to add T which is a type and therefore cannot be a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, treeTableContext.childDataSelector]);

  return null;
}

export default AutoExpandTreeTableRows;
