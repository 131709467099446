import * as React from "react";
import TopologyTreeItem from "./TopologyTreeItem";
import { ITreeItem } from "./types";

const renderTopologyTreeItem = (treeItem: ITreeItem, filter: string): JSX.Element | null => {
  const childItems = treeItem.children
    ? treeItem.children
        .map((child) => renderTopologyTreeItem(child, filter))
        .filter((childItem): childItem is JSX.Element => Boolean(childItem))
    : [];

  if (!filter || childItems.length > 0 || treeItem.name.toLowerCase().includes(filter.toLowerCase())) {
    return <TopologyTreeItem childItems={childItems} key={treeItem.id} treeItem={treeItem} />;
  } else {
    return null;
  }
};

export default renderTopologyTreeItem;
