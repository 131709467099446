import * as React from "react";
import DialogContent, { DialogContentProps } from "@material-ui/core/DialogContent";
import useStyles from "./utils/styles";

const FocusedDialogContent: React.FC<DialogContentProps> = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <DialogContent className={classes.content} {...props}>
      {children}
    </DialogContent>
  );
};

export default FocusedDialogContent;
