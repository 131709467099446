import * as React from "react";
import SearchTextField from "../../../../../../components/SearchTextField";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";

interface ITopologyFilterInputProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles((theme) => ({
  searchFieldFocused: {},
  searchFieldInput: {
    color: "var(--sidebar-dark-text-white)",
    paddingBottom: "10.5px",
    paddingTop: "10.5px",
  },
  searchFieldInputRoot: {
    "& fieldset ": {
      borderColor: "var(--sidebar-dark-text-white)",
    },
    "&:hover > fieldset": {
      borderColor: "var(--sidebar-dark-text-white)",
    },
  },
  searchFieldLabel: {
    "&$searchFieldFocused": {
      color: "var(--sidebar-dark-text-grey)",
    },
    color: "var(--sidebar-dark-text-grey)",
  },
  searchFieldRoot: {
    width: "100%",
  },
}));

const TopologyFilterInput = ({ value, onChange }: ITopologyFilterInputProps): React.ReactElement => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <SearchTextField
      InputLabelProps={{
        classes: {
          focused: classes.searchFieldFocused,
          root: classes.searchFieldLabel,
        },
      }}
      InputProps={{
        classes: {
          input: classes.searchFieldInput,
          root: classes.searchFieldInputRoot,
        },
      }}
      className={classes.searchFieldRoot}
      id="targetFilterTextField"
      label={intl.formatMessage({ id: "filterMsg" })}
      onChange={onChange}
      value={value}
    />
  );
};

export default TopologyFilterInput;
