import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { LoadingIndicator } from "@sentryone/material-ui";
import * as React from "react";
import { useIntl } from "react-intl";
import { useDeadlocksContext } from "../../../../contexts/deadlocksContext";
import NoDataIndicator from "../../../../components/NoDataIndicator";

const useStyles = makeStyles((theme) => ({
  loading: {
    flexGrow: 1,
    height: 0,
    maxWidth: "100%",
    minHeight: "5rem",
  },
  noWrap: {
    maxWidth: theme.spacing() * 30,
    minWidth: 0,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  tableRoot: {
    "& td": {
      verticalAlign: "middle",
    },
  },
  title: {
    fontWeight: 600,
    paddingLeft: theme.spacing() * 2,
  },
}));

export interface IResourceLockGridRow {
  key: string;
  type: string;
  lockMode: string;
  lockType: string;
  object: string;
  index: string;
  waitResource: string;
}

const DeadlockLockGrid: React.FunctionComponent = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { loading, loadingDeadlock, error, selectedDeadlock, selectedNodeId } = useDeadlocksContext();

  const lockInformation = React.useMemo(
    () => {
      const locks: IResourceLockGridRow[] = [];
      selectedDeadlock?.resources.forEach((resourceLock) => {
        resourceLock.owners.forEach((owner) => {
          if (owner.id === selectedNodeId) {
            locks.push({
              index: resourceLock.indexName ?? "",
              key: owner.key,
              lockMode: owner.requestType ? `${owner.mode} (${owner.requestType})` : owner.mode,
              lockType: intl.formatMessage({ defaultMessage: resourceLock.type, id: resourceLock.type }),
              object: resourceLock.objectName,
              type: intl.formatMessage({ id: "owner" }),
              waitResource: owner.process.waitResource,
            });
          }
        });
      });
      // Loop a second time to handle the waiters, same speed as doing all and then sorting
      selectedDeadlock?.resources.forEach((resourceLock) => {
        resourceLock.waiters.forEach((waiter) => {
          if (waiter.id === selectedNodeId) {
            locks.push({
              index: resourceLock.indexName ?? "",
              key: waiter.key,
              lockMode: waiter.requestType ? `${waiter.mode} (${waiter.requestType})` : waiter.mode,
              lockType: intl.formatMessage({ defaultMessage: resourceLock.type, id: resourceLock.type }),
              object: resourceLock.objectName,
              type: intl.formatMessage({ id: "waiter" }),
              waitResource: waiter.process.waitResource,
            });
          }
        });
      });
      return locks;
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDeadlock, selectedNodeId],
  );

  return (
    <Table className={classes.tableRoot} size="small">
      <caption>{intl.formatMessage({ id: "locks" })}</caption>
      <TableHead>
        <TableRow>
          <TableCell>{intl.formatMessage({ id: "context" })}</TableCell>
          <TableCell>{intl.formatMessage({ id: "lockMode" })}</TableCell>
          <TableCell>{intl.formatMessage({ id: "lockType" })}</TableCell>
          <TableCell>{intl.formatMessage({ id: "object" })}</TableCell>
          <TableCell>{intl.formatMessage({ id: "index" })}</TableCell>
          <TableCell>{intl.formatMessage({ id: "waitResource" })}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loading || loadingDeadlock ? (
          <TableRow>
            <td colSpan={6}>
              <LoadingIndicator className={classes.loading} variant="table" />
            </td>
          </TableRow>
        ) : error || !lockInformation.length ? (
          <TableRow>
            <td colSpan={6}>
              <NoDataIndicator />
            </td>
          </TableRow>
        ) : (
          lockInformation.map((row) => (
            <TableRow key={row.key}>
              <TableCell>{row.type}</TableCell>
              <TableCell>{row.lockMode}</TableCell>
              <TableCell>{row.lockType}</TableCell>
              <TableCell className={classes.noWrap} title={row.object}>
                {row.object}
              </TableCell>
              <TableCell>{row.index}</TableCell>
              <TableCell className={classes.noWrap} title={row.waitResource}>
                {row.waitResource}
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
};

export default DeadlockLockGrid;
