import * as React from "react";
import { IExecutedQueryTotalsResponse } from "../../api/models/ExecutedQueryTotals";
import { IExecutedStatementsResponse } from "../../api/models/ExecutedStatements";
import { IExecutedQueryTraceEventsResponse } from "../../api/models/IExecutedQueryTraceEventsCriteria";
import { IDateRange } from "../../components/DateContext";
import {
  chartFilterDefault,
  dataInfoDefault,
  totalsGridPageInfoDefault,
  traceEventGridPageInfoDefault,
  statementGridPageInfoDefault,
} from "./topSqlDefaults";
import { ITopSqlContext, ISearchParams, ITotalsParams, ITraceEventParams } from "./types";

const TopSqlContext = React.createContext<ITopSqlContext>({
  cachePlanId: null,
  chartFilter: chartFilterDefault,
  eventSourceConnectionId: -1,
  handleChangeChartFilter: (newChartFilter: IDateRange | null) => null,
  handleChangeIsTotals: () => null,
  handleSearchTotals: (keyword: ISearchParams) => null,
  handleSelectedEventChange: (row: IExecutedQueryTraceEventsResponse) => null,
  handleSelectedEventStatementChange: (row: IExecutedQueryTraceEventsResponse) => null,
  handleSelectedStatementChange: (row: IExecutedStatementsResponse | null) => null,
  handleSelectedTotalChange: (row: IExecutedQueryTotalsResponse) => null,
  handleStatementGridPageChange: (pageIndex: number, pageSize: number) => null,
  handleStatementsEventsBackClick: () => null,
  handleStatementsEventsClick: (row: IExecutedStatementsResponse) => null,
  handleTotalsEventsBackClick: () => null,
  handleTotalsEventsClick: (row: IExecutedQueryTotalsResponse) => null,
  handleTotalsFilter: (keyword: ITotalsParams) => null,
  handleTotalsGridPageChange: (pageIndex: number, pageSize: number) => null,
  handleTraceEventFilter: (keyword: ITraceEventParams) => null,
  handleTraceEventGridPageChange: (pageIndex: number, pageSize: number) => null,
  isTotals: true,
  isTraceStatement: true,
  searchKeyword: "",
  selectedEvent: null,
  selectedStatement: null,
  selectedStatementEvent: null,
  selectedTotal: null,
  setCachePlanId: () => null,
  statementGridPageInfo: statementGridPageInfoDefault,
  statementResults: dataInfoDefault,
  statementsTraceEventCriteria: null,
  statementsTraceEventsResults: dataInfoDefault,
  totalsGridPageInfo: totalsGridPageInfoDefault,
  totalsResults: dataInfoDefault,
  totalsTraceEventCriteria: null,
  totalsTraceEventsResults: dataInfoDefault,
  traceEventGridPageInfo: traceEventGridPageInfoDefault,
});

export default TopSqlContext;
