import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import * as Sql from "../SqlCharts";
import ChartCard from "./ChartCard";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridAutoRows: theme.spacing(45),
    gridGap: theme.spacing(2),
    gridTemplateColumns: "1fr",
  },
}));

const SqlServer: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ChartCard chartComponent={Sql.SqlServerActivityChart} title="sqlServerActivity" />
      <ChartCard chartComponent={Sql.WaitsChart} title="sqlServerWaits" />
      <ChartCard chartComponent={Sql.SqlServerMemoryChart} title="sqlServerMemory" />
      <ChartCard chartComponent={Sql.DatabaseIOChart} title="databaseIO" />
    </div>
  );
};

export default SqlServer;
