import { useRef } from "react";

const defaultCompare = (a: unknown, b: unknown): boolean => {
  return a === b;
};

/**
 * Memoizes an array of values or objects to maintain referential integrity.
 * @param values - the array to memoize
 * @param compareFn - the function used to determine if the array's values have changed
 */
export default function useMemoizedArray<T>(
  values: readonly T[],
  compareFn: (a: T, b: T) => boolean = defaultCompare,
): readonly T[] {
  const ref = useRef(values);
  if (values.length !== ref.current.length || ref.current.some((x, i) => !compareFn(x, values[i]))) {
    ref.current = values;
  }
  return ref.current;
}
