import * as React from "react";
import type { IUser, IUserGroup, IModalCloseOptions } from "../../types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
import PrincipalMultipleSelect from "./PrincipalMultipleSelect";
import { usePrincipalActions } from "../../useFeatureAccess";
import { SWIcon } from "../../../../components/icons/SWIcon";

export interface IUserModalProps {
  handleClose: (options: IModalCloseOptions) => void;
  open: boolean;
  selectedUser: IUser | null;
  userGroups: IUserGroup[];
}

// email regex obtained from https://www.emailregex.com/
/* eslint-disable no-useless-escape */
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    gap: `${theme.spacing(2)}px`,
  },
  loadingIcon: {
    height: "150px",
    width: "100%",
  },
  loadingIconContainer: {
    display: "flex",
    height: "100%",
    placeItems: "center",
    position: "absolute",
    width: "100%",
    zIndex: 1,
  },
}));

const UserModal: React.FC<IUserModalProps> = ({ handleClose, open, selectedUser, userGroups }) => {
  const intl = useIntl();
  const classes = useStyles();

  const [userFirstName, setUserFirstName] = React.useState(selectedUser?.firstName ?? "");
  const [userLastName, setUserLastName] = React.useState(selectedUser?.lastName ?? "");
  const [userEmail, setUserEmail] = React.useState(selectedUser?.email ?? "");
  const [selectedUserGroups, setSelectedUserGroups] = React.useState(selectedUser?.userGroups ?? []);
  const [isLoading, setIsLoading] = React.useState(false);

  const { addUser, updateUser } = usePrincipalActions();

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    const ModalCloseOption: IModalCloseOptions = { id: null, status: "CLOSED" };

    if (selectedUser) {
      setIsLoading(true);

      try {
        const { isSuccess } = await updateUser({
          email: userEmail.trim(),
          firstName: userFirstName.trim(),
          lastName: userLastName.trim(),
          objectId: selectedUser.id,
          userGroupIds: selectedUserGroups.map((e) => e.id),
        });
        ModalCloseOption.status = isSuccess ? "SUCCESS" : "ERROR";
      } catch {
        setIsLoading(false);
        ModalCloseOption.status = "ERROR";
      }
    } else {
      setIsLoading(true);

      try {
        const { isSuccess } = await addUser({
          email: userEmail.trim(),
          firstName: userFirstName.trim(),
          lastName: userLastName.trim(),
          userGroupIds: selectedUserGroups.map((e) => e.id),
        });
        ModalCloseOption.status = isSuccess ? "SUCCESS" : "ERROR";
        ModalCloseOption.id = isSuccess ? userEmail.trim() : null;
      } catch {
        setIsLoading(false);
        ModalCloseOption.status = "ERROR";
      }
    }

    handleClose(ModalCloseOption);
  };

  const validateEmail = (): boolean => {
    return !(userEmail.trim() && emailRegex.test(userEmail));
  };
  const validateForm = (): boolean => {
    return !userFirstName.trim() || !userLastName.trim() || validateEmail();
  };

  return (
    <Dialog fullWidth maxWidth="xs" onClose={() => handleClose({ id: null, status: "CLOSED" })} open={open}>
      {isLoading && (
        <div className={classes.loadingIconContainer}>
          <SWIcon className={classes.loadingIcon} />
        </div>
      )}
      <DialogTitle disableTypography>
        <Typography component="h1" variant="h6">
          {intl.formatMessage({ id: `${selectedUser ? "editUser" : "createUser"}` })}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form className={classes.form} id="userGroupForm" method="POST" onSubmit={handleSubmit}>
          <TextField
            InputLabelProps={{ htmlFor: intl.formatMessage({ id: "firstName" }), shrink: true }}
            autoFocus
            fullWidth
            inputProps={{ "aria-label": intl.formatMessage({ id: "firstName" }) }}
            label={intl.formatMessage({ id: "firstName" })}
            onChange={(e) => setUserFirstName(e.target.value)}
            required
            value={userFirstName}
            variant="filled"
          />
          <TextField
            InputLabelProps={{ htmlFor: intl.formatMessage({ id: "lastName" }), shrink: true }}
            fullWidth
            inputProps={{ "aria-label": intl.formatMessage({ id: "lastName" }) }}
            label={intl.formatMessage({ id: "lastName" })}
            onChange={(e) => setUserLastName(e.target.value)}
            required
            value={userLastName}
            variant="filled"
          />
          <TextField
            InputLabelProps={{ htmlFor: intl.formatMessage({ id: "email" }), shrink: true }}
            error={!!userEmail.trim() && validateEmail()}
            fullWidth
            helperText={!!userEmail.trim() && validateEmail() ? intl.formatMessage({ id: "enterValidEmail" }) : ""}
            inputProps={{ "aria-label": intl.formatMessage({ id: "email" }) }}
            label={intl.formatMessage({ id: "email" })}
            onChange={(e) => setUserEmail(e.target.value)}
            required
            value={userEmail}
            variant="filled"
          />

          <PrincipalMultipleSelect
            allPrincipals={userGroups}
            category="groups"
            selectedPrincipals={selectedUserGroups as IUserGroup[]}
            setSelectedPrincipals={(groups) => setSelectedUserGroups(groups as IUserGroup[])}
          />
          <DialogActions>
            <Button onClick={() => handleClose({ id: null, status: "CLOSED" })} variant="text">
              {intl.formatMessage({ id: "cancel" })}
            </Button>
            <Button
              color="primary"
              disabled={validateForm()}
              form="userGroupForm"
              size="small"
              type="submit"
              variant="contained"
            >
              {selectedUser ? intl.formatMessage({ id: "save" }) : intl.formatMessage({ id: "create" })}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UserModal;
