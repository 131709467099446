import * as React from "react";
import { ITranslation } from "../../../../intl/types";
import ErrorBoundary from "../../../../components/ErrorBoundary";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import { useIntl } from "react-intl";

export type IChartType = "forecast" | "default";

export interface IStorageChartCardProps {
  title: keyof ITranslation;
  subHeader?: string;
}

const useStyles = makeStyles(() => ({
  card: {
    overflow: "visible",
  },
  cardContent: {
    display: "grid",
    gridTemplateRows: "250px",
    position: "relative",
  },
}));


const StorageChartCard: React.FC<IStorageChartCardProps> = ({ subHeader, title, children }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Card className={classes.card}>
      <CardHeader subheader={subHeader} title={intl.formatMessage({ id: title })} />
      <CardContent className={classes.cardContent}>
        <ErrorBoundary variant="chart">{children}</ErrorBoundary>
      </CardContent>
    </Card>
  );
};

export default StorageChartCard;
