import { Location } from "history";
import { DateTime } from "luxon";
import memoizeOne, { EqualityFn } from "memoize-one";
import * as React from "react";
import { matchPath, RouteComponentProps, withRouter } from "react-router-dom";
import { DatePreset, IDateContext, IDateRange } from "./types";

const enum Feature {
  Alerts = "alerts",
  Blocking = "blocking",
  DashboardDigest = "dashboards",
  CustomDashboard = "dashboards-id",
  Deadlocks = "deadlocks",
  /** @deprecated */
  Dashboard = "dashboard",
  Health = "health",
  PerformanceAnalysis = "performance",
  Storage = "storage",
  TempDb = "tempdb",
  TopSql = "topsql",
}

interface IDateProviderState {
  /**
   * Holds a random value generated on each live mode interval run to force memoization cache to bust.
   */
  intervalRandomizer?: number;
}

type IDateProviderProps = RouteComponentProps;

/**
 * Creates a new date range ending at the current time using the specified preset to determine the start time.
 * @param preset The preset to retrieve a new date range for.
 */
function getDateRangeForPreset(preset: DatePreset): IDateRange {
  const now = DateTime.local();
  const to = now.toJSDate();
  switch (preset) {
    case DatePreset.LastDay:
      return {
        from: now.minus({ day: 1 }).toJSDate(),
        to,
      };
    case DatePreset.Last4Hours:
      return {
        from: now.minus({ hour: 4 }).toJSDate(),
        to,
      };
    case DatePreset.Last8Hours:
      return {
        from: now.minus({ hour: 8 }).toJSDate(),
        to,
      };
    case DatePreset.LastHour:
      return {
        from: now.minus({ hour: 1 }).toJSDate(),
        to,
      };
    case DatePreset.LastMonth:
      return {
        from: now.minus({ month: 1 }).toJSDate(),
        to,
      };
    case DatePreset.LastWeek:
      return {
        from: now.minus({ week: 1 }).toJSDate(),
        to,
      };
    default:
      throw Error(`DatePreset ${preset} not supported by getDateRange`);
  }
}

function getDefaultLiveDateRange(): IDateRange {
  const now = DateTime.local();
  return {
    // TODO: Move this to a configuration context provider - PBI 50193
    from: now.minus({ hour: 1 }).toJSDate(),
    to: now.toJSDate(),
  };
}

// TODO: Move this to a configuration context provider - PBI 50193
const AUTO_REFRESH_INTERVAL = 90000;
const FROM_PARAM = "from";
const TO_PARAM = "to";

/**
 * Extracts a preset and date range from a URL search (query) param string.
 * Returns null if the URL does not contain valid dates.
 * @param location The current route location.
 */
function parseSearchParams(location: Location): IDateRange | null {
  const params = new URLSearchParams(location.search);
  const fromParam = params.get(FROM_PARAM);
  const toParam = params.get(TO_PARAM);
  if (fromParam && toParam) {
    try {
      return {
        from: new Date(fromParam),
        to: new Date(toParam),
      };
    } catch {
      // URL contained values but they were invalid dates
      // TODO: Report this to the user - PBI 50192
    }
  }
  return null;
}

export function getFeatureFromRoute(path: string): string {
  const targetMatch = matchPath<{ feature: string }>(path, {
    path: "/:tenant/targets/:targetId/:feature/(.*)*",
  });
  const globalMatch = matchPath<{ feature: string; id?: string }>(path, {
    path: "/:tenant/:feature/:id?",
  });
  if (targetMatch) return targetMatch.params.feature;
  else if (globalMatch) {
    if (globalMatch.params.feature === "dashboards")
      return globalMatch.params.id ? Feature.CustomDashboard : Feature.DashboardDigest;
    else return globalMatch.params.feature;
  } else return "";
}

/**
 * Extracts a preset and date range from a URL search (query) param string.
 * Returns live mode date range if the URL does not contain valid dates.
 * @param location The current route location.
 */
function parseLocation(
  location: Location,
): {
  allowDateNavigation: boolean;
  autoRefreshSupported: boolean;
  dateRange: IDateRange;
  liveEnabled: boolean;
} {
  const paramDateRange: IDateRange | null = parseSearchParams(location);
  const feature = getFeatureFromRoute(location.pathname).toLowerCase();
  const now = DateTime.local();
  switch (feature) {
    case Feature.Blocking:
      // Blocking uses URL params and defaults to 1 hour live with auto refresh
      return {
        allowDateNavigation: true,
        autoRefreshSupported: false,
        dateRange: paramDateRange || {
          from: now.minus({ day: 7 }).toJSDate(),
          to: now.toJSDate(),
        },
        liveEnabled: false,
      };
    case Feature.Deadlocks:
      // Deadlocks uses URL params and defaults to last 7 days static
      return {
        allowDateNavigation: true,
        autoRefreshSupported: false,
        dateRange: paramDateRange || {
          from: now.minus({ day: 7 }).toJSDate(),
          to: now.toJSDate(),
        },
        liveEnabled: false,
      };
    case Feature.Health:
      // Overview is always last 5 days without auto refresh
      return {
        allowDateNavigation: true,
        autoRefreshSupported: false,
        dateRange: paramDateRange || {
          // TODO: Move this to a configuration context provider - PBI 50193
          from: now.minus({ hour: 24 }).toJSDate(),
          to: now.toJSDate(),
        },
        liveEnabled: false,
      };
    case Feature.TempDb:
    case Feature.TopSql:
      // Top SQL and Temp DB uses URL params and defaults to 1 hour live without auto refresh
      return {
        allowDateNavigation: true,
        autoRefreshSupported: false,
        dateRange: paramDateRange || {
          // TODO: Move this to a configuration context provider - PBI 50193
          from: now.minus({ hour: 1 }).toJSDate(),
          to: now.toJSDate(),
        },
        liveEnabled: false,
      };
    case Feature.Dashboard:
      // Dashboard uses URL params and defaults to 30 minutes live with auto refresh
      return {
        allowDateNavigation: true,
        autoRefreshSupported: true,
        dateRange: paramDateRange || {
          // TODO: Move this to a configuration context provider - PBI 50193
          from: now.minus({ minute: 30 }).toJSDate(),
          to: now.toJSDate(),
        },
        liveEnabled: !paramDateRange,
      };
    case Feature.Alerts:
      // Alerts uses URL params and defaults to 1 hour live with auto refresh
      return {
        allowDateNavigation: true,
        autoRefreshSupported: true,
        dateRange: paramDateRange || {
          // TODO: Move this to a configuration context provider - PBI 50193
          from: now.minus({ hour: 1 }).toJSDate(),
          to: now.toJSDate(),
        },
        liveEnabled: false,
      };
    case Feature.CustomDashboard:
    case Feature.PerformanceAnalysis:
      // Performance Analysis and Custom Dashboards use URL params and default to 30 minutes live with auto refresh
      return {
        allowDateNavigation: true,
        autoRefreshSupported: true,
        dateRange: paramDateRange || {
          // TODO: Move this to a configuration context provider - PBI 50193
          from: now.minus({ minute: 30 }).toJSDate(),
          to: now.toJSDate(),
        },
        liveEnabled: !paramDateRange,
      };
    case Feature.Storage:
      // Storage use URL params and default to 5 minutes live with auto refresh
      return {
        allowDateNavigation: true,
        autoRefreshSupported: true,
        dateRange: paramDateRange || {
          // TODO: Move this to a configuration context provider - PBI 50193
          from: now.minus({ minute: 5 }).toJSDate(),
          to: now.toJSDate(),
        },
        liveEnabled: !paramDateRange,
      };
    case Feature.DashboardDigest:
      // Dashboard Digest does not use URL params and uses the default live date range if there is no date range already set
      return {
        allowDateNavigation: false,
        autoRefreshSupported: false,
        dateRange: paramDateRange || getDefaultLiveDateRange(),
        liveEnabled: false,
      };
    default:
      return {
        allowDateNavigation: true,
        autoRefreshSupported: true,
        dateRange: getDefaultLiveDateRange(),
        liveEnabled: true,
      };
  }
}

// Create the React Context with the default value.
// This will be immediately replaced when DateProvider is rendered.
const DateContext = React.createContext<IDateContext>({
  allowDateNavigation: true,
  autoRefreshSupported: true,
  dateRange: getDefaultLiveDateRange(),
  getUrlWithDateRange: (path: string, dateRange: IDateRange | null): string => {
    if (!dateRange) {
      return path;
    }
    const params = new URLSearchParams();
    params.set(FROM_PARAM, dateRange.from.toISOString());
    params.set(TO_PARAM, dateRange.to.toISOString());
    return `${path}?${params}`;
  },
  liveEnabled: true,
  setDateRange: () => undefined,
  setPreset: () => undefined,
  switchToLive: () => undefined,
});

/**
 * Returns true if the memoized value can be reused, false otherwise.
 * @param param0 The arguments to the last getValue execution.
 * @param param1 The arguments to the new getValue exection.
 */
function isMemoizedGetValueValid([a1, a2]: [Location<any>, number], [b1, b2]: [Location<any>, number]): boolean {
  return a1.search === b1.search && a1.pathname === b1.pathname && a2 === b2;
}

class DateProvider extends React.Component<IDateProviderProps, IDateProviderState> {
  public state: IDateProviderState = {};

  private readonly memoizedGetValue = memoizeOne(this.getValue, isMemoizedGetValueValid as EqualityFn);
  private autoRefreshInterval: number | undefined;

  public componentDidMount(): void {
    const { liveEnabled } = parseLocation(this.props.location);
    if (liveEnabled) {
      this.startLiveMode();
    }
  }

  public componentDidUpdate(prevProps: IDateProviderProps): void {
    if (
      this.props.location.search !== prevProps.location.search ||
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      const { liveEnabled } = parseLocation(this.props.location);
      if (liveEnabled) {
        this.startLiveMode();
      } else {
        this.stopLiveMode();
      }
    }
  }

  public componentWillUnmount(): void {
    this.stopLiveMode();
  }

  public render(): React.ReactElement {
    const { children, location } = this.props;
    const { intervalRandomizer } = this.state;
    const value = this.memoizedGetValue(location, intervalRandomizer || 0);
    return <DateContext.Provider value={value}>{children}</DateContext.Provider>;
  }

  /**
   * Creates an IDateContext from the history location.
   * @param location The history location to extract parameters from.
   * @param intervalRandomizer A no-op number used to bust memoization on interval triggers.
   */
  private getValue(location: Location<any>, _: number): IDateContext {
    const { allowDateNavigation, autoRefreshSupported, dateRange, liveEnabled } = parseLocation(location);

    return {
      allowDateNavigation,
      autoRefreshSupported,
      dateRange,
      getUrlWithDateRange: this.getUrlWithDateRange,
      liveEnabled,
      setDateRange: this.setDateRange,
      setPreset: this.setPreset,
      switchToLive: this.switchToLive,
    };
  }

  /**
   * Updates the URL search (query) parameters with from and to values from a date range.
   * Pushing a new URL causes a new render since this component is connected by withRouter.
   */
  private navigateToDateRange(dateRange: IDateRange): void {
    const { history, location } = this.props;
    const params = new URLSearchParams(location.search);
    params.set(FROM_PARAM, dateRange.from.toISOString());
    params.set(TO_PARAM, dateRange.to.toISOString());
    history.push({
      pathname: location.pathname,
      search: `?${params}`,
    });
  }

  private setPreset = (preset: DatePreset): void => {
    const dateRange = getDateRangeForPreset(preset);
    this.setDateRange(dateRange);
  };

  private setDateRange = (dateRange: IDateRange): void => {
    this.stopLiveMode();
    this.navigateToDateRange(dateRange);
  };

  private getUrlWithDateRange = (path: string, dateRange: IDateRange | null): string => {
    const { location } = this.props;
    if (!dateRange) {
      return path;
    }
    const params = new URLSearchParams(location.search);
    params.set(FROM_PARAM, dateRange.from.toISOString());
    params.set(TO_PARAM, dateRange.to.toISOString());
    return `${path}?${params}`;
  };

  private startLiveMode(): void {
    // Stop any current intervals to prevent race conditions
    this.stopLiveMode();

    this.autoRefreshInterval = window.setInterval(() => {
      // Update the intervalRandomizer state key to trigger a re-render
      this.setState((prev) => {
        let intervalRandomizer: number;
        do {
          intervalRandomizer = Math.random();
          // Don't trust the browser's Math.random
        } while (intervalRandomizer === prev.intervalRandomizer);
        return { intervalRandomizer };
      });
    }, AUTO_REFRESH_INTERVAL);
  }

  private stopLiveMode(): void {
    if (typeof this.autoRefreshInterval === "number") {
      clearInterval(this.autoRefreshInterval);
      this.autoRefreshInterval = undefined;
    }
  }

  /**
   * Switches to live mode by removing URL search (query) parameters for from and to dates.
   * Pushing a new URL causes a new render since this component is connected by withRouter.
   */
  private switchToLive = (): void => {
    const { history, location } = this.props;
    const params = new URLSearchParams(location.search);
    params.delete(FROM_PARAM);
    params.delete(TO_PARAM);
    history.push({
      pathname: location.pathname,
      search: `?${params}`,
    });
    this.setState({
      intervalRandomizer: Math.random(),
    });
    this.startLiveMode();
  };
}

const DateProviderRouted = withRouter(DateProvider);
export { DateProviderRouted as DateProvider };

export default DateContext;
