import * as React from "react";
import { IUserTenant } from "../../../../../../../api/AccountService/AccountService";
import {
  ITopologyItemDevice,
  ITopologyItemGroup,
  TopologyObjectType,
} from "../../../../../../../components/TopologyContext";
import { useUserContext } from "../../../../../../../contexts/userContext";
import { renderTopologyTreeItem, ITreeItem } from "../TopologyTreeItem";
import useTopologyRouteMatch from "../../hooks/useTopologyRouteMatch";
import { ITopologyViewProps } from "../../TopologyList";
import { useLocation } from "react-router";

const TopologyHierarchy = ({ filter, topology }: ITopologyViewProps): React.ReactElement => {
  const { tenants } = useUserContext();
  const { search } = useLocation();
  const { tenantMatch, groupMatch, targetMatch, featureMatch } = useTopologyRouteMatch();

  const buildUrl = React.useCallback(
    (tenantId: string, targetId?: string, groupId?: string) => {
      const urlItems = [tenantId];
      if (targetId) {
        urlItems.push("targets");
        urlItems.push(targetId);
        if (featureMatch?.params.feature) {
          urlItems.push(featureMatch?.params.feature);
        }
      } else if (groupId) {
        urlItems.push("health");
        urlItems.push("groups");
        urlItems.push(groupId);
      }
      return `/${urlItems.join("/")}${search}`;
    },
    [featureMatch?.params.feature, search],
  );

  const tenantHierarchy = React.useMemo<Array<ITreeItem>>(() => {
    const { groups, devices, eventSourceConnections } = topology;
    const sites = groups.filter((group) => group.type === TopologyObjectType.site);

    const getDeviceEsc = (device: ITopologyItemDevice): Array<ITreeItem> => {
      return eventSourceConnections
        .filter((esc) => esc.parentObjectId === device.objectId)
        .map((esc) => ({
          children: null,
          eventSourceConnectionType: esc.eventSourceConnectionType,
          id: esc.objectId,
          isSelected: Boolean(targetMatch && targetMatch.params.targetId === esc.objectId),
          isWatched: esc.isWatched,
          name: esc.name,
          to: buildUrl(esc.tenantId, esc.objectId),
          type: "esc",
        }));
    };

    // parentGroup could be group or site
    const getGroupDevices = (parentGroup: ITopologyItemGroup): Array<ITreeItem> => {
      return devices
        .filter((device) => device.parentObjectId === parentGroup.objectId)
        .map((device) => {
          const deviceChildren = getDeviceEsc(device);

          return deviceChildren.length === 1
            ? deviceChildren[0]
            : {
                children: deviceChildren.length === 0 ? null : deviceChildren,
                deviceType: device.deviceType,
                id: device.objectId,
                isSelected: Boolean(targetMatch && targetMatch.params.targetId === device.objectId),
                isWatched: device.isWatched,
                name: device.name,
                to: buildUrl(device.tenantId, device.objectId),
                type: "device",
              };
        });
    };

    // parentGroup could be group or site
    const getGroupGroups = (parentGroup: ITopologyItemGroup): Array<ITreeItem> => {
      return groups
        .filter((group) => group.parentObjectId === parentGroup.objectId)
        .map((group) => ({
          children: [...getGroupGroups(group), ...getGroupDevices(group)],
          id: group.objectId,
          isSelected: Boolean(groupMatch && groupMatch.params.groupId === group.objectId),
          name: group.name,
          to: buildUrl(group.tenantId, undefined, group.objectId),
          type: "group",
        }));
    };

    const getTenantSites = (tenant: IUserTenant): Array<ITreeItem> => {
      return sites
        .filter((site) => site.tenantId === tenant.id)
        .map((site) => ({
          children: [...getGroupGroups(site), ...getGroupDevices(site)],
          id: site.objectId,
          isSelected: Boolean(groupMatch && groupMatch.params.groupId === site.objectId),
          name: site.name,
          to: buildUrl(site.tenantId, undefined, site.objectId),
          type: "site",
        }));
    };

    return tenants.map((tenant) => {
      return {
        children: getTenantSites(tenant),
        hasErrors: tenant.state === "failedToConnect" || topology.tenantErrors.has(tenant.id),
        id: tenant.id,
        isParentOfSelectedItem: Boolean(
          tenantMatch && (groupMatch || targetMatch) && tenantMatch.params.tenantId === tenant.id,
        ),
        isSelected: Boolean(!targetMatch && !groupMatch && tenantMatch && tenantMatch.params.tenantId === tenant.id),
        name: tenant.name,
        to: `/${tenant.id}`,
        type: "tenant",
      };
    });
  }, [tenants, topology, tenantMatch, groupMatch, targetMatch, buildUrl]);

  return <>{tenantHierarchy.map((tenantItem) => renderTopologyTreeItem(tenantItem, filter))}</>;
};

export default TopologyHierarchy;
