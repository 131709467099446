import * as React from "react";
import {
  ITopologyItem,
  ITopologyItemDevice,
  ITopologyItemEventSourceConnection,
  TopologyDeviceType,
  TopologyEventSourceConnectionType,
} from "../../../../../components/TopologyContext";
import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import TargetIcon from "../../../../../components/icons/TargetIcon";
import { Tooltip } from "@material-ui/core";

export interface ITargetAutocompleteProps {
  activeTarget: ITopologyItem | null;
  targets: Array<ITopologyItemDevice | ITopologyItemEventSourceConnection>;
  handleTargetChange: (target: ITopologyItemDevice | ITopologyItemEventSourceConnection) => void;
  isDisabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
  hiddenLabel: {},
  inputLabel: {
    textTransform: "uppercase",
  },
  targetIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  targetIconFilled: {
    "&$targetIconPositionStart:not($hiddenLabel)": {
      margin: theme.spacing(0, 1),
    },
  },
  targetIconPositionStart: {},
}));

const TargetAutocomplete: React.FC<ITargetAutocompleteProps> = ({
  activeTarget,
  targets,
  handleTargetChange,
  isDisabled,
}) => {
  const classes = useStyles();

  const getTargetType = (
    target: ITopologyItemDevice | ITopologyItemEventSourceConnection,
  ): TopologyDeviceType | TopologyEventSourceConnectionType =>
    "deviceType" in target ? target.deviceType : target.eventSourceConnectionType;

  return (
    <Autocomplete
      data-testid="targetSelect"
      disableClearable
      disabled={isDisabled}
      getOptionLabel={(option) => option.name}
      id="targetSelect"
      onChange={(_, value: ITopologyItemDevice | ITopologyItemEventSourceConnection) => handleTargetChange(value)}
      options={targets}
      renderInput={(params) => (
        <Tooltip title={activeTarget?.name || ""}>
          <TextField
            {...params}
            InputLabelProps={{
              ...params.InputLabelProps,
              className: classes.inputLabel,
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment
                  classes={{
                    filled: classes.targetIconFilled,
                    hiddenLabel: classes.hiddenLabel,
                    positionStart: classes.targetIconPositionStart,
                  }}
                  position="start"
                >
                  <TargetIcon
                    activeTargetType={getTargetType(
                      activeTarget as ITopologyItemDevice | ITopologyItemEventSourceConnection,
                    )}
                    fontSize="small"
                    sizeIcon="medium"
                  />
                </InputAdornment>
              ),
            }}
            error={!targets}
            name="target"
            required
            variant="standard"
          />
        </Tooltip>
      )}
      renderOption={(option) => (
        <>
          <TargetIcon
            activeTargetType={getTargetType(option)}
            className={classes.targetIcon}
            fontSize="small"
            sizeIcon="medium"
          />
          {option.name}
        </>
      )}
      value={activeTarget as ITopologyItemDevice | ITopologyItemEventSourceConnection}
    />
  );
};

export default TargetAutocomplete;
