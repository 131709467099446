import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { CardTable } from "@sentryone/material-ui";
import { SortDescriptor } from "@sentryone/react-datatable";
import classNames from "classnames";
import * as React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { IExecutedQueryTotalsResponse } from "../../../../api/models/ExecutedQueryTotals";
import { styles } from "../../../../components/ThemeProvider/grids";
import { useTopSqlContext } from "../../../../contexts/topSqlContext";
import { useState } from "react";
import ChartFilterChip from "../ChartFilterChip";
import CustomSnackbar from "../../components/CustomSnackbar";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import QueryTooltip from "../QueryTooltip";
import {
  DataTable,
  DataTableFilterMetaData,
  DataTableOperatorFilterMetaData,
  DataTablePFSEvent,
} from "primereact/datatable";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";

import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { useDateContext } from "../../../../components/DateContext";
import { InputText } from "primereact/inputtext";
import { ITopSqlFilterDropDownData, handleSortOrder } from "../TraceEventsGrid/TraceEventsGrid";
import { MultiSelect } from "primereact/multiselect";
import { FilterMatchMode } from "primereact/api";
import { GRID_PAGINATIONROW_OPTIONS } from "../../../../utilities/PrimeReactUtility";
import { Button as PrimereactButton } from "primereact/button";
import { ON_PAGE, ON_SORT, ON_SEARCH, DEFAULT_PAGE, CLEAR_FILTER } from "../../../../contexts/topSqlContext/types";
import { ITopologyItemDevice, ITopologyItemEventSourceConnection } from "../../../../components/TopologyContext";
import WaitsByTraceModal from "../WaitsByTraceChart/WaitsByTraceModal";

export interface ITotalsGridProps {
  className?: string;
  onHandleSort: (sort: readonly SortDescriptor[]) => void;
  sort: readonly SortDescriptor[];
  style?: React.CSSProperties;
  target: ITopologyItemEventSourceConnection | undefined;
  topsqlFilterDropDownData: ITopSqlFilterDropDownData;
}

export interface ILazyParams {
  filters?: any;
  first: number;
  page: number;
  rows: number;
  searchKey: string;
  sortField: string;
  sortOrder: 1 | 0 | -1 | undefined | null;
}

interface Ievent extends DataTablePFSEvent {
  searchKey?: string;
}

function instanceOfFilter(
  object: DataTableFilterMetaData | DataTableOperatorFilterMetaData,
): object is DataTableOperatorFilterMetaData {
  return "constraints" in object;
}

function reducer(state: ILazyParams, action: { type: string; event?: DataTablePFSEvent }): ILazyParams {
  switch (action.type) {
    case ON_PAGE:
      return {
        ...state,
        first: action.event?.first ?? state.first,
        page: action.event?.page ?? state.page,
        rows: action.event?.rows ?? state.rows,
      };
    case ON_SORT:
      return {
        ...state,
        sortField: action.event?.sortField ?? state.sortField,
        sortOrder: action.event && handleSortOrder(state, action.event),
      };
    case ON_SEARCH:
      return {
        ...state,
        searchKey: action.event ? action.event.toString() : "",
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filters: {
          databaseName: {
            constraints: [
              {
                matchMode: FilterMatchMode.STARTS_WITH,
                value: null,
              },
            ],
            operator: "and",
          },
        },
        first: 0,
        page: 1,
        searchKey: "",
        sortField: "readsL",
        sortOrder: -1,
      };
    case DEFAULT_PAGE:
      return {
        ...state,
        first: 0,
        page: 1,
      };
    default:
      return {
        filters: {},
        first: 0,
        page: 1,
        rows: GRID_PAGINATIONROW_OPTIONS[0],
        searchKey: "",
        sortField: "readsL",
        sortOrder: -1,
      };
  }
}

const useStyles = makeStyles({
  ...styles,
  filterDropDown: {
    width: "17em",
  },
});

const TotalsGrid = React.forwardRef<HTMLElement, ITotalsGridProps>(
  ({ className, onHandleSort, sort, style = {}, target, topsqlFilterDropDownData, ...props }, ref) => {
    const classes = useStyles({});
    const timeoutId = React.useRef<ReturnType<typeof setInterval>>();
    const { dateRange } = useDateContext();
    const {
      chartFilter,
      handleChangeChartFilter,
      handleTotalsGridPageChange,
      handleSelectedTotalChange,
      handleTotalsEventsClick,
      handleSearchTotals,
      handleTotalsFilter,
      searchKeyword,
      totalsResults,
    } = useTopSqlContext();
    const { data, isLoading, pageLoading, totalCount } = totalsResults;
    const [open, setOpen] = useState(false);
    const [keywordSeach, setKeywordSearch] = React.useState(searchKeyword ?? "");
    const [isSearchDisabled, setIsSearchDisabled] = React.useState(true);
    const [isClearBtnDisabled, setIsClearBtnDisabled] = React.useState(true);
    const [state, dispatch] = React.useReducer(reducer, {
      filters: {
        databaseName: {
          constraints: [
            {
              matchMode: FilterMatchMode.STARTS_WITH,
              value: null,
            },
          ],
          operator: "and",
        },
      },
      first: 0,
      page: 1,
      rows: GRID_PAGINATIONROW_OPTIONS[0],
      searchKey: "",
      sortField: "readsL",
      sortOrder: -1,
    });

    const copyQuery = (d: IExecutedQueryTotalsResponse | undefined): void => {
      navigator.clipboard?.writeText(d?.textData ?? "");
      setOpen(true);
    };

    const handleClose = (): void => {
      setOpen(false);
    };

    const onPage = (event: DataTablePFSEvent): void => {
      dispatch({ event, type: ON_PAGE });
      const page = event.first > -1 ? event.first : 5;
      const rows = event.rows === state.rows ? state.rows : event.rows;
      handleTotalsGridPageChange(page, rows);
    };

    const onSort = (event: DataTablePFSEvent): void => {
      const sortOrder = handleSortOrder(state, event);
      onHandleSort([
        {
          direction: sortOrder && sortOrder > 0 ? "asc" : "desc",
          id: event.sortField,
        },
      ]);
      dispatch({ event, type: ON_SORT });
    };

    const onSearch = React.useCallback(
      (event): void => {
        if (timeoutId?.current) clearTimeout(timeoutId?.current);
        const searchKeyword = event.target.value;
        setKeywordSearch(searchKeyword);
        setIsClearBtnDisabled(false);
        timeoutId.current = setTimeout(() => {
          setKeywordSearch(searchKeyword.trim());
          handleSearchTotals({ ...state.filters, searchKeyword: searchKeyword.trim() });
          handleTotalsGridPageChange(0, state.rows);
          dispatch({ type: DEFAULT_PAGE });
          return dispatch({ event: searchKeyword.trim(), type: ON_SEARCH });
        }, 1000);
      },
      [handleSearchTotals, state.filters, handleTotalsGridPageChange, state.rows],
    );
    const clearFilter = (): void => {
      handleSearchTotals({ searchKeyword: "" });
      handleTotalsFilter({ totalsDatabase: null });
      onHandleSort([
        {
          direction: "desc",
          id: "readsL",
        },
      ]);
      handleTotalsGridPageChange(0, GRID_PAGINATIONROW_OPTIONS[0]);
      setIsClearBtnDisabled(true);
      dispatch({ type: CLEAR_FILTER });
    };

    const renderHeader = (): JSX.Element => {
      return (
        <div className="flex justify-content-between">
          <PrimereactButton
            className="p-button-contained"
            data-testid="clear-all-btn"
            disabled={isClearBtnDisabled}
            icon="pi pi-filter-slash"
            label="Clear"
            onClick={clearFilter}
            type="button"
          />
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              autoComplete="off"
              disabled={isSearchDisabled}
              name="searchKey"
              onChange={(e) => {
                e.persist();
                onSearch(e);
              }}
              placeholder="Search"
              type="search"
              value={keywordSeach}
            />
          </span>
        </div>
      );
    };

    const header = renderHeader();

    const memoizedCallback = React.useCallback(() => handleTotalsGridPageChange(0, state.rows), [
      handleTotalsGridPageChange,
      state.rows,
    ]);

    React.useEffect(
      () => {
        memoizedCallback();
      },
      /*  Added the dateRange in the deps array as it resets the totals grid properly when the date range is updated */
      [dateRange, memoizedCallback],
    );

    React.useEffect(() => {
      setIsSearchDisabled(isLoading);
      setKeywordSearch(searchKeyword ?? "");
    }, [isLoading, searchKeyword]);

    const databaseNameFilterTemplate = (options: ColumnFilterElementTemplateOptions): JSX.Element => {
      return (
        <MultiSelect
          className={classes.filterDropDown}
          data-testid="databaseName-options"
          onChange={(e) => options.filterCallback(e.value, options.index)}
          options={topsqlFilterDropDownData.databaseNames.map((action) => action.name)}
          placeholder="Select Database"
          showClear
          value={options.value}
        />
      );
    };

    const filterApplyTemplate = (options: ColumnFilterElementTemplateOptions): JSX.Element => {
      return (
        <PrimereactButton data-testid="apply-btn" onClick={options.filterApplyCallback} type="button">
          <FormattedMessage id="apply" />
        </PrimereactButton>
      );
    };
    const onFilter = async (event: DataTablePFSEvent): Promise<void> => {
      let databaseNameFilter: string[] = [];
      Object.keys(event.filters).map((key) => {
        let filterEnum: Array<{ id: string | number; name: string }> = topsqlFilterDropDownData.databaseNames;
        let arrayToPush: Array<string | number> = [];
        const dataObj = event.filters[key];
        switch (key) {
          case "databaseName":
            filterEnum = topsqlFilterDropDownData.databaseNames;
            arrayToPush = databaseNameFilter;
            databaseNameFilter = instanceOfFilter(dataObj) && dataObj?.constraints[0]?.value;
            break;
        }
        if (instanceOfFilter(dataObj) && dataObj.constraints[0]?.value) {
          for (const value of dataObj.constraints[0]?.value) {
            arrayToPush.push(
              filterEnum.filter((raw) => {
                if (raw.name === value) return raw.id;
              })[0]?.id,
            );
          }
        }
      });
      handleTotalsGridPageChange(0, state.rows);
      dispatch({ type: DEFAULT_PAGE });
      handleTotalsFilter({
        ...state.filters,
        totalsDatabase: databaseNameFilter,
      });
      setIsClearBtnDisabled(false);
    };

    return (
      <div className={className} ref={ref as React.Ref<HTMLDivElement>}>
        <CustomSnackbar
          animation="Slide"
          aria-label="custom-snackbar"
          autoHideDuration={2000}
          handleClose={handleClose}
          icon={<FileCopyIcon />}
          message="Query copied successfully!"
          open={open}
          slideDirection="right"
        />
        <CardTable
          actions={
            <>{chartFilter && <ChartFilterChip onRemove={() => handleChangeChartFilter(null)} value={chartFilter} />}</>
          }
          className={className}
          style={style}
          title={<FormattedMessage id="totals" />}
          {...props}
        >
          <DataTable
            columnResizeMode="expand"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
            dataKey="textData"
            filterDisplay="menu"
            filters={state.filters}
            first={state.first}
            header={header}
            lazy
            loading={pageLoading || isLoading}
            onFilter={onFilter}
            onPage={onPage}
            onSelectionChange={(e) => handleSelectedTotalChange(e.value)}
            onSort={onSort}
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            resizableColumns
            responsiveLayout="scroll"
            rows={state.rows}
            rowsPerPageOptions={GRID_PAGINATIONROW_OPTIONS}
            selectionMode="single"
            size="small"
            sortField={state.sortField}
            sortOrder={state.sortOrder}
            totalRecords={totalCount}
            value={[...data]}
          >
            <Column
              align="left"
              body={(d) => {
                if (d.eventCount > 0) {
                  return (
                    <FormattedMessage id="showTraceEvents">
                      {(message: string) => (
                        <Button
                          className={classNames(classes.events, "telemetry-topSql-showTraceEvents")}
                          color="primary"
                          onClick={(e) => {
                            handleTotalsEventsClick(d);
                          }}
                          title={message}
                          variant="text"
                        >
                          <FormattedNumber value={d.eventCount} />
                        </Button>
                      )}
                    </FormattedMessage>
                  );
                } else {
                  return (
                    <Button className={classes.events} color="primary" disabled variant="text">
                      <FormattedNumber value={d.eventCount} />
                    </Button>
                  );
                }
              }}
              field="eventCount"
              header={<FormattedMessage id="events" />}
              sortable
            />
            <Column
              body={(d) => {
                return navigator.clipboard ? (
                  <div className={classes.copybtn}>
                    <QueryTooltip placement="top-start" title={d.textData.replace(/\t/g, "  ")}>
                      <span className={classes.queryWrap}>{d.textData.replace(/\t/g, "  ")}</span>
                    </QueryTooltip>
                    <FileCopyOutlinedIcon
                      aria-label="copy-btn"
                      className={classes.cursorPointer}
                      onClick={() => copyQuery(d)}
                    />
                  </div>
                ) : (
                  <QueryTooltip placement="top-start" title={d.textData.replace(/\t/g, "  ")}>
                    <span>{d.textData.replace(/\t/g, "  ")}</span>
                  </QueryTooltip>
                );
              }}
              className={classes.noWrap}
              field="textData"
              header={<FormattedMessage id="textData" />}
              sortable
              style={{ minWidth: "12rem" }}
            />
            <Column
              className={classes.noWrap}
              field="databaseName"
              filter
              filterApply={filterApplyTemplate}
              filterElement={databaseNameFilterTemplate}
              header={<FormattedMessage id="database" />}
              showAddButton={false}
              showFilterMatchModes={false}
              showFilterOperator={false}
              sortable
            />
            <Column field="duration" header={<FormattedMessage id="duration" />} sortable />
            <Column field="execCount" header={<FormattedMessage id="count" />} sortable />
            <Column field="cpu" header={<FormattedMessage id="cpuMs" />} sortable />
            <Column field="readsL" header={<FormattedMessage id="readsL" />} sortable />
            <Column field="writesL" header={<FormattedMessage id="writesL" />} sortable />
            <Column
              body={(d) =>
                d.waitMS ? (
                  <WaitsByTraceModal
                    target={target as ITopologyItemEventSourceConnection | ITopologyItemDevice}
                    totals_row={d}
                    visibleDates={dateRange}
                    wait_ms={d.waitMS}
                  ></WaitsByTraceModal>
                ) : (
                  0
                )
              }
              field="waitMS"
              header={<FormattedMessage id="waitsMS" />}
              sortable
            />
            <Column field="grantedMemoryKB" header={<FormattedMessage id="grantedMemoryKB" />} sortable />
            <Column field="grantedQueryMemoryKB" header={<FormattedMessage id="grantedQueryMemoryKB" />} sortable />
            <Column field="requestedMemoryKB" header={<FormattedMessage id="requestedMemoryKB" />} sortable />
            <Column field="sessionMemoryKB" header={<FormattedMessage id="sessionMemoryKB" />} sortable />
            <Column field="tempdbInternalKB" header={<FormattedMessage id="tempdbInternalKB" />} sortable />
            <Column
              field="tempdbInternalKBDealloc"
              header={<FormattedMessage id="tempdbInternalKBDealloc" />}
              sortable
            />
            <Column field="tempdbUserKB" header={<FormattedMessage id="tempdbUserKB" />} sortable />
            <Column field="tempdbUserKBDealloc" header={<FormattedMessage id="tempdbUserKBDealloc" />} sortable />
          </DataTable>
        </CardTable>
      </div>
    );
  },
);

export default TotalsGrid;
