import { useQuery } from "@apollo/react-hooks";
import { useMemo } from "react";
import { ITopologyItemDevice, ITopologyItemEventSourceConnection } from "../../../../components/TopologyContext";
import { MoRef } from "../../../../utilities/TopologyUtility";
import type { IAsyncResult } from "../../types";
import * as GET_FORECAST_FOR_LOGICAL_VOLUME from "./getForecastForLogicalVolume.graphql";

export interface ILogicalVolumeForecastData {
  forecastTimestamp: string;
  forecastType: "STANDARD" | "ADVANCED";
  forecastValue: number;
  lowerBound: number;
  upperBound: number;
}

export function useForecastData(
  target: ITopologyItemDevice | ITopologyItemEventSourceConnection,
  volumeId: string,
): IAsyncResult<Array<ILogicalVolumeForecastData>> {
  const moRef = MoRef.fromTopologyItem(target).toString();
  const { data = { target: { storage: { entity: { forecast: [] } } } }, error, loading } = useQuery<{
    target: {
      storage: {
        entity: {
          forecast: Array<ILogicalVolumeForecastData>;
        };
      };
    };
  }>(GET_FORECAST_FOR_LOGICAL_VOLUME, { variables: { moRef: moRef.toString(), volumeId: volumeId } });
  return useMemo(() => {
    if (error) {
      return {
        data: undefined,
        error: error,
        isLoading: false,
        state: "error",
      };
    } else if (loading) {
      return {
        data: undefined,
        error: null,
        isLoading: true,
        state: "loading",
      };
    } else {
      return {
        data: data.target.storage.entity.forecast,
        error: null,
        isLoading: false,
        state: "success",
      };
    }
  }, [data, loading, error]);
}
