import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { pd } from "pretty-data";
import * as prismjs from "prismjs";
import "prismjs/components/prism-markup";
import * as React from "react";
import { useIntl } from "react-intl";
import NoDataIndicator from "../../../../components/NoDataIndicator";
import { useDeadlocksContext } from "../../../../contexts/deadlocksContext";
const { useEffect, useMemo, useState } = React;

const useStyles = makeStyles((theme) => ({
  copyButton: {},
  root: {
    "& $copyButton": {
      position: "absolute",
      right: theme.spacing(4),
      top: theme.spacing(),
    },
    "& code": {
      fontSize: theme.typography.queryText.fontSize,
      fontWeight: theme.typography.queryText.fontWeight,
      letterSpacing: theme.typography.queryText.letterSpacing,
    },
    "& pre": {
      height: "100%",
      margin: 0,
      overflow: "auto",
      padding: theme.spacing(),
      width: "100%",
    },
    overflow: "hidden",
    position: "relative",
  },
}));

const DeadlockXml: React.FC = () => {
  const intl = useIntl();
  const { selectedDeadlock } = useDeadlocksContext();
  const classes = useStyles({});
  const codeRef = React.useRef<HTMLElement>(null);
  //deadlockXML constant created to remove complex dependency from prettyXML dependency array
  const deadlockXml = selectedDeadlock?.deadlockXml;
  const prettyXML = useMemo(() => pd.xml(deadlockXml || ""), [deadlockXml]);
  const [copyButtonText, setCopyButtonText] = useState({
    text: intl.formatMessage({ id: "copy" }),
    type: "",
  });

  useEffect(() => {
    setCopyButtonText({
      text: intl.formatMessage({ id: "copy" }),
      type: "primary",
    });
  }, [intl, prettyXML]);

  // This segment of code will be used if we ever add a download button
  // useEffect(() => {
  //   const blob = new Blob([prettyXML], { type: "text/xml" });
  //   const xml = URL.createObjectURL(blob);
  //   return () => URL.revokeObjectURL(xml);
  // }, [prettyXML]);

  useEffect(() => {
    if (codeRef.current) {
      prismjs.highlightElement(codeRef.current);
    }
  });

  function copyText(): void {
    navigator.clipboard.writeText(prettyXML);
    setCopyButtonText({
      text: intl.formatMessage({ id: "copied" }),
      type: "primary",
    });
  }

  if (prettyXML === "") {
    return <NoDataIndicator />;
  }

  return (
    <div className={classes.root}>
      <pre>
        <code className="language-markup" ref={codeRef}>
          {prettyXML}
        </code>
      </pre>
      {navigator.clipboard && (
        <Button className={classes.copyButton} onClick={copyText} size="small" variant="contained">
          {copyButtonText.text}
        </Button>
      )}
    </div>
  );
};

export default DeadlockXml;
