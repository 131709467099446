import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import { SortDescriptor } from "@sentryone/react-datatable";
import { DateTime, Duration } from "luxon";
import * as React from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { NavigationContextTrigger } from "../../../components/NavigationContextMenu";
import { styles as gridStyles } from "../../../components/ThemeProvider/grids";
import { ITopology, useTopology, TopologyObjectType, ITopologyItem } from "../../../components/TopologyContext";
import {
  DataTable,
  DataTablePFSEvent,
  DataTableFilterMeta,
  DataTableOperatorFilterMetaData,
  DataTableFilterMetaData,
  DataTableRowEventParams,
  DataTableExpandedRows,
} from "primereact/datatable";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { FormattedDuration } from "../../../components/DataTable/TimespanColumn/TimespanColumn";
import { Checkbox } from "primereact/checkbox";
import { MultiSelect } from "primereact/multiselect";
import { FilterMatchMode } from "primereact/api";
import { ActionType, ConditionType, Severity, AlertStatus } from "./EventEnums";
import { SERVERS_CHART, CONDITIONS_CHART, TAGS_CHART } from "./Top5ChartEnums";

import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import {
  AlertClosingType,
  useAlertsLogActions,
  useGetAlertsLogQuery,
  IContactDetails,
  useAllContactsList,
  useGetAllTags,
} from "./useAlertsLogActions";
import { IEventLogResponse } from "../../../api/AdvisoryEventsService/AdvisoryEventsService";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ContextMenu } from "primereact/contextmenu";
import { Tooltip } from "primereact/tooltip";
import NotesGrid from "./NotesGrid";
import { Dropdown, DropdownChangeParams, DropdownItemTemplateType } from "primereact/dropdown";
import { v4 as uuid } from "uuid";
import { GRID_PAGINATIONROW_OPTIONS } from "../../../utilities/PrimeReactUtility";
import { PaginatorCurrentPageReportOptions, PaginatorTemplate } from "primereact/paginator";
import useTopologyRouteMatch from "../../../features/Layout/components/Sidebar/TopologyList/hooks/useTopologyRouteMatch";

const useStyles = makeStyles({
  ...gridStyles,
  dataTable: {
    "& .p-datatable-tbody > tr > td .p-row-toggler": {
      "& .pi-chevron-down:before": {
        color: "#2196F3",
        content: "'Add/View Notes'",
        fontWeight: "bold",
      },
      "& .pi-chevron-right:before": {
        color: "#2196F3",
        content: "'Add/View Notes'",
        fontWeight: "bold",
      },
      borderRadius: "unset",
      width: "auto",
    },
  },
  dropDown: {
    "& span.p-dropdown-label.p-inputtext.p-placeholder": {
      padding: "0.4rem",
    },
    width: "12em",
  },
  filterDropDown: {
    width: "17em",
  },
  switch: {
    bottom: 8,
    left: -15,
  },
  switchBase: {
    height: `inherit`,
  },
});

export interface IAdvisoryEventsGridProps {
  className?: string;
  endDate: Date;
  objectAvailableTo?: string[],
  onClearFilter: () => void;
  onRowSelected: (row: IEventLogResponse | null) => void;
  sort?: SortDescriptor[];
  startDate: Date;
  linkPaths?: Array<{ source: string; target: string }>;
  topology?: ITopology;
  style?: React.CSSProperties;
  selectedRow: IEventLogResponse | null;
  selectedChartCell?: any;
}

type filtersDataType = {
  actionFilter: Array<string>;
  conditionsFilter: Array<string>;
  severityFilter: Array<number>;
  closeFilter: Array<number>;
  serverFilter: Array<string>;
  tagsFilter: Array<string>;
};

interface ILazyParams {
  currentPage: number;
  filters: DataTableFilterMeta;
  filtersData: filtersDataType;
  first: number;
  page: number;
  rows: number;
  searchKey: string;
  sortField: string;
  sortIsDescending: boolean;
  startDate?: Date | null;
  endDate?: Date | null;
}

type IPageInfo = {
  currentPage: number;
  first: number;
  rows: number;
};

type IAdvisoryEventsGridAction =
  | { type: "onPage"; event: DataTablePFSEvent }
  | { type: "goTo"; pageInfo: IPageInfo }
  | { type: "onSort"; event: DataTablePFSEvent }
  | { type: "onFilter"; event: DataTablePFSEvent; filtersData: filtersDataType }
  | { type: "clearFilter" }
  | { type: "onSearch"; event: string }
  | { type: "onDateChanged"; startDate: Date; endDate: Date }
  | { type: "onSuccess" };

const initialFilters = {
  filters: {
    actionTypeName: {
      constraints: [
        {
          matchMode: FilterMatchMode.STARTS_WITH,
          value: null,
        },
      ],
      operator: "and",
    },
    conditionType: {
      constraints: [
        {
          matchMode: FilterMatchMode.STARTS_WITH,
          value: null,
        },
      ],
      operator: "and",
    },
    isResolved: {
      constraints: [
        {
          matchMode: FilterMatchMode.EQUALS,
          value: null,
        },
      ],
      operator: "and",
    },
    severity: {
      constraints: [
        {
          matchMode: FilterMatchMode.EQUALS,
          value: null,
        },
      ],
      operator: "and",
    },
    tags: {
      constraints: [
        {
          matchMode: FilterMatchMode.STARTS_WITH,
          value: null,
        },
      ],
      operator: "and",
    },
  },
  searchKey: "",
};

function reducer(state: ILazyParams, action: IAdvisoryEventsGridAction): ILazyParams {
  switch (action.type) {
    case "onPage":
      return {
        ...state,
        currentPage: typeof action.event.page !== "undefined" ? action.event.page + 1 : state.currentPage,
        first: action.event.first ?? state.first,
        page: action.event.page ?? state.page,
        rows: action.event.rows ?? state.rows,
      };
    case "goTo":
      return {
        ...state,
        currentPage: action.pageInfo.currentPage ?? state.currentPage,
        first: action.pageInfo.first ?? state.first,
        rows: action.pageInfo.rows ?? state.rows,
      };
    case "onSort":
      return {
        ...state,
        sortField: action.event.sortField ?? state.sortField,
        sortIsDescending: state.sortField === action.event.sortField ? !state.sortIsDescending : true,
      };
    case "onDateChanged":
      return {
        ...state,
        currentPage: 1,
        endDate: action.endDate,
        first: 0,
        startDate: action.startDate,
      };
    case "onSuccess":
      return {
        ...state,
      };
    case "onFilter":
      return {
        ...state,
        currentPage: 1,
        filters: action.event.filters,
        filtersData: action.filtersData,
        first: 0,
      };
    case "clearFilter":
      return {
        ...state,
        filters: initialFilters.filters,
        filtersData: {
          actionFilter: [],
          closeFilter: [],
          conditionsFilter: [],
          serverFilter: [],
          severityFilter: [],
          tagsFilter: [],
        },
        searchKey: initialFilters.searchKey,
      };
    case "onSearch":
      return {
        ...state,
        searchKey: action.event,
      };
    default:
      return {
        ...state,
      };
  }
}

const AdvisoryEventsGrid: React.FunctionComponent<IAdvisoryEventsGridProps> = ({
  endDate,
  onRowSelected,
  selectedRow,
  startDate,
  linkPaths,
  onClearFilter,
  style = {},
  selectedChartCell,
  sort = [{ direction: "desc", id: "startDateTimeUtc" }],
  objectAvailableTo,
  ...props
}) => {
  const mounted = React.useRef(true);
  React.useEffect((): any => {
    mounted.current = true;
    return () => mounted.current = false;
  })

  const timeoutId = React.useRef<ReturnType<typeof setInterval>>();
  const classes = useStyles();
  const topology = useTopology();
  const intl = useIntl();
  const contactsList = useAllContactsList();
  const tagsList = useGetAllTags();
  const { closeSpecificAlert, saveAssignedContact } = useAlertsLogActions();
  const [keywordSeach, setKeywordSearch] = React.useState("");
  const [isSearchDisabled, setIsSearchDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [selectedAlert, setSelectedAlert] = React.useState<IEventLogResponse | null>(null);
  const cm = React.useRef<ContextMenu>(null);
  const [expandedRows, setExpandedRows] = React.useState<any[] | DataTableExpandedRows>();
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [pageInputTooltip, setPageInputTooltip] = React.useState("Press 'Enter' key to go to this page.");
  const [isClearBtnDisabled, setIsClearBtnDisabled] = React.useState(true);

  const { targetMatch, groupMatch } = useTopologyRouteMatch();

  let targetFilter: string[] | null = [];
  const getChildrenThatAreTargets = function(id: string): void {
    const children: ITopologyItem[] = topology.findByParentObjectId(id ?? '');
    
    if(children.length == 0) {
      targetFilter?.push(id);
    } else {
      children.map( (item: ITopologyItem) => {
        getChildrenThatAreTargets(item.objectId);
      });
    }
  }

  
  if (targetMatch) {
    targetFilter.push(targetMatch.params.targetId);
  } else if (groupMatch) {
    getChildrenThatAreTargets(groupMatch?.params.groupId ?? '')
  } else {
    targetFilter = null;
  }

  const contactsOptionTemplate = (option: IContactDetails): DropdownItemTemplateType => {
    return <span data-testid="contactItemOption">{option.name}</span>;
  };

  const selectedContactTemplate = (alert: IEventLogResponse): JSX.Element => {
    if (alert && alert.contactName) {
      return <span>{alert.contactName}</span>;
    }

    return <span>{intl.formatMessage({ id: "selectUser" })}</span>;
  };

  const contactsAndContactGroups = React.useMemo(() => {
    if (contactsList && contactsList.data) {
      return [...contactsList.data.userGroups, ...contactsList.data.users].map((item: [string, string]) => {
        return {
          id: item[0],
          name: item[1],
        };
      });
    }
  }, [contactsList]);

  const tags = React.useMemo(() => {
    if (tagsList && tagsList.data) {
      return tagsList.data.map((x) => {
        return { id: x, name: x };
      });
    }
    return [];
  }, [tagsList]);

  const closeContextModel = [
    {
      command: () => selectedAlert && onCloseAlert(selectedAlert, AlertClosingType.CloseAlertByObject),
      label: intl.formatMessage({ id: "closeForObject" }),
    },
    {
      command: () => selectedAlert && onCloseAlert(selectedAlert, AlertClosingType.CloseAlertByCondition),
      label: intl.formatMessage({ id: "closeForCondition" }),
    },
  ];

  const [state, dispatch] = React.useReducer<React.Reducer<ILazyParams, IAdvisoryEventsGridAction>>(reducer, {
    ...initialFilters,
    currentPage: 1,
    endDate: endDate,
    filters: {
      actionTypeName: {
        constraints: [
          {
            matchMode: FilterMatchMode.STARTS_WITH,
            value: null,
          },
        ],
        operator: "and",
      },
      conditionType: {
        constraints: [
          {
            matchMode: FilterMatchMode.STARTS_WITH,
            value: null,
          },
        ],
        operator: "and",
      },
      isResolved: {
        constraints: [
          {
            matchMode: FilterMatchMode.EQUALS,
            value: null,
          },
        ],
        operator: "and",
      },
      severity: {
        constraints: [
          {
            matchMode: FilterMatchMode.EQUALS,
            value: null,
          },
        ],
        operator: "and",
      },
      tags: {
        constraints: [
          {
            matchMode: FilterMatchMode.STARTS_WITH,
            value: null,
          },
        ],
        operator: "and",
      },
    },
    filtersData: {
      actionFilter: [],
      closeFilter: [],
      conditionsFilter: [],
      serverFilter: [],
      severityFilter: [],
      tagsFilter: [],
    },
    first: 0,
    page: 1,
    rows: GRID_PAGINATIONROW_OPTIONS[0],
    sortField: sort[0].id,
    sortIsDescending: sort[0].direction.toLowerCase() === "desc",
    startDate: startDate,
  });

  const { data, error, isLoading, refetch } = useGetAlertsLogQuery({
    alertLogFilterOptions: {
      actionFilter: state.filtersData.actionFilter,
      closeFilter: state.filtersData.closeFilter.map((x) => x - 1),
      conditionFilter: selectedChartCell?.chartType === CONDITIONS_CHART ? [selectedChartCell.id] : [],
      conditionTypeFilter: state.filtersData.conditionsFilter,
      searchKey: state.searchKey,
      serverFilter: selectedChartCell?.chartType === SERVERS_CHART ? [selectedChartCell.id] : [],
      severityFilter: state.filtersData.severityFilter,
      tagsFilter:
      selectedChartCell?.chartType === TAGS_CHART
      ? [selectedChartCell.value.toString()]
      : state.filtersData.tagsFilter,
      targetFilter: targetFilter,
    },
    endDateTimeUtc: endDate.toISOString(),
    limit: state.rows,
    offset: state.first,
    sortIsDescending: state.sortIsDescending,
    sortProperty: state.sortField,
    startDateTimeUtc: startDate.toISOString(),
  });

  if (error) {
    throw error;
  }

  const clearFilter = (): void => {
    if(mounted.current) {
      setKeywordSearch("");
      onClearFilter();
      setIsClearBtnDisabled(true);
      dispatch({ type: "clearFilter" });
    }
  };

  function instanceOfFilter(
    object: DataTableFilterMetaData | DataTableOperatorFilterMetaData,
  ): object is DataTableOperatorFilterMetaData {
    return "constraints" in object;
  }

  const onFilter = async (event: DataTablePFSEvent): Promise<void> => {
    if(mounted.current) {
      const actionFilter: string[] = [];
      const conditionsFilter: string[] = [];
      const severityFilter: number[] = [];
      const closeFilter: number[] = [];
      const serverFilter: string[] = [];
      const tagsFilter: string[] = [];

      Object.keys(event.filters).map((key) => {
        let filterEnum: Array<{ id: string | number; name: string }> = ConditionType;
        let arrayToPush: Array<string | number> = [];
        switch (key) {
          case "conditionType":
            filterEnum = ConditionType;
            arrayToPush = conditionsFilter;
            break;
          case "actionTypeName":
            filterEnum = ActionType;
            arrayToPush = actionFilter;
            break;
          case "severity":
            filterEnum = Severity;
            arrayToPush = severityFilter;
            break;
          case "isResolved":
            filterEnum = AlertStatus;
            arrayToPush = closeFilter;
            break;
          case "tags":
            filterEnum = tags;
            arrayToPush = tagsFilter;
            break;
        }
        const dataObj = event.filters[key];
        if (instanceOfFilter(dataObj) && dataObj.constraints[0]?.value) {
          for (const value of dataObj.constraints[0]?.value) {
            arrayToPush.push(
              filterEnum.filter((raw) => {
                if (raw.name === value) return raw.id;
              })[0]?.id,
            );
          }
        }
      });

      const filtersData = { actionFilter, closeFilter, conditionsFilter, serverFilter, severityFilter, tagsFilter };

      setIsClearBtnDisabled(false);
      dispatch({ event, filtersData: filtersData, type: "onFilter" });
    }
  };

  const onSearch = React.useCallback((event): void => {
    if(mounted.current) {
      if (timeoutId?.current) clearTimeout(timeoutId?.current);
      const searchKeyword = event.target.value;
      setKeywordSearch(searchKeyword);
      setIsClearBtnDisabled(false);
      timeoutId.current = setTimeout(() => {
        if(mounted.current) {
          setKeywordSearch(searchKeyword.trim());
          return dispatch({ event: searchKeyword.trim(), type: "onSearch" });
        }
      }, 1000);
    }
  }, []);

  async function onCloseAlert(alert: IEventLogResponse, alertClosingType: AlertClosingType): Promise<void> {
    if(mounted.current) {
      setLoading(true);
      const { isSuccess } = await closeSpecificAlert({
        alertClosingType: alertClosingType,
        alertId: alert.alertID,
        conditionId: alert.alertConditionId,
        isResolved: alertClosingType === AlertClosingType.CloseAlertByAlertId ? !alert.isResolved : true,
        objectId: alert.objectId,
      });

      if (isSuccess) {
        await refetch();
      }

      if(mounted.current) {
        setLoading(false);
      }
    }
  }

  async function onContactSelection(event: DropdownChangeParams, alert: IEventLogResponse): Promise<void> {
    if(mounted.current) {
      event.preventDefault();
      setLoading(true);

      const { isSuccess } = await saveAssignedContact({
        alertId: alert.alertID,
        contactObjectId: event?.value?.id,
      });

      if (isSuccess) {
        await refetch();
      }

      if(mounted.current) {
        setLoading(false);
      }
    }
  }

  const filteredItems = React.useMemo(() => {
    return topology.eventSourceConnections.filter((e) => e.isWatched);
  }, [topology]);

  React.useEffect((): any => {
    if(mounted.current) {
      setIsSearchDisabled(isLoading);
    }
  }, [isLoading]);

  // Clears the selected row of the alerts log on data change
  React.useEffect((): any => {
    if(mounted.current) {
      data && onRowSelected(null);
    }
  }, [data, onRowSelected]);

  const closeFilterTemplate = (options: ColumnFilterElementTemplateOptions): JSX.Element => {
    return (
      <MultiSelect
        className="p-column-filter"
        data-testid="close-options"
        onChange={(e) => options.filterCallback(e.value, options.index)}
        options={AlertStatus.map((status) => status.name)}
        placeholder="Select Resolution Status"
        showClear
        value={options.value}
      />
    );
  };

  const actionTypeFilterTemplate = (options: ColumnFilterElementTemplateOptions): JSX.Element => {
    return (
      <MultiSelect
        className={classes.filterDropDown}
        data-testid="action-options"
        onChange={(e) => options.filterCallback(e.value, options.index)}
        options={ActionType.map((action) => action.name)}
        placeholder="Select a Action Type"
        showClear
        value={options.value}
      />
    );
  };

  const conditionTypeFilterTemplate = (options: ColumnFilterElementTemplateOptions): JSX.Element => {
    return (
      <MultiSelect
        className={classes.filterDropDown}
        data-testid="condition-options"
        onChange={(e) => options.filterCallback(e.value, options.index)}
        options={ConditionType.map((action) => action.name)}
        placeholder="Select a Condition Type"
        showClear
        value={options.value}
      />
    );
  };

  const severityStatusFilterTemplate = (options: ColumnFilterElementTemplateOptions): JSX.Element => {
    return (
      <MultiSelect
        className={classes.filterDropDown}
        data-testid="severity-options"
        onChange={(e) => options.filterCallback(e.value, options.index)}
        options={Severity.map((action) => action.name)}
        placeholder="Select a Severity"
        showClear
        value={options.value}
      />
    );
  };

  const tagsFilterTemplate = (options: ColumnFilterElementTemplateOptions): JSX.Element => {
    return (
      <MultiSelect
        className={classes.filterDropDown}
        data-testid="tags-options"
        onChange={(e) => options.filterCallback(e.value, options.index)}
        options={tags.map((action) => action.name)}
        placeholder="Select a Tags"
        showClear
        value={options.value}
      />
    );
  };

  const renderHeader = (): JSX.Element => {
    return (
      <div className="flex justify-content-between">
        <Button
          className="p-button-contained"
          disabled={isClearBtnDisabled && !selectedChartCell}
          icon="pi pi-filter-slash"
          label="Clear"
          onClick={clearFilter}
          type="button"
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            autoComplete="off"
            disabled={isSearchDisabled}
            name="searchKey"
            onChange={(e) => {
              e.persist();
              onSearch(e);
            }}
            placeholder="Search"
            type="search"
            value={keywordSeach}
          />
        </span>
      </div>
    );
  };

  const filterApplyTemplate = (options: ColumnFilterElementTemplateOptions): JSX.Element => {
    return (
      <Button data-testid="apply-btn" onClick={options.filterApplyCallback} type="button">
        Apply
      </Button>
    );
  };

  const fItems = React.useMemo(() => {
    return (
      data &&
      data.items &&
      data.items.map((raw: IEventLogResponse) => {
        raw.startDateTimeUtc = new Date(raw.startDateTimeUtc);
        raw.id = uuid();
        if (raw.endDateTimeUtc) raw.endDateTimeUtc = new Date(raw.endDateTimeUtc);
        return raw;
      })
    );
  }, [data]);

  if (fItems && data) data.items = fItems;

  React.useMemo(() => mounted.current && setCurrentPage(state.currentPage), [state.currentPage]);

  const onPageInputKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    options: PaginatorCurrentPageReportOptions,
  ): void => {
    if (event.key === "Enter") {
      if (currentPage >= 1 && currentPage <= options.totalPages) {
        const first = options.rows * (currentPage - 1);
        dispatch({ pageInfo: { currentPage, first, rows: options.rows }, type: "goTo" });
      }
    }
  };

  const onPageInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    options: PaginatorCurrentPageReportOptions,
  ): void => {
    if(mounted.current) {
      const page = event.target.value ? parseInt(event.target.value) : 0;
      if (page < 1 || page > options.totalPages) {
        setPageInputTooltip(`Value must be between 1 and ${options.totalPages}.`);
      } else {
        setPageInputTooltip("Press 'Enter' key to go to this page.");
      }
      
      setCurrentPage(page);
    }
  };

  const customPaginatorTemplate: PaginatorTemplate = {
    CurrentPageReport: (options) => {
      return (
        <span className="mx-2" style={{ color: "var(--text-color)", userSelect: "none" }}>
          Go to{" "}
          <InputText
            className={`ml-1 ${
              options.totalPages !== 0
                ? currentPage < 1 || currentPage > options.totalPages
                  ? "p-invalid"
                  : ""
                : "p-disabled"
            }`}
            data-testid="goto-btn"
            onChange={(e) => onPageInputChange(e, options)}
            onKeyDown={(e) => onPageInputKeyDown(e, options)}
            size={2}
            tooltip={pageInputTooltip}
            value={currentPage}
          />
        </span>
      );
    },
    layout: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport",
  };

  const header = renderHeader();

  const showContextmenu = (event: DataTableRowEventParams): void => {
    const { originalEvent } = event;
    originalEvent.persist();
    cm.current?.show(originalEvent);
  };

  const rowExpansionTemplate = (data: IEventLogResponse): React.ReactElement => {
    return <NotesGrid row={data} />;
  };

  return (
    <Card
      className="agrid"
      style={{
        overflow: "auto",
        ...style,
      }}
      {...props}
    >
      <ContextMenu
        data-testid="closeContextModel"
        model={closeContextModel}
        onHide={() => mounted.current && setSelectedAlert(null)}
        ref={cm}
      />

      <DataTable
        className={classes.dataTable}
        columnResizeMode="expand"
        contextMenuSelection={selectedAlert ?? {}}
        dataKey="id"
        expandedRows={expandedRows}
        filterDisplay="menu"
        filters={state.filters}
        first={state.first}
        header={header}
        lazy
        loading={isLoading || loading}
        onContextMenu={(e) => showContextmenu(e)}
        onContextMenuSelectionChange={(e) => mounted.current && setSelectedAlert(e.value)}
        onFilter={onFilter}
        onPage={(event) => dispatch({ event, type: "onPage" })}
        onRowSelect={(e) => onRowSelected(e.data)}
        onRowToggle={(e) => mounted.current && setExpandedRows(e.data)}
        onSort={(event) => dispatch({ event, type: "onSort" })}
        paginator
        paginatorTemplate={customPaginatorTemplate}
        resizableColumns
        responsiveLayout="scroll"
        rowExpansionTemplate={rowExpansionTemplate}
        rows={state.rows}
        rowsPerPageOptions={GRID_PAGINATIONROW_OPTIONS}
        selection={selectedRow}
        selectionMode="single"
        size="small"
        sortField={state.sortField}
        sortOrder={state.sortIsDescending ? -1 : 1}
        totalRecords={data ? data.rowCount : 0}
        value={data ? [...data.items] : []}
      >
        <Column
          body={(alert) => {
            return (
              <>
                <Tooltip target=".p-checkbox-disabled" />
                <Checkbox
                  checked={alert.isResolved}
                  data-pr-tooltip={intl.formatMessage({ id: "cannotclosethisalert" })}
                  data-testid="close-alert"
                  disabled={alert.isResolved === null}
                  onChange={() => onCloseAlert(alert, AlertClosingType.CloseAlertByAlertId)}
                ></Checkbox>
              </>
            );
          }}
          field="isResolved"
          filter
          filterElement={closeFilterTemplate}
          header={<FormattedMessage id="close" />}
          showAddButton={false}
          showFilterMatchModes={false}
          showFilterOperator={false}
          sortable
        />
        <Column
          body={(alert) => {
            let target = topology.findByObjectId(alert.objectId);
            if (target) {
              if (target.type === TopologyObjectType.group || target.type === TopologyObjectType.site) {
                return alert.targetName;
              }
              if (target.type === TopologyObjectType.device) {
                const foundEventSrcConnection = filteredItems.find((y) => y.parentObjectId === alert.objectId);
                if (foundEventSrcConnection) target = foundEventSrcConnection;
              }
              return (
                <NavigationContextTrigger
                  dateRange={{
                    from: DateTime.fromJSDate(alert.startDateTimeUtc).minus({ minute: 10 }).toJSDate(),
                    to: DateTime.fromJSDate(alert.endDateTimeUtc ?? new Date())
                      .plus({ minute: 10 })
                      .toJSDate(),
                  }}
                  target={target}
                  variant="table"
                >
                  {alert.targetName}
                </NavigationContextTrigger>
              );
            } else {
              return alert.targetName;
            }
          }}
          field="targetName"
          header={<FormattedMessage id="target" />}
          sortable
        />
        <Column field="objectType" header={<FormattedMessage id="type" />} sortable />
        <Column field="conditionName" header={<FormattedMessage id="name" />} sortable />
        <Column
          data-testid="filter-alert"
          field="conditionType"
          filter
          filterElement={conditionTypeFilterTemplate}
          header={<FormattedMessage id="conditionType" />}
          showAddButton={false}
          showFilterMatchModes={false}
          showFilterOperator={false}
          sortable
        />
        <Column
          field="actionTypeName"
          filter
          filterElement={actionTypeFilterTemplate}
          header={<FormattedMessage id="actionType" />}
          showAddButton={false}
          showFilterMatchModes={false}
          showFilterOperator={false}
          sortable
        />
        <Column
          dataType="text"
          field="severity"
          filter
          filterApply={filterApplyTemplate}
          filterElement={severityStatusFilterTemplate}
          header={<FormattedMessage id="severity" />}
          showAddButton={false}
          showFilterMatchModes={false}
          showFilterOperator={false}
          sortable
        />
        <Column
          dataType="text"
          field="tags"
          filter
          filterApply={filterApplyTemplate}
          filterElement={tagsFilterTemplate}
          header={<FormattedMessage id="tags" />}
          showAddButton={false}
          showFilterMatchModes={false}
          showFilterOperator={false}
          sortable
        />
        <Column
          body={(alert) => {
            return (
              <Dropdown
                className={classes.dropDown}
                data-testid="assignedContactDrd"
                filter
                filterBy="name"
                itemTemplate={contactsOptionTemplate}
                onChange={(event) => onContactSelection(event, alert)}
                optionLabel="name"
                options={contactsAndContactGroups}
                placeholder={intl.formatMessage({ id: "selectUser" })}
                title={alert.contactName}
                valueTemplate={() => selectedContactTemplate(alert)}
              />
            );
          }}
          field="assignedUser"
          header={<FormattedMessage id="assignedUser" />}
        />
        <Column
          body={(alert) => {
            if (alert.startDateTimeUtc && Date.parse(alert.startDateTimeUtc)) {
              return (
                <FormattedDate
                  day="numeric"
                  hour="2-digit"
                  minute="numeric"
                  month="numeric"
                  second="numeric"
                  value={alert.startDateTimeUtc}
                  year="numeric"
                />
              );
            }
            return null;
          }}
          className={classes.noWrap}
          field="startDateTimeUtc"
          header={<FormattedMessage id="startTime" />}
          sortable
        />
        <Column
          body={(alert) => {
            if (alert.endDateTimeUtc && Date.parse(alert.endDateTimeUtc)) {
              return (
                <FormattedDate
                  day="numeric"
                  hour="2-digit"
                  minute="numeric"
                  month="numeric"
                  second="numeric"
                  value={alert.endDateTimeUtc}
                  year="numeric"
                />
              );
            }
            return null;
          }}
          className={classes.noWrap}
          field="endDateTimeUtc"
          header={<FormattedMessage id="endTime" />}
          sortable
        />
        <Column
          body={(alert) => {
            if (typeof alert.durationInMilliseconds === "number") {
              const duration = Duration.fromMillis(alert.durationInMilliseconds).shiftTo(
                "milliseconds",
                "seconds",
                "minutes",
                "hours",
                "days",
              );

              return (
                <FormattedDuration
                  days={duration.days}
                  hours={duration.hours}
                  milliseconds={duration.milliseconds}
                  minutes={duration.minutes}
                  seconds={duration.seconds}
                />
              );
            }
          }}
          field="durationInMilliseconds"
          header={<FormattedMessage id="duration" />}
          sortable
        />
        <Column expander header={intl.formatMessage({ id: "notes" })} />
      </DataTable>
    </Card>
  );
};

export default AdvisoryEventsGrid;
