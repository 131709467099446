import Card from "@material-ui/core/Card";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import * as React from "react";
import { useIntl } from "react-intl";
import DeadlockDiagram from "../DeadlockDiagram";
import DeadlockXml from "../DeadlockXml";

const useStyles = makeStyles({
  fullScreenToggle: {
    flexGrow: "inherit",
    minWidth: "4rem",
  },
  root: {
    display: "grid",
    gridTemplateRows: "min-content 1fr",
    height: "100%", // for full-screen mode
  },
});

export enum DeadlockDetailsTab {
  DeadlockDiagram,
  DeadlockXml,
}

export interface IDeadlockDetailsProps {
  className?: string;
  fullScreen: boolean;
  onToggleFullScreen: () => void;
}

const DeadlockDetails: React.FC<IDeadlockDetailsProps> = ({ className, fullScreen, onToggleFullScreen }) => {
  const intl = useIntl();
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = React.useState(DeadlockDetailsTab.DeadlockDiagram);

  function handleTabChange(_: React.ChangeEvent<unknown>, tab: DeadlockDetailsTab): void {
    if (tab in Object.values(DeadlockDetailsTab)) {
      setSelectedTab(tab);
    }
  }

  return (
    <Card className={classNames(classes.root, className)} data-testid="DeadlockDetails">
      <Tabs onChange={handleTabChange} scrollButtons="auto" value={selectedTab} variant="scrollable">
        <Tab
          className={classes.fullScreenToggle}
          icon={fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          onClick={onToggleFullScreen}
          title={intl.formatMessage({ id: fullScreen ? "exitFullScreen" : "fullScreen" })}
          value={-1}
        />
        <Tab label="Deadlock Diagram" value={DeadlockDetailsTab.DeadlockDiagram} />
        <Tab label="Deadlock XML" value={DeadlockDetailsTab.DeadlockXml} />
      </Tabs>
      {selectedTab === DeadlockDetailsTab.DeadlockDiagram && <DeadlockDiagram />}
      {selectedTab === DeadlockDetailsTab.DeadlockXml && <DeadlockXml />}
    </Card>
  );
};

export default DeadlockDetails;
