import * as React from "react";
import { DateTime } from "luxon";
import { Alerts } from "../alerts/alerts";
import { AppBar, Box, Card, CardContent, Tab, Tabs } from "@material-ui/core";
import { TopologyObjectType, useTopology } from "../../components/TopologyContext";
import HealthScore from "../HealthScore/HealthScore";
import { ITopologyItemDevice, ITopologyItemEventSourceConnection } from "../../components/TopologyContext";
// import DiskSpace from "../DiskSpace/DiskSpace";
// import { useCurrentTarget } from "../../hooks/useCurrentTarget/useCurrentTarget";
// import { StorageContextProvider } from "../../contexts/storageContext";
import TargetInfoCard from "../TargetInfoCard/TargetInfoCard";

// function TabPanel(props: { [x: string]: any; children: any; value: any; index: any }): any {
//   const { children, value, index, ...other } = props;
// 
//   return (
//     <div
//       aria-labelledby={`simple-tab-${index}`}
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       role="tabpanel"
//       {...other}
//     >
//       {value === index && <Box>{children}</Box>}
//     </div>
//   );
// }

export interface IHealthProps {
  className?: string;
  groupId?: string;
  targets?: ReadonlyArray<ITopologyItemDevice | ITopologyItemEventSourceConnection>;
}

const TargetHealth: React.FC<IHealthProps> = () => {
  const now = DateTime.local();
  const end_date = now.toJSDate();
  const start_date = now.minus({ day: 1 }).toJSDate();
  // const [subTab, setSubTab] = React.useState(0);
  const { shouldShowFeature } = useTopology();

  const [has_alerts_permission] = React.useMemo(() => {
    return [shouldShowFeature("alerts")];
  }, [shouldShowFeature]);

  // let tab_index = 0;

  // const target = useCurrentTarget();

  return (
    <div>
      <Card>
        <CardContent>
          <TargetInfoCard></TargetInfoCard>
          <br></br>
          <HealthScore></HealthScore>
        </CardContent>
      </Card>
      <br></br>
      {/* <AppBar position="static">
        <Tabs
          onChange={(_event, newValue) => {
            setSubTab(newValue);
          }}
          value={subTab}
        >
          {has_alerts_permission && <Tab label="Alerts"></Tab>}
          {!!target && target.features.storage && (target.type === TopologyObjectType.device || target.type === TopologyObjectType.eventSourceConnection) && <Tab label="Risk"></Tab>}
          <Tab label="Performance"></Tab>
          <Tab label="Queries"></Tab>
        </Tabs>
      </AppBar> */}
      {has_alerts_permission && (
        // <TabPanel index={tab_index++} value={subTab}>
        <Alerts endDate={end_date} onClearFilter={null} selectedChartCell={null} startDate={start_date}></Alerts>
        // </TabPanel>
      )}
      {/* {!!target && target.features.storage && (target.type === TopologyObjectType.device || target.type === TopologyObjectType.eventSourceConnection) && (
        <StorageContextProvider currentTarget={target}>
          <TabPanel index={tab_index++} value={subTab}>
            <Card>
              <CardContent>
                <DiskSpace></DiskSpace>
                <br></br>
                <div style={{ backgroundColor: 'pink', height: '100%' }}>Databases without backup placeholder</div>
              </CardContent>
            </Card>
          </TabPanel>
        </StorageContextProvider>
      )}
      <TabPanel index={tab_index++} value={subTab}>
        <div style={{ backgroundColor: 'pink', height: '100%' }}>Performance placeholder</div>
      </TabPanel>
      <TabPanel index={tab_index++} value={subTab}>
        <div style={{ backgroundColor: 'pink', height: '100%' }}>Queries placeholder</div>
      </TabPanel> */}
    </div>
  );
};

export default TargetHealth;
