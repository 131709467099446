import { IntlShape } from "react-intl";
import { TickFormatterFunction, TooltipFormatter } from "recharts";
import { formatAbbreviatedNumber, formatBitrate, formatBits, formatDigitalStorage } from "../../utilities/Formatters";
import { MutableDeep } from "../../utilities/UtilityTypes";
import { IMetricDataQueryResultMetricSeries } from "./MetricDataQuery.types";
import { IMetricChartPoint, IMetricChartTooltipGroup, MetricUnitOfMeasure } from "./types";

/**
 * Returns a function that can be used as a tick formatter in Recharts.
 * @param unitOfMeasure The unit of measure to format to.
 * @param intl Intertionalization context.
 */
export function makeFormatterForUnit(unitOfMeasure: MetricUnitOfMeasure, intl: IntlShape): TickFormatterFunction {
  switch (unitOfMeasure) {
    case MetricUnitOfMeasure.Bitrate:
      return formatBits;
    case MetricUnitOfMeasure.Bytes:
    case MetricUnitOfMeasure.BytesPerSecond:
      return formatDigitalStorage;
    case MetricUnitOfMeasure.Percent:
      return (x) => intl.formatNumber(x / 100, { style: "percent" });
    default:
      return formatAbbreviatedNumber;
  }
}

/**
 * Returns a function that can be used as a tooltip formatter in Recharts.
 * @param intl Internationalization context
 */
export function makeTooltipFormatter(intl: IntlShape): TooltipFormatter {
  return (value, _name, payload, _index) => {
    if (typeof value !== "number") {
      return value;
    } else {
      switch (payload.unit) {
        case MetricUnitOfMeasure.Bitrate:
          return formatBitrate(value);
        case MetricUnitOfMeasure.Bytes:
          return intl.formatNumber(value / 1024 ** 2, {
            maximumFractionDigits: 1,
            minimumFractionDigits: 1,
            style: "unit",
            unit: "megabyte",
          });
        case MetricUnitOfMeasure.BytesPerSecond: {
          const formattedValue = intl.formatNumber(value / 1024 ** 2, {
            maximumFractionDigits: 1,
            minimumFractionDigits: 1,
            style: "unit",
            unit: "megabyte",
          });
          return `${formattedValue}/sec`;
        }
        case MetricUnitOfMeasure.Millisecond:
          return intl.formatNumber(value, { maximumFractionDigits: 2, style: "unit", unit: "millisecond" });
        case MetricUnitOfMeasure.PagesPerSecond:
        case MetricUnitOfMeasure.PerSecond:
          return `${intl.formatNumber(value, { maximumFractionDigits: 2 })}/sec`;
        case MetricUnitOfMeasure.Percent:
          return intl.formatNumber(value / 100, { maximumFractionDigits: 2, style: "percent" });
        case MetricUnitOfMeasure.Second:
          return intl.formatNumber(value, { maximumFractionDigits: 2, style: "unit", unit: "second" });
        default:
          return intl.formatNumber(value, { maximumFractionDigits: 2 });
      }
    }
  };
}

export function mapQueryResultPoints(series: IMetricDataQueryResultMetricSeries): IMetricChartPoint[] {
  return series.points.map<IMetricChartPoint>((p) => {
    const tooltipGroups = p.detail.reduce((prev, curr) => {
      const group = prev.get(curr.category) ?? { entries: [], groupName: curr.category };
      group.entries.push({ name: curr.name, value: curr.value });
      prev.set(group.groupName, group);
      return prev;
    }, new Map<string, MutableDeep<IMetricChartTooltipGroup>>());
    return {
      timestamp: new Date(p.timestamp),
      tooltip: tooltipGroups.size > 0 ? Array.from(tooltipGroups.values()) : undefined,
      value: p.value,
    };
  });
}
