import { Theme } from "@material-ui/core/styles";
import { createTheme, ThemeProvider } from "@sentryone/material-ui";
import * as React from "react";
import GlobalCss from "./GlobalCss";
import theme from "./theme";

const customTheme: Theme = createTheme(theme);

const CustomThemeProvider: React.FunctionComponent = ({ children }) => {
  return (
    <ThemeProvider theme={customTheme}>
      <GlobalCss />
      {children}
    </ThemeProvider>
  );
};

export default CustomThemeProvider;
