import BaseService from "../BaseService";
import {
  IFeatureFlagService,
  IMonitorFeatures,
} from "./types";

export default class FeatureFlagService extends BaseService implements IFeatureFlagService {
  constructor() {
    super("/api/featureFlags");
  }

  public fetchFeatureFlags(controller?: AbortController): Promise<IMonitorFeatures> {
    return this.get<IMonitorFeatures>("", {
      signal: controller?.signal,
    });
  }
}
