import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useIntl } from "react-intl";
import { TooltipPayload, TooltipProps } from "recharts";
import {
  IProcedureQueryHistoryChartPoints,
  ITraceDataQueryHistoryChartDataPoints,
} from "../../../../../api/models/QueryHistory";
import { formatDate } from "../../../../../components/FormattedDate";
import { DataDisplay, Grouping, IQueryHistoryOptions } from "../types";

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.body2,
    "& caption": {
      color: "#f2f2f2",
      paddingBottom: theme.spacing(),
    },
    "& table": {
      borderCollapse: "collapse",
    },
    "& td": {
      color: "#f2f2f2",
      textAlign: "right",
    },
    "& tfoot>tr": {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    "& th": {
      ...theme.typography.body2,
      color: "#aaaaaa",
      textAlign: "left",
    },
    backgroundColor: "#333333",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    zIndex: theme.zIndex.tooltip,
  },
}));

export interface IQueryHistoryChartTooltipProps extends TooltipProps {
  options: IQueryHistoryOptions;
  dataAccessor: (
    payload: readonly TooltipPayload[],
  ) => IProcedureQueryHistoryChartPoints | ITraceDataQueryHistoryChartDataPoints | null | undefined;
}

const QueryHistoryChartTooltip: React.FC<IQueryHistoryChartTooltipProps> = ({
  dataAccessor,
  payload = [],
  options,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const data = dataAccessor(payload);
  if (!data) {
    return null;
  } else if ("startTimeUtc" in data) {
    // data is IProcedureQueryHistoryChartPoints
    const avgPrefix =
      options.dataDisplay === DataDisplay.average ? intl.formatMessage({ id: "avgPrefix" }) + " " : null;
    return (
      <Card className={classes.root}>
        <CardContent>
          <table>
            <tbody>
              <tr>
                <th scope="row">{intl.formatMessage({ id: "type" })}</th>
                <td>{data.queryType}</td>
              </tr>
              <tr>
                <th scope="row">{intl.formatMessage({ id: "planNumber" })}</th>
                <td>{intl.formatNumber(data.planNumber)}</td>
              </tr>
              <tr>
                <th scope="row">
                  {avgPrefix}
                  {intl.formatMessage({ id: "durationMs" })}
                </th>
                <td>{intl.formatNumber(data.elapsedTimeDelta)}</td>
              </tr>
              <tr>
                <th scope="row">
                  {avgPrefix}
                  {intl.formatMessage({ id: "cpuMs" })}
                </th>
                <td>{intl.formatNumber(data.workerTimeDelta)}</td>
              </tr>
              <tr>
                <th scope="row">
                  {avgPrefix}
                  {intl.formatMessage({ id: "readsL" })}
                </th>
                <td>{intl.formatNumber(data.logicalReadsDelta)}</td>
              </tr>
              <tr>
                <th scope="row">
                  {avgPrefix}
                  {intl.formatMessage({ id: "writesL" })}
                </th>
                <td>{intl.formatNumber(data.logicalWritesDelta)}</td>
              </tr>
              <tr>
                <th scope="row">
                  {avgPrefix}
                  {intl.formatMessage({ id: "readsP" })}
                </th>
                <td>{typeof data.physicalReadsDelta === "number" && intl.formatNumber(data.physicalReadsDelta)}</td>
              </tr>
              <tr>
                <th scope="row">{intl.formatMessage({ id: "execCount" })}</th>
                <td>{intl.formatNumber(data.executionCountDelta)}</td>
              </tr>
              <tr>
                <th scope="row">
                  {intl.formatMessage({ id: options.grouping === Grouping.ungrouped ? "startTime" : "minStartTime" })}
                </th>
                <td>
                  {formatDate(data.startTimeUtc, {
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    month: "numeric",
                    second: "2-digit",
                    year: "numeric",
                  })}
                </td>
              </tr>
              <tr>
                <th scope="row">
                  {intl.formatMessage({ id: options.grouping === Grouping.ungrouped ? "endTime" : "maxEndTime" })}
                </th>
                <td>
                  {formatDate(data.endTimeUtc, {
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    month: "numeric",
                    second: "2-digit",
                    year: "numeric",
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        </CardContent>
      </Card>
    );
  } else {
    // data is ITraceDataQueryHistoryChartDataPoints
    const avgPrefix = data.recCount ? intl.formatMessage({ id: "avgPrefix" }) + " " : null;
    return (
      <Card className={classes.root}>
        <CardContent>
          <table>
            <tbody>
              <tr>
                <th scope="row">{intl.formatMessage({ id: "type" })}</th>
                <td>{intl.formatMessage({ id: "traceQueryStats" })}</td>
              </tr>
              {data.planNumber > 0 && (
                <tr>
                  <th scope="row">{intl.formatMessage({ id: "planNumber" })}</th>
                  <td>{intl.formatNumber(data.planNumber)}</td>
                </tr>
              )}
              <tr>
                <th scope="row">
                  {avgPrefix}
                  {intl.formatMessage({ id: "durationMs" })}
                </th>
                <td>{intl.formatNumber(data.duration)}</td>
              </tr>
              <tr>
                <th scope="row">
                  {avgPrefix}
                  {intl.formatMessage({ id: "cpuMs" })}
                </th>
                <td>{intl.formatNumber(data.cpu)}</td>
              </tr>
              <tr>
                <th scope="row">
                  {avgPrefix}
                  {intl.formatMessage({ id: "readsL" })}
                </th>
                <td>{intl.formatNumber(data.reads)}</td>
              </tr>
              <tr>
                <th scope="row">
                  {avgPrefix}
                  {intl.formatMessage({ id: "writesP" })}
                </th>
                <td>{intl.formatNumber(data.writes)}</td>
              </tr>
              {data.recCount && (
                <tr>
                  <th scope="row">{intl.formatMessage({ id: "count" })}</th>
                  <td>{intl.formatNumber(data.recCount)}</td>
                </tr>
              )}
              <tr>
                <th scope="row">
                  {intl.formatMessage({ id: options.grouping === Grouping.ungrouped ? "startTime" : "minStartTime" })}
                </th>
                <td>
                  {formatDate(data.normalizedStartTime, {
                    day: "numeric",
                    hour: "2-digit",
                    millisecond: "3-digit",
                    minute: "2-digit",
                    month: "numeric",
                    second: "2-digit",
                    year: "numeric",
                  })}
                </td>
              </tr>
              <tr>
                <th scope="row">
                  {intl.formatMessage({ id: options.grouping === Grouping.ungrouped ? "endTime" : "maxEndTime" })}
                </th>
                <td>
                  {formatDate(data.normalizedEndTime, {
                    day: "numeric",
                    hour: "2-digit",
                    millisecond: "3-digit",
                    minute: "2-digit",
                    month: "numeric",
                    second: "2-digit",
                    year: "numeric",
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        </CardContent>
      </Card>
    );
  }
};

export default QueryHistoryChartTooltip;
