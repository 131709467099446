import * as React from "react";
import Typography from "@material-ui/core/Typography";
import RefreshIcon from "@material-ui/icons/Refresh";
import ErrorIcon from "../../../../components/icons/ErrorIcon";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { ICurrentTenant } from "../../../../hooks/useCurrentTenant";
import { useIntl } from "react-intl";
import DocumentTitle from "../../../../components/DocumentTitle";

export interface ITenantErrorProps {
  currentTenant: ICurrentTenant;
}

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: theme.typography.h5.fontSize,
  },
  content: {
    textAlign: "center",
  },
  placeholder: {
    fill: "none",
    height: "25rem",
    margin: "auto",
    maxHeight: "100%",
    width: "25rem",
  },
  root: {
    alignItems: "center",
    display: "grid",
    gridColumnGap: theme.spacing(2),
    gridTemplateColumns: "repeat(auto-fit, 50rem)",
    justifyContent: "center",
    marginTop: theme.spacing(3),
  },
  text: {
    fontSize: "1.5rem",
  },
  title: {
    fontWeight: theme.typography.h2.fontWeight,
  },
}));

const TenantError: React.FC<ITenantErrorProps> = ({ currentTenant }) => {
  const classes = useStyles();
  const intl = useIntl();
  const { tenant, error } = currentTenant;

  return (
    <>
      <DocumentTitle title={intl.formatMessage({ id: "failedToConnect" }, { tenant: tenant.name })} useIntl={false} />
      <div className={classes.root}>
        <div className={classes.content}>
          <Typography className={classes.title} component="h1" gutterBottom variant="h2">
            {error
              ? intl.formatMessage({ id: "apiReturnedError" })
              : intl.formatMessage({ id: "serverFailedToConnect" })}
          </Typography>
          <Typography className={classes.text} component="p" gutterBottom>
            {error ? (
              intl.formatMessage({ id: "problemCommunicatingWithApi" })
            ) : (
              <>
                {intl.formatMessage({ id: "errorConnecting" })}
                <b>&nbsp;{tenant.name}</b>.
              </>
            )}
          </Typography>
          <Typography className={classes.text} component="p" gutterBottom>
            {error
              ? intl.formatMessage({ id: "ifIssuePersistsContactSupport" })
              : intl.formatMessage({ id: "verifyServerRunningAndReload" })}
          </Typography>
          {error && (
            <Typography component="p" gutterBottom variant="h5">
              {error.message}
            </Typography>
          )}
          <Button
            className={classes.button}
            color="primary"
            onClick={() => window.location.reload()}
            startIcon={<RefreshIcon fontSize="large" />}
          >
            {intl.formatMessage({ id: "reloadPage" })}
          </Button>
        </div>
        <ErrorIcon className={classes.placeholder} />
      </div>
    </>
  );
};

export default TenantError;
