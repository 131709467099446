import {
  DeadlockResourceType,
  IDeadlock,
  IDeadlockExecutionStack,
  IDeadlockProcess,
  IDeadlockResource,
  IDeadlockResourceItem,
} from "../types";

export function parseDeadlockXml(xmlDocument: Document): IDeadlock;
export function parseDeadlockXml(xmlString: string): IDeadlock;
export function parseDeadlockXml(xmlString: string | Document): IDeadlock {
  const xml = typeof xmlString === "string" ? new window.DOMParser().parseFromString(xmlString, "text/xml") : xmlString;
  const deadlock = xml.querySelector("deadlock");
  if (!deadlock) {
    throw new Error("Invalid Deadlock XML: no deadlock node present");
  }

  let victimIds: readonly string[];
  if (deadlock.querySelector("victim-list")) {
    victimIds = Array.from(deadlock.querySelectorAll("victim-list > victimProcess"), (x) => {
      const id = x.getAttribute("id");
      if (!id) {
        throw new Error("Invalid Deadlock XML: victimProcess node exists without id attribute.");
      }
      return id;
    });
  } else {
    const victim = deadlock.getAttribute("victim");
    if (!victim) {
      throw new Error("Invalid Deadlock XML: deadlock node does not have victim attribute or contain victim-list.");
    }
    victimIds = [victim];
  }

  const processes = Array.from<Element, IDeadlockProcess>(deadlock.querySelectorAll("process"), (process) => {
    const id = process.getAttribute("id");
    if (!id) {
      throw new Error("Invalid Deadlock XML: process node exists without id attribute.");
    }
    const p = {
      clientApp: process.getAttribute("clientapp"),
      databaseName: process.getAttribute("databaseName"),
      ecid: Number(process.getAttribute("ecid")),
      executionStack: [],
      hostName: process.getAttribute("hostname"),
      id,
      inputBufferText: process.querySelector("inputbuf")?.innerHTML,
      isolationLevel: process.getAttribute("isolationlevel") ?? null,
      lastTranStarted: process.getAttribute("lasttranstarted"),
      lockMode: process.getAttribute("lockMode") ?? "",
      logUsed: Number(process.getAttribute("logused")),
      loginName: process.getAttribute("loginname"),
      ownerOf: [],
      priority: Number(process.getAttribute("priority")),
      spid: Number(process.getAttribute("spid")),
      transactionName: process.getAttribute("transactionname") ?? "",
      type: victimIds.includes(id) ? "victim" : "process",
      waitResource: process.getAttribute("waitresource") ?? null,
      waitTime: Number(process.getAttribute("waittime")),
      waiterOf: [],
      xactid: Number(process.getAttribute("xactid")),
    } as IDeadlockProcess;

    p.executionStack = Array.from<Element, IDeadlockExecutionStack>(
      process.querySelectorAll("frame"),
      (executionStack) => {
        return {
          line: Number(executionStack.getAttribute("line")),
          processName: executionStack.getAttribute("procname"),
          sqlHandle: executionStack.getAttribute("sqlhandle"),
          text:
            executionStack.innerHTML.trim().localeCompare("unknown") === 0 && p.inputBufferText
              ? p.inputBufferText
              : executionStack.innerHTML,
        } as IDeadlockExecutionStack;
      },
    );
    return p;
  });

  const processesById = processes.reduce<Record<string, IDeadlockProcess>>((prev, curr) => {
    prev[curr.id] = curr;
    return prev;
  }, {});

  const resources = Array.from(deadlock.querySelectorAll("resource-list > *")).reduce<IDeadlockResource[]>(
    (prev, resource) => {
      // Some resource types don't have IDs, like resourceWait
      const id = resource.getAttribute("id") ?? Math.random().toString();

      // Create IDeadlockResource before mapping owners and waiters so that reference is available
      // Resources can be duplicated with different owner/waiter pairs, so we flatten them by ID
      let r = prev.find((x) => x.id === id);
      if (!r) {
        if (!Object.values(DeadlockResourceType).includes(resource.tagName as DeadlockResourceType)) {
          console.warn(
            `Invalid Deadlock XML: ${resource.tagName} is not in the expected resource types [${Object.values(
              DeadlockResourceType,
            )}]`,
          );
        }
        r = {
          id,
          indexName: resource.getAttribute("indexname"),
          mode: resource.getAttribute("mode") ?? null,
          objectName: resource.getAttribute("objectname") ?? "",
          owners: [],
          type: resource.tagName,
          waiters: [],
        };
        prev.push(r);
      }
      const r2: IDeadlockResource = r;
      r2.owners = Array.from<Element, IDeadlockResourceItem>(
        resource.querySelectorAll("owner-list > owner"),
        (owner) => {
          const processId = owner.getAttribute("id");
          if (!processId) {
            throw new Error("Invalid Deadlock XML: owner node exists without id attribute.");
          }
          return {
            id: processId,
            key: Math.random().toString(),
            mode: owner.getAttribute("mode") ?? "",
            process: processesById[processId],
            requestType: owner.getAttribute("requestType") ?? null,
            resource: r2,
            // Sequence is set later once mapping is complete
            sequence: 0,
          };
        },
      ).reduce(
        (prev, owner) => {
          prev.push(owner);
          return prev;
        },
        [...r2.owners],
      );
      r2.waiters = Array.from<Element, IDeadlockResourceItem>(
        resource.querySelectorAll("waiter-list > waiter"),
        (waiter) => {
          const processId = waiter.getAttribute("id");
          if (!processId) {
            throw new Error("Invalid Deadlock XML: waiter node exists without id attribute.");
          }
          return {
            id: processId,
            key: Math.random().toString(),
            mode: waiter.getAttribute("mode") ?? "",
            process: processesById[processId],
            requestType: waiter.getAttribute("requestType") ?? null,
            resource: r2,
            // Sequence is set later once mapping is complete
            sequence: 0,
          };
        },
      ).reduce(
        (prev, waiter) => {
          prev.push(waiter);
          return prev;
        },
        [...r2.waiters],
      );
      return prev;
    },
    [],
  );

  const allOwners = resources.flatMap((x) => x.owners);
  const allWaiters = resources.flatMap((x) => x.waiters);

  // Assign ownerOf and waiterOf lists from resources
  processes.forEach((process) => {
    process.ownerOf = allOwners.filter((x) => x.id === process.id);
    process.waiterOf = allWaiters.filter((x) => x.id === process.id);
  });

  // Set sequence number. Owners first by transaction ID, then waiters by wait time descending
  let sequence = 0;
  [...processes]
    .sort((a, b) => a.xactid - b.xactid)
    .forEach((process) => {
      allOwners
        .filter((x) => x.id === process.id)
        .forEach((x) => {
          x.sequence = ++sequence;
        });
    });
  [...processes]
    .sort((a, b) => a.xactid - b.xactid)
    .sort((a, b) => b.waitTime - a.waitTime)
    .forEach((process) => {
      allWaiters
        .filter((x) => x.id === process.id)
        .forEach((x) => {
          x.sequence = ++sequence;
        });
    });

  return {
    deadlockXml: typeof xmlString === "string" ? xmlString : xmlString.documentElement.innerHTML,
    processes,
    resources,
    victimIds,
  };
}
