import { useMemo } from "react";
import { IAsyncResult } from "src/features/Storage/types";
import { useQuery } from "@apollo/react-hooks";
import * as WAITS_BY_TRACE_DATA from "./WaitsByTraceChart.graphql";
import { WaitsByTraceData } from "./types";

export function useWaitsByTraceChartData(
  databaseId: number,
  normalizedTextMd5: string,
  startDate: Date,
  endDate: Date,
  moRef: string,
): IAsyncResult<WaitsByTraceData> {
  const { data = { target: { topsql: { waitsByTrace: {} as WaitsByTraceData } } }, error, loading } = useQuery<{
    target: {
      topsql: {
        waitsByTrace: WaitsByTraceData;
      };
    };
  }>(WAITS_BY_TRACE_DATA, {
    variables: { databaseId, endDate, moRef, normalizedTextMd5, startDate },
  });

  return useMemo(() => {
    if (error) {
      return {
        data: undefined,
        error: error,
        isLoading: false,
        state: "error",
      };
    }

    if (loading) {
      return {
        data: undefined,
        error: null,
        isLoading: true,
        state: "loading",
      };
    }

    return {
      data: data.target.topsql.waitsByTrace,
      error: null,
      isLoading: false,
      state: "success",
    };
  }, [data, loading, error]);
}
