import * as React from "react";
import { IDataDisplayProps } from "./DataDisplay";

type IDataDisplayGroupProps = Pick<IDataDisplayProps, "variant"> &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

type IDataDisplayGroupContext = Pick<IDataDisplayProps, "variant">;

const DataDisplayGroupContext = React.createContext<IDataDisplayGroupContext>({ variant: undefined });

function useDataDisplayGroup(): IDataDisplayGroupContext {
  return React.useContext(DataDisplayGroupContext);
}

const DataDisplayGroup: React.FC<IDataDisplayGroupProps> = ({ variant, children, ...props }) => {
  return (
    <DataDisplayGroupContext.Provider value={{ variant }}>
      <div role="group" {...props}>
        {children}
      </div>
    </DataDisplayGroupContext.Provider>
  );
};

export { DataDisplayGroup as default, useDataDisplayGroup };
