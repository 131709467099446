import * as React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const NoDigestDashboardsIcon: React.FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 80 80" {...props}>
      <path d="M6.72 71.8529L3.08979 28.0827C3.04145 27.4998 3.50149 27 4.08637 27H12.96H20.4092C20.7736 27 21.1092 27.1983 21.2851 27.5175L24.2149 32.8355C24.3908 33.1547 24.7264 33.3529 25.0908 33.3529H65.66C66.2123 33.3529 66.66 33.8007 66.66 34.3529V41.6765M6.72 71.8529L16.2167 42.3699C16.3498 41.9566 16.7343 41.6765 17.1685 41.6765H66.66M6.72 71.8529H65.9343C66.3671 71.8529 66.7508 71.5745 66.885 71.163L76.0728 42.9865C76.2836 42.3402 75.8019 41.6765 75.1221 41.6765H66.66" stroke="currentColor" strokeWidth="2" />
      <path d="M63.0026 9C55.8244 9 50.0006 13.1568 50.0006 18.287C50.0006 20.4882 51.0706 22.5063 52.8583 24.1003C52.4656 25.1406 51.8606 25.5603 51.1834 26.0247C50.6823 26.3684 49.7298 26.9042 50.0729 27.9535C50.3031 28.6411 51.0164 29.0563 51.7929 28.9938C54.3978 28.7884 56.8131 28.1365 58.8808 27.0962C60.1765 27.4043 61.5624 27.574 62.9981 27.574C70.1807 27.574 76 23.4216 76 18.287C76.0045 13.1568 70.1852 9 63.0026 9ZM63.0026 25.4308C61.5353 25.4308 60.05 25.2344 58.6099 24.8057C57.1517 25.6541 55.0569 26.8596 52.5288 27.2168C53.9734 26.1452 55.0931 24.4664 55.2917 23.2698C53.5717 22.1089 52.1676 20.3721 52.1676 18.287C52.1676 15.1214 56.0637 11.1432 63.0026 11.1432C69.9415 11.1432 73.8375 15.1214 73.8375 18.287C73.8375 21.4571 69.9415 25.4308 63.0026 25.4308Z" fill="currentColor" />
      <path d="M65.6321 18.091C65.606 18.1139 65.2989 18.38 64.7106 18.8895C64.3339 19.2117 64.0532 19.4981 63.8685 19.7486C63.701 19.9739 63.4974 20.1055 63.2578 20.1435C63.0224 20.1808 62.804 20.1385 62.6026 20.0166C62.4006 19.8911 62.284 19.7295 62.2527 19.532C62.2064 19.2393 62.4209 18.8696 62.8964 18.423C63.3393 18.0378 63.7818 17.6508 64.2241 17.262C64.7002 16.819 64.9153 16.4531 64.8696 16.164C64.8157 15.8238 64.569 15.5365 64.1297 15.3023C63.6939 15.0638 63.2654 14.9779 62.844 15.0447C62.621 15.08 62.3333 15.2418 61.9809 15.5302C61.628 15.8148 61.371 15.9699 61.2099 15.9955C60.9662 16.0341 60.7472 15.9881 60.5529 15.8576C60.3622 15.7228 60.2515 15.5584 60.2208 15.3645C60.1628 14.9987 60.4712 14.6066 61.146 14.1885C61.7162 13.8319 62.2037 13.6216 62.6085 13.5574C63.5048 13.4155 64.3608 13.5799 65.1763 14.0508C65.996 14.5211 66.4659 15.1349 66.5858 15.8922C66.7046 16.6422 66.3867 17.3752 65.6321 18.091ZM63.283 22.5079C63.0475 22.5452 62.8223 22.5133 62.6073 22.4124C62.3735 22.2994 62.2395 22.135 62.2053 21.9191C62.1659 21.6703 62.212 21.4267 62.3436 21.1884C62.5007 20.8972 62.7466 20.7251 63.0811 20.6721C63.362 20.6276 63.5957 20.6863 63.7822 20.848C63.9305 20.982 64.0211 21.1533 64.0542 21.3619C64.0895 21.585 64.0544 21.8044 63.9488 22.0199C63.8147 22.2961 63.5928 22.4588 63.283 22.5079Z" fill="currentColor" />
      <ellipse cx="31" cy="53.0458" fill="currentColor" rx="1.5" ry="2.5" />
      <ellipse cx="50" cy="53.0458" fill="currentColor" rx="1.5" ry="2.5" />
      <path d="M26 49C29.2712 49.4194 30.8307 49.3407 33 48M31.5 65C36 62 44 62 49.5 65M55 49C51.7288 49.4194 50.1693 49.3407 48 48" stroke="currentColor" strokeWidth="2" />
    </SvgIcon>
  );
};

export default NoDigestDashboardsIcon;
