import { Column, ColumnPropsAuto } from "@sentryone/react-datatable";
import * as React from "react";
import FormattedDate from "../FormattedDate";
import IDateTimeFormatOptions from "../FormattedDate/IDateTimeFormatOptions";

export interface IDateColumnProps<TEntity> extends ColumnPropsAuto<TEntity> {
  format?: IDateTimeFormatOptions;
}

export default function DateColumn<TEntity>({
  format = {},
  ...props
}: IDateColumnProps<TEntity>): React.ReactElement | null {
  const renderCell = (d: TEntity): React.ReactElement => {
    const value = d[props.field];
    if (value instanceof Date) {
      return (
        <FormattedDate value={value}{...format} />
      );
    } else if (value) {
      throw new Error(`Value in DateColumn is not of type Date. Value is of type ${typeof value}.`);
    } else {
      return <></>;
    }
  };
  return (
    <Column<TEntity>
      defaultSortDirection="desc"
      renderCell={renderCell}
      sort={(a: TEntity, b: TEntity) => {
        //Empty dates are considered greater than non-empty dates
        const valA = a[props.field];
        const valB = b[props.field];
        if (valA instanceof Date && valB instanceof Date)
          return valA.getTime() - valB.getTime();
        else if (valB instanceof Date)
          return 1;
        else if (valA instanceof Date)
          return -1
        return 0;
      }}
      {...props}
    />
  );
}
