import { Dispatch, SetStateAction } from "react";
import { getMouseSvgPoint, ViewBox } from "./utils";
import { IViewBox } from "./types";

const DOM_DELTA_LINE = 1;

export interface IUseZoomParams {
  maxViewBox: IViewBox;
  minViewBox: IViewBox;
  onUpdateViewBox: Dispatch<SetStateAction<IViewBox>>;
  viewBox: IViewBox;
}

export interface IUseZoomResult {
  canZoomIn: boolean;
  canZoomOut: boolean;
  onWheel: (e: React.WheelEvent<SVGSVGElement>) => void;
  zoomIn: () => void;
  zoomOut: () => void;
}

export default function useZoom({ maxViewBox, minViewBox, onUpdateViewBox, viewBox }: IUseZoomParams): IUseZoomResult {
  function getBoundedViewBox(newViewBox: IViewBox, prevViewBox: IViewBox): IViewBox {
    if (newViewBox.width < minViewBox.width || newViewBox.height < minViewBox.height) {
      return minViewBox.translate(prevViewBox.minX, prevViewBox.minY);
    } else if (newViewBox.width > maxViewBox.width || newViewBox.height > maxViewBox.height) {
      return maxViewBox;
    } else {
      return newViewBox;
    }
  }

  function onWheel(e: React.WheelEvent<SVGSVGElement>): void {
    const cursorPoint = getMouseSvgPoint(e);
    const steps = e.deltaY / (e.deltaMode === DOM_DELTA_LINE ? 3 : 100);
    const factor = Math.pow(1.25, steps);
    onUpdateViewBox((prev) => {
      const newViewBox = prev.scale(factor, cursorPoint.x, cursorPoint.y);
      return getBoundedViewBox(newViewBox, prev);
    });
  }

  function zoomIn(): void {
    onUpdateViewBox((prev) => {
      const { x, y } = prev.center;
      const newViewBox = prev.scale(0.8, x, y);
      return getBoundedViewBox(newViewBox, prev);
    });
  }

  function zoomOut(): void {
    onUpdateViewBox((prev) => {
      const { x, y } = prev.center;
      const newViewBox = prev.scale(1.25, x, y);
      return getBoundedViewBox(newViewBox, prev);
    });
  }

  return {
    canZoomIn: viewBox.width > minViewBox.width && viewBox.height > minViewBox.height,
    canZoomOut: viewBox.width < maxViewBox.width && viewBox.height < maxViewBox.height,
    onWheel,
    zoomIn,
    zoomOut,
  };
}
