import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { DateTime } from "luxon";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import ErrorBoundary from "../../../../components/ErrorBoundary";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import NoDataIndicator from "../../../../components/NoDataIndicator";
import { useTopSqlContext } from "../../../../contexts/topSqlContext";
import QueryHistoryChart from "./QueryHistoryChart";
import QueryHistoryOptions from "./QueryHistoryOptions";
import RangeSlider from "./RangeSlider";
import { DataDisplay, Grouping, IDateRange, IQueryHistoryOptions, Metric, Mode } from "./types";
const { useMemo, useState } = React;

export interface IQueryHistoryProps {
  className?: string;
  eventSourceConnectionId: number;
}

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: "visible",
    position: "relative",
  },
  cardContent: {
    paddingTop: 0,
  },
  cardHeader: {
    paddingBottom: 5,
  },
  cardHeaderContent: {
    flex: "0 0 auto",
  },
  chartContainer: {
    height: theme.typography.pxToRem(200),
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
  rangeSliderContainer: {
    flex: "1 1 auto",
    marginLeft: "10%",
  },
}));

const QueryHistory: React.FunctionComponent<IQueryHistoryProps> = ({ className, eventSourceConnectionId }) => {
  const {
    isTotals,
    selectedEvent,
    selectedStatement,
    selectedStatementEvent,
    selectedTotal,
    statementResults: { isLoading: isStatementsLoading },
    totalsResults: { isLoading: isTotalsLoading },
  } = useTopSqlContext();
  const classes = useStyles({});
  const [availableDateRange] = useState<IDateRange>({
    from: DateTime.utc().minus({ days: 45 }).toJSDate(),
    to: DateTime.utc().toJSDate(),
  });
  const [options, setOptions] = useState<IQueryHistoryOptions>({
    dataDisplay: DataDisplay.average,
    dateRange: {
      from: DateTime.utc().minus({ days: 3 }).toJSDate(),
      to: DateTime.utc().toJSDate(),
    },
    grouping: Grouping.ungrouped,
    metric: Metric.duration,
    mode: Mode.procedure,
  });
  let currentOptions = options;
  useMemo(() => {
    if (isTotals) {
      if (selectedStatement) {
        //eslint-disable-next-line react-hooks/exhaustive-deps
        currentOptions = { ...options, mode: Mode.statement };
        setOptions(currentOptions);
      } else {
        //eslint-disable-next-line react-hooks/exhaustive-deps
        currentOptions = { ...options, mode: Mode.procedure };
        setOptions(currentOptions);
      }
    } else {
      if (selectedStatementEvent) {
        //eslint-disable-next-line react-hooks/exhaustive-deps
        currentOptions = { ...options, mode: Mode.statement };
        setOptions(currentOptions);
      } else {
        //eslint-disable-next-line react-hooks/exhaustive-deps
        currentOptions = { ...options, mode: Mode.procedure };
        setOptions(currentOptions);
      }
    }
  }, [isTotals, selectedEvent, selectedStatement, selectedStatementEvent, selectedTotal]);

  return (
    <Card className={classNames(classes.card, className)} data-testid="queryHistoryChart">
      <CardHeader
        action={
          <RangeSlider
            availableDateRange={availableDateRange}
            disabled={!(selectedStatement || selectedTotal || selectedStatementEvent || selectedEvent)}
            onChange={setOptions}
            value={currentOptions}
          />
        }
        className={classes.cardHeader}
        classes={{
          action: classes.rangeSliderContainer,
          content: classes.cardHeaderContent,
        }}
        title={<FormattedMessage id="queryHistory" />}
      />
      <CardContent className={classes.cardContent}>
        <ErrorBoundary fallbackClassName={classes.chartContainer} variant="chart">
          <div className={classes.chartContainer}>
            {isStatementsLoading || isTotalsLoading ? (
              <LoadingIndicator className={classes.chartContainer} variant="chart" />
            ) : (
              <QueryHistoryChart
                eventSourceConnectionId={eventSourceConnectionId}
                options={currentOptions}
                renderLoading={() => <LoadingIndicator className={classes.chartContainer} variant="chart" />}
                renderNoData={() => <NoDataIndicator className={classes.chartContainer} />}
              />
            )}
          </div>
        </ErrorBoundary>
        <QueryHistoryOptions onChange={setOptions} value={currentOptions} />
      </CardContent>
    </Card>
  );
};

export default QueryHistory;
