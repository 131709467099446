import { useQuery } from "@apollo/react-hooks";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { useIntl } from "react-intl";
import { Legend, LegendPayload } from "recharts";
import ErrorBoundary, { Throw } from "../../../../components/ErrorBoundary";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import { BlockingReportType, IBlockingReportingChartQuery, IReportingChartPoint } from "../../types";
import ReportingChart, { IReportingChartPointColor } from "./ReportingChart";
import ReportingChartMenu from "./ReportingChartMenu";
import * as GET_REPORT_CHART_QUERIES from "./reportingChartQuery.graphql";

export interface ReportingChartCardProps {
  className?: string;
  eventSourceConnectionId: number;
  visibleDates: Readonly<{
    from: Date;
    to: Date;
  }>;
}

const useStyles = makeStyles((theme) => ({
  chartArea: {
    display: "grid",
    gridGap: theme.spacing(),
    gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) 100px",
    gridTemplateRows: "minmax(190px, min-content) minmax(20px, min-content)",
  },
  indicator: {
    gridColumn: "1 / -2",
  },
  legendItem: {
    display: "inline-block",
    maxWidth: "50ch",
    overflow: "hidden",
    textOverflow: "ellipsis",
    verticalAlign: "middle",
    whiteSpace: "nowrap",
  },
  root: {
    overflow: "visible",
  },
  title: {
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
}));

function mapDataToPoints(
  points: readonly IReportingChartPoint[],
  legends: readonly LegendPayload[],
  theme: Theme,
): IReportingChartPointColor[] {
  return points.map<IReportingChartPointColor>((x) => {
    const legend = legends.find((y) => y.id === x.name);
    return {
      color: legend?.color ?? theme.palette.primary.main,
      name: legend?.value,
      value: x.value,
    };
  });
}

const ReportingChartCard: React.FC<ReportingChartCardProps> = ({
  className,
  eventSourceConnectionId,
  visibleDates,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();
  const [retryCount, setRetryCount] = React.useState(0);
  const [aggregateBy, setAggregateBy] = React.useState<BlockingReportType>(BlockingReportType.application);

  const requestParams = {
    endDate: visibleDates.to,
    eventSourceConnectionId,
    filters: {},
    limit: 4,
    offset: 0,
    startDate: visibleDates.from,
  };
  const { loading, error, data } = useQuery<IBlockingReportingChartQuery>(GET_REPORT_CHART_QUERIES, {
    context: {
      fetchOptions: {
        signal: "",
      },
    },
    fetchPolicy: "no-cache",
    variables: {
      bcrAvgTime: {
        ...requestParams,
        orderBy: "AVG_TIME_SEC",
      },
      bcrTotalCount: {
        ...requestParams,
        orderBy: "TOTAL_COUNT",
      },
      bcrTotalTime: {
        ...requestParams,
        orderBy: "TOTAL_TIME_SEC",
      },
      retryCount,
      shouldFetchApplications: aggregateBy === BlockingReportType.application,
      shouldFetchByResource: aggregateBy === BlockingReportType.resource,
      shouldFetchByResourceType: aggregateBy === BlockingReportType.waitType,
    },
  });

  const legends: LegendPayload[] = React.useMemo<LegendPayload[]>(() => {
    if (data) {
      const labels = new Set(
        [...data.blocking.AvgTime, ...data.blocking.TotalCount, ...data.blocking.TotalTime].map((x) => x.name),
      );
      return Array.from<string | null, LegendPayload>(labels, (x, i) => {
        return {
          color: theme.palette.genericChartColors[i % theme.palette.genericChartColors.length],
          id: x,
          type: "circle",
          value: x,
        };
      });
    } else {
      return [];
    }
  }, [data, theme]);
  const avgTime = mapDataToPoints(data?.blocking.AvgTime ?? [], legends, theme);
  const totalCount = mapDataToPoints(data?.blocking.TotalCount ?? [], legends, theme);
  const totalTime = mapDataToPoints(data?.blocking.TotalTime ?? [], legends, theme);

  return (
    <Card
      className={classNames(classes.wrapper, className)}
      classes={{ root: classes.root }}
      data-testid="BlockingReportingChart"
    >
      <CardHeader
        title={
          <>
            {intl.formatMessage({ id: "blocking" })}{" "}
            {aggregateBy === BlockingReportType.application && intl.formatMessage({ id: "byApplication" })}
            {aggregateBy === BlockingReportType.resource && intl.formatMessage({ id: "byWaitResource" })}
            {aggregateBy === BlockingReportType.waitType && intl.formatMessage({ id: "byWaitType" })}
          </>
        }
      />
      <CardContent className={classes.chartArea}>
        {loading && <LoadingIndicator className={classes.indicator} variant="chart" />}
        <ErrorBoundary
          fallbackClassName={classes.indicator}
          onRetry={() => setRetryCount((prevRetry) => prevRetry + 1)}
          variant="chart"
        >
          {/* Throwing the error inside of the ErrorBoundary so that the outer Card is kept in the DOM */}
          {error && <Throw error={error} />}
          {!loading && data && (
            <>
              <ReportingChart
                aggregateBy={aggregateBy}
                data={totalCount}
                title={intl.formatMessage({ id: "totalBlocks" })}
              />
              <ReportingChart
                aggregateBy={aggregateBy}
                data={totalTime}
                title={intl.formatMessage({ id: "totalSeconds" })}
              />
              <ReportingChart
                aggregateBy={aggregateBy}
                data={avgTime}
                title={intl.formatMessage({ id: "averageSeconds" })}
              />
            </>
          )}
        </ErrorBoundary>
        <ReportingChartMenu aggregateBy={aggregateBy} handleSelectAggregate={setAggregateBy} />
        {!loading && (
          <Legend
            formatter={(value) => {
              const legendText = intl.formatMessage({ defaultMessage: value ?? undefined, id: value || "(unknown)" });
              return (
                <span className={classes.legendItem} title={legendText}>
                  {legendText}
                </span>
              );
            }}
            payload={legends}
            wrapperStyle={{ gridColumn: "1 / -2", position: "relative" }}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default ReportingChartCard;
