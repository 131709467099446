import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import * as React from "react";
import { useIntl } from "react-intl";
import { IUser, IUserGroup, IModalCloseOptions } from "../../types";
import DialogActions from "@material-ui/core/DialogActions";
import PrincipalMultipleSelect from "./PrincipalMultipleSelect";
import Typography from "@material-ui/core/Typography";
import { usePrincipalActions } from "../../useFeatureAccess";
import { SWIcon } from "../../../../components/icons/SWIcon";

export interface IUserGroupModalProps {
  handleClose: (options: IModalCloseOptions) => void;
  open: boolean;
  selectedUserGroup: IUserGroup | null;
  users: IUser[];
}

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    gap: `${theme.spacing(2)}px`,
  },
  loadingIcon: {
    height: "150px",
    width: "100%",
  },
  loadingIconContainer: {
    display: "flex",
    height: "100%",
    placeItems: "center",
    position: "absolute",
    width: "100%",
    zIndex: 1,
  },
}));

const UserGroupModal: React.FC<IUserGroupModalProps> = ({ handleClose, open, selectedUserGroup, users }) => {
  const classes = useStyles();
  const intl = useIntl();

  const [userGroupName, setUserGroupName] = React.useState(selectedUserGroup?.name ?? "");
  const [selectedUsers, setSelectedUsers] = React.useState(selectedUserGroup?.users ?? []);
  const [isLoading, setIsLoading] = React.useState(false);
  //const [createUserGroup, updateUserGroup] = useUserActions();

  const { addUserGroup, updateUserGroup } = usePrincipalActions();

  const handleUserGroupSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    const ModalCloseOption: IModalCloseOptions = { id: null, status: "CLOSED" };

    if (selectedUserGroup) {
      setIsLoading(true);

      try {
        const { isSuccess } = await updateUserGroup({
          groupName: userGroupName.trim(),
          objectId: selectedUserGroup.id,
          userIds: selectedUsers.map((e) => e.id),
        });
        ModalCloseOption.status = isSuccess ? "SUCCESS" : "ERROR";
        ModalCloseOption.id = userGroupName.trim();
      } catch {
        setIsLoading(false);
        ModalCloseOption.status = "ERROR";
      }
    } else {
      setIsLoading(true);
      try {
        const { isSuccess } = await addUserGroup({
          groupName: userGroupName.trim(),
          userIds: selectedUsers.map((e) => e.id),
        });
        ModalCloseOption.status = isSuccess ? "SUCCESS" : "ERROR";
        ModalCloseOption.id = userGroupName.trim();
      } catch {
        setIsLoading(false);
        ModalCloseOption.status = "ERROR";
      }
    }

    handleClose(ModalCloseOption);
  };

  const validateForm = (): boolean => {
    return !userGroupName.trim();
  };

  return (
    <Dialog fullWidth maxWidth="xs" onClose={() => handleClose({ id: null, status: "CLOSED" })} open={open}>
      {isLoading && (
        <div className={classes.loadingIconContainer}>
          <SWIcon className={classes.loadingIcon} />
        </div>
      )}
      <DialogTitle disableTypography>
        <Typography component="h1" variant="h6">
          {selectedUserGroup ? intl.formatMessage({ id: "editGroup" }) : intl.formatMessage({ id: "createGroup" })}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form className={classes.form} id="userGroupForm" method="POST" onSubmit={handleUserGroupSubmit}>
          <TextField
            InputLabelProps={{ htmlFor: intl.formatMessage({ id: "groupName" }) }}
            autoFocus
            fullWidth
            inputProps={{ "aria-label": intl.formatMessage({ id: "groupName" }) }}
            label={intl.formatMessage({ id: "groupName" })}
            onChange={(e) => setUserGroupName(e.target.value)}
            required
            value={userGroupName}
            variant="filled"
          />
          <PrincipalMultipleSelect
            allPrincipals={users}
            category="users"
            selectedPrincipals={selectedUsers as IUser[]}
            setSelectedPrincipals={(users) => setSelectedUsers(users as IUser[])}
          />
          <DialogActions>
            <Button onClick={() => handleClose({ id: null, status: "CLOSED" })} variant="text">
              {intl.formatMessage({ id: "cancel" })}
            </Button>
            <Button
              color="primary"
              disabled={validateForm()}
              form="userGroupForm"
              size="small"
              type="submit"
              variant="contained"
            >
              {selectedUserGroup ? intl.formatMessage({ id: "save" }) : intl.formatMessage({ id: "create" })}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UserGroupModal;
