import * as React from "react";
import MetricChart, { IMetricChartSeriesOptions, useMetrics } from "../../../../components/MetricChart";
import { IPerformanceAnalysisChartProps } from "../../types";
import { usePerformanceAnalysisContext } from "../../context";

const metrics = Object.freeze<IMetricChartSeriesOptions>([
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#0D47A1",
    instance: null,
    label: "totalDtuPercent",
    metric: "azure.sqldb.dtu.total.pct",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#FF8C00",
    instance: null,
    label: "dataIO",
    metric: "azure.sqldb.dtu.dataio.pct",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#FF4500",
    instance: null,
    label: "logIO",
    metric: "azure.sqldb.dtu.logio.pct",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#32CD32",
    instance: null,
    label: "cpuPercent",
    metric: "azure.sqldb.dtu.cpu.pct",
  }),
]);

const ResourceUsage: React.FC<IPerformanceAnalysisChartProps> = ({ className }) => {
  const { contextMenuItems, dateRange, selectedRange, setSelectedRange, target } = usePerformanceAnalysisContext();
  const metricData = useMetrics({
    dateRange,
    metrics,
    target,
  });
  return (
    <MetricChart
      className={className}
      contextMenuItems={contextMenuItems}
      dateRange={dateRange}
      metricData={metricData}
      onSelectedRangeChange={setSelectedRange}
      selectedRange={selectedRange}
      target={target}
    />
  );
};

export default ResourceUsage;
