import ButtonBase from "@material-ui/core/ButtonBase";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { PopoverOrigin } from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import classNames from "classnames";
import * as React from "react";
import { useIntl } from "react-intl";
import { TopSqlChartType } from "../../types";
import { chartList, ChartSubMenuEnum } from "./types";

interface IChartSelectorItemProps {
  onChange: (chart: TopSqlChartType, subMenuSelection?: ChartSubMenuEnum | null) => void;
  selection: TopSqlChartType;
  subMenuSelection: ChartSubMenuEnum | null;
  type: TopSqlChartType;
}

const useStyles = makeStyles((theme) => ({
  hasSubMenu: {},
  menuItem: {
    "&$hasSubMenu": {
      // Adjust the padding when sub menu is present because of chevron size
      // The heights end up matching
      paddingBottom: theme.spacing(0.5),
      paddingTop: theme.spacing(0.5),
    },
    fontSize: theme.typography.body2.fontSize,
    height: "26px",
    padding: theme.spacing(),
  },
  menuItemPrimary: {
    flex: 1,
  },
}));

const anchorOrigin: PopoverOrigin = { horizontal: "left", vertical: "top" };

const ChartSelectorItem: React.FunctionComponent<IChartSelectorItemProps> = ({
  onChange,
  selection,
  subMenuSelection,
  type,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [subMenuOpen, setSubMenuOpen] = React.useState(false);
  const subMenuAnchor = React.useRef<HTMLButtonElement | null>(null);

  const chartMenu = chartList.find((c) => c.id === type);
  if (!chartMenu) {
    throw new Error(
      `Invalid chart menu type "${type}". Valid chart menu types are [${chartList.map((c) => `"${c.id}"`)}]`,
    );
  }
  const { subMenu } = chartMenu;
  const hasSubMenu = subMenu.length > 0;

  function handleSubMenuClick(subMenuOption: ChartSubMenuEnum): void {
    onChange(type, subMenuOption);
    setSubMenuOpen(false);
  }

  function handleSubMenuOpen(e: React.MouseEvent<HTMLElement>): void {
    e.stopPropagation();
    setSubMenuOpen(true);
  }

  return (
    <>
      <MenuItem
        aria-selected={type === selection}
        button
        classes={{
          root: classNames(classes.menuItem, {
            [classes.hasSubMenu]: hasSubMenu,
          }),
        }}
        onClick={() => onChange(type)}
        selected={type === selection}
      >
        <span className={classes.menuItemPrimary}>{intl.formatMessage({ id: type })}</span>
        {hasSubMenu && (
          <ButtonBase
            disableRipple
            onClick={handleSubMenuOpen}
            ref={subMenuAnchor}
            title={intl.formatMessage({ id: "clickToExpand" })}
          >
            <ChevronRightIcon />
          </ButtonBase>
        )}
      </MenuItem>
      {hasSubMenu && (
        <Menu
          anchorEl={subMenuAnchor.current}
          anchorOrigin={anchorOrigin}
          onClose={() => setSubMenuOpen(false)}
          open={subMenuOpen}
        >
          {subMenu.map((option) => (
            <MenuItem
              classes={{
                root: classes.menuItem,
              }}
              key={option.value}
              onClick={() => handleSubMenuClick(option.value)}
              selected={option.value === subMenuSelection}
            >
              {intl.formatMessage({ id: option.label })}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

export default ChartSelectorItem;
