import { useQuery } from "@apollo/react-hooks";
import * as GET_HEALTH_SCORE_HISTORY from "./getHealthScoreHistory.graphql";

// TYPES //
export type IAsyncResult<T> =
  | {
      data: undefined;
      error: Error;
      isLoading: false;
      refetch?: any;
      state: "error";
    }
  | {
      data: undefined;
      error: null;
      isLoading: true;
      refetch?: any;
      state: "loading";
    }
  | {
      data: T;
      error: null;
      isLoading: false;
      refetch?: any;
      state: "success";
    };

export interface IHealthScoreHistoryItemResponse {
    objectId: string;
    score: number;
    timestampUtc: Date;
}
export interface IHealthScoreHistoryRequest {
  endDateUtc: Date;
  objectId: string;
  startDateUtc: Date;
}

// QUERIES //
export function useGetHealthScoreHistory(healthScoreHistoryRequest: IHealthScoreHistoryRequest): IAsyncResult<IHealthScoreHistoryItemResponse[]> {
  const { data = { healthScoreHistory: [] }, error, loading } = useQuery<
    {
      healthScoreHistory: Array<IHealthScoreHistoryItemResponse>;
    },
    {
        healthScoreHistoryRequest: {
        dateTimeRangeUtc: {
          startDateTime: string;
          endDateTime: string;
        };
        objectId: string;
      };
    }
  >(GET_HEALTH_SCORE_HISTORY, {
    variables: {
      healthScoreHistoryRequest: {
        dateTimeRangeUtc: {
          endDateTime: healthScoreHistoryRequest.endDateUtc.toISOString(),
          startDateTime: healthScoreHistoryRequest.startDateUtc.toISOString(),
        },
        objectId: healthScoreHistoryRequest.objectId,
      },
    },
  });

  if (error) {
    return {
      data: undefined,
      error: error,
      isLoading: false,
      state: "error",
    };
  } else if (loading) {
    return {
      data: undefined,
      error: null,
      isLoading: true,
      state: "loading",
    };
  } else {
    return {
      data: data.healthScoreHistory,
      error: null,
      isLoading: false,
      state: "success",
    };
  }
}
