import { DateTime } from "luxon";
import { WaitsByTraceIntervalData, WaitsByTraceTransposedData } from "./types";

export const transposeWaitsData = (
  data: WaitsByTraceIntervalData[],
  waitTypes: string[],
): WaitsByTraceTransposedData[] => {
  return data.map((x) => {
    const newObject: WaitsByTraceTransposedData = {
      date: x.date,
      total: x.totalWaitMs,
    };
    for (let i = 0; i < waitTypes.length; i++) {
      const currWaitType: string = waitTypes[i];
      const currWaitData:
        | {
            waitType: string;
            waitMs: number;
          }
        | undefined = x.waits.find((y) => y.waitType == currWaitType);
      newObject[currWaitType] = currWaitData ? currWaitData.waitMs : 0;
    }
    return newObject;
  });
};

export const formatDate = (date: string): string => {
  const myDate = DateTime.fromISO(date);
  return myDate.setLocale("en-US").toLocaleString(DateTime.DATETIME_SHORT);
};
