import * as React from "react";
import MetricChart, { IMetricChartSeriesOptions, useMetrics } from "../../../../components/MetricChart";
import { ChartsReadColor, ChartsWriteColor, IDiskChartProps, IStorageEntity, StorageEntityType } from "../../types";
import { useStorageChartContext } from "./StorageChartContext";

function getMetricsForStorageEntity(storageEntity: IStorageEntity): IMetricChartSeriesOptions[] {
  switch (storageEntity.type) {
    case StorageEntityType.PhysicalDisk:
      return [
        {
          axisLabel: null,
          chartType: "line",
          color: ChartsReadColor,
          instance: `${storageEntity.diskIndex}`,
          label: "Read",
          metric: "os.disk.read.ms",
        },
        {
          axisLabel: null,
          chartType: "line",
          color: ChartsWriteColor,
          instance: `${storageEntity.diskIndex}`,
          label: "Write",
          metric: "os.disk.write.ms",
        },
      ];
    case StorageEntityType.SqlServerDataFile:
    case StorageEntityType.SqlServerLogFile:
      return [
        {
          axisLabel: null,
          chartType: "line",
          color: ChartsReadColor,
          instance: `${storageEntity.name} (${storageEntity.databaseName})`,
          label: "Read",
          metric: "sqlserver.filestats.read.ms",
        },
        {
          axisLabel: null,
          chartType: "line",
          color: ChartsWriteColor,
          instance: `${storageEntity.name} (${storageEntity.databaseName})`,
          label: "Write",
          metric: "sqlserver.filestats.write.ms",
        },
      ];
    case StorageEntityType.LogicalVolume:
    case StorageEntityType.DiskController:
    default:
      return [];
  }
}

const LatencyChart: React.FC<IDiskChartProps> = ({ className }) => {
  const { dateRange, selectedEntity, selectedRange, setSelectedRange, target } = useStorageChartContext();
  const metrics = getMetricsForStorageEntity(selectedEntity);

  const metricData = useMetrics({
    dateRange,
    metrics: metrics,
    target,
  });

  return (
    <MetricChart
      className={className}
      dateRange={dateRange}
      disableContextMenu
      metricData={metricData}
      onSelectedRangeChange={setSelectedRange}
      selectedRange={selectedRange}
      target={target}
    />
  );
};

export default LatencyChart;
