import { MoRef } from "../../utilities/TopologyUtility";
export type ChartDisplayType = "line" | "stackedArea" | "stackedBar";

export const ALL_INSTANCES = Symbol("ALL_INSTANCES");
export const TOTAL_INSTANCE = "_Total";

export type MetricChartInstanceThunk<T extends boolean | number | string | null | undefined> =
  | ((instance: string | null) => T)
  | T;

/**
 * Defines metric chart series options for a dynamic set of instances returned by the API.
 * All instances are requested and returned.
 * Options can be defined as a scalar value or a varied value based on the instance using a callback.
 */
export interface IMetricChartSeriesDynamicOptions {
  readonly axisLabel: MetricChartInstanceThunk<string | null>;
  readonly chartType: MetricChartInstanceThunk<ChartDisplayType>;
  readonly color: MetricChartInstanceThunk<string>;
  /**
   * Whether to enable the default axis scale.
   * Defaults to `true`.
   * @default true
   */
  readonly enableDefaultScale?: MetricChartInstanceThunk<boolean>;
  readonly instance: typeof ALL_INSTANCES;
  /**
   * Label to display in legend and tooltip
   * @default metric[: instance]
   */
  readonly label: MetricChartInstanceThunk<string | null>;
  readonly metric: string;
  readonly moRef?: MoRef;
  /**
   * Sorts the instances by the specified function.
   * Defaults to a no-op that maintains the original order.
   */
  readonly sort?: (a: IMetricChartSeries, b: IMetricChartSeries) => number;
  readonly strokeColor?: MetricChartInstanceThunk<string>;
}

/**
 * Defines metric chart series options for a single instance.
 */
export interface IMetricChartSeriesInstanceOptions {
  readonly axisLabel: string | null;
  readonly chartType: ChartDisplayType;
  readonly color: string;
  /**
   * Whether to enable the default axis scale.
   * Defaults to `true`.
   * @default true
   */
  readonly enableDefaultScale?: boolean;
  readonly instance: string | null;
  /**
   * Label to display in legend and tooltip
   * @default metric[: instance]
   */
  readonly moRef?: MoRef;
  readonly label: string | null;
  readonly metric: string;
  readonly strokeColor?: string;
}

export type IMetricChartSeriesOptions = IMetricChartSeriesDynamicOptions | IMetricChartSeriesInstanceOptions;

export interface IMetricChartPoint {
  readonly timestamp: Date;
  /**
   * Assigning tooltip data to a point replaces the default tooltip with one that displays only this point's data.
   */
  readonly tooltip?: readonly IMetricChartTooltipGroup[];
  readonly value: number;
}

export interface IMetricChartSeries {
  readonly axisLabel: string | null;
  readonly chartType: ChartDisplayType;
  readonly color: string;
  /** Whether to enable the default axis scale. */
  readonly enableDefaultScale: boolean;
  readonly instance: string | null;
  readonly interval: string;
  readonly key: string;
  readonly label: string | null;
  readonly metric: string;
  readonly points: readonly IMetricChartPoint[];
  readonly strokeColor?: string;
  readonly unitOfMeasure: MetricUnitOfMeasure;
  readonly targetName?: string;
}

export interface IMetricChartTooltipEntry {
  readonly name: string;
  readonly value: number;
}

export interface IMetricChartTooltipGroup {
  readonly entries: readonly IMetricChartTooltipEntry[];
  readonly groupName: string;
}

export type MetricInstanceKey = string | null | typeof ALL_INSTANCES;

export const enum MetricUnitOfMeasure {
  Bitrate = "BITRATE",
  Bytes = "BYTES",
  BytesPerSecond = "BYTES_PER_SECOND",
  Millisecond = "MILLISECOND",
  Packets = "PACKETS",
  Pages = "PAGES",
  PagesPerSecond = "PAGES_PER_SECOND",
  Percent = "PERCENT",
  PerSecond = "PER_SECOND",
  Second = "SECOND",
  Unspecified = "UNSPECIFIED",
}

export enum AggregateTypes {
  SUM = "SUM",
  MAX = "MAX",
  AVERAGE = "AVERAGE",
}
