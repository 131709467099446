import React, { ReactElement, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Backdrop,
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Fade,
  Grid,
  Modal,
  Paper,
  TextField,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { ITopologyItemDevice, ITopologyItemEventSourceConnection } from "../../../../components/TopologyContext";
import { IDateRange } from "../../../../components/DateContext";
import WaitsByTraceChart from "./WaitsByTraceChart";
import { IExecutedQueryTraceEventsResponse } from "../../../../api/models/IExecutedQueryTraceEventsCriteria";

const useStyles = makeStyles((theme) => ({
  modal: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

type IWaitsByTraceModalProps = {
  target: ITopologyItemEventSourceConnection | ITopologyItemDevice;
  totals_row: any;
  visibleDates: Readonly<IDateRange>;
  wait_ms: number;
};

const WaitsByTraceModal = ({ target, totals_row, visibleDates, wait_ms }: IWaitsByTraceModalProps): ReactElement => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const intl = useIntl();

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Button
        color="primary"
        onClick={() => setOpen(true)}
        title={intl.formatMessage({ id: "traceEventWaitsChart" })}
        variant="text"
      >
        {wait_ms}
      </Button>
      <Modal
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        aria-label="transition-modal-title"
        className={classes.modal}
        closeAfterTransition
        onClick={(e) => e.stopPropagation()}
        onClose={handleClose}
        open={open}
      >
        <Fade in={open}>
          <Paper className={classes.paper}>
            <DialogTitle>{intl.formatMessage({ id: "traceEventWaits" })}</DialogTitle>
            <DialogContent style={{ textAlign: "center" }}>
              <Box sx={{ flexGrow: 1, marginBottom: "20px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      inputProps={{ readOnly: true }}
                      label="Text Data"
                      size="small"
                      value={totals_row.textData}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      inputProps={{ readOnly: true }}
                      label="Database Name"
                      size="small"
                      value={totals_row.databaseName}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      inputProps={{ readOnly: true }}
                      label="Event Class"
                      size="small"
                      value={totals_row.eventClass}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      inputProps={{ readOnly: true }}
                      label="Duration"
                      size="small"
                      value={totals_row.duration}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      inputProps={{ readOnly: true }}
                      label="CPU"
                      size="small"
                      value={totals_row.cpu}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      inputProps={{ readOnly: true }}
                      label="Reads (L)"
                      size="small"
                      value={totals_row.readsL}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      inputProps={{ readOnly: true }}
                      label="Writes (L)"
                      size="small"
                      value={totals_row.writesL}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      inputProps={{ readOnly: true }}
                      label="Waits (ms)"
                      size="small"
                      value={totals_row.waitMS}
                    />
                  </Grid>
                </Grid>
              </Box>
              <WaitsByTraceChart
                event={totals_row}
                height={350}
                target={target}
                visibleDates={visibleDates}
                width={1100}
              ></WaitsByTraceChart>
            </DialogContent>
          </Paper>
        </Fade>
      </Modal>
    </>
  );
};

export default WaitsByTraceModal;
