import Badge from "@material-ui/core/Badge";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsNone from "@material-ui/icons/NotificationsNone";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, Route } from "react-router-dom";
import { useUID } from "react-uid";
import { PerformanceAdvisorService } from "../../../../api/PerformanceAdvisor";
import { EventLogSeverity } from "../../../../api/PerformanceAdvisor/models/EventLog";
import { useDateContext } from "../../../../components/DateContext";
import { TopologyObjectType, useTopology } from "../../../../components/TopologyContext";
import { useAsync } from "../../../../hooks/Async";
import { INotificationItem, NotificationItem } from "./NotificationItem";

interface INotificationsProps {
  styleSheet?: INotificationStyleSheet;
}

interface INotificationStyleSheet {
  drawer?: string;
  span?: string;
  title?: string;
  typography?: string;
}

function useNotifications(): readonly INotificationItem[] {
  const { dateRange } = useDateContext();
  const topology = useTopology();

  const fetchFn = React.useCallback(
    (_: never, controller: AbortController) => {
      const service = new PerformanceAdvisorService();
      return service.fetchAggregateEventLogs(
        {
          endDate: dateRange.to,
          objectType: TopologyObjectType.global,
          startDate: dateRange.from,
        },
        controller,
      );
    },
    [dateRange.from, dateRange.to],
  );

  const { data = [] } = useAsync(fetchFn);

  const allNotificationItems = React.useMemo<INotificationItem[]>(() => {
    return data
      .filter((i) => !!i && i.severity === EventLogSeverity.critical)
      .reduce<INotificationItem[]>((prev, i) => {
        const topologyItem = topology.findByObjectId(i.objectId);
        if (topologyItem) {
          const device = topologyItem.parentObjectId
            ? topology.findByObjectId(topologyItem.parentObjectId, TopologyObjectType.device)
            : null;
          prev.push({
            conditionId: i.conditionId,
            conditionName: i.conditionName,
            deviceId: device ? device.itemId : undefined,
            itemId: i.itemId,
            name: topologyItem.name,
            severity: i.severity,
          });
        }
        return prev;
      }, []);
  }, [data, topology]);

  return allNotificationItems;
}

const Notifications: React.FunctionComponent<INotificationsProps> = ({ styleSheet = {} }) => {
  const intl = useIntl();
  const [open, setOpen] = React.useState(false);
  const id = useUID();
  const criticalNotificationItems = useNotifications();
  const notificationCount = criticalNotificationItems.length;

  return (
    <>
      <IconButton
        aria-controls={id}
        aria-expanded={open}
        aria-haspopup
        onClick={() => setOpen(true)}
        title={intl.formatMessage({ id: "notifications" })}
      >
        {!notificationCount ? (
          <NotificationsNone />
        ) : (
          <Badge badgeContent={notificationCount} color="error">
            <NotificationsIcon className="telemetry-nav-notifications" />
          </Badge>
        )}
      </IconButton>
      <Drawer anchor="right" classes={{ paper: styleSheet.drawer }} id={id} onClose={() => setOpen(false)} open={open}>
        <span>
          <Typography className={styleSheet.title} variant="h1">
            <FormattedMessage id="notifications" />
          </Typography>
          <Route
            path="/:tenant"
            render={({ match }) => (
              <List>
                {notificationCount > 0 ? (
                  criticalNotificationItems.map((i) => (
                    <ListItem
                      button
                      component={Link as any}
                      {...{ to: `/${match.params.tenant}/overview/targets/${i.deviceId}/instances/${i.itemId}` }}
                      divider
                      key={`${i.itemId}-${i.conditionId}`}
                    >
                      <NotificationItem
                        item={i}
                        styleSheet={{
                          span: styleSheet.span,
                          typography: styleSheet.typography,
                        }}
                      />
                    </ListItem>
                  ))
                ) : (
                  <ListItem>
                    <FormattedMessage id="noNotifications" />
                  </ListItem>
                )}
              </List>
            )}
          />
        </span>
      </Drawer>
    </>
  );
};

export default Notifications;
