import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import * as React from "react";
import { NoDataIcon } from "../icons";

const useStyles = makeStyles((theme) => ({
  chartPlaceholder: {
    marginTop: -theme.spacing(6),
  },
  content: {
    textAlign: "right",
  },
  placeholder: {
    fill: "none",
    height: "12rem",
    maxHeight: "100%",
    width: "12rem",
  },
  root: {
    alignItems: "center",
    display: "grid",
    gridColumnGap: "5vw",
    gridTemplateColumns: "1fr 1fr",
    justifyContent: "center",
  },
  title: {
    fontWeight: theme.typography.h5.fontWeight,
  },
}));

export interface IFeatureDisabledProps {
  className?: string;
  action: React.ReactElement;
  message: string | Array<string>;
  style?: React.CSSProperties;
  title: string;
  titleComponent?: React.ElementType<any>;
}

const FeatureDisabled: React.FunctionComponent<IFeatureDisabledProps> = ({
  className,
  action,
  message,
  style,
  title,
  titleComponent = "h1",
}) => {
  const classes = useStyles();
  const messages: string[] = message instanceof Array ? message : [message];
  return (
    <div className={classNames(classes.root, className)} style={{ ...style }}>
      <div className={classes.content}>
        <Typography className={classes.title} component={titleComponent} gutterBottom variant="h5">
          {title}
        </Typography>
        {messages.map((msg) => (
          <Typography component="p" gutterBottom key={msg} variant="h5">
            {msg}
          </Typography>
        ))}
        {action}
      </div>
      <NoDataIcon className={classes.placeholder} />
    </div>
  );
};

export default FeatureDisabled;
