import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import classNames from "classnames";
import * as React from "react";
import { useIntl } from "react-intl";
import { IMatchedPlanStatement } from "../../../../api/TopSqlService/models/PlanData";
import ErrorBoundary from "../../../../components/ErrorBoundary";
import QueryText from "../../../../components/QueryText";
import ParametersGrid from "./components/ParametersGrid";
import PlanDiagram from "./components/PlanDiagram";
import PlanXML from "./components/PlanXML";

export enum StatementDetailsTab {
  Parameters,
  PlanDiagram,
  QueryText,
  PlanXML,
}

interface IStatementDetailsContentProps {
  fullScreen?: boolean;
  isStatementSelected: boolean;
  loading: boolean;
  matchedPlanStatement: IMatchedPlanStatement | null;
  onTabChange: (tab: StatementDetailsTab) => void;
  onToggleFullScreen: () => void;
  selectedTab: StatementDetailsTab;
  textData?: string;
  animate: boolean;
}

const tab = {
  "&:not($activeTab)": {
    display: "none",
  },
  flex: 1,
  justifyContent: "center",
};

const useStyles = makeStyles((theme) => ({
  activeTab: {},
  error: {
    flex: 1,
  },
  fullScreenToggle: {
    flexGrow: "inherit",
    minWidth: "4rem",
  },
  parameters: {
    display: "flex",
    flex: 1,
  },
  planDiagram: {
    "& canvas": {
      "&:focus": {
        WebkitTapHighlightColor: "rgba(255, 255, 255, 0)",
        outline: "none",
      },
      WebkitTapHighlightColor: "rgba(255, 255, 255, 0)",
      outline: "none",
    },
  },
  planDiagramTab: {
    ...tab,
    overflow: "hidden",
  },
  queryText: {
    "& pre": {
      overflow: "visible",
    },
  },
  tab: {
    ...tab,
    overflow: "auto",
  },
}));

const fullScreenValue = "fullscreen" as const;
const resolveTelemetryId = (fullScreen: boolean): string => {
  return fullScreen ? "telemetry-topSql-statementDetails-fullScreen" : "telemetry-topSql-statementDetails";
};

const StatementDetailsContent: React.FunctionComponent<IStatementDetailsContentProps> = ({
  fullScreen = false,
  loading,
  matchedPlanStatement,
  onTabChange,
  onToggleFullScreen,
  selectedTab,
  textData,
  animate,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  return (
    <>
      <Tabs
        className={resolveTelemetryId(fullScreen)}
        onChange={(_, tab: StatementDetailsTab | typeof fullScreenValue) => {
          if (tab !== fullScreenValue) {
            onTabChange(tab);
          }
        }}
        scrollButtons="auto"
        value={selectedTab}
        variant="scrollable"
      >
        <Tab
          className={classes.fullScreenToggle}
          icon={fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          onClick={onToggleFullScreen}
          title={intl.formatMessage({ id: fullScreen ? "exitFullScreen" : "fullScreen" })}
          value={fullScreenValue}
        />
        <Tab label={intl.formatMessage({ id: "planDiagram" })} value={StatementDetailsTab.PlanDiagram} />
        <Tab label={intl.formatMessage({ id: "queryText" })} value={StatementDetailsTab.QueryText} />
        <Tab label={intl.formatMessage({ id: "parameters" })} value={StatementDetailsTab.Parameters} />
        <Tab label={intl.formatMessage({ id: "rawXML" })} value={StatementDetailsTab.PlanXML} />
      </Tabs>
      <ErrorBoundary fallbackClassName={classes.error} key={selectedTab} variant="generic">
        {selectedTab === StatementDetailsTab.PlanDiagram && (
          <PlanDiagram
            animate={!fullScreen && animate}
            className={classNames(classes.planDiagramTab, classes.planDiagram, "telemetry-planDiagram", {
              [classes.activeTab]: selectedTab === StatementDetailsTab.PlanDiagram,
            })}
            loading={loading}
          />
        )}
        <QueryText
          className={classNames(classes.tab, classes.queryText, {
            [classes.activeTab]: selectedTab === StatementDetailsTab.QueryText,
          })}
          textData={textData}
        />
        <ParametersGrid
          className={classNames(classes.tab, classes.parameters, {
            [classes.activeTab]: selectedTab === StatementDetailsTab.Parameters,
          })}
          data={matchedPlanStatement?.planStatement.parameterInfo ?? undefined}
        />
        <PlanXML
          className={classNames(classes.tab, {
            [classes.activeTab]: selectedTab === StatementDetailsTab.PlanXML,
          })}
        />
      </ErrorBoundary>
    </>
  );
};

export default StatementDetailsContent;
