import { ITranslation } from "../types";

const messages: ITranslation = {
  "#days": `Resolution: {days} {days, plural,
    one {day}
    other {days}
  }`,
  "#hours": `Resolution: {hours} {hours, plural,
    one {hours}
    other {hours}
  }`,
  "#minutes": `Resolution: {minutes} {minutes, plural,
    one {minute}
    other {minutes}
  }`,
  "#seconds": `Resolution: {seconds} {seconds, plural,
    one {second}
    other {seconds}
  }`,
  "(system process)": "(system process)",
  "(unknown)": "(unknown)",
  abort: "Abort",
  accountBeingConfigured: `
    Your account is being configured, please check back in a minute or two.<br/>
    In the meantime, check out our
    <a href="https://docs.sentryone.com/help/sentryone-universal-client"> documentation </a>.`,
  actionType: "Action Type",
  active: "Active",
  activeTempDb: "Active TempDB",
  activeTempDbInternal: "Active TempDB Internal",
  activeTempDbUser: "Active TempDB User",
  activeTempTables: "Active Temp Tables",
  activeVlfs: "Active VLFs",
  activity: "Activity",
  actualAverage: "Actual/Average",
  add: "Add",
  addCharts: "Add Charts",
  addMetric: "Add Metric",
  addNew: "Add New",
  addNote: "Add Note",
  addTargetsByFollowingThisLink:
    "Add targets from the SQL Sentry Client by following the steps outlined in our documentation here:",
  addUpTo10Metrics: "Add up to 10 metrics",
  addWidgets: "Add Widgets",
  administratorInfoTT: "Access to security controls",
  advisory: "Advisory",
  advisoryConditions: "Advisory Conditions",
  advisoryEvents: "Advisory Events",
  agentName: "Agent Name",
  alertConfiguration: "Alert",
  alertDetails: "Alert Details",
  alertingChannelLogStatus: "Alerting Channel Log Status",
  alerts: "Alerts",
  alertsBySeverity: "Alerts by Severity",
  alertsByTag: "Alerts by Tag",
  alertsCount: "Alert Count",
  alertsInfoTT: "Access to Events Log",
  alertsLog: "Alerts Log",
  all: "All",
  allSqlServers: "All SQL Servers",
  allWindowsServers: "All Windows Servers",
  allocatedMemoryUsage: "Allocated Memory Usage",
  almostDone: "Almost done...",
  amazonRds: "Amazon RDS",
  amount: "AMOUNT",
  anUnexpectedErrorOccurredWhileLoadingThisPage: "An unexpected error occurred while loading this page.",
  apiReturnedError: "The API Returned an Error",
  application: "Application",
  applicationName: "Application Name",
  applicationVersion: "Version {version}",
  applications: "Applications",
  applock: "App Lock",
  apply: "Apply",
  applyFilter: "Apply Filter",
  areYouSureDeleteDashboard: "Are you sure you want to delete this dashboard?",
  areYouSureDeleteGroup: "Are you sure you want to delete this group?",
  areYouSureDeleteNote: "Are you sure you want to delete this note?",
  areYouSureDeleteUser: "Are you sure you want to delete this user?",
  area: "Area",
  assignedUser: "Assigned User",
  atRisk: "At Risk",
  authentication: "Authentication",
  autogrowth: "Autogrowth",
  autogrowthVlfCount: "Autogrowth VLF Count",
  autogrowthVlfSize: "Autogrowth VLF Size",
  available: "Available",
  availableWidgets: "Available Widgets",
  averageSeconds: "Average Time (sec)",
  avgDurationMs: "Avg Duration (ms)",
  avgPrefix: "Avg",
  azureSqlDb: "Azure SQL Database",
  back: "Back",
  backToConditions: "Back to Conditions",
  backToStatements: "Back to Statements",
  backToTotals: "Back to Totals",
  backupRestoreMb: "Backup/Restore MB",
  bar: "Bar",
  batches: "Batches",
  blankDashboard: "Blank Dashboard",
  blocked: "Blocked",
  blocking: "Blocking",
  blockingFilters: "Blocking Filters",
  browserAlertDismiss: "Dismiss",
  browserAlertHeader: "Improve Your Experience",
  browserAlertMain:
    "You are visiting this site with an unsupported browser. For the best SentryOne experience, we recommend using Chrome or Microsoft Edge.",
  buffer: "Buffer",
  byApp: "By App",
  byApplication: "by Application",
  byDatabase: "By DB",
  byHost: "By Host",
  byLogin: "By Login",
  byResource: "by Resource",
  byWaitResource: "by Wait Resource",
  byWaitType: "By Wait Type",
  bytesPerRead: "Bytes/Read",
  bytesPerWrite: "Bytes/Write",
  callStack: "Call Stack",
  cancel: "Cancel",
  cancelSelection: "Cancel Selection",
  cancelTargetSave: "Cancel",
  cancelled: "Cancelled",
  cannotclosethisalert: "Can not close this alert",
  capacity: "Capacity",
  category: "Category",
  changeApplication: "Change Application",
  changePassword: "Change Password",
  changeTarget: "Change Target",
  changeVersion: "Change Version",
  chartFormatError: "Error Loading Chart. Please open chart editor to resolve.",
  chartName: "CHART NAME",
  chartSidebar: "Chart Sidebar",
  chartType: "CHART TYPE",
  checkpointPages: "Checkpoint Pages",
  clear: "Clear",
  clickTheButtonToAddWidgets: "Let's change that. Click the Add Widgets button to get started.",
  clickToExpand: "Click to expand",
  cloneExisting: "Clone Existing",
  close: "Close",
  closeConfig: "Close Config",
  closeEditing: "Close Editing",
  closeForCondition: "Close for Condition",
  closeForObject: "Close for Object",
  closed: "Closed",
  collapse: "Collapse...",
  collapseItem: "Collapse {item}",
  collapseSpid: "Collapse SPID {spid}",
  collapseTarget: "Collapse Target {MoRefID}",
  collectWindowsPerformanceData: "Collect Windows Performance Data",
  collectWindowsPerformanceNotice: "Please configure Windows Performance Data collection.",
  color: "Color",
  column: "Column",
  columnStore: "Column Store",
  compiledValue: "Compiled Value",
  compiles: "Compiles",
  completed: "Completed",
  condition: "Condition",
  conditionType: "Condition Type",
  conditions: "Conditions",
  configWidget: "Configure Widget",
  configuration: "Configuration",
  configure: "Configure",
  confirm: "Confirm",
  confirmNavigation: "Are you sure you want to leave? Unsaved changes will be lost.",
  confirmationDefaultTitle: "Confirm",
  connectingToDatabases: "Sit tight while we connect to your databases...",
  contactAdminOrSupport: "Contact your Sentryone Administrator or visit customer support.",
  context: "Context",
  copied: "Copied!",
  copy: "Copy",
  count: "Count",
  cpu: "CPU",
  cpuMs: "CPU (ms)",
  cpuPercent: "CPU %",
  cpuTimeMs: "CPU Time (ms)",
  cpuVM: "CPU (VM)",
  create: "Create",
  createANewDashboard: "Create a New Dashboard",
  createANewDashboardOrCloneAnExistingOne: "Create a new dashboard or clone an existing one.",
  createDashboard: "Create Dashboard",
  createGroup: "Create Group",
  createNewGroup: "Create New Group",
  createNewUser: "Create New User",
  createUser: "Create User",
  created: "Created",
  critical: "Critical",
  custom: "Custom",
  customChart: "Custom Chart",
  customDashboard: "Custom Dashboard",
  customDashboards: "Custom Dashboards",
  customDateRange: "Custom Date Range:",
  customTarget: "Custom Target:",
  customTimeRange: "Custom Time Range",
  customTimeRangeInfoTooltip:
    "Lock this chart into using a specific time range instead of inheriting the global time range.",
  customizeChart: "Customize Chart",
  dashboard: "Dashboard",
  dashboardDeletionCannotBeUndone: "Dashboard deletion cannot be undone",
  dashboardName: "Dashboard Name",
  dashboardNameInUse: "Dashboard Name already in use",
  dashboardSaveError: "Something went wrong while saving dashboard",
  dashboardTemplate: "Dashboard Template",
  dashboards: "Dashboards",
  dataIO: "Data I/O",
  database: "Database",
  databaseIO: "Database I/O",
  databaseName: "Database Name",
  databaseSize: "Database Size",
  databaselock: "Database Lock",
  databases: "Databases",
  databasesWithoutBackup: "Databases without backup",
  day: "Day",
  days: "Days",
  deadlock: "Deadlock",
  deadlockPriority: "Deadlock Priority",
  deadlocks: "Deadlocks",
  defaultDashboard: "Default Dashboard",
  delete: "Delete",
  deleteDashboard: "Delete dashboard",
  deleteDashboardConfirmation: "Delete Dashboard Confirmation",
  deleteGroup: "Delete Group",
  deleteNote: "Delete Note",
  deleteTarget: "Delete Target",
  deleteTargetButton: "Delete Target",
  deleteTargetConfirmation:
    "Are you sure you want to delete this target? This action will remove all of the data for this target and can not be undone.",
  deleteTargetTitle: "Delete Target",
  deleteUser: "Delete User",
  delta: "Delta",
  deltaFilter:
    "This score represents the difference between the current score and the last average Health Score we have for the targets.",
  details: "Details",
  detailsTitle: "Details",
  devices: "Devices",
  digest: "Dashboard Digest",
  disabled: "Disabled",
  disabledChartSelectMessage:
    "If an Area or Column metric has been added, any new metrics with a different unit of measure will be locked into Line.",
  discardChangesAndExit: "Discard changes and exit?",
  disk: "Disk",
  diskSpace: "Disk Space",
  diskVM: "Disk (VM)",
  doNotSave: "Do Not Save",
  document: "Document",
  documentation: "Documentation",
  done: "done",
  downloadPlan: "Download Plan",
  downtime: "Downtime",
  dragToStart: "Drag a widget here to begin.",
  dtu: "DTU",
  duplicateTargetException: "Error: Target already exists",
  duration: "Duration",
  durationMs: "Duration (ms)",
  dynamicConditionGrid: "CONFIGURATION",
  dynamicConditionStatus: "Evaluation Status",
  ecid: "ecid",
  edit: "Edit",
  editDashboard: "Edit Dashboard",
  editGroup: "Edit Group",
  editNote: "Edit Note",
  editUser: "Edit User",
  email: "Email",
  enabled: "Is Enabled",
  end: "End",
  endTime: "End Time",
  enterNumber: "Enter Number",
  enterStartDatePrecedingEndDate: "Start date must precede end date",
  enterValidEmail: "Enter a valid Email Address",
  environmentNavigation: "Environment Navigation",
  environmentScoreSheet: "Environment Score Sheet",
  error: "Error",
  errorConnecting: "There was an error connecting to",
  errorCopiedMessage: "Error details copied successfully!",
  errorCopiedTitle: "Copy the error details",
  errorDetails: "Error Details",
  errorLoadingData: "Error loading chart",
  evaluationColumnHeader: "Evaluation",
  evaluationDuration: "Evaluation Duration",
  evaluationType: "Evaluation Type",
  eventClass: "Event Class",
  eventSourceConnections: "Event Source Connections",
  events: "Events",
  eventsGrid: "LOG",
  exception: "Exception",
  exchangeEvent: "Exchange Event",
  execCount: "Exec Count",
  exitFullScreen: "Exit Full Screen",
  exitSavingChanges: "Exit Saving Changes",
  exitWithoutSaving: "Exit without Saving",
  expand: "Expand",
  expandItem: "Expand {item}",
  expandSpid: "Expand SPID {spid}",
  expandTarget: "Expand Target {MoRefID}",
  extentlock: "Extent Lock",
  failedToConnect: "{tenant} - Failed to connect to server.",
  false: "False",
  faulted: "Faulted",
  featureAccess: "Feature Access",
  featureRequest: "Submit a feature request",
  feedback: "Feedback",
  fileCache: "File Cache",
  fileGroup: "File Group",
  filePath: "File Path",
  fileSystem: "File System",
  filter: "Filter",
  filterDashboards: "Filter Dashboards",
  filterMsg: "Begin typing to filter...",
  filterTargets: "Filter targets.",
  filterUsers: "Filter users.",
  filterUsersGroups: "Filter users/groups.",
  firstName: "First Name",
  forecastedExhaustion: "Forecasted Exhaustion",
  //if we change this value, we need to update the Forecast chart TOTAL_LEGEND_WIDTH to the new SVG Width
  forecastedFreeSpace: "Forecasted Free Space",
  forwardRecords: "Forward Records",
  free: "Free",
  //if we change this value, we need to update the Forecast chart TOTAL_LEGEND_WIDTH and FREE_SPACE_LEGEND_WIDTH to the new SVG Width
  freeSpace: "Free Space",
  fullScreen: "Full Screen",
  futureDateNotAllowed: "Future date/time is not allowed",
  global: "Global",
  goBack: "Go Back",
  goTo: "Go to",
  grantedMemoryKB: "Granted Memory (KB)",
  grantedQueryMemoryKB: "Granted Query Memory (KB)",
  group: "Group",
  groupName: "Group Name",
  grouping: "Grouping",
  groups: "Groups",
  hasPlan: "Plan",
  hasStatements: "Statements",
  headBlockers: "Head Blockers",
  health: "Health",
  healthOverview: "Health Overview",
  healthScore: "Health Score",
  healthScoreForTarget: "health score for {name}",
  healthy: "Healthy",
  help: "Help",
  hideAxisLabels: "Hide Axis Labels",
  high: "High",
  host: "Host",
  hostName: "Host",
  hostProcessId: "Host Process ID",
  hosts: "Hosts",
  hour: "Hour",
  hours: "Hours",
  ifIssuePersistsContactSupport: "If this issue persists, please contact support.",
  in: "In",
  inMemOltp: "In-Mem OLTP",
  inProgress: "In Progress",
  includeInNotifications: "Include In Notifications",
  index: "Index",
  info: "Info",
  instance: "Instance",
  instances: "Instances",
  integerData: "Information",
  internalAllocated: "Internal Allocated",
  internalDeallocated: "Internal Deallocated",
  internalObjects: "Internal Objects",
  io: "IO",
  ioPerSecond: "IOPS",
  isolationLevel: "Isolation Level",
  jumpTo: "Jump to...",
  jumpToBlocking: "Jump to Blocking",
  jumpToCurrentTime: "Jump to Current Time",
  jumpToTopSql: "Jump to Top SQL",
  keyLookups: "Key Lookups",
  keylock: "Key Lock",
  last: "Last ",
  last4Hours: "Last 4 Hours",
  last8Hours: "Last 8 Hours",
  last24Hours: "Last 24 Hours",
  lastBackupTime: "Last Backup Time",
  lastBackupType: "Last Backup Type",
  lastError: "Last Error",
  lastEvaluationTime: "Last Evaluation Time",
  lastExecution: "Last Execution",
  lastFiveDays: "Last 5 Days",
  lastHour: "Last Hour",
  lastMonth: "Last Month",
  lastName: "Last Name",
  lastOccurrence: "Last Occurrence",
  lastResult: "Last Result",
  lastTransactionStartDateTime: "Last Transaction Start Time",
  lastWeek: "Last Week",
  latency: "Latency (ms)",
  lazyWrites: "Lazy Writes",
  legendLabel: "Legend Label",
  line: "Line",
  lineNumber: "Line Number",
  loading: "Loading",
  loadingCustomDashboards: "Loading custom dashboards...",
  loadingDotDotDot: "Loading...",
  loadingTargets: "Loading targets...",
  lockMode: "Lock Mode",
  lockType: "Lock Type",
  locks: "Locks",
  logFlushes: "Log Flushes",
  logIO: "Log I/O",
  logReuseWait: "Log Reuse Wait",
  logTime: "Log Time",
  logUsed: "Log Used",
  login: "Login",
  logout: "Logout",
  low: "Low",
  lowerBound: "Lower Bound",
  manufacturer: "Manufacturer",
  maxEndTime: "Max End Time",
  maxFileSize: "Max File Size",
  maxGrantedMemory: "Max Granted Mem",
  maxInboundRate: "Max Inbound Rate",
  maxOutboundRate: "Max Outbound Rate",
  maxVlfSize: "Max VLF Size",
  maximumScore: "Maximum Score",
  mbPerSecond: "MB/sec",
  medium: "Medium",
  memory: "Memory",
  memoryUsage: "Memory Usage",
  menu: "Menu",
  message: "Message",
  metric: "Metric",
  metrics: "METRICS",
  minStartTime: "Min Start Time",
  minVlfSize: "Min VLF Size",
  minimumWaitTimePerSession: "Minimum Wait Time/Session",
  minutes: "Minutes",
  mixedExtents: "Mixed Extents",
  mode: "Mode",
  monitor: "Monitor",
  months: "Months",
  moreHistoricalMetricsInfoTooltip:
    "Determines how far back in time to go for chart preview, and to determine which instances populate the metric instances list.",
  msRead: "ms/Read",
  msSession: "ms/session",
  msWrite: "ms/Write",
  myserverDomainComPort: "myserver.domain.com:port",
  name: "Name",
  network: "Network",
  networkVM: "Network (VM)",
  newTarget: "New Target",
  nextButton: "Next",
  no: "No",
  noAccessOrDoesNotExist: "You may not have access to the target, or it no longer exists.",
  noDashboardsInDigest: "You don't have any dashboards",
  noData: "No data",
  noDataForTargetAvailable: "No data for the specified target is available.",
  noDataToDisplay: "There is no data to display",
  noDatabasesAvailable:
    "You do not have access to the specified SentryOne database. Please contact your administrator for access.",
  noFilterSelected: "Please select at least one object to view in each category",
  noForeacstDataMsg: "SQL Sentry does not have enough data to generate an accurate forecast. Please check back later.",
  noNotifications: "There are no notifications to display",
  noTargetsAreBeingMonitored: "No targets are currently being monitored.",
  noWidgets: "No widgets added yet. Open editing window to start.",
  nodeDetails: "Node Details",
  none: "None",
  nonsnapshotVersionTransactions: "Non-snapshot Version Transactions",
  notAvailable: "N/A",
  notAvailableInDemo: "This feature is not available in demo mode.",
  noteAddedSuccessfully: "Note Has Been Successfully Added",
  noteAddedWithError: "An Error Occurred While Adding This Note",
  noteDeletedSuccessfully: "Note Has Been Successfully Deleted",
  noteDeletedWithError: "An Error Occurred While Deleting This Note",
  noteEditedSuccessfully: "Note Has Been Successfully Edited",
  noteEditedWithError: "An Error Occurred While Editing This Note",
  noteText: "Note Text",
  noteTextField: "Note text field",
  notes: "Notes",
  nothingToSeeHere: "Nothing to see here. Make sure the necessary conditions are configured and check back soon.",
  notifications: "Notifications",
  object: "Object",
  objectCount: "Object Count",
  objectName: "Object Name",
  objectlock: "Object Lock",
  objects: "Objects",
  ok: "OK",
  onDemand: "On Demand",
  oneDay: "1 day",
  oneHour: "1 hour",
  oneWeek: "1 week",
  openMenu: "Open menu",
  openTargetInSqlSentry: "Open target in SQLSentry",
  openTransactionCount: "Open Transaction Count",
  options: "Options",
  organizationSettings: "Organization Settings",
  os: "OS",
  other: "Other",
  otherSpace: "Other Space",
  out: "Out",
  overview: "Overview",
  owner: "Owner",
  pagelock: "Page Lock",
  pages: "Pages",
  pagesReads: "Pages: Reads",
  pagesWrites: "Pages: Writes",
  parameter: "Parameter",
  parameterDetails: "Parameter Details",
  parameters: "Parameters",
  parentId: "Parent ID",
  password: "Password",
  pauseLiveData: "Pause Live Data",
  performance: "Performance",
  performanceAnalysis: "Performance Analysis",
  performanceCounters: "Performance Counters",
  performanceInfoTT: "Access Performance and Custom Dashboards",
  performanceMetrics: "Performance Metrics",
  permanentTarget: "Permanent target",
  permissions: "Permissions",
  personalAccessToken: "Personal Access Token",
  planDiagram: "Plan Diagram",
  planDidNotContainMatchingStatement: `
    The selected statement was not found in the plan XML.
    Download the full plan to view in <a href="https://www.sentryone.com/plan-explorer" rel="noopener noreferrer" target="_blank">SentryOne Plan Explorer</a>.
  `,
  planNumber: "Plan #",
  planObjects: "Plan (Objects)",
  planSql: "Plan (SQL)",
  pleaseSeeTheDocumentationToEnableColleciton:
    "Please see the documentation for instructions on how to enable collection.",
  pleaseWaitDotDotDot: "Please wait...",
  //if we change this value, we need to update the Forecast chart TOTAL_LEGEND_WIDTH to the new SVG Width
  predictionInterval70: "Prediction Interval: 70%",
  preview: "Preview",
  previousButton: "Previous",
  problemCommunicatingWithApi: "There was a problem communicating with the API",
  procedure: "Procedure",
  procedureQueryType: "Proc Stats Sample",
  process: "Process",
  processId: "Process Id",
  processes: "Processes",
  profile: "Profile",
  profileEmail: "Profile Email",
  profileUsername: "Profile Username",
  publishedChartsInfoMessage:
    "Published charts utilize the dashboard time range unless a custom time range is specified",
  purchase: "Please view purchase options",
  queries: "Queries",
  queryGrants: "Query Grants",
  queryHistory: "Query History",
  queryHistoryByCpu: "Query History by CPU",
  queryHistoryByDuration: "Query History by Duration",
  queryHistoryByIo: "Query History by IO",
  queryPlans: "Query Plans",
  queryStatsType: "Query Stats Sample",
  queryText: "Text Data",
  queryTextDetails: "Query Text Details",
  queryTunerInfoTT: "Access to Top SQL, Blocking, Deadlocks and TempDB",
  range: "Range",
  rawXML: "Plan XML",
  readBytes: "Read Bytes",
  readBytesPerSecond: "Read Bytes/sec",
  readBytesPercent: "Read Bytes %",
  reads: "Reads",
  readsL: "Reads (L)",
  readsLogical: "Reads Logical",
  readsP: "Reads (P)",
  readsPerSecond: "Reads/sec",
  readsPercent: "Reads %",
  readsPhysical: "Reads Physical",
  recent: "Recent",
  recompiles: "Recompiles",
  records: "Records",
  relatedTargets: "Related Targets",
  reloadPage: "Reload Page",
  requestCompleted: "RequestCompleted",
  requestedMemoryKB: "Requested Memory (KB)",
  reservedSpace: "Reserved Space",
  reset: "Reset",
  resource: "Resource",
  resourceType: "Resource Type",
  resourceUsage: "Resource Usage",
  resourceWait: "Resource Wait",
  resources: "Resources",
  resultType: "Result Type",
  retry: "Retry",
  ridlock: "RID Lock",
  roleAssignedSuccess: "Role assigned successfully!",
  roleRemovedSuccess: "Role removed successfully!",
  rowCount: "Row Count",
  rows: "Rows",
  rowsPerPage: "Rows per page",
  running: "Running",
  runtimeValue: "Runtime Value",
  samplePeriod: "Sample Period",
  save: "Save",
  saveAndAddNew: "Save Add New",
  saveAndExit: "Save & Exit",
  saveDashboard: "Save Dashboard",
  saveInventoryItem: "Add Target",
  scheduled: "Scheduled",
  selectExisting: "Select Existing",
  selectTarget: "Select Target",
  selectUnits: "Select Units",
  selectUser: "Select User",
  selectedInstanceIsInvalid:
    "The selected instance is not valid for the selected metric. Please select a different instance.",
  selectedMetricIsInvalid:
    "The selected metric is not valid for the selected target. Please select a different metric.",
  serverFailedToConnect: "The Server Failed to Connect",
  serverName: "Server Name",
  serviceAgentStatus: "Service Agent Status",
  serviceIdentity: "Use Service Identity",
  sessionCount: "Session Count",
  sessionMemoryKB: "Session Memory (KB)",
  severity: "Severity",
  show: "Show",
  showAvgOver: "Show Avg Over",
  showAxisLabels: "Show Axis Labels",
  showBlocksOver: "Show blocks lasting longer than",
  showDetails: "Show Details",
  showHideAxes: "Show/Hide Axes",
  showHierarchy: "Show Hierarchy",
  showMore: "Show more...",
  showTop: "Show top",
  showTotals: "Show Totals",
  showTraceEvents: "Show Trace Events",
  signOut: "Sign Out",
  site: "Site",
  sites: "Sites",
  size: "Size",
  skipped: "Skipped",
  snapshotTransactions: "Snapshot Transactions",
  snoozed: "Snoozed",
  solarwindsSqlSentry: "SolarWinds SQL Sentry",
  solarwindsSupport: " SolarWinds Support",
  spid: "SPID",
  spidTextData: "Text Data: SPID {spid}",
  sqlDatabaseWaits: "SQL Database Waits",
  sqlServer: "SQL Server",
  sqlServerActivity: "SQL Server Activity",
  sqlServerAuthentication: "SQL Server",
  sqlServerMemory: "SQL Server Memory",
  sqlServerWaits: "SQL Server Waits",
  start: "Start",
  startTime: "Start Time",
  state: "State",
  statement: "Statement",
  statements: "Statements",
  status: "Status",
  stolenBuffer: "Stolen Buffer",
  storage: "Storage",
  storageWarningMessage_AUTOGROWTH_IS_PERCENT: "Autogrowth is percent",
  storageWarningMessage_AUTOGROWTH_OVER_ONE_GIGABYTES: "Autogrowth over one gigabytes",
  storageWarningMessage_AUTOGROWTH_OVER_REMAINING_DISK_SPACE: "Autogrowth exceeds remaining disk space",
  storageWarningMessage_AUTOGROWTH_UNDER_TEN_MEGABYTES: "Autogrowth under ten megabytes",
  storageWarningMessage_DEFAULT_FILE_SIZE_UNDER_FIVE_MEGABYTES: "Default file size under five megabytes",
  storageWarningMessage_FORECAST_EXHAUSTION_WITHIN_THIRTY_DAYS:
    "The forecasted exhaustion of this volume is within 30 days",
  storageWarningMessage_LOW_FREE_SPACE_PERCENTAGE: "This volume has low free space",
  storageWarningMessage_NON_SIMPLE_RECOVERY_MODEL_AND_NOT_BACKED_UP:
    "This database is in a non-simple recovery model and is not backed up",
  storageWarningMessage_NONE: "None",
  storageWarningMessage_TOTAL_VLFS_OVER_100:
    "Total VLFs over 100. Too many VLFs can cause long startup and backup times",
  storageWarningMessage_TOTAL_VLFS_OVER_300:
    "Total VLFs over 300. Too many VLFs can cause long startup and backup times",
  success: "Success",
  summary: "Summary",
  support: "SentryOne Support",
  switchToLiveData: "Switch to Live Data",
  systemMemory: "System Memory",
  systemMemoryVM: "System Memory (VM)",
  tags: "Tags",
  target: "Target",
  targetAddress: "Target Address",
  targetName: "Target Name",
  targetScores: "Target Scores",
  targetSelectorTitleMultiSelect: "Select Targets",
  targetSelectorTitleSingleSelect: "Select Target",
  targetTypes: "Target Types",
  targetUnavailable: "Target Unavailable",
  targetUnwatched: "This target is unwatched - Some features and data may not be available",
  targets: "Targets",
  tempDb: "TempDB",
  tempDbActivity: "TempDB Activity",
  tempDbObjects: "TempDB Objects",
  tempDbObjectsNotEnabledMessage: "TempDB Object collection is disabled.",
  tempDbSessionUsage: "TempDB Session Usage",
  tempDbSessionUsageNotEnabledMessage: "TempDB Session collection is disabled.",
  tempDbSummary: "TempDB Summary",
  tempdbInternalKB: "Tempdb Internal (KB)",
  tempdbInternalKBDealloc: "Tempdb Internal (KB) Dealloc",
  tempdbUserKB: "Tempdb User (KB)",
  tempdbUserKBDealloc: "Tempdb User (KB) Dealloc",
  test: "Test",
  textData: "Text Data",
  thereIsNoMessage: "There is no message",
  thereWasAnErrorRetrievingDataForThisComponent: "There was an error retrieving data for this component",
  thisActionCannotBeUndone: "This action cannot be undone",
  thisDataIsRestricted: "This data is restricted",
  threadpool: "Threadpool",
  time: "Time",
  timeframe: "Timeframe: ",
  toggleSidebar: "Toggle Sidebar",
  top5Conditions: "Top 5 Conditions",
  top5Servers: "Top 5 Servers",
  top5Tags: "Top 5 Tags",
  topSql: "Top SQL",
  total: "Total",
  totalBlocks: "Total Blocks",
  totalCPU: "Total CPU",
  totalDtuPercent: "Total DTU %",
  totalDuration: "Total Duration",
  totalExecutions: "Total Executions",
  totalLogicalReads: "Total Reads (L)",
  totalMs: "Total (ms)",
  totalPhysicalReads: "Total Physical Reads",
  totalPhysicalWrites: "Total Writes (P)",
  totalSeconds: "Total Time (sec)",
  totalTempDb: "Total TempDB",
  totalTempDbInternal: "Total TempDB Internal",
  totalTempDbUser: "Total TempDB User",
  totalTime: "Total Time",
  totalVlfs: "Total VLFs",
  totals: "Totals",
  traceEventStatements: "Trace Event Statements",
  traceEventWaits: "Trace Event Waits",
  traceEventWaitsChart: "Trace Event Waits Chart",
  traceEvents: "Trace Events",
  traceQueryStats: "Query Event",
  transName: "Trans Name",
  transactions: "Transactions",
  trends: "Trends",
  true: "True",
  tryAgain: "try again",
  type: "Type",
  typeCategory: "Category",
  typeNoteHere: "Type note here.",
  unhealthy: "Unhealthy",
  unitOfMeasureMS: "(ms)",
  units: "UNITS",
  unknown: "Unknown",
  unsavedEditsWillNotBeSaved: "Unsaved edits will not be saved.",
  unwatchedServers: "Unwatched Servers",
  update: "Update",
  upperBound: "Upper Bound",
  uptimeWithPercent: "Uptime: {uptimePercentage, number, detailedPercent}",
  usage: "Usage",
  useDashboardTarget: "Use Dashboard Target",
  useDashboardTimeRange: "Use Dashboard Time Range",
  usedPercentage: "% Used",
  usedSpace: "Used Space",
  userAddedSuccessfully: "User Was Successfully Added",
  userAddedWithError: "An Error Occurred While Adding This User",
  userAllocated: "User Allocated",
  userDeallocated: "User Deallocated",
  userDeletedSuccessfully: "User Was Successfully Deleted",
  userDeletedWithError: "An Error Occurred While Deleting This User",
  userDetails: "User Details",
  userEditedSuccessfully: "User Was Successfully Edited",
  userEditedWithError: "An Error Occurred While Editing This User",
  userGroupAddedSuccessfully: "User Group Was Successfully Added",
  userGroupAddedWithError: "An Error Occurred While Adding This User Group",
  userGroupDeletedSuccessfully: "User Group Was Successfully Deleted",
  userGroupDeletedWithError: "An Error Occurred While Deleting This User Group",
  userGroupEditedSuccessfully: "User Group Was Successfully Edited",
  userGroupEditedWithError: "An Error Occurred While Editing This User Group",
  userObjects: "User Objects",
  userProfile: "User Profile",
  username: "Username",
  users: "Users",
  usersAndGroups: "Users and Groups",
  value: "Value",
  verifyServerRunningAndReload: "Verify the server is running and reload the page to reconnect.",
  version: "Version",
  versionNotSupported: "Unsupported Version",
  versionStore: "Version Store",
  victim: "Victim",
  victimApplication: "Victim Application",
  victimDatabase: "Victim Database",
  victimHost: "Victim Host",
  victimSpid: "Victim SPID [ecid]",
  view: "View",
  viewDocumentation: "View Documentation",
  viewMoreHistoricalMetrics: "View More Historical Metrics",
  visitSupport: "Visit SentryOne Support",
  vmware: "VMware",
  vmwareActiveSize: "Active",
  vmwareAvailableSize: "Available",
  vmwareCPUGuest: "CPU (Guest)",
  vmwareCPUHost: "CPU (Host)",
  vmwareConsumedSize: "Consumed",
  vmwareCostop: "Co Stop",
  vmwareDiskGuest: "Disk (Guest)",
  vmwareDiskHost: "Disk (Host)",
  vmwareGrantedSize: "Granted",
  vmwareOverheadSize: "Overhead",
  vmwareProcessorTime: "Processor Time",
  vmwareReadyTime: "Ready Time",
  vmwareSystemMemoryGuest: "System Memory (Guest)",
  vmwareSystemMemoryHost: "System Memory (Host)",
  waitResource: "Wait Resource",
  waitResourceTypes: "Wait Resource Types",
  waitResources: "Wait Resources",
  waitTime: "Wait Time",
  waitTimeSession: "Wait Time / Session",
  waitType: "Wait Type",
  waiter: "Waiter",
  waits: "Waits",
  waitsMS: "Waits (ms)",
  warningCard: "Warning Card",
  warningIcon: "Warning Icon",
  warnings: "Warnings",
  week: "Week",
  weeks: "Weeks",
  widgetName: "Widget Name",
  windowsAuthentication: "Windows",
  windowsComputer: "Windows Computer",
  writeBytes: "Write Bytes",
  writeBytesPerSecond: "Write Bytes/sec",
  writeBytesPercent: "Write Bytes %",
  writes: "Writes",
  writesL: "Writes (L)",
  writesLogical: "Writes Logical",
  writesP: "Writes (P)",
  writesPerSecond: "Writes/sec",
  writesPercent: "Writes %",
  yes: "Yes",
  youDoNotHaveAccessToAnyDatabases: "You do not have access to any databases.",
  youDontHaveAnyWidgets: "You don't have any widgets!",
  yourNewDashboardNeedsAName: "Your new dashboard needs a name!",
  zoom: "Zoom",
  zoomIn: "Zoom In",
  zoomOut: "Zoom Out",
};

export default messages;
