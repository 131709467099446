import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useIntl } from "react-intl";

const useStyles = makeStyles({
  st0: {
    fill: "#FF6200",
  },
});

interface ILogoProps {
  className?: string;
  style?: React.CSSProperties;
}

const Logo: React.FC<ILogoProps> = ({ className, style }) => {
  const intl = useIntl();
  const classes = useStyles();
  return (
    <svg className={className} id="Layer_1" version="1.1" viewBox="0 0 1000 300" xmlns="http://www.w3.org/2000/svg" >
      <title>{intl.formatMessage({ id: "solarwindsSqlSentry" })}</title>
      <g>
        <path className={classes.st0} d="M407.028,140.266V76.349h11.212v54.047h30.623v9.87H407.028z" />
        <path
          className={classes.st0}
          d="M515.476,140.266L510.781,128h-30.282l-4.695,12.266h-12.266l25.107-63.917h13.99l25.107,63.917H515.476z
	M495.64,85.836l-11.978,32.294h23.956L495.64,85.836z"
        />
        <path
          className={classes.st0}
          d="M670.683,140.266l-12.457-47.147l-12.362,47.147h-11.979l-18.303-63.917h12.553l12.458,49.256l13.224-49.256
   h8.912l13.224,49.256l12.362-49.256h12.553l-18.207,63.917H670.683z"
        />
        <path className={classes.st0} d="M722.916,140.266V76.349h11.212v63.917H722.916z" />
        <path
          className={classes.st0}
          d="M807.891,140.266l-33.348-46.573v46.573h-11.212V76.349h11.499l32.677,45.327V76.349h11.212v63.917H807.891z"
        />
        <path
          className={classes.st0}
          d="M845.474,140.266V76.349h23.765c19.932,0,33.443,13.224,33.443,32.006c0,18.783-13.511,31.911-33.443,31.911
   H845.474z M856.685,130.396h12.553c13.895,0,21.944-9.966,21.944-22.041c0-12.362-7.666-22.136-21.944-22.136h-12.553V130.396z"
        />
        <path
          className={classes.st0}
          d="M354.552,75.295c-18.259,0-33.061,14.802-33.061,33.061c0,18.259,14.802,33.061,33.061,33.061
   c18.259,0,33.061-14.802,33.061-33.061C387.613,90.097,372.812,75.295,354.552,75.295z M354.552,130.253
   c-12.094,0-21.897-9.804-21.897-21.897s9.804-21.897,21.897-21.897s21.897,9.804,21.897,21.897S366.646,130.253,354.552,130.253z"
        />
        <path
          className={classes.st0}
          d="M589.039,120.208L589.039,120.208l-0.094-0.151l-0.058-0.093l-2.99-4.805l-0.009,0l-0.59-0.945
   c6.282-2.339,11.627-8.198,11.627-17.836c0-11.787-8.241-20.028-20.891-20.028h-28.077v63.917h11.212v-23.861h4.113h7.003h4.113
   l14.182,23.861h12.937L589.039,120.208z M559.169,106.534V86.219h15.332c6.325,0,10.925,4.025,10.925,10.158
   c0,6.133-4.6,10.157-10.925,10.157H559.169z"
        />
        <path
          className={classes.st0}
          d="M282.724,101.839c-7.57-1.917-14.087-3.546-14.087-8.528c0-4.792,4.216-7.954,10.828-7.954
   c6.335,0,12.736,2.082,17.627,6.507l7.046-7.246l0.242-0.315c-5.942-5.75-13.991-8.912-23.957-8.912
   c-13.991,0-23.286,8.145-23.286,18.686c0,13.511,12.266,16.577,22.711,19.165c7.762,2.012,14.566,3.737,14.566,9.487
   c0,4.217-3.642,8.72-12.841,8.72c-8.148,0-14.608-3.447-19.026-7.626l-7.325,7.534c5.654,5.982,14.151,10.06,25.872,10.06
   c17.249,0,24.723-9.104,24.723-19.741C305.818,107.781,293.265,104.522,282.724,101.839z"
        />
        <path
          className={classes.st0}
          d="M947.088,101.839c-7.57-1.917-14.087-3.546-14.087-8.528c0-4.792,4.216-7.954,10.828-7.954
   c6.335,0,12.736,2.082,17.627,6.507l7.046-7.246l0.242-0.315c-5.942-5.75-13.991-8.912-23.957-8.912
   c-13.991,0-23.286,8.145-23.286,18.686c0,13.511,12.266,16.577,22.711,19.165c7.762,2.012,14.566,3.737,14.566,9.487
   c0,4.217-3.642,8.72-12.841,8.72c-8.148,0-14.608-3.447-19.026-7.626l-7.325,7.534c5.654,5.982,14.151,10.06,25.872,10.06
   c17.249,0,24.723-9.104,24.723-19.741C970.182,107.781,957.628,104.522,947.088,101.839z"
        />
        <g>
          <g>
            <g>
              <path
                className={classes.st0}
                d="M982.198,81.725h-1.379V84.1h-0.778v-5.871h1.944c0.661,0,1.17,0.151,1.526,0.452
			   c0.356,0.301,0.534,0.739,0.534,1.314c0,0.366-0.099,0.684-0.297,0.956c-0.198,0.272-0.472,0.475-0.825,0.609l1.379,2.492V84.1
			   h-0.831L982.198,81.725z M980.819,81.092h1.19c0.384,0,0.69-0.1,0.917-0.299c0.227-0.199,0.341-0.466,0.341-0.8
			   c0-0.364-0.108-0.642-0.325-0.836c-0.216-0.194-0.529-0.292-0.937-0.295h-1.186V81.092z"
              />
            </g>
            <path
              className={classes.st0}
              d="M981.828,86.34c-2.784,0-5.049-2.265-5.049-5.049c0-2.784,2.265-5.049,5.049-5.049s5.049,2.265,5.049,5.049
		   C986.876,84.075,984.612,86.34,981.828,86.34z M981.828,76.96c-2.388,0-4.331,1.943-4.331,4.331c0,2.388,1.943,4.331,4.331,4.331
		   s4.331-1.943,4.331-4.331C986.159,78.903,984.216,76.96,981.828,76.96z"
            />
          </g>
          <g>
            <path className={classes.st0} d="M140.43,173.976h-2.449v6.797h-0.999v-6.797h-2.444v-0.822h5.892V173.976z" />
            <path
              className={classes.st0}
              d="M142.858,173.154l2.491,6.216l2.491-6.216h1.303v7.619h-1.005v-2.967l0.094-3.202l-2.501,6.169h-0.769
		   l-2.496-6.154l0.099,3.187v2.967h-1.005v-7.619H142.858z"
            />
          </g>
          <g>
            <g>
              <g>
                <path
                  className={classes.st0}
                  d="M105.191,96.65c0.58-0.155,1.142-0.31,1.72-0.477c12.698-3.58,25.05-8.54,37.324-13.677
				   c12.016-5.028,23.901-11.067,34.342-19.565c10.715-8.717,19.334-20.009,24.091-34.012c0.67-1.949,2.04-4.76,2.171-6.898
				   c-16.685,22.815-82.873,25.17-82.873,25.17l15.619-14.329C74.794,32.981,30.977,59.633,10.902,73.887
				   c25.691,2.756,48.948,13.638,67.156,30.023C87.017,101.136,96.162,99.132,105.191,96.65z"
                />
                <path
                  className={classes.st0}
                  d="M208.993,94.409c0,0-61.073-4.671-124.512,15.757c8.417,8.793,15.511,18.851,20.947,29.879
				   C138.784,121.478,185.036,97.757,208.993,94.409z"
                />
                <path
                  className={classes.st0}
                  d="M109.61,149.577c3.873,9.96,6.471,20.555,7.549,31.592l58.138-59.794L109.61,149.577z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Logo;