import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { LoadingIndicator } from "@sentryone/material-ui";
import classNames from "classnames";
import { Duration } from "luxon";
import * as React from "react";
import { useIntl } from "react-intl";
import {
  Area,
  AreaChartProps,
  AreaProps,
  AxisDomain,
  Bar,
  BarProps,
  CartesianGrid,
  ComposedChart,
  ContentRenderer,
  Label,
  Legend,
  Line,
  LineProps,
  ReferenceArea,
  ResponsiveContainer,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import { v4 as uuid } from "uuid";
import { makeFormatDateAxis } from "../../utilities/ChartUtility";
import { Mutable } from "../../utilities/UtilityTypes";
import { IDateRange } from "../DateContext";
import { formatDate } from "../FormattedDate";
import { NavigationContextMenu } from "../NavigationContextMenu";
import NoDataIndicator from "../NoDataIndicator";
import {
  ChartRange,
  StickyTooltip,
  StickyTooltipRef,
  TabularTooltip,
  useRangeSelect,
  useStickyTooltip,
} from "../recharts";
import { ITopologyItemDevice, ITopologyItemEventSourceConnection } from "../TopologyContext";
import { useLegend } from "./legends";
import MetricTooltip from "./MetricTooltip";
import { ChartDisplayType, IMetricChartSeries, MetricUnitOfMeasure } from "./types";
import { IUseMetricsResult } from "./useMetrics";
import { makeFormatterForUnit, makeTooltipFormatter } from "./utils";
import useMemoizedArray from "./useMemoizedArray";

export interface IMetricChartProps {
  backgroundColor?: string;
  children?: React.ReactNode;
  contextMenuItems?: React.ReactNode[];
  className?: string;
  /** The date range to display. */
  dateRange: IDateRange;
  /**
   * Whether to disable the axis merge process.
   * Defaults to false.
   * @default false
   */
  disableAxisMerge?: boolean;
  /**
   * Whether to disable the context menu from appearing on area selection.
   * Defaults to false.
   * @default false
   */
  disableContextMenu?: boolean;
  /**
   * Whether to show or hide axis labels.
   * Axis labels are only applicable if set in the metric options.
   */
  hideAxisLabels?: boolean;
  /** Whether to show or hide the legend. */
  hideLegend?: boolean;
  metricData: IUseMetricsResult;
  onSelectedRangeChange: (value: ChartRange) => void;
  /** The active range to highlight on the chart. */
  selectedRange: ChartRange;
  style?: React.CSSProperties;
  /** Charts with the same syncId will synchronize tooltips and hover reference lines.  */
  syncId?: AreaChartProps["syncId"];
  /** The target data is being displayed for. */
  target: ITopologyItemDevice | ITopologyItemEventSourceConnection;
}

const useStyles = makeStyles({
  disabledLegend: {
    // #ccc is the color Recharts uses for the disabled legend icon
    color: "#ccc",
    cursor: "auto",
  },
  // ResponsiveContainer struggles in CSS Grid or Flexbox scenarios due to the absolute width of the chart element.
  // overflow: hidden resolves this, but causes tooltips to be cut off as well.
  // Absolutely positioning the ResponsiveContainer inside a position relative container resolves this.
  responsiveContainer: {
    height: "100%",
    position: "absolute",
    width: "100%",
  },
  root: {
    position: "relative",
  },
});

function getChartDisplayOrder(type: ChartDisplayType): number {
  switch (type) {
    case "line":
      return 2;
    case "stackedArea":
      return 0;
    case "stackedBar":
      return 1;
  }
}

function getDefaultLabel(series: IMetricChartSeries): string {
  if (series.targetName) {
    return series.instance ? `${series.targetName}: ${series.metric}: ${series.instance}` : `${series.targetName}: ${series.metric}`;
  }
  return series.instance ? `${series.metric}: ${series.instance}` : series.metric;
}

function getSeriesComponent(
  type: ChartDisplayType,
): React.ComponentType<Omit<AreaProps, "data"> & Omit<BarProps, "data"> & Omit<LineProps, "data">> {
  switch (type) {
    case "line":
      return Line;
    case "stackedArea":
      return Area;
    case "stackedBar":
      return Bar;
  }
}

interface IMetricChartAxis {
  readonly color: string | null;
  readonly domain: [AxisDomain, AxisDomain];
  readonly label: string | null;
  readonly key: string;
  readonly ticks: number[] | null;
  readonly unitOfMeasure: MetricUnitOfMeasure;
}

function getMergedAxes(
  series: readonly IMetricChartSeries[],
  hideAxisLabels: boolean,
): ReadonlyMap<IMetricChartSeries, IMetricChartAxis> {
  const [_, map] = series.reduce<[Mutable<IMetricChartAxis>[], Map<IMetricChartSeries, IMetricChartAxis>]>(
    ([axes, map], curr) => {
      // TODO: What should we do if two merged axes have different values for enableDefaultScale?
      let axis = axes.find(
        (x) => x.unitOfMeasure === curr.unitOfMeasure && (hideAxisLabels || x.label === curr.axisLabel),
      );
      if (!axis) {
        axis = mapSeriesToAxis(curr);
        axes.push(axis);
      } else {
        // Multiple series use this axis, so remove the color
        axis.color = null;
      }
      map.set(curr, axis);
      return [axes, map];
    },
    [[], new Map()],
  );
  return map;
}

function getSeperateAxes(series: readonly IMetricChartSeries[]): ReadonlyMap<IMetricChartSeries, IMetricChartAxis> {
  return new Map<IMetricChartSeries, IMetricChartAxis>(series.map((x) => [x, mapSeriesToAxis(x)]));
}

function mapSeriesToAxis(series: IMetricChartSeries): IMetricChartAxis {
  const { axisLabel, color, enableDefaultScale = true, unitOfMeasure } = series;

  return {
    color,
    domain: enableDefaultScale && unitOfMeasure === MetricUnitOfMeasure.Percent ? [0, 100] : [0, "auto"],
    key: uuid(),
    label: axisLabel,
    ticks: enableDefaultScale && unitOfMeasure === MetricUnitOfMeasure.Percent ? [0, 25, 50, 75, 100] : null,
    unitOfMeasure: unitOfMeasure,
  };
}

interface IMetricChartData {
  [key: string]: number;
  timestamp: number;
}

/**
 * Returns the set of all factors of the specified number.
 * @param value Number to factorize
 */
function getFactors(value: number): Set<number> {
  const results = new Set<number>();
  for (let i = 1; i <= value; i++) {
    if (value % i === 0) {
      results.add(i);
    }
  }
  return results;
}

/**
 * Finds the greatest common factor (GCF) of a set of numbers.
 * @param numbers Set of numbers to determine GCF for. Set reduces duplicate number factorization.
 */
function getGreatestCommonFactor(numbers: ReadonlySet<number>): number {
  const numbersArray = Array.from(numbers);
  if (numbersArray.some((x) => x <= 0)) {
    throw new Error(`Cannot determine GCF of negative numbers. Received [${numbersArray.join(", ")}].`);
  } else if (numbers.size < 2) {
    return Math.max(1, ...numbersArray);
  } else {
    const [first, ...rest] = numbersArray;
    const factors = getFactors(first);
    // Sort factors descending so that largest value will be encountered first
    for (const factor of Array.from(factors).sort((a, b) => b - a)) {
      if (rest.every((x) => x % factor === 0)) {
        return factor;
      }
    }
    return 1;
  }
}

function useChartData(series: readonly IMetricChartSeries[]): readonly IMetricChartData[] {
  return React.useMemo<IMetricChartData[]>(() => {
    // Determine interval based on GCF of all series intervals to ensure alignment.
    // Ex. 2-minute and 15-minute intervals will align to a 1-minute interval.
    const alignedIntervalMilliseconds =
      1000 * getGreatestCommonFactor(new Set(series.map((x) => Duration.fromISO(x.interval).as("seconds"))));

    // Pre-populate the result with all expected timestamps before filling it
    // we do this to introduce gaps where necessary
    const initialSeries = new Map<number, IMetricChartData>();

    // The rollingTimestamp is a let but the startTimestamp is a const. The rule wants to explicitly label startTimestamp
    // as a const, this is a case where this is not necessary for this destructure, so the rule is ignored for this fringe case.
    // eslint-disable-next-line prefer-const
    let [startTimestamp, rollingTimestamp] = series
      .flatMap((x) => x.points)
      .map((x) => x.timestamp.getTime())
      .reduce<[number, number]>(([min, max], curr) => [Math.min(min, curr), Math.max(max, curr)], [
        Infinity,
        -Infinity,
      ]);

    // Insert placeholders for all points at the expected timestamps.
    // Start with the most recent and work back. When the agent comes online it will do an initial sync of all counters
    // at a non-aligned timestamp, which can throw off all following timestamps if used as the initial value.
    while (rollingTimestamp > startTimestamp) {
      initialSeries.set(rollingTimestamp, { timestamp: rollingTimestamp });
      rollingTimestamp -= alignedIntervalMilliseconds;
    }

    const data = series.reduce<Map<number, IMetricChartData>>((prev, curr) => {
      [...curr.points]
        .sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime())
        .forEach((x, i, a) => {
          const prevPoint = a[i - 1];
          const timestamp = x.timestamp.getTime();
          const intervalMilliseconds = Duration.fromISO(curr.interval).as("millisecond");

          if (prevPoint) {
            // x => hit
            // $ => avg fill
            // _ => gap unfilled
            // - => alignment fill

            // Scenario 1: single series with single/multiple gap
            // x   x   x   $   x   _   _   x

            // Scenario 2: two series w/ diff intervals and single/multiple gap
            // First series is 20 second interval
            // Second series is 10 second inteval
            // x - x - x - $ - x   _   _   x
            // x x x x x x x x x x x x x x x

            const diff = timestamp - prevPoint.timestamp.getTime();
            const multipleGapsOccurred = diff > intervalMilliseconds * 2;
            if (!multipleGapsOccurred) {
              const singleGapOccurred = diff > intervalMilliseconds;
              const smoothedTimestamp = prevPoint.timestamp.getTime() + intervalMilliseconds;
              const smoothedValue = (x.value + prevPoint.value) / 2;

              // Smoothing for the current chart series (fill a single missing point with the average of neighbors)
              if (singleGapOccurred) {
                const entry = prev.get(smoothedTimestamp) ?? { timestamp: smoothedTimestamp };
                entry[curr.key] = smoothedValue;
                prev.set(smoothedTimestamp, entry);
              }

              // Align larger interval to smaller interval
              // (i.e. One interval is 40 seconds and the other is 10 seconds. We don't want it to be counted
              // as a true gap in data.)
              if (alignedIntervalMilliseconds !== intervalMilliseconds) {
                let tempTimestamp = prevPoint.timestamp.getTime() + alignedIntervalMilliseconds;
                while (tempTimestamp < timestamp) {
                  const entry = prev.get(tempTimestamp) ?? { timestamp: tempTimestamp };
                  // If a single gap was filled (smoothing), use the smoothed value after crossing that timestamp
                  // Otherwise repeat the previous real value
                  entry[curr.key] = tempTimestamp > smoothedTimestamp ? smoothedValue : prevPoint.value;
                  prev.set(tempTimestamp, entry);
                  tempTimestamp += alignedIntervalMilliseconds;
                }
              }
            }
          }

          const entry = prev.get(timestamp) ?? { timestamp };
          entry[curr.key] = x.value;
          prev.set(timestamp, entry);
        });
      return prev;
    }, initialSeries);
    return Array.from(data.entries())
      .sort(([a], [b]) => +a - +b)
      .map(([, x]) => x);
  }, [series]);
}

const MetricChart: React.FC<IMetricChartProps> = ({
  backgroundColor,
  children,
  className,
  contextMenuItems = [],
  dateRange,
  disableAxisMerge = false,
  disableContextMenu = false,
  hideAxisLabels = false,
  hideLegend = false,
  metricData: { error, isLoading, series },
  onSelectedRangeChange,
  selectedRange,
  style,
  syncId,
  target,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [tooltipSeries, setTooltipSeries] = React.useState<string | null>(null);
  const [stickySeries, setStickySeries] = React.useState<string | null>(null);
  const [showJumpToMenu, setShowJumpToMenu] = React.useState<boolean>(false);
  const [jumpToMenuPosition, setJumpToMenuPosition] = React.useState<{ left: number; top: number }>({
    left: 0,
    top: 0,
  });
  const [tooltipYPos, setTooltipYPos] = React.useState<number>(0);
  const [tooltipXPos, setTooltipXPos] = React.useState<number>(0);
  const [activeRange, setActiveRange] = React.useState<ChartRange>(selectedRange);
  const tooltipRef = React.useRef<StickyTooltipRef>(null);
  const { closeTooltip, onClick, stickyProps } = useStickyTooltip();
  const legend = useLegend();
  const memoSeries = useMemoizedArray(series, (a, b) => a.key === b.key);

  const { onMouseDown, onMouseMove, onMouseUp, referenceArea } = useRangeSelect({
    activeRange,
    onActiveRangeChange(value: ChartRange) {
      setActiveRange(value);
      onSelectedRangeChange(value);
      if (value[0] && value[1]) {
        setShowJumpToMenu(true);
      }
    },
  });

  // Close any active tooltip when the highlighted range changes
  React.useEffect(
    () => {
      if (activeRange[0] !== activeRange[1]) {
        setTimeout(() => closeTooltip(), 0);
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [activeRange],
  );

  /**
   * if the selected range is changed, update the activeRange so that the chart selection is correct
   * In other words when a filter is cleared, the selection area will also be removed.
   */
  React.useEffect(
    () => {
      if (activeRange !== selectedRange) setActiveRange(selectedRange);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [...selectedRange],
  );

  const dateFormatter = makeFormatDateAxis(dateRange);
  const tooltipFormatter = makeTooltipFormatter(intl);

  const axisMap = disableAxisMerge ? getSeperateAxes(series) : getMergedAxes(series, hideAxisLabels);
  const axes = new Set(axisMap.values());
  const visibleAxes = Array.from(
    new Set(
      Array.from(axisMap.entries())
        .filter(([s]) => !legend.isHidden(s))
        .map(([_, axis]) => axis.key),
    ),
  );

  // filters out the series with no points as this can cause errors with interval rollup
  const filteredSeries = React.useMemo(() => memoSeries.filter((series) => series.points.length > 0), [memoSeries]);

  React.useEffect(() => {
    legend.setSeries(filteredSeries);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredSeries]);

  const data = useChartData(filteredSeries);

  function makeTooltipContent(tooltipSeriesKey: string | null): ContentRenderer<TooltipProps> {
    return (props: TooltipProps) => {
      const headerPayload = (props.payload ?? []).find((x) => x.dataKey === tooltipSeriesKey);
      if (headerPayload) {
        const timestamp = headerPayload.payload.timestamp;
        const payload = memoSeries
          .filter((x) => x.key === tooltipSeriesKey)
          .flatMap((x) => x.points)
          .filter((x) => x.timestamp.getTime() === timestamp)
          .flatMap((x) => x.tooltip ?? []);
        return <MetricTooltip {...props} headerPayload={headerPayload} payload={payload} />;
      } else {
        // Only show the total row for stacked charts
        // Non-stacked charts may be showing unrelated data
        const disableTotal = memoSeries.some((x) => x.chartType === "line" && !legend.isHidden(x));
        return <TabularTooltip disableTotal={disableTotal} {...props} />;
      }
    };
  }

  function handleContainerMouseUp(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    setJumpToMenuPosition({
      left: e.clientX,
      top: e.clientY,
    });
  }

  // Sets position of chart tooltip with consideration of window bounds
  function handleMouseMove(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    if (tooltipRef.current) {
      const EDGE_THRESH = 25;
      const CURSOR_SPACING = 10;
      const boundingBox = tooltipRef.current.wrapperNode.getBoundingClientRect();
      const viewBox = tooltipRef.current.props.viewBox;

      if (viewBox) {
        if (window.innerHeight < boundingBox.height + e.clientY + EDGE_THRESH && viewBox.height) {
          setTooltipYPos(viewBox.height - (boundingBox.height - EDGE_THRESH));
        } else if (tooltipRef.current.props.coordinate) {
          setTooltipYPos(tooltipRef.current.props.coordinate.y + CURSOR_SPACING);
        }

        if (window.innerWidth < boundingBox.width + e.clientX + EDGE_THRESH && viewBox.width) {
          setTooltipXPos(viewBox.width - (boundingBox.width - EDGE_THRESH));
        } else if (tooltipRef.current.props.coordinate) {
          setTooltipXPos(tooltipRef.current.props.coordinate.x + CURSOR_SPACING);
        }
      }
    }
  }

  // make sticky tooltips styling consistent both when pinned and when dynamic
  const additionalStickyTooltipProps: Partial<TooltipProps> = {
    isAnimationActive: false,
    position: {
      x: tooltipXPos,
      y: tooltipYPos,
    },
  };

  if (error) {
    throw error;
  } else if (isLoading) {
    return <LoadingIndicator className={className} style={style} variant="chart" />;
  } else if (!memoSeries.some((x) => x.points.length > 0)) {
    return <NoDataIndicator className={className} style={style} />;
  } else {
    const hasStackedBar: boolean = memoSeries.some((x) => x.chartType === "stackedBar");
    return (
      <>
        <div
          className={classNames(classes.root, className)}
          onMouseMove={handleMouseMove}
          onMouseUp={handleContainerMouseUp}
          style={style}
        >
          <ResponsiveContainer className={classes.responsiveContainer} debounce={25}>
            <ComposedChart
              data={data}
              margin={{ bottom: 0, left: 0, right: 0, top: 0 }}
              onClick={(chart) => {
                onClick(chart, undefined, additionalStickyTooltipProps);
                if (chart) {
                  setStickySeries(tooltipSeries);
                } else {
                  setStickySeries(null);
                }
              }}
              onMouseDown={onMouseDown}
              onMouseMove={onMouseMove}
              onMouseUp={onMouseUp}
              style={{ userSelect: "none" }}
              syncId={syncId}
            >
              {children}
              <CartesianGrid fill={backgroundColor} />
              <XAxis
                dataKey="timestamp"
                domain={[dateRange.from.getTime(), dateRange.to.getTime()]}
                interval="preserveStartEnd"
                minTickGap={125}
                scale={hasStackedBar ? undefined : "time"}
                tickFormatter={(x) => dateFormatter(new Date(x))}
                type={hasStackedBar ? undefined : "number"}
              />
              {Array.from(axes, (axis) => {
                // Width and label padding are determined from worst-case tick situations
                const LABEL_PADDING = 17;
                const WIDTH = 42;
                const orientation = visibleAxes.indexOf(axis.key) % 2 === 0 ? "left" : "right";
                return (
                  <YAxis
                    axisLine={{ stroke: axis.color }}
                    domain={axis.domain}
                    hide={!visibleAxes.includes(axis.key)}
                    key={axis.key}
                    orientation={orientation}
                    tick={{ fill: axis.color }}
                    tickFormatter={makeFormatterForUnit(axis.unitOfMeasure, intl)}
                    tickLine={{ stroke: axis.color }}
                    tickSize={2}
                    ticks={axis.ticks ?? undefined}
                    width={axis.label ? WIDTH + LABEL_PADDING : WIDTH}
                    yAxisId={axis.key}
                  >
                    {!hideAxisLabels && axis.label && (
                      <Label
                        angle={orientation === "left" ? -90 : 90}
                        dx={orientation === "left" ? -LABEL_PADDING : LABEL_PADDING}
                        fill={axis.color ?? undefined}
                        position="center"
                        value={intl.formatMessage({ id: axis.label })}
                      />
                    )}
                  </YAxis>
                );
              })}
              {!hideLegend && (
                <Legend
                  // entry's type definitions are incorrect but we just need dataKey
                  formatter={(value, entry: any) => {
                    const s = series.find((x) => x.key === entry.dataKey);
                    if (s && s.points.length === 0) {
                      return (
                        <Tooltip arrow title={intl.formatMessage({ id: "noData" })}>
                          <span className={classes.disabledLegend}>{value}</span>
                        </Tooltip>
                      );
                    } else {
                      return value;
                    }
                  }}
                  iconType="circle"
                  {...legend.legendProps}
                />
              )}
              <StickyTooltip
                allowEscapeViewBox={{ x: true, y: true }}
                content={makeTooltipContent(tooltipSeries)}
                cursor
                formatter={tooltipFormatter}
                labelFormatter={(x) =>
                  formatDate(new Date(x), {
                    day: "2-digit",
                    hour: "2-digit",
                    hour12: true,
                    minute: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })
                }
                sticky={{
                  ...stickyProps,
                  content: makeTooltipContent(stickySeries),
                }}
                tooltipRef={tooltipRef} // used for determining size of tooltip, needed in handleMouseMove function
                {...additionalStickyTooltipProps}
              />
              {Array.from(axisMap.entries())
                .sort(([a], [b]) => getChartDisplayOrder(a.chartType) - getChartDisplayOrder(b.chartType))
                .map(([x, yAxis], i) => {
                  const SeriesComponent = getSeriesComponent(x.chartType);
                  const hasCustomTooltip = x.points.some((y) => Array.isArray(y.tooltip));
                  // Setting unit to undefined for Unspecified will trigger TabularTooltip not to show totals row
                  const unit = x.unitOfMeasure === MetricUnitOfMeasure.Unspecified ? undefined : x.unitOfMeasure;
                  return (
                    <SeriesComponent
                      dataKey={x.key}
                      dot={false}
                      fill={x.color}
                      fillOpacity={1}
                      hide={legend.isHidden(x)}
                      isAnimationActive={false}
                      key={`${x.key}_${i}`}
                      name={x.label ? intl.formatMessage({ defaultMessage: x.label, id: x.label }) : getDefaultLabel(x)}
                      // Recharts doesn't suport setting events to undefined or it causes React errors
                      // It uses key detection to call some additional functions, but does not check the value
                      onMouseEnter={hasCustomTooltip ? () => setTooltipSeries(x.key) : () => null}
                      onMouseLeave={hasCustomTooltip ? () => setTooltipSeries(null) : () => null}
                      stackId={x.chartType === "line" ? undefined : x.chartType}
                      stroke={x.strokeColor ?? x.color}
                      strokeWidth={2}
                      type="linear"
                      unit={unit}
                      yAxisId={yAxis.key}
                    />
                  );
                })}
              {referenceArea[0] && referenceArea[1] && (
                <ReferenceArea
                  ifOverflow="hidden"
                  x1={referenceArea[0]}
                  x2={referenceArea[1]}
                  yAxisId={Array.from(axes, (x) => x.key)[0]}
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        {!disableContextMenu && (
          <NavigationContextMenu
            anchorPosition={jumpToMenuPosition}
            anchorReference="anchorPosition"
            dateRange={
              activeRange && activeRange[0] && activeRange[1]
                ? {
                    from: activeRange[0],
                    to: activeRange[1],
                  }
                : undefined
            }
            onClose={(e, reason) => {
              if (reason === "cancelClick") onSelectedRangeChange([null, null]);
              setShowJumpToMenu(false);
            }}
            open={showJumpToMenu}
            showCancel
            target={target}
          >
            {contextMenuItems}
          </NavigationContextMenu>
        )}
      </>
    );
  }
};

export default MetricChart;
