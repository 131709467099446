import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import classNames from "classnames";
import * as React from "react";
import { useIntl } from "react-intl";
import FeatureDisabled from "../../../../components/FeatureDisabled";
import {
  getHighestRowValue,
  positionAndExpandWidgets,
  removeWidget,
  shiftRowsBelowWidget,
} from "../../CustomDashboardUtilities";
import { ICustomChartConfig, ICustomDashboardWidgetPosition } from "../../types";
import { ICustomDashboard, ICustomDashboardWidget } from "../../useDashboards";
import DashboardNewRow from "./DashboardNewRow";
import DashboardWidget from "./DashboardWidget";

export interface IDashboardGridProps {
  onAddWidget: (customChartConfig: ICustomChartConfig, newWidgetPosition: ICustomDashboardWidgetPosition) => void;
  dashboard: ICustomDashboard;
  onEditWidget: (customChartConfig: ICustomDashboardWidget) => void;
  editing: boolean;
  handleEditing: (value: boolean) => void;
  onChangeLayout: (newLayout: ICustomDashboard) => void;
  onCopyWidget: (customChartConfig: ICustomDashboardWidget) => void;
}

const useStyles = makeStyles((theme) => ({
  edit: {},
  emptyPlaceholder: {
    backgroundColor: theme.palette.action.selected,
  },
  root: {
    "&$edit": {
      gridAutoRows: `${theme.spacing(7)}px ${theme.spacing(40)}px`,
    },
    display: "grid",
    gridAutoColumns: "1fr",
    gridAutoRows: theme.spacing(45),
    gridGap: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
}));

const DashboardGrid: React.FC<IDashboardGridProps> = ({
  onAddWidget,
  dashboard,
  onEditWidget,
  editing,
  handleEditing,
  onChangeLayout,
  onCopyWidget,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  function handleAddWidget(
    newWidget: ICustomChartConfig,
    direction: "left" | "right",
    neighborWidget: ICustomDashboardWidget,
  ): void {
    const newWidgetPosition: ICustomDashboardWidgetPosition = {
      column: direction === "left" ? neighborWidget.position.column - 1 : neighborWidget.position.column + 1,
      height: 1,
      row: neighborWidget.position.row,
      width: 1,
    };
    onAddWidget(newWidget, newWidgetPosition);
  }

  function handleUpdateWidgetPosition(id: number, direction: "left" | "right", widgetId: number): void {
    const widgetToUpdateIndex = dashboard.widgets.findIndex((r) => r.id === id);
    const neighborWidgetIndex = dashboard.widgets.findIndex((r) => r.id === widgetId);
    if (widgetToUpdateIndex < 0) {
      throw new Error(`Widget with ID ${id} not found`);
    }
    if (neighborWidgetIndex < 0) {
      throw new Error(`Widget with ID ${widgetId} not found`);
    }

    const neighborWidgetPosition = dashboard.widgets[neighborWidgetIndex].position;
    const updatedDashboard: ICustomDashboard = {
      ...dashboard,
      widgets: dashboard.widgets.map((widget, i) => {
        if (i === widgetToUpdateIndex) {
          return {
            ...widget,
            position: {
              ...widget.position,
              column: direction === "left" ? neighborWidgetPosition.column - 1 : neighborWidgetPosition.column + 1,
              row: neighborWidgetPosition.row,
            },
          };
        } else {
          return widget;
        }
      }),
    };

    const adjustedRows = positionAndExpandWidgets(updatedDashboard, getHighestRowValue(updatedDashboard));
    if (adjustedRows) onChangeLayout(adjustedRows);
  }

  function handleNewRowAdd(newWidgetConfig: ICustomChartConfig, beforeRow: number): void {
    const newWidgetPosition: ICustomDashboardWidgetPosition = {
      column: 1,
      height: 1,
      row: beforeRow + 1,
      width: 12,
    };
    onAddWidget(newWidgetConfig, newWidgetPosition);
  }

  function handleNewRowUpdate(id: number, beforeRow: number): void {
    const widgetToUpdateIndex = dashboard.widgets.findIndex((r) => r.id === id);
    if (widgetToUpdateIndex < 0) {
      throw new Error(`Widget with ID ${id} not found`);
    }

    const updatedDashboard: ICustomDashboard = {
      ...dashboard,
      widgets: dashboard.widgets.map((widget, i) => {
        if (i === widgetToUpdateIndex) {
          return {
            ...widget,
            position: {
              column: 1,
              height: 1,
              row: beforeRow + 1,
              width: 12,
            },
          };
        } else {
          return widget;
        }
      }),
    };

    const adjustedRowValues = shiftRowsBelowWidget(updatedDashboard, updatedDashboard.widgets[widgetToUpdateIndex]);
    const adjustedRows = positionAndExpandWidgets(adjustedRowValues, getHighestRowValue(updatedDashboard) + 1); //plus one for new row
    if (adjustedRows) onChangeLayout(adjustedRows);
  }

  function handleRemoveWidget(id: number): void {
    const adjustedRows = removeWidget(dashboard, id);
    if (adjustedRows) onChangeLayout(adjustedRows);
  }

  return (
    <div className={classNames(classes.root, { [classes.edit]: editing })}>
      {dashboard.widgets.map((widget) => (
        <DashboardWidget
          editing={editing}
          key={widget.id}
          onAddWidget={(newWidget, direction) => handleAddWidget(newWidget, direction, widget)}
          onCopyWidget={onCopyWidget}
          onEditWidget={onEditWidget}
          onRemoveWidget={() => handleRemoveWidget(widget.id)}
          onUpdateWidgetPosition={(id, direction) => handleUpdateWidgetPosition(id, direction, widget.id)}
          style={{
            gridColumn: `${widget.position.column} / span ${widget.position.width}`,
            // In edit mode, skip every other row for "add new row" area
            gridRow: `${editing ? widget.position.row * 2 : widget.position.row} / span ${widget.position.height}`,
          }}
          widget={widget}
        />
      ))}
      {!editing && dashboard.widgets.length === 0 && (
        <Paper className={classes.emptyPlaceholder} elevation={0}>
          <FeatureDisabled
            action={
              <Button color="primary" onClick={() => handleEditing(true)} startIcon={<EditIcon />}>
                {intl.formatMessage({ id: "addWidgets" })}
              </Button>
            }
            message={intl.formatMessage({ id: "clickTheButtonToAddWidgets" })}
            style={{ height: "100%" }}
            title={intl.formatMessage({ id: "youDontHaveAnyWidgets" })}
          />
        </Paper>
      )}
      {Array.from({ length: getHighestRowValue(dashboard) + 1 }, (_, index) => {
        return (
          <DashboardNewRow
            key={index}
            onAddWidget={(w) => handleNewRowAdd(w, index)}
            onUpdateWidget={(id) => handleNewRowUpdate(id, index)}
            show={editing}
            // Insert "add new row" in-between each widget row
            style={{ gridColumn: "1 / span 12", gridRow: `${index * 2 + 1} / span 1` }}
          />
        );
      })}
    </div>
  );
};

export default DashboardGrid;
