import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { useIntl } from "react-intl";
import SidebarContent from "../SidebarContent";
import Drawer from "@material-ui/core/Drawer";

interface ISidebarProps {
  className?: string;
  open: boolean;
}

const SIDEBAR_WIDTH_OPEN = "26.236em";
const SIDEBAR_WIDTH_CLOSED = "60px";

const useStyles = makeStyles((theme) => ({
  dragger: {
    "&:hover": {
      backgroundColor: "var(--sidebar-dark-light-blue)",
    },
    bottom: 0,
    cursor: "col-resize",
    padding: "4px 0 0",
    position: "absolute",
    right: 0,
    top: 0,
    transition: "background-color linear 150ms",
    width: "4px",
    zIndex: 100,
  },
  draggerShow: {
    backgroundColor: "var(--sidebar-dark-light-blue)",
  },
  drawerClose: {
    bottom: 0,
    height: "auto",
    overflowX: "hidden",
    resize: "none",
    top: "64px",
    transition: "width ease-out 150ms",
    width: SIDEBAR_WIDTH_CLOSED,
  },
  drawerDragging: {
    "&$drawerOpen": {
      resize: "horizontal",
      transition: "none",
      userSelect: "none",
    },
  },
  drawerOpen: {
    height: "100%",
    position: "relative",
    resize: "horizontal",
    transition: "width ease-in 150ms",
    width: SIDEBAR_WIDTH_OPEN,
  },
  drawerPaper: {
    backgroundColor: "var(--sidebar-dark-background)",
    border: "none",
    color: "var(--sidebar-dark-text-white)",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflow: "hidden",
  },
}));

const Sidebar: React.FunctionComponent<ISidebarProps> = ({ className, open }) => {
  const dragRef = React.useRef<HTMLElement>();
  const intl = useIntl();
  const classes = useStyles();

  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(open);
  const [dragActive, setDragActive] = React.useState<boolean>(false);

  React.useEffect(() => {
    setDrawerOpen(open);
    if (dragRef.current) {
      if (open) dragRef.current.style.width = SIDEBAR_WIDTH_OPEN;
      else dragRef.current.style.width = SIDEBAR_WIDTH_CLOSED;
    }
  }, [open]);

  React.useEffect(() => {
    if (dragRef.current) {
      if (drawerOpen) dragRef.current.style.width = SIDEBAR_WIDTH_OPEN;
      else dragRef.current.style.width = SIDEBAR_WIDTH_CLOSED;
    }
  }, [drawerOpen]);

  const handleMouseDown = (): void => {
    setDragActive(true);
    const mouseMove = (event: MouseEvent): void => {
      let x = Math.max(event.clientX, 175);
      x = Math.min(x, window.innerWidth - 175);
      if (dragRef.current) {
        dragRef.current.style.width = `${x}px`;
      }
    };
    const mouseUp = (): void => {
      setDragActive(false);
      document.removeEventListener("mousemove", mouseMove);
      document.removeEventListener("mouseup", mouseUp);
    };
    document.addEventListener("mousemove", mouseMove);
    document.addEventListener("mouseup", mouseUp);
  };

  return (
    <Drawer
      PaperProps={{
        "aria-grabbed": drawerOpen,
        component: "nav",
        elevation: 16,
        ref: dragRef,
      }}
      aria-expanded={drawerOpen}
      aria-label={intl.formatMessage({ id: "environmentNavigation" })}
      className={className}
      classes={{
        paper: classNames(classes.drawerPaper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
          [classes.drawerDragging]: open && dragActive,
        }),
      }}
      onMouseEnter={() => {
        if (!open) setDrawerOpen(true);
      }}
      onMouseLeave={() => {
        if (!open) setDrawerOpen(false);
      }}
      variant="permanent"
    >
      <SidebarContent sidebarOpen={drawerOpen} />
      {open && (
        <span
          className={classNames(classes.dragger, { [classes.draggerShow]: dragActive })}
          data-testid="dragger"
          onMouseDown={handleMouseDown}
        />
      )}
    </Drawer>
  );
};

export default Sidebar;
