import { useSnackbar } from "notistack";
import * as React from "react";
import { useIntl } from "react-intl";
import { useFeatureFlag } from "../../contexts/featuresContext/hooks";
import { DemoModeUtilities } from "./types";

/**
 * Hook for accessing the demo mode untilities in functional components
 */
const useDemoMode = (): DemoModeUtilities => {
  const isDemoMode = useFeatureFlag("demoMode");
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const enqueueDemoSnackbar = ():void => {
    enqueueSnackbar(intl.formatMessage({ id: "notAvailableInDemo" }), {
      anchorOrigin: { horizontal: "center", vertical: "top" },
      variant: "info",
    });
  }

  return ({ enqueueDemoSnackbar, isDemoMode });
};

export default useDemoMode;
