import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useIntl } from "react-intl";
import { TooltipProps } from "recharts";

const useStyles = makeStyles((theme) => ({
  circle: {
    display: "inline-block",
    height: "1em",
    marginRight: 4,
    verticalAlign: "text-top",
    width: "1em",
  },
  name: {
    display: "inline-block",
    maxWidth: "70ch",
    overflow: "hidden",
    textOverflow: "ellipsis",
    verticalAlign: "middle",
    whiteSpace: "nowrap",
  },
  root: {
    "& caption": {
      fontSize: "0.857rem",
      paddingBottom: theme.spacing(),
    },
    "& table": {
      borderCollapse: "collapse",
    },
    "& tbody>tr:last-of-type": {
      "& td,th": {
        paddingBottom: theme.spacing(),
      },
    },
    "& td": {
      color: "inherit",
      fontSize: "0.857rem",
      fontWeight: "bold",
      textAlign: "right",
    },
    "& tfoot>tr:first-of-type": {
      "& th,td": {
        paddingTop: theme.spacing(),
      },
      borderTop: "1px solid #555555",
    },
    "& th": {
      color: "#aaaaaa",
      fontSize: "0.857rem",
      textAlign: "left",
    },
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(),
    whiteSpace: "nowrap",
  },
}));

export interface ITabularTooltipProps extends TooltipProps {
  disableTotal?: boolean;
}

export const TabularTooltip: React.FC<ITabularTooltipProps> = ({ disableTotal = false, ...props }) => {
  const intl = useIntl();
  const classes = useStyles();
  const total = (props.payload ?? [])
    .map((x) => x.value)
    .filter((x): x is number => typeof x === "number")
    .reduce((prev, curr) => prev + curr, 0);
  return (
    <div className={classes.root}>
      <table>
        {props.label && (
          <caption>
            {typeof props.labelFormatter === "function" ? props.labelFormatter(props.label) : props.label}
          </caption>
        )}
        {props.payload && props.payload.length > 0 ? (
          <tbody>
            {props.payload.map((p, i) => (
              <tr key={`${p.name}-${i}`}>
                <th scope="row">
                  {/* The viewBox used here is the same as the Recharts Legend */}
                  <svg className={classes.circle} viewBox="0 0 32 32">
                    <circle cx={16} cy={16} fill={p.color} r={16} />
                  </svg>
                  <span className={classes.name}>{p.name}</span>
                </th>
                {/* any is needed because of a type mismatch in @types/recharts. One is readonly while the other isn't */}
                <td>
                  {typeof props.formatter === "function" ? props.formatter(p.value as any, p.name, p, i) : p.value}
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={2}>{intl.formatMessage({ id: "noDataToDisplay" })}</td>
            </tr>
          </tbody>
        )}
        {!disableTotal &&
          props.payload &&
          props.payload.length > 1 &&
          props.payload.every((x, i, a) => i === 0 || (x.unit && x.unit === a[i - 1].unit)) && (
            <tfoot>
              <tr>
                <th scope="row">{intl.formatMessage({ id: "total" })}</th>
                <td>
                  {typeof props.formatter === "function" ? props.formatter(total, "", props.payload[0], -1) : total}
                </td>
              </tr>
            </tfoot>
          )}
      </table>
    </div>
  );
};
