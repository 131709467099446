import * as React from "react";
import { TopologyObjectType } from "../../api/PerformanceAdvisor";
import { useDateContext } from "../../components/DateContext";
import DocumentTitle from "../../components/DocumentTitle";
import { useCurrentTarget } from "../../hooks/useCurrentTarget";
import BlockingContent from "./BlockingContent";

const Blocking: React.FC = () => {
  const { dateRange } = useDateContext();
  const target = useCurrentTarget();
  if (!target) {
    throw new Error("Blocking can only be used with a target");
  } else if (target.type !== TopologyObjectType.eventSourceConnection) {
    throw new Error(`Blocking can only be used with event source connections. Received ${target.type}`);
  }

  return (
    <>
      <DocumentTitle title="blocking" topologyItem={target} />
      <BlockingContent target={target} visibleDates={dateRange} />
    </>
  );
};

export default Blocking;
