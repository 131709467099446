import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
import { IconProps } from "./types";
import { getClass } from "./utils";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  large: {
    height: "24px",
    width: "24px",
  },
  medium: {
    height: "20px",
    width: "20px",
  },
  small: {
    height: "14px",
    width: "14px",
  },
}));

const AzureIcon: React.FunctionComponent<IconProps> = ({className, size}) => {
  const classes = useStyles();

  return (
    <SvgIcon
      className={getClass(classes, size) + " " + className}
      data-testid="azure-target-icon"
      height="77.43"
      viewBox="0 0 19.68 15.24"
      width="100"
    >
      <path
        d="M9.105 14.43l4.642-.82.043-.01-2.387-2.84a403.945 403.945 0 0 1-2.387-2.853c0-.014 2.465-6.802 2.479-6.826.004-.008 1.682 2.888 4.066 7.02l4.09 7.09.031.054-7.587-.001-7.587-.001 4.597-.812zM0 13.566c0-.004 1.125-1.957 2.5-4.34L5 4.893l2.913-2.445C9.515 1.104 10.83.002 10.836 0a.512.512 0 0 1-.047.118L7.625 6.903l-3.107 6.663-2.259.003c-1.242.002-2.259 0-2.259-.004z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default AzureIcon;
