import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import OsIcon from "@material-ui/icons/DesktopWindows";
import * as React from "react";
import { useIntl } from "react-intl";
import { AzureIcon, SqlIcon, VMWareIcon } from "../../../../../../components/icons";
import {
  ITopologyItemDevice,
  ITopologyItemEventSourceConnection,
  TopologyDeviceType,
  TopologyEventSourceConnectionType,
  TopologyObjectType,
  useTopology,
} from "../../../../../../components/TopologyContext";
import { ITranslation } from "../../../../../../intl/types";
import { ICustomChartConfig } from "../../../../types";
import { CustomChartTemplates, ICustomChartTemplate } from "./CustomChartTemplates";
import WidgetListItem from "./WidgetListItem";
import { MoRef } from "../../../../../../utilities/TopologyUtility";

export interface IWidgetListProps {
  addWidget: (newWidget: ICustomChartConfig) => void;
  targets: Array<ITopologyItemDevice | ITopologyItemEventSourceConnection>;
}

const enum WidgetGroupCategory {
  Azure = "AzureSqlDb",
  Os = "Os",
  Sql = "SqlServer",
  VMWare = "VMWare",
}

export interface ICustomChartWidgetGroup {
  display: boolean;
  icon: React.ReactElement;
  title: keyof ITranslation;
  widgets: ICustomChartTemplate[];
}

const useStyles = makeStyles((theme) => ({
  divider: {
    marginBottom: theme.spacing(2),
  },
  listSection: {
    marginTop: theme.spacing(2),
  },
  sectionHeader: {
    alignItems: "center",
    display: "flex",
    textTransform: "uppercase",
  },
  sectionHeaderIcon: {
    color: "inherit",
    marginRight: theme.spacing(1),
    // requested by Justin to better align the icon to the text
    marginTop: "-1px",
  },
}));

const WidgetList: React.FC<IWidgetListProps> = ({ addWidget, targets }) => {
  const classes = useStyles();
  const intl = useIntl();
  const topology = useTopology();
  const widgetTemplateGroups: Record<string, ICustomChartWidgetGroup> = {
    [WidgetGroupCategory.Os]: {
      display: true,
      icon: <OsIcon className={classes.sectionHeaderIcon} fontSize="small" />,
      title: "os",
      widgets: [],
    },
    [WidgetGroupCategory.Sql]: {
      display: true,
      icon: <SqlIcon className={classes.sectionHeaderIcon} fontSize="small" />,
      title: "sqlServer",
      widgets: [],
    },
    [WidgetGroupCategory.Azure]: {
      display: true,
      icon: <AzureIcon className={classes.sectionHeaderIcon} size="small" />,
      title: "azureSqlDb",
      widgets: [],
    },
    [WidgetGroupCategory.VMWare]: {
      display: true,
      icon: <VMWareIcon className={classes.sectionHeaderIcon} size="medium" />,
      title: "vmware",
      widgets: [],
    },
  };

  Object.keys(CustomChartTemplates).forEach((key) => {
    const template = CustomChartTemplates[key];
    let defaultTarget: ITopologyItemEventSourceConnection | ITopologyItemDevice | undefined;
    let widgetTemplateGroup: ICustomChartWidgetGroup | undefined;

    //get the default target for the current widget and widget template group that the widget will be added to
    if (key.startsWith("azure")) {
      defaultTarget = targets.find(
        (target) =>
          target.type === TopologyObjectType.eventSourceConnection &&
          target.eventSourceConnectionType === TopologyEventSourceConnectionType.AzureSqlDatabase,
      );
      widgetTemplateGroup = widgetTemplateGroups[WidgetGroupCategory.Azure];
    } else if (key.startsWith("sqlServer")) {
      defaultTarget = targets.find(
        (target) =>
          target.type === TopologyObjectType.eventSourceConnection &&
          target.eventSourceConnectionType === TopologyEventSourceConnectionType.SqlServer,
      );
      widgetTemplateGroup = widgetTemplateGroups[WidgetGroupCategory.Sql];
    } else if (key.startsWith("os")) {
      defaultTarget = targets.find(
        (target) =>
          (target.type === TopologyObjectType.device && target.deviceType === TopologyDeviceType.Windows) ||
          (target.parentObjectId &&
            (topology.findByObjectId(target.parentObjectId, TopologyObjectType.device) as ITopologyItemDevice)
              ?.deviceType === TopologyDeviceType.Windows),
      );
      widgetTemplateGroup = widgetTemplateGroups[WidgetGroupCategory.Os];
    } else if (key.startsWith("vmware")) {
      defaultTarget = targets.find(
        (target) => target.type === TopologyObjectType.device && target.deviceType === TopologyDeviceType.VMWare,
      );
      widgetTemplateGroup = widgetTemplateGroups[WidgetGroupCategory.VMWare];
    } else {
      defaultTarget = targets[0];
      widgetTemplateGroup = undefined;
    }

    if (widgetTemplateGroup) {
      //if no targets/eventsourceconnections exist for the template type, don't show those templates
      if (!defaultTarget) {
        widgetTemplateGroup.display = false;
      } else {
        const intlChartName = intl.formatMessage({ id: template.chartName });
        //templates with predefined labels need to be intl'd others will have their label set to the instance name (doesn't need to be intl'd)
        let formattedPerformanceCounters;
        if (typeof template.performanceCounters !== "function") {
          formattedPerformanceCounters = template.performanceCounters.map((counter) => {
            const label = counter.label ? intl.formatMessage({ id: counter.label }) : null;
            const moRef = defaultTarget && MoRef.fromTopologyItem(defaultTarget);
            if (moRef) return { ...counter, label, moRef };
            return { ...counter, label };
          });
        }
        widgetTemplateGroup.widgets.push({
          ...template,
          chartName: intlChartName,
          performanceCounters: formattedPerformanceCounters ?? template.performanceCounters,
          target: defaultTarget,
        });
      }
    }
  });

  const customChartWidgetInfo: ICustomChartTemplate = {
    ...CustomChartTemplates["blankTemplate"],
    chartName: intl.formatMessage({ id: CustomChartTemplates["blankTemplate"].chartName }),
  };

  return (
    <List className={classes.listSection}>
      <Typography color="textSecondary" variant="h5">
        {intl.formatMessage({ id: "addCharts" })}
      </Typography>
      <Divider className={classes.divider} />
      <WidgetListItem addWidget={addWidget} widgetInfo={customChartWidgetInfo} />
      {Object.keys(widgetTemplateGroups)
        .filter((group) => widgetTemplateGroups[group].display)
        .map((group) => (
          <List className={classes.listSection} key={widgetTemplateGroups[group].title}>
            <Typography className={classes.sectionHeader} color="textSecondary" variant="body2">
              {widgetTemplateGroups[group].icon}
              {intl.formatMessage({ id: widgetTemplateGroups[group].title })}
            </Typography>
            {widgetTemplateGroups[group].widgets.map((widget) => (
              <WidgetListItem addWidget={addWidget} key={widget.chartName} widgetInfo={widget} />
            ))}
          </List>
        ))}
    </List>
  );
};

export default WidgetList;
