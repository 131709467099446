import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import NavigationContextMenu, { INavigationContextMenuProps } from "./NavigationContextMenu";

export interface INavigationContextTriggerProps
  extends Pick<INavigationContextMenuProps, "dateRange" | "showCancel" | "target"> {
  /**
   * Additional action items to display in the menu.
   * React.Fragment cannot be used to contain multiple items due to a Material-UI Menu limitation.
   * For multiple items, use an array instead.
   */
  actionItems?: INavigationContextMenuProps["children"];
  className?: string;
  /** Styling variant to apply. */
  variant?: "inline" | "standard" | "table";
}

const useStyles = makeStyles({
  inline: {
    fontWeight: "normal",
    padding: 0,
  },
  label: {
    display: "inline-block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textTransform: "none",
    whiteSpace: "nowrap",
  },
  root: {
    maxWidth: "100%",
    textAlign: "left",
  },
  standard: {},
  table: {
    fontWeight: "normal",
    padding: 0,
  },
});

const NavigationContextTrigger: React.FC<INavigationContextTriggerProps> = ({
  actionItems,
  children,
  className,
  dateRange,
  showCancel,
  target,
  variant = "standard",
}) => {
  const classes = useStyles();
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Button
        classes={{
          label: classes.label,
          root: classNames(classes.root, classes[variant], className),
        }}
        color="primary"
        onClick={() => setOpen(true)}
        ref={anchorRef}
        size="small"
        title={typeof children === "string" ? children : undefined}
        variant="text"
      >
        {children}
      </Button>
      <NavigationContextMenu
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        dateRange={dateRange}
        getContentAnchorEl={null}
        onClose={() => setOpen(false)}
        open={open}
        showCancel={showCancel}
        target={target}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
      >
        {actionItems}
      </NavigationContextMenu>
    </>
  );
};

export default NavigationContextTrigger;
