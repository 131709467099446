import { makeStyles, useTheme } from "@material-ui/core/styles";
import { LoadingIndicator } from "@sentryone/material-ui";
import classNames from "classnames";
import * as React from "react";
import Diagram, { IDiagramEdgeInput, makeCircularLayout } from "../../../../components/Diagram";
import NoDataIndicator from "../../../../components/NoDataIndicator";
import {
  IDeadlockProcess,
  IDeadlockResource,
  parseDeadlockXml,
  useDeadlocksContext,
} from "../../../../contexts/deadlocksContext";
import DeadlockDetailRow from "../DeadlockDetailRow";
import NodeDetails from "../NodeDetails";
import DeadlockDiagramEdge from "./DeadlockDiagramEdge";
import DeadlockDiagramNode from "./DeadlockDiagramNode";

const circularLayout = makeCircularLayout<IDeadlockProcess | IDeadlockResource>({
  edgeEnd: "border",
  nodeHeight: 10,
  // 40 is the magic spacing we want for the 24x10 node
  nodeSpacing: 40,
  nodeWidth: 24,
});

const useStyles = makeStyles((theme) => ({
  active: {},
  applyFade: {
    "& $diagramElement": {
      "&$active": {
        opacity: 1,
      },
      opacity: 0.1,
    },
  },
  details: {
    maxHeight: theme.spacing(50),
    overflow: "auto",
    padding: theme.spacing(1),
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(12),
  },
  diagram: {
    flex: 1,
    // Edge doesn't respect the max height of the container unless flex-basis is set
    flexBasis: 1,
  },
  diagramElement: {
    transition: "opacity 0.5s",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
    position: "relative",
  },
}));

const DeadlockDiagram: React.FC = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const [edgeHover, setEdgeHover] = React.useState<[string, string, number] | null>(null);
  const [nodeHover, setNodeHover] = React.useState<string | null>(null);
  const {
    loading,
    loadingDeadlock,
    selectedDeadlock,
    onUploadDeadlock,
    selectedNode,
    setSelectedNodeId,
  } = useDeadlocksContext();

  const [fileName, setFileName] = React.useState<string>("");
  const visibleDeadlock = selectedDeadlock;

  const processes = visibleDeadlock?.processes;
  const resources = visibleDeadlock?.resources;
  const data = React.useMemo<ReadonlyArray<IDeadlockProcess | IDeadlockResource>>(() => {
    return [...(processes ?? []), ...(resources ?? [])];
  }, [processes, resources]);

  const edges = React.useMemo<ReadonlyArray<IDiagramEdgeInput<IDeadlockProcess | IDeadlockResource>>>(() => {
    const owners = (processes ?? [])
      .flatMap((x) => x.ownerOf)
      .map<IDiagramEdgeInput<IDeadlockProcess | IDeadlockResource>>((x) => {
        return { sequence: x.sequence, source: x.resource, target: x.process };
      });
    const waiters = (processes ?? [])
      .flatMap((x) => x.waiterOf)
      .map<IDiagramEdgeInput<IDeadlockProcess | IDeadlockResource>>((x) => {
        return {
          sequence: x.sequence,
          source: x.process,
          target: x.resource,
        };
      });
    return [...owners, ...waiters];
  }, [processes]);

  function handleDrop(e: React.DragEvent<SVGSVGElement>): void {
    e.preventDefault();
    if (e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      const reader = new FileReader();
      reader.addEventListener("load", (data) => {
        const xmlString = data.target?.result?.toString();
        if (xmlString) {
          try {
            const deadlock = parseDeadlockXml(xmlString);
            onUploadDeadlock(deadlock);
            setFileName(file.name);
          } catch (e) {
            console.error(e);
          }
        } else {
          console.warn(`File ${file.name} is empty.`);
        }
      });
      reader.readAsText(file);
    }
  }

  if (loading || loadingDeadlock) {
    return <LoadingIndicator />;
  } else if (!selectedDeadlock) {
    return <NoDataIndicator />;
  } else {
    return (
      <div className={classes.root}>
        <DeadlockDetailRow />
        <Diagram
          className={classNames(classes.diagram, { [classes.applyFade]: edgeHover !== null || nodeHover !== null })}
          data={data}
          edges={edges}
          layout={circularLayout}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
          renderEdge={(edge, i, allEdges) => {
            const isHovered =
              (edgeHover && edge.sequence === edgeHover[2]) ||
              edge.source.id === nodeHover ||
              edge.target.id === nodeHover;

            return (
              <DeadlockDiagramEdge
                allEdges={allEdges}
                className={classNames(classes.diagramElement, { [classes.active]: isHovered })}
                edge={edge}
                key={i}
                onMouseEnter={() => setEdgeHover([edge.source.id, edge.target.id, edge.sequence])}
                onMouseLeave={() => setEdgeHover(null)}
              />
            );
          }}
          renderNode={({ data, x, y }, i) => {
            const isHovered =
              data.id === nodeHover ||
              edgeHover?.includes(data.id) ||
              ("owners" in data && data.owners.find((o) => o.process.id === nodeHover)) ||
              ("waiters" in data && data.waiters.find((w) => w.process.id === nodeHover)) ||
              ("ownerOf" in data && data.ownerOf.find((o) => o.resource.id === nodeHover)) ||
              ("waiterOf" in data && data.waiterOf.find((w) => w.resource.id === nodeHover));
            const dataProp = "owners" in data ? { resource: data } : { process: data };
            return (
              <DeadlockDiagramNode
                className={classNames(classes.diagramElement, {
                  [classes.active]: isHovered,
                })}
                height={20}
                isSelected={selectedNode === data}
                key={i}
                onClick={() => setSelectedNodeId(data.id)}
                onMouseEnter={() => setNodeHover(data.id)}
                onMouseLeave={() => setNodeHover(null)}
                x={x}
                y={y}
                {...dataProp}
              />
            );
          }}
          {...props}
        >
          <title>{fileName}</title>
          <defs>
            <marker id="arrow" markerHeight="4" markerWidth="2" orient="auto" refX="0.1" refY="2">
              <path d="M 0 0 V 4 L 2 2 Z" fill={theme.palette.grey[800]} />
            </marker>
          </defs>
        </Diagram>
        {selectedNode && <NodeDetails />}
      </div>
    );
  }
};

export default DeadlockDiagram;
