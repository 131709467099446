import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import RefreshIcon from "@material-ui/icons/Refresh";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ErrorIcon } from "../icons";
import Collapse from "@material-ui/core/Collapse";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ButtonBase from "@material-ui/core/ButtonBase";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import CustomSnackbar from "../../../src/features/TopSql/components/CustomSnackbar";

export type ErrorBoundaryVariant = "chart" | "dialog" | "generic";

const useStyles = makeStyles((theme) => ({
  centeredContent: {
    display: "flex",
    justifyContent: "center",
  },
  chartPlaceholder: {
    marginTop: -theme.spacing(6),
  },
  content: {
    padding: "16px",
    textAlign: "right",
  },
  dialogContainer: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: "18px",
    paddingLeft: "7px",
    width: "100%",
  },
  errorDetails: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  errorMessage: {
    height: "100px",
    overflow: "auto",
    textAlign: "left",
  },
  placeholder: {
    fill: "none",
    height: "12rem",
    maxHeight: "100%",
    width: "12rem",
  },
  root: {
    alignItems: "center",
    display: "grid",
    gridColumnGap: "5%",
    gridTemplateColumns: "70% 30%",
    justifyContent: "center",
    width: "697px",
  },
  title: {
    fontWeight: theme.typography.h5.fontWeight,
    textAlign: "left",
  },
}));

interface IErrorDisplayProps {
  className?: string;
  error: Error;
  onTryAgain: () => void;
  style?: React.CSSProperties;
  variant: ErrorBoundaryVariant;
  showDetails?: boolean;
}

const ErrorDisplay: React.FunctionComponent<IErrorDisplayProps> = ({
  className,
  onTryAgain,
  variant,
  error,
  showDetails = true,
  ...props
}) => {
  const classes = useStyles({});
  const [expanded, setExpanded] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const intl = useIntl();

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleCollapseClick = (collapseState: boolean): void => {
    setExpanded(collapseState);
  };

  const copyErrorStack = (errorStack: Error | null): void => {
    if (errorStack && errorStack.stack) {
      setOpen(true);
      navigator.clipboard.writeText(errorStack.stack);
    }
  };

  switch (variant) {
    case "dialog":
      return (
        <div className={classes.centeredContent}>
          <Dialog className={className} open {...props}>
            <CustomSnackbar
              animation="Slide"
              aria-label="error-copied-message-snackbar"
              handleClose={handleClose}
              message={intl.formatMessage({ id: "errorCopiedMessage" })}
              open={open}
              slideDirection="right"
            />
            <DialogContent>
              <DialogContentText>
                <FormattedMessage id="anUnexpectedErrorOccurredWhileLoadingThisPage" />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <div className={classes.content}>
                {showDetails && (
                  <>
                    <div className={classes.dialogContainer}>
                      <div>
                        <ButtonBase
                          aria-label="about-header"
                          data-testid="detailsButton"
                          onClick={() => handleCollapseClick(!expanded)}
                        >
                          <Typography variant="h5">
                            <FormattedMessage id="errorDetails" />
                          </Typography>
                          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ButtonBase>
                        {navigator.clipboard && (
                          <ButtonBase
                            aria-label="copy-to-clipboard"
                            data-testid="copyIcon"
                            onClick={() => copyErrorStack(error)}
                            title={intl.formatMessage({ id: "errorCopiedTitle" })}
                          >
                            <FileCopyOutlinedIcon />
                          </ButtonBase>
                        )}
                      </div>
                      <Button color="primary" onClick={onTryAgain} startIcon={<RefreshIcon />} variant="text">
                        <FormattedMessage id="tryAgain" />
                      </Button>
                    </div>
                    <Collapse aria-label="about-collapse" in={expanded}>
                      <div className={classes.errorMessage}>
                        <Typography variant="h5">{error.message}</Typography>
                        <Typography>{error.stack}</Typography>
                      </div>
                    </Collapse>
                  </>
                )}
              </div>
            </DialogActions>
          </Dialog>
        </div>
      );
    default:
      return (
        <div className={classes.centeredContent}>
          <CustomSnackbar
            animation="Slide"
            aria-label="error-copied-message-snackbar"
            handleClose={handleClose}
            message={intl.formatMessage({ id: "errorCopiedMessage" })}
            open={open}
            slideDirection="right"
          />

          <div className={classNames(classes.root, className)} {...props}>
            <div className={classes.content}>
              <Typography className={classes.title} component="h1" gutterBottom variant="h5">
                <FormattedMessage id="thereWasAnErrorRetrievingDataForThisComponent" />
              </Typography>
              {showDetails && (
                <>
                  <div className={classes.errorDetails}>
                    <div>
                      <ButtonBase
                        aria-label="about-header"
                        data-testid="detailsButton"
                        onClick={() => handleCollapseClick(!expanded)}
                      >
                        <Typography variant="h5">
                          <FormattedMessage id="errorDetails" />
                        </Typography>
                        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </ButtonBase>
                      {navigator.clipboard && (
                        <ButtonBase
                          aria-label="copy-to-clipboard"
                          data-testid="copyIcon"
                          onClick={() => copyErrorStack(error)}
                          title={intl.formatMessage({ id: "errorCopiedTitle" })}
                        >
                          <FileCopyOutlinedIcon />
                        </ButtonBase>
                      )}
                    </div>
                    <Button color="primary" onClick={onTryAgain} startIcon={<RefreshIcon />} variant="text">
                      <FormattedMessage id="tryAgain" />
                    </Button>
                  </div>
                  <Collapse aria-label="about-collapse" in={expanded}>
                    <div className={classes.errorMessage}>
                      <Typography variant="h5">{error.message}</Typography>
                      <Typography>{error.stack}</Typography>
                    </div>
                  </Collapse>
                </>
              )}
            </div>
            <ErrorIcon className={classes.placeholder} />
          </div>
        </div>
      );
  }
};

export default ErrorDisplay;
