import { Dispatch, SetStateAction, useRef, useState } from "react";
import { IViewBox } from "./types";
import { getPointerSvgPoint } from "./utils";

interface IUsePanResult {
  /** True if the document is actively being panned. False otherwise. */
  isPanning: boolean;
  onPointerDown: (e: React.PointerEvent<SVGSVGElement>) => void;
  onPointerLeave: (e: React.PointerEvent<SVGSVGElement>) => void;
  onPointerMove: (e: React.PointerEvent<SVGSVGElement>) => void;
  onPointerUp: (e: React.MouseEvent<SVGSVGElement>) => void;
}

interface IUsePanParams {
  onUpdateViewBox: Dispatch<SetStateAction<IViewBox>>;
  viewBox: IViewBox;
}

export default function usePan({ onUpdateViewBox }: IUsePanParams): IUsePanResult {
  const animationFrame = useRef<number>(0);
  const [startingPoint, setStartingPoint] = useState<[number, number] | null>(null);

  function handleStart(e: React.PointerEvent<SVGSVGElement>): void {
    const cursorPoint = getPointerSvgPoint(e);
    setStartingPoint([cursorPoint.x, cursorPoint.y]);
  }

  function handleMove(e: React.PointerEvent<SVGSVGElement>): void {
    if (startingPoint) {
      const cursorPoint = getPointerSvgPoint(e);
      const [x, y] = [cursorPoint.x - startingPoint[0], cursorPoint.y - startingPoint[1]];
      window.cancelAnimationFrame(animationFrame.current);
      animationFrame.current = window.requestAnimationFrame(() => {
        onUpdateViewBox((prev) => prev.translate(-x, -y));
      });
    }
  }

  function handleEnd(): void {
    setStartingPoint(null);
  }

  return {
    isPanning: !!startingPoint,
    onPointerDown: handleStart,
    onPointerLeave: handleEnd,
    onPointerMove: handleMove,
    onPointerUp: handleEnd,
  };
}
