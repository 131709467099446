import type {
  IStorageDiskController,
  IStoragePhysicalDisk,
  IStorageSqlServerFile,
  IStorageEntity,
  IStorageLogicalVolume,
} from "./types";

export interface IAllStorageEntitiesResponse {
  controllers: Array<IStorageDiskController>;
  disks: Array<IStoragePhysicalDisk>;
  volumes: Array<IStorageLogicalVolume>;
  files: Array<IStorageSqlServerFile>;
}

export default class StorageEntityMap {
  #controllers: ReadonlyArray<IStorageDiskController>;
  #disks: ReadonlyArray<IStoragePhysicalDisk>;
  #volumes: ReadonlyArray<IStorageLogicalVolume>;
  #files: ReadonlyArray<IStorageSqlServerFile>;
  #allEntities: Map<string, IStorageEntity>;

  constructor(entities: IAllStorageEntitiesResponse) {
    this.#controllers = entities.controllers.slice().sort(this.sortEntity);
    this.#disks = entities.disks.slice().sort(this.sortEntity);
    this.#volumes = entities.volumes.slice().sort(this.sortEntity);
    this.#files = entities.files.slice().sort(this.sortEntity);

    this.#allEntities = new Map<string, IStorageEntity>();
    this.#controllers.forEach((controller) => this.#allEntities.set(controller.id, controller));
    this.#disks.forEach((disk) => this.#allEntities.set(disk.id, disk));
    this.#volumes.forEach((volume) => this.#allEntities.set(volume.id, volume));
    this.#files.forEach((file) => this.#allEntities.set(file.id, file));
  }

  get controllers(): ReadonlyArray<IStorageDiskController> {
    return this.#controllers;
  }

  get disks(): ReadonlyArray<IStoragePhysicalDisk> {
    return this.#disks;
  }

  get volumes(): ReadonlyArray<IStorageLogicalVolume> {
    return this.#volumes;
  }

  get files(): ReadonlyArray<IStorageSqlServerFile> {
    return this.#files;
  }

  get allEntities(): ReadonlyMap<string, IStorageEntity> {
    return this.#allEntities;
  }

  getEntityById(entityId: string): IStorageEntity | undefined {
    return this.#allEntities.get(entityId);
  }

  sortEntity(a: IStorageEntity, b: IStorageEntity): number {
    return a.name.localeCompare(b.name);
  }
}
