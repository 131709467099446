import * as React from "react";
import { IDateRange, useDateContext } from "../../../../components/DateContext";
import NavigationMenuItem from "../../../../components/NavigationContextMenu/NavigationMenuItem";
import { ChartRange } from "../../../../components/recharts";
import { useTopology } from "../../../../components/TopologyContext";
import { ITopologyItemDevice } from "../../../../components/TopologyContext/types";
import { MoRef } from "../../../../utilities/TopologyUtility";

interface VmGuestContextMenus {
  guestContextMenuOptions: React.ReactNode[];
}

function useVmGuestContextMenuOptions(
  hostDevice: ITopologyItemDevice,
  chartDateRange: ChartRange,
): VmGuestContextMenus {
  const { devices } = useTopology();
  const { getUrlWithDateRange, dateRange } = useDateContext();
  const urlDateRange =
    chartDateRange[0] && chartDateRange[1] ? { from: chartDateRange[0], to: chartDateRange[1] } : dateRange;
  const guestContextMenuOptions = hostDevice.children
    ?.map((guestMoRef) => {
      const device = devices.find((device) => MoRef.fromTopologyItem(device).toString() === guestMoRef.toString());
      if (device) {
        return (
          <NavigationMenuItem
            key={device.objectId}
            to={getUrlWithDateRange(`/${device.tenantId}/targets/${device.objectId}/performance`, urlDateRange)}
          >
            {device.name}
          </NavigationMenuItem>
        );
      }
    })
    /*"undefined" array elements are added if he host device has children that do not exist in the topology.
    realistically we should never have child moRefs that don't exist in the topology, but we want to filter 
    those out IF that scenario somehow happens.*/
    .filter((menuOption) => !!menuOption);
  return { guestContextMenuOptions };
}

export default useVmGuestContextMenuOptions;
