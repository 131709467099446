import { CardTable } from "@sentryone/material-ui";
import { SortDescriptor } from "@sentryone/react-datatable";
import * as React from "react";
import { useIntl } from "react-intl";
import ErrorBoundary from "../../components/ErrorBoundary";
import { IEventLogResponse } from "../../api/AdvisoryEventsService/AdvisoryEventsService";
import AdvisoryEventsGrid from "./Event/AdvisoryEventsGrid";
import { useTopology } from "../../components/TopologyContext";
import { useDateContext } from "../../components/DateContext";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { formatDate } from "../../components/FormattedDate";

interface IAdvisoryState {
  eventRow: IEventLogResponse | null;
  popupMessage?: any;
  viewTab: string;
}

export const Alerts: React.FunctionComponent<{
  onClearFilter: any,
  selectedChartCell: any,
  sort?: SortDescriptor[],
  ObjectAvailableTo?: string[],
  endDate?: Date,
  startDate?: Date,
}> = ({
  onClearFilter,
  selectedChartCell,
  sort,
  ObjectAvailableTo,
  endDate,
  startDate,
}) => {
  const intl = useIntl();

  const [advisoryState, updateAdvisoryState] = React.useState<IAdvisoryState>({
    eventRow: null,
    viewTab: "log",
  });

  const [dialog_open, setDialogOpen] = React.useState(false);

  const onSelectedRow = React.useCallback((row: IEventLogResponse | null): void => {
    updateAdvisoryState((prevState: IAdvisoryState) => {
      return { ...prevState, eventRow: row };
    });

    if(row != null)
      setDialogOpen(true);
  }, []);

  const { dateRange } = useDateContext();
  const { shouldShowFeature } = useTopology();
  const [has_alerts_permission] = React.useMemo(() => {
    return [
      shouldShowFeature("alerts")
    ];
  }, [shouldShowFeature]);

  return has_alerts_permission ?
      <>
        <CardTable data-testid="advisoryEventsGrid" title={intl.formatMessage({ id: "alertsLog" })}>
          <ErrorBoundary variant="generic">
            <AdvisoryEventsGrid
              className="noClass"
              endDate={endDate || dateRange.to}
              linkPaths={[{ source: "condition", target: "configuration" }]}
              objectAvailableTo={ObjectAvailableTo}
              onClearFilter={onClearFilter}
              onRowSelected={onSelectedRow}
              selectedChartCell={selectedChartCell}
              selectedRow={advisoryState.eventRow}
              sort={sort}
              startDate={startDate || dateRange.from}
            />
          </ErrorBoundary>
        </CardTable>
        <Dialog
          onClose={()=>setDialogOpen(false)}
          open={dialog_open}
        >
          <DialogTitle>
            {`${intl.formatMessage({ id: "alertDetails" })} |
            ${advisoryState?.eventRow?.targetName} |
            ${advisoryState?.eventRow?.startDateTimeUtc ?
              formatDate(advisoryState?.eventRow?.startDateTimeUtc, {
                day: "numeric",
                hour: "2-digit",
                minute: "numeric",
                month: "numeric",
                second: "numeric",
                year: "numeric",
              })
              : ''
            }`}
          </DialogTitle>
          <DialogContent>
            <Typography title={intl.formatMessage({ id: "message" })}>
              {advisoryState?.eventRow?.message
                ? advisoryState.eventRow.message.split("\n").map((item, index) => (
                    <React.Fragment key={index}>
                      {item}
                      <br />
                    </React.Fragment>
                  ))
                : intl.formatMessage({ id: "thereIsNoMessage" })}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setDialogOpen(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
      : <></>;
};
