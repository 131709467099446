import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import * as React from "react";
import { useIntl } from "react-intl";
import { ICustomDashboard } from "../../useDashboards";

export interface ICustomDashboardHeaderProps {
  editing: boolean;
  handleStartEditing: () => void;
  handleDeleteDashboard: () => void;
  handleSave: () => void;
  handleDiscard: () => void;
  dashboard?: ICustomDashboard; // possible undefined if loading dashboard
}

const useStyles = makeStyles((theme) => ({
  header: {
    height: theme.spacing(9),
  },
  headerText: {
    "&>h1": {
      fontSize: "1.45rem",
      letterSpacing: "0.5px",
    },
    display: "flex",
  },
}));

const CustomDashboardHeader: React.FC<ICustomDashboardHeaderProps> = ({
  dashboard,
  editing,
  handleStartEditing,
  handleDeleteDashboard,
  handleSave,
  handleDiscard,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <>
      <Grid alignItems="center" className={classes.header} container justifyContent="space-between">
        <Grid item>
          <Grid alignItems="center" container spacing={3}>
            <Grid item>
              <div className={classes.headerText}>
                <Typography color="textSecondary" component="h1" variant="h5">
                  {intl.formatMessage({ id: "dashboards" })}
                </Typography>
                <Divider flexItem orientation="vertical" variant="middle" />
                <Typography color="textSecondary" component="h1" variant="h5">
                  {dashboard ? dashboard.name : `${intl.formatMessage({ id: "loading" })}...`}
                </Typography>
              </div>
            </Grid>
            {!editing && (
              <Grid item>
                <Tooltip arrow enterDelay={500} placement="right" title={intl.formatMessage({ id: "editDashboard" })}>
                  <IconButton
                    aria-controls="editControl"
                    aria-haspopup
                    aria-hidden={editing}
                    color="default"
                    onClick={() => handleStartEditing()}
                    size="medium"
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
        {editing && (
          <Grid item>
            <Grid alignItems="center" container spacing={3}>
              <Grid item>
                <Button color="primary" onClick={handleDeleteDashboard} startIcon={<DeleteIcon />}>
                  {intl.formatMessage({ id: "delete" })}
                </Button>
              </Grid>
              <Grid item>
                <Button color="primary" onClick={handleDiscard} startIcon={<CancelIcon />}>
                  {intl.formatMessage({ id: "cancel" })}
                </Button>
              </Grid>
              <Grid item>
                <Button color="primary" onClick={handleSave} startIcon={<SaveIcon />} variant="contained">
                  {intl.formatMessage({ id: "saveAndExit" })}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Divider />
    </>
  );
};

export default CustomDashboardHeader;
