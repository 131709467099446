/* istanbul ignore file */
export function* getColorGenerator(): Generator<string, never, void> {
  while (true) {
    yield "#5F9EA0";
    yield "#FF7F50";
    yield "#808000";
    yield "#DEB887";
    yield "#B8860B";
    yield "#8B008B";
    yield "#E9967A";
    yield "#556B2F";
    yield "#CD853F";
    yield "#ADD8E6";
    yield "#FF6347";
    yield "#6495ED";
    yield "#8B4513";
    yield "#DB7093";
    yield "#2E8B57";
    yield "#FFEFD5";
    yield "#BDB76B";
    yield "#FFA500";
    yield "#B22222";
    yield "#008080";
    yield "#6A5ACD";
    yield "#C71585";
    yield "#4682B4";
    yield "#FFDAB9";
  }
}
