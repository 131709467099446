import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

const StopCircleIcon: React.FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <circle cx="12" cy="12" fill="transparent" r="6" stroke="currentColor" strokeWidth="1.5" />
      <rect fill="currentColor" height="6" rx="0.5" ry="0.5" width="6" x="9" y="9" />
    </SvgIcon>
  )
};

export default StopCircleIcon;
