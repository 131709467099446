import { faWindows } from "@fortawesome/free-brands-svg-icons/faWindows";
import { faDatabase } from "@fortawesome/free-solid-svg-icons/faDatabase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { TopologyDeviceType, TopologyEventSourceConnectionType } from "../TopologyContext/types";
import AmazonRDSIcon from "./AmazonRDSIcon";
import AzureIcon from "./AzureIcon";
import { FontSize, IconSizes } from "./types";
import VMWareIcon from "./VMWareIcon";
import OsIcon from "@material-ui/icons/DesktopWindows";
import SvgIcon from "@material-ui/core/SvgIcon";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

export interface ITargetIconProps {
  activeTargetType: string;
  className?: string;
  fontSize: FontSize;
  sizeIcon?: IconSizes;
}

const TargetIcon: React.FunctionComponent<ITargetIconProps> = ({ activeTargetType, className, fontSize, sizeIcon }) => {
  switch (activeTargetType) {
    case TopologyDeviceType.VMWare:
      return <VMWareIcon className={className} data-testid="vmware-target-icon" size={sizeIcon} />;
    case TopologyDeviceType.Windows:
      return (
        <SvgIcon className={className} data-testid="windows-target-icon" fontSize={fontSize}>
          <FontAwesomeIcon icon={faWindows} />
        </SvgIcon>
      );
    case TopologyDeviceType.AzureSQLDataWarehouse:
    case TopologyDeviceType.Linux:
    case TopologyDeviceType.ManagedInstance:
    case TopologyDeviceType.Tintri:
      return <OsIcon className={className} data-testid="os-target-icon" fontSize="small" />;
    case TopologyEventSourceConnectionType.AmazonRdsSqlServer:
      return <AmazonRDSIcon className={className} data-testid="amazonRDS-target-icon" size={sizeIcon} />;
    case TopologyEventSourceConnectionType.AzureSqlDatabase:
      return <AzureIcon className={className} data-testid="azure-target-icon" size={sizeIcon} />;
    case TopologyEventSourceConnectionType.Aps:
    case TopologyEventSourceConnectionType.AzureElasticPool:
    case TopologyEventSourceConnectionType.OracleDatabase:
    case TopologyEventSourceConnectionType.SharePointServer:
    case TopologyEventSourceConnectionType.SqlDataWarehouse:
    case TopologyEventSourceConnectionType.SqlServer:
    case TopologyEventSourceConnectionType.SqlServerAnalysisServices:
    case TopologyEventSourceConnectionType.TintriVMStore:
      return (
        <SvgIcon className={className} data-testid="db-target-icon" fontSize={fontSize}>
          <FontAwesomeIcon icon={faDatabase} />
        </SvgIcon>
      );
    default:
      return (
        <SvgIcon data-testid="questionmark-target-icon" fontSize={fontSize}>
          <FontAwesomeIcon icon={faQuestion} />
        </SvgIcon>
      );
  }
};

export default TargetIcon;
