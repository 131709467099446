import * as React from "react";
import Popover, { PopoverProps } from "@material-ui/core/Popover";
import useStyles from "./utils/styles";
import classNames from "classnames";

interface IFocusedDialogSelectorPopoverProps extends PopoverProps {
  paperClassName?: string;
}

const FocusedDialogSelectorPopover: React.FC<IFocusedDialogSelectorPopoverProps> = ({
  paperClassName,
  children,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Popover
      PaperProps={{ className: classNames(classes.popover, paperClassName) }}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      elevation={10}
      transformOrigin={{ horizontal: "center", vertical: "top" }}
      transitionDuration={150}
      {...props}
    >
      {children}
    </Popover>
  );
};

export default FocusedDialogSelectorPopover;
