import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { useTheme } from "@material-ui/core/styles";
import SettingsBackupRestore from "@material-ui/icons/SettingsBackupRestore";
import ZoomIn from "@material-ui/icons/ZoomIn";
import ZoomOut from "@material-ui/icons/ZoomOut";
import * as React from "react";
import { useIntl } from "react-intl";
import usePan from "./usePan";
import useZoom from "./useZoom";
import {
  DiagramLayoutFunction,
  IDiagramEdge,
  IDiagramNode,
  IDiagramEdgeInput,
  IDiagramLayout,
  IViewBox,
} from "./types";
import { ViewBox } from "./utils";

interface IDiagramProps<T> extends React.SVGProps<SVGSVGElement> {
  data: readonly T[];
  edges: readonly IDiagramEdgeInput<T>[];
  layout: DiagramLayoutFunction<T>;
  renderEdge: (edge: IDiagramEdge<T>, i: number, edges: readonly IDiagramEdge<T>[]) => React.ReactElement | null;
  renderNode: (node: IDiagramNode<T>, i: number, nodes: readonly IDiagramNode<T>[]) => React.ReactElement | null;
}

function Diagram<T>({
  children,
  className,
  data,
  edges,
  layout: layoutFunction,
  renderEdge,
  renderNode,
  ...props
}: IDiagramProps<T>): React.ReactElement | null {
  const intl = useIntl();
  const theme = useTheme();
  const layout = React.useMemo<IDiagramLayout<T>>(() => {
    return layoutFunction(data, edges);
  }, [data, edges, layoutFunction]);
  const [viewBox, setViewBox] = React.useState<IViewBox>(layout.viewBox);

  const { isPanning, onPointerDown, onPointerLeave, onPointerMove, onPointerUp } = usePan({
    onUpdateViewBox: setViewBox,
    viewBox,
  });
  const { canZoomIn, canZoomOut, onWheel, zoomIn, zoomOut } = useZoom({
    maxViewBox: layout.viewBox,
    minViewBox: new ViewBox(0, 0, 60, 60),
    onUpdateViewBox: setViewBox,
    viewBox,
  });

  React.useEffect(
    () => {
      setViewBox(layout.viewBox);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [layout.viewBox.height, layout.viewBox.minX, layout.viewBox.minY, layout.viewBox.width],
  );

  return (
    <div
      className={className}
      style={{
        overflow: "hidden",
        position: "relative",
      }}
    >
      <ButtonGroup
        color="default"
        size="small"
        style={{
          backgroundColor: theme.palette.common.white,
          left: theme.spacing(),
          position: "absolute",
          top: theme.spacing(),
        }}
      >
        <Button
          onClick={() => setViewBox(layout.viewBox)}
          title={intl.formatMessage({ id: "reset" })}
          variant="contained"
        >
          <SettingsBackupRestore />
        </Button>
        <Button
          disabled={!canZoomOut}
          onClick={zoomOut}
          title={intl.formatMessage({ id: "zoomOut" })}
          variant="contained"
        >
          <ZoomOut />
        </Button>
        <Button disabled={!canZoomIn} onClick={zoomIn} title={intl.formatMessage({ id: "zoomIn" })} variant="contained">
          <ZoomIn />
        </Button>
      </ButtonGroup>
      <svg
        onPointerDown={onPointerDown}
        onPointerLeave={onPointerLeave}
        onPointerMove={onPointerMove}
        onPointerUp={onPointerUp}
        onWheel={onWheel}
        preserveAspectRatio="xMinYMid meet"
        viewBox={viewBox.toViewBoxValue()}
        {...props}
        style={{
          ...props.style,
          cursor: isPanning ? "grabbing" : "grab",
          maxHeight: "100%",
          maxWidth: "100%",
          touchAction: "none",
          userSelect: "none",
          width: "100%",
        }}
      >
        {children}
        {layout.edges.map((e, i, a) => renderEdge(e, i, a))}
        {layout.nodes.map((n, i, a) => renderNode(n, i, a))}
      </svg>
    </div>
  );
}

export default Diagram;
