import { IMetricChartSeriesOptions } from "../../../../components/MetricChart";

export const resourcesMetrics = Object.freeze<IMetricChartSeriesOptions>([
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: "avgDurationMs",
    chartType: "line",
    color: "#253494",
    instance: null,
    label: "avgDurationMs",
    metric: "sqlserver.topsql.resources.avgDuration",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: "readsLogical",
    chartType: "line",
    color: "#DE7632",
    instance: null,
    label: "readsLogical",
    metric: "sqlserver.topsql.resources.logicalReads",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: "cpuTimeMs",
    chartType: "line",
    color: "#5FAB7B",
    instance: null,
    label: "cpuTimeMs",
    metric: "sqlserver.topsql.resources.cpu",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: "writesLogical",
    chartType: "line",
    color: "#FEC44F",
    instance: null,
    label: "writesLogical",
    metric: "sqlserver.topsql.resources.logicalWrites",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: "execCount",
    chartType: "line",
    color: "#3389AE",
    instance: null,
    label: "execCount",
    metric: "sqlserver.topsql.resources.executions",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: "readsPhysical",
    chartType: "line",
    color: "#2196F3",
    instance: null,
    label: "readsPhysical",
    metric: "sqlserver.topsql.resources.physicalReads",
  }),
]);
