import { makeStyles } from "@material-ui/core/styles";
import { CardTable, MuiDataTable } from "@sentryone/material-ui";
import { Column, SortDescriptor } from "@sentryone/react-datatable";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { DateColumn, TextEllipsisColumn } from "../../../../components/DataTable";
import classNames from "classnames";
import { useDeadlocksContext } from "../../../../contexts/deadlocksContext";

const useStyles = makeStyles((theme) => ({
  grid: {
    gridArea: "grid",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}));

interface IVictimGridRow {
  id: number;
  processId: string;
  victimEcid: number;
  victimSpid: number;
  victimHost: string;
  victimApplication: string;
  victimDatabase: string;
  statement: string;
  time: Date;
}

interface IPageSettings {
  pageIndex: number;
  pageSize: number;
}

const VictimsGrid: React.FC = () => {
  const [sort, setSort] = React.useState<SortDescriptor[] | undefined>();
  const classes = useStyles();
  const { deadlockInfo, error, loading, selectedDeadlockId, onChangeDeadlock } = useDeadlocksContext();
  const [selectedGridProcess, setSelectedGridProcess] = React.useState<string | undefined>();
  const [topRow, setTopRow] = React.useState<IVictimGridRow | undefined>();

  const setSelection = (newSelection: IVictimGridRow): void => {
    setSelectedGridProcess(newSelection.processId);
    onChangeDeadlock(newSelection.processId, newSelection.id);
  };

  const defaultPageSettings: IPageSettings = {
    pageIndex: 0,
    pageSize: 5,
  };

  const [pageInfo, setPageInfo] = React.useState<IPageSettings>(defaultPageSettings);

  const rowData = React.useMemo<readonly IVictimGridRow[]>(() => {
    return (deadlockInfo ?? []).reduce<IVictimGridRow[]>((rows, deadlock) => {
      deadlock.deadlocks.forEach((deadlockItem) => {
        deadlockItem.victimsIds.forEach((victimId) => {
          const victimProcess = deadlockItem.processes.find((p) => p.processId === victimId);
          if (victimProcess) {
            const row: IVictimGridRow = {
              id: deadlock.id,
              processId: victimProcess.processId,
              statement: victimProcess.executionStack
                .map((x) => x.text.trim())
                .concat([victimProcess.inputBufferText?.trim() ?? ""])
                .filter((x) => x && x !== "unknown")
                .join(","),
              time: new Date(deadlock.startTime),
              victimApplication: victimProcess.clientApplication,
              victimDatabase: victimProcess.databaseName,
              victimEcid: victimProcess.eCID,
              victimHost: victimProcess.hostName,
              victimSpid: victimProcess.sPID,
            };
            rows.push(row);
          }
        });
      });
      return rows;
    }, []);
  }, [deadlockInfo]);

  React.useEffect(
    () => {
      !selectedGridProcess && topRow && setSelection(topRow);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [topRow],
  );

  React.useEffect(() => {
    setSelectedGridProcess(undefined);
  }, [rowData]);

  return (
    <CardTable
      className={classes.grid}
      data-testid="VictimsGrid"
      title={
        <>
          <span id="victimsGridHeader">
            <FormattedMessage id="deadlocks" />
          </span>
        </>
      }
    >
      <MuiDataTable
        data={rowData}
        defaultPageSize={5}
        defaultSort={[{ direction: "desc", id: "time" }]}
        error={error}
        isLoading={loading}
        keySelector={(d) => `${d.id}-${d.processId}`}
        onChangePage={(pi, ps) => setPageInfo({ pageIndex: pi, pageSize: ps })}
        onChangeSort={(newSort: readonly SortDescriptor[]) => setSort([...newSort])}
        onDataReady={(d) => d.length && setTopRow(d[0])}
        pageIndex={pageInfo.pageIndex}
        pageSize={pageInfo.pageSize}
        pageVariant="rolling"
        sort={sort}
        tableRowProps={(d) => {
          if (!d) {
            return {};
          } else {
            return {
              "aria-selected":
                !!selectedGridProcess &&
                selectedDeadlockId !== undefined &&
                d.processId === selectedGridProcess &&
                d.id === selectedDeadlockId,
              onClick: () => setSelection(d),
            };
          }
        }}
      >
        <DateColumn<IVictimGridRow>
          cellProps={() => ({
            className: classes.noWrap,
          })}
          field="time"
          format={{
            day: "numeric",
            hour: "2-digit",
            minute: "numeric",
            month: "2-digit",
            year: "numeric",
          }}
          header={<FormattedMessage id="time" />}
        />
        <Column<IVictimGridRow>
          cellProps={() => ({
            className: classes.noWrap,
          })}
          header={<FormattedMessage id="victimSpid" />}
          id="victimSpid"
          renderCell={(d) => `${d.victimSpid} [${d.victimEcid}]`}
          sort={(a, b) => a.victimSpid - b.victimSpid || a.victimEcid - b.victimEcid}
        />
        <TextEllipsisColumn<IVictimGridRow>
          field="victimHost"
          header={<FormattedMessage id="victimHost" />}
          title={(dataItem) => dataItem.victimHost}
        />
        <TextEllipsisColumn<IVictimGridRow>
          field="victimApplication"
          header={<FormattedMessage id="victimApplication" />}
          title={(dataItem) => dataItem.victimApplication}
        />
        <TextEllipsisColumn<IVictimGridRow>
          field="victimDatabase"
          header={<FormattedMessage id="victimDatabase" />}
          title={(dataItem) => dataItem.victimDatabase}
        />
        <TextEllipsisColumn<IVictimGridRow>
          field="statement"
          header={<FormattedMessage id="statement" />}
          title={(dataItem) => dataItem.statement}
        />
      </MuiDataTable>
    </CardTable>
  );
};

export default VictimsGrid;
