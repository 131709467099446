import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import TopSqlService from "../../../../api/TopSqlService";
import { IMatchedPlanStatement, IPlanData, IPlanStatement } from "../../../../api/TopSqlService/models/PlanData";
import { PlanProvider } from "../../../../contexts/planContext";
import { useTopSqlContext } from "../../../../contexts/topSqlContext";
import { useAsync } from "../../../../hooks/Async";
import StatementDetailsContent, { StatementDetailsTab } from "./StatementDetailsContent";
import { findMatchingStatement } from "./findMatchingStatements";

export interface IQueryParameter {
  parameterName: string;
  compiledValue: string;
  runtimeValue: string;
}

interface IStatementDetailsProps {
  className?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    flex: 1,
  },
}));

const StatementDetails: React.FunctionComponent<IStatementDetailsProps> = ({ className }) => {
  const classes = useStyles({});
  const animateRef = React.useRef(true);
  const [fullScreen, setFullScreen] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState(StatementDetailsTab.PlanDiagram);
  const {
    cachePlanId: planId,
    eventSourceConnectionId,
    selectedEvent,
    selectedTotal,
    selectedStatement,
    selectedStatementEvent,
    statementResults,
    totalsResults,
    totalsTraceEventCriteria,
    totalsTraceEventsResults,
  } = useTopSqlContext();

  const isStatementSelected = !!selectedStatement;
  const procedureTextData = selectedEvent ? selectedEvent.textData : selectedTotal ? selectedTotal.textData : "";
  const selectionInfo: { queryHash?: string | null; textData: string } | null = selectedStatement
    ? selectedStatement
    : selectedStatementEvent;
  const textData = Array.from(new Set([procedureTextData, selectionInfo?.textData]))
    .filter(Boolean)
    .join("\n----------\n");

  const toggleFullScreen = React.useCallback(() => {
    setFullScreen((prev) => !prev);
  }, []);

  const isLoadingParents =
    statementResults.isLoading ||
    totalsResults.isLoading ||
    (!!totalsTraceEventCriteria && totalsTraceEventsResults.isLoading);
  const promiseFn = React.useCallback(
    (_: never, controller: AbortController): Promise<IPlanData | null> => {
      if (typeof planId === "undefined") {
        // Plan has not been determined (other data is loading)
        // Return a Promise that never resolves
        return new Promise(() => null);
      } else if (planId === null) {
        // No available plan
        return Promise.resolve(null);
      } else {
        const service = new TopSqlService();
        return service.fetchTracePlans({ eventSourceConnectionId, planId }, controller);
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [eventSourceConnectionId, planId, isLoadingParents],
  );

  const { data: planData, isLoading: isLoadingResults } = useAsync(promiseFn, {
    onResolve: () => {
      animateRef.current = true;
    },
  });

  const isLoading = isLoadingResults || isLoadingParents;

  const matchedPlanStatement = React.useMemo<IMatchedPlanStatement | null>(() => {
    return !isLoading && planData ? findMatchingStatement(selectionInfo ?? { textData: "" }, planData) : null;
  }, [isLoading, planData, selectionInfo]);

  const handleTabChange = React.useCallback((tab: StatementDetailsTab) => {
    animateRef.current = false;
    setSelectedTab(tab);
  }, []);

  return (
    <PlanProvider planToken={planData?.planToken} statementId={matchedPlanStatement?.planStatement.id}>
      <Card className={classNames(classes.root, className)} data-testid="statementDetails">
        <StatementDetailsContent
          animate={animateRef.current}
          isStatementSelected={isStatementSelected}
          loading={isLoading}
          matchedPlanStatement={matchedPlanStatement}
          onTabChange={handleTabChange}
          onToggleFullScreen={toggleFullScreen}
          selectedTab={selectedTab}
          textData={textData}
        />
      </Card>
      <Dialog className={classes.root} fullScreen onClose={toggleFullScreen} open={fullScreen}>
        <StatementDetailsContent
          animate={animateRef.current}
          fullScreen
          isStatementSelected={isStatementSelected}
          loading={isLoading}
          matchedPlanStatement={matchedPlanStatement}
          onTabChange={handleTabChange}
          onToggleFullScreen={toggleFullScreen}
          selectedTab={selectedTab}
          textData={textData}
        />
      </Dialog>
    </PlanProvider>
  );
};

export default StatementDetails;
