import { makeStyles } from "@material-ui/core/styles";
import * as noUiSlider from "nouislider";
import "nouislider/dist/nouislider.css";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".noUi-connect": {
      background: theme.palette.primary.main,
    },
    ".noUi-handle": {
      "&::before,&::after": {
        content: "none",
      },
      backgroundColor: theme.palette.background.paper,
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: "50%",
      boxShadow: "none",
      boxSizing: "border-box",
    },
    ".noUi-horizontal .noUi-handle": {
      // Style the tooltips
      "& .noUi-tooltip": {
        bottom: "-100%",
        color: theme.typography.caption.color,
        fontSize: theme.typography.caption.fontSize,
        padding: 2,
      },
      "&.noUi-handle-lower": {
        cursor: "w-resize",
      },
      "&.noUi-handle-upper": {
        cursor: "e-resize",
      },
      width: 28,
    },
    // Hide the tick markers
    ".noUi-marker": {
      display: "none",
    },
    ".noUi-target": {
      // Hide tooltips until hover or drag
      "&:not(.noUi-state-drag) .noUi-handle:not(:hover):not(:focus) .noUi-tooltip": {
        display: "none",
      },
      "&[disabled='true']": {
        "& .noUi-draggable": {
          cursor: "not-allowed",
        },
        "& .noUi-handle": {
          borderColor: theme.palette.grey[600],
          cursor: "not-allowed",
        },
        "&.noUi-connect": {
          background: theme.palette.grey[400],
        },
        background: theme.palette.grey[300],
      },
      background: theme.palette.primary.light,
      border: "none",
      boxShadow: "none",
      margin: "5px 18px",
    },
    ".noUi-value-horizontal": {
      color: theme.typography.caption.color,
      fontSize: theme.typography.caption.fontSize,
      transform: "translate(-50%, -30%)",
    },
  },
}));

type NoUiSliderBehavior = "drag" | "drag-fixed" | "tap" | "tap-drag" | "hover" | "unconstrained-tap" | "none";

interface INoUiSliderPropsGeneric<T> {
  behaviour?: NoUiSliderBehavior;
  className?: string;
  connect?: boolean;
  disabled?: boolean;
  format?: {
    from: (value: string) => number;
    to: (value: number) => string;
  };
  onChange: (value: T) => void;
  range: {
    max: number;
    min: number;
  };
  tooltips?:
    | boolean
    | Array<{
        from: (value: string) => number;
        to: (value: number) => string;
      }>;
  value: T;
}

export type INoUiSliderProps = INoUiSliderPropsGeneric<number> | INoUiSliderPropsGeneric<[number, number]>;

const NoUiSlider: React.FunctionComponent<INoUiSliderProps> = ({
  behaviour,
  className,
  connect,
  disabled = false,
  format,
  onChange,
  range,
  tooltips,
  value,
}) => {
  useStyles();
  const rootEl = React.useRef<HTMLDivElement>(null);
  const slider = React.useRef<noUiSlider.API | null>(null);

  const fixedFormat = React.useMemo(() => {
    if (!format) {
      return undefined;
    } else {
      return {
        from: Number,
        to: format.to,
      };
    }
  }, [format]);

  // Eventually this should be moved to props as well
  const pips = React.useMemo<noUiSlider.Options["pips"]>(() => {
    return {
      density: 100,
      format: fixedFormat,
      mode: noUiSlider.PipsMode.Count,
      values: 2,
    };
  }, [fixedFormat]);

  // Create the slider once when the div is added to the DOM
  React.useLayoutEffect(
    () => {
      if (rootEl.current) {
        const scopedSlider = (slider.current = noUiSlider.create(rootEl.current, {
          behaviour,
          connect,
          format: fixedFormat,
          pips,
          range,
          start: value,
          tooltips,
        }));
        return () => scopedSlider.destroy();
      }
      return;
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // If the onChange or value prop changes reset the event listener on the slider
  React.useEffect(() => {
    const scopedSlider = slider.current;
    if (!scopedSlider) {
      return;
    }

    if (typeof onChange === "function") {
      const reverseFormat = format && typeof format.from === "function" ? format.from : Number;
      if (Array.isArray(value)) {
        scopedSlider.on("change", (_: any[], __: number, newValueNumbers: number[]) => {
          // newValue comes in as an array of strings, so convert them to numbers
          if (newValueNumbers.length !== value.length || newValueNumbers.some((x, i) => value[i] !== x)) {
            (onChange as (x: number[]) => void)(newValueNumbers as number[]);
          }
        });
        return () => scopedSlider.off("change");
      } else {
        scopedSlider.on("change", (newValue: any) => {
          // newValue comes in as a single value here rather than an array, but the type definition is wrong
          const newValueNumber = reverseFormat(newValue);
          if (newValueNumber !== value) {
            (onChange as (x: number) => void)(newValueNumber);
          }
        });
      }
      return () => scopedSlider.off("change");
    }
  }, [format, onChange, value]);

  // If the value prop is changed, set the values in the slider
  React.useEffect(() => {
    slider.current?.set(value);
  }, [value]);

  // If options props change, update the options in the slider
  React.useEffect(() => {
    slider.current?.updateOptions(
      {
        format: fixedFormat,
        pips,
        range,
        start: value,
        tooltips,
      },
      false,
    );
  }, [fixedFormat, pips, range, tooltips, value]);

  // If disable changes, update the disabled attribute
  React.useEffect(() => {
    if (disabled) {
      rootEl.current?.setAttribute("disabled", "true");
    } else {
      rootEl.current?.removeAttribute("disabled");
    }
  }, [disabled]);

  return <div className={className} ref={rootEl}></div>;
};

export default NoUiSlider;
