import { IPrincipal, IPrincipalCategory, IUser, IUserGroup } from "../../types";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useIntl } from "react-intl";
import * as React from "react";

export interface IPrincipalMultipleSelectProps {
  selectedPrincipals: IPrincipal[];
  setSelectedPrincipals: (entities: IPrincipal[]) => void;
  allPrincipals: IPrincipal[];
  category: IPrincipalCategory;
}

const useStyles = makeStyles(() => ({
  chip: {
    margin: 2,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const PrincipalMultipleSelect: React.FC<IPrincipalMultipleSelectProps> = ({
  selectedPrincipals,
  setSelectedPrincipals,
  allPrincipals,
  category,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const newUserIds = (event.target.value as unknown) as string[];
    const newUsers = allPrincipals.filter((x) => newUserIds.some((y) => y === x.id));
    setSelectedPrincipals(newUsers);
  };

  const createEntityLabel = (entity: IPrincipal): string => {
    return category === "users"
      ? `${(entity as IUser).firstName} ${(entity as IUser).lastName}`
      : (entity as IUserGroup).name;
  };

  return (
    <FormControl>
      <InputLabel id="multiple-select-label">
        {category === "users" ? intl.formatMessage({ id: "users" }) : intl.formatMessage({ id: "groups" })}
      </InputLabel>
      <Select
        MenuProps={MenuProps}
        fullWidth
        id="multiple-select"
        input={<Input data-testid="multiple-select" id="multiple-select" />}
        labelId="multiple-select-label"
        multiple
        onChange={(e) => handleChange(e as React.ChangeEvent<HTMLSelectElement>)}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {(selected as string[]).map((selected) => {
              const entity = allPrincipals.find((e) => e.id === selected);
              return entity ? (
                <Chip
                  className={classes.chip}
                  data-testid="user-entity-chip"
                  key={entity.id}
                  label={createEntityLabel(entity)}
                />
              ) : null;
            })}
          </div>
        )}
        value={selectedPrincipals.map((u) => u.id)}
        variant="filled"
      >
        {allPrincipals.map((entity) => (
          <MenuItem key={entity.id} value={entity.id}>
            {createEntityLabel(entity)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default PrincipalMultipleSelect;
