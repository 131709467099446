import * as React from "react";
import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import { AlertTitle } from "@material-ui/lab";
import type { Color } from "@material-ui/lab/Alert";
import Fade from "@material-ui/core/Fade";
import Grow from "@material-ui/core/Grow";
import Slide, { SlideProps } from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  alertTitle: {
    color: "white",
  },
  message: {
    paddingTop: theme.spacing(2),
  },
}));

type variant = "outlined" | "filled" | "standard" | undefined;

type animation = "Grow" | "Fade";

type slideDirection = "left" | "up" | "right" | "down";

type iconMap = {
  success?: React.ReactElement;
  error?: React.ReactElement;
  warning?: React.ReactElement;
  info?: React.ReactElement;
};

type commonSnackBarProps = {
  open: boolean;
  message: string;
  autoHideDuration?: number;
  position?: SnackbarOrigin;
  variant?: variant;
  type?: Color;
  icon?: React.ReactElement;
  showIcon?: boolean;
  action?: React.ReactElement;
  handleClose: () => void;
  title?: string;
};

type SnackbarProps =
  | (commonSnackBarProps & {
      animation?: animation;
      slideDirection?: slideDirection;
    })
  | (commonSnackBarProps & {
      animation?: "Slide";
      slideDirection: slideDirection;
    });

type TransitionProps = Omit<SlideProps, "direction">;

function TransitionLeft(props: TransitionProps): React.ReactElement {
  return <Slide {...props} direction="left" />;
}

function TransitionUp(props: TransitionProps): React.ReactElement {
  return <Slide {...props} direction="up" />;
}

function TransitionRight(props: TransitionProps): React.ReactElement {
  return <Slide {...props} direction="right" />;
}

function TransitionDown(props: TransitionProps): React.ReactElement {
  return <Slide {...props} direction="down" />;
}

function GrowTransition(props: TransitionProps): React.ReactElement {
  return <Grow {...props} />;
}

const CustomSnackbar = (props: SnackbarProps): React.ReactElement => {
  const classes = useStyles();

  const [showIcon, setShowIcon] = React.useState<boolean>(true);
  const [position, setPosition] = React.useState<SnackbarOrigin>({
    horizontal: "left",
    vertical: "bottom",
  });
  const [animation, setAnimation] = React.useState<
    React.ComponentType<TransitionProps & { children?: React.ReactElement<any, any> }>
  >(Fade);

  React.useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(props, "showIcon")) {
      setShowIcon(props.showIcon ?? false);
    }

    if (props.position) {
      setPosition(props.position);
    }

    if (props.animation === "Fade") {
      setAnimation(Fade);
    }

    if (props.animation === "Grow") {
      setAnimation(() => GrowTransition);
    }

    if (props.animation === "Slide" && props.slideDirection) {
      if (props.slideDirection === "down") setAnimation(() => TransitionDown);

      if (props.slideDirection === "up") setAnimation(() => TransitionUp);
      if (props.slideDirection === "left") setAnimation(() => TransitionLeft);

      if (props.slideDirection === "right") setAnimation(() => TransitionRight);
    }
  }, [props]);

  const iconMapping: iconMap = {};
  if (props.icon) iconMapping[props.type ?? "success"] = props.icon;

  const { vertical, horizontal } = position;

  return (
    <Snackbar
      TransitionComponent={animation}
      anchorOrigin={{ horizontal, vertical }}
      autoHideDuration={props.autoHideDuration ?? 6000}
      onClose={props.handleClose}
      open={props.open ?? false}
    >
      <Alert
        action={props?.action ?? props.action}
        classes={{
          message: classes.message,
        }}
        icon={showIcon ? undefined : showIcon}
        iconMapping={props.icon ? iconMapping : undefined}
        onClose={props.handleClose}
        severity={props.type ?? "success"}
        variant={props.variant ?? "filled"}
      >
        {props.title && <AlertTitle className={classes.alertTitle}>{props.title}</AlertTitle>}
        {props.message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
