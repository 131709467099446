import * as React from "react";
import SelectionContext from "./";
import { ChartRange } from "../../components/recharts";

const SelectionProvider: React.FunctionComponent = ({ children }) => {
  const [selection, setSelection] = React.useState([null, null] as ChartRange);
  return (
    <SelectionContext.Provider
      value={{
        selection,
        setSelection,
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
};

export default SelectionProvider;
