import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useIntl } from "react-intl";
import { TooltipPayload, TooltipProps } from "recharts";
import { IMetricChartTooltipGroup } from "./types";

const useStyles = makeStyles((theme) => ({
  circle: {
    display: "inline-block",
    height: "1em",
    marginRight: 4,
    verticalAlign: "text-top",
    width: "1em",
  },
  detailRow: {
    "& th": {
      paddingLeft: theme.spacing(),
    },
  },
  groupRow: {},
  headerBody: {
    "& tr:last-of-type": {
      "& th,td": {
        borderBottom: "1px solid #555555",
        paddingBottom: theme.spacing(),
      },
    },
  },
  name: {
    display: "inline-block",
    maxWidth: "70ch",
    overflow: "hidden",
    textOverflow: "ellipsis",
    verticalAlign: "middle",
    whiteSpace: "nowrap",
  },
  root: {
    "& $groupRow": {
      "& th": {
        color: "inherit",
        fontSize: "0.714rem",
        textAlign: "left",
        textTransform: "uppercase",
      },
      "& th,td": {
        paddingTop: theme.spacing(),
      },
    },
    "& caption": {
      fontSize: "0.857rem",
      paddingBottom: theme.spacing(),
    },
    "& table": {
      borderCollapse: "collapse",
    },
    "& td": {
      color: "inherit",
      fontSize: "0.857rem",
      fontWeight: "bold",
      textAlign: "right",
    },
    "& th": {
      color: "#aaaaaa",
      fontSize: "0.857rem",
      textAlign: "left",
    },
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(),
    whiteSpace: "nowrap",
  },
}));

export interface IMetricTooltipProps extends Omit<TooltipProps, "payload"> {
  headerPayload: TooltipPayload;
  payload: readonly IMetricChartTooltipGroup[];
}

/**
 * MetricTooltip is a series/point specific detail used by some charts.
 * It parallels TabularTooltip in style, but shows the breakout tooltip data rather than the list of all points at the time slice.
 * @param props
 */
const MetricTooltip: React.FC<IMetricTooltipProps> = ({ formatter, headerPayload, label, labelFormatter, payload }) => {
  const intl = useIntl();
  const classes = useStyles();
  const payloadWithTotals = payload.map((x) => {
    return {
      ...x,
      total: x.entries.reduce((prev, curr) => prev + curr.value, 0),
    };
  });
  return (
    <div className={classes.root}>
      <table>
        {label && <caption>{typeof labelFormatter === "function" ? labelFormatter(label) : label}</caption>}
        <tbody className={classes.headerBody}>
          <tr>
            <th scope="row">
              {/* The viewBox used here is the same as the Recharts Legend */}
              <svg className={classes.circle} viewBox="0 0 32 32">
                <circle cx={16} cy={16} fill={headerPayload.color} r={16} />
              </svg>
              {intl.formatMessage({ defaultMessage: headerPayload.name, id: headerPayload.name })}
            </th>
            {/* any is needed because of a type mismatch in @types/recharts. One is readonly while the other isn't */}
            <td>
              {typeof formatter === "function"
                ? formatter(headerPayload.value as any, headerPayload.name, headerPayload, 0)
                : headerPayload.value}
            </td>
          </tr>
        </tbody>
        {payloadWithTotals.length === 0 && (
          <tbody>
            <tr>
              <td colSpan={2}>{intl.formatMessage({ id: "noDataToDisplay" })}</td>
            </tr>
          </tbody>
        )}
        {payloadWithTotals
          .sort((a, b) => b.total - a.total)
          .map((group, ig) => (
            // Index is safe in key here because child components do not have state to leak.
            // The index is needed since the group name is not guaranteed unique.
            <tbody key={`${group.groupName}-${ig}`}>
              <tr className={classes.groupRow}>
                <th colSpan={2}>{intl.formatMessage({ defaultMessage: group.groupName, id: group.groupName })}</th>
              </tr>
              {[...group.entries]
                .sort((a, b) => b.value - a.value)
                .map((e, ie) => (
                  <tr className={classes.detailRow} key={`${e.name}-${ie}`}>
                    <th scope="row">
                      <span className={classes.name}>{intl.formatMessage({ defaultMessage: e.name, id: e.name })}</span>
                    </th>
                    <td>{typeof formatter === "function" ? formatter(e.value, e.name, headerPayload, ie) : e.value}</td>
                  </tr>
                ))}
            </tbody>
          ))}
      </table>
    </div>
  );
};

export default MetricTooltip;
