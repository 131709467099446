import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import * as VMHost from "../VMHostCharts";
import * as VMGuest from "../VMGuestCharts";
import ChartCard from "./ChartCard";

const useStyles = makeStyles((theme) => ({
  host: {},
  root: {
    display: "grid",
    gridAutoFlow: "row dense",
    gridAutoRows: theme.spacing(45),
    gridGap: theme.spacing(2),
    gridTemplateColumns: "1fr",
    [theme.breakpoints.up("lg")]: {
      "& $host": {
        gridColumn: 1,
      },
      "& $vm": {
        gridColumn: 2,
      },
      gridTemplateColumns: "1fr 1fr",
    },
  },
  vm: {},
}));

const VMWarePerformanceAnalysis: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ChartCard chartComponent={VMHost.NetworkChart} className={classes.host} title="network" vertical />
      <ChartCard chartComponent={VMHost.CpuChart} className={classes.host} title="cpu" />
      <ChartCard chartComponent={VMHost.SystemMemoryChart} className={classes.host} title="systemMemory" />
      <ChartCard chartComponent={VMHost.DiskIOChart} className={classes.host} title="disk" />
      <ChartCard chartComponent={VMGuest.NetworkChart} className={classes.vm} title="networkVM" vertical />
      <ChartCard chartComponent={VMGuest.CpuChart} className={classes.vm} title="cpuVM" />
      <ChartCard chartComponent={VMGuest.SystemMemoryChart} className={classes.vm} title="systemMemoryVM" />
      <ChartCard chartComponent={VMGuest.DiskIOChart} className={classes.vm} title="diskVM" />
    </div>
  );
};

export default VMWarePerformanceAnalysis;
