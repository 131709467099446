import * as React from "react";
import DocumentTitle from "../../components/DocumentTitle";
import { TopologyObjectType } from "../../components/TopologyContext";
import { useCurrentTarget } from "../../hooks/useCurrentTarget";
import TempDbContent from "./TempDbContent";

const TempDb: React.FC = () => {
  const target = useCurrentTarget();
  if (!target) {
    throw new Error("TempDB can only be used with a target");
  } else if (target.type !== TopologyObjectType.eventSourceConnection) {
    throw new Error(`TempDB can only be used with event source connections. Received ${target.type}`);
  }

  return (
    <>
      <DocumentTitle title="tempDb" topologyItem={target} />
      {/* key will cause TempDbContent to completely reset its state when switching targets */}
      <TempDbContent key={target.objectId} target={target} />
    </>
  );
};

export default TempDb;
