import { IHealthStatusItem } from "../../../../api/PerformanceAdvisor/models";

export enum HealthScoreRange {
  atRisk = "atRisk",
  critical = "critical",
  healthy = "healthy",
}

export interface IHealthDataLookup {
  [objectId: string]: IHealthStatusItem;
}
