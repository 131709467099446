import FormControlLabel from "@material-ui/core/FormControlLabel";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import classNames from "classnames";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { defaults } from "../../../../components/ThemeProvider/grids";
import { useTopology } from "../../../../components/TopologyContext";
import { useSortContext } from "../../../../contexts/sortContext";
import { useTopSqlContext } from "../../../../contexts/topSqlContext";
import QueryHistory from "../QueryHistory";
import TopSqlChart from "../TopSqlChart";
import StatementDetails from "../StatementDetails";
import StatementsGrid from "../StatementsGrid";
import TotalsGrid from "../TotalsGrid";
import TraceEventsGrid from "../TraceEventsGrid";
import { useQuery } from "@apollo/react-hooks";
import * as GET_TOPSQL_FILTER_DROPDOWN from "./TopSqlFilterQuery.graphql";
import sqlTraceEvents from "../../../../api/sqlTraceEvents";
import { Errors } from "../../TopSqlEnums";

const useStyles = makeStyles((theme) => ({
  chart: {
    gridArea: "chart",
    overflow: "visible",
    width: "100%",
  },
  queryHistory: {
    gridArea: "queryHistory",
  },
  root: {
    display: "grid",
    gridGap: theme.spacing(2),
    gridTemplateAreas: `
      "chart chart"
      "switch switch"
      "totals totals"
      "statements statements"
      "queryHistory statementDetails"
    `,
    gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr)",
    gridTemplateRows: "min-content min-content min-content min-content min-content",
  },
  statementDetails: {
    gridArea: "statementDetails",
    height: "100%",
    position: "relative",
  },
  statementDetailsContent: {
    // Absolute position the content inside statementDetails and stretch to fit
    // This prevents the statementDetails section from affecting the height of the grid row and instead match the height
    height: "100%",
    left: 0,
    position: "absolute",
    top: 0,
    width: "100%",
  },
  statements: {
    gridArea: "statements",
  },
  totalSwitch: {
    alignSelf: "center",
    gridArea: "switch",
    justifySelf: "start",
  },
  totals: {
    gridArea: "totals",
    width: "100%",
  },
}));

export interface ITopSqlContentProps {
  eventSourceConnectionId: number;
  visibleDates: Readonly<{
    from: Date;
    to: Date;
  }>;
}

export interface ITopSQLFilterQuery {
  topSqlFilterDataList: {
    databaseNames: string[];
    hostNames: string[];
    applicationNames: string[];
    loginNames: string[];
    sPIDs: number[];
    errors: number[];
    eventClasses: number[];
  };
}

const TopSqlContent: React.FunctionComponent<ITopSqlContentProps> = ({ eventSourceConnectionId, visibleDates }) => {
  const intl = useIntl();
  const {
    chartFilter,
    handleChangeChartFilter,
    handleChangeIsTotals,
    handleTraceEventGridPageChange,
    handleSelectedEventChange,
    handleSelectedEventStatementChange,
    handleStatementGridPageChange,
    handleStatementsEventsBackClick,
    handleTotalsEventsBackClick,
    isTraceStatement,
    isTotals,
    totalsGridPageInfo,
    traceEventGridPageInfo,
    setCachePlanId,
    selectedEvent,
    selectedStatement,
    selectedStatementEvent,
    selectedTotal,
    statementsTraceEventCriteria,
    statementGridPageInfo,
    statementsTraceEventsResults,
    totalsTraceEventCriteria,
    totalsTraceEventsResults,
  } = useTopSqlContext();
  const sortContext = useSortContext();
  const classes = useStyles();
  const topology = useTopology();
  const target = topology.eventSourceConnections.find((x) => x.itemId === eventSourceConnectionId);
  if (!target) {
    throw new Error(`Target TopologyItem ${eventSourceConnectionId} not found`);
  }

  const {
    loading,
    error,
    data = {
      topSqlFilterDataList: {
        applicationNames: [],
        databaseNames: [],
        errors: [],
        eventClasses: [],
        hostNames: [],
        loginNames: [],
        sPIDs: [],
      },
    },
  } = useQuery<ITopSQLFilterQuery>(GET_TOPSQL_FILTER_DROPDOWN, {
    variables: {
      eventSourceConnectionID: eventSourceConnectionId,
    },
  });

  const topsqlFilterDropDownData = React.useMemo<any | null>(() => {
    return {
      ...data,
      topSqlFilterDataList: {
        ...data.topSqlFilterDataList,
        applicationNames: data.topSqlFilterDataList.applicationNames.reduce<
          Array<{ id: string | number; name: string }>
        >(function (result, x) {
          if (x != "") result.push({ id: x, name: x });
          return result;
        }, []),
        databaseNames: data.topSqlFilterDataList.databaseNames.reduce<Array<{ id: string | number; name: string }>>(
          function (result, x) {
            if (x != "") result.push({ id: x, name: x });
            return result;
          },
          [],
        ),
        errors: Errors,
        eventClasses: data.topSqlFilterDataList.eventClasses.reduce<Array<{ id: string | number; name: string }>>(
          function (result, x) {
            if ((x as keyof typeof sqlTraceEvents) in sqlTraceEvents)
              result.push({ id: x, name: sqlTraceEvents[x as keyof typeof sqlTraceEvents] });
            return result;
          },
          [],
        ),
        hostNames: data.topSqlFilterDataList.hostNames.reduce<Array<{ id: string | number; name: string }>>(function (
          result,
          x,
        ) {
          if (x != "") result.push({ id: x, name: x });
          return result;
        },
        []),
        loginNames: data.topSqlFilterDataList.loginNames.reduce<Array<{ id: string | number; name: string }>>(function (
          result,
          x,
        ) {
          if (x != "") result.push({ id: x, name: x });
          return result;
        },
        []),
        sPIDs: data.topSqlFilterDataList.sPIDs.map((x: any) => {
          return { id: x, name: x };
        }),
      },
    };
  }, [data]);

  const handleTotalsDefaultBackClick = (): void => {
    if (isTotals) {
      sortContext.updateTotalsTrace([
        {
          direction: "desc",
          id: "reads",
        },
      ]);
      handleTotalsEventsBackClick();
    }
  };

  const handleStatementDefaultBackClick = (): void => {
    sortContext.updateStatementsTrace([
      {
        direction: "desc",
        id: "reads",
      },
    ]);
    handleStatementsEventsBackClick();
  };

  const handleTotalsDefault = (): void => {
    handleChangeIsTotals();
    sortContext.updateTotalsTrace([
      {
        direction: "desc",
        id: "reads",
      },
    ]);
  };

  return (
    <div className={classes.root}>
      {target.features.performance && (
        <TopSqlChart
          className={classes.chart}
          currentFilter={chartFilter}
          onSeriesClick={handleChangeChartFilter}
          target={target}
          visibleDates={visibleDates}
        />
      )}
      <FormControlLabel
        checked={!isTotals}
        className={classes.totalSwitch}
        control={
          <Switch color="primary" data-testid="showTotalsSwitch" disableRipple onChange={handleChangeIsTotals} />
        }
        label={<FormattedMessage id="details" />}
      />
      {isTotals ? (
        <>
          <Slide direction="right" in={!totalsTraceEventCriteria}>
            <TotalsGrid
              className={classes.totals}
              data-testid="totalsGrid"
              onHandleSort={sortContext.updateTotals}
              sort={sortContext.sort.totals}
              target={target}
              topsqlFilterDropDownData={topsqlFilterDropDownData.topSqlFilterDataList}
            />
          </Slide>
          <Slide direction="left" in={!!totalsTraceEventCriteria} mountOnEnter unmountOnExit>
            <TraceEventsGrid
              backButtonToolTip={intl.formatMessage({ id: "backToTotals" })}
              className={classes.totals}
              data={totalsTraceEventsResults.data}
              data-testid="totalsTraceEventsGrid"
              error={totalsTraceEventsResults.error}
              eventSourceConnectionId={eventSourceConnectionId}
              isLoading={totalsTraceEventsResults.isLoading || totalsTraceEventsResults.pageLoading}
              isTotals={isTotals}
              isTraceStatement={!isTraceStatement}
              onBackClick={handleTotalsDefaultBackClick}
              onChangePage={handleTraceEventGridPageChange}
              onHandleSort={sortContext.updateTotalsTrace}
              onRowSelected={handleSelectedEventChange}
              pageIndex={traceEventGridPageInfo.pageIndex}
              pageSize={traceEventGridPageInfo.pageSize}
              rowsPerPage={statementGridPageInfo.pageSize}
              selectedRow={selectedTotal}
              sort={sortContext.sort.totalsTrace}
              topsqlFilterDropDownData={topsqlFilterDropDownData.topSqlFilterDataList}
              totalCount={totalsTraceEventsResults.totalCount}
            />
          </Slide>
          <Slide direction="right" in={!statementsTraceEventCriteria}>
            <StatementsGrid
              className={classes.statements}
              data-testid="statementsGrid"
              onHandleSort={sortContext.updateStatements}
            />
          </Slide>
          <Slide direction="left" in={!!statementsTraceEventCriteria} mountOnEnter unmountOnExit>
            <TraceEventsGrid
              backButtonToolTip={intl.formatMessage({ id: "backToStatements" })}
              className={classes.statements}
              data={statementsTraceEventsResults.data}
              data-testid="statementsTraceEventsGrid"
              error={statementsTraceEventsResults.error}
              eventSourceConnectionId={eventSourceConnectionId}
              isLoading={statementsTraceEventsResults.isLoading || statementsTraceEventsResults.pageLoading}
              isTotals={isTotals}
              isTraceStatement={!isTraceStatement}
              onBackClick={handleStatementDefaultBackClick}
              onChangePage={handleStatementGridPageChange}
              onHandleSort={sortContext.updateStatementsTrace}
              onRowSelected={handleSelectedEventStatementChange}
              pageIndex={statementGridPageInfo.pageIndex}
              pageSize={statementGridPageInfo.pageSize}
              rowsPerPage={defaults.statementsGridRowsPerPage}
              selectedRow={selectedStatementEvent}
              sort={sortContext.sort.statementsTrace}
              topsqlFilterDropDownData={topsqlFilterDropDownData.topSqlFilterDataList}
              totalCount={statementsTraceEventsResults.totalCount}
            />
          </Slide>
        </>
      ) : (
        <>
          <TraceEventsGrid
            className={classes.totals}
            data={totalsTraceEventsResults.data}
            data-testid="totalsTraceEventsGrid"
            error={totalsTraceEventsResults.error}
            eventSourceConnectionId={eventSourceConnectionId}
            isLoading={totalsTraceEventsResults.isLoading || totalsTraceEventsResults.pageLoading}
            isTotals={isTotals}
            isTraceStatement={!isTraceStatement}
            onChangePage={handleTraceEventGridPageChange}
            onHandleSort={sortContext.updateTotalsTrace}
            onRowSelected={handleSelectedEventChange}
            pageIndex={traceEventGridPageInfo.pageIndex}
            pageSize={traceEventGridPageInfo.pageSize}
            rowsPerPage={defaults.totalsGridRowsPerPage}
            selectedRow={selectedTotal}
            sort={sortContext.sort.totalsTrace}
            topsqlFilterDropDownData={topsqlFilterDropDownData.topSqlFilterDataList}
            totalCount={totalsTraceEventsResults.totalCount}
          />
          <TraceEventsGrid
            className={classes.statements}
            data={statementsTraceEventsResults.data}
            data-testid="statementsTraceEventsGrid"
            error={statementsTraceEventsResults.error}
            eventSourceConnectionId={eventSourceConnectionId}
            isLoading={statementsTraceEventsResults.isLoading || statementsTraceEventsResults.pageLoading}
            isTotals={isTotals}
            isTraceStatement={isTraceStatement}
            onChangePage={handleStatementGridPageChange}
            onHandleSort={sortContext.updateTotalsTrace}
            onRowSelected={handleSelectedEventStatementChange}
            pageIndex={statementGridPageInfo.pageIndex}
            pageSize={statementGridPageInfo.pageSize}
            rowsPerPage={defaults.statementsGridRowsPerPage}
            selectedRow={selectedStatementEvent}
            sort={sortContext.sort.statementsTrace}
            topsqlFilterDropDownData={topsqlFilterDropDownData.topSqlFilterDataList}
            totalCount={statementsTraceEventsResults.totalCount}
          />
        </>
      )}
      {/* Wrapper div is used to force statementDetails to match height of statements  */}
      <Slide direction="left" in mountOnEnter unmountOnExit>
        <div className={classes.statementDetails}>
          <StatementDetails className={classes.statementDetailsContent} />
        </div>
      </Slide>
      <QueryHistory
        className={classNames(classes.queryHistory, "telemetry-topSql-queryHistoryChart")}
        eventSourceConnectionId={eventSourceConnectionId}
      />
    </div>
  );
};

export default TopSqlContent;
