import { alpha, makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import classNames from "classnames";
import * as React from "react";
import { useDrop } from "react-dnd";
import { useIntl } from "react-intl";
import { ICustomChartConfig, WidgetDragItem, WidgetDragItemType } from "../../types";

export interface IDashboardGridProps {
  className?: string;
  onAddWidget: (widgetTemplate: ICustomChartConfig) => void;
  onUpdateWidget: (id: number) => void;
  show: boolean;
  style?: React.CSSProperties;
}

const useStyles = makeStyles((theme) => ({
  isOver: {},
  root: {
    "&$isOver": {
      backgroundColor: alpha(theme.palette.primary.main, 0.4),
    },
    alignItems: "center",
    backgroundColor: theme.palette.action.selected,
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    justifyContent: "center",
  },
}));

const DashboardNewRow: React.FC<IDashboardGridProps> = ({ className, onAddWidget, show, onUpdateWidget, style }) => {
  const intl = useIntl();
  const classes = useStyles();

  const [{ isOver }, drop] = useDrop({
    accept: [WidgetDragItemType.add, WidgetDragItemType.move],
    collect: (monitor) => {
      return { isOver: monitor.isOver() && monitor.canDrop() };
    },
    drop: (item: WidgetDragItem) => {
      switch (item.type) {
        case WidgetDragItemType.add: {
          onAddWidget(item.widgetTemplate);
          break;
        }
        case WidgetDragItemType.move: {
          onUpdateWidget(item.id);
          break;
        }
      }
    },
  });

  return show ? (
    <div
      className={classNames(classes.root, { [classes.isOver]: isOver }, className)}
      ref={drop}
      style={style}
      title={intl.formatMessage({ id: "dragToStart" })}
    >
      <AddCircleOutlineIcon color="disabled" fontSize="large" />
    </div>
  ) : (
    <></>
  );
};

export default DashboardNewRow;
