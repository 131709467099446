import { ITranslation } from "../../../../intl/types";
import { formatDigitalStorage, formatBitrate } from "../../../../utilities/Formatters";
import { StorageEntityType, IStorageEntity, ForecastDateFormatOptions, IAutogrowth } from "../../types";
import { FormatNumberOptions, useIntl } from "react-intl";
import { formatForecastExhaustionDate } from "../Utilities/StorageUtilities";

export class StorageEntityStatsMap extends Map<keyof ITranslation, string | number> {}

export interface IStorageEntityStats {
  emphasisStats: StorageEntityStatsMap | null;
  filledStats: StorageEntityStatsMap | null;
  standardStats: StorageEntityStatsMap | null;
}

const formatPercentOptions: FormatNumberOptions = {
  maximumFractionDigits: 2,
  style: "percent",
};

const emptyField: string = "-";

/**
 * A function to split and format data for a storage entity for display.
 * @param entity The storage entity object to get display stats for.
 * @returns An object containing the primary and secondary stats with labels ready for translation and values formatted as necessary.
 */
function useStorageEntityStats(entity: IStorageEntity): IStorageEntityStats {
  const intl = useIntl();
  const calculateAutogrowth = (autogrowth: IAutogrowth | null): string => {
    if (!autogrowth) return emptyField;
    return autogrowth.type === "PERCENT"
      ? `${intl.formatNumber(autogrowth.value, formatPercentOptions)} (${formatDigitalStorage(
          autogrowth.nextGrowthBytes,
        )})`
      : formatDigitalStorage(autogrowth.nextGrowthBytes);
  };

  switch (entity.type) {
    case StorageEntityType.DiskController:
      return {
        emphasisStats: new StorageEntityStatsMap([["manufacturer", entity.manufacturer]]),
        filledStats: new StorageEntityStatsMap([
          ["maxInboundRate", formatBitrate(entity.maxInboundRate)],
          ["maxOutboundRate", formatBitrate(entity.maxOutboundRate)],
        ]),
        standardStats: null,
      };
    case StorageEntityType.PhysicalDisk:
      return {
        emphasisStats: null,
        filledStats: new StorageEntityStatsMap([["size", formatDigitalStorage(entity.sizeBytes)]]),
        standardStats: new StorageEntityStatsMap([["status", entity.status]]),
      };
    case StorageEntityType.LogicalVolume:
      return {
        emphasisStats: null,
        filledStats: new StorageEntityStatsMap([
          ["size", formatDigitalStorage(entity.sizeBytes)],
          ["fileSystem", entity.fileSystem],
        ]),
        standardStats: new StorageEntityStatsMap([
          ["freeSpace", formatDigitalStorage(entity.freeSpaceBytes)],
          ["otherSpace", formatDigitalStorage(entity.otherSpaceBytes)],
          [
            "forecastedExhaustion",
            entity.forecastedExhaustionDate
              ? formatForecastExhaustionDate(entity.forecastedExhaustionDate, intl)
              : emptyField,
          ],
        ]),
      };
    case StorageEntityType.SqlServerDataFile:
    case StorageEntityType.SqlServerLogFile:
      return {
        emphasisStats: new StorageEntityStatsMap([
          ["database", entity.databaseName],
          ["filePath", entity.fileName],
        ]),
        filledStats: new StorageEntityStatsMap([
          ["size", formatDigitalStorage(entity.sizeBytes)],
          [
            "usedPercentage",
            entity.usedPercentage ? intl.formatNumber(entity.usedPercentage, formatPercentOptions) : emptyField,
          ],
          ["fileGroup", entity.filegroupName ?? emptyField],
        ]),
        standardStats: new StorageEntityStatsMap([
          ["activeVlfs", entity.activeVLFs ?? emptyField],
          ["autogrowth", calculateAutogrowth(entity.autogrowth)],
          ["autogrowthVlfCount", entity.autogrowth?.vlfCount ?? emptyField],
          [
            "autogrowthVlfSize",
            entity.autogrowth?.vlfBytes ? formatDigitalStorage(entity.autogrowth.vlfBytes) : emptyField,
          ],
          [
            "lastBackupTime",
            entity.lastBackupTime ? intl.formatDate(entity.lastBackupTime, ForecastDateFormatOptions) : emptyField,
          ],
          ["lastBackupType", entity.lastBackupType ?? emptyField],
          ["logReuseWait", entity.logReuseWait],
          [
            "maxFileSize",
            entity.maxFileSizeBytes && entity.maxFileSizeBytes > -1
              ? formatDigitalStorage(entity.maxFileSizeBytes)
              : emptyField,
          ],
          ["maxVlfSize", entity.maxVLFSizeBytes ? formatDigitalStorage(entity.maxVLFSizeBytes) : emptyField],
          ["minVlfSize", entity.minVLFSizeBytes ? formatDigitalStorage(entity.minVLFSizeBytes) : emptyField],
          ["totalVlfs", entity.totalVLFs ?? emptyField],
          ["usedSpace", entity.usedSizeBytes ? formatDigitalStorage(entity.usedSizeBytes) : emptyField],
        ]),
      };
  }
}

export default useStorageEntityStats;
