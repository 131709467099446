import { makeStyles } from "@material-ui/core/styles";
import BarChartIcon from "@material-ui/icons/BarChart";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  icon: {
    backgroundColor: "#333333",
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.getContrastText(theme.palette.error.dark),
    margin: theme.spacing(0, 0.5),
  },
  root: {
    alignItems: "center",
    background: `linear-gradient(45deg, ${theme.palette.primary.main}, #4be6ff)`,
    display: "flex",
    justifyContent: "center",
    minHeight: "7rem",
  },
}));

const DashboardThumbnail: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <BarChartIcon className={classes.icon} fontSize="large" />
      <BubbleChartIcon className={classes.icon} fontSize="large" />
      <ShowChartIcon className={classes.icon} fontSize="large" />
    </div>
  );
};

export default DashboardThumbnail;
