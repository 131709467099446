import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { DateTime, Duration } from "luxon";
import * as React from "react";
import { useIntl, FormattedDate, FormattedMessage } from "react-intl";
import { Nullable } from "../../utilities/UtilityTypes";
import { IDateRange } from "../DateContext";
import Tooltip from "@material-ui/core/Tooltip";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  buttons: {
    "&>*": {
      marginLeft: theme.spacing(2),
    },
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(3),
  },
  customRange: {
    display: "grid",
    gridGap: theme.spacing(3),
    gridTemplateColumns: "1fr 1fr",
    padding: theme.spacing(0, 3),
  },
  datePickers: {
    marginTop: theme.spacing(2),
  },
  futureDateError: {
    color: "#D91C24",
    display: "grid",
    justifyContent: "end",
    padding: theme.spacing(0, 3),
  },
  listItem: {
    fontSize: "1rem",
  },
  listItemText: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  sectionHeader: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "1.1rem",
  },
}));

interface IDateRangeTabProps {
  onCancel: () => void;
  onChange: (value: Nullable<IDateRange>) => void;
  onConfirm: (value: IDateRange) => void;
  value: Nullable<IDateRange>;
}

interface IDateSelectionValue {
  readonly duration: Duration;
  readonly label: string;
}

const selectionValues: readonly IDateSelectionValue[] = [
  { duration: Duration.fromObject({ hour: 1 }), label: "lastHour" },
  { duration: Duration.fromObject({ hour: 4 }), label: "last4Hours" },
  { duration: Duration.fromObject({ hour: 8 }), label: "last8Hours" },
  { duration: Duration.fromObject({ day: 1 }), label: "last24Hours" },
  { duration: Duration.fromObject({ week: 1 }), label: "lastWeek" },
  { duration: Duration.fromObject({ month: 1 }), label: "lastMonth" },
];

const DateRangeTab: React.FC<IDateRangeTabProps> = ({ onCancel, onChange, onConfirm, value: { from, to } }) => {
  const [futureDateDisabled, setFutureDateDisabled] = useState(false);

  const intl = useIntl();
  const classes = useStyles();

  function handlePreset(preset: IDateSelectionValue): void {
    onConfirm({
      from: DateTime.utc().minus(preset.duration).toJSDate(),
      to: new Date(),
    });
  }

  const handleDisableFutureTime = (value: Nullable<IDateRange>): void => {
    const from = value.from ? new Date(value.from) : new Date();
    const to = value.to ? new Date(value.to) : new Date();
    setFutureDateDisabled(false);

    if (from > new Date() || to > new Date()) {
      setFutureDateDisabled(true);
    } else {
      onChange(value);
    }
  };

  let disabledApplyTooltipMsg = "";
  if (from && to && from.getTime() >= to.getTime())
    disabledApplyTooltipMsg = intl.formatMessage({ id: "enterStartDatePrecedingEndDate" });

  return (
    <div className={classes.container}>
      <Typography className={classes.sectionHeader} component="label">
        <FormattedMessage id="jumpTo" />
      </Typography>
      {/* Specifically not using subheader prop here so that the two headers are consistent */}
      <List>
        {selectionValues.map((x) => (
          <ListItem button key={x.label} onClick={() => handlePreset(x)}>
            <ListItemText
              className={classes.listItemText}
              primary={intl.formatMessage({ id: x.label })}
              primaryTypographyProps={{ className: classes.listItem }}
              secondary={
                <>
                  <FormattedDate
                    day="numeric"
                    hour="numeric"
                    hour12
                    minute="numeric"
                    month="short"
                    value={DateTime.utc().minus(x.duration).toJSDate()}
                  />
                  <Typography color="inherit" component="span" variant="body2">
                    &nbsp;-&nbsp;
                  </Typography>
                  <FormattedDate
                    day="numeric"
                    hour="numeric"
                    hour12
                    minute="numeric"
                    month="short"
                    value={DateTime.utc().toJSDate()}
                  />
                </>
              }
              secondaryTypographyProps={{ display: "inline" }}
            />
          </ListItem>
        ))}
      </List>
      <div className={classes.datePickers}>
        <Typography className={classes.sectionHeader} component="label">
          Custom Range
        </Typography>
        <div className={classes.customRange}>
          <KeyboardDateTimePicker
            color="primary"
            disableFuture
            format="yyyy-MM-dd hh:mm a"
            label={intl.formatMessage({ id: "start" })}
            margin="normal"
            // The tailing M addresses a bug in the masking done by the keyboard input that doesn't allow M to be typed
            mask="____-__-__ __:__ _M"
            onChange={(x) => handleDisableFutureTime({ from: x?.toJSDate() ?? null, to })}
            shouldDisableDate={(x) => !!x && !!to && x.toJSDate().getTime() > to.getTime()}
            value={from}
            variant="inline"
          />
          <KeyboardDateTimePicker
            color="primary"
            disableFuture
            format="yyyy-MM-dd hh:mm a"
            label={intl.formatMessage({ id: "end" })}
            margin="normal"
            // The tailing M addresses a bug in the masking done by the keyboard input that doesn't allow M to be type
            mask="____-__-__ __:__ _M"
            onChange={(x) => handleDisableFutureTime({ from, to: x?.toJSDate() ?? null })}
            // x.toJSDate() compares the beginning of the selected date, so we compare the start of from's date as well
            shouldDisableDate={(x) =>
              !!x && !!from && x.toJSDate() < DateTime.fromJSDate(from).startOf("day").toJSDate()
            }
            value={to}
            variant="inline"
          />
        </div>
        {futureDateDisabled && (
          <div className={classes.futureDateError}>
            <span>{intl.formatMessage({ id: "futureDateNotAllowed" })}</span>
          </div>
        )}
      </div>
      <div className={classes.buttons}>
        <Button color="secondary" onClick={onCancel} variant="text">
          {intl.formatMessage({ id: "cancel" })}
        </Button>
        <Tooltip placement="bottom" title={disabledApplyTooltipMsg}>
          <span>
            <Button
              aria-label={intl.formatMessage({ id: "apply" })}
              color="primary"
              disabled={futureDateDisabled || !from || !to || from.getTime() > to.getTime()}
              onClick={() => {
                // from and to should always have a value if we reached this point to do disabled
                // onClick cannot be called because of this, so this will always show as a branch miss in coverage
                if (from && to) {
                  onConfirm({ from, to });
                }
              }}
              variant="contained"
            >
              {intl.formatMessage({ id: "apply" })}
            </Button>
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default DateRangeTab;
