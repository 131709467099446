import * as React from "react";

interface IThrowProps {
  /** The error to throw. Will only throw if there is a value. */
  error?: Error | null;
}

/**
 * Convenience component for throwing an error within an ErrorBoundary child.
 * Useful when a component that has an error to throw still needs to be partially rendered.
 * @param props
 */
const Throw: React.FunctionComponent<IThrowProps> = ({ error }) => {
  if (error) {
    throw error;
  }
  return null;
};

export default Throw;
