import * as React from "react";
import { useIntl } from "react-intl";
import FeatureLoadingSpinner from "../../components/FeatureLoadingSpinner";
import DocumentTitle from "../../components/DocumentTitle";
import NoDataIndicator from "../../components/NoDataIndicator";
import CustomDashboardContent from "./CustomDashboardContent";
import { useDashboard } from "./useDashboards";

interface ICustomDashboardProps {
  dashboardId: string;
  editMode: boolean;
}

const CustomDashboard: React.FC<ICustomDashboardProps> = ({ dashboardId, editMode }) => {
  const intl = useIntl();
  const { data = null, error, isLoading } = useDashboard(dashboardId);

  if (error) {
    throw error;
  } else if (isLoading) {
    return <FeatureLoadingSpinner />;
  } else if (!data) {
    return (
      <>
        <DocumentTitle title={`${intl.formatMessage({ id: "dashboards" })}`} useIntl={false} />
        <NoDataIndicator />
      </>
    );
  } else {
    return (
      <>
        <DocumentTitle title={`${intl.formatMessage({ id: "dashboards" })} - ${data.name}`} useIntl={false} />
        <CustomDashboardContent customDashboard={data} editMode={editMode} />
      </>
    );
  }
};

export default CustomDashboard;
