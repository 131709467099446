import MenuItem from "@material-ui/core/MenuItem";
import * as React from "react";
import { useIntl } from "react-intl";
import { useDateContext } from "../../components/DateContext";
import DocumentTitle from "../../components/DocumentTitle";
import { ChartRange } from "../../components/recharts";
import {
  ITopology,
  ITopologyItemDevice,
  ITopologyItemEventSourceConnection,
  TopologyDeviceType,
  TopologyEventSourceConnectionType,
  TopologyObjectType,
  useTopology,
} from "../../components/TopologyContext";
import { useCurrentTarget } from "../../hooks/useCurrentTarget";
import * as Layouts from "./components/Layouts";
import { PerformanceAnalysisProvider } from "./context";

function getLayout(
  target: ITopologyItemDevice | ITopologyItemEventSourceConnection,
  topology: ITopology,
): React.ComponentType {
  switch (target.type) {
    case TopologyObjectType.device: {
      switch (target.deviceType) {
        case TopologyDeviceType.VMWare:
          return Layouts.VMWarePerformanceAnalysis;
        default: {
          return Layouts.Windows;
        }
      }
    }
    case TopologyObjectType.eventSourceConnection: {
      switch (target.eventSourceConnectionType) {
        case TopologyEventSourceConnectionType.AzureSqlDatabase:
          return Layouts.AzureSqlDb;
        default: {
          const device = topology.findByObjectId(target.parentObjectId);
          return device?.description ? Layouts.WindowsSql : Layouts.SqlServer;
        }
      }
    }
  }
}

const PerformanceAnalysis: React.FC = () => {
  const intl = useIntl();
  const topology = useTopology();
  const { dateRange, setDateRange } = useDateContext();
  const target = useCurrentTarget();
  const [selectedRange, setSelectedRange] = React.useState<ChartRange>([null, null]);
  if (!target) {
    throw new Error("PerformanceAnalysis can only be used with a target");
  } else if (target.type !== TopologyObjectType.device && target.type !== TopologyObjectType.eventSourceConnection) {
    throw new Error(`PerformanceAnalysis does not support target type ${target.type}`);
  } else {
    const Layout = getLayout(target, topology);
    return (
      <PerformanceAnalysisProvider
        value={{
          contextMenuItems: [
            <MenuItem
              key="zoom"
              onClick={() => {
                if (selectedRange[0] && selectedRange[1]) {
                  setDateRange({ from: selectedRange[0], to: selectedRange[1] });
                  setSelectedRange([null, null]);
                }
              }}
            >
              {intl.formatMessage({ id: "zoom" })}
            </MenuItem>,
          ],
          dateRange,
          selectedRange,
          setSelectedRange,
          target,
        }}
      >
        <DocumentTitle title="performance" topologyItem={target} />
        <Layout />
      </PerformanceAnalysisProvider>
    );
  }
};

export default PerformanceAnalysis;
