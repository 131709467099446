import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import RefreshIcon from "@material-ui/icons/Refresh";
import * as React from "react";
import { FormattedHTMLMessage, useIntl } from "react-intl";
import { Redirect, useLocation } from "react-router-dom";
import { useUID } from "react-uid";
import AccountService from "../../api/AccountService/AccountService";
import FullScreenLoad from "../../components/FullScreenLoad";
import { useAsync } from "../../hooks/Async";
import { IUserContext } from "./types";
import userContext from "./userContext";

async function fetchUserInfo(_: never, controller: AbortController): Promise<IUserContext> {
  const userInfo = await new AccountService().fetchUserInfo(controller.signal);
  return {
    tenants: userInfo.tenants,
    userInfo,
  };
}

const UserContextProvider: React.FunctionComponent = ({ children }) => {
  const location = useLocation();
  const intl = useIntl();
  const { data, error, isLoading } = useAsync(fetchUserInfo);
  const dialogTitleId = useUID();

  if (error) {
    // Throwing the error allows our error handling component to display the proper message
    // Without the user context the app shouldn't proceed
    throw error;
  }

  if (isLoading) {
    return <FullScreenLoad message="connectingToDatabases" />;
  } else if (!data) {
    throw new Error("User info has finished loading, but returned a null value");
  } else if (data.userInfo?.userSignInUrl) {
    window.location.href = data.userInfo.userSignInUrl;
    return <></>;
  } else if (data.tenants?.length === 0) {
    // User does not have any tenants accessible
    // This is likely because they have a new account that has not provisioned yet
    return (
      <Dialog aria-labelledby={dialogTitleId} open>
        <DialogTitle id={dialogTitleId}>{intl.formatMessage({ id: "noDatabasesAvailable" })}</DialogTitle>
        <DialogContent>
          <DialogContentText>{intl.formatMessage({ id: "youDoNotHaveAccessToAnyDatabases" })}</DialogContentText>
        </DialogContent>
        {data.userInfo.email && (
          <DialogActions>
            <Button color="secondary" href="/account/signout" variant="contained">
              {intl.formatMessage({ id: "signOut" })}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  } else {
    const tenant = data.tenants.find((x) => location.pathname.toLowerCase().startsWith(`/${x.id.toLowerCase()}`));

    if (!tenant) {
      // No tenant selected. Redirect to first active tenant or any tenant if none are active
      const firstTenant = data.tenants.find((x) => x.state === "ready") ?? data.tenants[0];
      return <Redirect to={`/${firstTenant.id}`} />;
    } else {
      switch (tenant.state) {
        case "inactive":
        case "initializing":
          return (
            <Dialog open>
              <DialogContent>
                <DialogContentText>
                  <FormattedHTMLMessage id="accountBeingConfigured" />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  onClick={() => window.location.reload()}
                  size="small"
                  startIcon={<RefreshIcon />}
                  variant="contained"
                >
                  {intl.formatMessage({ id: "retry" })}
                </Button>
              </DialogActions>
            </Dialog>
          );
        default:
          return <userContext.Provider value={data}>{children}</userContext.Provider>;
      }
    }
  }
};

export default UserContextProvider;
