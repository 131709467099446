import * as React from "react";
import { useIntl as useIntlHook } from "react-intl";
import { useCurrentTenant } from "../../hooks/useCurrentTenant";
import { ITopologyItem } from "../../components/TopologyContext";

interface IDocumentTitleProps {
  title: string;
  topologyItem?: ITopologyItem;
  useIntl?: boolean;
}

/**
 * This function updates the document's title to properly reflect the current page in both the tab of the browser
 * and the history of the browser.
 * Accepts: Intl object, a string for the page title to be passed into Intl, and a topology item (can be null)
 */
const DocumentTitle: React.FC<IDocumentTitleProps> = ({ title, topologyItem, useIntl = true }) => {
  const intl = useIntlHook();
  const currentTenantName = useCurrentTenant()?.tenant.name;
  const translatedTitle = useIntl ? intl.formatMessage({ id: title }) : title;
  const productTitle = intl.formatMessage({ id: "solarwindsSqlSentry" });

  document.title = topologyItem
    ? `${productTitle} : ${currentTenantName} - ${topologyItem.name} : ${translatedTitle}`
    : `${productTitle} : ${currentTenantName} : ${translatedTitle}`;
  return null;
};

export default DocumentTitle;
