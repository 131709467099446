import { SortDescriptor } from "@sentryone/react-datatable";

export interface ISortableFeatureGrids {
  aggregatedEventLogs: readonly SortDescriptor[];
  eventLogs: readonly SortDescriptor[];
  parameters: readonly SortDescriptor[];
  statements: readonly SortDescriptor[];
  statementsTrace: readonly SortDescriptor[];
  totals: readonly SortDescriptor[];
  totalsTrace: readonly SortDescriptor[];
}

export interface ISortContext {
  sort: ISortableFeatureGrids;
  updateAggregatedEventLogs: (sort: readonly SortDescriptor[]) => void;
  updateEventLogs: (sort: readonly SortDescriptor[]) => void;
  updateParameters: (sort: readonly SortDescriptor[]) => void;
  updateStatements: (sort: readonly SortDescriptor[]) => void;
  updateStatementsTrace: (sort: readonly SortDescriptor[]) => void;
  updateTotals: (sort: readonly SortDescriptor[]) => void;
  updateTotalsTrace: (sort: readonly SortDescriptor[]) => void;
}

export enum AggregatedEventLogsSortType {
  "name",
  "type",
  "condition",
  "severity",
  "tags",
  "lastOccurrence",
  "count",
}

export enum EventLogsSortType {
  "startTime",
  "endTime",
  "duration",
  "closed",
  "status",
}

export enum ParametersSortType {
  "parameterName",
  "compiledValue",
}

export enum StatementsSortType {
  "textData",
  "duration",
  "execCount",
  "cpu",
  "readsL",
  "writesL",
  "eventCount",
}

export enum TotalsSortType {
  "textData",
  "databaseName",
  "duration",
  "execCount",
  "cpu",
  "readsL",
  "writesL",
  "waitMS",
  "eventCount",
  "grantedMemoryKB",
  "grantedQueryMemoryKB",
  "requestedMemoryKB",
  "sessionMemoryKB",
  "tempdbInternalKB",
  "tempdbInternalKBDealloc",
  "tempdbUserKB",
  "tempdbUserKBDealloc",
}

export enum TraceEventsSortType {
  "eventClass",
  "textData",
  "hostName",
  "applicationName",
  "databaseName",
  "login",
  "duration",
  "cpu",
  "reads",
  "writes",
  "startTime",
  "endTime",
  "integerData",
  "error",
  "spid",
  "hostProcessId",
  "grantedMemoryKB",
  "grantedQueryMemoryKB",
  "requestedMemoryKB",
  "sessionMemoryKB",
  "tempdbInternalKB",
  "tempdbInternalKBDealloc",
  "tempdbUserKB",
  "tempdbUserKBDealloc",
  "waitMS"
}
