import * as React from "react";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../../components/Logo";
import { useIntl } from "react-intl";
import { ITranslation } from "../../intl/types";

interface IFullScreenLoadProps {
  disableFadeAnimation?: boolean;
  message: keyof ITranslation;
}

const useStyles = makeStyles((theme) => ({
  loading: {
    "&>svg": {
      width: "35rem",
    },
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
  },
  loadingText: {
    marginLeft: theme.spacing(2),
  },
  loadingTextContainer: {
    alignItems: "center",
    display: "flex",
  },
}));

const FullScreenLoad: React.FC<IFullScreenLoadProps> = ({ disableFadeAnimation = false, message }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.loading}>
      <Fade appear={!disableFadeAnimation} in>
        <Logo />
      </Fade>
      <div className={classes.loadingTextContainer}>
        <CircularProgress size="1.7rem" thickness={5} />
        <Typography className={classes.loadingText} component="h1" variant="h4">
          {intl.formatMessage({ id: message })}
        </Typography>
      </div>
    </div>
  );
};

export default FullScreenLoad;
