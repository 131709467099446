import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

const NoDataIcon: React.FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 80 80" {...props}>
      <path
        d="M6.72 71.8529L3.08979 28.0827C3.04145 27.4998 3.50149 27 4.08637 27H12.96H20.4092C20.7736 27 21.1092 27.1983 21.2851 27.5175L24.2149 32.8355C24.3908 33.1547 24.7264 33.3529 25.0908 33.3529H65.66C66.2123 33.3529 66.66 33.8007 66.66 34.3529V41.6765M6.72 71.8529L16.2167 42.3699C16.3498 41.9566 16.7343 41.6765 17.1685 41.6765H66.66M6.72 71.8529H65.9343C66.3671 71.8529 66.7508 71.5745 66.885 71.163L76.0728 42.9865C76.2836 42.3402 75.8019 41.6765 75.1221 41.6765H66.66"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M63.0026 9C55.8244 9 50.0006 13.1568 50.0006 18.287C50.0006 20.4882 51.0706 22.5063 52.8583 24.1003C52.4656 25.1406 51.8606 25.5603 51.1834 26.0247C50.6823 26.3684 49.7298 26.9042 50.0729 27.9535C50.3031 28.6411 51.0164 29.0563 51.7929 28.9938C54.3978 28.7884 56.8131 28.1365 58.8808 27.0962C60.1765 27.4043 61.5624 27.574 62.9981 27.574C70.1807 27.574 76 23.4216 76 18.287C76.0045 13.1568 70.1852 9 63.0026 9ZM63.0026 25.4308C61.5353 25.4308 60.05 25.2344 58.6099 24.8057C57.1517 25.6541 55.0569 26.8596 52.5288 27.2168C53.9734 26.1452 55.0931 24.4664 55.2917 23.2698C53.5717 22.1089 52.1676 20.3721 52.1676 18.287C52.1676 15.1214 56.0637 11.1432 63.0026 11.1432C69.9415 11.1432 73.8375 15.1214 73.8375 18.287C73.8375 21.4571 69.9415 25.4308 63.0026 25.4308Z"
        fill="currentColor"
      />
      <rect
        fill="currentColor"
        height="7.64738"
        rx="0.945182"
        transform="matrix(0.710836 -0.703358 0.710836 0.703358 59.6743 16.4835)"
        width="1.89036"
      />
      <rect
        fill="currentColor"
        height="7.64738"
        rx="0.945182"
        transform="matrix(-0.710836 -0.703358 0.710836 -0.703358 61.0181 21.8623)"
        width="1.89036"
      />
      <ellipse cx="31" cy="53.0458" fill="currentColor" rx="1.5" ry="2.5" />
      <ellipse cx="50" cy="53.0458" fill="currentColor" rx="1.5" ry="2.5" />
      <path
        d="M26 49C29.2712 49.4194 30.8307 49.3407 33 48M31.5 65C36 62 44 62 49.5 65M55 49C51.7288 49.4194 50.1693 49.3407 48 48"
        stroke="currentColor"
        strokeWidth="2"
      />
    </SvgIcon>
  );
};

export default NoDataIcon;
