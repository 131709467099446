import { ClassNameMap } from "@material-ui/core/styles/withStyles";

export const getClass = (classes: ClassNameMap<"small" | "medium" | "large">, theSize: string = "small"): string => {
  switch (theSize) {
    case "small":
      return classes.small;
    case "medium":
      return classes.medium;
    case "large":
      return classes.large;
    default:
      return classes.small;
  }
};
