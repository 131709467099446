import { useQuery } from "@apollo/react-hooks";
import * as React from "react";
import * as GET_DEADLOCKS from "./deadlocksQuery.graphql";
import * as GET_DEADLOCK_BY_ID from "./deadlockXmlQuery.graphql";
import {
  IDeadlocksQuery,
  IDeadlocksVariables,
  IDeadlockByIdQuery,
  IDeadlock,
  IDeadlockResource,
  IDeadlockProcess,
} from "./types";
import { useDateContext } from "../../components/DateContext";
import DeadlocksContext from "./deadlocksContext";
import { parseDeadlockXml } from "./utilities/DeadlockParser";

const MAX_RESULTS = 10000;

export interface IDeadlocksContextProps {
  eventSourceConnectionId: number;
}

const DeadlocksContextProvider: React.FunctionComponent<IDeadlocksContextProps> = ({
  children,
  eventSourceConnectionId,
}) => {
  const { dateRange } = useDateContext();
  const [selectedNodeId, setSelectedNodeId] = React.useState<string | undefined>();
  const [selectedDeadlockId, setSelectedDeadlockId] = React.useState<number | undefined>();
  const [uploadedDeadlock, setUploadedDeadlock] = React.useState<IDeadlock | undefined>();

  const { loading, error, data } = useQuery<IDeadlocksQuery, IDeadlocksVariables>(GET_DEADLOCKS, {
    context: {
      fetchOptions: {
        signal: "",
      },
    },
    fetchPolicy: "no-cache",
    variables: {
      dr: {
        endDate: dateRange.to.toISOString(),
        eventSourceConnectionId,
        limit: MAX_RESULTS,
        offset: 0,
        startDate: dateRange.from.toISOString(),
      },
    },
  });

  React.useEffect(() => {
    setUploadedDeadlock(undefined);
    setSelectedDeadlockId(undefined);
    setSelectedNodeId(undefined);
  }, [eventSourceConnectionId, dateRange]);

  const onUploadDeadlock = (newUploadedDeadlock: IDeadlock | undefined): void => {
    const victimProcessId = newUploadedDeadlock?.victimIds[0];
    setUploadedDeadlock(newUploadedDeadlock);
    setSelectedDeadlockId(undefined);
    setSelectedNodeId(victimProcessId);
  };

  const onChangeDeadlock = (newSelectedNodeId: string, newSelectedDeadlockId: number): void => {
    setSelectedNodeId(newSelectedNodeId);
    setSelectedDeadlockId(newSelectedDeadlockId);
    setUploadedDeadlock(undefined);
  };
  const { loading: loadingDeadlock, data: requestedDeadlock } = useQuery<IDeadlockByIdQuery>(GET_DEADLOCK_BY_ID, {
    context: {
      fetchOptions: {
        signal: "",
      },
    },
    skip: selectedDeadlockId === undefined || !!uploadedDeadlock,
    variables: {
      did: selectedDeadlockId,
      eid: eventSourceConnectionId,
    },
  });

  const selectedDeadlock = React.useMemo(() => {
    if (uploadedDeadlock) {
      return uploadedDeadlock;
    }
    return requestedDeadlock?.deadlocks.getById.deadlockXml
      ? parseDeadlockXml(requestedDeadlock.deadlocks.getById.deadlockXml)
      : undefined;
  }, [requestedDeadlock, uploadedDeadlock]);

  const selectedNode = React.useMemo(() => {
    if (!selectedDeadlock) {
      return undefined;
    }
    let node: IDeadlockProcess | IDeadlockResource | undefined = selectedDeadlock.processes.find(
      (x) => x.id == selectedNodeId,
    );
    if (!node) {
      node = selectedDeadlock.resources.find((x) => x.id == selectedNodeId);
    }
    return node;
  }, [selectedDeadlock, selectedNodeId]);

  return (
    <DeadlocksContext.Provider
      value={{
        deadlockInfo: data?.deadlocks.list.items,
        error,
        loading,
        loadingDeadlock,
        onChangeDeadlock,
        onUploadDeadlock,
        selectedDeadlock,
        selectedDeadlockId,
        selectedNode,
        selectedNodeId,
        setSelectedNodeId,
      }}
    >
      {children}
    </DeadlocksContext.Provider>
  );
};

export default DeadlocksContextProvider;
