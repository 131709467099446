import { ChartDisplayType } from "../../components/MetricChart";
import { ITopologyItemDevice, ITopologyItemEventSourceConnection } from "../../components/TopologyContext";
import { MoRef } from "../../utilities/TopologyUtility";
import { CustomTimeRangeUnit } from "./useDashboards";

export const enum WidgetDragItemType {
  add = "addWidget",
  move = "widget",
}

export type WidgetDragItem =
  | { widgetTemplate: ICustomChartConfig; type: WidgetDragItemType.add }
  | { id: number; type: WidgetDragItemType.move };

export interface ICustomDashboardChartSeries {
  axisLabel?: string | null;
  chartType: ChartDisplayType;
  color: string;
  instance: string | null;
  instanceList?: string[] | null;
  label: string | null;
  metric: string;
  moRef: MoRef;
  strokeColor?: string;
}

export interface ICustomTimeRange {
  unit: CustomTimeRangeUnit;
  value: number;
}

export interface ICustomDashboardChartOptions {
  customTimeRange: ICustomTimeRange | null;
  series: ICustomDashboardChartSeries[];
}

export interface ICustomDashboardWidgetPosition {
  row: number;
  column: number;
  width: number;
  height: number;
}

export interface ICustomChartConfig {
  chartName: string;
  performanceCounters: ICustomDashboardChartSeries[];
  customTimeRange: ICustomTimeRange | null;
  target?: ITopologyItemDevice | ITopologyItemEventSourceConnection;
}

export interface ICustomDashboardWidget {
  position: ICustomDashboardWidgetPosition;
  type: "chart";
  title: string;
  options: ICustomDashboardChartOptions;
}
