import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as prismjs from "prismjs";
import "prismjs/components/prism-sql";
import * as React from "react";
import "./QueryText-Prism.css";

interface IQueryTextProps {
  className?: string;
  textData?: string;
}

const useStyles = makeStyles((theme) => ({
  code: {
    fontSize: theme.typography.queryText.fontSize,
    fontWeight: theme.typography.queryText.fontWeight,
    letterSpacing: theme.typography.queryText.letterSpacing,
  },
  pre: {
    margin: 0,
  },
  root: {
    padding: theme.spacing(),
  },
}));

const QueryText: React.FunctionComponent<IQueryTextProps> = ({
  className,
  textData,
  ...props
}) => {
  const classes = useStyles({});
  const codeRef = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    if (codeRef.current) {
      prismjs.highlightElement(codeRef.current);
    }
  });

  return (
    <div className={classNames(classes.root, className)} {...props}>
      <pre className={classes.pre}>
        <code className={classNames("language-sql", classes.code)} ref={codeRef}>
          {textData}
        </code>
      </pre>
    </div>
  );
};

export default QueryText;
