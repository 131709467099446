import * as React from "react";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import { ITranslation } from "../../../../../intl/types";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";

export interface ICustomChartModalTooltipProps extends Omit<TooltipProps, "title"> {
  title: keyof ITranslation | undefined;
  children: React.ReactElement;
}

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: "#333",
  },
  tooltip: {
    backgroundColor: "#333",
    padding: theme.spacing(2),
  },
}));

const CustomChartModalTooltip: React.FC<ICustomChartModalTooltipProps> = ({ title, children, ...props }) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Tooltip
      TransitionComponent={Zoom}
      TransitionProps={{ timeout: 100 }}
      arrow
      classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
      title={title ? intl.formatMessage({ id: title }) : ""}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default CustomChartModalTooltip;
