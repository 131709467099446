import { ALL_INSTANCES, IMetricChartSeriesOptions } from "../../../../components/MetricChart";
import { ITranslation } from "../../../../intl/types";
import { PerformanceAnalysisAggregateType, TopSqlChartType } from "./types";

type ByChartTypes =
  | TopSqlChartType.ByApp
  | TopSqlChartType.ByDatabase
  | TopSqlChartType.ByHost
  | TopSqlChartType.ByLogin;

function getPerformanceOptions(aggregateBy: PerformanceAnalysisAggregateType): [keyof ITranslation, string] {
  switch (aggregateBy) {
    case PerformanceAnalysisAggregateType.cpu:
      return ["cpuMs", "cpu"];
    case PerformanceAnalysisAggregateType.duration:
      return ["durationMs", "duration"];
    case PerformanceAnalysisAggregateType.execCount:
      return ["execCount", "executions"];
    case PerformanceAnalysisAggregateType.reads:
      return ["readsL", "logicalReads"];
    case PerformanceAnalysisAggregateType.writes:
      return ["writesP", "physicalWrites"];
    case PerformanceAnalysisAggregateType.waits:
      return ["waits", "waits"];
  }
}

function getPerformanceCategory(chartType: ByChartTypes): string {
  switch (chartType) {
    case TopSqlChartType.ByApp:
      return "applications";
    case TopSqlChartType.ByDatabase:
      return "databases";
    case TopSqlChartType.ByHost:
      return "hosts";
    case TopSqlChartType.ByLogin:
      return "logins";
  }
}

export function makeByOptions(
  chartType: ByChartTypes,
  aggregateBy: PerformanceAnalysisAggregateType,
  colors: Generator<string, never, void>,
): IMetricChartSeriesOptions {
  const category = getPerformanceCategory(chartType);
  const [axisLabel, metric] = getPerformanceOptions(aggregateBy);
  return {
    axisLabel,
    chartType: "stackedBar",
    color: () => colors.next().value,
    instance: ALL_INSTANCES,
    label: (s) => s ?? "",
    metric: `sqlserver.topsql.${category}.${metric}`,
  };
}
