import * as React from "react";
import type { IPrincipalSelection, IPrincipal } from "../../types";
import SearchTextField from "../../../../components/SearchTextField";
import { makeStyles } from "@material-ui/core/styles";
import { UserGroupDetailsView, UserDetailsView } from "./views";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { useIntl } from "react-intl";
import Collapse from "@material-ui/core/Collapse";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Divider from "@material-ui/core/Divider";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import { useFeatureFlag } from "../../../../contexts/featuresContext";

export interface IPrincipalDetailsProps {
  handleEdit: (principal: IPrincipal) => void;
  handleDelete: (principal: IPrincipal) => void;
  handleSelection: (newSelection: IPrincipalSelection) => void;
  selectedPrincipal: IPrincipal;
}

const useStyles = makeStyles((theme) => ({
  "@keyframes flipArrow": {
    from: {
      transform: "rotate(180deg)",
    },
    to: {
      transform: "rotate(0deg)",
    },
  },
  cardHeaderAction: {
    "& svg": {
      animation: "$flipArrow 200ms",
      // used to reduce shift when removing/adding filter text field
      margin: "7px 0px",
    },
    flex: "none",
    marginLeft: "auto",
    maxWidth: "50%",
  },
  cardHeaderContent: {
    flex: "none",
    maxWidth: "50%",
  },
  groupHeader: {
    "& span:last-child": {
      fontWeight: theme.typography.fontWeightBold,
      overflowX: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    display: "flex",
    gap: `${theme.spacing(1)}px`,
  },
  header: {
    cursor: "pointer",
  },
  headerActions: {
    alignItems: "center",
    display: "flex",
    gap: `${theme.spacing(1)}px`,
  },
}));

interface IPrincipalDetailsState {
  collapse: boolean;
  showDetails: boolean;
  selectedPrincipal: IPrincipal;
  usersFilter: string;
}

type IPrincipalDetailsAction =
  | { type: "userCollapseExpand" }
  | { type: "switchViews"; selectedPrincipal: IPrincipal }
  | { type: "selectionChange"; selectedPrincipal: IPrincipal }
  | { type: "updateUserFilter"; usersFilter: string };

function reducer(state: IPrincipalDetailsState, action: IPrincipalDetailsAction): IPrincipalDetailsState {
  switch (action.type) {
    case "switchViews":
      return {
        ...state,
        collapse: state.showDetails ? false : state.collapse,
        selectedPrincipal: action.selectedPrincipal,
      };
    case "selectionChange":
      if ("name" in action.selectedPrincipal === "name" in state.selectedPrincipal || !state.showDetails)
        // switch views without animation
        return {
          ...state,
          collapse: state.showDetails ? false : state.collapse,
          selectedPrincipal: action.selectedPrincipal,
        };
      // start view switch animation
      else return { ...state, collapse: true, usersFilter: "" };
    case "userCollapseExpand":
      return { ...state, collapse: !state.collapse, showDetails: !state.showDetails };
    case "updateUserFilter":
      return { ...state, usersFilter: action.usersFilter };
  }
}

const PrincipalDetails: React.FC<IPrincipalDetailsProps> = ({
  selectedPrincipal,
  handleSelection,
  handleDelete,
  handleEdit,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const hasUserAndGroupCreationFeatureFlag = useFeatureFlag("userAndGroupCreation");
  const [state, dispatch] = React.useReducer<React.Reducer<IPrincipalDetailsState, IPrincipalDetailsAction>>(reducer, {
    collapse: false,
    selectedPrincipal,
    showDetails: true,
    usersFilter: "",
  });

  React.useEffect(() => {
    dispatch({ selectedPrincipal, type: "selectionChange" });
  }, [selectedPrincipal]);

  return (
    <Card data-testid="userDetails">
      <CardHeader
        action={
          <div className={classes.headerActions}>
            {"name" in state.selectedPrincipal && (
              <SearchTextField
                aria-label={intl.formatMessage({ id: "filterUsers" })}
                disabled={!state.showDetails}
                hiddenLabel
                onChange={(e) => dispatch({ type: "updateUserFilter", usersFilter: e.target.value })}
                onClick={(e) => e.stopPropagation()}
                placeholder={intl.formatMessage({ id: "filterMsg" })}
                value={state.usersFilter}
              />
            )}
            {hasUserAndGroupCreationFeatureFlag && (
              <>
                <Button
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEdit(selectedPrincipal);
                  }}
                  startIcon={<EditIcon />}
                >
                  {intl.formatMessage({ id: "edit" })}
                </Button>
                <Button
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(selectedPrincipal);
                  }}
                  startIcon={<DeleteIcon />}
                >
                  {intl.formatMessage({ id: "delete" })}
                </Button>
              </>
            )}
            {state.showDetails ? <ArrowDownIcon /> : <ArrowUpIcon />}
          </div>
        }
        className={classes.header}
        classes={{
          action: classes.cardHeaderAction,
          content: classes.cardHeaderContent,
        }}
        onClick={() => dispatch({ type: "userCollapseExpand" })}
        title={
          "name" in state.selectedPrincipal ? (
            <div className={classes.groupHeader}>
              <span>{intl.formatMessage({ id: "users" })}</span>
              <Divider flexItem orientation="vertical" />
              <span title={state.selectedPrincipal.name}>{state.selectedPrincipal.name}</span>
            </div>
          ) : (
            intl.formatMessage({ id: "userDetails" })
          )
        }
      ></CardHeader>
      <Collapse
        data-testid={`CollapseContainer-${state.showDetails && !state.collapse}`}
        in={state.showDetails && !state.collapse}
        onExited={() => dispatch({ selectedPrincipal, type: "switchViews" })}
      >
        {"name" in state.selectedPrincipal ? (
          <UserGroupDetailsView
            handleSelection={handleSelection}
            selectedGroup={state.selectedPrincipal}
            usersFilter={state.usersFilter}
          />
        ) : (
          <UserDetailsView handleSelection={handleSelection} selectedUser={state.selectedPrincipal} />
        )}
      </Collapse>
    </Card>
  );
};

export default PrincipalDetails;
