import { TextField } from "@material-ui/core";
import * as React from "react";
import { useCurrentTarget } from "../../hooks/useCurrentTarget";
import { ITopologyItemDevice, ITopologyItemEventSourceConnection, TopologyDeviceType, TopologyObjectType, useTopology } from "../../components/TopologyContext";
import TargetIcon from "../../components/icons/TargetIcon";
import { formatBits } from "../../utilities/Formatters";

const TargetInfoCard: React.FC = () => {
    const target = useCurrentTarget();
    const topology = useTopology();

    if (!target) throw new Error();

    let host_target;
    let instance_target;

    if(target.type == TopologyObjectType.eventSourceConnection) {
        instance_target = target as ITopologyItemEventSourceConnection;
        host_target = topology.findByObjectId(target.parentObjectId) as ITopologyItemDevice;
    }
    
    if( !host_target || target.type == TopologyObjectType.device) {
        host_target = target as ITopologyItemDevice;
    }

    if (host_target.deviceType != TopologyDeviceType.Windows && host_target.deviceType != TopologyDeviceType.VMWare) {
        return (<></>);
    }

    return (
        <div>
            <div>Target Information</div>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', padding: '5px', }}>
                <div style={{ flexGrow: 1 }}>
                    <TextField
                        fullWidth
                        inputProps={
                            { readOnly: true, }
                        }
                        label="Host Name"
                        size="small"
                        value={host_target.name}
                    />
                </div>
                <div style={{ flexGrow: 1 }}>
                    <TextField
                        InputProps={{
                            readOnly: true,
                            startAdornment: <><TargetIcon activeTargetType={host_target.deviceType} fontSize="small" sizeIcon="large" /><span style={{marginRight: '5px'}}></span></>,
                        }}
                        fullWidth
                        label="Host OS"
                        size="small"
                        value={host_target.description}
                    />
                </div>
                <div style={{ flexGrow: 1 }}>
                    <TextField
                        fullWidth
                        inputProps={
                            { readOnly: true, }
                        }
                        label="Host Last Boot Up"
                        size="small"
                        value={new Date(host_target.lastBootUpTime).toUTCString() == new Date("0001-01-01T00:00:00").toUTCString() ? ' ' : new Date(host_target.lastBootUpTime).toUTCString()}
                    />
                </div>
                <div>
                    <TextField
                        inputProps={
                            {
                                readOnly: true,
                                style: { width: '110px'}
                            }
                        }
                        label="Host Core Count"
                        size="small"
                        value={host_target.coreCount}
                    />
                </div>
                <div style={{ flexShrink: 1 }}>
                    <TextField
                        inputProps={
                            {
                                readOnly: true,
                                style: { width: '100px'}
                            }
                        }
                        label="Host Memory"
                        size="small"
                        value={formatBits((host_target.totalMemory ?? 0) * 1000)}
                    />
                </div>
                {instance_target && (
                    <div style={{ flexGrow: 1 }}>
                        <TextField
                            InputProps={{
                                readOnly: true,
                                startAdornment: <><TargetIcon activeTargetType={instance_target.eventSourceConnectionType} fontSize="small" sizeIcon="large" /><span style={{marginRight: '5px'}}></span></>,
                            }}
                            fullWidth
                            label="Instance Name"
                            size="small"
                            value={instance_target.description}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default TargetInfoCard;