import { useContext } from "react";
import { IUserContext } from "./types";
import userContext from "./userContext";

export function useUserContext(): IUserContext {
  const value = useContext(userContext);
  if (!value) {
    throw new Error("useUserContext can only be used in a child of UserContextProvider");
  }
  return value;
}
