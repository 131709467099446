import * as React from "react";
import { IDateRange } from "../../../../components/DateContext/types";
import { ITopologyItemDevice, ITopologyItemEventSourceConnection } from "../../../../components/TopologyContext";
import ErrorBoundary from "../../../../components/ErrorBoundary";
import { TopSqlChartType } from "../../../TopSql/components/TopSqlChart/types";
import { paletteOptions } from "../../../../components/ThemeProvider/palette";
import { LoadingIndicator } from "@sentryone/material-ui";
import { BarStack } from "@visx/shape";
import { SeriesPoint } from "@visx/shape/lib/types";
import { Group } from "@visx/group";
import { Grid } from "@visx/grid";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { DateTime } from "luxon";
import { useWaitsByTraceChartData } from "./useWaitsByTraceChartData";
import { MoRef } from "../../../../utilities/TopologyUtility";
import { WaitsByTraceTransposedData, WaitType } from "./types";
import { formatDate, transposeWaitsData } from "./utils";

const colors: string[] = paletteOptions.genericChartColors;

type TooltipData = {
  bar: SeriesPoint<WaitsByTraceTransposedData>;
  key: WaitType;
  index: number;
  height: number;
  width: number;
  x: number;
  y: number;
  color: string;
};

type IWaitsByTraceChartProps = {
  event: any | null;
  height: number;
  target: ITopologyItemEventSourceConnection | ITopologyItemDevice;
  visibleDates: Readonly<IDateRange>;
  width: number;
};

const WaitsByTraceChart = ({
  event,
  height,
  target,
  visibleDates,
  width,
}: IWaitsByTraceChartProps): React.ReactElement => {
  const { data, error, isLoading } = useWaitsByTraceChartData(
    event ? event.databaseId : 0,
    event ? event.textMd5 : "",
    visibleDates.from,
    visibleDates.to,
    MoRef.fromTopologyItem(target).toString(),
  );

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  // scales
  const dateScale = scaleBand<string>({
    domain: data?.data.map((x) => x.date.toString()),
    padding: 0.2,
  });
  const waitMsScale = scaleLinear<number>({
    domain: [0, data?.max_total ?? 100],
    nice: true,
  });
  const colorScale = scaleOrdinal<string, string>({
    domain: data?.waitTypes,
    range: colors,
  });

  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();

  const xMax = width - 60;
  const yMax = height - 40;

  let tooltipTimeout: number;

  dateScale.rangeRound([0, xMax]);
  waitMsScale.range([yMax, 0]);

  let transposedData: WaitsByTraceTransposedData[] = [];

  if (data && !error) {
    transposedData = transposeWaitsData(data.data, data.waitTypes);
  }

  const tooltipStyles: React.CSSProperties = {
    ...defaultStyles,
    backgroundColor: "rgba(0,0,0,0.9)",
    color: "white",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    minWidth: 60,
    zIndex: 5000,
  };

  const formatTick = (tick: any): string => {
    const intTick = parseInt(tick);
    if (intTick < 1000000 && intTick > 999) {
      return intTick / 1000 + "k";
    }
    if (intTick < 1000000000 && intTick >= 1000000) {
      return intTick / 1000000 + "m";
    }
    return intTick.toString();
  };

  return (
    <div>
      {isLoading ? (
        <LoadingIndicator style={{ height: `${height}px`, width: `${width}px` }} variant="chart" />
      ) : (
        <ErrorBoundary key={TopSqlChartType.Waits} variant="chart">
          <svg fill="white" height={height} ref={containerRef} width={width}>
            <Grid
              height={yMax}
              left={60}
              stroke="black"
              strokeOpacity={0.1}
              top={10}
              width={xMax}
              xOffset={dateScale.bandwidth() / 2}
              xScale={dateScale}
              yScale={waitMsScale}
            />
            <Group left={60} top={10}>
              <BarStack<WaitsByTraceTransposedData, WaitType>
                color={colorScale}
                data={transposedData}
                keys={data?.waitTypes as WaitType[] | undefined}
                x={(x) => x.date}
                xScale={dateScale}
                yScale={waitMsScale}
              >
                {(barStacks) =>
                  barStacks.map((barStack) =>
                    barStack.bars.map((bar) => (
                      <rect
                        fill={bar.color}
                        height={bar.height}
                        key={`bar-stack-${barStack.index}-${bar.index}`}
                        onMouseLeave={() => {
                          tooltipTimeout = window.setTimeout(() => {
                            hideTooltip();
                          }, 300);
                        }}
                        onMouseMove={(event) => {
                          if (tooltipTimeout) clearTimeout(tooltipTimeout);
                          const eventSvgCoords = localPoint(event);
                          const left = bar.x + bar.width / 2;
                          showTooltip({
                            tooltipData: bar,
                            tooltipLeft: left,
                            tooltipTop: eventSvgCoords?.y,
                          });
                        }}
                        width={bar.width}
                        x={bar.x}
                        y={bar.y}
                      />
                    )),
                  )
                }
              </BarStack>
              <AxisBottom
                numTicks={8}
                scale={dateScale}
                stroke="black"
                tickFormat={(date) => formatDate(date)}
                tickLabelProps={{
                  fill: "black",
                  fontSize: 10,
                  textAnchor: "middle",
                }}
                tickStroke="black"
                top={yMax}
              />
              <AxisLeft
                numTicks={6}
                scale={waitMsScale}
                stroke="black"
                tickFormat={(tick) => formatTick(tick)}
                tickLabelProps={{
                  fill: "black",
                  fontSize: 11,
                  textAnchor: "end",
                }}
                tickStroke="black"
              />
            </Group>
          </svg>
          {tooltipOpen && tooltipData && (
            <TooltipInPortal left={tooltipLeft} style={tooltipStyles} top={tooltipTop}>
              <div style={{ color: colorScale(tooltipData.key) }}>
                <strong>{tooltipData.key}</strong>
              </div>
              <div>{tooltipData.bar.data[tooltipData.key]}ms</div>
              <div>
                <small>{formatDate(tooltipData.bar.data.date.toLocaleString())}</small>
              </div>
            </TooltipInPortal>
          )}
        </ErrorBoundary>
      )}
    </div>
  );
};

export default WaitsByTraceChart;
