import Chip from "@material-ui/core/Chip";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { ITranslation } from "../../../intl/types";

const useStyles = makeStyles((theme) => ({
  chip: {
    marginLeft: theme.spacing(1),
  },
}));

interface IChartFilterChipProps {
  filters: string[] | null;
  filterType: keyof ITranslation;
  id: string;
  onRemove: () => void;
}

const ChartFilterChip: React.FC<IChartFilterChipProps> = ({ filters, filterType, id, onRemove }) => {
  const classes = useStyles();
  if (!filters) {
    return null;
  } else {
    return (
      <Chip
        avatar={<Avatar>{filters.length}</Avatar>}
        className={classes.chip}
        color="primary"
        id={id}
        label={<FormattedMessage id={filterType} />}
        onDelete={onRemove}
      />
    );
  }
};

export default ChartFilterChip;
