import * as React from "react";
import type { IUser, IPrincipalSelection } from "../../../types";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { useIntl } from "react-intl";

export interface IUserDetailsViewProps {
  selectedUser: IUser;
  handleSelection: (newSelection: IPrincipalSelection) => void;
}

const useStyles = makeStyles((theme) => ({
  chipCell: {
    display: "flex",
    flexWrap: "wrap",
    gap: `${theme.spacing(1)}px`,
    height: "fit-content",
  },
  groupsTable: {
    marginTop: theme.spacing(2),
  },
  table: {
    "& table": {
      tableLayout: "fixed",
    },
    "& td": {
      ...theme.typography.body1,
      overflowX: "hidden",
      paddingTop: 0,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& td, th": {
      border: "none",
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.body2.fontSize,
      textTransform: "uppercase",
    },
    "& thead > tr": {
      backgroundColor: "inherit",
    },
  },
}));

const UserDetailsView: React.FC<IUserDetailsViewProps> = ({ selectedUser, handleSelection }) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <CardContent className={classes.table}>
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell>{intl.formatMessage({ id: "firstName" })}</TableCell>
            <TableCell>{intl.formatMessage({ id: "lastName" })}</TableCell>
            <TableCell>{intl.formatMessage({ id: "email" })}</TableCell>
            <TableCell>{intl.formatMessage({ id: "login" })}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell title={selectedUser.firstName}>{selectedUser.firstName}</TableCell>
            <TableCell title={selectedUser.lastName}>{selectedUser.lastName}</TableCell>
            <TableCell title={selectedUser.email ?? intl.formatMessage({ id: "notAvailable" })}>
              {selectedUser.email ?? intl.formatMessage({ id: "notAvailable" })}
            </TableCell>
            <TableCell title={selectedUser.login ?? intl.formatMessage({ id: "notAvailable" })}>
              {selectedUser.login ?? intl.formatMessage({ id: "notAvailable" })}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table className={classes.groupsTable} size="medium">
        <TableHead>
          <TableRow>
            <TableCell>{intl.formatMessage({ id: "groups" })}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <div aria-label="userDetailsGroups" className={classes.chipCell}>
                {selectedUser.userGroups.map((group) => (
                  <Chip
                    key={group.id}
                    label={group.name}
                    onClick={() => handleSelection({ category: "groups", id: group.id })}
                  />
                ))}
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </CardContent>
  );
};

export default UserDetailsView;
