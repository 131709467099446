import Tab, { TabProps } from "@material-ui/core/Tab";
import * as React from "react";
import { useLocation } from "react-router";
import { NavLink, NavLinkProps } from "react-router-dom";

interface INavBarLinkProps extends TabProps {
  label: React.ReactNode;
  to: string;
  value: string;
  disableNavigation?: boolean;
}

const LinkComponent = React.forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => {
  return <NavLink {...props} innerRef={ref} />;
});

const TargetDialogHeaderLink: React.FunctionComponent<INavBarLinkProps> = ({ disableNavigation, to, ...props }) => {
  const location = useLocation();

  const navLinkProps = {
    to: {
      pathname: disableNavigation ? undefined : to,
      search: location.search,
    },
  };

  return <Tab component={LinkComponent as any} {...props} {...navLinkProps} />;
};

export default TargetDialogHeaderLink;
