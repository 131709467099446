import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useIntl } from "react-intl";
import { BlockingReportType } from "../../types";

interface ReportingChartMenuProps {
  aggregateBy: BlockingReportType;
  handleSelectAggregate: (aggregate: BlockingReportType) => void;
}

const useStyles = makeStyles(theme => ({
  item: {
    backgroundColor: theme.palette.primary.light,
  },
  label: {
    paddingLeft: theme.spacing(1.5),
  },
  menu: {
    height: "100%",
  },
}));

const ReportingChartMenu: React.FC<ReportingChartMenuProps> = ({ aggregateBy, handleSelectAggregate }) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <MenuList className={classes.menu}>
      <MenuItem
        classes={{ gutters: classes.label, selected: classes.item }}
        onClick={() => handleSelectAggregate(BlockingReportType.application)}
        selected={aggregateBy === BlockingReportType.application}
      >
        {intl.formatMessage({ id: "application" })}
      </MenuItem>
      <MenuItem
        classes={{ gutters: classes.label, selected: classes.item }}
        onClick={() => handleSelectAggregate(BlockingReportType.resource)}
        selected={aggregateBy === BlockingReportType.resource}
      >
        {intl.formatMessage({ id: "waitResource" })}
      </MenuItem>
      <MenuItem
        classes={{ gutters: classes.label, selected: classes.item }}
        onClick={() => handleSelectAggregate(BlockingReportType.waitType)}
        selected={aggregateBy === BlockingReportType.waitType}
      >
        {intl.formatMessage({ id: "waitType" })}
      </MenuItem>
    </MenuList>
  );
};

export default ReportingChartMenu;
