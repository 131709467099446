import { match, useRouteMatch } from "react-router";

type ITenantRouteMatch = { tenantId: string }; 
type IGroupRouteMatch = { groupId: string }; 
type ITargetRouteMatch = { targetId: string }; 
type IFeatureRouteMatch = { feature: string };

interface IUseTopologyRouteMatchResult {
    featureMatch: match<IFeatureRouteMatch> | null;
    groupMatch: match<IGroupRouteMatch> | null;
    targetMatch: match<ITargetRouteMatch> | null;
    tenantMatch: match<ITenantRouteMatch> | null;
}

export default function useTopologySectionRouteMatch(): IUseTopologyRouteMatchResult {
    const tenantMatch = useRouteMatch<ITenantRouteMatch>("/:tenantId");
    const groupMatch = useRouteMatch<IGroupRouteMatch>(`${tenantMatch?.url}/health/groups/:groupId`);
    const targetMatch = useRouteMatch<ITargetRouteMatch>(`${tenantMatch?.url}/targets/:targetId`);
    const featureMatch = useRouteMatch<IFeatureRouteMatch>(`${targetMatch?.url}/:feature`);

    return {
      featureMatch,
      groupMatch,
      targetMatch,
      tenantMatch,
    };
  }