import { useQuery } from "@apollo/react-hooks";
import * as GET_HEALTH_SCORE from "./getHealthScore.graphql";
import { EventGroupHealthType, IHealthStatusItem, TopologyObjectType } from "../../api/PerformanceAdvisor";

// TYPES //
export type IAsyncResult<T> =
  | {
      data: undefined;
      error: Error;
      isLoading: false;
      refetch?: any;
      state: "error";
    }
  | {
      data: undefined;
      error: null;
      isLoading: true;
      refetch?: any;
      state: "loading";
    }
  | {
      data: T;
      error: null;
      isLoading: false;
      refetch?: any;
      state: "success";
    };

type IDetails = {
  itemType: TopologyObjectType;
  itemId: number;
  score: number | null;
  scoreDelta: number | null;
};

export interface IHealthStatusItemResponse {
  objectId: string;
  details: IDetails;
}
export interface IHealthStatusRequest {
  calculateDelta: boolean;
  endDateUtc: Date;
  eventGroupHealthType: EventGroupHealthType;
  objectId?: number;
  objectType: TopologyObjectType;
  returnAllChildrenOfType?: Array<TopologyObjectType>;
  rollupLevelMinutes?: number;
  scoreSeverities?: Array<string> | null;
  scoreThreshold?: number;
  startDateUtc: Date;
}

// QUERIES //
export function useGetHealthScore(request: IHealthStatusRequest): IAsyncResult<IHealthStatusItem[]> {
  const { data = { targetHealth: [] }, error, loading } = useQuery<
    {
      targetHealth: Array<IHealthStatusItemResponse>;
    },
    {
      request: {
        calculateDelta: boolean;
        dateTimeRangeUtc: {
          startDateTime: string;
          endDateTime: string;
        };
        eventGroupHealthType: EventGroupHealthType;
        objectId?: number;
        objectType: TopologyObjectType;
        returnAllChildrenOfType?: Array<TopologyObjectType>;
        rollupLevelMinutes?: number;
        scoreSeverities?: Array<string> | null;
        scoreThreshold?: number;
      };
    }
  >(GET_HEALTH_SCORE, {
    variables: {
      request: {
        calculateDelta: request.calculateDelta,
        dateTimeRangeUtc: {
          endDateTime: request.endDateUtc.toISOString(),
          startDateTime: request.startDateUtc.toISOString(),
        },
        eventGroupHealthType: request.eventGroupHealthType,
        objectId: request.objectId,
        objectType: request.objectType,
        returnAllChildrenOfType: request.returnAllChildrenOfType,
        rollupLevelMinutes: request.rollupLevelMinutes,
        scoreSeverities: request.scoreSeverities,
        scoreThreshold: request.scoreThreshold,
      },
    },
  });

  if (error) {
    return {
      data: undefined,
      error: error,
      isLoading: false,
      state: "error",
    };
  } else if (loading) {
    return {
      data: undefined,
      error: null,
      isLoading: true,
      state: "loading",
    };
  } else {
    return {
      data: [
        ...data.targetHealth.map(({ objectId, details }) => {
          return { objectId, ...details, scoreDelta: details.scoreDelta ?? 0 };
        }),
      ] as IHealthStatusItem[],
      error: null,
      isLoading: false,
      state: "success",
    };
  }
}
