import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { LoadingIndicator } from "@sentryone/material-ui";
import classnames from "classnames";
import * as React from "react";
import { useIntl } from "react-intl";
import DeadlockNodeGrid from "../DeadlockNodeGrid";
import DeadlockLockGrid from "../DeadlockLockGrid";
import DeadlockResourceDetails from "../DeadlockResourceDetails";
import { useDeadlocksContext, IDeadlockProcess, IDeadlockResource } from "../../../../contexts/deadlocksContext";
import NoDataIndicator from "../../../../components/NoDataIndicator";

const useStyles = makeStyles((theme) => ({
  content: {
    "& td": {
      verticalAlign: "middle",
    },
  },
  control: {
    cursor: "pointer",
  },
  details: {
    // 100% is relative to the position: relative parent
    // 13 spacing units accounts for the other items above this one
    // landing it 1 spacing unit from the bottom of the diagram
    maxHeight: `calc(100% - ${theme.spacing(13)}px)`,
    minWidth: theme.spacing(100),
    overflow: "auto",
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(12),
    width: "50%",
  },
  icon: {
    color: theme.palette.text.secondary,
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  iconFlipped: {
    transform: "rotate(180deg)",
  },
  noData: {
    alignSelf: "center",
    bottom: 0,
    position: "absolute",
    top: 0,
  },
  title: {
    alignSelf: "center",
    display: "flex",
    fontWeight: 600,
    paddingLeft: theme.spacing(2),
    verticalAlign: "bottom",
  },
}));

const isProcessNode = (node: IDeadlockProcess | IDeadlockResource | undefined): node is IDeadlockProcess => {
  return (node as IDeadlockProcess)?.spid !== undefined;
};

const NodeDetails: React.FC = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { loading, selectedNode } = useDeadlocksContext();
  const [expanded, setExpanded] = React.useState(true);

  if (loading) {
    return <LoadingIndicator />;
  } else if (!selectedNode) {
    return <NoDataIndicator className={classes.noData} />;
  }
  return (
    <Card className={classes.details} elevation={2}>
      <CardHeader
        action={
          <ExpandMoreIcon
            className={classnames(classes.icon, {
              [classes.iconFlipped]: expanded,
            })}
          />
        }
        aria-expanded={expanded}
        aria-label={intl.formatMessage({ id: expanded ? "collapse" : "expand" })}
        className={classes.control}
        onClick={() => setExpanded((prev) => !prev)}
        role="button"
        title={intl.formatMessage({ id: "nodeDetails" })}
      />
      <Collapse in={expanded} timeout="auto">
        <div className={classes.content}>
          {isProcessNode(selectedNode) ? (
            <>
              <DeadlockNodeGrid />
              <DeadlockLockGrid />
            </>
          ) : (
            <DeadlockResourceDetails />
          )}
        </div>
      </Collapse>
    </Card>
  );
};

export default NodeDetails;
