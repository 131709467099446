import { makeStyles } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import { LoadingIndicator } from "@sentryone/material-ui";
import * as React from "react";
import { useIntl } from "react-intl";
import { IDeadlockProcess, IDeadlockResource, useDeadlocksContext } from "../../../../contexts/deadlocksContext";
import NoDataIndicator from "../../../../components/NoDataIndicator";

const useStyles = makeStyles((theme) => ({
  loading: {
    flexGrow: 1,
    height: 0,
    maxWidth: "100%",
    minHeight: "5rem",
  },
  noWrap: {
    maxWidth: theme.spacing() * 30,
    minWidth: 0,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  spid: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    verticalAlign: "inherit",
    whiteSpace: "nowrap",
  },
  title: {
    fontWeight: 600,
    paddingLeft: theme.spacing() * 2,
  },
}));

const isResourceNode = (node: IDeadlockProcess | IDeadlockResource | undefined): node is IDeadlockResource => {
  return (node as IDeadlockResource)?.waiters !== undefined;
};

const DeadlockResourceDetails: React.FunctionComponent = () => {
  const classes = useStyles();
  const { deadlockInfo, selectedNode, loading, loadingDeadlock, error, setSelectedNodeId } = useDeadlocksContext();
  const intl = useIntl();
  const selectedResource = isResourceNode(selectedNode) ? selectedNode : undefined;

  return (
    <Table size="small">
      <caption>{intl.formatMessage({ id: "processes" })}</caption>
      <TableHead>
        <TableRow>
          <TableCell>{intl.formatMessage({ id: "type" })}</TableCell>
          <TableCell>{`${intl.formatMessage({ id: "spid" })} [${intl.formatMessage({ id: "ecid" })}]`}</TableCell>
          <TableCell>{intl.formatMessage({ id: "lockMode" })}</TableCell>
          <TableCell>{intl.formatMessage({ id: "host" })}</TableCell>
          <TableCell>{intl.formatMessage({ id: "application" })}</TableCell>
          <TableCell>{intl.formatMessage({ id: "login" })}</TableCell>
          <TableCell>{intl.formatMessage({ id: "textData" })}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loading || !deadlockInfo || loadingDeadlock ? (
          <tr>
            <td colSpan={6}>
              <LoadingIndicator className={classes.loading} variant="table" />
            </td>
          </tr>
        ) : error || !selectedResource || (!selectedResource.waiters.length && !selectedResource.owners.length) ? (
          <tr>
            <td colSpan={6}>
              <NoDataIndicator />
            </td>
          </tr>
        ) : (
          <>
            {selectedResource.owners.map((row) => (
              <TableRow key={row.key}>
                <TableCell>{intl.formatMessage({ id: "owner" })}</TableCell>
                <TableCell
                  className={classes.spid}
                  onClick={() => setSelectedNodeId(row.id)}
                >{`${row.process.spid} [${row.process.ecid}]`}</TableCell>
                <TableCell>{row.mode}</TableCell>
                <TableCell className={classes.noWrap} title={row.process.hostName}>
                  {row.process.hostName}
                </TableCell>
                <TableCell className={classes.noWrap} title={row.process.clientApp}>
                  {row.process.clientApp}
                </TableCell>
                <TableCell className={classes.noWrap} title={row.process.loginName}>
                  {row.process.loginName}
                </TableCell>
                <TableCell>{row.process.executionStack.map((x) => x.text).join("\n")}</TableCell>
              </TableRow>
            ))}
            {selectedResource.waiters.map((row) => (
              <TableRow key={row.key}>
                <TableCell>{intl.formatMessage({ id: "waiter" })}</TableCell>
                <TableCell
                  className={classes.spid}
                  onClick={() => setSelectedNodeId(row.id)}
                >{`${row.process.spid} [${row.process.ecid}]`}</TableCell>
                <TableCell>{row.mode}</TableCell>
                <TableCell className={classes.noWrap} title={row.process.hostName}>
                  {row.process.hostName}
                </TableCell>
                <TableCell className={classes.noWrap} title={row.process.clientApp}>
                  {row.process.clientApp}
                </TableCell>
                <TableCell className={classes.noWrap} title={row.process.loginName}>
                  {row.process.loginName}
                </TableCell>
                <TableCell>{row.process.executionStack.map((x) => x.text).join("\n")}</TableCell>
              </TableRow>
            ))}
          </>
        )}
      </TableBody>
    </Table>
  );
};

export default DeadlockResourceDetails;
