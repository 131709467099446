import * as React from "react";
import { EventGroupHealthType, HealthRegionKey } from "../../api/PerformanceAdvisor";
import { ITopologyItemDevice, ITopologyItemEventSourceConnection } from "../../components/TopologyContext";

export interface IHealthEventsFilter {
  readonly healthRegion: HealthRegionKey | null;
  readonly target: ITopologyItemEventSourceConnection | ITopologyItemDevice | null;
}

export interface IHealthTargetsFilter {
  readonly maxScore?: number;
  readonly minWaitTimePerSession?: number;
  readonly rollupLevelMinutes: number;
}

export interface IHealthContext {
  readonly eventsFilter: IHealthEventsFilter;
  readonly groupHealthType: EventGroupHealthType;
  readonly rollupLevelMinutes: number;
  readonly setEventsFilter: (value: IHealthEventsFilter | null) => void;
  readonly setGroupHealthType: (value: EventGroupHealthType) => void;
  readonly setTargetsFilter: (value: IHealthTargetsFilter | null) => void;
  readonly setZoom: (value: ZoomLevel) => void;
  readonly targetsFilter: IHealthTargetsFilter;
  readonly zoom: ZoomLevel;
}

export type ZoomLevel = -1 | 0 | 1;

const context = React.createContext<IHealthContext | undefined>(undefined);
context.displayName = "TargetHealthContext";

const DEFAULT_EVENTS_FILTER: IHealthEventsFilter = {
  healthRegion: null,
  target: null,
};
const DEFAULT_TARGETS_FILTER: IHealthTargetsFilter = {
  rollupLevelMinutes: 2,
};

export const HealthContextProvider: React.FC = ({ children }) => {
  const [eventsFilter, setEventsFilter] = React.useState<IHealthEventsFilter>(DEFAULT_EVENTS_FILTER);
  const [groupHealthType, setGroupHealthType] = React.useState<EventGroupHealthType>(EventGroupHealthType.Severity);
  const [targetsFilter, setTargetsFilter] = React.useState<IHealthTargetsFilter>(DEFAULT_TARGETS_FILTER);
  const [zoom, setZoom] = React.useState<IHealthContext["zoom"]>(0);
  const value: IHealthContext = {
    eventsFilter,
    groupHealthType,
    rollupLevelMinutes: targetsFilter.rollupLevelMinutes,
    setEventsFilter: (value) => setEventsFilter(value ?? DEFAULT_EVENTS_FILTER),
    setGroupHealthType,
    setTargetsFilter: (value) => setTargetsFilter(value ?? DEFAULT_TARGETS_FILTER),
    setZoom,
    targetsFilter,
    zoom,
  };
  return <context.Provider value={value}>{children}</context.Provider>;
};

export function useHealthContext(): IHealthContext {
  const value = React.useContext(context);
  if (!value) {
    throw new Error("useHealthContext can only be used within HealthOverview");
  }
  return value;
}
