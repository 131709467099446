import * as React from "react";
import { TopologyObjectType } from "../../api/PerformanceAdvisor";
import DocumentTitle from "../../components/DocumentTitle";
import { useCurrentTarget } from "../../hooks/useCurrentTarget";
import DeadlocksContent from "./DeadlocksContent";

const Deadlocks: React.FC = () => {
  const target = useCurrentTarget();
  if (!target) {
    throw new Error("Deadlocks can only be used with a target");
  } else if (target.type !== TopologyObjectType.eventSourceConnection) {
    throw new Error(`Deadlocks can only be used with event source connections. Received ${target.type}`);
  }

  return (
    <>
      <DeadlocksContent target={target} />
      <DocumentTitle title="deadlocks" topologyItem={target} />
    </>
  );
};

export default Deadlocks;
