export enum PlanDataCalculationStyle {
  cumulative = 1,
  node = 2,
}

export enum PlanDataCostsBy {
  cpu = 1,
  io = 2,
  cpuIo = 3,
}

export enum PlanDataCostType {
  estimated = 1,
  actual = 2,
}

export enum PlanDataOverlay {
  Information = 1,
  Warning = 2,
  Error = 3,
  Parallel = 4,
  BatchMode = 5,
  BatchModeParallel = 6,
}

export interface IPlanDataRequest {
  planToken: string;
  statementId?: number | null;
}

export interface IPlanDataResponse {
  hasActuals: boolean;
  hasMissingIndexes: boolean;
  linkInfo: IPlanDataLinkInfo;
  linkTooltips: Record<number, IPlanDataTooltipGroup> | null;
  opacityInfo: IPlanDataOpacityInfo;
  operationCosts: IPlanDataOperationCosts;
  planToken: string;
  rootRelOp: IPlanDataNode;
  statementText: string;
  tooltips: Record<number, IPlanDataNodeTooltip> | null;
}

export interface IRawPlanXMLResponse {
  rawXML: string;
}

export interface IPlanDataLinkInfo {
  costType: PlanDataCostType;
  links: Record<number, IPlanDataLink>;
  widthBy: number;
}

export interface IPlanDataLink {
  text: string;
  textBackgroundColor: string | null;
  textForegroundColor: string | null;
  toNodeId: number;
  widthPercent: number;
}

export interface IPlanDataTooltipGroup {
  header: string;
  valuePairs: { [key: string]: string };
  values: [string];
}

export interface IPlanDataOpacityInfo {
  costType: PlanDataCostType;
  isImmaterial: Record<number, boolean>;
}

export interface IPlanDataOperationCosts {
  calculationStyle: PlanDataCalculationStyle;
  costType: PlanDataCostType;
  costs: Record<number, IPlanDataOperationCostValue>;
  costsBy: PlanDataCostsBy;
}

export interface IPlanDataOperationCostValue {
  backgroundColor: string | null;
  costPercent: number;
  foregroundColor: string | null;
  nodeId: number;
}

export interface IPlanDataNode {
  additionalIndexes: IPlanDataNodeAdditionalIndexes | null;
  children: IPlanDataNode[];
  id: number;
  indexName: string;
  nodeId: number;
  objectName: string;
  operationName: string;
  operationTextBackgroundColor: string | null;
  operationTextForegroundColor: string | null;
  overlay: PlanDataOverlay;
  shortName: string;
  table: string;
  totalOperatorCost: number;
}

export interface IPlanDataNodeAdditionalIndexes {
  backgroundColor: string | null;
  foregroundColor: string | null;
  text: string;
}

export interface IPlanDataNodeTooltip {
  description: string;
  nodeId: number;
  title: string;
  tooltipGroups: Record<number, IPlanDataTooltipGroup> | null;
}
