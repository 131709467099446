import { SortDescriptor } from "@sentryone/react-datatable";
import * as React from "react";
import sortContext from "./sortContext";
import { endTimeDefault, lastDefault, paramsDefault, readsDefault, readsLDefault } from "./sortDefaults";
import {
  AggregatedEventLogsSortType,
  EventLogsSortType,
  ParametersSortType,
  StatementsSortType,
  TotalsSortType,
  TraceEventsSortType,
} from "./types";

const SortContextProvider: React.FunctionComponent = ({ children }) => {
  const [aggregatedEventLogs, setAggregatedEventLogs] = React.useState(lastDefault);
  const [eventLogs, setEventLogs] = React.useState(endTimeDefault);
  const [parameters, setParameters] = React.useState(paramsDefault);
  const [statements, setStatements] = React.useState(readsLDefault);
  const [statementsTrace, setStatementsTrace] = React.useState(readsDefault);
  const [totals, setTotals] = React.useState(readsLDefault);
  const [totalsTrace, setTotalsTrace] = React.useState(readsDefault);

  const normalizeReadsColumn = (sort: readonly SortDescriptor[]): readonly SortDescriptor[] => {
    let normalizedSortObject = sort;

    if (sort[0].id === "readsL") {
      normalizedSortObject = [{
        direction: sort[0].direction,
        id: "reads",
      }];
    }

    return normalizedSortObject;
  };

  const updateAggregatedEventLogs = (sort: readonly SortDescriptor[]): void => {
    if (!(sort[0].id in AggregatedEventLogsSortType)) { return; }
    setAggregatedEventLogs(sort);
  };
  const updateEventLogs = (sort: readonly SortDescriptor[]): void => {
    if (!(sort[0].id in EventLogsSortType)) { return; }
    setEventLogs(sort);
  };
  const updateParameters = (sort: readonly SortDescriptor[]): void => {
    if (!(sort[0].id in ParametersSortType)) { return; }
    setParameters(sort);
  };
  const updateStatementsTrace = (sort: readonly SortDescriptor[]): void => {
    const result = normalizeReadsColumn(sort);
    if (!(result[0].id in TraceEventsSortType)) { return; }
    setStatementsTrace(result);
  };
  const updateStatements = (sort: readonly SortDescriptor[]): void => {
    if (!(sort[0].id in StatementsSortType)) { return; }
    setStatements(sort);
    updateStatementsTrace(sort);
  };
  const updateTotalsTrace = (sort: readonly SortDescriptor[]): void => {
    const result = normalizeReadsColumn(sort);
    if (!(result[0].id in TraceEventsSortType)) { return; }
    setTotalsTrace(result);
    updateStatementsTrace(result);
  };
  const updateTotals = (sort: readonly SortDescriptor[]): void => {
    if (!(sort[0].id in TotalsSortType)) { return; }
    setTotals(sort);
    updateTotalsTrace(sort);
    updateStatements(sort);
  };

  return (
    <sortContext.Provider value={{
      sort: {
        aggregatedEventLogs,
        eventLogs,
        parameters,
        statements,
        statementsTrace,
        totals,
        totalsTrace,
      },
      updateAggregatedEventLogs,
      updateEventLogs,
      updateParameters,
      updateStatements,
      updateStatementsTrace,
      updateTotals,
      updateTotalsTrace,
    }}>
      {children}
    </sortContext.Provider>
  );
};

export default SortContextProvider;
