import * as React from "react";
import DocumentTitle from "../../components/DocumentTitle";
import { useCurrentTarget } from "../../hooks/useCurrentTarget";
import { StorageContextProvider } from "../../contexts/storageContext";
import StorageContent from "./StorageContent";
import { TopologyObjectType } from "../../components/TopologyContext";

const Storage: React.FC = () => {
  const target = useCurrentTarget();
  if (!target) {
    throw new Error("Storage can only be used with a target");
  } else if (target.type !== TopologyObjectType.device && target.type !== TopologyObjectType.eventSourceConnection) {
    throw new Error("Storage can only be used with a target of type 'Device' or 'EventSourceConnection'");
  } else {
    return (
      <>
        <DocumentTitle title="storage" topologyItem={target} />
        <StorageContextProvider currentTarget={target}>
          <StorageContent />
        </StorageContextProvider>
      </>
    );
  }
};

export default Storage;
