import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import PauseIcon from "@material-ui/icons/PauseCircleOutline";
import PlayIcon from "@material-ui/icons/PlayCircleOutline";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import * as React from "react";
import { useIntl } from "react-intl";
import { useDateContext } from "../DateContext";
import { makeStyles } from "@material-ui/core/styles";

export interface IDateJumpIconButtonProps {
  color: IconButtonProps["color"];
}

const useStyles = makeStyles({
  disabled: {},
  root: {
    "&$disabled": {
      borderColor: "rgba(255, 255, 255, 0.4)",
      color: "rgba(255, 255, 255, 0.4)",
    },
  },
});

const DateJumpIconButton: React.FC<IDateJumpIconButtonProps> = ({ color }) => {
  const intl = useIntl();
  const classes = useStyles();
  const {
    allowDateNavigation,
    autoRefreshSupported,
    dateRange,
    liveEnabled,
    setDateRange,
    switchToLive,
  } = useDateContext();

  const handleClick = (): void => {
    if (liveEnabled) {
      setDateRange(dateRange);
    } else {
      switchToLive();
    }
  };

  if (liveEnabled) {
    return (
      <IconButton
        classes={{
          disabled: classes.disabled,
          root: classes.root,
        }}
        color={color}
        disabled={!allowDateNavigation}
        onClick={handleClick}
        title={intl.formatMessage({ id: "pauseLiveData" })}
      >
        <PauseIcon className="telemetry-nav-pauseData" />
      </IconButton>
    );
  } else if (autoRefreshSupported) {
    return (
      <IconButton
        classes={{
          disabled: classes.disabled,
          root: classes.root,
        }}
        color={color}
        disabled={!allowDateNavigation}
        onClick={handleClick}
        title={intl.formatMessage({ id: "switchToLiveData" })}
      >
        <PlayIcon className="telemetry-nav-liveData" />
      </IconButton>
    );
  } else {
    return (
      <IconButton
        classes={{
          disabled: classes.disabled,
          root: classes.root,
        }}
        color={color}
        disabled={!allowDateNavigation}
        onClick={handleClick}
        title={intl.formatMessage({ id: "jumpToCurrentTime" })}
      >
        <SkipNextIcon className="telemetry-nav-jumpToCurrent" />
      </IconButton>
    );
  }
};

export default DateJumpIconButton;
