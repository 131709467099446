import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import WarningIcon from "@material-ui/icons/Error";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { IWarningResponse } from "../../types";
interface IWarningDataProps {
    warningData: IWarningResponse,
    entityLink: string
}

const useStyles = makeStyles((theme) => ({
    warningArea: {
        alignItems: "center",
        border: "1px solid rgba(0, 0, 0, 0.2)",
        display: "flex",
        flexDirection: "row",
        padding: `${theme.spacing(2.35)}px`,
    },
    warningCard: {
        width: "99%"
    },
    warningFilePath: {
        fontSize: `${theme.spacing(2.35)}px`,
        margin: "0",
    },
    warningIcon: {
        flex: " 0 0 8%",
        height: "1.2em",
        marginRight: `${theme.spacing(2)}px`,
        width: "1.2em"
    },
    warningSection: {
        display: "flex",
        flexDirection: "column",
        gap: `${theme.spacing(2)}px`,
        overflow: "auto",
    },
    warningValue: {
        color: "#E74C3C",
        fontSize: `${theme.spacing(2.7)}px`,
        fontWeight: "bold",
    }
}))

export const getPath = (warning: IWarningResponse): string | void => {
  if (warning.warningEntity.__typename === "SqlServerFile") {
    const diskName = warning?.warningEntity?.logicalVolume?.physicalDisks?.[0]?.diskController?.name;
    const physicalDiskName = warning?.warningEntity?.logicalVolume?.physicalDisks?.[0]?.name;
    const logicalVolumeName = warning?.warningEntity?.logicalVolume?.name;
    return `${diskName} > ${physicalDiskName} > ${logicalVolumeName} > `;
  }
  if (warning.warningEntity.__typename === "LogicalVolume") {
    const diskName = warning?.warningEntity?.physicalDisks?.[0]?.diskController?.name;
    const physicalDiskName = warning?.warningEntity?.physicalDisks?.[0]?.name;
    return `${diskName} > ${physicalDiskName} > `;
  }
  if (warning.warningEntity.__typename === "PhysicalDisk") {
    const diskName = warning?.warningEntity?.diskController?.name;
    return `${diskName} > `;
  }
  if (warning.warningEntity.__typename === "DiskController") {
    return "";
  }
};

const StorageEntityWarning: React.FC<IWarningDataProps> = ({ warningData, entityLink }) => {
    const classes = useStyles();

  const filePath = getPath(warningData);

    return (
        <Card className={classes.warningCard}>
            <Link className={classes.warningArea} to={entityLink ? entityLink : "#"}>
                <WarningIcon className={classes.warningIcon} color="error" fontSize="medium" />
                <div className={classes.warningSection}>
                    <Typography className={classes.warningFilePath} component="h5">
                        {filePath} <span style={{ fontWeight: 'bold' }}>{warningData?.warningEntity?.name}</span>
                    </Typography>
                    <Typography className={classes.warningFilePath} component="h5">{warningData.warningMessage}
                        <span className={classes.warningValue}> {warningData.warningValueWithUnit}</span>
                    </Typography>
                </div>
            </Link>
        </Card>
    )
}

export default StorageEntityWarning;
