import Paper from "@material-ui/core/Paper";
import StorageEntityDetails from "./components/StorageEntityDetails";
import StorageEntityCharts from "./components/StorageEntityCharts";
import StorageEntityTree from "./components/StorageEntityTree";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useIntl } from "react-intl";
import { StorageEntityType } from "./types";
import { useStorageContext } from "../../contexts/storageContext";
import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  detailsContainer: {
    flex: 1,
    overflowY: "auto",
  },
  detailsContent: {
    padding: theme.spacing(4),
  },
  nav: {
    maxWidth: theme.spacing(120),
    minWidth: theme.spacing(60),
    overflowY: "auto",
    width: "40%",
  },
  root: {
    display: "flex",
    gap: `${theme.spacing(3)}px`,
    height: "100%",
  },
}));

const StorageContent: React.FC = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { selectedEntity } = useStorageContext();
  const [tab, setTab] = React.useState<"details" | "trends">("details");

  useEffect(() => {
    // Controllers are the only entities without charts
    if (selectedEntity.type === StorageEntityType.DiskController) {
      setTab("details");
    }
  }, [selectedEntity]);

  return (
    <div className={classes.root}>
      <Paper className={classes.nav} component="aside">
        <StorageEntityTree />
      </Paper>
      <Paper className={classes.detailsContainer}>
        <Tabs onChange={(_, value) => setTab(value)} value={tab}>
          <Tab label={intl.formatMessage({ id: "details" })} value="details" />
          <Tab
            disabled={selectedEntity.type === StorageEntityType.DiskController}
            label={intl.formatMessage({ id: "trends" })}
            value="trends"
          />
        </Tabs>
        {tab === "details" ? (
          <StorageEntityDetails className={classes.detailsContent} />
        ) : (
          <StorageEntityCharts className={classes.detailsContent} />
        )}
      </Paper>
    </div>
  );
};

export default StorageContent;
