import * as React from "react";
import { IPlanDataResponse } from "../../../../../../api/PlanViewerService/models/PlanViewer";
import usePlanDiagram from "./usePlanDiagram";

interface IPlanDiagramContentProps {
  data: IPlanDataResponse;
  showColorScale: boolean;
  className?: string;
  animate: boolean;
}

const PlanDiagramContent: React.FunctionComponent<IPlanDiagramContentProps> = (props) => {
  const diagramProps = usePlanDiagram(props);

  return <div {...diagramProps} />;
};

export default PlanDiagramContent;
