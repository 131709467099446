import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import DownloadIcon from "@material-ui/icons/GetApp";
import classNames from "classnames";
import * as React from "react";
import { useIntl } from "react-intl";

export interface IDownloadSqlPlanButtonProps {
  className?: string;
  planXml: string;
}

const useStyles = makeStyles((theme) => ({
  btn: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 9,
  },
}));

const DownloadSqlPlanButton: React.FC<IDownloadSqlPlanButtonProps> = ({ className, planXml }) => {
  const intl = useIntl();
  const classes = useStyles();

  const downloadUrl = React.useMemo<string>(() => {
    const blob = new Blob([planXml], { type: "text/xml" });
    return URL.createObjectURL(blob);
  }, [planXml]);
  React.useEffect(() => {
    // Revoke the object URL on cleanup
    return () => URL.revokeObjectURL(downloadUrl);
  }, [downloadUrl]);

  const downloadName = `tmp_${new Date().getUTCMilliseconds()}.sqlplan`;
  return (
    <Button
      className={classNames(classes.btn, className)}
      download={downloadName}
      href={downloadUrl}
      size="small"
      startIcon={<DownloadIcon />}
      variant="contained"
    >
      {intl.formatMessage({ id: "downloadPlan" })}
    </Button>
  );
};

export default DownloadSqlPlanButton;
