import * as React from "react";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import FocusedDialogContext from "./utils/FocusedDialogContext";
import useStyles from "./utils/styles";

export interface IFocusedDialogProps extends DialogProps {
  // making it required here as it's needed by context
  onClose: () => void;
}

const SlideTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
SlideTransition.displayName = "SlideTransition";

const FocusedDialog: React.FC<IFocusedDialogProps> = ({ onClose, children, ...props }) => {
  const classes = useStyles();

  return (
    <Dialog PaperProps={{ className: classes.root }} TransitionComponent={SlideTransition} fullScreen {...props}>
      <FocusedDialogContext.Provider value={{ onClose }}>{children}</FocusedDialogContext.Provider>
    </Dialog>
  );
};

export default FocusedDialog;
