import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { pd } from "pretty-data";
import * as React from "react";
import NoDataIndicator from "../../../../../../components/NoDataIndicator";
import { usePlanContext } from "../../../../../../contexts/planContext";
import DownloadSqlPlanButton from "../DownloadSqlPlanButton";

interface IPlanXMLProps {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(),
  },
  code: {
    fontSize: theme.typography.queryText.fontSize,
    fontWeight: theme.typography.queryText.fontWeight,
    letterSpacing: theme.typography.queryText.letterSpacing,
  },
  codeContainer: {
    position: "relative",
  },
  copyButton: {
    position: "absolute",
    right: "1.445rem",
    top: "3.3rem",
  },
  pre: {
    margin: 0,
  },
  root: {
    padding: theme.spacing(),
  },
}));

const PlanXML: React.FunctionComponent<IPlanXMLProps> = ({ className }) => {
  const classes = useStyles();
  const { rawXML } = usePlanContext();
  const prettyXML = React.useMemo(() => pd.xml(rawXML || ""), [rawXML]);

  if (prettyXML === "") {
    return <NoDataIndicator className={className} />;
  } else {
    return (
      <div className={classNames(className, classes.codeContainer)}>
        <pre>
          <code className={classes.code}>{prettyXML}</code>
        </pre>
        <DownloadSqlPlanButton planXml={rawXML} />
      </div>
    );
  }
};

export default PlanXML;
