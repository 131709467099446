import * as React from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { useRouteMatch } from "react-router";

import { fetchTopology, IFetchTopologyResponse } from "./fetchTopology";
import { IUserTenant } from "../../api/AccountService/AccountService";
import FullScreenLoad from "../../components/FullScreenLoad";
import { useUserContext } from "../../contexts/userContext";
import { useAsync } from "../../hooks/Async";
import { makeTopology } from "./utils";
import { ITopology } from "./types";

const TopologyContext = React.createContext<ITopology | undefined>(undefined);
export default TopologyContext;

export const TopologyProvider: React.FC = ({ children }) => {
  const client = useApolloClient();
  const { tenants } = useUserContext();
  const promiseFn = React.useCallback(async () => {
    const tenantPromises = tenants
      .filter((x) => x.state === "ready")
      .map(async (x) => {
        try {
          return await fetchTopology(client, x);
        } catch (e) {
          if (e instanceof Error) {
            return [x.id, e] as const;
          } else {
            throw e;
          }
        }
      });
    const results = await Promise.all(tenantPromises);
    const fulfilled = results.filter((x): x is IFetchTopologyResponse => !Array.isArray(x));
    const tenantErrors = results.filter((x): x is [IUserTenant["id"], Error] => Array.isArray(x));
    return {
      devices: fulfilled.flatMap((x) => x.devices),
      eventSourceConnections: fulfilled.flatMap((x) => x.eventSourceConnections),
      groups: fulfilled.flatMap((x) => x.groups),
      tenantErrors: new Map<IUserTenant["id"], Error>(tenantErrors),
    };
  }, [client, tenants]);
  const { data, error, isLoading, reload } = useAsync(promiseFn);

  const contextValue = React.useMemo<ITopology>(() => {
    return makeTopology({
      devices: data?.devices ?? [],
      eventSourceConnections: data?.eventSourceConnections ?? [],
      groups: data?.groups ?? [],
      reload,
      tenantErrors: data?.tenantErrors ?? new Map(),
    });
  }, [data, reload]);

  if (error) {
    throw error;
  } else if (isLoading) {
    return <FullScreenLoad disableFadeAnimation message="loadingTargets" />;
  } else {
    return <TopologyContext.Provider value={contextValue}>{children}</TopologyContext.Provider>;
  }
};

export function useTopology(global: boolean = false): ITopology {
  const topology = React.useContext(TopologyContext);
  if (!topology) {
    throw new Error(`useTopology can only be used in a descendant of TopologyProvider`);
  }
  const currentTenantId = useRouteMatch<{ tenantId: string }>("/:tenantId")?.params.tenantId ?? "";

  const scopedTopology = React.useMemo(
    () =>
      makeTopology({
        devices: topology.devices.filter((d) => d.tenantId === currentTenantId),
        eventSourceConnections: topology.eventSourceConnections.filter((e) => e.tenantId === currentTenantId),
        groups: topology.groups.filter((g) => g.tenantId === currentTenantId),
        reload: topology.reload,
        tenantErrors: topology.tenantErrors,
      }),
    [currentTenantId, topology],
  );

  return global ? topology : scopedTopology;
}
