import * as React from "react";
import type { IStorageEntity } from "../../features/Storage/types";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { useAllStorageEntities } from "../../features/Storage/useStorageEntities";
import { MoRef } from "../../utilities/TopologyUtility";
import { ITopologyItemDevice, ITopologyItemEventSourceConnection } from "../../components/TopologyContext";
import StorageEntityMap from "../../features/Storage/StorageEntityMap";
import FeatureLoadingSpinner from "../../components/FeatureLoadingSpinner";

export interface IStorageContext {
  selectedEntity: IStorageEntity;
  storageEntityMap: StorageEntityMap;
  getUrlForEntity: (entityId: string) => string;
  isEntitySelected: (entityId?: string) => boolean;
  target: ITopologyItemEventSourceConnection | ITopologyItemDevice;
}

export interface IStorageContextProviderProps {
  currentTarget: ITopologyItemEventSourceConnection | ITopologyItemDevice;
}

const StorageContext = React.createContext<IStorageContext | null>(null);

const StorageContextProvider: React.FC<IStorageContextProviderProps> = ({ currentTarget, children }) => {
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const queryParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
  const currentTargetMoRef = React.useMemo<MoRef>(() => MoRef.fromTopologyItem(currentTarget), [currentTarget]);
  const { data: entityMap, isLoading, error } = useAllStorageEntities(currentTargetMoRef);

  const selectedEntity: IStorageEntity | undefined = entityMap?.getEntityById(queryParams.get("entity") ?? "");

  const getUrlForEntity = React.useCallback(
    (entityId: string) => {
      const wouldBeQueryParams = new URLSearchParams(queryParams);
      wouldBeQueryParams.set("entity", entityId);
      return `${match.url}?${wouldBeQueryParams.toString()}`;
    },
    [queryParams, match.url],
  );

  const isEntitySelected = React.useCallback(
    (entityId?: string) => {
      return Boolean(entityId && selectedEntity && selectedEntity.id === entityId);
    },
    [selectedEntity],
  );

  React.useEffect(() => {
    if (entityMap && !selectedEntity) {
      history.replace(getUrlForEntity(entityMap.controllers[0].id));
    }
  }, [entityMap, history, selectedEntity, getUrlForEntity]);

  if (error) {
    throw error;
  } else if (isLoading || !selectedEntity || !entityMap) {
    return <FeatureLoadingSpinner />;
  } else {
    return (
      <StorageContext.Provider
        value={{
          getUrlForEntity,
          isEntitySelected,
          selectedEntity,
          storageEntityMap: entityMap,
          target: currentTarget,
        }}
      >
        {children}
      </StorageContext.Provider>
    );
  }
};

const useStorageContext = (): IStorageContext => {
  const storageContext = React.useContext(StorageContext);

  if (!storageContext) {
    throw new Error("Cannot use useStorageContext outside of a StorageContextProvider.");
  } else {
    return storageContext;
  }
};

export { StorageContextProvider, useStorageContext, StorageContext };
