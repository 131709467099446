import * as React from "react";
import { LinePath, Circle } from "@visx/shape";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { Threshold } from "@visx/threshold";
import { Point } from "@visx/point";
import { Group } from "@visx/group";
import { localPoint } from "@visx/event";
import { scaleTime, scaleLinear } from "@visx/scale";
import { ParentSize } from "@visx/responsive";
import { useTooltip } from "@visx/tooltip";
import { Grid } from "@visx/grid";
import { DateTime } from "luxon";
import { bisector } from "d3-array";
import { IDiskChartProps, StorageEntityType } from "../../types";
import { makeStyles } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
import ForecastChartTooltip, { isForecastedDataTooltip } from "./ForecastChartTooltip";
import { formatDigitalStorage } from "../../../../utilities/Formatters";
import { makeFormatDateAxis } from "../../../../utilities/ChartUtility";
import { useStorageContext } from "../../../../contexts/storageContext";
import { useForecastData } from "./useForecastData";
import { IMetricChartSeriesOptions, useMetrics } from "../../../../components/MetricChart";
import { IDateRange } from "../../../../components/DateContext";
import { LoadingIndicator } from "@sentryone/material-ui";
import NoDataIndicator from "../../../../components/NoDataIndicator";
import { formatForecastExhaustionDate } from "../Utilities/StorageUtilities";

export interface IDataPoint {
  timestamp: number;
  value: number;
}
export interface IForecastDataPoint extends IDataPoint {
  high: number;
  low: number;
}

const FREE_SPACE_LEGEND_WIDTH = 82;
const TOTAL_LEGEND_WIDTH = 411;
const margin = { bottom: 70, left: 50, right: 30, top: 20 };
const bisect = bisector<IDataPoint, Date>((x) => x.timestamp).center;

interface IGetPointFromDataResult {
  dataPoint: IDataPoint | null;
  coords: Point;
}

const useStyles = makeStyles(() => ({
  chartSvg: {
    color: "#333",
  },
  tooltipCircle: {
    transition: "fill 400ms",
  },
  verticalAxis: {
    stroke: "var(--visx-grid-line-color)",
  },
}));

const ForecastChart: React.FC<IDiskChartProps> = ({ className, setSubHeaderText }) => {
  const classes = useStyles();
  const intl = useIntl();
  const tooltip = useTooltip<IDataPoint>();
  const stickyTooltip = useTooltip<IDataPoint>();
  const tooltipContainerRef = React.useRef<SVGSVGElement>(null);

  React.useEffect(() => {
    const root = document.documentElement.style;
    // set colors for chart
    root.setProperty("--visx-grid-line-color", "#CCC");
    root.setProperty("--visx-threshold-color", "#919191");
    root.setProperty("--visx-blue-line-color", "#4788C0");
    root.setProperty("--visx-orange-line-color", "#E9B535");

    return () => {
      root.removeProperty("--visx-grid-line-color");
      root.removeProperty("--visx-threshold-color");
      root.removeProperty("--visx-blue-line-color");
      root.removeProperty("--visx-orange-line-color");
    };
  }, []);

  const { selectedEntity, target } = useStorageContext();
  const metricOptions: IMetricChartSeriesOptions[] = React.useMemo(
    () => [
      {
        axisLabel: null,
        chartType: "line",
        color: "var(--visx-blue-line-color)",
        instance: selectedEntity.name,
        label: "Free Space",
        metric: "sqlserver.disk.freeSpace",
      },
    ],
    [selectedEntity],
  );
  const dateRange = React.useMemo(() => {
    //we want to request the last 1 year of free space data because that matches the desktop client
    const today = new Date();
    const startDate = new Date().setDate(today.getDate() - 365);
    const dateRange: IDateRange = { from: new Date(startDate), to: today };
    return dateRange;
  }, []);

  const { isLoading: metricIsLoading, error: metricError, series: metricData } = useMetrics({
    dateRange,
    metrics: metricOptions,
    target,
  });
  const { data: forecastData, error: forecastError, isLoading: forecastIsLoading } = useForecastData(
    target,
    selectedEntity.id,
  );

  // Logic to show subheader text above chart
  React.useEffect(() => {
    if (setSubHeaderText) {
      if (!metricData[0] || !metricData[0].points || metricData[0].points.length === 0) {
        //We don't need to show subheader when we have no data recevied from API
        setSubHeaderText("");
      } else {
        if (selectedEntity.type === StorageEntityType.LogicalVolume) {
          if (selectedEntity.forecastedExhaustionDate) {
            setSubHeaderText(
              `${intl.formatMessage({ id: "forecastedExhaustion" })}: ${formatForecastExhaustionDate(
                selectedEntity.forecastedExhaustionDate,
                intl,
              )}`,
            );
          } else {
            setSubHeaderText(intl.formatMessage({ id: "noForeacstDataMsg" }));
          }
        }
      }
    }
  });

  if (metricError) {
    throw metricError; //this will be captured by the chart component's error boundary
  } else if (forecastError) {
    throw forecastError; //this will be captured by the chart component's error boundary
  } else if (metricIsLoading || forecastIsLoading) {
    return <LoadingIndicator className={className} variant="chart" />;
  } else if (!metricData[0] || !metricData[0].points || metricData[0].points.length === 0) {
    return <NoDataIndicator className={className} />;
  }

  const metricPoints = metricData[0].points.map((x) => ({
    timestamp: x.timestamp.valueOf(),
    value: x.value,
  }));
  //If we have no forecast data, we still want to render the chart and show the historical disk space data
  let forecastPoints: Array<IForecastDataPoint> = [];
  if (forecastData?.some((x) => x.upperBound >= 0)) {
    const newestMetricPoint = metricPoints.reduce((max, point) => (max.timestamp > point.timestamp ? max : point));
    //we want to start the forecast data with a point that matches the last historical data point to make the lines intersect and look continuous
    forecastPoints = [
      {
        high: newestMetricPoint.value,
        low: newestMetricPoint.value,
        timestamp: newestMetricPoint.timestamp,
        value: newestMetricPoint.value,
      },
    ].concat(
      forecastData
        .map((x) => ({
          high: x.upperBound,
          low: x.lowerBound,
          timestamp: DateTime.fromISO(x.forecastTimestamp).valueOf(),
          value: x.forecastValue,
        }))
        //we only want points with a positive upper bound value, otherwise the entire forecast would be under the x-axis
        .filter((x) => x.high >= 0)
        .sort((x, y) => x.timestamp - y.timestamp),
    );
  }
  const forecastChartPoints: Array<IDataPoint> = [...metricPoints, ...forecastPoints];

  // the timeScale and valueScale are used to determine the minimum and maximum axis values
  const timeScale = scaleTime<number>({
    domain: [
      Math.min(...metricPoints.map((x) => x.timestamp)),
      Math.max(
        ...forecastPoints.filter((x) => x.high > 0).map((x) => x.timestamp),
        ...metricPoints.filter((x) => x.value > 0).map((x) => x.timestamp),
      ),
    ],
    nice: false,
  });
  const timeDomain = timeScale.domain();
  const dateFormatter = makeFormatDateAxis({ from: timeDomain[0], to: timeDomain[1] });
  const valueScale = scaleLinear<number>({
    domain: [0, Math.max(...[...forecastPoints.map((x) => x.high), ...metricPoints.map((x) => x.value)])],
    nice: true,
  });

  function getPointFromData(
    event: React.MouseEvent<SVGGElement, MouseEvent>,
    data: Array<IDataPoint>,
  ): IGetPointFromDataResult | null {
    const coords = localPoint(event);
    if (!coords) {
      return null;
    }

    const x0 = timeScale.invert(coords.x - margin.left);
    const index = bisect(data, x0, 1);
    return {
      coords,
      dataPoint: data[index],
    };
  }

  const handleMouseMove = (event: React.MouseEvent<SVGGElement, MouseEvent>): void => {
    const data = getPointFromData(event, forecastChartPoints);
    if (data && data.dataPoint) {
      tooltip.showTooltip({
        tooltipData: data.dataPoint,
        tooltipLeft: data.coords.x,
        tooltipTop: data.coords.y,
      });
    }
  };

  const handleMouseLeave = (): void => {
    tooltip.hideTooltip();
  };

  const handleClick = (event: React.MouseEvent<SVGGElement, MouseEvent>): void => {
    event.stopPropagation();
    const data = getPointFromData(event, forecastChartPoints);
    if (data && data.dataPoint) {
      stickyTooltip.showTooltip({
        tooltipData: data.dataPoint,
        tooltipLeft: data.coords.x,
        tooltipTop: data.coords.y,
      });
    }
  };

  const handleOutsideClick = (): void => {
    stickyTooltip.hideTooltip();
  };

  const tooltipDataIsForecastData = isForecastedDataTooltip(tooltip);
  return (
    <>
      <ParentSize
        className={className}
        data-testid="ForecastChart-Container"
        onClick={handleOutsideClick}
        parentSizeStyles={{ inset: 0, position: "absolute" }}
      >
        {({ width, height }) => {
          const xMax = width - margin.left - margin.right;
          const yMax = height - margin.top - margin.bottom;
          timeScale.range([0, xMax]);
          valueScale.range([yMax, 0]);
          return (
            <svg className={classes.chartSvg} height={height} ref={tooltipContainerRef} width={width}>
              <defs>
                <pattern
                  height="9.5"
                  id="pattern"
                  patternTransform="rotate(45)"
                  patternUnits="userSpaceOnUse"
                  width="9.5"
                >
                  <line stroke="var(--visx-threshold-color)" strokeWidth="1" x1="0" x2="0" y="0" y2="9.5" />
                </pattern>
              </defs>
              <Group
                data-testid="ForecastChart-Area"
                left={margin.left}
                onClick={handleClick}
                onMouseLeave={handleMouseLeave}
                onMouseMove={handleMouseMove}
                pointerEvents="bounding-box"
                top={margin.top}
              >
                <AxisBottom
                  scale={timeScale}
                  tickFormat={(t) => (t instanceof Date ? dateFormatter(t) : dateFormatter(new Date(t.valueOf())))}
                  top={yMax}
                />
                <AxisLeft
                  axisLineClassName={classes.verticalAxis}
                  hideTicks
                  scale={valueScale}
                  tickFormat={(d) => formatDigitalStorage(d.valueOf())}
                />
                <Grid
                  height={yMax}
                  stroke="var(--visx-grid-line-color)"
                  width={xMax}
                  xScale={timeScale}
                  yScale={valueScale}
                />
                <Threshold
                  aboveAreaProps={{
                    fill: "url(#pattern)",
                    stroke: "var(--visx-threshold-color)",
                    strokeWidth: 1,
                  }}
                  belowAreaProps={{
                    fill: "url(#pattern)",
                    stroke: "var(--visx-threshold-color)",
                    strokeWidth: 1,
                  }}
                  clipAboveTo={0}
                  clipBelowTo={yMax}
                  data={forecastPoints}
                  id="threshold"
                  x={(d) => timeScale(d.timestamp)}
                  y0={(d) => valueScale(d.low)}
                  y1={(d) => valueScale(d.high)}
                />
                <LinePath
                  data={metricPoints}
                  stroke="var(--visx-blue-line-color)"
                  strokeWidth={2}
                  x={(d) => timeScale(d.timestamp)}
                  y={(d) => valueScale(d.value)}
                />
                <LinePath
                  data={forecastPoints}
                  defined={(d) => d.value > 0}
                  stroke="var(--visx-orange-line-color)"
                  strokeWidth={2}
                  x={(d) => timeScale(d.timestamp)}
                  y={(d) => valueScale(d.value)}
                />
                {tooltip.tooltipData && (
                  <>
                    {tooltipDataIsForecastData && (
                      <>
                        {
                          //this cast is safe because we verify the tooltip data is for forecast data
                          (tooltip.tooltipData as IForecastDataPoint).high >= 0 && (
                            <Circle
                              cx={timeScale((tooltip.tooltipData as IForecastDataPoint).timestamp)}
                              cy={valueScale((tooltip.tooltipData as IForecastDataPoint).high)}
                              fill="var(--visx-threshold-color)"
                              r="6"
                              stroke="white"
                              strokeWidth="2"
                            />
                          )
                        }
                        {
                          //this cast is safe because we verify the tooltip data is for forecast data
                          (tooltip.tooltipData as IForecastDataPoint).low >= 0 && (
                            <Circle
                              cx={timeScale((tooltip.tooltipData as IForecastDataPoint).timestamp)}
                              cy={valueScale((tooltip.tooltipData as IForecastDataPoint).low)}
                              fill="var(--visx-threshold-color)"
                              r="6"
                              stroke="white"
                              strokeWidth="2"
                            />
                          )
                        }
                      </>
                    )}
                    {tooltip.tooltipData.value >= 0 && (
                      <Circle
                        className={classes.tooltipCircle}
                        cx={timeScale(tooltip.tooltipData.timestamp)}
                        cy={valueScale(tooltip.tooltipData.value)}
                        fill={
                          tooltipDataIsForecastData ? "var(--visx-orange-line-color)" : "var(--visx-blue-line-color)"
                        }
                        r="6"
                        stroke="white"
                        strokeWidth="2"
                      />
                    )}
                  </>
                )}
              </Group>
              {/* We center the legend in the container depending on if we are showing the forecasted legend items or just the free space legend item*/}
              <svg
                x={(width - (forecastPoints.length ? TOTAL_LEGEND_WIDTH : FREE_SPACE_LEGEND_WIDTH)) / 2}
                y={height - 30}
              >
                <svg x={0}>
                  <circle cx="8" cy="11" fill="var(--visx-blue-line-color)" r="7" />
                  <text x={20} y={15}>
                    {intl.formatMessage({ id: "freeSpace" })}
                  </text>
                </svg>
                {
                  //we want to hide the forecast legend item if there is no forecast data
                  !!forecastPoints.length && (
                    <>
                      <svg x={100}>
                        <circle cx="8" cy="11" fill="var(--visx-orange-line-color)" r="7" />
                        <text x={20} y={15}>
                          {intl.formatMessage({ id: "forecastedFreeSpace" })}
                        </text>
                      </svg>

                      <svg x={263}>
                        <rect
                          fill="url(#pattern)"
                          height="16"
                          stroke="var(--visx-threshold-color)"
                          strokeWidth="1"
                          width="16"
                          y="3"
                        />
                        <text x={22} y={15}>
                          {intl.formatMessage({ id: "predictionInterval70" })}
                        </text>
                      </svg>
                    </>
                  )
                }
              </svg>
            </svg>
          );
        }}
      </ParentSize>
      <ForecastChartTooltip
        data-testid="ForecastChart-Tooltip"
        tooltip={tooltip}
        tooltipContainerRef={tooltipContainerRef}
      />
      <ForecastChartTooltip
        data-testid="ForecastChart-Sticky-Tooltip"
        tooltip={stickyTooltip}
        tooltipContainerRef={tooltipContainerRef}
      />
    </>
  );
};

export default React.memo(ForecastChart);
