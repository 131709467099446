import * as React from "react";
import { IChartPlaceHolderProps } from "./types";

const ChartLoading: React.FunctionComponent<IChartPlaceHolderProps> = ({
  className,
}) => {
  return (
    <svg
      className={className}
      height="100%"
      preserveAspectRatio="none"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg">
      <rect fill="#F2F2F2" height="5" rx="2.5" width="29" x="25" y="73" />
      <circle cx="10" cy="75.5" fill="#FAFAFA" r="2" stroke="#FAFAFA" />
      <rect fill="#F2F2F2" height="5" rx="2.5" width="29" x="25" y="83" />
      <circle cx="10" cy="85.5" fill="#F2F2F2" r="2.5" />
      <rect fill="#F2F2F2" height="5" rx="2.5" width="29" x="25" y="93" />
      <circle cx="10" cy="95.5" fill="#E0E0E0" r="2.5" />
      <line stroke="#E0E0E0" x1="10" x2="10" y1="10" y2="66" />
      <rect fill="#FAFAFA" height="31" stroke="#FAFAFA" width="10" x="23" y="35.5" />
      <rect fill="#F2F2F2" height="37" stroke="#F2F2F2" width="11" x="49" y="29.5" />
      <rect fill="#E0E0E0" height="49" stroke="#E0E0E0" width="10" x="76" y="17.5" />
      <line stroke="#E0E0E0" x1="89.5" x2="9.5" y1="66.5" y2="66.5" />
    </svg>

  );
};

export default ChartLoading;
