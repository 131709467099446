import React from "react";
import MiniHealthScoreCard from "./MiniHealthScoreCard";

const HealthScoreItem: React.FC<{
  color: "green" | "red" | "gray",
  label: string,
  value: number,
}> = ({color, label, value}) => {
  return (
    <div style={{
      backgroundColor: color,
      borderRadius: '4px',
      color: 'white',
      display: 'flex',
      padding: '2px',
    }}>
      <div style={{
        backgroundColor: 'white',
        borderRadius: '3px 0 0 3px',
        color: color,
        flex: 1,
        fontSize: '0.8rem',
        textAlign: 'center',
        verticalAlign: 'middle',
      }}>{label}:</div>
      <div style={{
        minWidth: '30px',
        textAlign: 'center'
      }}>{value}</div>
    </div>
  );
}

const HealthScoreCard: React.FC<{
  data: {
    score: number,
    best: number,
    worst: number,
    total_targets: number,
  },
}> = ({data}) => {
  return (
      <div style={{boxShadow: 'grey 2px 2px 10px', height: '100%', lineHeight: '1'}}>
          <MiniHealthScoreCard score={data.score} score_size="large"></MiniHealthScoreCard>
          <div style={{
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            height: '50%',
            padding: '8px',
          }}>
            <HealthScoreItem color="green" label="Best" value={data.best}></HealthScoreItem>
            <HealthScoreItem color="red" label="Worst" value={data.worst}></HealthScoreItem>
            {data.total_targets > 1 && <HealthScoreItem color="gray" label="Total Targets" value={data.total_targets}></HealthScoreItem>}
          </div>
      </div>
  );
};

export default HealthScoreCard;