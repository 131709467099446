import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

const CustomDashboardIcon: React.FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M0 0V19H24V0H0ZM22 17H2V2H22V17ZM15.401 21L18 24H6L8.599 21H15.401Z"/>
      <path d="M4 6.3915V5L6.66667 7L10 4L16 7.939L20 5.978V7.1965L15.8827 9.191L10.1493 5.427L6.756 8.481L4 6.3915H4ZM16.606 10.031L15.7613 10.44L15.0047 9.943L10.2993 6.8545L7.78067 9.1215L6.84067 9.9675L5.80867 9.1845L4 7.813V15H20V8.387L16.606 10.031V10.031Z"/>
    </SvgIcon>
  );
};

export default CustomDashboardIcon;
