import * as React from "react";
import { IDateRange, useDateContext } from "../../../../components/DateContext";
import { IMetricChartProps } from "../../../../components/MetricChart";
import { ChartRange } from "../../../../components/recharts";
import {
  ITopologyItemDevice,
  ITopologyItemEventSourceConnection,
  TopologyObjectType,
} from "../../../../components/TopologyContext";
import { useStorageContext } from "../../../../contexts/storageContext";
import { useCurrentTarget } from "../../../../hooks/useCurrentTarget";
import { IStorageEntity } from "../../types";

export interface IStorageChartContext {
  readonly selectedEntity: IStorageEntity;
  readonly selectedRange: IMetricChartProps["selectedRange"];
  readonly dateRange: IDateRange;
  readonly setSelectedRange: IMetricChartProps["onSelectedRangeChange"];
  readonly target: ITopologyItemDevice | ITopologyItemEventSourceConnection;
}

const StorageChartContext = React.createContext<IStorageChartContext | null>(null);

const StorageChartContextProvider: React.FC = ({ children }) => {
  const { dateRange } = useDateContext();
  const { selectedEntity, target } = useStorageContext();
  const [selectedRange, setSelectedRange] = React.useState<ChartRange>([null, null]);

  React.useEffect(() => {
    setSelectedRange([null, null]);
  }, [selectedEntity]);

  return (
    <StorageChartContext.Provider
      value={{
        dateRange,
        selectedEntity,
        selectedRange,
        setSelectedRange,
        target,
      }}
    >
      {children}
    </StorageChartContext.Provider>
  );
};

const useStorageChartContext = (): IStorageChartContext => {
  const value = React.useContext(StorageChartContext);
  if (!value) {
    throw new Error("useStorageChartContext can only be used in a descendant of StorageChart");
  }
  return value;
};

export { StorageChartContextProvider, useStorageChartContext, StorageChartContext };
