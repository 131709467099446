export enum EventGroupHealthType {
  Severity = "Severity",
  Tags = "Tags",
  Waits = "Waits",
}

export enum HealthRegionKey {
  Low = "Low",
  Medium = "Medium",
  High = "High",
  Info = "Info",
  Critical = "Critical",
  Network = "Network",
  CPU = "CPU",
  Memory = "Memory",
  Disk = "Disk",
  Other = "Other",
}

export interface IConnectionUptime {
  downtimeIntervals: IDowntimeInterval[];
  uptimePercentage: number;
}

export interface IDowntimeInterval {
  duration: string;
  endDateUtc: Date;
  startDateUtc: Date;
}

export interface IHealthRegionMap {
  regions: {
    [key in keyof typeof HealthRegionKey]?: IRegionValue;
  };
}

export interface IHealthStatusItem {
  itemId: number;
  itemType: TopologyObjectType;
  objectId: string;
  score: number | null;
  scoreDelta: number;
}

export interface IHealthStatusRequest {
  calculateDelta?: boolean;
  endDateUtc: Date;
  eventGroupHealthType: EventGroupHealthType;
  objectId?: number;
  objectType: TopologyObjectType;
  returnAllChildrenOfType?: TopologyObjectType;
  rollupLevelMinutes?: number;
  scoreThreshold?: number;
  startDateUtc: Date;
}

export interface IRegionValue {
  key: HealthRegionKey;
  percentageOfMax: number;
  value: number;
}

export enum TopologyObjectType {
  device = "device",
  eventSourceConnection = "eventSourceConnection",
  global = "global",
  group = "group",
  site = "site",
}

export enum UnitOfMeasure {
  msSession = "msSession",
  notSpecified = "notSpecified",
  score = "score",
}
