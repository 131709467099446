import * as React from "react";
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

// This component will eventually be provided by moment & moment-range and can be replaced with that implementation.

export interface IFormattedDateRangeProps extends Intl.DateTimeFormatOptions {
  from: Date;
  to: Date;
}

/**
 * Renders a formatted version of the date range of from - to using moment and moment-range npm.
 */
const FormattedDateRange: React.FC<IFormattedDateRangeProps> = ({ from, to }) => {
  const start = moment(from, "MMM D YYYY, h:mm A");
  const end = moment(to, "MMM D YYYY, h:mm A");
  let dateFormat = "";

  if (start.year() === moment().year() && end.year() === moment().year()) {
    const startDateFormat = moment(from).format("MMM D, h:mm A");
    const endDateFormat = start.isSame(end, 'day') ? moment(to).format("h:mm A") : moment(to).format("MMM D, h:mm A");
    dateFormat = startDateFormat + " - " + endDateFormat;
  } else {
    dateFormat = moment(from).format("MMM D, YYYY, h:mm A") + " - " + moment(to).format("MMM D, YYYY, h:mm A");
  }
  return <>{dateFormat}</>;
};

export default FormattedDateRange;
