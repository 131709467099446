import * as React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import type { IPrincipalSelection, IUser, IUserGroup } from "../../types";
import SearchTextField from "../../../../components/SearchTextField";
import { filterPrincipal } from "../../utils";
import { useIntl } from "react-intl";
import Slide from "@material-ui/core/Slide";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { useFeatureFlag } from "../../../../contexts/featuresContext";

export interface IPrincipalListProps {
  handleSelection: (newSelection: IPrincipalSelection) => void;
  selectedPrincipal: IPrincipalSelection;
  onNewUserGroupClick: () => void;
  onNewUserClick: () => void;
  users: readonly IUser[];
  groups: readonly IUserGroup[];
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    transition: theme.transitions.create("height", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeOut,
    }),
  },
  groupListItemText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  listContainer: {
    width: "100%",
  },
  principalListItem: {
    borderRadius: 0,
    gap: `${theme.spacing(0.5)}px`,
  },
  userListItemText: {
    "& span": {
      "&:first-child": {
        marginRight: theme.spacing(0.5),
        maxWidth: "50%",
      },
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
}));

const PrincipalList: React.FC<IPrincipalListProps> = ({
  selectedPrincipal,
  handleSelection,
  onNewUserClick,
  onNewUserGroupClick,
  users,
  groups,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const hasUserAndGroupCreationFeatureFlag = useFeatureFlag("userAndGroupCreation");
  const [principalsFilter, setPrincipalsFilter] = React.useState<string>("");

  const onSelection = (selection: IPrincipalSelection): void => {
    if (selection.category !== selectedPrincipal.category || selection.id !== selectedPrincipal.id)
      handleSelection(selection);
  };

  const handleTabChange = (tabValue: "users" | "groups"): void => {
    handleSelection({ category: tabValue, id: tabValue === "users" ? users[0].id : groups[0].id });
  };

  return (
    <Paper className={classes.container} data-testid="usersList">
      <Tabs onChange={(_, value) => handleTabChange(value)} value={selectedPrincipal.category}>
        <Tab disabled={users.length <= 0} label={intl.formatMessage({ id: "users" })} value="users" />
        <Tab disabled={groups.length <= 0} label={intl.formatMessage({ id: "groups" })} value="groups" />
      </Tabs>
      {hasUserAndGroupCreationFeatureFlag && (
        <Box padding={2} paddingBottom={0}>
          <Button
            color="primary"
            fullWidth
            onClick={selectedPrincipal.category === "users" ? onNewUserClick : onNewUserGroupClick}
            variant="contained"
          >
            {selectedPrincipal.category === "users"
              ? intl.formatMessage({ id: "createNewUser" })
              : intl.formatMessage({ id: "createNewGroup" })}
          </Button>
        </Box>
      )}
      <Box padding={2} paddingBottom={0}>
        <SearchTextField
          aria-label={intl.formatMessage({ id: "filterUsersGroups" })}
          fullWidth
          hiddenLabel
          onChange={(e) => setPrincipalsFilter(e.target.value)}
          placeholder={intl.formatMessage({ id: "filterMsg" })}
          value={principalsFilter}
        />
      </Box>
      {/* Box needed to hide overflowed content as it is entering/exiting */}
      <Box height="100%" overflow="hidden" position="relative">
        <Slide appear={false} direction="left" in={selectedPrincipal.category === "users"} unmountOnExit>
          <List className={classes.listContainer} dense={false}>
            {users
              .filter((u) => filterPrincipal(u, principalsFilter))
              .map((user) => (
                <Tooltip
                  enterDelay={500}
                  enterNextDelay={500}
                  key={user.id}
                  title={`${user.firstName} ${user.lastName} - ${
                    user.email ?? intl.formatMessage({ id: "notAvailable" })
                  }`}
                >
                  <ListItem
                    aria-pressed={selectedPrincipal.category === "users" && user.id === selectedPrincipal.id}
                    button
                    className={classes.principalListItem}
                    onClick={() => onSelection({ category: "users", id: user.id })}
                    selected={selectedPrincipal.category === "users" && user.id === selectedPrincipal.id}
                  >
                    <ListItemText
                      classes={{
                        root: classes.userListItemText,
                      }}
                      primary={`${user.firstName} ${user.lastName}`}
                      primaryTypographyProps={{ component: "span" }}
                      secondary={user.email ?? intl.formatMessage({ id: "notAvailable" })}
                      secondaryTypographyProps={{ component: "span" }}
                    />
                  </ListItem>
                </Tooltip>
              ))}
          </List>
        </Slide>
        <Slide appear={false} direction="right" in={selectedPrincipal.category === "groups"} unmountOnExit>
          <List className={classes.listContainer} dense={false}>
            {groups
              .filter((g) => filterPrincipal(g, principalsFilter))
              .map((group) => (
                <Tooltip enterDelay={500} enterNextDelay={500} key={group.id} title={group.name}>
                  <ListItem
                    aria-pressed={selectedPrincipal.category === "groups" && group.id === selectedPrincipal.id}
                    button
                    className={classes.principalListItem}
                    key={group.id}
                    onClick={() => onSelection({ category: "groups", id: group.id })}
                    selected={selectedPrincipal.category === "groups" && group.id === selectedPrincipal.id}
                  >
                    <ListItemText classes={{ primary: classes.groupListItemText }} primary={group.name} />
                    <Chip label={group.usersCount} size="small" />
                  </ListItem>
                </Tooltip>
              ))}
          </List>
        </Slide>
      </Box>
    </Paper>
  );
};

export default PrincipalList;
