import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { useIntl } from "react-intl";
import { NoDataIcon } from "../icons";

const useStyles = makeStyles((theme) => ({
  background: {
    color: theme.typography.h5.color,
    fill: "none",
    height: "auto",
    maxHeight: "100%",
    width: "125px",
  },
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    placeSelf: "center",
  },
}));

interface INoDataIndicatorProps {
  className?: string;
  style?: React.CSSProperties;
}

export const NoDataIndicator: React.FC<INoDataIndicatorProps> = ({ className, style }) => {
  const intl = useIntl();
  const classes = useStyles();
  return (
    <div className={classNames(classes.root, className)} style={style}>
      <NoDataIcon className={classes.background} />
      <Typography component="span" variant="body1">
        {intl.formatMessage({ id: "noDataToDisplay" })}
      </Typography>
    </div>
  );
};

export const RestrictedDataIndicator: React.FC<INoDataIndicatorProps> = ({ className, style }) => {
  const intl = useIntl();
  const classes = useStyles();
  return (
    <div className={classNames(classes.root, className)} style={style}>
      <NoDataIcon className={classes.background} />
      <Typography component="span" variant="body1">
        {intl.formatMessage({ id: "thisDataIsRestricted" })}
      </Typography>
    </div>
  );
};

export default NoDataIndicator;
