import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import * as React from "react";
import { useIntl } from "react-intl";
import { Redirect, Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import ErrorBoundary from "../../../../components/ErrorBoundary";
import FeatureDisabled from "../../../../components/FeatureDisabled";
import FocusedDialog, { FocusedDialogContent } from "../../../../components/FocusedDialog";
import { useCurrentTarget } from "../../../../hooks/useCurrentTarget";
import Blocking from "../../../Blocking";
import Deadlocks from "../../../Deadlocks";
import PerformanceAnalysis from "../../../PerformanceAnalysis";
import Storage from "../../../Storage";
import TargetHealth from "../../../../ui/TargetHealth/TargetHealth";
import TempDb from "../../../TempDb";
import TopSql from "../../../TopSql";
import TargetDialogHeader from "./TargetDialogHeader";

const useStyles = makeStyles((theme) => ({
  noDataPaper: {
    padding: theme.spacing(5),
  },
}));

const TargetDialog: React.FC = () => {
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const currentMatch = useRouteMatch();
  const targetMatch = useRouteMatch<{ targetId: string }>(`${currentMatch.url}/targets/:targetId`);
  const target = useCurrentTarget();

  return (
    <FocusedDialog onClose={() => history.push(currentMatch.url)} open={!!targetMatch}>
      <TargetDialogHeader />
      <FocusedDialogContent>
        <ErrorBoundary variant="generic">
          {targetMatch && target && (
            <Switch>
              {target.features.blocking && (
                <Route path={`${targetMatch.url}/blocking`}>
                  <Blocking key={target.objectId} />
                </Route>
              )}
              {target.features.deadlocks && (
                <Route path={`${targetMatch.url}/deadlocks`}>
                  <Deadlocks key={target.objectId} />
                </Route>
              )}
              {target.features.health && (
                <Route path={`${targetMatch.url}/health`}>
                  <TargetHealth key={target.objectId} />
                </Route>
              )}
              {target.features.performance && (
                <Route path={`${targetMatch.url}/performance`}>
                  <PerformanceAnalysis key={target.objectId} />
                </Route>
              )}
              {target.features.storage && (
                <Route path={`${targetMatch.url}/storage`}>
                  <Storage key={target.objectId} />
                </Route>
              )}
              {target.features.tempdb && (
                <Route path={`${targetMatch.url}/tempdb`}>
                  <TempDb key={target.objectId} />
                </Route>
              )}
              {target.features.topsql && (
                <Route path={`${targetMatch.url}/topsql`}>
                  <TopSql key={target.objectId} />
                </Route>
              )}
              <Redirect
                to={{
                  pathname: `${targetMatch.url}/health`,
                  search: location.search,
                }}
              />
            </Switch>
          )}
          {!target && (
            <Paper className={classes.noDataPaper}>
              <FeatureDisabled
                action={
                  <Button
                    color="primary"
                    href="https://support.sentryone.com/s/"
                    rel="noopener noreferrer"
                    startIcon={<OpenInNewIcon />}
                    target="_blank"
                    variant="text"
                  >
                    {intl.formatMessage({ id: "visitSupport" })}
                  </Button>
                }
                message={[
                  intl.formatMessage({ id: "noAccessOrDoesNotExist" }),
                  intl.formatMessage({ id: "contactAdminOrSupport" }),
                ]}
                title={intl.formatMessage({ id: "noDataForTargetAvailable" })}
              />
            </Paper>
          )}
        </ErrorBoundary>
      </FocusedDialogContent>
    </FocusedDialog>
  );
};

export default TargetDialog;
