import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import * as React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import SearchTextField from "../../../../components/SearchTextField";
import DocumentTitle from "../../../../components/DocumentTitle";
import NoDigestDashboardsIcon from "../../../../components/icons/NoDigestDashboardsIcon";
import { IDigestDashboard, useAllDashboards, useDashboardActions } from "../../useDashboards";
import CreateDashboard from "../CreateDashboard";
import { ICreateDashboardParams } from "../CreateDashboard/CreateDashboard";
import DashboardConfirmationDialog from "../DashboardConfirmationDialog";
import DashboardCard from "./DashboardCard";

const useStyles = makeStyles((theme) => ({
  dataGridContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: `${theme.spacing(2)}px`,
    marginTop: theme.spacing(2),
  },
  headerActions: {
    alignItems: "center",
    display: "flex",
  },
  headerArea: {
    "&>h1": {
      fontSize: "1.45rem",
      letterSpacing: "0.5px",
    },
    alignItems: "center",
    display: "flex",
    height: theme.spacing(9),
    justifyContent: "space-between",
  },
  input: {
    paddingBottom: "10.5px",
    paddingTop: "10.5px",
  },
  noDashboardsIcon: {
    color: theme.palette.text.secondary,
    display: "block",
    fill: "none",
    height: "80px",
    margin: "auto",
    width: "80px",
  },
  noDataGridContainer: {
    display: "grid",
    height: "50vh",
    placeItems: "center",
  },
  root: {
    padding: theme.spacing(2, 4),
  },
  searchFilter: {
    marginRight: theme.spacing(2),
  },
}));

const DashboardDigest: React.FC = () => {
  const classes = useStyles();
  const intl = useIntl();
  const [createDashboardModalOpen, setCreateDashboardModalOpen] = React.useState<boolean>(false);
  const [deleteDashboardId, setDashboardDeleteId] = React.useState<string | null>(null);

  const [filter, setFilter] = React.useState<string>("");
  const history = useHistory();
  const match = useRouteMatch();

  const { data: allDashboards = [], error, isLoading } = useAllDashboards();
  const { cloneDashboard, createDashboard, deleteDashboard } = useDashboardActions();

  const filteredDashboards = React.useMemo<readonly IDigestDashboard[]>(() => {
    if (filter && allDashboards.length > 0) {
      return allDashboards.filter((d) => d.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()));
    } else {
      return allDashboards;
    }
  }, [allDashboards, filter]);

  async function handleAddingDashboard({ cloneId, name }: ICreateDashboardParams): Promise<void> {
    const newDashboard = cloneId
      ? await cloneDashboard(cloneId, { name })
      : await createDashboard({ name, widgets: [] });
    history.push({
      pathname: `${match.url}/${newDashboard.id}`,
      search: location.search,
    });
  }

  if (error) {
    throw error;
  } else {
    return (
      <div className={classes.root}>
        <DocumentTitle title="digest" />
        <div className={classes.headerArea}>
          <Typography color="textSecondary" component="h1" variant="h5">
            {intl.formatMessage({ id: "digest" })}
          </Typography>
          <div className={classes.headerActions}>
            <SearchTextField
              className={classes.searchFilter}
              id="filterDashboardsTextField"
              label={intl.formatMessage({ id: "filterDashboards" })}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilter(e.target.value)}
            />
            {allDashboards.length > 0 && (
              <Button color="primary" onClick={() => setCreateDashboardModalOpen(true)} variant="contained">
                {intl.formatMessage({ id: "createDashboard" })}
              </Button>
            )}
          </div>
        </div>
        <Divider />
        <div className={classes.dataGridContainer}>
          {isLoading
            ? Array.from({ length: 7 }, () => (
                <Skeleton height="162px" key={Math.random()} role="progressbar" variant="rect" width="17rem" />
              ))
            : filteredDashboards.map((d) => (
                <DashboardCard dashboard={d} key={d.id} onDeleteDashboard={() => setDashboardDeleteId(d.id)} />
              ))}
        </div>
        {!isLoading && allDashboards.length === 0 && (
          <div className={classes.noDataGridContainer}>
            <Grid alignItems="center" container direction="column" justify="center" spacing={3}>
              <Grid item>
                <NoDigestDashboardsIcon className={classes.noDashboardsIcon} />
                <Typography color="textSecondary" component="h1" variant="h5">
                  {intl.formatMessage({ id: "noDashboardsInDigest" })}
                </Typography>
              </Grid>
              <Grid item>
                <Button color="primary" onClick={() => setCreateDashboardModalOpen(true)} variant="contained">
                  {intl.formatMessage({ id: "createDashboard" })}
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
        <CreateDashboard
          customDashboards={allDashboards}
          onClose={() => setCreateDashboardModalOpen(false)}
          onCreateDashboard={handleAddingDashboard}
          open={createDashboardModalOpen}
        />
        <DashboardConfirmationDialog
          handleClose={(confirmed) => {
            if (confirmed && deleteDashboardId) {
              deleteDashboard(deleteDashboardId);
            }
            setDashboardDeleteId(null);
          }}
          open={deleteDashboardId ? "delete" : undefined}
        />
      </div>
    );
  }
};

export default DashboardDigest;
