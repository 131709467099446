import { useRouteMatch } from "react-router";
import { useTopology } from "../../components/TopologyContext";
import { useUserContext } from "../../contexts/userContext";
import { IUserTenant } from "../../api/AccountService/AccountService";

export interface ICurrentTenant {
  tenant: IUserTenant;
  error: Error | undefined;
}

export function useCurrentTenant(): ICurrentTenant | null {
  const {
    userInfo: { tenants },
  } = useUserContext();
  const { tenantErrors } = useTopology();

  const tenantMatch = useRouteMatch<{ tenantId: string }>("/:tenantId");
  const tenant = tenants.find((tenant) => tenant.id === tenantMatch?.params.tenantId) ?? null;

  return tenant
    ? {
        error: tenantErrors.get(tenant.id),
        tenant,
      }
    : null;
}
