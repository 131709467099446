import Chip from "@material-ui/core/Chip";
import * as React from "react";
import { FormatDateOptions, useIntl } from "react-intl";
import { IDateRange } from "../../../components/DateContext";

interface IChartFilterChipProps {
  onRemove: () => void;
  value: IDateRange;
}

const formatOptions = Object.freeze<FormatDateOptions>({
  day: "numeric",
  hour: "2-digit",
  minute: "numeric",
  second: "numeric",
  year: "numeric",
});

const ChartFilterChip: React.FunctionComponent<IChartFilterChipProps> = ({ onRemove, value }) => {
  const intl = useIntl();
  const from = intl.formatDate(value.from, formatOptions);
  const to = intl.formatDate(value.to, formatOptions);
  return <Chip color="primary" label={`${from} - ${to}`} onDelete={onRemove} title="Chart filter" />;
};

export default ChartFilterChip;
