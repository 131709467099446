import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { CpuChart, DiskIOChart, NetworkChart, SystemMemoryChart } from "../HostCharts";
import ChartCard from "./ChartCard";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridAutoRows: theme.spacing(45),
    gridGap: theme.spacing(2),
    gridTemplateColumns: "1fr",
  },
}));

const WindowsPerformanceAnalysis: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ChartCard chartComponent={NetworkChart} title="network" />
      <ChartCard chartComponent={CpuChart} title="cpu" />
      <ChartCard chartComponent={SystemMemoryChart} title="systemMemory" />
      <ChartCard chartComponent={DiskIOChart} title="disk" />
    </div>
  );
};

export default WindowsPerformanceAnalysis;
