import { DateTime, Duration } from "luxon";
import { formatDate } from "../components/FormattedDate";
import { IDateRange } from "../components/DateContext";

interface IDateRage {
  from: Date;
  to: Date;
}

interface ISize {
  height: number;
  width: number;
}

function getDateFormatSizeFactor(diff: Duration): number {
  if (diff.as("days") < 1) {
    return 1;
  } else if (diff.as("days") < 2) {
    return 2;
  } else {
    return 1;
  }
}

function getChartSizeFactor(size: ISize, totalPoints: number): number {
  if (size.width <= 480) {
    return totalPoints;
  } else if (size.width <= 650) {
    return totalPoints / 2;
  } else if (size.width <= 1080) {
    return totalPoints / 4;
  } else if (size.width <= 1440) {
    return totalPoints / 6;
  } else if (size.width <= 1920) {
    return totalPoints / 8;
  } else {
    return totalPoints / 10;
  }
}

export function getChartCategoryStep(visibleDates: IDateRage, size: ISize, rollupLevelMinutes: number): number {
  const from = DateTime.fromJSDate(visibleDates.from);
  const to = DateTime.fromJSDate(visibleDates.to);
  const diff = to.diff(from);
  const totalPoints = diff.as("minutes") / rollupLevelMinutes;
  const formatSizeFactor = getDateFormatSizeFactor(diff);
  const sizeFactor = getChartSizeFactor(size, totalPoints);
  return Math.floor(formatSizeFactor * sizeFactor);
}

function getDateFormatOptions(visibleDates: IDateRage): Intl.DateTimeFormatOptions {
  const from = DateTime.fromJSDate(visibleDates.from);
  const to = DateTime.fromJSDate(visibleDates.to);
  const diff = to.diff(from);
  if (diff.as("days") < 1) {
    return {
      hour: "2-digit",
      minute: "numeric",
    };
  } else if (diff.as("days") < 2) {
    return {
      day: "numeric",
      hour: "2-digit",
      minute: "numeric",
      month: "numeric",
      year: "numeric",
    };
  } else {
    return {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    };
  }
}

/**
 * Returns a function that accepts a Date and formats it based on the specified date range.
 * The returned function changes the format to fit the date range.
 * For example, small ranges will only return time, mid ranges will return a full format,
 * and large ranges will exclude time.
 * @param visibleDates The date range of the chart.
 * @returns Function that accepts a Date and formats it based on visibleDates.
 */
export function makeFormatDateAxis(visibleDates: IDateRage): (value: Date) => string {
  const options = getDateFormatOptions(visibleDates);
  return (value: Date) => formatDate(value, options);
}
