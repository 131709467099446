import * as React from "react";
import { LegendProps } from "recharts";
import { IMetricChartSeries } from "./types";

export interface IUseLegendResult {
  readonly legendProps: Partial<LegendProps>;
  isHidden(series: IMetricChartSeries): boolean;
  setHiddenSeries(series: readonly IMetricChartSeries[]): void;
  setSeries(series: readonly IMetricChartSeries[]): void;
  toggleSeries(series: IMetricChartSeries): void;
}

const context = React.createContext<IUseLegendResult | null>(null);
context.displayName = "Legends";

interface LegendState {
  hiddenKeys: ReadonlySet<string>;
  keys: ReadonlySet<string>;
}

type LegendActions =
  | { hiddenSeries: readonly IMetricChartSeries[]; type: "setHiddenSeries" }
  | { series: readonly IMetricChartSeries[]; type: "setSeries" }
  | { series: string | IMetricChartSeries; type: "toggleSeries" };

function reducer(prev: LegendState, action: LegendActions): LegendState {
  switch (action.type) {
    case "setHiddenSeries": {
      const hiddenKeys = new Set(action.hiddenSeries.filter((x) => x.points.length > 0).map((x) => x.key));
      return {
        ...prev,
        hiddenKeys,
      };
    }
    case "setSeries": {
      const keys = new Set(action.series.filter((x) => x.points.length > 0).map((x) => x.key));
      return {
        ...prev,
        hiddenKeys: new Set(Array.from(prev.hiddenKeys).filter((x) => keys.has(x))),
        keys,
      };
    }
    case "toggleSeries": {
      const key = typeof action.series === "string" ? action.series : action.series.key;
      if (!prev.keys.has(key)) {
        return prev;
      } else if (prev.hiddenKeys.has(key)) {
        const hiddenKeys = new Set(prev.hiddenKeys);
        hiddenKeys.delete(key);
        return {
          ...prev,
          hiddenKeys,
        };
      } else if (prev.keys.size - prev.hiddenKeys.size === 1) {
        //don't toggle off if only one active item
        return prev;
      } else {
        const hiddenKeys = new Set(prev.hiddenKeys);
        hiddenKeys.add(key);
        return {
          ...prev,
          hiddenKeys,
        };
      }
    }
  }
}

function useLegendState(): IUseLegendResult {
  const [{ hiddenKeys }, dispatch] = React.useReducer(reducer, {
    hiddenKeys: new Set<string>(),
    keys: new Set<string>(),
  });

  const setHiddenSeries = React.useCallback<IUseLegendResult["setHiddenSeries"]>((hiddenSeries) => {
    dispatch({
      hiddenSeries,
      type: "setHiddenSeries",
    });
  }, []);

  const setSeries = React.useCallback<IUseLegendResult["setSeries"]>((series) => {
    dispatch({
      series,
      type: "setSeries",
    });
  }, []);

  const toggleSeries = React.useCallback<IUseLegendResult["toggleSeries"]>((series) => {
    dispatch({
      series,
      type: "toggleSeries",
    });
  }, []);

  return {
    isHidden(series) {
      return series.points.length === 0 || hiddenKeys.has(series.key);
    },
    legendProps: {
      onClick(chart, _index, _event) {
        toggleSeries(chart.dataKey);
      },
    },
    setHiddenSeries,
    setSeries: setSeries,
    toggleSeries,
  };
}

export function useLegend(): IUseLegendResult {
  const stateValue = useLegendState();
  const contextValue = React.useContext(context);
  return contextValue ?? stateValue;
}

export const LegendProvider: React.FC<IUseLegendResult> = ({ children, ...props }) => {
  return <context.Provider value={props}>{children}</context.Provider>;
};
