import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import classNames from "classnames";
import * as React from "react";
import { useIntl } from "react-intl";
import { useDateContext } from "../../../components/DateContext";
import { Cell, Legend, Pie, PieChart, Tooltip, TooltipProps } from "recharts";
import { Tooltip as MuiTooltip } from "@material-ui/core";
import { useTopCharts } from "./actions";
import NoDataIndicator from "../../../components/NoDataIndicator";

const useStyles = makeStyles((theme) => ({
  card: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  cardContent: {
    "& ul": {
      "& li": {
        maxWidth: "150px",
        overflow: "hidden",
      },
      lineHeight: "1.9em",
      maxHeight: "13.5em",
      overflowX: "hidden",
      overflowY: "auto",
      scrollSnapType: "y mandatory",
      textAlign: "left !important",
    },
    maxHeight: "200px",
    padding: "0px",
  },
  customTooltip: {
    padding: "0px 10px",
    width: "auto",
  },
  healthScoreValue: {
    ...theme.typography.h1,
    alignItems: "center",
    display: "flex",
    flex: 1,
    fontSize: "7rem",
    justifyContent: "center",
  },
  regions: {
    gridArea: "regions",
  },
  root: {
    display: "grid",
    gridGap: theme.spacing(),
    gridTemplateColumns: "0.5fr 0.5fr 0.5fr",
    gridTemplateRows: "min-content",
  },
  total: {
    color: theme.palette.grey[500],
  },
  wedget: {
    display: "flex",
    gridArea: "wedget",
    height: "100%",
    maxWidth: "100%",
  },
}));

interface ILegendType {
  chartType: string;
  color: string;
  id: string;
  type: string;
  value: string;
}

type PieCellType = {
  color: string;
  name: string;
  value: number;
};

export interface ITop5ChartsProps {
  className?: string;
  onClickChart: (cell: any) => void;
}

function* getColorGenerator(): Generator<string, never, void> {
  while (true) {
    yield "#D91C24";
    yield "#F7931E";
    yield "#94A8C8";
    yield "#F2F221";
    yield "#BDBDBD";
  }
}

const Top5Charts: React.FC<ITop5ChartsProps> = ({ className, onClickChart }) => {
  const intl = useIntl();
  const { dateRange } = useDateContext();
  const classes = useStyles();
  const colors = getColorGenerator();
  const { data, error, isLoading } = useTopCharts({
    endDateTimeUtc: dateRange.to,
    startDateTimeUtc: dateRange.from,
  });

  const CustomTooltip = ({ active, payload }: TooltipProps): React.ReactElement | null => {
    if (active && payload && payload.length) {
      return (
        <div className={classes.customTooltip}>
          <p>
            {intl.formatMessage({ id: "alertsCount" })}: {payload[0].value}
          </p>
        </div>
      );
    }
    return null;
  };

  const chartsData = React.useMemo(() => {
    if (data && data.alertLogTopCharts) {
      const charts = data.alertLogTopCharts;
      return {
        top5Conditions: {
          data: charts.top5Conditions.map((x: any) => {
            return {
              color: colors.next().value,
              id: x.conditionId,
              name: x.conditionName,
              value: x.alertCount,
            };
          }),
        },
        top5Servers: {
          data: charts.top5Server.map((x: any) => {
            return {
              color: colors.next().value,
              id: x.objectId,
              name: x.serverName,
              value: x.alertCount,
            };
          }),
        },
        top5Tags: {
          data: charts.top5Tags.map((x: any) => {
            return {
              color: colors.next().value,
              name: x.tag,
              value: x.alertCount,
            };
          }),
        },
      };
    }
  }, [colors, data]);

  const handleClick = (legend: ILegendType): void => {
    onClickChart(legend);
  };

  return (
    <div className={classNames(classes.root, className)}>
      <Card className={classes.card} data-testid="top5serversCard">
        <CardHeader title={intl.formatMessage({ id: "top5Servers" })} />
        <CardContent className={classes.cardContent} role={isLoading ? "progressbar" : undefined}>
          {isLoading ? (
            <>
              <Skeleton className={classes.wedget} data-testid="loader" height="100%" variant="circle" width="100%" />
            </>
          ) : (
            <>
              {chartsData?.top5Servers?.data?.length ? (
                <PieChart aria-label="top5serverschart" className={classes.wedget} height={200} width={380}>
                  <Pie
                    cx={90}
                    cy={90}
                    data={chartsData?.top5Servers.data}
                    data-testid="chart"
                    dataKey="value"
                    fill="#82ca9d"
                    innerRadius={40}
                    outerRadius={80}
                  >
                    {chartsData?.top5Servers.data.map((item: PieCellType, index: number) => (
                      <Cell
                        fill={chartsData?.top5Servers.data[index % chartsData?.top5Servers.data.length].color}
                        key={`cell-${index}`}
                      />
                    ))}
                  </Pie>
                  <Tooltip content={<CustomTooltip />} />
                  <Legend
                    align="left"
                    formatter={(value: string) => {
                      return (
                        <MuiTooltip placement="top" title={value}>
                          <span>{value}</span>
                        </MuiTooltip>
                      );
                    }}
                    height={90}
                    onClick={handleClick}
                    payload={chartsData?.top5Servers?.data.map((item) => {
                      return {
                        chartType: "Server",
                        color: item.color,
                        id: item.id,
                        type: "circle",
                        value: item.name,
                      };
                    })}
                    verticalAlign="middle"
                    wrapperStyle={{ left: 190, top: 30, width: 150 }}
                  />
                </PieChart>
              ) : (
                <NoDataIndicator />
              )}
            </>
          )}
        </CardContent>
      </Card>
      <Card className={classes.card} data-testid="top5conditionsCard">
        <CardHeader title={intl.formatMessage({ id: "top5Conditions" })} />
        <CardContent className={classes.cardContent} role={isLoading ? "progressbar" : undefined}>
          {isLoading ? (
            <>
              <Skeleton className={classes.wedget} height="100%" variant="circle" width="100%" />
            </>
          ) : (
            <>
              {chartsData?.top5Conditions?.data?.length ? (
                <PieChart className={classes.wedget} height={200} width={380}>
                  <Pie
                    cx={90}
                    cy={90}
                    data={chartsData?.top5Conditions.data}
                    dataKey="value"
                    fill="#82ca9d"
                    innerRadius={40}
                    outerRadius={80}
                  >
                    {chartsData?.top5Conditions.data.map((item: PieCellType, index: number) => (
                      <Cell
                        fill={chartsData?.top5Conditions.data[index % chartsData?.top5Conditions.data.length].color}
                        key={`cell-${index}`}
                      />
                    ))}
                  </Pie>
                  <Tooltip content={<CustomTooltip />} />
                  <Legend
                    align="right"
                    formatter={(value: string) => {
                      return (
                        <MuiTooltip placement="top" title={value}>
                          <span>{value}</span>
                        </MuiTooltip>
                      );
                    }}
                    height={90}
                    onClick={handleClick}
                    payload={chartsData?.top5Conditions?.data.map((item) => {
                      return {
                        chartType: "Condition",
                        color: item.color,
                        id: item.id,
                        type: "circle",
                        value: item.name,
                      };
                    })}
                    verticalAlign="middle"
                    wrapperStyle={{ left: 190, top: 30, width: 150 }}
                  />
                </PieChart>
              ) : (
                <NoDataIndicator />
              )}
            </>
          )}
        </CardContent>
      </Card>
      <Card className={classes.card} data-testid="top5tagsCard">
        <CardHeader title={intl.formatMessage({ id: "top5Tags" })} />
        <CardContent className={classes.cardContent} role={isLoading ? "progressbar" : undefined}>
          {isLoading ? (
            <>
              <Skeleton className={classes.wedget} height="100%" variant="circle" width="100%" />
            </>
          ) : (
            <>
              {chartsData?.top5Tags?.data?.length ? (
                <PieChart className={classes.wedget} height={200} width={380}>
                  <Pie
                    cx={90}
                    cy={90}
                    data={chartsData?.top5Tags.data}
                    dataKey="value"
                    fill="#82ca9d"
                    innerRadius={40}
                    outerRadius={80}
                  >
                    {chartsData?.top5Tags.data.map((item: PieCellType, index: number) => (
                      <Cell
                        fill={chartsData?.top5Tags.data[index % chartsData?.top5Tags.data.length].color}
                        key={`cell-${index}`}
                      />
                    ))}
                  </Pie>
                  <Tooltip content={<CustomTooltip />} />
                  <Legend
                    align="right"
                    formatter={(value: string) => {
                      return (
                        <MuiTooltip placement="top" title={value}>
                          <span>{value}</span>
                        </MuiTooltip>
                      );
                    }}
                    height={90}
                    onClick={handleClick}
                    payload={chartsData?.top5Tags?.data.map((item) => {
                      return {
                        chartType: "Tags",
                        color: item.color,
                        id: item.value + Date.now(),
                        type: "circle",
                        value: item.name,
                      };
                    })}
                    verticalAlign="middle"
                    wrapperStyle={{ left: 190, top: 30, width: 150 }}
                  />
                </PieChart>
              ) : (
                <NoDataIndicator />
              )}
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default Top5Charts;
