import { ApolloClient } from "apollo-boost";
import { IUserTenant } from "../../api/AccountService/AccountService";
import { MoRef } from "../../utilities/TopologyUtility";
import * as topologyQuery from "./topologyQuery.graphql";
import {
  ITargetFeatures,
  ITopologyItemDevice,
  ITopologyItemEventSourceConnection,
  ITopologyItemGroup,
  TopologyDeviceType,
  TopologyEventSourceConnectionType,
  TopologyObjectType,
} from "./types";

export type TargetFeatures =
  | "UNSPECIFIED"
  | "PERMISSION_ADMINISTRATION"
  | "ALERTS"
  | "BLOCKING"
  | "DEADLOCKS"
  | "PERFORMANCE"
  | "STORAGE"
  | "TEMP_DB"
  | "TOP_SQL";

export interface ITopologyQueryTarget {
  features: TargetFeatures[];
  isWatched: boolean;
  itemId: number;
  moRef: string;
  name: string;
  objectId: string;
  parent: {
    __typename: "Device" | "Group";
    objectId: string;
  };
}

export interface ITopologyQueryDevice extends ITopologyQueryTarget {
  __typename: "Device";
  deviceType: TopologyDeviceType;
  friendlyName: string | null;
  versionInfo: string | null;
  lastBootUpTime: Date;
  totalMemory: number | null;
  coreCount: number | null;
  children: {
    moRef: string;
  }[];
}

export interface ITopologyQueryEventSourceConnection extends ITopologyQueryTarget {
  __typename: "EventSourceConnection";
  eventSourceConnectionType: TopologyEventSourceConnectionType;
  settings: {
    __typename: "AzureSqlDbTargetSettings" | "SqlServerTargetSettings";
    collectTempDbObjects: boolean;
    collectTempDbSessions: boolean;
  } | null;
  versionName: string | null;
  versionNumber: string | null;
  friendlyName: string | null;
}

export interface ITopologyQueryGroup {
  __typename: "Group";
  features: TargetFeatures[];
  itemId: number;
  moRef: string;
  name: string;
  objectId: string;
  parent: {
    __typename: "Group";
    objectId: string;
  } | null;
}

export interface ITopologyQueryResult {
  targets: Array<ITopologyQueryDevice | ITopologyQueryEventSourceConnection>;
  targetGroups: Array<ITopologyQueryGroup>;
}

export interface IFetchTopologyResponse {
  devices: ITopologyItemDevice[];
  eventSourceConnections: ITopologyItemEventSourceConnection[];
  groups: ITopologyItemGroup[];
  tenant: IUserTenant;
}

export async function fetchTopology(
  client: ApolloClient<unknown>,
  tenant: IUserTenant,
): Promise<IFetchTopologyResponse> {
  const { data } = await client.query<ITopologyQueryResult>({
    context: {
      headers: {
        "X-Tenant-ID": tenant.id,
      },
    },
    fetchPolicy: "no-cache",
    query: topologyQuery,
    // This variable doesn't actually exist in the query
    // It forces Apollo to treat these as separate queries to execute
    variables: { tenant: tenant.id },
  });
  const devices = data.targets
    .filter((x): x is ITopologyQueryDevice => x.__typename === "Device")
    .map<ITopologyItemDevice>((x) => {
      return {
        children: x.children.map((y) => MoRef.parse(y.moRef)),
        coreCount: x.coreCount,
        description: x.versionInfo ?? undefined,
        deviceType: x.deviceType,
        features: getFeatures(x.features),
        isWatched: x.isWatched,
        itemId: x.itemId,
        lastBootUpTime: x.lastBootUpTime,
        name: x.friendlyName ?? x.name,
        objectId: x.objectId,
        parentObjectId: x.parent.objectId,
        tenantId: tenant.id,
        totalMemory: x.totalMemory,
        type: TopologyObjectType.device,
        versionInfo: x.versionInfo,
      };
    });
  const eventSourceConnections = data.targets
    .filter((x): x is ITopologyQueryEventSourceConnection => x.__typename === "EventSourceConnection")
    .map<ITopologyItemEventSourceConnection>((x) => {
      return {
        description: [x.versionName, x.versionNumber].filter(Boolean).join(" "),
        eventSourceConnectionType: x.eventSourceConnectionType,
        features: getFeatures(x.features),
        isWatched: x.isWatched,
        itemId: x.itemId,
        name: x.friendlyName ?? x.name,
        objectId: x.objectId,
        parentObjectId: x.parent.objectId,
        settings: x.settings
          ? {
              collectTempDbObjects: x.settings.collectTempDbObjects,
              collectTempDbSessions: x.settings.collectTempDbSessions,
            }
          : null,
        tenantId: tenant.id,
        type: TopologyObjectType.eventSourceConnection,
        versionName: x.versionName,
        versionNumber: x.versionNumber,
      };
    });
  const groups = data.targetGroups.map<ITopologyItemGroup>((x) => {
    return {
      features: getFeatures(x.features),
      itemId: x.itemId,
      name: x.name,
      objectId: x.objectId,
      parentObjectId: x.parent?.objectId,
      tenantId: tenant.id,
      type: x.parent ? TopologyObjectType.group : TopologyObjectType.site,
    };
  });
  return {
    devices,
    eventSourceConnections,
    groups,
    tenant,
  };
}

function getFeatures(features: ReadonlyArray<TargetFeatures>): ITargetFeatures {
  return {
    alerts: features.includes("ALERTS"),
    blocking: features.includes("BLOCKING"),
    deadlocks: features.includes("DEADLOCKS"),
    health: true, // Health is allowed for everything
    performance: features.includes("PERFORMANCE"),
    permissionAdministration: features.includes("PERMISSION_ADMINISTRATION"),
    storage: features.includes("STORAGE"),
    tempdb: features.includes("TEMP_DB"),
    topsql: features.includes("TOP_SQL"),
  };
}
