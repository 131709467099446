import * as React from "react";
import IopsChart from "./IopsChart";
import IOBytesPerSecondChart from "./IOBytesPerSecondChart";
import LatencyChart from "./LatencyChart";
import { StorageChartContextProvider } from "./StorageChartContext";
import { StorageEntityType } from "../../types";
import ForecastChart from "../ForecastChart";
import { useStorageContext } from "../../../../contexts/storageContext";
import StorageChartCard from "./StorageChartCard";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

interface IStorageEntityCharts {
  className?: string;
  setSubHeaderText?: (subHeader: string) => string;
}

const useStyles = makeStyles((theme) => ({
  chartsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: `${theme.spacing(2)}px`,
  },
}));

const StorageEntityCharts = ({ className }: IStorageEntityCharts): React.ReactElement => {
  const classes = useStyles();
  const { selectedEntity } = useStorageContext();
  const [subHeader, setSubHeader] = React.useState<string>();

  return (
    <div className={classNames(classes.chartsContainer, className)}>
      <StorageChartContextProvider>
        {selectedEntity.type === StorageEntityType.LogicalVolume ? (
          <StorageChartCard subHeader={subHeader} title="freeSpace">
            <ForecastChart setSubHeaderText={setSubHeader} />
          </StorageChartCard>
        ) : (
          <>
            <StorageChartCard title="latency">
              <LatencyChart />
            </StorageChartCard>
            <StorageChartCard title="ioPerSecond">
              <IopsChart />
            </StorageChartCard>
            <StorageChartCard title="mbPerSecond">
              <IOBytesPerSecondChart />
            </StorageChartCard>
          </>
        )}
      </StorageChartContextProvider>
    </div>
  );
};

export default StorageEntityCharts;
