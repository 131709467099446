import * as React from "react";
import { IntlProvider } from "react-intl";
import { messages } from "../../intl/en";
import formats from "../../intl/formats";
import MuiLocalizationProvider from "./MuiLocalizationProvider";

const MonitorIntlProvider: React.FunctionComponent = ({ children }) => {
  return (
    <IntlProvider defaultLocale="en-US" formats={formats} locale="en-US" messages={messages}>
      <MuiLocalizationProvider>{children}</MuiLocalizationProvider>
    </IntlProvider>
  );
};

export default MonitorIntlProvider;
