import { useMemo } from "react";
import { IAsyncResult } from "src/features/Storage/types";
import { useQuery } from "@apollo/react-hooks";
import * as TRACE_WAITS_CHART_DATA from "./TraceWaitsChart.graphql";

export type AggregateWaitsData = {
    durationMs: number;
    eventCount: number;
    waitType: string;
};

export function useTraceWaitsChartData (eventSourceConnectionId: number, traceId: number): IAsyncResult<Array<AggregateWaitsData>> {
    const { data = { target: { topsql: { traceWaits: { aggregateWaitsData: [] } } } }, error, loading } = useQuery<{
        target: {
          topsql: {
            traceWaits: {
                aggregateWaitsData: Array<AggregateWaitsData>
            };
          };
        };
      }>(TRACE_WAITS_CHART_DATA, { variables: { moRef: `connection-${eventSourceConnectionId}`, traceId } });
      
      return useMemo(() => {
        if (error) {
          return {
            data: undefined,
            error: error,
            isLoading: false,
            state: "error",
          };
        }
        
        if (loading) {
          return {
            data: undefined,
            error: null,
            isLoading: true,
            state: "loading",
          };
        }

        return {
            data: data.target.topsql.traceWaits.aggregateWaitsData,
            error: null,
            isLoading: false,
            state: "success"
        }
      }, [data, loading, error]);
};