import { Duration } from "luxon";

function roundPlaces(e: number, places: number): number {
  return Math.round(e * Math.pow(10, places)) / Math.pow(10, places);
}

/**
 * Formats a number in bit/sec based on size (i.e. 1 Mbps).
 *
 * @param value The value to format in bit/sec (bps).
 */
export function formatBitrate(value: number): string {
  return value !== 0 ? `${formatBits(value)}ps` : "0";
}

/**
 * Formats a number in bits based on size (i.e. 1 Mb).
 *
 * @param value The value to format in bytes.
 */
export function formatBits(value: number): string {
  const v = Math.abs(value);
  if (v >= 10000000000000) {
    return `${Math.round(value / 1000000000000)} Tb`;
  } else if (v >= 1000000000000) {
    return `${roundPlaces(value / 1000000000000, 1)} Tb`;
  } else if (v >= 10000000000) {
    return `${Math.round(value / 1000000000)} Gb`;
  } else if (v >= 1000000000) {
    return `${roundPlaces(value / 1000000000, 1)} Gb`;
  } else if (v >= 10000000) {
    return `${Math.round(value / 1000000)} Mb`;
  } else if (v >= 1000000) {
    return `${roundPlaces(value / 1000000, 1)} Mb`;
  } else if (v >= 10000) {
    return `${Math.round(value / 1000)} Kb`;
  } else if (v >= 1000) {
    return `${roundPlaces(value / 1000, 1)} Kb`;
  } else if (v > 0) {
    return `${value} b`;
  } else {
    return "0";
  }
}

/**
 * Formats a number in bytes based on drive size (i.e. 1 TB).
 *
 * @param value The value to format in bytes.
 */
export function formatDigitalStorage(value: number): string {
  const v = Math.abs(value);
  if (v >= 10 * 1024 ** 4) {
    return `${Math.round(value / 1024 ** 4)} TB`;
  } else if (v >= 1024 ** 4) {
    return `${roundPlaces(value / 1024 ** 4, 1)} TB`;
  } else if (v >= 10 * 1024 ** 3) {
    return `${Math.round(value / 1024 ** 3)} GB`;
  } else if (v >= 1024 ** 3) {
    return `${roundPlaces(value / 1024 ** 3, 1)} GB`;
  } else if (v >= 10 * 1024 ** 2) {
    return `${Math.round(value / 1024 ** 2)} MB`;
  } else if (v >= 1024 ** 2) {
    return `${roundPlaces(value / 1024 ** 2, 1)} MB`;
  } else if (v >= 10 * 1024) {
    return `${Math.round(value / 1024)} KB`;
  } else if (v >= 1024) {
    return `${roundPlaces(value / 1024, 1)} KB`;
  } else if (v > 0) {
    return `${value} B`;
  } else {
    return "0";
  }
}

/**
 * Formats a number into its abbreviated version. Ex: 7M, 10K, or 1.2B.
 */
export function formatAbbreviatedNumber(value: number): string {
  const v = Math.abs(value);
  if (v >= 10000000000) {
    return `${Math.round(value / 1000000000)}B`;
  } else if (v >= 1000000000) {
    return `${roundPlaces(value / 1000000000, 1)}B`;
  } else if (v >= 10000000) {
    return `${Math.round(value / 1000000)}M`;
  } else if (v >= 1000000) {
    return `${roundPlaces(value / 1000000, 1)}M`;
  } else if (v >= 10000) {
    return `${Math.round(value / 1000)}K`;
  } else if (v >= 1000) {
    return `${roundPlaces(value / 1000, 1)}K`;
  } else {
    return value.toString();
  }
}

/**
 * Formats a time span string in expected to be in the format 00:00:00.0000000.
 *
 * @param timeSpan The time span string to format.
 */
export function formatTimeSpan(timeSpan: string): string {
  if (!timeSpan) {
    return "";
  } else if (isIso8601Interval(timeSpan)) {
    const value = Duration.fromISO(timeSpan);
    const normalizedValue = value.shiftTo("days", "hours", "minutes", "seconds", "milliseconds");
    return normalizedValue.days > 0
      ? normalizedValue.toFormat("d.hh:mm:ss.SSS")
      : normalizedValue.toFormat("hh:mm:ss.SSS");
  } else {
    const numberOfMillisecondDigits = 3;
    let millisecondDot = timeSpan.lastIndexOf(".");
    const lastColon = timeSpan.lastIndexOf(":");

    // If there is no dot or the dot comes before the last colon, put a dot
    if (millisecondDot === -1 || millisecondDot < lastColon) {
      timeSpan = timeSpan.concat(".");
      millisecondDot = timeSpan.length - 1;
    }

    const endOfTimeSpan = numberOfMillisecondDigits + millisecondDot + 1;
    return timeSpan.substring(0, endOfTimeSpan).padEnd(endOfTimeSpan, "0");
  }
}

/**
 * Returns true if the string value is a valid ISO-8601 interval duration, false otherwise.
 * @param value
 */
export function isIso8601Interval(value: string): boolean {
  return Duration.fromISO(value).isValid;
}

export interface ITimeSpanDuration {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
}

export function parseTimeSpan(timeSpan: string): ITimeSpanDuration {
  let days = 0;
  let hours = 0;
  let minutes = 0;
  let seconds = 0;
  let milliseconds = 0;

  const regex = /\d+/g;
  const matches = timeSpan.match(regex);
  if (!matches) {
    throw new Error(`Value is not a valid time span: ${timeSpan}`);
  }
  let secondsIndex = matches.length - 1;

  const index = timeSpan.lastIndexOf(".");
  // We are checking past the middle of the string incase the timespan was formatted with a '.'
  // for indicating days without a millisecond format.
  if (index !== -1 && index >= timeSpan.length / 2) {
    milliseconds = parseFloat(timeSpan.substring(index + 1, index + 4) + "." + timeSpan.substring(index + 4));
    secondsIndex--;
  }

  seconds = parseInt(matches[secondsIndex], 10);
  minutes = parseInt(matches[secondsIndex - 1], 10);
  hours = parseInt(matches[secondsIndex - 2], 10);

  if (secondsIndex - 3 > -1) {
    days = parseInt(matches[secondsIndex - 3], 10);
  }

  return {
    days,
    hours,
    milliseconds,
    minutes,
    seconds,
  };
}
