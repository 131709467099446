import * as React from "react";

export function useLocalStorageState<T>(key: string, defaultValue: T): [T, React.Dispatch<React.SetStateAction<T>>] {
  const state = React.useState<T>(() => {
    const localValue = localStorage.getItem(key);
    if (localValue) return JSON.parse(localValue);
    else return defaultValue;
  });

  React.useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state[0]));
  }, [key, state]);

  return state;
}
