import { useEffect, useRef, useState } from "react";
import { RechartsFunction } from "recharts";

export type ChartRange = readonly [Date | null, Date | null];

export interface IUseRangeSelectOptions {
  activeRange: ChartRange;
  onActiveRangeChange?: (value: ChartRange) => void;
}

export interface IUseRangeSelectResult {
  onMouseDown: RechartsFunction;
  onMouseMove: RechartsFunction;
  onMouseUp: RechartsFunction;
  referenceArea: readonly [number | null, number | null];
}

export function useRangeSelect({ activeRange, onActiveRangeChange }: IUseRangeSelectOptions): IUseRangeSelectResult {
  const animationFrameRef = useRef<number>(0);
  const [active, setActive] = useState(false);
  const [referenceArea, setReferenceArea] = useState<[number | null, number | null]>([null, null]);

  const onMouseDown: RechartsFunction = (e) => {
    if (typeof onActiveRangeChange === "function" && e) {
      setActive(true);
      setReferenceArea([e.activeLabel, null]);
    }
  };

  const onMouseMove: RechartsFunction = (e) => {
    if (typeof onActiveRangeChange === "function" && active && e) {
      window.cancelAnimationFrame(animationFrameRef.current);
      animationFrameRef.current = window.requestAnimationFrame(() => {
        setReferenceArea((prev) => [prev[0], e.activeLabel]);
      });
    }
  };

  const onMouseUp: RechartsFunction = (e) => {
    if (typeof onActiveRangeChange === "function" && typeof referenceArea[0] === "number") {
      if (e && e.activeLabel !== referenceArea[0]) {
        const first = new Date(referenceArea[0]);
        const second = new Date(e.activeLabel);
        if (first < second) {
          onActiveRangeChange([first, second]);
        } else {
          onActiveRangeChange([second, first]);
        }
      } else if (activeRange[0] && activeRange[1]) {
        // if the user clicked inside the reference area, open the menu and keep the reference area
        if (referenceArea[0] > activeRange[0].getTime() && referenceArea[0] <= activeRange[1].getTime()) {
          onActiveRangeChange(activeRange);
          setReferenceArea([activeRange[0].getTime(), activeRange[1].getTime()]);
        } else {
          // if the user clicked inside the graph and outside the reference area, clear the reference area
          onActiveRangeChange([null, null]);
        }
      }
    }
    setActive(false);
  };

  useEffect(() => {
    setReferenceArea([activeRange[0]?.getTime() ?? null, activeRange[1]?.getTime() ?? null]);
  }, [activeRange]);

  return {
    onMouseDown,
    onMouseMove,
    onMouseUp,
    referenceArea,
  };
}
