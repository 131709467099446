import Button, { ButtonProps } from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import DateRangeIcon from "@material-ui/icons/DateRange";
import * as React from "react";
import { useDateContext } from "../DateContext";
import FormattedDateRange from "../FormattedDateRange";
import DateJumpIconButton from "./DateJumpIconButton";
import DateSelectionPopover from "./DateSelectionPopover";
import classNames from "classnames";

export interface IDateSelectionProps {
  color?: ButtonProps["color"];
}

const useStyles = makeStyles((theme) => ({
  darkModeStyles: {
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.04)",
    },
    borderColor: "rgba(255, 255, 255, 0.4)",
    borderWidth: "1px",
    marginRight: theme.spacing(10),
  },
  disabled: {},
  popover: {
    // This width aligns with the @material-ui/pickers landscape calendar width
    width: "475px",
  },
  root: {
    "&$disabled": {
      borderColor: "rgba(255, 255, 255, 0.4)",
      color: "rgba(255, 255, 255, 0.4)",
    },
    borderRadius: "8px",
    fontWeight: 400,
    padding: "3px 15px",
  },
}));

const DateSelection: React.FC<IDateSelectionProps> = ({ color = "primary" }) => {
  const { allowDateNavigation, dateRange } = useDateContext();
  const classes = useStyles();
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <DateJumpIconButton color={color} />
      <Button
        classes={{
          disabled: classNames({ [classes.disabled]: color !== "primary" }),
          root: classNames(classes.root, {
            [classes.darkModeStyles]: color !== "primary",
          }),
        }}
        color={color}
        data-testid="dateSelect"
        disabled={!allowDateNavigation}
        onClick={() => setOpen(true)}
        ref={anchorRef}
        startIcon={<DateRangeIcon />}
        variant="outlined"
      >
        <FormattedDateRange
          // The actual values displayed will depend on the difference in `from` and `to` and what is necessary.
          from={dateRange.from}
          to={dateRange.to}
        />
      </Button>
      <Popover
        PaperProps={{ className: classes.popover, "data-testid": "dateSelectPopover" } as any}
        // The default Grow transition adversely affects the Tab's ability to compute the width of the selection bar.
        // The Fade transition maintains the same size as it transitions and eliminates this issue.
        TransitionComponent={Fade}
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        onClose={() => setOpen(false)}
        open={open}
      >
        <DateSelectionPopover onClose={() => setOpen(false)} />
      </Popover>
    </>
  );
};

export default DateSelection;
