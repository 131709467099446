import { traceEventGridPageInfoDefault } from "./topSqlDefaults";
import {
  CHANGE_CHART_FILTER,
  CHANGE_TOTALS_GRID_PAGE,
  CHANGE_TRACE_EVENT_GRID_PAGE,
  CHANGE_SELECTED_EVENT,
  CHANGE_SELECTED_STATEMENT,
  CHANGE_SELECTED_STATEMENT_EVENT,
  CHANGE_SELECTED_TOTAL,
  CHANGE_STATEMENT_GRID_PAGE,
  ITopSqlState,
  SET_CACHE_PLAN_ID,
  SET_IS_TOTALS,
  SET_STATEMENT_RESULTS,
  SET_STATEMENT_TRACE_EVENT_RESULTS,
  SET_TOTALS_RESULTS,
  SET_TOTALS_TRACE_EVENT_RESULTS,
  STATEMENT_EVENT_BACK_CLICK,
  STATEMENT_EVENT_CLICK,
  TopSqlActionTypes,
  TOTALS_EVENT_BACK_CLICK,
  TOTALS_EVENT_CLICK,
  SEARCH_TOTALS,
  TOTALS_FILTER,
  TRACE_EVENT_FILTER,
} from "./types";

export function topSqlReducer(state: ITopSqlState, action: TopSqlActionTypes): ITopSqlState {
  switch (action.type) {
    case CHANGE_CHART_FILTER:
      return {
        ...state,
        chartFilter: action.payload,
        statementResults: {
          ...state.statementResults,
          isLoading: true,
        },
        totalsResults: {
          ...state.totalsResults,
          isLoading: true,
        },
      };

    case CHANGE_TOTALS_GRID_PAGE:
      return {
        ...state,
        totalsGridPageInfo: action.payload,
        totalsResults: {
          ...state.totalsResults,
          pageLoading: true,
        },
      };

    case CHANGE_TRACE_EVENT_GRID_PAGE:
      return {
        ...state,
        totalsTraceEventsResults: {
          ...state.totalsTraceEventsResults,
          pageLoading: true,
        },
        traceEventGridPageInfo: action.payload,
      };

    case CHANGE_STATEMENT_GRID_PAGE:
      return {
        ...state,
        statementGridPageInfo: action.payload,
        statementResults: {
          ...state.statementResults,
          pageLoading: true,
        },
      };

    case CHANGE_SELECTED_STATEMENT:
      if (state.isTotals) {
        return {
          ...state,
          selectedStatement: action.payload
            ? {
                databaseId: action.payload.databaseId,
                objectName: action.payload.objectName,
                queryHash: action.payload.queryHash,
                textData: action.payload.textData,
                textMd5: action.payload.textMd5,
                type: action.payload.type,
              }
            : null,
        };
      } else {
        return {
          ...state,
          cachePlanId: null,
          selectedStatement: action.payload
            ? {
                cpu: action.payload.cpu,
                databaseId: action.payload.databaseId,
                queryHash: action.payload.queryHash,
                reads: action.payload.readsL,
                textData: action.payload.textData,
                textMd5: action.payload.textMd5,
                type: "T",
              }
            : null,
        };
      }

    case CHANGE_SELECTED_TOTAL: {
      const newSelectedTotal = {
        databaseId: action.payload.databaseId,
        databaseName: action.payload.databaseName,
        deviceId: action.payload.deviceId,
        textData: action.payload.textData,
        textMd5: action.payload.textMd5,
        traceDataId: action.payload.latestTraceDataId,
        type: action.payload.type,
      };

      if (
        !state.selectedTotal ||
        state.selectedTotal.databaseId !== newSelectedTotal.databaseId ||
        state.selectedTotal.databaseName !== newSelectedTotal.databaseName ||
        state.selectedTotal.deviceId !== newSelectedTotal.deviceId ||
        state.selectedTotal.textData !== newSelectedTotal.textData ||
        state.selectedTotal.textMd5 !== newSelectedTotal.textMd5 ||
        state.selectedTotal.traceDataId !== newSelectedTotal.traceDataId ||
        state.selectedTotal.type !== newSelectedTotal.type
      ) {
        return {
          ...state,
          cachePlanId: undefined,
          selectedStatement: null,
          selectedTotal: newSelectedTotal,
          statementResults: {
            data: [],
            error: undefined,
            isLoading: true,
            pageLoading: false,
            totalCount: 0,
          },
          statementsTraceEventCriteria: null,
        };
      } else {
        return state;
      }
    }
    case CHANGE_SELECTED_EVENT:
      return {
        ...state,
        selectedEvent: {
          cpu: action.payload.cpu,
          databaseId: action.payload.databaseId,
          databaseName: action.payload.databaseName,
          parentId: action.payload.traceDataId,
          textData: action.payload.textData,
          textMd5: action.payload.normalizedTextMd5,
          type: state.isTotals ? undefined : "T", // Hard-coded T represents Trace Event
        },
        selectedStatementEvent: null,
        selectedTotal: state.isTotals ? state.selectedTotal : null,
        statementsTraceEventsResults: {
          data: [],
          error: undefined,
          isLoading: true,
          pageLoading: false,
          totalCount: 0,
        },
      };

    case CHANGE_SELECTED_STATEMENT_EVENT:
      return {
        ...state,
        selectedStatementEvent: {
          cpu: action.payload.cpu,
          databaseId: action.payload.databaseId,
          databaseName: action.payload.databaseName,
          parentId: action.payload.parentId,
          parentTextMd5: state.isTotals ? state.selectedTotal?.textMd5 : state.selectedEvent?.textMd5,
          textData: action.payload.textData,
          textMd5: action.payload.normalizedTextMd5,
          type: state.isTotals ? undefined : "T", // Hard-coded T represents Trace Event
        },
      };

    case SET_CACHE_PLAN_ID:
      return {
        ...state,
        cachePlanId: action.payload,
      };

    case SEARCH_TOTALS:
      return {
        ...state,
        searchKeyword: action.payload.searchKey,
      };

    case TOTALS_FILTER:
      return {
        ...state,
        totalsDatabase: action.payload.totalsDatabase,
      };
    case TRACE_EVENT_FILTER:
      return {
        ...state,
        applicationName: action.payload.applicationName,
        databaseName: action.payload.databaseName,
        errorKeyword: action.payload.errorKeyword,
        eventClass: action.payload.eventClass,
        hostName: action.payload.hostName,
        loginName: action.payload.loginName,
        spid: action.payload.spid,
      };

    case SET_IS_TOTALS:
      return {
        ...state,
        applicationName: null,
        cachePlanId: null,
        databaseName: null,
        errorKeyword: null,
        eventClass: null,
        hostName: null,
        isTotals: !state.isTotals,
        loginName: null,
        selectedEvent: null,
        selectedStatement: null,
        selectedTotal: null,
        spid: null,
        statementsTraceEventCriteria: null,
        totalsTraceEventCriteria: null,
        traceEventGridPageInfo: traceEventGridPageInfoDefault,
      };

    case SET_STATEMENT_RESULTS:
      if (!state.selectedStatement && action.payload.data.length > 0) {
        if (state.isTotals) {
          return {
            ...state,
            selectedStatement: {
              databaseId: action.payload.data[0].databaseId,
              objectName: action.payload.data[0].objectName,
              queryHash: action.payload.data[0].queryHash,
              textData: action.payload.data[0].textData,
              textMd5: action.payload.data[0].textMd5,
              type: action.payload.data[0].type,
            },
            statementResults: action.payload,
          };
        } else {
          return {
            ...state,
            cachePlanId: null,
            selectedStatement: {
              cpu: action.payload.data[0].cpu,
              databaseId: action.payload.data[0].databaseId,
              queryHash: action.payload.data[0].queryHash,
              reads: action.payload.data[0].readsL,
              textData: action.payload.data[0].textData,
              textMd5: action.payload.data[0].textMd5,
              type: "T",
            },
            statementResults: action.payload,
          };
        }
      }
      return {
        ...state,
        cachePlanId: null,
        selectedStatement: null,
        statementResults: action.payload,
      };

    case SET_STATEMENT_TRACE_EVENT_RESULTS:
      if (action.payload.data.length === 0) {
        return {
          ...state,
          selectedStatementEvent: null,
          statementsTraceEventsResults: action.payload,
        };
      } else {
        return {
          ...state,
          selectedStatementEvent: {
            cpu: action.payload.data[0].cpu,
            databaseId: action.payload.data[0].databaseId,
            databaseName: action.payload.data[0].databaseName,
            parentId: action.payload.data[0].parentId,
            parentTextMd5: state.isTotals ? state.selectedTotal?.textMd5 : state.selectedEvent?.textMd5,
            textData: action.payload.data[0].textData,
            textMd5: action.payload.data[0].normalizedTextMd5,
            type: state.isTotals ? undefined : "T", // Hard-coded T represents Trace Event
          },
          statementsTraceEventsResults: action.payload,
        };
      }

    case SET_TOTALS_RESULTS: {
      const resultData = action.payload.results;
      if (!state.selectedTotal && resultData && resultData.data.length > 0) {
        return {
          ...state,
          cachePlanId: undefined,
          selectedStatement: null,
          selectedTotal: {
            databaseId: resultData.data[0].databaseId,
            databaseName: resultData.data[0].databaseName,
            deviceId: action.payload.deviceId,
            textData: resultData.data[0].textData,
            textMd5: resultData.data[0].textMd5,
            traceDataId: resultData.data[0].latestTraceDataId,
            type: resultData.data[0].type,
          },
          statementResults: {
            data: [],
            error: undefined,
            isLoading: true,
            pageLoading: false,
            totalCount: 0,
          },
          statementsTraceEventCriteria: null,
          totalsResults: resultData,
        };
      }
      return {
        ...state,
        totalsResults: resultData,
      };
    }
    case SET_TOTALS_TRACE_EVENT_RESULTS:
      if (action.payload.data.length) {
        return {
          ...state,
          cachePlanId: undefined,
          selectedEvent: state.totalsTraceEventsResults.pageLoading
            ? state.selectedEvent
            : {
                cpu: action.payload.data[0].cpu,
                databaseId: action.payload.data[0].databaseId,
                databaseName: action.payload.data[0].databaseName,
                parentId: action.payload.data[0].traceDataId,
                textData: action.payload.data[0].textData,
                textMd5: action.payload.data[0].normalizedTextMd5,
                type: state.isTotals ? undefined : "T", // Hard-coded T represents Trace Event
              },
          selectedStatement: null,
          selectedTotal: state.isTotals ? state.selectedTotal : null,
          totalsTraceEventsResults: action.payload,
        };
      }

      return {
        ...state,
        cachePlanId: null,
        totalsTraceEventsResults: action.payload,
      };

    case TOTALS_EVENT_BACK_CLICK:
      return {
        ...state,
        applicationName: null,
        databaseName: null,
        errorKeyword: null,
        eventClass: null,
        hostName: null,
        loginName: null,
        spid: null,
        totalsTraceEventCriteria: null,
        traceEventGridPageInfo: traceEventGridPageInfoDefault,
      };

    case STATEMENT_EVENT_BACK_CLICK:
      return {
        ...state,
        applicationName: null,
        databaseName: null,
        errorKeyword: null,
        eventClass: null,
        hostName: null,
        loginName: null,
        spid: null,
        statementsTraceEventCriteria: null,
        traceEventGridPageInfo: traceEventGridPageInfoDefault,
      };

    case TOTALS_EVENT_CLICK:
      return {
        ...state,
        totalsTraceEventCriteria: {
          databaseId: action.payload.databaseId,
          textMd5: action.payload.textMd5,
        },
      };

    case STATEMENT_EVENT_CLICK:
      return {
        ...state,
        statementsTraceEventCriteria: {
          databaseId: state.selectedTotal ? state.selectedTotal.databaseId : undefined,
          objectName: action.payload.objectName,
          textMd5: action.payload.textMd5,
        },
      };

    default:
      throw new Error("Unknown action called for Top Sql.");
  }
}
