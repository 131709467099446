import { lighten, useTheme } from "@material-ui/core/styles";
import * as React from "react";
import { useIntl } from "react-intl";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";
import { useTopSqlContext } from "../../../../../contexts/topSqlContext";
import { makeFormatDateAxis } from "../../../../../utilities/ChartUtility";
import { formatAbbreviatedNumber } from "../../../../../utilities/Formatters";
import { Grouping, IQueryHistoryOptions } from "../types";
import QueryHistoryChartTooltip from "./QueryHistoryChartTooltip";
import useQueryHistory, { IQueryHistoryPoint, IQueryHistoryPointValues } from "./useQueryHistory";

export interface IQueryHistoryChartProps {
  eventSourceConnectionId: number;
  options: IQueryHistoryOptions;
  renderLoading: () => React.ReactElement;
  renderNoData: () => React.ReactElement;
}

interface ITimestampPoint extends IQueryHistoryPointValues {
  readonly timestamp: IQueryHistoryPoint["timestamp"];
}

function getIntervalMs(grouping: Grouping.day | Grouping.hour | Grouping.week): number {
  switch (grouping) {
    case Grouping.day:
      return 24 * 60 * 60 * 1000;
    case Grouping.hour:
      return 60 * 60 * 1000;
    case Grouping.week:
      return 7 * 24 * 60 * 60 * 1000;
  }
}

const QueryHistoryChart: React.FC<IQueryHistoryChartProps> = ({
  eventSourceConnectionId,
  options,
  renderLoading,
  renderNoData,
}) => {
  const theme = useTheme();
  const intl = useIntl();
  const { setCachePlanId } = useTopSqlContext();
  const [payloadKey, setPayloadKey] = React.useState<string | null>(null);
  const [selectedPoint, setSelectedPoint] = React.useState<ITimestampPoint | null>(null);
  const { data, error, isLoading, series } = useQueryHistory({
    eventSourceConnectionId,
    options,
  });
  React.useEffect(
    () => {
      if (isLoading) {
        setSelectedPoint(null);
        setCachePlanId(undefined);
      } else {
        const lastPair = data
          // Retrieve all entries from all points as [ITimestampPoint, cachePlanId] tuples
          .flatMap((x) => {
            return Object.entries(x)
              .filter((entry): entry is [string, IQueryHistoryPointValues] => entry[0] !== "timestamp")
              .map<[ITimestampPoint, number | undefined]>(([, y]) => [
                { ...y, timestamp: x.timestamp },
                y.data.cachedPlanId,
              ]);
          })
          // Filter out null/undefined cachePlanId
          .filter((pair): pair is [ITimestampPoint, number] => typeof pair[1] === "number")
          // Find the most recent pair by timestamp
          .reduce<[ITimestampPoint, number] | null>(
            (prev, curr) => (prev && prev[0].timestamp > curr[0].timestamp ? prev : curr),
            null,
          );
        if (lastPair) {
          setSelectedPoint(lastPair[0]);
          setCachePlanId(lastPair[1]);
        } else {
          setSelectedPoint(null);
          setCachePlanId(null);
        }
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [data, isLoading, options.grouping, options.metric],
  );

  const formatDateAxis = makeFormatDateAxis(options.dateRange);
  if (error) {
    throw error;
  } else if (isLoading) {
    return renderLoading();
  } else if (data.length === 0) {
    return renderNoData();
  } else if (options.grouping === Grouping.ungrouped) {
    return (
      <ResponsiveContainer height="100%" width="100%">
        <ScatterChart>
          <CartesianGrid fill="#f2f2f2" />
          <XAxis
            dataKey="timestamp"
            domain={[options.dateRange.from.getTime(), options.dateRange.to.getTime()]}
            minTickGap={125}
            padding={{ left: 10, right: 10 }}
            scale="time"
            tickFormatter={(x) => formatDateAxis(new Date(x))}
            type="number"
          />
          <YAxis dataKey={options.metric} tickFormatter={formatAbbreviatedNumber}>
            <Label angle={-90} dx={-10}>
              {intl.formatMessage({ id: options.metric })}
            </Label>
          </YAxis>
          {/* ZAxis sets the size of the dots */}
          <ZAxis range={[30, 30]} />
          <Tooltip
            content={
              <QueryHistoryChartTooltip
                dataAccessor={(x) => (x.length > 0 ? x[0].payload.data : null)}
                options={options}
              />
            }
            isAnimationActive={false}
          />
          {series.map((s) => {
            const seriesData = data
              .filter((x) => s.key in x)
              .map((x: any) => ({ ...x[s.key], timestamp: x.timestamp }));
            return (
              <Scatter
                data={seriesData}
                fill={lighten(s.color, 0.2)}
                isAnimationActive={false}
                key={s.key}
                onClick={(chart) => {
                  if (typeof chart.data.cachedPlanId === "number") {
                    setSelectedPoint(chart.payload);
                    setCachePlanId(chart.data.cachedPlanId);
                  }
                }}
                shape={s.shape}
                stroke={s.color}
              />
            );
          })}
          {selectedPoint && <ReferenceLine stroke={theme.palette.secondary.main} x={selectedPoint.timestamp} />}
          {selectedPoint && <ReferenceLine stroke={theme.palette.secondary.main} y={selectedPoint[options.metric]} />}
        </ScatterChart>
      </ResponsiveContainer>
    );
  } else {
    const interval = getIntervalMs(options.grouping);
    return (
      <ResponsiveContainer height="100%" width="100%">
        <BarChart data={data}>
          <CartesianGrid fill="#f2f2f2" />
          <XAxis
            dataKey="timestamp"
            // adding interval to the min/max pads the bars one unit so that they don't run off the chart
            // Recharts doesn't pad for side-by-side bars well
            domain={[`dataMin - ${interval}`, `dataMax + ${interval}`]}
            scale="time"
            tickFormatter={(x) => formatDateAxis(new Date(x))}
            type="number"
          />
          <YAxis tickFormatter={formatAbbreviatedNumber}>
            <Label angle={-90} dx={-10}>
              {intl.formatMessage({ id: options.metric })}
            </Label>
          </YAxis>
          <Tooltip
            content={
              <QueryHistoryChartTooltip
                dataAccessor={(x) => (x.length > 0 && payloadKey ? x[0].payload[payloadKey].data : null)}
                isAnimationActive={false}
                options={options}
              />
            }
            isAnimationActive={false}
          />
          {series.map((s) => {
            return (
              <Bar
                dataKey={(x) => x[s.key]?.[options.metric]}
                fill={s.color}
                isAnimationActive={false}
                key={s.key}
                onMouseEnter={() => setPayloadKey(s.key)}
                onMouseLeave={() => setPayloadKey(null)}
              />
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    );
  }
};

export default QueryHistoryChart;
