import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const QueryTooltip = withStyles(
    {
        tooltip: {
            backgroundColor: "#333333",
            maxWidth: "700px",
            whiteSpace: "pre-wrap",
        }
    }
)(Tooltip);

export default QueryTooltip