import * as React from "react";
import { v4 as uuid } from "uuid";
import MetricChart, { IMetricChartSeriesOptions, TOTAL_INSTANCE, useMetrics } from "../../../../components/MetricChart";
import {
  ITopology,
  ITopologyItemDevice,
  ITopologyItemEventSourceConnection,
  TopologyObjectType,
  useTopology,
} from "../../../../components/TopologyContext";
import { usePerformanceAnalysisContext } from "../../context";
import { IPerformanceAnalysisChartProps } from "../../types";

const availablePatternId = `free-space-pattern-${uuid()}`;
const aMetricsWithSql = Object.freeze<IMetricChartSeriesOptions>([
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "stackedArea",
    color: "#5F9EA0",
    instance: TOTAL_INSTANCE,
    label: "sqlServer",
    metric: "os.memory.sqlInstances.size",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "stackedArea",
    color: "#B0C4DE",
    instance: null,
    label: "fileCache",
    metric: "os.memory.fileCache.size",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "stackedArea",
    color: "#808080",
    instance: null,
    label: "other",
    metric: "os.memory.other.size",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "stackedArea",
    color: `url(#${availablePatternId})`,
    instance: null,
    label: "available",
    metric: "os.memory.available.size",
    strokeColor: "#194537",
  }),
]);
const aMetricsWithoutSql = Object.freeze<IMetricChartSeriesOptions>([
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "stackedArea",
    color: "#B0C4DE",
    instance: null,
    label: "fileCache",
    metric: "os.memory.fileCache.size",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "stackedArea",
    color: "#808080",
    instance: null,
    label: "other",
    metric: "os.memory.other.size",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "stackedArea",
    color: `url(#${availablePatternId})`,
    instance: null,
    label: "available",
    metric: "os.memory.available.size",
    strokeColor: "#194537",
  }),
]);

const bMetrics = Object.freeze<IMetricChartSeriesOptions>([
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#FF8C00",
    instance: null,
    label: "Reads",
    metric: "os.memory.read.pages.ps",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#FF4500",
    instance: null,
    label: "Writes",
    metric: "os.memory.write.pages.ps",
  }),
]);

function showSqlServer(target: ITopologyItemDevice | ITopologyItemEventSourceConnection, topology: ITopology): boolean {
  return (
    target.type === TopologyObjectType.eventSourceConnection ||
    topology.findByParentObjectId(target.objectId, TopologyObjectType.eventSourceConnection).length > 0
  );
}

const SystemMemoryChart: React.FC<IPerformanceAnalysisChartProps> = ({ className }) => {
  const topology = useTopology();
  const { contextMenuItems, dateRange, selectedRange, setSelectedRange, target } = usePerformanceAnalysisContext();
  const aData = useMetrics({
    dateRange,
    metrics: showSqlServer(target, topology) ? aMetricsWithSql : aMetricsWithoutSql,
    target,
  });
  const bData = useMetrics({
    dateRange,
    metrics: bMetrics,
    target,
  });
  return (
    <>
      <MetricChart
        className={className}
        contextMenuItems={contextMenuItems}
        dateRange={dateRange}
        metricData={aData}
        onSelectedRangeChange={setSelectedRange}
        selectedRange={selectedRange}
        target={target}
      >
        <defs>
          <pattern height="6" id={availablePatternId} patternUnits="userSpaceOnUse" width="6" x="0" y="0">
            <circle cx="2" cy="2" fill="#194537" r="1" stroke="none" />
            <circle cx="5" cy="5" fill="#194537" r="1" stroke="none" />
          </pattern>
        </defs>
      </MetricChart>
      <MetricChart
        className={className}
        contextMenuItems={contextMenuItems}
        dateRange={dateRange}
        metricData={bData}
        onSelectedRangeChange={setSelectedRange}
        selectedRange={selectedRange}
        target={target}
      />
    </>
  );
};

export default SystemMemoryChart;
