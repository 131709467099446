import * as React from "react";
import { IPlanDataResponse, IRawPlanXMLResponse } from "../../api/PlanViewerService/models/PlanViewer";
import PlanViewerService from "../../api/PlanViewerService/PlanViewerService";
import { useAsync } from "../../hooks/Async";
import { useTopSqlContext } from "../topSqlContext";
import planContext from "./planContext";
import { IPlanContext } from "./types";

export interface IPlanProviderProps {
  planToken?: string;
  statementId?: number;
}

const PlanProvider: React.FunctionComponent<IPlanProviderProps> = ({ children, planToken, statementId }) => {
  const { statementResults, totalsResults } = useTopSqlContext();
  const service = React.useRef<PlanViewerService>(new PlanViewerService());
  const resultsLoading: boolean = statementResults.isLoading || totalsResults.isLoading;
  const promiseFn = React.useCallback(
    (_: never, controller: AbortController): Promise<IPlanDataResponse | null> => {
      if (!planToken || !statementId || resultsLoading) {
        return Promise.resolve(null);
      }
      return service.current.fetchPlanData({ planToken, statementId }, controller.signal);
    },
    [planToken, statementId, resultsLoading],
  );
  const { data, isLoading } = useAsync(promiseFn);

  const planXMLPromiseFn = React.useCallback(
    (_: never, controller: AbortController): Promise<IRawPlanXMLResponse | null> => {
      if (resultsLoading) {
        return Promise.resolve({ rawXML: "" });
      } else if (!planToken) {
        return Promise.resolve(null);
      } else {
        return service.current.fetchRawPlanXML({ planToken }, controller.signal);
      }
    },
    [planToken, resultsLoading],
  );
  const { data: response } = useAsync(planXMLPromiseFn);

  const value = React.useMemo<IPlanContext>(() => {
    return {
      loading: isLoading,
      model: data ?? null,
      rawXML: response?.rawXML ?? "",
    };
  }, [data, isLoading, response]);

  return <planContext.Provider value={value}>{children}</planContext.Provider>;
};

export default PlanProvider;
