import Button from "@material-ui/core/Button";
import { DataTable } from "primereact/datatable";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Column } from "primereact/column";
import FormattedDate from "../../../components/FormattedDate";
import { IconButton, makeStyles, Paper, Tooltip } from "@material-ui/core";
import { Checkbox } from "primereact/checkbox";
import { IEventLogResponse } from "../../../api/AdvisoryEventsService/AdvisoryEventsService";
import { useAlertLogNotes, useAlertsLogActions } from "./useAlertsLogActions";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { INotesGridResponse } from "../../../api/models/AdvisoryConditionEvents";
import NoteModal from "./NoteModal";
import CustomSnackbar from "../../../features/TopSql/components/CustomSnackbar";
import { IModalCloseOptions } from "../../../features/Permissions/types";
import { Color } from "@material-ui/lab";
import { styles } from "../../../components/ThemeProvider/grids";
import NoteConfirmationDialog from "./NoteConfirmationDialog";

const useStyles = makeStyles((theme) => ({
  checkbox: {
    cursor: "unset",
  },
  gridArea: {
    marginLeft: theme.spacing(1.2),
    marginRight: theme.spacing(1.2),
  },
  tableHeader: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "1.143rem",
  },
  ...styles,
}));

const NotesGrid: React.FC<{
  row: IEventLogResponse;
}> = ({ row }) => {
  const intl = useIntl();
  const { data, isLoading, error } = useAlertLogNotes(row.alertID);
  const { deleteNote } = useAlertsLogActions();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [selectedNote, setSelectedNote] = React.useState<INotesGridResponse | null>(null);
  const [editing, setEditing] = React.useState(false);
  const [openToast, setOpenToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState<string>("test message");
  const [toastType, setToastType] = React.useState<Color>("success");
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState(false);

  if (error) throw error;

  const noteHeader = (
    <div className={classes.tableHeader}>
      <Button color="primary" onClick={(e) => setOpen(true)} size="small" variant="contained">
        {intl.formatMessage({ id: "add" })}
      </Button>
    </div>
  );

  const handleButtonClick = (actionType: string, rowData: INotesGridResponse): void => {
    if (actionType === "edit") {
      setOpen(true);
      setEditing(true);
    } else {
      setOpenDeleteConfirmation(true);
    }
    setSelectedNote(rowData);
  };

  const handleNoteModalClose = (modalCloseResult: IModalCloseOptions): void => {
    setOpen(false);
    setEditing(false);

    if (modalCloseResult.status === "SUCCESS" && data) {
      editing && modalCloseResult.id
        ? setToastMessage(intl.formatMessage({ id: "noteEditedSuccessfully" }))
        : setToastMessage(intl.formatMessage({ id: "noteAddedSuccessfully" }));
      setToastType("success");
      setOpenToast(true);
    } else if (modalCloseResult.status === "ERROR") {
      editing
        ? setToastMessage(intl.formatMessage({ id: "noteEditedWithError" }))
        : setToastMessage(intl.formatMessage({ id: "noteAddedWithError" }));
      setToastType("error");
      setOpenToast(true);
    }
    // If CLOSED, don't create a toast
  };

  const handleDeleteNote = async (confirmed: boolean): Promise<void> => {
    setOpenDeleteConfirmation(false);
    if (confirmed && selectedNote) {
      // This prevents DialogContent to re-render
      setLoading(true);
      const { isSuccess } = await deleteNote({
        alertId: row.alertID,
        noteId: selectedNote.noteId,
      });

      if (isSuccess) {
        setToastMessage(intl.formatMessage({ id: "noteDeletedSuccessfully" }));
        setToastType("success");
        setOpenToast(true);
      } else {
        setToastMessage(intl.formatMessage({ id: "noteDeletedWithError" }));
        setToastType("error");
        setOpenToast(true);
      }
      setLoading(false);
    }
  };

  const handleCloseToast = (): void => {
    setOpenToast(false);
  };

  const actionBodyTemplate = (rowData: INotesGridResponse): React.ReactFragment => {
    return (
      <React.Fragment key={rowData.noteId}>
        <Tooltip title={intl.formatMessage({ id: "edit" })}>
          <IconButton
            aria-label={intl.formatMessage({ id: "edit" })}
            color="primary"
            onClick={() => handleButtonClick("edit", rowData)}
            size="medium"
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={intl.formatMessage({ id: "delete" })}>
          <IconButton
            aria-label={intl.formatMessage({ id: "delete" })}
            color="secondary"
            onClick={() => handleButtonClick("delete", rowData)}
            size="medium"
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  };

  return (
    <>
      <CustomSnackbar
        animation="Slide"
        aria-label="custom-snackbar"
        autoHideDuration={20000}
        handleClose={() => handleCloseToast()}
        message={toastMessage}
        open={openToast}
        slideDirection="right"
        type={toastType}
      />
      <Paper className={classes.gridArea} variant="outlined">
        <DataTable
          dataKey="id"
          header={noteHeader}
          loading={isLoading || loading}
          responsiveLayout="scroll"
          value={data}
        >
          <Column
            body={(d) => {
              return (
                <Tooltip placement="top-start" title={d.noteText.replace(/\t/g, "  ")}>
                  <span>{d.noteText.replace(/\t/g, "  ")}</span>
                </Tooltip>
              );
            }}
            className={classes.noWrap}
            field="noteText"
            header={<FormattedMessage id="noteText" />}
            style={{ minWidth: "12rem" }}
          />
          <Column
            body={(value) => {
              return <Checkbox checked={value.includeInNotification} className={classes.checkbox} readOnly></Checkbox>;
            }}
            field="includeInNotifications"
            header={<FormattedMessage id="includeInNotifications" />}
          />
          <Column field="userName" header={<FormattedMessage id="username" />} />
          <Column
            body={(value) => {
              if (!value.logDateTimeUtc) {
                return null;
              }
              return (
                <FormattedDate
                  day="numeric"
                  hour="2-digit"
                  minute="numeric"
                  month="numeric"
                  second="numeric"
                  value={value.logDateTimeUtc}
                  year="numeric"
                />
              );
            }}
            field="logDateTimeUtc"
            header={<FormattedMessage id="logTime" />}
          />
          <Column body={actionBodyTemplate} exportable={false}></Column>
        </DataTable>
        {open && (
          <NoteModal
            alertLogId={row.alertID}
            handleNoteModalClose={handleNoteModalClose}
            openState={open}
            selectedNote={editing ? selectedNote : null}
            setIsLoading={setLoading}
          />
        )}
        {openDeleteConfirmation && (
          <NoteConfirmationDialog
            handleClose={(confirmed) => handleDeleteNote(confirmed)}
            open={openDeleteConfirmation}
          />
        )}
      </Paper>
    </>
  );
};
export default NotesGrid;
