import type { IDateTimeFormatOptions } from "../../components/FormattedDate";

export const ChartsReadColor: string = "#98FB98";
export const ChartsWriteColor: string = "#20B2AA";

export type IAsyncResult<T> =
  | { data: undefined; error: Error; isLoading: false; state: "error" }
  | { data: undefined; error: null; isLoading: true; state: "loading" }
  | { data: T; error: null; isLoading: false; state: "success" };

export const enum StorageEntityType {
  DiskController = "Controller",
  PhysicalDisk = "Physical Disk",
  LogicalVolume = "Logical Disk",
  SqlServerDataFile = "Data",
  SqlServerLogFile = "Transaction Log",
}

export interface IStorageEntityCommonTypes {
  id: string;
  name: string;
  warnings?: Array<IWarningResponse> | null;
}

export interface IDiskChartProps {
  className?: string;
  setSubHeaderText?: (subHeader: string) => void;
}

export interface IStorageDiskController extends IStorageEntityCommonTypes {
  type: StorageEntityType.DiskController;
  manufacturer: string;
  maxInboundRate: number;
  maxOutboundRate: number;
}

export interface IStoragePhysicalDisk extends IStorageEntityCommonTypes {
  sizeBytes: number;
  diskIndex: number;
  type: StorageEntityType.PhysicalDisk;
  status: string;
  diskController: Pick<IStorageDiskController, "id">;
}

export interface IStorageLogicalVolume extends IStorageEntityCommonTypes {
  sizeBytes: number;
  fileSystem: string;
  friendlyName: string;
  otherSpaceBytes: number;
  freeSpaceBytes: number;
  forecastedExhaustionDate: Date | null;
  type: StorageEntityType.LogicalVolume;
  physicalDisks: Array<Pick<IStoragePhysicalDisk, "id">>;
}
export interface IAutogrowth {
  nextGrowthBytes: number;
  type: "PERCENT" | "FIXED";
  value: number;
  vlfBytes: number;
  vlfCount: number;
}
export interface IStorageSqlServerFile extends IStorageEntityCommonTypes {
  sizeBytes: number;
  sqlServerName: string;
  databaseName: string;
  fileName: string;
  filegroupName: string | null;
  usedSizeBytes: number | null;
  usedPercentage: number | null;
  autogrowth: IAutogrowth | null;
  maxFileSizeBytes: number | null;
  totalVLFs: number | null;
  activeVLFs: number | null;
  minVLFSizeBytes: number | null;
  maxVLFSizeBytes: number | null;
  lastBackupTime: Date | null;
  lastBackupType: string | null;
  logReuseWait: string;
  logicalVolume: Pick<IStorageLogicalVolume, "id">;
  type: StorageEntityType.SqlServerDataFile | StorageEntityType.SqlServerLogFile;
}

export type IStorageEntity =
  | IStorageDiskController
  | IStoragePhysicalDisk
  | IStorageLogicalVolume
  | IStorageSqlServerFile;

export const ForecastDateFormatOptions: IDateTimeFormatOptions = {
  day: "numeric",
  month: "short",
  year: "numeric",
};

// Warning Entity Interface
export interface IWarningDiskController {
  id: string;
  name: string;
  __typename: string;
}

export interface IWarningPhysicalDisk extends IWarningDiskController {
  diskController?: {
    id: string;
    name: string;
  };
}

export interface IWarningLogicalVolume extends IWarningDiskController {
  physicalDisks?: Array<{
    id: string;
    name: string;
    diskController?: {
      id: string;
      name: string;
    };
  }>;
}

export interface IWarningSqlServerFile extends IWarningDiskController {
  logicalVolume?: {
    id: string;
    name: string;
    physicalDisks?: Array<{
      id: string;
      name: string;
      diskController?: {
        id: string;
        name: string;
      };
    }>;
  };
}

export type IWarningEntity =
  | IWarningDiskController
  | IWarningPhysicalDisk
  | IWarningLogicalVolume
  | IWarningSqlServerFile;

export type WarningEntityType =
  | (IWarningDiskController & { __typename: "DiskController" })
  | (IWarningPhysicalDisk & { __typename: "PhysicalDisk" })
  | (IWarningLogicalVolume & { __typename: "LogicalVolume" })
  | (IWarningSqlServerFile & { __typename: "SqlServerFile"; fileType: "DATA" | "LOG" });

export interface IWarningResponse {
  warningEntity: WarningEntityType;
  warningType: string | null;
  warningMessage?: string | null;
  warningValue: string | null;
  warningValueWithUnit?: string | null;
}

export type StorageEntityWarningType =
  | "NONE"
  | "TOTAL_VLFS_OVER_100"
  | "TOTAL_VLFS_OVER_300"
  | "AUTOGROWTH_IS_PERCENT"
  | "AUTOGROWTH_UNDER_TEN_MEGABYTES"
  | "AUTOGROWTH_OVER_ONE_GIGABYTES"
  | "AUTOGROWTH_OVER_REMAINING_DISK_SPACE"
  | "NON_SIMPLE_RECOVERY_MODEL_AND_NOT_BACKED_UP"
  | "FORECAST_EXHAUSTION_WITHIN_THIRTY_DAYS"
  | "LOW_FREE_SPACE_PERCENTAGE"
  | "DEFAULT_FILE_SIZE_UNDER_FIVE_MEGABYTES";
