import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { useIntl } from "react-intl";
import { useDemoMode } from "../../../../hooks/useDemoMode";
import { IDigestDashboard } from "../../useDashboards";

export interface ICreateDashboardParams {
  cloneId: string | null;
  name: string;
}

export interface ICreateDashboardProps {
  customDashboards: readonly IDigestDashboard[];
  onClose: () => void;
  onCreateDashboard: (dashboardToAdd: ICreateDashboardParams) => void;
  open: boolean;
}

const useStyles = makeStyles((theme) => ({
  descriptor: {
    marginBottom: -theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  form: {
    display: "grid",
    gridAutoColumns: "1fr",
    gridGap: theme.spacing(4),
  },
  menuItem: {
    borderRadius: 0,
  },
}));

const BLANK_DASHBOARD_ID = "blankDashboard";

const CreateDashboard: React.FunctionComponent<ICreateDashboardProps> = ({
  customDashboards,
  onClose,
  onCreateDashboard,
  open,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [cloneDashboardId, setCloneDashboardId] = React.useState<string>(BLANK_DASHBOARD_ID);
  const [dashboardName, setDashboardName] = React.useState("");
  const { enqueueDemoSnackbar, isDemoMode } = useDemoMode();

  const dashboardNameTaken = customDashboards.some(
    (d) => d.name.toLocaleLowerCase() === dashboardName.toLocaleLowerCase(),
  );
  const { name: cloneDashboardName } = customDashboards.find((d) => d.id === cloneDashboardId) ?? { name: undefined };

  async function handleSubmit(event: React.FormEvent): Promise<void> {
    event.preventDefault();
    if (isDemoMode) {
      enqueueDemoSnackbar();
    } else {
      const submittedDashboard: ICreateDashboardParams =
        cloneDashboardId === BLANK_DASHBOARD_ID
          ? { cloneId: null, name: dashboardName }
          : { cloneId: cloneDashboardId, name: dashboardName };
      onCreateDashboard(submittedDashboard);
      onClose();
    }
  }

  function resetState(): void {
    setCloneDashboardId(BLANK_DASHBOARD_ID);
    setDashboardName("");
  }

  let disabledSubmitTooltipMsg = "";
  if (dashboardNameTaken) disabledSubmitTooltipMsg = intl.formatMessage({ id: "dashboardNameInUse" });
  if (dashboardName.trim().length === 0)
    disabledSubmitTooltipMsg = intl.formatMessage({ id: "yourNewDashboardNeedsAName" });

  return (
    <Dialog TransitionProps={{ onEnter: resetState }} fullWidth maxWidth="xs" onClose={onClose} open={open}>
      <DialogTitle>{intl.formatMessage({ id: "createANewDashboard" })}</DialogTitle>
      <DialogContent>
        <form className={classes.form} id="newDashboardForm" onSubmit={handleSubmit}>
          <TextField
            autoFocus
            error={dashboardNameTaken}
            fullWidth
            helperText={dashboardNameTaken ? intl.formatMessage({ id: "dashboardNameInUse" }) : ""}
            id="dashboardNameLabel"
            inputProps={{ maxLength: "128" }}
            label={intl.formatMessage({ id: "dashboardName" })}
            name="dashboardName"
            onChange={(e) => setDashboardName(e.target.value)}
            required
            value={dashboardName}
            variant="filled"
          />
          {customDashboards.length > 0 && (
            <>
              <Typography className={classes.descriptor} color="textSecondary" id="dashboardTemplateDescriptor">
                {intl.formatMessage({ id: "createANewDashboardOrCloneAnExistingOne" })}
              </Typography>
              <FormControl fullWidth required variant="filled">
                <InputLabel id="dashboardTemplateLabel">{intl.formatMessage({ id: "dashboardTemplate" })}</InputLabel>
                <Select
                  aria-describedby="dashboardTemplateDescriptor"
                  color="primary"
                  fullWidth
                  inputProps={{ "data-testid": "cloneDashboardSelect" }}
                  labelId="dashboardTemplateLabel"
                  name="cloneDashboardId"
                  onChange={(e) => setCloneDashboardId(e.target.value as string)}
                  required
                  title={cloneDashboardName}
                  value={cloneDashboardId}
                >
                  <MenuItem className={classes.menuItem} key={BLANK_DASHBOARD_ID} value={BLANK_DASHBOARD_ID}>
                    {intl.formatMessage({ id: "blankDashboard" })}
                  </MenuItem>
                  <Divider className={classes.divider} />
                  {customDashboards.map((d) => (
                    <MenuItem className={classes.menuItem} key={d.id} value={d.id}>
                      {d.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          {intl.formatMessage({ id: "cancel" })}
        </Button>
        <Tooltip placement="top" title={disabledSubmitTooltipMsg}>
          <span>
            <Button
              color="primary"
              disabled={dashboardNameTaken || dashboardName.trim().length === 0}
              form="newDashboardForm"
              id="submitCreateDashboard"
              type="submit"
              variant="contained"
            >
              {intl.formatMessage({ id: "createDashboard" })}
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default CreateDashboard;
