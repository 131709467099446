import * as React from "react";
import MetricChart, { IMetricChartSeriesOptions, useMetrics } from "../../../../components/MetricChart";
import { IPerformanceAnalysisChartProps } from "../../types";
import { usePerformanceAnalysisContext } from "../../context";

const aMetrics = Object.freeze<IMetricChartSeriesOptions>([
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#98FB98",
    instance: "Total",
    label: "msRead",
    metric: "sqlserver.reads",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#20B2AA",
    instance: "Total",
    label: "msWrite",
    metric: "sqlserver.writes",
  }),
]);

const bMetrics = Object.freeze<IMetricChartSeriesOptions>([
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#DAA520",
    instance: null,
    label: "logFlushes",
    metric: "sqlserver.logFlushes",
  }),
]);

const cMetrics = Object.freeze<IMetricChartSeriesOptions>([
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#B8860B",
    instance: null,
    label: "checkpointPages",
    metric: "sqlserver.bufferManager.checkpointPages.ps",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#C3B091",
    instance: null,
    label: "lazyWrites",
    metric: "sqlserver.bufferManager.lazyWrites.ps",
  }),
]);

const DatabaseIOChart: React.FC<IPerformanceAnalysisChartProps> = ({ className }) => {
  const { contextMenuItems, dateRange, selectedRange, setSelectedRange, target } = usePerformanceAnalysisContext();

  const aData = useMetrics({
    dateRange,
    metrics: aMetrics,
    target,
  });
  const bData = useMetrics({
    dateRange,
    metrics: bMetrics,
    target,
  });
  const cData = useMetrics({
    dateRange,
    metrics: cMetrics,
    target,
  });
  return (
    <>
      <MetricChart
        className={className}
        contextMenuItems={contextMenuItems}
        dateRange={dateRange}
        metricData={aData}
        onSelectedRangeChange={setSelectedRange}
        selectedRange={selectedRange}
        target={target}
      />
      <MetricChart
        className={className}
        contextMenuItems={contextMenuItems}
        dateRange={dateRange}
        metricData={bData}
        onSelectedRangeChange={setSelectedRange}
        selectedRange={selectedRange}
        target={target}
      />
      <MetricChart
        className={className}
        contextMenuItems={contextMenuItems}
        dateRange={dateRange}
        metricData={cData}
        onSelectedRangeChange={setSelectedRange}
        selectedRange={selectedRange}
        target={target}
      />
    </>
  );
};

export default DatabaseIOChart;
