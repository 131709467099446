import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useIntl } from "react-intl";
import { IDateRange, useDateContext } from "../../../../components/DateContext";
import ErrorBoundary from "../../../../components/ErrorBoundary";
import MetricChart, { IMetricChartSeriesOptions, useMetrics } from "../../../../components/MetricChart";
import { ITopologyItemEventSourceConnection } from "../../../../components/TopologyContext";

export interface ITempDbActivityProps {
  activeRange: IDateRange | null;
  className?: string;
  onActiveRangeChange: (value: IDateRange | null) => void;
  target: ITopologyItemEventSourceConnection;
}

const useStyles = makeStyles({
  chart: {
    flex: 1,
  },
  content: {
    display: "flex",
    height: "250px",
  },
});

const metrics = Object.freeze<IMetricChartSeriesOptions>([
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: "usage",
    chartType: "line",
    color: "#FF8C00",
    instance: null,
    label: "activeTempTables",
    metric: "sqlserver.tempdb.tempTables",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: "transactions",
    chartType: "line",
    color: "#98FB98",
    instance: null,
    label: "nonsnapshotVersionTransactions",
    metric: "sqlserver.tempdb.nonsnapshotVersionTransactions",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: "transactions",
    chartType: "line",
    color: "#20B2AA",
    instance: null,
    label: "snapshotTransactions",
    metric: "sqlserver.tempdb.snapshotTransactions",
  }),
]);

const TempDbActivity: React.FC<ITempDbActivityProps> = ({ activeRange, className, onActiveRangeChange, target }) => {
  const intl = useIntl();
  const classes = useStyles();

  const { dateRange } = useDateContext();
  const metricData = useMetrics({
    dateRange,
    metrics,
    target,
  });

  const [selectedDateRange, setSelectedDateRange] = React.useState<IDateRange | null>(activeRange);
  React.useEffect(() => {
    setSelectedDateRange(activeRange);
  }, [activeRange]);

  return (
    <Card className={className} data-testid="tempDbActivity">
      <CardHeader title={intl.formatMessage({ id: "tempDbActivity" })} />
      <CardContent className={classes.content}>
        <ErrorBoundary variant="chart">
          <MetricChart
            className={classes.chart}
            contextMenuItems={[
              <MenuItem key="filter" onClick={() => onActiveRangeChange(selectedDateRange)}>
                {intl.formatMessage({ id: "filter" })}
              </MenuItem>,
            ]}
            dateRange={dateRange}
            metricData={metricData}
            onSelectedRangeChange={([from, to]) => setSelectedDateRange(from && to ? { from, to } : null)}
            selectedRange={[selectedDateRange?.from ?? null, selectedDateRange?.to ?? null]}
            target={target}
          />
        </ErrorBoundary>
      </CardContent>
    </Card>
  );
};

export default TempDbActivity;
