export const Errors: Array<{ id: number; name: string }> = [
    {
      id: -1,
      name: "Request Completed",
    },
    {
     id: 0,
     name: "OK",
    },
    {
      id: 1,
      name: "Error",
    },
    {
      id: 2,
      name: "Abort",
    },
    {
      id: 3,
      name: "Skipped",
    },
    {
     id: 4,
     name: "Unknown",
    }
  ];