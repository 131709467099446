import * as React from "react";
import TreeView from "@material-ui/lab/TreeView";
import { useTopology } from "../../../../../components/TopologyContext";
import ExpandIcon from "@material-ui/icons/ChevronRight";
import CollapseIcon from "@material-ui/icons/ExpandMore";
import { useLocalStorageState } from "../../../../../hooks/useLocalStorageState";
import useTopologyRouteMatch from "./hooks/useTopologyRouteMatch";
import TopologyFlatList from "./components/TopologyFlatList";
import TopologyHierarchy from "./components/TopologyHierarchy";
import TopologyFilterInput from "./components/TopologyFilterInput";
import TopologyUnwatchedSection from "./components/TopologyUnwatchedSection";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { ITopology } from "../../../../../components/TopologyContext";

interface ITopologyListProps {
  showHierarchy: boolean;
}

export interface ITopologyViewProps {
  topology: ITopology;
  filter: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    // scroll bar styles
    "&::-webkit-scrollbar": {
      width: theme.spacing(),
    },
    "&::-webkit-scrollbar-thumb": {
      background: alpha(theme.palette.common.white, 0.5),
      borderRadius: theme.shape.borderRadius,
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    display: "flex",
    flex: 1,
    flexDirection: "column",
    gap: theme.spacing(2),
    overflowX: "hidden",
    overflowY: "scroll",
    padding: theme.spacing(2, 3),
  },
  unwatchedContainer: {
    // scroll bar styles
    "&::-webkit-scrollbar": {
      width: theme.spacing(),
    },
    "&::-webkit-scrollbar-thumb": {
      background: alpha(theme.palette.common.white, 0.5),
      borderRadius: theme.shape.borderRadius,
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    gap: theme.spacing(2),
    overflowX: "hidden",
    overflowY: "scroll",
  },
}));

const TopologyList = ({ showHierarchy }: ITopologyListProps): React.ReactElement => {
  const classes = useStyles();
  const [filter, setFilter] = React.useState("");
  const [expanded, setExpanded] = useLocalStorageState<Array<string>>("expandedTargetListNodes", []);
  const topology = useTopology(true);
  const { tenantMatch, targetMatch, groupMatch } = useTopologyRouteMatch();

  // helpful constants
  const numOfTopologyItems =
    topology.eventSourceConnections.length + topology.devices.length + (showHierarchy ? topology.groups.length : 0);
  const tenantId = tenantMatch?.params.tenantId;
  const groupId = groupMatch?.params.groupId;
  const targetId = targetMatch?.params.targetId;

  // auto expand groups if selected
  React.useEffect(() => {
    setExpanded((prevExpanded) => {
      const itemsToExpand = [];

      // expand selected tenant
      if (tenantId && !prevExpanded.includes(tenantId)) {
        itemsToExpand.push(tenantId);
      }

      // expand all items starting from the selected item and working up the tree
      const lowestMatch = targetId ?? groupId ?? null;
      if (lowestMatch) {
        let item = topology.findByObjectId(lowestMatch);
        while (item) {
          if (!prevExpanded.includes(item.objectId)) {
            itemsToExpand.push(item.objectId);
          }
          item = topology.findByObjectId(item.parentObjectId ?? "");
        }
      }

      return itemsToExpand.length > 0 ? [...itemsToExpand, ...prevExpanded] : prevExpanded;
    });
  }, [targetId, groupId, tenantId, topology, setExpanded]);

  const commonProps: ITopologyViewProps = { filter, topology };

  return (
    <>
      <div className={classes.container} data-testid="topology-section">
        {numOfTopologyItems > 5 ? (
          <TopologyFilterInput onChange={(e) => setFilter(e.target.value)} value={filter} />
        ) : null}
        <TreeView
          defaultCollapseIcon={<CollapseIcon />}
          defaultExpandIcon={<ExpandIcon />}
          disableSelection
          expanded={expanded}
          onNodeToggle={(_, nodeIds) => setExpanded(nodeIds)}
          selected={[targetId ?? groupId ?? tenantId ?? ""]}
        >
          {showHierarchy ? <TopologyHierarchy {...commonProps} /> : <TopologyFlatList {...commonProps} />}
        </TreeView>
      </div>
      <div className={classes.unwatchedContainer}>
        {!showHierarchy && <TopologyUnwatchedSection {...commonProps} />}
      </div>
    </>
  );
};

export default TopologyList;