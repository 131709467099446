import { SortDescriptor } from "@sentryone/react-datatable";
import * as React from "react";
import { lastDefault, paramsDefault, readsDefault, readsLDefault } from "./sortDefaults";
import { ISortContext } from "./types";

const sortContext = React.createContext<ISortContext>({
  sort: {
    aggregatedEventLogs: lastDefault,
    eventLogs: lastDefault,
    parameters: paramsDefault,
    statements: readsLDefault,
    statementsTrace: readsDefault,
    totals: readsLDefault,
    totalsTrace: readsDefault,
  },
  updateAggregatedEventLogs: (_: readonly SortDescriptor[]) => null,
  updateEventLogs: (_: readonly SortDescriptor[]) => null,
  updateParameters: (_: readonly SortDescriptor[]) => null,
  updateStatements: (_: readonly SortDescriptor[]) => null,
  updateStatementsTrace: (_: readonly SortDescriptor[]) => null,
  updateTotals: (_: readonly SortDescriptor[]) => null,
  updateTotalsTrace: (_: readonly SortDescriptor[]) => null,
});

export default sortContext;
