import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { LoadingIndicator } from "@sentryone/material-ui";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { IBlockingDuration, useBlockingContext } from "../../../../contexts/blockingContext";
import { FilterSection } from "../FilterSection";

interface IFilterFormProps {
  closeDrawer: () => void;
}

const useStyles = makeStyles((theme) => ({
  content: {
    padding: "0",
  },
  errorMessage: {
    fontSize: "1em",
    padding: "0.1rem 1rem",
  },
  formControl: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(),
    },
  },
  loader: {
    height: "20vh",
    verticalAlign: "center",
  },
  root: {
    overflowX: "hidden",
    width: theme.spacing(65),
  },
}));

const FilterForm: React.FunctionComponent<IFilterFormProps> = ({ closeDrawer }) => {
  const { blockersByType, blockingFilter, data, error, loading, setBlockingFilter } = useBlockingContext();
  const classes = useStyles({});

  const intl = useIntl();

  const [duration, setDuration] = React.useState<IBlockingDuration>({
    unit: "Minutes",
    value: 15,
  });

  const [selections, setSelections] = React.useState({
    selectedApplications: [] as string[],
    selectedDatabases: [] as string[],
    selectedHosts: [] as string[],
    submitError: false,
  });

  const setSelectedApplications = (newSelection: string[]): void => {
    setSelections((prevSelections) => ({
      ...prevSelections,
      selectedApplications: newSelection,
      submitError:
        prevSelections.submitError &&
        (!prevSelections.selectedDatabases.length || !prevSelections.selectedHosts.length || !newSelection.length),
    }));
  };

  const setSelectedDatabases = (newSelection: string[]): void => {
    setSelections((prevSelections) => ({
      ...prevSelections,
      selectedDatabases: newSelection,
      submitError:
        prevSelections.submitError &&
        (!prevSelections.selectedApplications.length || !prevSelections.selectedHosts.length || !newSelection.length),
    }));
  };

  const setSelectedHosts = (newSelection: string[]): void => {
    setSelections((prevSelections) => ({
      ...prevSelections,
      selectedHosts: newSelection,
      submitError:
        prevSelections.submitError &&
        (!prevSelections.selectedDatabases.length ||
          !prevSelections.selectedApplications.length ||
          !newSelection.length),
    }));
  };

  const handleReset = React.useCallback(
    () => {
      setBlockingFilter({
        duration: blockingFilter.duration,
        selectedApplications: null,
        selectedDatabases: null,
        selectedHosts: null,
        selectedResource: null,
        selectedResourceType: null,
      });
      closeDrawer();
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [closeDrawer, blockersByType, blockingFilter],
  );

  React.useMemo(
    () => {
      setSelections({
        selectedApplications: [...(blockingFilter.selectedApplications ?? blockersByType.byApplications)],
        selectedDatabases: [...(blockingFilter.selectedDatabases ?? blockersByType.byDatabases)],
        selectedHosts: [...(blockingFilter.selectedHosts ?? blockersByType.byHosts)],
        submitError: false,
      });
      setDuration({ ...blockingFilter.duration });
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [blockersByType, blockingFilter, data, error, loading, setBlockingFilter],
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (
      selections.selectedApplications.length &&
      selections.selectedDatabases.length &&
      selections.selectedHosts.length
    ) {
      setBlockingFilter({
        duration,
        selectedApplications:
          selections.selectedApplications.length === blockersByType.byApplications.length
            ? null
            : selections.selectedApplications,
        selectedDatabases:
          selections.selectedDatabases.length === blockersByType.byDatabases.length
            ? null
            : selections.selectedDatabases,
        selectedHosts:
          selections.selectedHosts.length === blockersByType.byHosts.length ? null : selections.selectedHosts,
      });
      closeDrawer();
    } else {
      setSelections((prevSelections) => ({
        ...prevSelections,
        submitError: true,
      }));
    }
  };
  if (error) {
    throw error;
  }
  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <CardHeader title={intl.formatMessage({ id: "blockingFilters" })} />
      <CardContent
        classes={{
          root: classes.content,
        }}
      >
        {/* TODO: #62524 Implement Duration filter in backend
        <DurationSelection
          duration={duration}
          setDuration={setDuration}
        /> */}
        {loading || !data ? (
          <div className={classes.loader}>
            <LoadingIndicator />{" "}
          </div>
        ) : (
          <>
            <FilterSection
              filterOptions={blockersByType.byApplications}
              selectedFilters={selections.selectedApplications}
              setSelectedFilters={setSelectedApplications}
              title={intl.formatMessage({ id: "applications" })}
            />
            <FilterSection
              filterOptions={blockersByType.byDatabases}
              selectedFilters={selections.selectedDatabases}
              setSelectedFilters={setSelectedDatabases}
              title={intl.formatMessage({ id: "databases" })}
            />
            <FilterSection
              filterOptions={blockersByType.byHosts}
              selectedFilters={selections.selectedHosts}
              setSelectedFilters={setSelectedHosts}
              title={intl.formatMessage({ id: "hosts" })}
            />
          </>
        )}
      </CardContent>
      {selections.submitError && (
        <Typography
          classes={{
            root: classes.errorMessage,
          }}
          color="error"
        >
          <FormattedMessage id="noFilterSelected" />
        </Typography>
      )}
      <CardActions>
        <Button color="primary" onClick={handleReset} size="small" variant="text">
          <FormattedMessage id="reset" />
        </Button>
        <Button color="primary" size="small" type="submit" variant="contained">
          <FormattedMessage id="apply" />
        </Button>
      </CardActions>
    </form>
  );
};

export default FilterForm;
