import { makeStyles } from "@material-ui/core/styles";
import { MuiDataTable } from "@sentryone/material-ui";
import { Column } from "@sentryone/react-datatable";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { IQueryParameter } from "../../../../../../api/TopSqlService/models/PlanData";
import { styles } from "../../../../../../components/ThemeProvider/grids";
import { useSortContext } from "../../../../../../contexts/sortContext";

interface IParametersGridProps {
  className?: string;
  data?: readonly IQueryParameter[];
}

const useStyles = makeStyles(styles);

const ParametersGrid: React.FC<IParametersGridProps> = ({ className, data }) => {
  const classes = useStyles();
  const { sort, updateParameters } = useSortContext();

  return (
    <div className={className}>
      <MuiDataTable
        data={data ?? []}
        defaultPageSize={5}
        defaultSort={[{ direction: "asc", id: "parameterName" }]}
        keySelector={(d) => d.parameterName}
        onChangeSort={updateParameters}
        pageVariant="rolling"
        sort={sort.parameters}
      >
        <Column<IQueryParameter>
          cellProps={() => ({ className: classes.noWrap })}
          field="parameterName"
          header={<FormattedMessage id="parameter" />}
        />
        <Column<IQueryParameter>
          cellProps={() => ({ className: classes.noWrap })}
          disableSort
          field="compiledValue"
          header={<FormattedMessage id="compiledValue" />}
        />
      </MuiDataTable>
    </div>
  );
};

export default ParametersGrid;
