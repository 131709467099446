import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { useUID } from "react-uid";

const useStyles = makeStyles((theme) => ({
  input: {
    height: "2.9em",
  },
  label: {
    fontSize: "1em",
  },
  root: {
    width: theme.typography.pxToRem(theme.spacing(22)),
  },
  select: {
    fontSize: "0.8em",
  },
}));

interface IQueryHistoryDropdownProps {
  children: React.ReactNode;
  className?: string;
  label: React.ReactNode;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value: string;
}

const QueryHistoryDropdown: React.FunctionComponent<IQueryHistoryDropdownProps> = ({
  children,
  className,
  label,
  onChange,
  value,
}) => {
  const classes = useStyles({});
  const id = useUID();
  return (
    <FormControl className={classNames(classes.root, className)} margin="none" variant="filled">
      <InputLabel className={classes.label} htmlFor={id}>
        {label}
      </InputLabel>
      <Select
        className={classes.input}
        classes={{
          select: classes.select,
        }}
        input={<FilledInput id={id} />}
        onChange={(e) => onChange(e as React.ChangeEvent<HTMLSelectElement>)}
        value={value}
      >
        {children}
      </Select>
    </FormControl>
  );
};

export default QueryHistoryDropdown;
