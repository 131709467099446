import { Column, ColumnPropsAuto } from "@sentryone/react-datatable";
import * as React from "react";
import { FormatNumberOptions, useIntl } from "react-intl";

export interface NumericColumnProps<TEntity> extends ColumnPropsAuto<TEntity> {
  format?: FormatNumberOptions;
}

export default function NumericColumn<TEntity>({
  format,
  ...props
}: NumericColumnProps<TEntity>): React.ReactElement | null {
  const intl = useIntl();
  const renderCell = (d: TEntity): React.ReactNode => {
    const value = d[props.field];
    if (typeof value === "number") {
      return intl.formatNumber(value, format);
    } else if (value) {
      throw new Error(`Value in NumericColumn is not of type number. Value is of type ${typeof value}.`);
    } else {
      return null;
    }
  };
  return <Column<TEntity> align="left" defaultSortDirection="desc" renderCell={renderCell} {...props} />;
}
