import {
  PerformanceAnalysisSubMenu,
  PerformanceAnalysisAggregateType,
  QueryAggregateType,
  QueriesChartSubMenu,
  TopSqlChartType,
} from "../../types";
import { ITranslation } from "../../../../../../intl/types";

export type ChartSubMenuEnum = QueryAggregateType | PerformanceAnalysisAggregateType;

export interface IChartOption {
  id: TopSqlChartType;
  subMenu: readonly ISubMenuOption[];
  subMenuDefault?: ChartSubMenuEnum;
}

export interface ISubMenuOption {
  label: keyof ITranslation;
  value: ChartSubMenuEnum;
}

const performanceAnalysisDefault = PerformanceAnalysisAggregateType.reads;
export const chartList: IChartOption[] = [
  { id: TopSqlChartType.Waits, subMenu: [] },
  { id: TopSqlChartType.Resources, subMenu: [] },
  { id: TopSqlChartType.Queries, subMenu: QueriesChartSubMenu, subMenuDefault: QueryAggregateType.readsL },
  { id: TopSqlChartType.ByApp, subMenu: PerformanceAnalysisSubMenu, subMenuDefault: performanceAnalysisDefault },
  { id: TopSqlChartType.ByDatabase, subMenu: PerformanceAnalysisSubMenu, subMenuDefault: performanceAnalysisDefault },
  { id: TopSqlChartType.ByHost, subMenu: PerformanceAnalysisSubMenu, subMenuDefault: performanceAnalysisDefault },
  { id: TopSqlChartType.ByLogin, subMenu: PerformanceAnalysisSubMenu, subMenuDefault: performanceAnalysisDefault },
];
