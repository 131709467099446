import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import classNames from "classnames";
import * as React from "react";
import { useIntl } from "react-intl";
import { ITopologyItemDevice, ITopologyItemEventSourceConnection } from "src/components/TopologyContext";
import { ICustomChartConfig } from "../../types";
import WidgetList from "./components/WidgetList";

interface IWidgetTemplateSidebarProps {
  addWidget: (newWidget: ICustomChartConfig) => void;
  dashboardName: string;
  onDashboardNameChange: (newName: string) => void;
  open: boolean;
  targets: Array<ITopologyItemDevice | ITopologyItemEventSourceConnection>;
}

const useStyles = makeStyles((theme) => ({
  drawerOpen: {
    width: theme.spacing(40),
  },
  drawerPaper: {
    bottom: 0,
    height: "auto",
    overflowX: "hidden",
    // bottom needs extra padding so that the last widget item doesn't get stuck behind the S1 Help Center button
    padding: theme.spacing(2, 4, 9, 4),
    top: "64px",
  },
}));

const WidgetTemplateSidebar: React.FC<IWidgetTemplateSidebarProps> = ({
  addWidget,
  dashboardName,
  onDashboardNameChange,
  open,
  targets,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Drawer
      anchor="right"
      aria-expanded={open}
      aria-label={intl.formatMessage({ id: "chartSidebar" })}
      classes={{
        paper: classNames(classes.drawerPaper, {
          [classes.drawerOpen]: open,
        }),
      }}
      open={open}
      variant="persistent"
    >
      <TextField
        InputLabelProps={{ htmlFor: "dashboardName", shrink: true }}
        InputProps={{ id: "dashboardName", name: "dashboardName" }}
        inputProps={{ maxLength: 50 }}
        label={intl.formatMessage({ id: "dashboardName" })}
        onChange={(e) => onDashboardNameChange(e.target.value)}
        value={dashboardName}
        variant="filled"
      />
      <WidgetList addWidget={addWidget} targets={targets} />
    </Drawer>
  );
};

export default WidgetTemplateSidebar;
