import * as React from "react";
import MetricChart, { IMetricChartSeriesOptions, useMetrics } from "../../../../components/MetricChart";
import { IPerformanceAnalysisChartProps } from "../../types";
import { usePerformanceAnalysisContext } from "../../context";

const aMetrics = Object.freeze<IMetricChartSeriesOptions>([
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#00BFFF",
    instance: null,
    label: "batches",
    metric: "sqlserver.sqlstatistics.batches.ps",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#FF7F50",
    instance: null,
    label: "compiles",
    metric: "sqlserver.sqlstatistics.compiles.ps",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#FF4500",
    instance: null,
    label: "recompiles",
    metric: "sqlserver.sqlstatistics.recompiles.ps",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#3CB371",
    instance: null,
    label: "transactions",
    metric: "sqlserver.transactions",
  }),
]);

const bMetrics = Object.freeze<IMetricChartSeriesOptions>([
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#48D1CC",
    instance: null,
    label: "keyLookups",
    metric: "sqlserver.accessMethods.keyLookups.ps",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#F4A460",
    instance: null,
    label: "forwardRecords",
    metric: "sqlserver.accessMethods.forwardedRecords.ps",
  }),
]);

const cMetrics = Object.freeze<IMetricChartSeriesOptions>([
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#6495ED",
    instance: null,
    label: "backupRestoreMb",
    metric: "sqlserver.backupRestore",
  }),
]);

const SqlServerActivityChart: React.FC<IPerformanceAnalysisChartProps> = ({ className }) => {
  const { contextMenuItems, dateRange, selectedRange, setSelectedRange, target } = usePerformanceAnalysisContext();
  const aData = useMetrics({
    dateRange,
    metrics: aMetrics,
    target,
  });
  const bData = useMetrics({
    dateRange,
    metrics: bMetrics,
    target,
  });
  const cData = useMetrics({
    dateRange,
    metrics: cMetrics,
    target,
  });
  return (
    <>
      <MetricChart
        className={className}
        contextMenuItems={contextMenuItems}
        dateRange={dateRange}
        metricData={aData}
        onSelectedRangeChange={setSelectedRange}
        selectedRange={selectedRange}
        target={target}
      />
      <MetricChart
        className={className}
        contextMenuItems={contextMenuItems}
        dateRange={dateRange}
        metricData={bData}
        onSelectedRangeChange={setSelectedRange}
        selectedRange={selectedRange}
        target={target}
      />
      <MetricChart
        className={className}
        contextMenuItems={contextMenuItems}
        dateRange={dateRange}
        metricData={cData}
        onSelectedRangeChange={setSelectedRange}
        selectedRange={selectedRange}
        target={target}
      />
    </>
  );
};

export default SqlServerActivityChart;
