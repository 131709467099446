import * as go from "gojs";
import { Diagram, DiagramEvent } from "gojs";
import * as React from "react";
import { useUID } from "react-uid";
import {
  IPlanDataLinkInfo,
  IPlanDataNode,
  IPlanDataOpacityInfo,
  IPlanDataOperationCosts,
  IPlanDataResponse,
  PlanDataOverlay,
} from "../../../../../../api/PlanViewerService/models/PlanViewer";
import PlanDiagramIcon from "./PlanDiagramIcons";

/* GoJS Initialization Start */

// tslint:disable-next-line:max-line-length
(go as any).licenseKey =
  "73ff41ebb51c28c702d95d76423d38f919a1756499811ea45e0311f6eb0d6a06329fe02803d38d9387ac1efe187dc689d8c16820c348053ce130d4da42e085aab73326e1460b1089f10b25d189fc7ff1fa7823a2c3a767a1db7885edeeb7c099";
go.Shape.defineFigureGenerator("Pill", (shape, w, h) => {
  // predefined in 2.0
  const radius = h / 2;
  const geo = new go.Geometry().add(
    new go.PathFigure(radius, 0, true)
      .add(new go.PathSegment(go.PathSegment.Line, w - radius, 0))
      .add(new go.PathSegment(go.PathSegment.Arc, 270, 180, w - radius, radius, radius, radius))
      .add(new go.PathSegment(go.PathSegment.Line, radius, radius * 2))
      .add(new go.PathSegment(go.PathSegment.Arc, 90, 180, radius, radius, radius, radius).close()),
  );
  return geo;
});

/* GoJS Initialization End */

interface IDiagramNode {
  additionalIndexesText: string | null;
  additionalIndexesBackgroundColor: string | null;
  additionalIndexesForegroundColor: string | null;
  cost: string;
  costFont: string;
  costBackgroundColor: string | null;
  costForegroundColor: string | null;
  depth: number;
  depthWidth: number;
  indexName: string;
  isCollapsed: boolean;
  key: string;
  name: string;
  nameBackgroundColor: string | null;
  nameForegroundColor: string | null;
  nodeId: number;
  objectName: string;
  objectNameBackgroundColor: string | null;
  opacity: number;
  overlay: PlanDataOverlay;
  shortName: string;
}

interface IDiagramLink {
  isFirstChild: boolean;
  thick: number;
  from: string;
  fromSpot: string;
  to: string;
  toSpot: string;
  text: string;
  textBackgroundColor: string | null;
  textForegroundColor: string | null;
}

interface IDiagramModel {
  linkDataArray: IDiagramLink[];
  nodeDataArray: IDiagramNode[];
}

interface IPlanDiagramProps {
  data: IPlanDataResponse;
  showColorScale: boolean;
  className?: string;
  animate: boolean;
}

function getOverlay(overlay: PlanDataOverlay): string | null {
  switch (overlay) {
    case PlanDataOverlay.Information:
      return PlanDiagramIcon("OverlayInformation");
    case PlanDataOverlay.Warning:
      return PlanDiagramIcon("OverlayWarning");
    case PlanDataOverlay.Error:
      return PlanDiagramIcon("OverlayError");
    case PlanDataOverlay.Parallel:
      return PlanDiagramIcon("OverlayParallel");
    case PlanDataOverlay.BatchMode:
      return PlanDiagramIcon("OverlayBatchMode");
    case PlanDataOverlay.BatchModeParallel:
      return PlanDiagramIcon("OverlayBatchModeParallel");
    default:
      return null;
  }
}

function mouseEnterLink(e: any, obj: go.Link): void {
  obj.isHighlighted = true;
}

function mouseLeaveLink(e: any, obj: go.Link): void {
  obj.isHighlighted = false;
}

function expandFrom(node: go.Node, start: go.Node): void {
  if (node !== start) {
    node.diagram.model.setDataProperty(node.data, "visible", true);
  }

  if (!node.data.isCollapsed) {
    return;
  }

  node.diagram.model.setDataProperty(node.data, "isCollapsed", false);

  node.findNodesOutOf().each((n) => {
    expandFrom(n, node);
  });
}

function collapseFrom(node: go.Node, start: go.Node): void {
  if (node !== start) {
    node.diagram.model.setDataProperty(node.data, "visible", false);
  }

  if (node.data.isCollapsed) {
    return;
  }

  node.diagram.model.setDataProperty(node.data, "isCollapsed", true);

  node.findNodesOutOf().each((n) => {
    collapseFrom(n, node);
  });
}

function getNodeTemplate(): go.Part {
  const $go = go.GraphObject.make;

  const dmlBackground = "#27A6F0";
  const fontColor = "#555";
  const fontNode = "normal 12px Ubuntu";
  const fontNodeTitle = "bold 13px Ubuntu";
  const selectedIconBorderColor = "#27A6F0";

  const nodeTemplate = $go(
    go.Node,
    "Vertical",
    {
      deletable: false,
      selectionAdornmentTemplate: $go(
        go.Adornment,
        "Auto",
        $go(go.Shape, "Rectangle", { fill: null, stroke: selectedIconBorderColor, strokeWidth: 2 }),
        $go(go.Placeholder),
      ),
    },
    new go.Binding("visible"),
    new go.Binding("width", "depthWidth", (x) => (x + 2) * 7),
    new go.Binding("opacity", "opacity"),

    // Each node has a tooltip that reveals the name of its icon
    $go(
      go.Panel,
      "Auto",
      { width: 88 },
      $go(
        go.Panel,
        "Vertical",
        // Cost Label
        $go(
          go.Panel,
          "Auto",
          { height: 18 },
          $go(
            go.Shape,
            "Pill",
            { alignment: go.Spot.Center, strokeWidth: 0 },
            new go.Binding("fill", "costBackgroundColor"),
          ),
          $go(
            go.TextBlock,
            {
              alignment: go.Spot.Center,
              margin: new go.Margin(1, 8, 0, 8),
            },
            new go.Binding("font", "costFont"),
            new go.Binding("text", "cost"),
            new go.Binding("stroke", "costForegroundColor"),
          ),
        ),

        // Operation Icon Set
        $go(
          go.Panel,
          "Auto",
          { fromSpot: go.Spot.Left, height: 40, portId: "", toSpot: go.Spot.Right, width: 60 },
          $go(
            go.Panel,
            "Auto",
            { height: 40, margin: new go.Margin(0, 10, 0, 10), width: 40 },
            // Operation Icon
            $go(
              go.Picture,
              { height: 40, isPanelMain: true, width: 40 },
              new go.Binding("source", "shortName", PlanDiagramIcon),
            ),

            // Operation Warning Overlay
            $go(
              go.Picture,
              { alignment: go.Spot.TopLeft, height: 40, width: 40 },
              new go.Binding("visible", "overlay", (o) => o > 0),
              new go.Binding("source", "overlay", (o) => getOverlay(o)),
            ),
          ),
        ),
      ),

      // Expand / Collapse Button
      $go(
        "Button",
        {
          alignment: go.Spot.TopRight,
          click: (e: any, obj: any) => {
            e.diagram.startTransaction();
            const node = obj.part as go.Node;
            if (node.data.isCollapsed) {
              expandFrom(node, node);
            } else {
              collapseFrom(node, node);
            }
            e.diagram.commitTransaction("toggled visibility of dependencies");
          },
          margin: new go.Margin(2.5, 0, 0, 0),
        },
        new go.Binding("visible", "", (obj) => obj.findLinksOutOf().count > 1).ofObject(),
        $go(
          go.Shape,
          { desiredSize: new go.Size(6, 6), name: "ButtonIcon" },
          new go.Binding("figure", "isCollapsed", (c) => (c ? "PlusLine" : "MinusLine")),
        ),
      ),
    ),

    // Operation Name
    $go(
      go.Panel,
      "Auto",
      { margin: new go.Margin(2, 0, 2, 0) },
      $go(
        go.Shape,
        "Pill",
        { alignment: go.Spot.Center, strokeWidth: 0 },
        new go.Binding("fill", "nameBackgroundColor"),
      ),
      $go(
        go.TextBlock,
        {
          alignment: go.Spot.Center,
          font: fontNodeTitle,
          margin: new go.Margin(3, 8, 1, 8),
          textAlign: "center",
        },
        new go.Binding("text", "name"),
        new go.Binding("stroke", "nameForegroundColor", (x) => x || fontColor),
      ),
    ),

    // Object Name
    $go(
      go.TextBlock,
      {
        font: fontNode,
        margin: new go.Margin(3, 0, 3, 0),
        stroke: fontColor,
        textAlign: "center",
      },
      new go.Binding("background", "objectNameBackgroundColor"),
      new go.Binding("text", "objectName"),
      new go.Binding("visible", "objectName", (t) => !!t),
    ),

    // Index Name
    $go(
      go.TextBlock,
      {
        font: fontNode,
        margin: 1,
        stroke: fontColor,
        textAlign: "center",
      },
      new go.Binding("text", "indexName"),
      new go.Binding("visible", "indexName", (t) => !!t),
    ),

    $go(
      go.Panel,
      "Auto",
      { height: 18, margin: new go.Margin(2, 0, 0, 0) },
      new go.Binding("visible", "additionalIndexesText", (t) => !!t),
      $go(go.Shape, "Pill", { fill: dmlBackground, strokeWidth: 0 }),
      $go(
        go.TextBlock,
        {
          font: fontNode,
          margin: new go.Margin(1, 8, 0, 8),
          stroke: "white",
          textAlign: "center",
        },
        new go.Binding("text", "additionalIndexesText", (t) => t || ""),
      ),
    ),
  );

  return nodeTemplate;
}

function getLinkTextOffset(model: IDiagramLink): go.Point {
  const thicknessOffset = Math.floor(model.thick / 2);
  const offset = model.isFirstChild ? -10 - thicknessOffset : 13 + thicknessOffset;
  return new go.Point(NaN, offset);
}

function getLinkTemplate(): go.Link {
  const $go = go.GraphObject.make;

  const linkColors = {
    active: "slategray",
    default: "slategray",
  };

  const fontLink = "normal 12px Ubuntu";

  const linkTemplate = $go(
    go.Link, // the whole link panel
    {
      deletable: false,
      fromEndSegmentLength: 30,
      fromShortLength: 6,
      mouseEnter: mouseEnterLink,
      mouseLeave: mouseLeaveLink,
      toEndSegmentLength: 40,
    },
    new go.Binding("layerName", "isHighlighted", (h) => (h ? "Foreground" : "")).ofObject(),
    new go.Binding("fromSpot", "fromSpot", go.Spot.parse),
    new go.Binding("toSpot", "toSpot", go.Spot.parse),
    $go(
      go.Shape,
      new go.Binding("strokeWidth", "thick"),
      // the Shape.stroke color depends on whether Link.isHighlighted is true
      new go.Binding("stroke", "isHighlighted", (h) => (h ? linkColors.active : linkColors.default)).ofObject(),
    ),
    $go(
      go.Shape, // the "from" arrowhead
      {
        fromArrow: "BackwardTriangle",
      },
      new go.Binding("strokeWidth", "thick"),
      new go.Binding("fill", "isHighlighted", (h) => (h ? linkColors.active : linkColors.default)).ofObject(),
      new go.Binding("stroke", "isHighlighted", (h) => (h ? linkColors.active : linkColors.default)).ofObject(),
    ),
    $go(
      go.TextBlock,
      {
        font: fontLink,
        segmentIndex: -1,
        segmentOrientation: go.Link.OrientUpright,
      },
      new go.Binding("text", "text"),
      new go.Binding("stroke", "textForegroundColor"),
      new go.Binding("background", "textBackgroundColor"),
      new go.Binding("segmentOffset", "", getLinkTextOffset),
    ),
  );

  return linkTemplate;
}

function animationFinished(diagram: Diagram, animateRef: React.MutableRefObject<boolean>): void {
  diagram.animationManager.isEnabled = false;
  animateRef.current = false;
}

function setAllowScroll(diagram: Diagram): void {
  const documentBounds = diagram.documentBounds;
  const viewportBounds = diagram.viewportBounds;

  if (
    isNaN(documentBounds.width) ||
    isNaN(documentBounds.height) ||
    viewportBounds.width < 5 ||
    viewportBounds.height < 5 ||
    diagram.model.nodeDataArray.length === 0
  ) {
    return;
  }

  const allowHScroll = viewportBounds.width < documentBounds.width;
  const allowVScroll = viewportBounds.height < documentBounds.height;
  const prevAllowHScroll = diagram.allowHorizontalScroll;
  const prevAllowVScroll = diagram.allowVerticalScroll;

  if (allowHScroll === prevAllowHScroll && allowVScroll === prevAllowVScroll) {
    return;
  }

  diagram.allowHorizontalScroll = allowHScroll;
  diagram.allowVerticalScroll = allowVScroll;

  diagram.requestUpdate(true);
}

function recurse(
  parent: IPlanDataNode | null,
  element: IPlanDataNode,
  depth: number,
  costs: IPlanDataOperationCosts,
  opacityInfo: IPlanDataOpacityInfo,
  linkInfo: IPlanDataLinkInfo,
  depthWidth: number[],
  showColorScale: boolean,
): IDiagramModel {
  const result: IDiagramModel = { linkDataArray: [], nodeDataArray: [] };

  element.children.forEach((child) => {
    const childResult = recurse(element, child, depth + 1, costs, opacityInfo, linkInfo, depthWidth, showColorScale);
    result.linkDataArray = result.linkDataArray.concat(childResult.linkDataArray);
    result.nodeDataArray = result.nodeDataArray.concat(childResult.nodeDataArray);
  });

  const isFirstChild = !!parent && parent.children[0] === element;

  if (isFirstChild && parent) {
    depthWidth[depth] = Math.max(
      10,
      parent.children.reduce((max, child) => {
        const operationLength = child.operationName.split("\r\n").reduce((m, line) => Math.max(m, line.length), 0);
        const objectNameLength = (child.objectName && child.objectName.length) || 0;
        const indexNameLength = (child.indexName && child.indexName.length) || 0;

        return Math.max(max, operationLength, objectNameLength, indexNameLength);
      }, depthWidth[depth] || 11),
    );
  }

  const elementCostData = costs.costs[element.nodeId];
  const costBackgroundColor = showColorScale ? elementCostData.backgroundColor : "rgba(255, 255, 255, 1)";
  const costForegroundColor = showColorScale ? elementCostData.foregroundColor : "rgba(0, 0, 0, 1)";
  const costFont = costBackgroundColor === "rgba(255, 255, 255, 1)" ? "bold 13px Ubuntu" : "normal 13px Ubuntu";

  const node: IDiagramNode = {
    additionalIndexesBackgroundColor: element.additionalIndexes ? element.additionalIndexes.backgroundColor : null,
    additionalIndexesForegroundColor: element.additionalIndexes ? element.additionalIndexes.foregroundColor : null,
    additionalIndexesText: element.additionalIndexes ? element.additionalIndexes.text : null,
    cost: (elementCostData.costPercent * 100).toFixed(1) + "%",
    costBackgroundColor,
    costFont,
    costForegroundColor,
    depth,
    depthWidth: 0,
    indexName: element.indexName,
    isCollapsed: false,
    key: element.id.toString(),
    name: element.operationName,
    nameBackgroundColor: element.operationTextBackgroundColor,
    nameForegroundColor: element.operationTextForegroundColor,
    nodeId: element.nodeId,
    objectName: element.objectName ? element.objectName : "",
    objectNameBackgroundColor: null,
    opacity: opacityInfo.isImmaterial[element.nodeId] ? 0.5 : 1,
    overlay: element.overlay,
    shortName: element.shortName,
  };

  result.nodeDataArray.push(node);
  if (parent) {
    const elementLinkInfo = linkInfo.links[element.nodeId];
    const link: IDiagramLink = {
      from: parent.id.toString(),
      fromSpot: "Right",
      isFirstChild,
      text: elementLinkInfo.text,
      textBackgroundColor: elementLinkInfo.textBackgroundColor,
      textForegroundColor: elementLinkInfo.textForegroundColor,
      thick: Math.max(elementLinkInfo.widthPercent * 14, 1),
      to: element.id.toString(),
      toSpot: "Left",
    };

    result.linkDataArray.push(link);
  }

  return result;
}

export function parsePlanData(
  node: IPlanDataNode,
  costs: IPlanDataOperationCosts,
  opacityInfo: IPlanDataOpacityInfo,
  linkInfo: IPlanDataLinkInfo,
  showColorScale: boolean,
): IDiagramModel {
  const depthWidth: number[] = [11];

  const model = recurse(null, node, 0, costs, opacityInfo, linkInfo, depthWidth, showColorScale);

  model.nodeDataArray = model.nodeDataArray.map((x) => ({
    ...x,
    depthWidth: depthWidth[x.depth],
  }));

  return model;
}

function updateDiagram(diagram: Diagram, data: IPlanDataResponse, showColorScale: boolean): void {
  const { rootRelOp, operationCosts, opacityInfo, linkInfo } = data;
  const model = parsePlanData(rootRelOp, operationCosts, opacityInfo, linkInfo, showColorScale);
  const $go = go.GraphObject.make;

  diagram.model = $go(go.GraphLinksModel, {
    linkDataArray: model.linkDataArray,
    nodeDataArray: model.nodeDataArray,
  });
  diagram.model.isReadOnly = true;
}

export default function usePlanDiagram(
  props: IPlanDiagramProps,
): { className: string | undefined; id: string; ref: React.RefObject<HTMLDivElement> } {
  const animateRef = React.useRef<boolean>(!!props.animate);
  const diagramRef = React.useRef<Diagram>();
  const diagramDivRef = React.useRef<HTMLDivElement>(null);
  const divId = useUID();
  const $go = go.GraphObject.make;

  React.useEffect(
    () => {
      if (!diagramDivRef.current) {
        throw new Error("usePlanDiagram ref was not applied to a DOM element");
      }
      const diagram: Diagram = $go(go.Diagram, diagramDivRef.current.id, {
        AnimationFinished: (e: DiagramEvent) => animationFinished(e.diagram, animateRef),
        DocumentBoundsChanged: (e: DiagramEvent) => setAllowScroll(e.diagram),
        ViewportBoundsChanged: (e: DiagramEvent) => setAllowScroll(e.diagram),
        initialAutoScale: go.Diagram.Uniform,
        initialContentAlignment: go.Spot.LeftCenter,
        layout: $go(go.TreeLayout, { alignment: go.TreeLayout.AlignmentStart }),
        linkTemplate: getLinkTemplate(),
        nodeTemplate: getNodeTemplate(),
        padding: new go.Margin(10, 5, 5, 5),
        scale: 0.8,
      });
      diagram.animationManager.isEnabled = animateRef.current;
      diagram.undoManager.isEnabled = false;
      diagram.toolManager.hoverDelay = 100;
      diagram.toolManager.panningTool.isEnabled = false;
      diagram.toolManager.draggingTool.isCopyEnabled = false;

      diagramRef.current = diagram;
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  React.useEffect(() => {
    if (diagramRef.current) {
      updateDiagram(diagramRef.current, props.data, props.showColorScale);
    }
    return () => {
      diagramRef.current?.clear();
    };
  }, [props.data, props.showColorScale]);

  return { className: props.className, id: divId, ref: diagramDivRef };
}
