import { SvgIconProps } from "@material-ui/core/SvgIcon";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import DoneIcon from "@material-ui/icons/Done";
import * as React from "react";
import { useIntl } from "react-intl";
import { StopCircleIcon } from "../../../../components/icons";

export interface BlockingStatusIconProps extends SvgIconProps {
  status: "blocked" | "completed" | "running";
}

const BlockingStatusIcon: React.FunctionComponent<BlockingStatusIconProps> = ({ status, ...props }) => {
  const intl = useIntl();
  switch (status) {
    case "blocked":
      return <StopCircleIcon fontSize="medium" titleAccess={intl.formatMessage({ id: "blocked" })} {...props} />;
    case "completed":
      return <DoneIcon fontSize="medium" titleAccess={intl.formatMessage({ id: "completed" })} {...props} />;
    case "running":
      return <DirectionsRunIcon fontSize="medium" titleAccess={intl.formatMessage({ id: "running" })} {...props} />;
  }
};

export default BlockingStatusIcon;
