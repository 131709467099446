import { useContext } from "react";
import planContext from "./planContext";
import { IPlanContext } from "./types";

export function usePlanContext(): IPlanContext {
  const value = useContext(planContext);
  if (!value) {
    throw new Error("usePlanContext can only be used in a descendant of PlanProvider");
  }
  return value;
}
