import { createContext } from "react";
import { IMonitorFeatures } from "../../api/FeatureFlags/types";
import { IFeatureContext } from "./types";

/**
 * Default features to use until feature flags are retrieved from service.
 */
export const defaultFeatures: IMonitorFeatures = {
  advisoryEdit: false,
  alertConfiguration: false,
  authenticationService: false,
  demoMode: false,
  jumpToSqlSentry: false,
  notifications: false,
  showAlertCategory: false,
  userAndGroupCreation: false,
  vmWareDashboard: false,
};

const featureContext = createContext<IFeatureContext>({
  features: defaultFeatures,
  hasFeature: (feature) => defaultFeatures[feature],
  reload: () => {
    throw new Error("Feature context reload called without a FeatureFlagProvider");
  },
});
featureContext.displayName = "FeatureFlagContext";

export default featureContext;
