import * as React from "react";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import {
  FocusedDialogSelector,
  FocusedDialogSelectorPopover,
  useFocusedDialogSelectorWithPopover,
} from "../../../../components/FocusedDialog";
import { ITopologyItem } from "../../../../components/TopologyContext";
import TopologyList from "../Sidebar/TopologyList";
import { makeStyles } from "@material-ui/core/styles";

export interface ITargetDialogHeaderSelectorProps {
  target: ITopologyItem;
}

const useStyles = makeStyles((theme) => ({
  popover: {
    overflowY: "scroll", // Always allocate scrollbar width to avoid layout shift
    padding: theme.spacing(1, 1, 1, 2), // gives the text field label some room at the top of the container
    width: theme.typography.pxToRem(330), // 330 based on provided spec
  },
  selector: {
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(4),
  },
}));

const TargetDialogHeaderSelector: React.FC<ITargetDialogHeaderSelectorProps> = ({ target }) => {
  const classes = useStyles();
  const {
    onPopoverClose,
    onSelectorClick,
    popoverAnchorEl,
    popoverOpen,
    selectorRef,
  } = useFocusedDialogSelectorWithPopover();

  // if the user selects a new target from the selector, close the menus
  React.useEffect(() => {
    onPopoverClose();
  }, [onPopoverClose, target]);

  return (
    <>
      <FocusedDialogSelector
        aria-controls={popoverOpen ? "show-target-list" : undefined}
        aria-haspopup="true"
        className={classes.selector}
        data-testid="targetSelect"
        onClick={onSelectorClick}
        ref={selectorRef}
        secondaryText={target.description}
        startIcon={<MyLocationIcon />}
        text={target.name}
      />
      <FocusedDialogSelectorPopover
        anchorEl={popoverAnchorEl}
        data-testid="targetSelectPopover"
        onClose={onPopoverClose}
        open={popoverOpen}
        paperClassName={classes.popover}
      >
        <TopologyList showHierarchy={false} />
      </FocusedDialogSelectorPopover>
    </>
  );
};

export default TargetDialogHeaderSelector;
