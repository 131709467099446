export const ActionType: Array<{ id: string; name: string }> = [
  {
    id: "554540EA-79A3-4CCD-970F-360929212B3B",
    name: "Log To Windows Event Log",
  },  
  {
    id: "36AA7347-432E-489E-BD7D-4022B8F0EFE4",
    name: "Send To Alerting Channels",
  },
  {
    id: "4CC2B69C-843F-4E94-9826-ABF232CD29A5",
    name: "Log To Disk",
  },
  {
    id: "1CC87611-A0E1-4257-BD92-0FFB676DDEA7",
    name: "Execute Job",
  },
  {
    id: "6C9BDF0F-DBD9-4B1C-987C-669469A0C187",
    name: "Execute PowerShell",
  },
  {
    id: "FD4CD7A4-8B9E-4BF9-AA52-F9A00299E465",
    name: "Execute Process",
  },
  {
    id: "77A873F2-931F-40C1-B4AE-FE8F0E0FD6DF",
    name: "Execute SQL",
  },
  {
    id: "912551CF-FBE4-44DA-BF6B-564DCFA44740",
    name: "Kill Task",
  },
  {
    id: "29A25894-9E81-4AC0-860C-06F970924FAB",
    name: "Log To Database",
  },
  {
    id: "E2DC519A-9206-4E72-B4AF-F9CC1B365A5F",
    name: "Run QuickTrace",
  },
  {
    id: "B08B4C03-414D-41E8-AA5D-10865C6F95F3",
    name: "Send Email",
  },
  {
    id: "FEBBE8EA-02E4-42BC-B6DF-7C58D4B791D4",
    name: "Send Page",
  },
  {
    id: "49580DDA-1B1F-4AE6-B732-411F6C231C1B",
    name: "Send SNMP Trap",
  },
];

export const Severity: Array<{ id: number; name: string }> = [
  {
    id: 0,
    name: "Low",
  },
  {
    id: 1,
    name: "Medium",
  },
  {
    id: 2,
    name: "High",
  },
  {
    id: 3,
    name: "Critical",
  },
  {
    id: 4,
    name: "Info",
  },
];

export const ConditionType: Array<{ id: string; name: string }> = [
  {
    id: "632A1D90-4A46-4E5A-9C06-373C631B7037",
    name: "General Conditions",
  },
  {
    id: "89AFED32-B625-4A6E-BE8D-9CEBBDE16A76",
    name: "Failsafe Conditions",
  },
  {
    id: "E2AAD766-0B60-4E5D-90A8-2AE62135E99E",
    name: "Audit Conditions",
  },
  {
    id: "805A84C9-7F1B-4B4D-A9AD-3DDDCF9E0F17",
    name: "Advisory Conditions",
  },
];

export const AlertStatus: Array<{ id: number; name: string }> = [
   {
    id: 1,
    name: "Open",
  },
   {
    id: 2,
    name: "Close",
  }
];