import { ApiError, NetworkError } from "./Errors";

/**
 * Regex for matching and extracting the tenant ID from the route.
 */
const tenantRoutePartRegex = /^\/(.*?)(\/.*)?$/;

/**
 * Extracts and returns the tenant ID from the current route.
 */
function getTenantId(): string {
  const matches = location.pathname.match(tenantRoutePartRegex);
  if (!matches) {
    return "";
  }
  return matches[1];
}

export default class BaseService {
  constructor(private readonly baseUrl: string, private readonly sendTenantId: boolean = true) {}

  public async delete<TResult>(
    path: string,
    { query, signal, tenantId }: IFetchOptions<TResult> = {},
  ): Promise<Response> {
    const queryString = this.createQueryString(query);
    const response = await this.fetch(`${this.baseUrl}/${path}?${queryString}`, {
      headers: this.getDefaultHeaders({ tenantId }),
      method: "DELETE",
      signal,
    });

    return response;
  }

  public async get<TResult>(
    path: string,
    { defaultValue, query, signal, tenantId }: IFetchOptions<TResult> = {},
  ): Promise<TResult> {
    const queryString = this.createQueryString(query);

    const queryStringPart = !!queryString && queryString.length > 0 ? `?${queryString}` : "";

    const response = await this.fetch(`${this.baseUrl}/${path}${queryStringPart}`, {
      cache: "no-cache",
      credentials: "include",
      headers: this.getDefaultHeaders({ tenantId }),
      signal,
    });

    if (response.status === 204 && typeof defaultValue !== "undefined") {
      return defaultValue;
    } else if (response.status === 401) {
      // Auth is invalid, reload the page to go through the auth flow
      location.reload();
      // Await a never resolving promise to stop any failures from occurring that are awaiting data
      await new Promise(() => null);
    } else if (response.status < 200 || response.status >= 300) {
      throw await ApiError.CreateAsync(response);
    }

    const result: TResult = await response.json();

    return result;
  }

  public getDefaultHeaders({ tenantId }: Pick<IFetchOptions<unknown>, "tenantId">): Record<string, string> {
    return {
      Accept: "application/json",
      "Cache-Control": "no-cache, no-store",
      "Content-Type": "application/json",
      Pragma: "no-cache",
      "X-Tenant-ID": this.sendTenantId ? tenantId ?? getTenantId() : "",
    };
  }

  public async post<TRequest, TResult>(
    dataToPost: TRequest,
    path: string,
    { defaultValue, query, signal, tenantId }: IFetchOptions<TResult> = {},
  ): Promise<TResult> {
    const queryString = this.createQueryString(query);

    const response = await this.fetch(`${this.baseUrl}/${path}?${queryString}`, {
      body: JSON.stringify(dataToPost),
      cache: "no-cache",
      credentials: "same-origin",
      headers: this.getDefaultHeaders({ tenantId }),
      method: "POST",
      mode: "cors",
      redirect: "follow",
      referrer: "no-referrer",
      signal,
    });
    if (response.status === 204 && typeof defaultValue !== "undefined") {
      return defaultValue;
    } else if (response.status === 401) {
      // Auth is invalid, reload the page to go through the auth flow
      location.reload();
      // Await a never resolving promise to stop any failures from occurring that are awaiting data
      await new Promise(() => null);
    } else if (response.status < 200 || response.status >= 300) {
      throw await ApiError.CreateAsync(response);
    }

    const result: TResult = await response.json();

    return result;
  }

  protected getTenantId(): string {
    return getTenantId();
  }

  private createQueryString(query?: IQueryParams): string {
    if (!query) {
      return "";
    }

    return Object.keys(query)
      .reduce<string[]>((prev, key) => {
        const curr = query[key];
        if (typeof curr === "undefined") {
          return prev;
        } else if (Array.isArray(curr)) {
          return [...prev, ...curr.map((e) => `${encodeURIComponent(key)}=${encodeURIComponent(e)}`)];
        } else {
          return [...prev, `${encodeURIComponent(key)}=${encodeURIComponent(curr)}`];
        }
      }, [])
      .join("&");
  }

  private async fetch(url: string, init?: RequestInit): Promise<Response> {
    try {
      return await fetch(url, init);
    } catch (err) {
      throw new NetworkError(err);
    }
  }
}

export interface IFetchOptions<T> {
  /**
   * Default value used when no content is returned (HTTP 204).
   * Required if the endpoint can return no content under expected conditions.
   * If left undefined and no content is returned, an error will be thrown when parsing the empty result as JSON.
   */
  defaultValue?: T;

  /**
   * Query params to append to URL.
   */
  query?: IQueryParams;

  /**
   * Signal to abort (cancel) pending requests.
   */
  signal?: AbortSignal;

  /** Tenant ID to send with request. Defaults to pull from URL. */
  tenantId?: string;
}

export interface IPageResponse<T> {
  items: T[];
  rowCount: number;
}

export type IQueryParams = Readonly<Record<string, string | string[] | undefined>>;
