import BaseService from "../BaseService";
import {
  IAggregateEventLog,
  IAggregateEventLogRequest,
  IEnvironmentHealthOverview,
  IEnvironmentHealthOverviewRequest,
  IEventLogEntry,
  IEventLogRequest,
  IHealthStatusItem,
  IHealthStatusRequest,
} from "./models";

export default class PerformanceAdvisorService extends BaseService {
  constructor() {
    super("/api/performanceAdvisor");
  }

  public async fetchAggregateEventLogs(
    request: IAggregateEventLogRequest,
    controller?: AbortController,
  ): Promise<IAggregateEventLog[]> {
    const optionalValues: Record<string, string> = {};
    if (typeof request.scoreThreshold === "number") {
      optionalValues.scoreThreshold = request.scoreThreshold.toString();
    }
    const response = await this.get<any[]>("aggregate", {
      query: {
        ...optionalValues,
        "dateTimeRangeUtc.endDateTime": request.endDate.toISOString(),
        "dateTimeRangeUtc.startDateTime": request.startDate.toISOString(),
        objectId: typeof request.objectId === "undefined" ? "1" : request.objectId.toString(),
        objectType: request.objectType,
      },
      signal: controller?.signal,
    });

    return response.map<IAggregateEventLog>((r) => ({
      ...r,
      lastEndDateTimeUtc: r.lastEndDateTimeUtc ? new Date(r.lastEndDateTimeUtc) : undefined,
      lastStartDateTimeUtc: new Date(r.lastStartDateTimeUtc),
    }));
  }

  public async fetchEventLogs(
    request: IEventLogRequest,
    controller?: AbortController,
  ): Promise<IEventLogEntry[]> {
    let optionalValues = {};
    if (request.objectId) {
      optionalValues = {
        objectId: request.objectId,
      };
    }
    const response = await this.get<any[]>("events", {
      query: {
        ...optionalValues,
        conditionId: request.conditionId.toString(),
        endDateTimeUtc: request.endDate.toISOString(),
        startDateTimeUtc: request.startDate.toISOString(),
      },
      signal: controller?.signal,
    });

    return response.map<IEventLogEntry>((r) => ({
      ...r,
      endDate: r.endDateTimeUtc ? new Date(r.endDateTimeUtc) : undefined,
      startDate: new Date(r.startDateTimeUtc),
    }));
  }

  public fetchEnvironmentHealthOverview(
    request: IEnvironmentHealthOverviewRequest,
    controller?: AbortController,
  ): Promise<IEnvironmentHealthOverview> {
    return this.get<IEnvironmentHealthOverview>("overview", {
      query: {
        endDateTime: request.endDate.toISOString(),
        itemId: request.itemId.toString(),
        objectType: request.objectType,
        startDateTime: request.startDate.toISOString(),
      },
      signal: controller?.signal,
    });
  }

  public async fetchHealth(
    request: IHealthStatusRequest,
    controller?: AbortController,
  ): Promise<IHealthStatusItem[]> {
    const optionalValues: Record<string, string> = {};
    if (typeof request.objectId === "number") {
      optionalValues.objectId = request.objectId.toString();
    }
    if (typeof request.rollupLevelMinutes === "number") {
      optionalValues.rollupLevelMinutes = request.rollupLevelMinutes.toString();
    }
    if (typeof request.scoreThreshold === "number") {
      optionalValues.scoreThreshold = request.scoreThreshold.toString();
    }
    if (request.objectType) {
      optionalValues.objectType = request.objectType;
    }
    if (request.returnAllChildrenOfType) {
      optionalValues.returnAllChildrenOfType = request.returnAllChildrenOfType;
    }
    const response = await this.get<Record<string, any>>("health", {
      query: {
        ...optionalValues,
        "dateTimeRangeUtc.endDateTime": request.endDateUtc.toISOString(),
        "dateTimeRangeUtc.startDateTime": request.startDateUtc.toISOString(),
        eventGroupHealthType: request.eventGroupHealthType,
      },
      signal: controller?.signal,
    });
    return Object.keys(response)
      .map((k) => response[k])
      .map<IHealthStatusItem>((item) => ({
        ...item,
        connectionUptime: item.connectionUptime
          ? {
            ...item.connectionUptime,
            downtimeIntervals: item.connectionUptime.downtimeIntervals.map((d: any) => ({
              ...d,
              endDateUtc: new Date(d.endDateUtc),
              startDateUtc: new Date(d.startDateUtc),
            })),
          }
          : null,
      }));
  }
}
