import type { IUser, IUserGroup } from "./types";

const filterPrincipal = (
  principal: Pick<IUser, "firstName" | "lastName"> | Pick<IUserGroup, "name">,
  filter: string,
): boolean => {
  const name = "name" in principal ? principal.name : `${principal.firstName} ${principal.lastName}`;
  return !filter || name.toLowerCase().includes(filter.toLowerCase());
};

export { filterPrincipal };
