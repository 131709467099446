import { ApolloClient } from "apollo-boost";
import * as INSTANCES_QUERY from "./customChartInstancesQuery.graphql";

export interface IMetric {
  aliases: readonly string[];
  key: string;
  defaultColor: string;
  hasInstances: boolean;
  unitOfMeasure: string;
}

export interface IInstancesQueryResponse {
  target: {
    metric: {
      instances: Array<string>;
    };
  };
}

export interface IInstancesRequestVariables {
  dateRange: {
    from: string;
    to: string;
  };
  key: string;
  moRef: string;
}

export async function fetchMetricInstances(
  client: ApolloClient<any>,
  variables: IInstancesRequestVariables,
  signal?: AbortSignal,
): Promise<string[]> {
  const { data } = await client.query<IInstancesQueryResponse>({
    context: {
      fetchOptions: { signal },
    },
    query: INSTANCES_QUERY,
    variables,
  });
  return data.target.metric.instances;
}
