import { useQuery } from "@apollo/react-hooks";
import { useMemo } from "react";
import * as GET_TOP_CHARTS from "./AlertLogTopCharts.graphql";

type TopChartsParams = {
    startDateTimeUtc?: Date;
    endDateTimeUtc?: Date;
};

type IAsyncResult<T> =
  | {
      data: undefined;
      error: Error;
      isLoading: false;
      refetch?: any;
      state: "error";
    }
  | {
      data: undefined;
      error: null;
      isLoading: true;
      refetch?: any;
      state: "loading";
    }
  | {
      data: T;
      error: null;
      isLoading: false;
      refetch?: any;
      state: "success";
    };


export interface ITop5Conditions {
    alertCount: number;
    conditionId: string;
    conditionName: string;
}

export interface ITop5Servers {
    alertCount: number;
    objectId: string;
    serverName: string;
}

export interface ITop5Tags {
    alertCount: number;
    tag: string;
}

export interface IUseTopChartsResponse {
    alertLogTopCharts: {
        top5Conditions: ITop5Conditions[];
        top5Server: ITop5Servers[];
        top5Tags: ITop5Tags[];
    };
}

export function useTopCharts(dateRange: TopChartsParams): IAsyncResult<IUseTopChartsResponse> {
    const { data = { customDashboard: null }, error, loading } = useQuery<{
      alertLogTopCharts: {
        top5Server: ITop5Servers;
        top5Conditions: ITop5Conditions;
        top5Tags: ITop5Tags;
      };
    }>(GET_TOP_CHARTS, {
      variables: { dr: dateRange },
    });
  
    const result = useMemo<any | null>(() => {
      return data;
    }, [data]);
  
    if (error) {
      return {
        data: undefined,
        error: error,
        isLoading: false,
        state: "error",
      };
    } else if (loading) {
      return {
        data: undefined,
        error: null,
        isLoading: true,
        state: "loading",
      };
    } else {
      return {
        data: result,
        error: null,
        isLoading: false,
        state: "success",
      };
    }
}