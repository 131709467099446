import * as React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { useDataDisplayGroup } from "./DataDisplayGroup";

export type IDataDisplayVariants = "filled" | "emphasis" | "standard" | undefined;

export interface IDataDisplayProps {
  className?: string;
  label: string;
  value: string;
  variant?: IDataDisplayVariants; // defaults to standard
}

const useStyles = makeStyles((theme) => ({
  container: {
    "& dd": {
      color: theme.palette.text.primary,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& dt": {
      color: theme.palette.grey[700],
    },
    margin: 0,
    //if we update this value, please update the skeleton width in StorageEntityDetails to match
    width: theme.spacing(24),
  },
  emphasisContainer: {
    "& dd": {
      color: theme.palette.common.black,
      fontWeight: "bold",
      letterSpacing: theme.typography.body1.letterSpacing,
    },
    width: "100%",
  },
  filledContainer: {
    "& dd": {
      fontWeight: theme.typography.fontWeightBold,
    },
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    textAlign: "center",
  },
}));

const DataDisplay: React.FC<IDataDisplayProps> = ({ className, label, value, variant }) => {
  const classes = useStyles();
  const { variant: groupVariant } = useDataDisplayGroup();

  switch (variant ?? groupVariant) {
    case "filled":
      return (
        <dl
          className={classNames(classes.container, classes.filledContainer, className)}
          data-testid="DataDisplay-filled"
          title={value}
        >
          <Typography component="dt" variant="body2">
            {label}
          </Typography>
          <Typography component="dd" variant="h6">
            {value}
          </Typography>
        </dl>
      );
    case "emphasis":
      return (
        <dl
          className={classNames(classes.container, classes.emphasisContainer, className)}
          data-testid="DataDisplay-emphasis"
          title={value}
        >
          <Typography component="dt" variant="body2">
            {label}
          </Typography>
          <Typography component="dd" variant="h5">
            {value}
          </Typography>
        </dl>
      );
    case "standard":
    default:
      return (
        <dl className={classNames(classes.container, className)} data-testid="DataDisplay-standard" title={value}>
          <Typography component="dt" variant="body2">
            {label}
          </Typography>
          <Typography component="dd" variant="body1">
            {value}
          </Typography>
        </dl>
      );
  }
};

export default DataDisplay;
