import React, { ReactElement, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Grid,
  Paper,
  TextField
} from "@material-ui/core";
import TraceWaitsChart from "./TraceWaitsChart";
import { IExecutedQueryTraceEventsResponse } from "src/api/models/IExecutedQueryTraceEventsCriteria";
import { useIntl } from "react-intl";


const useStyles = makeStyles((theme) => ({
  modal: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function TraceWaitsModal(props: {eventSourceConnectionId: number, wait_ms: number, trace_record: IExecutedQueryTraceEventsResponse}): ReactElement {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const intl = useIntl();

  return (
    <>
      <Button
        color="primary"
        onClick={() => setOpen(true)}
        title={intl.formatMessage({ id: "traceEventWaitsChart" })}
        variant="text"
      >
        {props.wait_ms}
      </Button>
      <Dialog
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        aria-label="transition-modal-title"
        className={classes.modal}
        closeAfterTransition
        fullWidth
        maxWidth="lg"
        onClick={(e) => e.stopPropagation() }
        open={open}
      >
        <Fade in={open}>
          <Paper className={classes.paper}>
            <DialogTitle>{intl.formatMessage({ id: "traceEventWaits" })}</DialogTitle>
            <DialogContent>
              <Box sx={{flexGrow: 1}}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      inputProps={
                        { readOnly: true, }
                      }
                      label="SPID"
                      size="small"
                      value={props.trace_record.spid}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      inputProps={
                        { readOnly: true, }
                      }
                      label="Host"
                      size="small"
                      value={props.trace_record.hostName}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      inputProps={
                        { readOnly: true, }
                      }
                      label="Application Name"
                      size="small"
                      value={props.trace_record.applicationName}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      inputProps={
                        { readOnly: true, }
                      }
                      label="Database Name"
                      size="small"
                      value={props.trace_record.databaseName}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      inputProps={
                        { readOnly: true, }
                      }
                      label="Login"
                      size="small"
                      value={props.trace_record.login}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      inputProps={
                        { readOnly: true, }
                      }
                      label="CPU (ms)"
                      size="small"
                      value={props.trace_record.cpu}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      inputProps={
                        { readOnly: true, }
                      }
                      label="Read (L)"
                      size="small"
                      value={props.trace_record.reads}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      inputProps={
                        { readOnly: true, }
                      }
                      label="Writes (P)"
                      size="small"
                      value={props.trace_record.writes}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      inputProps={
                        { readOnly: true, }
                      }
                      label="Duration"
                      size="small"
                      value={props.trace_record.duration}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      inputProps={
                        { readOnly: true, }
                      }
                      label="Start Time"
                      size="small"
                      value={props.trace_record.startTime}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      inputProps={
                        { readOnly: true, }
                      }
                      label="End Time"
                      size="small"
                      value={props.trace_record.endTime}
                    />
                  </Grid>
                </Grid>
              </Box>
              <h3 style={{marginTop: '50px'}}>Waits</h3>
              <TraceWaitsChart eventSourceConnectionId={props.eventSourceConnectionId} trace_id={props.trace_record.traceDataId}></TraceWaitsChart>
            </DialogContent>
            <DialogActions>
              <Button onClick={(e) => {
                  e.stopPropagation();
                  setOpen(false);
                }}
                variant="outlined"
              >
                {intl.formatMessage({ id: "cancel" })}
              </Button>
            </DialogActions>
          </Paper>
        </Fade>
      </Dialog>
    </>
  );
}
