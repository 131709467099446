import * as React from "react";
import HealthScoreCard from "./HealthScoreCard";
import { ParentSize } from "@visx/responsive";
import ScoreHistoryChart from "../EnvironmentHealthScoreHistory/HealthScoreHistoryChart";
import { useGetHealthScoreHistory } from "./useHealthScoreHistory";
import {
  ITopologyItem,
  ITopologyItemDevice,
  ITopologyItemEventSourceConnection,
  useTopology,
} from "../../components/TopologyContext";
import { useDateContext } from "../../components/DateContext";
import { useCurrentTarget } from "../../hooks/useCurrentTarget";
import { TopologyObjectType } from "../../api/PerformanceAdvisor";
import ErrorBoundary, { Throw } from "../../components/ErrorBoundary";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import NoDataIndicator from "../../components/NoDataIndicator/NoDataIndicator";
import { DateTime } from "luxon";

export interface IHealthScoreProps {
  topLevelObject?: ITopologyItem;
}

const HealthScore: React.FC<IHealthScoreProps> = ({ topLevelObject }) => {
  const { dateRange } = useDateContext();
  const target = useCurrentTarget() ?? topLevelObject;

  const topology = useTopology();

  function getAllChildTopologyNodes(
    topologyNode: ITopologyItem,
    childNodes: ITopologyItem[],
  ): ITopologyItem[] | undefined {
    const children = topology.findByParentObjectId(topologyNode.objectId);
    children.forEach((child) => {
      if (![TopologyObjectType.global, TopologyObjectType.site, TopologyObjectType.group].includes(child.type)) {
        childNodes.push(child);
      }
      getAllChildTopologyNodes(child, childNodes);
    });
    return childNodes;
  }

  const allChildTargets = target && getAllChildTopologyNodes(target, []);

  const childWindowsTarget =
    allChildTargets &&
    allChildTargets
      .filter((child) => {
        if (child.type === TopologyObjectType.device) {
          return child;
        }
      })
      .map((child) => child as ITopologyItemDevice);

  const childSqlTarget =
    allChildTargets &&
    allChildTargets
      .filter((child) => {
        if (child.type === TopologyObjectType.eventSourceConnection) {
          return child;
        }
      })
      .map((child) => child as ITopologyItemEventSourceConnection);

  //if group or site is not selected, use global list of devices\eventsourceconnections
  const windowsTargets = childWindowsTarget ?? topology.devices;
  const sqlTargets = childSqlTarget ?? topology.eventSourceConnections;

  // available targets for the target selector
  const availableTargets: Array<ITopologyItemDevice | ITopologyItemEventSourceConnection> = [
    // filter out 'fake' devices and devices that have children connections
    ...windowsTargets.filter((d) => !!d.description && !sqlTargets.some((conn) => conn.parentObjectId === d.objectId)),
    ...sqlTargets,
  ].filter((d) => d.isWatched);

  const { data: healthScoreHistory = [], error, isLoading } = useGetHealthScoreHistory({
    endDateUtc: dateRange.to,
    objectId: target?.objectId ?? "B7C74F24-1A45-4115-8262-D7613878BBD6",
    startDateUtc: DateTime.fromJSDate(dateRange.to).minus({ month: 1 }).toJSDate(),
  });

  const total_targets = availableTargets.length;
  let best_score = 0;
  let worst_score = 100;
  let current_score = 100;

  if (!error && !isLoading && healthScoreHistory.length > 0) {
    current_score = healthScoreHistory[healthScoreHistory.length - 1].score;

    for (let i = healthScoreHistory.length - 1; i > -1; i--) {
      const score = healthScoreHistory[i].score;
      if (score > best_score) best_score = score;
      if (score < worst_score) worst_score = score;
    }
  }

  return (
    <div style={{ display: "flex", gap: "10px", height: "175px" }}>
      <ErrorBoundary variant="generic">
        {error && <Throw error={error} />}
        {isLoading ? (
          <div style={{ width: "100%" }}>
            <LoadingIndicator></LoadingIndicator>
          </div>
        ) : healthScoreHistory.length > 0 ? (
          <>
            <div style={{ height: "100%", width: "130px" }}>
              <HealthScoreCard
                data={{
                  best: best_score,
                  score: current_score,
                  total_targets: total_targets,
                  worst: worst_score,
                }}
              ></HealthScoreCard>
            </div>
            <div style={{ flex: 2 }}>
              <ParentSize>
                {({ width }) => (
                  <ScoreHistoryChart data={healthScoreHistory} height={175} width={width}></ScoreHistoryChart>
                )}
              </ParentSize>
            </div>
          </>
        ) : (
          <div style={{ width: "100%" }}>
            <NoDataIndicator></NoDataIndicator>
          </div>
        )}
      </ErrorBoundary>
    </div>
  );
};

export default HealthScore;
