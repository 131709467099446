import Divider from "@material-ui/core/Divider";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import * as React from "react";
import { useIntl } from "react-intl";
import { useFeatureFlag } from "../../contexts/featuresContext";
import { IDateRange, useDateContext } from "../DateContext";
import { ITopologyItem } from "../TopologyContext";
import NavigationMenuItem from "./NavigationMenuItem";

export interface INavigationContextMenuProps extends MenuProps {
  /**
   * Additional action items to display in the menu.
   * React.Fragment cannot be used to contain multiple items due to a Material-UI Menu limitation.
   * For multiple items, use an array instead.
   */
  children?: React.ReactNode;
  /**
   * Date range to navigate to when a navigation item is selected.
   * Default behavior is not to manipulate the date range.
   */
  dateRange?: IDateRange;
  onClose: (event: unknown, reason: "cancelClick" | "escapeKeyDown" | "backdropClick" | "selectionMade") => void;
  /** Whether to show the cancel menu item. Defaults to false. */
  showCancel?: boolean;
  /** The target to navigate to. */
  target: ITopologyItem;
}

const NavigationContextMenu: React.FC<INavigationContextMenuProps> = ({
  children,
  dateRange,
  onClose,
  showCancel = false,
  target,
  ...props
}) => {
  const intl = useIntl();
  const { getUrlWithDateRange, dateRange: currentDateRange, liveEnabled } = useDateContext();

  function handleClick(): void {
    onClose({}, "selectionMade");
  }

  const toDateRange = dateRange ?? (liveEnabled ? null : currentDateRange);
  return (
    <>
      <Menu onClick={handleClick} onClose={onClose} {...props}>
        {children}
        {children && <Divider />}
        {target.features.health && (
          <NavigationMenuItem
            to={getUrlWithDateRange(`/${target.tenantId}/targets/${target.objectId}/health`, toDateRange)}
          >
            {intl.formatMessage({ id: "health" })}
          </NavigationMenuItem>
        )}
        {target.features.performance && (
          <NavigationMenuItem
            to={getUrlWithDateRange(`/${target.tenantId}/targets/${target.objectId}/performance`, toDateRange)}
          >
            {intl.formatMessage({ id: "performance" })}
          </NavigationMenuItem>
        )}
        {target.features.storage && (
          <NavigationMenuItem
            to={getUrlWithDateRange(`/${target.tenantId}/targets/${target.objectId}/storage`, toDateRange)}
          >
            {intl.formatMessage({ id: "storage" })}
          </NavigationMenuItem>
        )}
        {target.features.topsql && (
          <NavigationMenuItem
            to={getUrlWithDateRange(`/${target.tenantId}/targets/${target.objectId}/topsql`, toDateRange)}
          >
            {intl.formatMessage({ id: "topSql" })}
          </NavigationMenuItem>
        )}
        {target.features.blocking && (
          <NavigationMenuItem
            to={getUrlWithDateRange(`/${target.tenantId}/targets/${target.objectId}/blocking`, toDateRange)}
          >
            {intl.formatMessage({ id: "blocking" })}
          </NavigationMenuItem>
        )}
        {target.features.deadlocks && (
          <NavigationMenuItem
            to={getUrlWithDateRange(`/${target.tenantId}/targets/${target.objectId}/deadlocks`, toDateRange)}
          >
            {intl.formatMessage({ id: "deadlocks" })}
          </NavigationMenuItem>
        )}
        {target.features.tempdb && (
          <NavigationMenuItem
            to={getUrlWithDateRange(`/${target.tenantId}/targets/${target.objectId}/tempdb`, toDateRange)}
          >
            {intl.formatMessage({ id: "tempDb" })}
          </NavigationMenuItem>
        )}
        {showCancel && <Divider />}
        {showCancel && (
          <MenuItem onClick={() => onClose({}, "cancelClick")}>
            {intl.formatMessage({ id: "cancelSelection" })}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default NavigationContextMenu;
