import { useQuery } from "@apollo/react-hooks";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, styled } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CancelIcon from "@material-ui/icons/Cancel";
import Skeleton from "@material-ui/lab/Skeleton";
import * as React from "react";
import { useIntl } from "react-intl";
import { IDateRange } from "../../../../components/DateContext";
import { MoRef } from "../../../../utilities/TopologyUtility";
import { ICustomChartConfig, ICustomDashboardChartSeries } from "../../types";
import * as METRICS_QUERY from "./customChartMetricsQuery.graphql";
import { IMetric } from "./CustomChartQueries";
import PerformanceCounterMetricRow from "./PerformanceCounterMetricRow";
import { ITargetAutocompleteProps } from "./components/TargetAutocomplete";

export interface IPerformanceCounterSelectionProps extends ITargetAutocompleteProps {
  initialChartConfig: ICustomChartConfig;
  handleAddMultipleInstances: (values: Partial<ICustomDashboardChartSeries>) => void;
  handleMetricsChange: React.Dispatch<React.SetStateAction<ICustomDashboardChartSeries[]>>;
  performanceCounters: ICustomDashboardChartSeries[];
  handleLoadingMetrics: (loading: boolean) => void;
  handleMetricsError: (error: boolean) => void;
  moRef: MoRef;
  dateRange: IDateRange;
}

interface PerformanceCounterMetricRowErrors {
  targetError: boolean;
  metricError: boolean;
  instanceError: boolean;
}
export interface IPerformanceCounterMetricRowStatus {
  loading: boolean;
  errors: PerformanceCounterMetricRowErrors;
}
interface IMetricsQueryResponse {
  target: {
    metrics: Array<IMetric>;
  } | null;
}
interface IMetricsRequestVariables {
  moRef: string;
}

const useStyles = makeStyles((theme) => ({
  actionColumn: {
    width: "5%",
  },
  uppercase: {
    textTransform: "uppercase",
  },
}));

const NoBorderTableCell = styled(TableCell)((theme) => ({
  border: "none",
  paddingBottom: theme.theme.spacing(1),
}));

const PerformanceCounterSelection: React.FC<IPerformanceCounterSelectionProps> = ({
  initialChartConfig,
  handleAddMultipleInstances,
  handleTargetChange,
  targets,
  handleMetricsChange,
  performanceCounters,
  handleLoadingMetrics,
  handleMetricsError,
  moRef,
  dateRange,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const [rowStatus, setRowStatus] = React.useState<Array<IPerformanceCounterMetricRowStatus>>(() =>
    Array<IPerformanceCounterMetricRowStatus>(performanceCounters.length).fill({
      errors: {
        instanceError: false,
        metricError: false,
        targetError: false,
      },
      loading: false,
    }),
  );

  // add an id to metrics for keying purposes
  const metricRows = React.useMemo(
    () => new Map<number, ICustomDashboardChartSeries>(performanceCounters.map((counter, i) => [i, counter])),
    [performanceCounters],
  );

  // if a metric was added, add a new status row for that new metric
  React.useEffect(() => {
    if (performanceCounters.length > rowStatus.length) {
      setRowStatus((prev) => {
        const newState = Array.from(prev);
        newState.push({
          errors: {
            instanceError: false,
            metricError: false,
            targetError: false,
          },
          loading: false,
        });
        return newState;
      });
    }
  }, [performanceCounters.length, rowStatus.length]);

  const { data, error, loading: loadingMetrics } = useQuery<IMetricsQueryResponse, IMetricsRequestVariables>(
    METRICS_QUERY,
    {
      variables: {
        moRef: moRef.toString(),
      },
    },
  );

  const metrics = data?.target?.metrics ?? [];
  if (error) {
    throw error;
  }

  React.useEffect(() => {
    handleLoadingMetrics(loadingMetrics || rowStatus.some((status) => status.loading));
    handleMetricsError(rowStatus.some((status) => status.errors.instanceError || status.errors.metricError));
  });

  const handleRemoveMetricClick = (rowId: number): void => {
    setRowStatus((prev) => {
      const newState = Array.from(prev);
      newState.splice(rowId, 1);
      return newState;
    });

    handleMetricsChange((prev) => {
      const prevRows = Array.from(prev);
      prevRows.splice(rowId, 1);
      return prevRows;
    });
  };

  const handleRowUpdate = (rowId: number, newValues: Partial<ICustomDashboardChartSeries>): void => {
    handleMetricsChange((prev) => {
      const prevRows = Array.from(prev);
      prevRows[rowId] = { ...prevRows[rowId], ...newValues };
      return prevRows;
    });
    newValues.instanceList?.length && handleAddMultipleInstances(newValues);
  };

  const nonLineCounter = performanceCounters.find((counter) => counter.chartType !== "line");
  const nonLineUnitOfMeasure = metrics.find((metric) => metric.key === nonLineCounter?.metric)?.unitOfMeasure ?? null;

  return (
    <Table aria-labelledby="performanceCounters" size="medium">
      <TableHead>
        <TableRow>
          <NoBorderTableCell className={classes.uppercase} id="targetSelect" scope="column">
            {intl.formatMessage({ id: "target" })}
          </NoBorderTableCell>
          <NoBorderTableCell className={classes.uppercase} id="metrics" scope="column">
            {intl.formatMessage({ id: "metric" })}
          </NoBorderTableCell>
          <NoBorderTableCell className={classes.uppercase} id="instance" scope="column">
            {intl.formatMessage({ id: "instance" })}
          </NoBorderTableCell>
          <NoBorderTableCell className={classes.uppercase} id="legendLabel" scope="column">
            {intl.formatMessage({ id: "legendLabel" })}
          </NoBorderTableCell>
          <NoBorderTableCell className={classes.uppercase} id="color" scope="column">
            {intl.formatMessage({ id: "color" })}
          </NoBorderTableCell>
          <NoBorderTableCell className={classes.uppercase} id="chartType" scope="column">
            {intl.formatMessage({ id: "type" })}
          </NoBorderTableCell>
          <NoBorderTableCell id="actions" scope="column"></NoBorderTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.from(metricRows.entries()).map(([i, row]) => (
          <TableRow key={i}>
            {!loadingMetrics && (
              <>
                <PerformanceCounterMetricRow
                  availableMetrics={metrics}
                  dateRange={dateRange}
                  handleStatusChange={(callback) => {
                    setRowStatus((prev) => {
                      const newValue = callback(
                        prev[i] ?? {
                          errors: {
                            instanceError: false,
                            metricError: false,
                          },
                          loading: false,
                        },
                      );
                      if (newValue === prev[i]) {
                        return prev;
                      } else {
                        const newState = [...prev];
                        newState[i] = newValue;
                        return newState;
                      }
                    });
                  }}
                  initialChartConfig={initialChartConfig}
                  metricData={row}
                  moRef={moRef}
                  nonLineUnitOfMeasure={nonLineUnitOfMeasure}
                  onRowUpdate={(newValues) => handleRowUpdate(i, newValues)}
                  rowCount={performanceCounters.length}
                  // row status will be undefined on metric add.
                  // the use effect above adds that row after render to handle status state update
                  status={
                    rowStatus[i] ?? {
                      errors: {
                        instanceError: false,
                        metricError: false,
                      },
                      loading: false,
                    }
                  }
                  targets={targets}
                />
                <NoBorderTableCell className={classes.actionColumn} headers="actions">
                  <IconButton disabled={metricRows.size === 1} onClick={() => handleRemoveMetricClick(i)} size="small">
                    <CancelIcon />
                  </IconButton>
                </NoBorderTableCell>
              </>
            )}
            {loadingMetrics && (
              <NoBorderTableCell colSpan={6}>
                <Skeleton animation="wave" data-testid="loadingMetricsSkeleton" height="30px" variant="rect" />
              </NoBorderTableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export { PerformanceCounterSelection as default, NoBorderTableCell };
