import { useApolloClient, useQuery } from "@apollo/react-hooks";
import { useMemo } from "react";
import { ChartDisplayType } from "../../../components/MetricChart";
import { MoRef } from "../../../utilities/TopologyUtility";
import * as CREATE_MUTATION from "./createCustomDashboardQuery.graphql";
import * as DELETE_MUTATION from "./deleteCustomDashboardQuery.graphql";
import * as GET_ALL_QUERY from "./getAllCustomDashboardsQuery.graphql";
import * as GET_SINGLE_QUERY from "./getCustomDashboardQuery.graphql";
import {
  DashboardChartType,
  DashboardWidgetType,
  IDashboardChartOptions,
  IDashboardChartSeries,
  IDashboardChartSeriesInput,
  IDashboardChartWidget,
  IDashboardChartWidgetInput,
  IDashboardInput,
  IDashboards,
  ISingleDashboard,
} from "./types";
import * as UPDATE_MUTATION from "./updateCustomDashboardQuery.graphql";

export { CustomTimeRangeUnit, DashboardAccessLevel, DashboardWidgetType } from "./types";
export type { IDashboardInput } from "./types";

export type IAsyncResult<T> =
  | { data: undefined; error: Error; isLoading: false; state: "error" }
  | { data: undefined; error: null; isLoading: true; state: "loading" }
  | { data: T; error: null; isLoading: false; state: "success" };

export interface ICustomDashboard extends Omit<ISingleDashboard, "widgets"> {
  readonly widgets: readonly ICustomDashboardWidget[];
}

export interface ICustomDashboardChartOptions extends Omit<IDashboardChartOptions, "series"> {
  readonly series: readonly ICustomDashboardChartSeries[];
}

export interface ICustomDashboardChartSeries extends Omit<IDashboardChartSeries, "chartType" | "moRef"> {
  readonly chartType: ChartDisplayType;
  readonly moRef: MoRef;
}

export interface ICustomDashboardWidget extends Omit<IDashboardChartWidget, "options"> {
  readonly id: number;
  readonly options: ICustomDashboardChartOptions;
}

export interface IDigestDashboard extends Omit<IDashboards, "dateModified"> {
  readonly dateModified: Date;
}

export interface IDashboardActions {
  /**
   * Creates a new dashboard by cloning the dashboard with the specified ID.
   * If the dashboard is not found, a blank dashboard is created instead.
   * Includes dashboard widgets in the response.
   * Returns a Promise that resolves with the created dashboard ID.
   * @param id The ID of the dashboard to clone.
   * @param changes Changes to apply when cloning the dashboard.
   */
  cloneDashboard(id: string, changes: { name: string }): Promise<{ id: string }>;
  /**
   * Creates a new dashboard.
   * Returns a Promise that resolves with the created dashboard ID.
   * @param dashboard The values of the dashboard to create.
   */
  createDashboard(dashboard: IDashboardInput): Promise<{ id: string }>;
  /**
   * Deletes a dashboard with the specified ID.
   * @param id The ID of the dashboard to delete.
   */
  deleteDashboard(id: string): Promise<void>;
  /**
   * Updates a dashboard with the specified ID.
   * @param id The ID of the dashboard to update.
   * @param dashboard The values of the dashboard.
   */
  updateDashboard(id: string, dashboard: IDashboardInput | ICustomDashboard): Promise<void>;
}

function getDashboardChartType(chartType: ChartDisplayType | DashboardChartType): DashboardChartType {
  switch (chartType) {
    case "line":
      return DashboardChartType.Line;
    case "stackedArea":
      return DashboardChartType.StackedArea;
    case "stackedBar":
      return DashboardChartType.StackedBar;
    default:
      return chartType;
  }
}

function getMetricChartType(chartType: DashboardChartType): ChartDisplayType {
  switch (chartType) {
    case DashboardChartType.Line:
      return "line";
    case DashboardChartType.StackedArea:
      return "stackedArea";
    case DashboardChartType.StackedBar:
      return "stackedBar";
  }
}

function mapDashboardToOutput(customDashboard: ISingleDashboard): ICustomDashboard {
  return {
    ...customDashboard,
    widgets: customDashboard.widgets
      .filter((x): x is IDashboardChartWidget => x.type === DashboardWidgetType.Chart)
      .map<ICustomDashboardWidget>((widget) => {
        return {
          ...widget,
          id: Math.random(),
          options: {
            ...widget.options,
            series: widget.options.series.map<ICustomDashboardChartSeries>((series) => {
              return {
                ...series,
                chartType: getMetricChartType(series.chartType),
                moRef: MoRef.parse(series.moRef),
              };
            }),
          },
        };
      }),
  };
}

/**
 * Map over dashboard object to remove any extra properties.
 * GraphQL will reject any unrecognized values in the input object.
 * @param dashboard
 */
function removeExtraDashboardFields(dashboard: IDashboardInput | ICustomDashboard): IDashboardInput {
  const widgets: ReadonlyArray<IDashboardChartWidgetInput | ICustomDashboardWidget> = dashboard.widgets;
  return {
    name: dashboard.name,
    widgets: widgets.map<IDashboardChartWidgetInput>((widget) => {
      const series: ReadonlyArray<IDashboardChartSeriesInput | ICustomDashboardChartSeries> = widget.options.series;
      return {
        options: {
          customTimeRange: widget.options.customTimeRange
            ? {
                unit: widget.options.customTimeRange.unit,
                value: widget.options.customTimeRange.value,
              }
            : null,
          series: series.map<IDashboardChartSeriesInput>((series) => {
            return {
              chartType: getDashboardChartType(series.chartType),
              color: series.color,
              instance: series.instance,
              label: series.label,
              metric: series.metric,
              moRef: series.moRef.toString(),
            };
          }),
        },
        position: {
          column: widget.position.column,
          height: widget.position.height,
          row: widget.position.row,
          width: widget.position.width,
        },
        title: widget.title,
        type: widget.type,
      };
    }),
  };
}

/**
 * Retrieves all dashboard definitions and their metadata.
 * Does not include widgets.
 */
export function useAllDashboards(): IAsyncResult<readonly IDigestDashboard[]> {
  const { data = { customDashboards: [] }, error, loading } = useQuery<{
    customDashboards: readonly IDashboards[];
  }>(GET_ALL_QUERY);
  if (error) {
    return {
      data: undefined,
      error: error,
      isLoading: false,
      state: "error",
    };
  } else if (loading) {
    return {
      data: undefined,
      error: null,
      isLoading: true,
      state: "loading",
    };
  } else {
    return {
      data: data.customDashboards.map<IDigestDashboard>((x) => {
        return {
          ...x,
          dateModified: new Date(x.dateModified),
        };
      }),
      error: null,
      isLoading: false,
      state: "success",
    };
  }
}

/**
 * Retrieves a single dashboard definition and its widgets.
 * @param id The ID of the dashboard to return.
 */
export function useDashboard(id: string): IAsyncResult<ICustomDashboard | null> {
  const { data = { customDashboard: null }, error, loading } = useQuery<
    { customDashboard: ISingleDashboard | null },
    { id: string }
  >(GET_SINGLE_QUERY, {
    variables: { id },
  });

  const dashboard = useMemo<ICustomDashboard | null>(() => {
    return data.customDashboard ? mapDashboardToOutput(data.customDashboard) : null;
  }, [data]);

  if (error) {
    return {
      data: undefined,
      error: error,
      isLoading: false,
      state: "error",
    };
  } else if (loading) {
    return {
      data: undefined,
      error: null,
      isLoading: true,
      state: "loading",
    };
  } else {
    return {
      data: dashboard,
      error: null,
      isLoading: false,
      state: "success",
    };
  }
}

/**
 * Returns a set of functions for working with dashboards.
 * Using these functions will automatically rerender any components
 * using `useAllDashboards` or `useDashboard` if needed.
 */
export function useDashboardActions(): IDashboardActions {
  const apolloClient = useApolloClient();
  return {
    async cloneDashboard(id, changes) {
      const {
        data: { customDashboard },
      } = await apolloClient.query<{ customDashboard: ISingleDashboard | null }, { id: string }>({
        query: GET_SINGLE_QUERY,
        variables: { id },
      });
      const { data } = await apolloClient.mutate<{ addDashboard: string }, { dashboard: IDashboardInput }>({
        awaitRefetchQueries: true,
        mutation: CREATE_MUTATION,
        refetchQueries: [{ query: GET_ALL_QUERY }],
        variables: {
          dashboard: removeExtraDashboardFields({
            widgets: [],
            ...customDashboard,
            ...changes,
          }),
        },
      });
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return { id: data!.addDashboard };
    },
    async createDashboard(dashboard) {
      const { data } = await apolloClient.mutate<{ addDashboard: string }, { dashboard: IDashboardInput }>({
        awaitRefetchQueries: true,
        mutation: CREATE_MUTATION,
        refetchQueries: [{ query: GET_ALL_QUERY }],
        variables: {
          dashboard: removeExtraDashboardFields(dashboard),
        },
      });
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return { id: data!.addDashboard };
    },
    async deleteDashboard(id) {
      await apolloClient.mutate<unknown, { id: string }>({
        awaitRefetchQueries: true,
        mutation: DELETE_MUTATION,
        refetchQueries: [{ query: GET_ALL_QUERY }],
        variables: { id },
      });
    },
    async updateDashboard(id, dashboard) {
      await apolloClient.mutate<unknown, { dashboard: IDashboardInput; id: string }>({
        awaitRefetchQueries: true,
        mutation: UPDATE_MUTATION,
        refetchQueries: [{ query: GET_ALL_QUERY }, { query: GET_SINGLE_QUERY, variables: { id } }],
        variables: {
          // Map over dashboard object to remove any extra properties
          dashboard: removeExtraDashboardFields(dashboard),
          id,
        },
      });
    },
  };
}
