import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import { alpha, makeStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Cancel";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SettingsIcon from "@material-ui/icons/Settings";
import classNames from "classnames";
import { DateTime } from "luxon";
import * as React from "react";
import { useDrag, useDrop } from "react-dnd";
import { IntlShape, useIntl } from "react-intl";
import { IDateRange, useDateContext } from "../../../../components/DateContext";
import ErrorBoundary from "../../../../components/ErrorBoundary";
import { RestrictedDataIndicator } from "../../../../components/NoDataIndicator";
import { TopologyObjectType, useTopology } from "../../../../components/TopologyContext";
import { ICustomChartConfig, WidgetDragItem, WidgetDragItemType } from "../../types";
import { CustomTimeRangeUnit, ICustomDashboardWidget } from "../../useDashboards";
import DashboardWidgetChart from "./DashboardWidgetChart";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";

export interface IDashboardWidgetProps {
  className?: string;
  editing: boolean;
  onCopyWidget: (widget: ICustomDashboardWidget) => void;
  onEditWidget: (widget: ICustomDashboardWidget) => void;
  onAddWidget: (widgetTemplate: ICustomChartConfig, direction: "left" | "right") => void;
  onRemoveWidget: () => void;
  onUpdateWidgetPosition: (id: number, direction: "left" | "right") => void;
  style?: React.CSSProperties;
  widget: ICustomDashboardWidget;
}

const useStyles = makeStyles((theme) => ({
  cardAction: {
    "&>button": {
      padding: theme.spacing(1),
    },
    backgroundColor: theme.palette.background.paper,
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  content: {
    display: "grid",
    flex: 1,
  },
  dropOverlay: {
    alignItems: "center",
    backgroundColor: alpha(theme.palette.primary.main, 0.4),
    display: "flex",
    height: "100%",
    justifyContent: "center",
    position: "absolute",
    width: theme.spacing(4),
  },
  dropOverlayLeft: {
    left: 0,
    top: 0,
  },
  dropOverlayRight: {
    right: 0,
    top: 0,
  },
  editing: {
    "& $content": {
      opacity: 0.5,
      pointerEvents: "none",
    },
    cursor: "move",
  },
  headerContent: {
    overflow: "hidden",
  },
  headerText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    // Allow tooltips to extend outside of Card bounds
    overflow: "visible",
    position: "relative",
  },
}));

const styles = {
  tooltip: {
    maxWidth: "150em",
  },
};

const TargetsTooltip = withStyles(styles)(Tooltip);

export function getWidgetDateRange(unit: CustomTimeRangeUnit, value: number): IDateRange {
  const now = DateTime.utc();
  switch (unit) {
    case CustomTimeRangeUnit.Days:
      return {
        from: now.minus({ day: value }).toJSDate(),
        to: now.toJSDate(),
      };
    case CustomTimeRangeUnit.Hours:
      return {
        from: now.minus({ hour: value }).toJSDate(),
        to: now.toJSDate(),
      };
    case CustomTimeRangeUnit.Minutes:
      return {
        from: now.minus({ minute: value }).toJSDate(),
        to: now.toJSDate(),
      };
  }
}

export function formatTimeRange(unit: CustomTimeRangeUnit, value: number, intl: IntlShape): string {
  const prefix = intl.formatMessage({ id: "timeframe" }) + intl.formatMessage({ id: "last" });
  switch (unit) {
    case CustomTimeRangeUnit.Days:
      return (
        prefix +
        intl.formatNumber(value, {
          style: "unit",
          unit: "day",
          unitDisplay: "long",
        })
      );
    case CustomTimeRangeUnit.Hours:
      return (
        prefix +
        intl.formatNumber(value, {
          style: "unit",
          unit: "hour",
          unitDisplay: "long",
        })
      );
    case CustomTimeRangeUnit.Minutes:
      return (
        prefix +
        intl.formatNumber(value, {
          style: "unit",
          unit: "minute",
          unitDisplay: "long",
        })
      );
  }
}

const DashboardWidget: React.FC<IDashboardWidgetProps> = ({
  className,
  editing,
  onCopyWidget,
  onEditWidget,
  onAddWidget,
  onRemoveWidget,
  onUpdateWidgetPosition,
  style,
  widget,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const { dateRange } = useDateContext();
  const topology = useTopology();
  const ref = React.useRef<HTMLElement>(null);
  const [direction, setDirection] = React.useState<"left" | "right">("left");

  const [, drag] = useDrag<WidgetDragItem, never, never>({
    canDrag: () => editing,
    item: {
      id: widget.id,
      type: WidgetDragItemType.move,
    },
    type: WidgetDragItemType.move,
  });

  const [{ isOver }, drop] = useDrop({
    accept: [WidgetDragItemType.add, WidgetDragItemType.move],
    canDrop: (item: WidgetDragItem) => {
      return item.type !== WidgetDragItemType.move || item.id !== widget.id;
    },
    collect: (monitor) => {
      return { isOver: monitor.isOver() && monitor.canDrop() };
    },
    drop: (item: WidgetDragItem) => {
      switch (item.type) {
        case WidgetDragItemType.add: {
          onAddWidget(item.widgetTemplate, direction);
          break;
        }
        case WidgetDragItemType.move: {
          onUpdateWidgetPosition(item.id, direction);
          break;
        }
      }
    },
    hover: (_, monitor) => {
      const coords = monitor.getClientOffset();
      const el = ref.current?.getBoundingClientRect();
      if (el && coords) {
        const direction = el.width / 2 > coords.x - el.left ? ("left" as const) : ("right" as const);
        setDirection(direction);
      }
    },
  });

  // Connect the drag and drop events to the element ref
  drag(drop(ref));

  const widgetDateRange = widget.options.customTimeRange
    ? getWidgetDateRange(widget.options.customTimeRange.unit, widget.options.customTimeRange.value)
    : dateRange;

  const widgetTargetMoRef = widget.options.series[0].moRef;
  if (!widgetTargetMoRef) throw new Error("The added Widget's metrics did not have an associated moRef Id");

  const widgetTarget = topology.findByMoRef(widgetTargetMoRef);
  if (
    widgetTarget &&
    widgetTarget.type !== TopologyObjectType.device &&
    widgetTarget.type !== TopologyObjectType.eventSourceConnection
  ) {
    throw new Error(`Target for MoRef ${widgetTargetMoRef} has unsupported type ${widgetTarget.type}`);
  }

  const targetsList = new Set(widget.options.series.map((t) => topology.findByMoRef(t.moRef)?.name));

  return (
    <Card
      className={classNames(classes.root, { [classes.editing]: editing }, className)}
      data-testid={`dashboard-widget-${widget.title}`}
      elevation={0}
      ref={ref}
      style={style}
    >
      <CardHeader
        action={
          // Only allow edit/remove widget if the user has performance access to the target
          editing && widgetTarget && widgetTarget.features.performance ? (
            <>
              <TargetsTooltip
                arrow
                data-testid="target-tooltip"
                enterDelay={500}
                placement="top"
                title={<div style={{ whiteSpace: "pre-line" }}>{Array.from(targetsList).join("\n")}</div>}
              >
                <IconButton>
                  <InfoOutlinedIcon color="action" />
                </IconButton>
              </TargetsTooltip>
              <IconButton onClick={() => onCopyWidget(widget)} title={intl.formatMessage({ id: "copy" })}>
                <FileCopyOutlinedIcon color="action" />
              </IconButton>
              <IconButton onClick={() => onEditWidget(widget)} title={intl.formatMessage({ id: "configure" })}>
                <SettingsIcon color="action" />
              </IconButton>
              <IconButton onClick={onRemoveWidget} title={intl.formatMessage({ id: "delete" })}>
                <CloseIcon color="error" />
              </IconButton>
            </>
          ) : (
            <TargetsTooltip
              arrow
              data-testid="target-tooltip"
              enterDelay={500}
              placement="top"
              title={<div style={{ whiteSpace: "pre-line" }}>{Array.from(targetsList).join("\n")}</div>}
            >
              <IconButton>
                <InfoOutlinedIcon color="action" />
              </IconButton>
            </TargetsTooltip>
          )
        }
        classes={{
          action: classes.cardAction,
          content: classes.headerContent,
          subheader: classes.headerText,
          title: classes.headerText,
        }}
        title={widget.title}
      />
      <CardContent className={classes.content}>
        <ErrorBoundary variant="chart">
          {widgetTarget && widgetTarget.features.performance ? (
            <DashboardWidgetChart
              dateRange={widgetDateRange}
              metrics={widget.options.series.map((series) => ({ ...series, axisLabel: null }))}
              target={widgetTarget}
            />
          ) : (
            <RestrictedDataIndicator />
          )}
        </ErrorBoundary>
      </CardContent>
      {isOver && direction === "left" && (
        <div className={classNames(classes.dropOverlay, classes.dropOverlayLeft)}>
          <ChevronLeftIcon color="action" fontSize="large" />
        </div>
      )}
      {isOver && direction === "right" && (
        <div className={classNames(classes.dropOverlay, classes.dropOverlayRight)}>
          <ChevronRightIcon color="action" fontSize="large" />
        </div>
      )}
    </Card>
  );
};

export default DashboardWidget;
