import React, { useMemo } from "react";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { Bar } from "@visx/shape";
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { Group } from "@visx/group";
import { localPoint } from '@visx/event';
import { scaleBand, scaleLinear } from "@visx/scale";
import { IHealthScoreHistoryItemResponse } from "../HealthScore/useHealthScoreHistory";
import MiniHealthScoreCard from "../HealthScore/MiniHealthScoreCard";


const getColorForHealthScore = (score: number): string => {
  if (score <= 10) {
    return "#B40019";
  } else if (score <= 20) {
    return "#D91C24";
  } else if (score <= 30) {
    return "#F15A24";
  } else if (score <= 40) {
    return "#F38301";
  } else if (score <= 50) {
    return "#FFC836";
  } else if (score <= 60) {
    return "#F1C40F";
  } else if (score <= 70) {
    return "#CBDB2A";
  } else if (score <= 80) {
    return "#85C441";
  } else if (score <= 90) {
    return "#4CA03D";
  } else {
    return "#1B7C2B";
  }
};

const getDate = (d: IHealthScoreHistoryItemResponse): Date => d.timestampUtc;
const getScore = (d: IHealthScoreHistoryItemResponse): number => (d.score ? d.score : 0);

type ScoreHistoryChartProps = {
  width: number;
  height: number;
  data: Array<IHealthScoreHistoryItemResponse>;
};

const verticalMargin = 50;
const margins = {
  left: 40,
};

let tooltipTimeout: number;

const ScoreHistoryChart = ({ data, width, height }: ScoreHistoryChartProps): JSX.Element => {
  // bounds
  const xMax = width - (margins.left + 10);
  const yMax = height - verticalMargin;

  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleBand<Date>({
        domain: data.map(getDate),
        paddingInner: 0.6,
        paddingOuter: 0.2,
        range: [0, xMax],
        round: true,
      }),
    [data, xMax],
  );

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        domain: [0, Math.max(...data.map(getScore))],
        range: [yMax, 0],
        round: true,
      }),
    [data, yMax],
  );

  const tickFormatter = (d: Date): string => {
    const tmp = new Date(d);
    return `${tmp.getUTCMonth()+1}/${tmp.getUTCDate()}`;
  };

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<IHealthScoreHistoryItemResponse>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  });

  return (
    <>
      <svg height={height} ref={containerRef} style={{ boxShadow: "2px 2px 10px grey" }} width="100%">
        <rect fill="white" height={height} width="100%" />
        <Group left={margins.left} top={verticalMargin / 2}>
          {data.map((d) => {
            const date = getDate(d);
            const barWidth = xScale.bandwidth();
            const barHeight = yMax - (yScale(getScore(d)) ?? 0);
            const barX = xScale(date);
            const barY = yMax - barHeight;
            return (
              <Bar
                fill={getColorForHealthScore(d.score)}
                height={barHeight}
                key={`bar-${date}`}
                onMouseLeave={() => {
                  tooltipTimeout = window.setTimeout(() => {
                    hideTooltip();
                  }, 300);
                }}
                onMouseMove={(event: React.TouchEvent<SVGRectElement>
                  | React.MouseEvent<SVGRectElement>) => {
                  if (tooltipTimeout) clearTimeout(tooltipTimeout);
                  const coords = localPoint(event) || { x: 0, y: 0 };
                  showTooltip({
                    tooltipData: d,
                    tooltipLeft: coords.x,
                    tooltipTop: coords.y - 70,
                  });
                }}
                width={barWidth}
                x={barX}
                y={barY}
              />
            );
          })}
          <AxisBottom
            numTicks={data.length / 2}
            scale={xScale}
            stroke="#cfcfcf"
            tickFormat={tickFormatter}
            tickLabelProps={() => ({
              fill: "#cfcfcf",
              fontSize: 10,
              textAnchor: "middle",
            })}
            tickStroke="#cfcfcf"
            top={yMax}
          />
          <AxisLeft
            numTicks={4}
            scale={yScale.nice()}
            stroke="#cfcfcf"
            tickLabelProps={(e) => ({
              fill: "#cfcfcf",
              fontSize: 10,
              textAnchor: "end",
              x: -12,
              y: (yScale(e) ?? 0) + 3,
            })}
            tickStroke="#cfcfcf"
            top={0}
          />
        </Group>
      </svg>
      {tooltipOpen && (
        <TooltipInPortal
          // set this to random so it correctly updates with parent bounds
          key={Math.random()}
          left={tooltipLeft}
          style={{
            ...defaultStyles,
            lineHeight: 'inherit',
            padding: '2px',
            width: '110px',
            zIndex: '1301'
          }}
          top={tooltipTop}
        >
          <MiniHealthScoreCard score={tooltipData?.score ?? 0} score_size="small"></MiniHealthScoreCard>
        </TooltipInPortal>
      )}
    </>
  );
};

export default ScoreHistoryChart;
