import { IViewBox } from "./types";

/**
 * Returns the angle (in radians) between two points.
 * @param start An [x, y] pair of cartesian coordinates.
 * @param end An [x, y] pair of cartesian coordinates.
 */
export function getAngle([x1, y1]: [number, number], [x2, y2]: [number, number]): number {
  const deltaX = x2 - x1;
  const deltaY = y2 - y1;
  return Math.atan2(deltaY, deltaX);
}

/**
 * Converts a PointerEvent's coordinates into coordinates within the SVG.
 * @param event The PointerEvent that triggered the mouse interaction.
 */
export function getPointerSvgPoint(event: React.PointerEvent<SVGSVGElement>): DOMPoint {
  const { clientX, clientY, currentTarget: svg } = event;
  const pt = svg.createSVGPoint();
  pt.x = clientX;
  pt.y = clientY;
  // getScreenCTM isn't documented to return null as the type definition states
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return pt.matrixTransform(svg.getScreenCTM()!.inverse());
}

/**
 * Converts a MouseEvent's coordinates into coordinates within the SVG.
 * @param event The MouseEvent that triggered the mouse interaction.
 */
export function getMouseSvgPoint(event: React.MouseEvent<SVGSVGElement>): DOMPoint {
  const { clientX, clientY, currentTarget: svg } = event;
  const pt = svg.createSVGPoint();
  pt.x = clientX;
  pt.y = clientY;
  // getScreenCTM isn't documented to return null as the type definition states
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return pt.matrixTransform(svg.getScreenCTM()!.inverse());
}

/**
 *
 * @param angle A numeric expression that contains an angle measured in radians.
 * @param width
 * @param height
 * @param center An [x, y] pair of cartesian coordinates representing the center of the rectangle
 */
export function getPointOnRectangle(
  angle: number,
  width: number,
  height: number,
  [cx, cy]: [number, number],
): [number, number] {
  const cos = Math.cos(angle);
  const sin = Math.sin(angle);
  if (width * Math.abs(sin) < height * Math.abs(cos)) {
    const x = (Math.sign(cos) * width) / 2;
    const y = Math.tan(angle) * x;
    return [cx + x, cy + y];
  } else {
    const y = (Math.sign(sin) * height) / 2;
    const x = (1 / Math.tan(angle)) * y;
    return [cx + x, cy + y];
  }
}

export class ViewBox implements IViewBox {
  constructor(
    public readonly minX: number,
    public readonly minY: number,
    public readonly width: number,
    public readonly height: number,
  ) {}

  public get center(): { x: number; y: number } {
    const x = this.minX + this.width / 2;
    const y = this.minY + this.height / 2;
    return { x, y };
  }

  public toViewBoxValue(): string {
    return `${this.minX} ${this.minY} ${this.width} ${this.height}`;
  }

  public translate(dx: number, dy: number): ViewBox {
    return new ViewBox(this.minX + dx, this.minY + dy, this.width, this.height);
  }

  public scale(factor: number, x: number, y: number): ViewBox {
    const width = this.width * factor;
    const height = this.height * factor;
    const minX = x - width * ((x - this.minX) / this.width);
    const minY = y - height * ((y - this.minY) / this.height);
    return new ViewBox(minX, minY, width, height);
  }
}
