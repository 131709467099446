import { MoRef } from "../../utilities/TopologyUtility";
import { IUserTenant } from "../../api/AccountService/AccountService";
import { TopologyObjectType } from "../../api/PerformanceAdvisor";
export { TopologyObjectType } from "../../api/PerformanceAdvisor";

export interface ITargetFeatures {
  readonly alerts: boolean;
  readonly blocking: boolean;
  readonly deadlocks: boolean;
  readonly health: boolean;
  readonly performance: boolean;
  readonly permissionAdministration: boolean;
  readonly storage: boolean;
  readonly tempdb: boolean;
  readonly topsql: boolean;
}

interface ITopologyItemBase {
  description?: string;
  features: ITargetFeatures;
  itemId: number;
  name: string;
  objectId: string;
  parentObjectId?: string | null;
  tenantId: string;
  type: TopologyObjectType;
  versionInfo?: string | null;
  versionName?: string | null;
  versionNumber?: string | null;
}
export interface ITopologyItemDevice extends ITopologyItemBase {
  coreCount: number | null;
  lastBootUpTime: Date;
  totalMemory: number | null;
  isWatched: boolean;
  type: TopologyObjectType.device;
  deviceType: TopologyDeviceType;
  children: MoRef[];
}
export interface ITopologyItemEventSourceConnection extends ITopologyItemBase {
  isWatched: boolean;
  parentObjectId: string;
  eventSourceConnectionType: TopologyEventSourceConnectionType;
  //Target types like VMware do not return a settings object, so null is a valid settings value
  settings: {
    readonly collectTempDbSessions: boolean;
    readonly collectTempDbObjects: boolean;
  } | null;
  type: TopologyObjectType.eventSourceConnection;
}
export interface ITopologyItemGroup extends ITopologyItemBase {
  type: TopologyObjectType.group | TopologyObjectType.site;
}
export type ITopologyItem = ITopologyItemDevice | ITopologyItemEventSourceConnection | ITopologyItemGroup;

export interface ITopology {
  devices: readonly ITopologyItemDevice[];
  eventSourceConnections: readonly ITopologyItemEventSourceConnection[];
  groups: readonly ITopologyItemGroup[];
  loading: boolean;
  tenantErrors: ReadonlyMap<IUserTenant["id"], Error>;
  findByItemId(id: number, type: TopologyObjectType): ITopologyItem | null | undefined;
  findByMoRef(moRef: MoRef): ITopologyItem | null;
  findByObjectId(id: string, type?: TopologyObjectType): ITopologyItem | null | undefined;
  findByParentObjectId(id: string, type?: TopologyObjectType): ITopologyItem[];
  shouldShowFeature(feature: keyof ITargetFeatures): boolean;
  reload(): void;
}

export interface ITopologyItems {
  dates: {
    endDate: Date;
    startDate: Date;
  };
  device: ITopologyItem | null;
  eventSourceConnections: ITopologyItem[] | null;
  selectedId?: number | null;
}

export const enum TopologyEventSourceConnectionType {
  AmazonRdsSqlServer = "AMAZON_RDS_SQL_SERVER",
  Aps = "APS",
  AzureElasticPool = "AZURE_ELASTIC_POOL",
  AzureSqlDatabase = "AZURE_SQL_DATABASE",
  NotSpecified = "NOT_SPECIFIED",
  OracleDatabase = "ORACLE_DATABASE",
  SharePointServer = "SHARE_POINT_SERVER",
  SqlDataWarehouse = "SQL_DATA_WAREHOUSE",
  SqlServer = "SQL_SERVER",
  SqlServerAnalysisServices = "SQL_SERVER_ANALYSIS_SERVICES",
  TintriVMStore = "TINTRI_VM_STORE",
}

export const enum TopologyDeviceType {
  AmazonRdsSqlServer = "AMAZON_RDS_SQL_SERVER",
  Aps = "APS",
  AzureElasticPool = "AZURE_ELASTIC_POOL",
  AzureSqlDatabase = "AZURE_SQL_DATABASE",
  AzureSQLDataWarehouse = "AZURE_SQL_DATA_WAREHOUSE",
  Linux = "LINUX",
  ManagedInstance = "MANAGED_INSTANCE",
  NotSpecified = "NOT_SPECIFIED",
  Tintri = "TINTRI",
  VMWare = "VM_WARE",
  Windows = "WINDOWS",
}
