import * as React from "react";
import formatDate from "./formatDate";
import IDateTimeFormatOptions from "./IDateTimeFormatOptions";

export interface IFormattedDateProps extends IDateTimeFormatOptions {
  children?: (value: string) => React.ReactNode;
  value: Date;
}

class FormattedDate extends React.Component<IFormattedDateProps> {
  public render():React.ReactNode{
    const { children, value, ...options } = this.props;

    const date = formatDate(value, options);

    if (typeof children === "function") {
      return children(date);
    } else {
      return date;
    }
  }
}

export default FormattedDate;
