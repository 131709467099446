import * as React from "react";
import { IBlockingContext, IBlockingFilter } from "./types";

const BlockingContext = React.createContext<IBlockingContext>({
  blockersByType: {
    byApplications: [],
    byDatabases: [],
    byHosts: [],
  },
  blockingFilter: {
    duration: {
      unit: "Minutes",
      value: 15,
    },
    selectedApplications: [],
    selectedDatabases: [],
    selectedHosts: [],
    selectedResource: [],
    selectedResourceType: [],
  },
  filterApplied: false,
  loading: true,
  setBlockingFilter: (newFilters: Partial<IBlockingFilter>) => null,
});

export default BlockingContext;
