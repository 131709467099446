import { faUserShield } from "@fortawesome/free-solid-svg-icons/faUserShield";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import Toolbar from "@material-ui/core/Toolbar";
import Help from "@material-ui/icons/Help";
import MenuIcon from "@material-ui/icons/Menu";
import TagFaces from "@material-ui/icons/TagFaces";
import { Profile } from "@sentryone/material-ui";
import classNames from "classnames";
import * as React from "react";
import { useIntl } from "react-intl";
import { Link, Route } from "react-router-dom";
import Logo from "../../../../components/Logo";
import { useTopology } from "../../../../components/TopologyContext";
import Config, { IClientTelemetryConfiguration } from "../../../../config/Config";
import { useFeatureFlag } from "../../../../contexts/featuresContext";
import { useUserContext } from "../../../../contexts/userContext";
import { useAsync } from "../../../../hooks/Async";
import Notifications from "../Notifications";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import MenuPopper from "../../../../components/MenuPopper/MenuPopper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import DateSelection from "../../../../components/DateSelection/DateSelection";
interface INavBarProps {
  className?: string;
  drawerVisible?: boolean;
  onDrawerToggle?: () => void;
  path?: string;
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  buttonLabel: {
    textTransform: "none",
  },
  buttonMain: {
    "&:hover": {
      backgroundColor: "unset",
    },
    fontWeight: "normal",
  },
  formControl: {
    "& .MuiSelect-selectMenu": {
      paddingLeft: theme.spacing(5),
    },
    margin: theme.spacing(1),
    minWidth: 120,
  },
  label: {
    paddingTop: theme.spacing(1.5),
  },
  logo: {
    height: "1.5em",
  },
  menuButton: {
    marginLeft: "-12px",
    marginRight: "4px",
  },
  menuItem: {},
  nDrawer: {
    maxWidth: "223px",
    minWidth: "223px",
  },
  nSpan: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    margin: "3px 6px",
    minWidth: "0px",
    overflow: "hidden",
  },
  nTitle: {
    borderBottom: "1px solid #e0e0e0",
    fontSize: "1em",
    padding: "12px 16px",
  },
  nTypography: {
    fontSize: "0.706em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
  },
  spacer: {
    display: "flex",
    flex: "1",
    justifyContent: "flex-end",
  },
  svgWidth: {
    marginTop: -theme.spacing(2.5),
    width: "200px",
  },
  tab: {
    minWidth: theme.spacing(20),
    width: "16ch",
  },
  tabLabelContainer: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },
  tabs: {
    overflow: "visible",
  },
  tabsScroller: {
    overflow: "visible",
  },
}));

function fetchTelemetry(): Promise<IClientTelemetryConfiguration> {
  return Config.telemetry();
}

function useTelemetry(): boolean {
  const { data, error } = useAsync(fetchTelemetry);
  return !!data && !!data.key && data.sendTelemetry;
}

const NavBar: React.FunctionComponent<INavBarProps> = ({ className, onDrawerToggle, path }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { shouldShowFeature } = useTopology();
  const hasNotifications = useFeatureFlag("notifications");
  const isTelemetryEnabled = useTelemetry();
  const { userInfo } = useUserContext();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  const showPermissions = React.useMemo<boolean>(() => {
    return shouldShowFeature("permissionAdministration");
  }, [shouldShowFeature]);

  return (
    <AppBar className={classNames(className, classes.appBar)} color="inherit" elevation={0} position="relative">
      <Toolbar role="nav">
        {onDrawerToggle && (
          <IconButton
            className={classes.menuButton}
            data-telemetry-id="telemetry-nav-toggleLeftNav"
            onClick={onDrawerToggle}
            title={intl.formatMessage({ id: "toggleSidebar" })}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Route
          path="/:tenant"
          render={({ match }) => (
            <Link className={classes.logo} to={`/${match.params.tenant}`}>
              <Logo className={classes.svgWidth} />
            </Link>
          )}
        />

        <div className={classes.spacer} />
        {path === "health" ? <></> : <DateSelection />}

        {isTelemetryEnabled && (
          <IconButton id={intl.formatMessage({ id: "feedback" })} title={intl.formatMessage({ id: "feedback" })}>
            <TagFaces />
          </IconButton>
        )}

        {hasNotifications && (
          <Notifications
            styleSheet={{
              drawer: classes.nDrawer,
              span: classes.nSpan,
              title: classes.nTitle,
              typography: classes.nTypography,
            }}
          />
        )}

        <IconButton
          aria-controls="menu-list-grow"
          aria-haspopup={open}
          onClick={handleToggle}
          ref={anchorRef}
          title={intl.formatMessage({ id: "help" })}
        >
          <Help />
        </IconButton>

        {anchorRef.current && (
          <MenuPopper anchorEl={anchorRef.current} open={open}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} data-testid="helpContextMenu" id="menu-list-grow">
                  <MenuItem onClick={handleClose}>
                    <Button
                      classes={{ label: classes.buttonLabel, root: classes.buttonMain }}
                      color="default"
                      href="https://docs.sentryone.com/help/getting-started-sentryone-portal"
                      target="_blank"
                      variant="text"
                    >
                      {intl.formatMessage({ id: "documentation" })}
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Button
                      classes={{ label: classes.buttonLabel, root: classes.buttonMain }}
                      color="default"
                      href="https://support.solarwinds.com"
                      target="_blank"
                      variant="text"
                    >
                      {intl.formatMessage({ id: "solarwindsSupport" })}
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Button
                      classes={{ label: classes.buttonLabel, root: classes.buttonMain }}
                      color="default"
                      href="https://thwack.solarwinds.com/product-forums/sql-sentry/i/feature-requests"
                      target="_blank"
                      variant="text"
                    >
                      {intl.formatMessage({ id: "featureRequest" })}
                    </Button>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </MenuPopper>
        )}

        {showPermissions && (
          <Route
            path="/:tenant"
            render={({ match }) => (
              <IconButton
                component={Link}
                id={intl.formatMessage({ id: "permissions" })}
                title={intl.formatMessage({ id: "permissions" })}
                to={`/${match.params.tenant}/permissions`}
              >
                <SvgIcon fontSize="inherit">
                  <FontAwesomeIcon icon={faUserShield} />
                </SvgIcon>
              </IconButton>
            )}
          />
        )}

        {userInfo && userInfo.email && (
          <Profile
            info={userInfo}
            logoutPath="/account/signout"
            passwordBaseUrl={(window as any).ACCOUNTSITE}
            profileId="profileSidebar"
          />
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
