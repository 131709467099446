import * as React from "react";
import { styled } from "@material-ui/core/styles";
import CircularProgress, { CircularProgressProps } from "@material-ui/core/CircularProgress";

const GridCenter = styled("div")({
  display: "grid",
  height: "100%",
  placeItems: "center",
  width: "100%",
});

interface IFeatureLoadingSpinnerProps {
  containerProps?: React.DetailedHTMLProps<React.HtmlHTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  spinnerProps?: Partial<CircularProgressProps>;
}

const FeatureLoadingSpinner = ({ containerProps, spinnerProps }: IFeatureLoadingSpinnerProps): React.ReactElement => (
  <GridCenter {...containerProps}>
    <CircularProgress thickness={4} {...spinnerProps} />
  </GridCenter>
);

export default FeatureLoadingSpinner;
