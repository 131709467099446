import * as React from "react";
import { useIntl } from "react-intl";
import { useDateContext } from "../../../../components/DateContext";
import {
  AggregateTypes,
  IAggregateMetricOptions,
  MetricUnitOfMeasure,
  useAggregateMetrics,
} from "../../../../components/MetricChart";
import { useStorageContext } from "../../../../contexts/storageContext";
import { IStorageEntity, StorageEntityType } from "../../types";

export interface IFormattedAggregateMetricData {
  label: string;
  value: string;
}

export interface IStorageActivityStats {
  data: IFormattedAggregateMetricData[];
  error: Error | null;
  expectedNumberOfMetrics: number;
  isLoading: boolean;
}

interface IAggregateMetricOptionsPartial {
  aggregateType: AggregateTypes;
  label: string;
  metricKey: string;
}

const sqlFileMetricReadsOptions: IAggregateMetricOptionsPartial[] = [
  {
    aggregateType: AggregateTypes.SUM,
    label: "reads",
    metricKey: "sqlserver.filestats.reads",
  },
  {
    aggregateType: AggregateTypes.AVERAGE,
    label: "readsPerSecond",
    metricKey: "sqlserver.filestats.reads",
  },
  {
    aggregateType: AggregateTypes.SUM,
    label: "readBytes",

    metricKey: "sqlserver.filestats.read.throughput",
  },
  {
    aggregateType: AggregateTypes.AVERAGE,
    label: "readBytesPerSecond",
    metricKey: "sqlserver.filestats.read.throughput",
  },
  {
    aggregateType: AggregateTypes.AVERAGE,
    label: "readBytesPercent",
    metricKey: "sqlserver.filestats.read.throughput.pct",
  },
  {
    aggregateType: AggregateTypes.AVERAGE,
    label: "bytesPerRead",
    metricKey: "sqlserver.filestats.read.bytesPerRead",
  },
  // These metrics have very poor performance, so we are removing them until
  // the performance issues can be investigated and addressed.
  // {
  //   aggregateType: AggregateTypes.AVERAGE,
  //   label: "msRead",
  //   metricKey: "sqlserver.filestats.read.msPerRead",
  // },
  // {
  //   aggregateType: AggregateTypes.AVERAGE,
  //   label: "msWrite",
  //   metricKey: "sqlserver.filestats.write.msPerWrite",
  // },
];

const sqlFileMetricWritesOptions: IAggregateMetricOptionsPartial[] = [
  {
    aggregateType: AggregateTypes.SUM,
    label: "writes",
    metricKey: "sqlserver.filestats.writes",
  },
  {
    aggregateType: AggregateTypes.AVERAGE,
    label: "writesPerSecond",
    metricKey: "sqlserver.filestats.writes",
  },
  {
    aggregateType: AggregateTypes.SUM,
    label: "writeBytes",
    metricKey: "sqlserver.filestats.write.throughput",
  },
  {
    aggregateType: AggregateTypes.AVERAGE,
    label: "writeBytesPerSecond",
    metricKey: "sqlserver.filestats.write.throughput",
  },
  {
    aggregateType: AggregateTypes.AVERAGE,
    label: "writeBytesPercent",
    metricKey: "sqlserver.filestats.write.throughput.pct",
  },
  {
    aggregateType: AggregateTypes.AVERAGE,
    label: "bytesPerWrite",
    metricKey: "sqlserver.filestats.write.bytesPerWrite",
  },
];

const physicalDiskMetricReadsOptions: IAggregateMetricOptionsPartial[] = [
  {
    aggregateType: AggregateTypes.SUM,
    label: "reads",
    metricKey: "os.disk.reads.ps",
  },
  {
    aggregateType: AggregateTypes.AVERAGE,
    label: "readsPerSecond",
    metricKey: "os.disk.reads.ps",
  },
  {
    aggregateType: AggregateTypes.SUM,
    label: "readBytes",
    metricKey: "os.disk.read.throughput",
  },
  {
    aggregateType: AggregateTypes.AVERAGE,
    label: "readBytesPerSecond",
    metricKey: "os.disk.read.throughput",
  },
  {
    aggregateType: AggregateTypes.AVERAGE,
    label: "readBytesPercent",
    metricKey: "os.disk.read.throughput.pct",
  },
  {
    aggregateType: AggregateTypes.AVERAGE,
    label: "bytesPerRead",
    metricKey: "os.disk.read.bytesPerRead",
  },
  {
    aggregateType: AggregateTypes.AVERAGE,
    label: "msRead",
    metricKey: "os.disk.read.ms",
  },
];

const physicalDiskMetricWritesOptions: IAggregateMetricOptionsPartial[] = [
  {
    aggregateType: AggregateTypes.SUM,
    label: "writes",
    metricKey: "os.disk.writes.ps",
  },
  {
    aggregateType: AggregateTypes.AVERAGE,
    label: "writesPerSecond",
    metricKey: "os.disk.writes.ps",
  },
  {
    aggregateType: AggregateTypes.SUM,
    label: "writeBytes",
    metricKey: "os.disk.write.throughput",
  },
  {
    aggregateType: AggregateTypes.AVERAGE,
    label: "writeBytesPerSecond",
    metricKey: "os.disk.write.throughput",
  },
  {
    aggregateType: AggregateTypes.AVERAGE,
    label: "writeBytesPercent",
    metricKey: "os.disk.write.throughput.pct",
  },
  {
    aggregateType: AggregateTypes.AVERAGE,
    label: "bytesPerWrite",
    metricKey: "os.disk.write.bytesPerWrite",
  },
  {
    aggregateType: AggregateTypes.AVERAGE,
    label: "msWrite",
    metricKey: "os.disk.write.ms",
  },
];

export interface IUseStorageActivityStatsParams {
  entity: IStorageEntity;
  metricType: "reads" | "writes";
}

function useStorageActivityStats({ entity, metricType }: IUseStorageActivityStatsParams): IStorageActivityStats {
  const intl = useIntl();
  const { dateRange } = useDateContext();
  const { target } = useStorageContext();
  const [expectedNumberOfMetrics, setExpectedNumberOfMetrics] = React.useState<number>(0);

  const getMetricOptions = (metricOptionsArray: IAggregateMetricOptionsPartial[], instanceName: string): IAggregateMetricOptions[] => {
    setExpectedNumberOfMetrics(metricOptionsArray.length);
    return metricOptionsArray.map((options) => ({
      aggregateType: options.aggregateType,
      instanceName: instanceName,
      label: options.label,
      metricKey: options.metricKey,
    }));
  }

  const metricOptions = React.useMemo<IAggregateMetricOptions[]>(() => {
    if (entity.type === StorageEntityType.SqlServerDataFile || entity.type === StorageEntityType.SqlServerLogFile) {
      if (metricType === "reads") {
        return getMetricOptions(sqlFileMetricReadsOptions, `${entity.name} (${entity.databaseName})`);
      } else {
        return getMetricOptions(sqlFileMetricWritesOptions, `${entity.name} (${entity.databaseName})`);
      }
    } else if (entity.type === StorageEntityType.PhysicalDisk) {
      if (metricType === "reads") {
        return getMetricOptions(physicalDiskMetricReadsOptions, `${entity.diskIndex}`);
      } else {
        return getMetricOptions(physicalDiskMetricWritesOptions, `${entity.diskIndex}`);
      }
    } else {
      setExpectedNumberOfMetrics(0);
      return [];
    }
  }, [entity, metricType]);

  const { data, isLoading, error } = useAggregateMetrics({
    dateRange,
    metrics: metricOptions,
    target,
  });

  const formattedAggregateData: IFormattedAggregateMetricData[] = data.map((d) => ({
    label: d.label,
    value:
      d.unitOfMeasure === MetricUnitOfMeasure.Percent
        ? intl.formatNumber(d.value / 100, { maximumFractionDigits: 2, style: "percent" })
        : intl.formatNumber(d.value, { maximumFractionDigits: 2 }),
  }));

  return {
    data: formattedAggregateData,
    error,
    expectedNumberOfMetrics,
    isLoading,
  };
}

export default useStorageActivityStats;
