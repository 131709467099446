import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import { TransitionProps as ITransitionProps } from "@material-ui/core/transitions";
import * as React from "react";

interface IMenuPopperProps {
  anchorEl: HTMLElement;
  children: React.ReactElement<any, any>;
  open: boolean;
}

const MenuPopper: React.FunctionComponent<IMenuPopperProps> = ({ anchorEl, children, open }) => {
  return (
    <Popper anchorEl={anchorEl} disablePortal open={open} transition>
      {({ TransitionProps }: { TransitionProps: ITransitionProps }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: "center bottom" }}>
          {children}
        </Grow>
      )}
    </Popper>
  );
};

export default MenuPopper;
