import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import * as Host from "../HostCharts";
import * as Sql from "../SqlCharts";
import ChartCard from "./ChartCard";

const useStyles = makeStyles((theme) => ({
  host: {},
  root: {
    display: "grid",
    gridAutoFlow: "row dense",
    gridAutoRows: theme.spacing(45),
    gridGap: theme.spacing(2),
    gridTemplateColumns: "1fr",
    [theme.breakpoints.up("lg")]: {
      "& $host": {
        gridColumn: 1,
      },
      "& $sql": {
        gridColumn: 2,
      },
      gridTemplateColumns: "1fr 1fr",
    },
  },
  sql: {},
}));

const WindowsSqlPerformanceAnalysis: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ChartCard chartComponent={Host.NetworkChart} className={classes.host} title="network" vertical />
      <ChartCard chartComponent={Host.CpuChart} className={classes.host} title="cpu" />
      <ChartCard chartComponent={Host.SystemMemoryChart} className={classes.host} title="systemMemory" />
      <ChartCard chartComponent={Host.DiskIOChart} className={classes.host} title="disk" />
      <ChartCard chartComponent={Sql.SqlServerActivityChart} className={classes.sql} title="sqlServerActivity" />
      <ChartCard chartComponent={Sql.WaitsChart} className={classes.sql} title="sqlServerWaits" />
      <ChartCard chartComponent={Sql.SqlServerMemoryChart} className={classes.sql} title="sqlServerMemory" />
      <ChartCard chartComponent={Sql.DatabaseIOChart} className={classes.sql} title="databaseIO" />
    </div>
  );
};

export default WindowsSqlPerformanceAnalysis;
