import * as React from "react";
import { Card, CardContent, Typography, Grid, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TargetIcon from "../../components/icons/TargetIcon";
import { IHealthStatusItem } from "../../api/PerformanceAdvisor";
import {
  ITopologyItemDevice,
  ITopologyItemEventSourceConnection,
  TopologyDeviceType,
  TopologyEventSourceConnectionType,
} from "../../components/TopologyContext";
import { Link } from "react-router-dom";
import DownArrowIcon from "../../components/icons/DownArrowIcon";
import UpArrowIcon from "../../components/icons/UpArrowIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

export interface ITargetScoreCardProps {
  calculateDelta: boolean;
  className?: string;
  health: IHealthStatusItem;
  target: ITopologyItemDevice | ITopologyItemEventSourceConnection;
}
const useStyles = makeStyles(() => ({
  deltaScoreDiv: {
    display: "flex",
    justifyContent: "center",
  },
  downArrow: {
    color: "red",
    height: "auto",
  },
  longText: {
    overflow: "hidden",
    paddingLeft: "2px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "195px",
  },
  root: {
    "& .MuiCardContent-root": {
      padding: "10px 5px",
      width: "100%",
    },
    border: "1px solid rgba(0, 0, 0, 0.2)",
    color: "grey",
    display: "flex",
    width: "23.7rem",
  },
  score: {
    alignItems: "center",
    display: "flex",
    fontSize: "2rem",
    fontWeight: "bold",
    height: "100%",
    justifyContent: "center",
    maxWidth: "80px",
    minWidth: "65px",
    paddingRight: "5px",
  },
  targetIconDiv: {
    marginRight: "12px",
    maxInlineSize: "fit-content",
  },
  upArrow: {
    color: "green",
    height: "auto",
  },
}));

const getColorForScoreDelta = (score: number): string => {
  if (score < 0) {
    return "#FF0000";
  } else if (score > 0) {
    return "#008000";
  } else {
    return "#000000";
  }
};

const TargetScoreCard: React.FC<ITargetScoreCardProps> = ({ calculateDelta, health, target }) => {
  const theme = useTheme();
  const classes = useStyles();
  const scoreColor = theme.palette.getColorForHealthScore(health?.score ?? 100); // get the color based on health score

  const scoreDeltaColor = getColorForScoreDelta(health.scoreDelta); // get the color based on scoreDelta

  // find the target type from the device and eventSourceConnection item.
  const getTargetType = (
    target: ITopologyItemDevice | ITopologyItemEventSourceConnection,
  ): TopologyDeviceType | TopologyEventSourceConnectionType =>
    "deviceType" in target ? target.deviceType : target.eventSourceConnectionType;

  return (
    <Card className={classes.root} key={health.itemId}>
      <CardContent>
        <Grid container>
          <Grid item xs={3}>
            {calculateDelta ? (
              <div className={classes.deltaScoreDiv}>
                {health.scoreDelta !== 0 ? (
                  health.scoreDelta < 0 ? (
                    <DownArrowIcon className={classes.downArrow} data-testid="scoreDeltaDownArrow" />
                  ) : (
                    <UpArrowIcon className={classes.upArrow} data-testid="scoreDeltaUpArrow" />
                  )
                ) : null}
                <Typography
                  className={classes.score}
                  data-testid="scoreDelta"
                  style={{ color: scoreDeltaColor }}
                  variant="body1"
                >
                  {health.scoreDelta}
                </Typography>
              </div>
            ) : (
              <Typography
                className={classes.score}
                data-testid="targetsHealthScore"
                style={{ color: scoreColor }}
                variant="body1"
              >
                {health.score ?? <FontAwesomeIcon color="gray" icon={faCircle} />}
              </Typography>
            )}
          </Grid>
          <Grid alignItems="center" container item sm xs={9}>
            <Grid className={classes.targetIconDiv} item xs={3}>
              <TargetIcon activeTargetType={getTargetType(target)} fontSize="small" sizeIcon="large" />
            </Grid>
            <Grid item xs={6}>
              <Link to={`/${target.tenantId}/targets/${target.objectId}/health`}>
                <Typography className={classes.longText} color="primary" title={target.name} variant="body1">
                  {target.name}
                </Typography>
              </Link>
              <Typography className={classes.longText} color="textSecondary" title={target.description} variant="body2">
                {target.description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default TargetScoreCard;
