import * as React from "react";
import { ChartRange } from "../../components/recharts";

export interface ISelectionInfo {
  selection: ChartRange;
  setSelection(newValue: ChartRange): void;
}

const SelectionContext = React.createContext<ISelectionInfo>({
  selection: [null, null],
  setSelection: () => {
    return;
  },
});

export default SelectionContext;

export function useSelection(): ISelectionInfo {
  return React.useContext(SelectionContext);
}
