import { useQuery } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { CardTable, MuiTreeTable, TreeToggleColumn } from "@sentryone/material-ui";
import { Column } from "@sentryone/react-datatable";
import { DateTime, Duration } from "luxon";
import * as React from "react";
import { useIntl } from "react-intl";
import { DateColumn, TextEllipsisColumn, TimespanColumn } from "../../../../components/DataTable";
import { useDateContext } from "../../../../components/DateContext";
import { useBlockingContext } from "../../../../contexts/blockingContext";
import {
  IBlockChainDetail,
  IBlockChainGraphResponse,
  IBlockChainGroup,
  IBlockChainVars,
  IBlockChainVersion,
  IBlockingSelection,
  IParsedBlockChainVersion,
} from "../../types";
import ChartFilterChip from "../ChartFilterChip";
import * as GET_BLOCKING_CHAINS from "./blockingGridQuery.graphql";
import BlockingStatusIcon from "./BlockingStatusIcon";
import VersionSelector from "./VersionSelector";

const MAX_RESULTS = 10000;

const useStyles = makeStyles((theme) => ({
  chipBox: {
    flexWrap: "wrap",
    marginLeft: "auto",
  },
  legend: {
    alignItems: "center",
    display: "inline-flex",
    verticalAlign: "bottom",
  },
  legendIcon: {
    marginLeft: theme.spacing(2),
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  spid: {
    color: theme.palette.primary.main,
    verticalAlign: "inherit",
  },
  titleStyle: {
    display: "flex",
    flexDirection: "row",
  },
}));

interface IBlockingDetailGridRow {
  application: string | null;
  children: readonly IBlockingDetailGridRow[];
  database: string;
  detail: IBlockChainDetail;
  duration: Duration | null;
  group: IBlockChainGroup;
  host: string | null;
  key: string;
  login: string | null;
  spid: number;
  startTime: Date | null;
  statement: string;
  status: "blocked" | "completed" | "running";
  version: IParsedBlockChainVersion;
  versionNumber: number | null;
  versions: readonly IParsedBlockChainVersion[];
  waitResource: string | null;
  waitTime: string;
  waitType: string | null;
}

export interface IBlockingDetailGridProps {
  className?: string;
  eventSourceConnectionId: number;
  onChangeSelection: (selection: IBlockingSelection | null) => void;
  selection: IBlockingSelection | null;
  visibleDates: Readonly<{
    from: Date;
    to: Date;
  }>;
}

function parseVersionDates(version: IBlockChainVersion): IParsedBlockChainVersion {
  const endTime = version.endTime ? new Date(version.endTime) : null;
  const startTime = new Date(version.startTime);
  return {
    ...version,
    endTime,
    startTime,
  };
}

function getBlockStatus(
  _: IBlockChainGroup,
  version: IBlockChainVersion,
  detail: IBlockChainDetail,
): "blocked" | "completed" | "running" {
  if (detail.blockedBySPID) {
    return "blocked";
  } else if (version.endTime) {
    return "completed";
  } else {
    return "running";
  }
}

/**
 * Recursively maps block chain information into rows with children.
 * @param group
 * @param version
 * @param detail
 */
function mapDetailRow(
  group: IBlockChainGroup,
  version: IBlockChainVersion,
  detail: IBlockChainDetail,
): IBlockingDetailGridRow {
  const isRoot = !detail.blockedBySPID;
  const parsedVersion = parseVersionDates(version);
  return {
    application: detail.programName,
    get children() {
      return version.details.filter((x) => x.blockedBySPID === detail.spid).map((x) => mapDetailRow(group, version, x));
    },
    database: detail.databaseName,
    detail,
    duration:
      isRoot && parsedVersion.endTime
        ? DateTime.fromJSDate(parsedVersion.endTime).diff(DateTime.fromJSDate(parsedVersion.startTime))
        : null,
    group,
    host: detail.hostName,
    key: `${group.id}_${detail.spid}`,
    login: detail.loginName,
    spid: detail.spid,
    startTime: isRoot ? parsedVersion.startTime : null,
    statement: detail.commandText,
    status: getBlockStatus(group, version, detail),
    version: parsedVersion,
    versionNumber: isRoot ? version.versionNumber : null,
    versions: group.versions.map((v) => parseVersionDates(v)),
    waitResource: detail.waitResource,
    waitTime: detail.waitTime,
    waitType: detail.waitText,
  };
}

const BlockingDetailGrid: React.FunctionComponent<IBlockingDetailGridProps> = ({
  className,
  eventSourceConnectionId,
  onChangeSelection,
  selection,
  visibleDates,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const { dateRange, setDateRange } = useDateContext();
  const [selectedVersions, setSelectedVersions] = React.useState<Record<string, number>>({});
  const [pageInfo, setPageInfo] = React.useState<{ pageIndex: number; pageSize: number }>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { blockingFilter, filterApplied, setBlockingFilter } = useBlockingContext();

  React.useEffect(() => {
    setPageInfo({
      pageIndex: 0,
      pageSize: 10,
    });
  }, [dateRange]);

  const { loading, error, data } = useQuery<IBlockChainGraphResponse, IBlockChainVars>(GET_BLOCKING_CHAINS, {
    context: {
      fetchOptions: {
        signal: "",
      },
    },
    fetchPolicy: "no-cache",
    variables: {
      bcr: {
        endDate: visibleDates.to.toISOString(),
        eventSourceConnectionId,
        filters: filterApplied
          ? {
              applications: blockingFilter.selectedApplications ?? undefined,
              databaseNames: blockingFilter.selectedDatabases ?? undefined,
              hosts: blockingFilter.selectedHosts ?? undefined,
              waitResources: blockingFilter.selectedResource ?? undefined,
              waitTexts: blockingFilter.selectedResourceType ?? undefined,
            }
          : {},
        limit: MAX_RESULTS,
        offset: 0,
        startDate: visibleDates.from.toISOString(),
      },
    },
  });

  const rowData = React.useMemo<readonly IBlockingDetailGridRow[]>(() => {
    return (data?.blocking?.chain?.items ?? []).reduce<IBlockingDetailGridRow[]>((rows, group) => {
      if (group.versions.length > 0) {
        const latest = group.versions.sort((a, b) => b.versionNumber - a.versionNumber)[0];
        const version =
          group.id in selectedVersions
            ? group.versions.find((version) => version.versionNumber === selectedVersions[group.id]) ?? latest
            : latest;
        const root = version.details.find((x) => !x.blockedBySPID) ?? version.details[0];
        const row = mapDetailRow(group, version, root);
        rows.push(row);
      }
      return rows;
    }, []);
  }, [data, selectedVersions]);

  const selectionRef = React.useRef(selection);
  selectionRef.current = selection;

  function getApplicationName(row: IBlockingDetailGridRow): string {
    return intl.formatMessage({ defaultMessage: row.application ?? undefined, id: row.application || "(unknown)" });
  }

  return (
    <CardTable
      className={className}
      data-testid="BlockingDetailsGrid"
      title={
        <div className={classes.titleStyle}>
          <span id="headBlockersHeading">{intl.formatMessage({ id: "headBlockers" })}</span>
          <span aria-hidden className={classes.legend} data-testid="headBlockersLegend">
            <BlockingStatusIcon className={classes.legendIcon} status="completed" />
            <Typography color="inherit" component="span" variant="caption">
              {intl.formatMessage({ id: "completed" })}
            </Typography>
            <BlockingStatusIcon className={classes.legendIcon} status="running" />
            <Typography color="inherit" component="span" variant="caption">
              {intl.formatMessage({ id: "running" })}
            </Typography>
            <BlockingStatusIcon className={classes.legendIcon} status="blocked" />
            <Typography color="inherit" component="span" variant="caption">
              {intl.formatMessage({ id: "blocked" })}
            </Typography>
          </span>

          <span className={classes.chipBox} id="filterChips">
            <ChartFilterChip
              filterType="applications"
              filters={blockingFilter.selectedApplications}
              id="applicationsChip"
              onRemove={() => setBlockingFilter({ selectedApplications: null })}
            />
            <ChartFilterChip
              filterType="databases"
              filters={blockingFilter.selectedDatabases}
              id="databaseChip"
              onRemove={() => setBlockingFilter({ selectedDatabases: null })}
            />
            <ChartFilterChip
              filterType="hosts"
              filters={blockingFilter.selectedHosts}
              id="hostsChip"
              onRemove={() => setBlockingFilter({ selectedHosts: null })}
            />
            <ChartFilterChip
              filterType="resources"
              filters={blockingFilter.selectedResource}
              id="resourcesChip"
              onRemove={() => setBlockingFilter({ selectedResource: null })}
            />
            <ChartFilterChip
              filterType="waitResourceTypes"
              filters={blockingFilter.selectedResourceType}
              id="resourceTypeChip"
              onRemove={() => setBlockingFilter({ selectedResourceType: null })}
            />
          </span>
        </div>
      }
    >
      <MuiTreeTable<IBlockingDetailGridRow>
        childDataSelector={(x) => x.children}
        data={rowData}
        defaultPageSize={10}
        defaultSort={[{ direction: "desc", id: "startTime" }]}
        error={error}
        isLoading={loading}
        keySelector={(x) => x.key}
        onChangeExpandedRows={() => setDateRange(dateRange)}
        onChangePage={(pi, ps) => {
          setDateRange(dateRange);
          setPageInfo({ pageIndex: pi, pageSize: ps });
        }}
        onDataReady={(d) => {
          if (!d.some((x) => selectionRef.current?.key === x.key)) {
            if (d.length > 0) {
              onChangeSelection({
                key: d[0].key,
                queryText: d[0].statement,
                spid: d[0].spid,
              });
            } else {
              onChangeSelection(null);
            }
          }
        }}
        pageIndex={pageInfo.pageIndex}
        pageSize={pageInfo.pageSize}
        pageVariant="rolling"
        tableProps={{ "aria-labelledby": "headBlockersHeading" }}
        tableRowProps={(d) => {
          return d
            ? {
                "aria-selected": selection?.key === d.key,
                onClick: () => {
                  onChangeSelection({
                    key: d.key,
                    queryText: d.statement,
                    spid: d.spid,
                  });
                },
              }
            : {};
        }}
      >
        <TreeToggleColumn<IBlockingDetailGridRow>
          buttonTitle={(x, e) =>
            e
              ? intl.formatMessage({ id: "collapseSpid" }, { spid: x.spid })
              : intl.formatMessage({ id: "expandSpid" }, { spid: x.spid })
          }
          cellProps={() => ({ className: classes.noWrap })}
          field="spid"
          header={intl.formatMessage({ id: "spid" })}
          renderCell={(x) => (
            <>
              <BlockingStatusIcon color="action" status={x.status} style={{ marginTop: -5 }} />
              <span className={classes.spid}>{x.spid}</span>
            </>
          )}
        />
        <DateColumn<IBlockingDetailGridRow>
          cellProps={() => ({ className: classes.noWrap })}
          field="startTime"
          format={{
            day: "numeric",
            hour: "2-digit",
            minute: "numeric",
            month: "2-digit",
            year: "numeric",
          }}
          header={intl.formatMessage({ id: "startTime" })}
        />
        <TimespanColumn<IBlockingDetailGridRow> field="duration" header={intl.formatMessage({ id: "duration" })} />
        <TextEllipsisColumn<IBlockingDetailGridRow>
          field="statement"
          header={intl.formatMessage({ id: "statement" })}
          title={(x) => x.statement}
        />
        <TimespanColumn<IBlockingDetailGridRow> field="waitTime" header={intl.formatMessage({ id: "waitTime" })} />
        <Column<IBlockingDetailGridRow> field="waitType" header={intl.formatMessage({ id: "waitType" })} />
        <TextEllipsisColumn<IBlockingDetailGridRow>
          field="waitResource"
          header={intl.formatMessage({ id: "waitResource" })}
          title={(x) => x.waitResource}
        />
        <TextEllipsisColumn<IBlockingDetailGridRow>
          field="host"
          header={intl.formatMessage({ id: "hostName" })}
          title={(x) => x.host}
        />
        <TextEllipsisColumn<IBlockingDetailGridRow>
          field="application"
          header={intl.formatMessage({ id: "application" })}
          renderCell={getApplicationName}
          title={getApplicationName}
        />
        <Column<IBlockingDetailGridRow> field="database" header={intl.formatMessage({ id: "database" })} />
        <Column<IBlockingDetailGridRow> field="login" header={intl.formatMessage({ id: "login" })} />
        <Column<IBlockingDetailGridRow>
          header={intl.formatMessage({ id: "version" })}
          id="version"
          renderCell={(x) =>
            x.versionNumber && (
              <VersionSelector
                onChange={(v) => setSelectedVersions((prev) => ({ ...prev, [x.group.id]: v.versionNumber }))}
                selectedVersionNumber={selectedVersions[x.key] ?? x.versionNumber}
                versions={x.versions}
              />
            )
          }
        />
      </MuiTreeTable>
    </CardTable>
  );
};

export default BlockingDetailGrid;
