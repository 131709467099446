import { useQuery } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core/styles";
import { CardTable } from "@sentryone/material-ui";
import * as React from "react";
import { FormatNumberOptions, FormattedMessage, useIntl } from "react-intl";
import { uid } from "react-uid";
import CardFilterChip from "../../../../components/CardFilterChip";
import { useDateContext } from "../../../../components/DateContext";
import { ITopologyItemEventSourceConnection } from "../../../../components/TopologyContext";
import * as GET_SESSION_USAGE from "./tempDbQuery.graphql";
import * as GET_FILTER_VALUE from "./tempDbColumFilterQuery.graphql";
import { IDateRange } from "./types";
import { DataTable, DataTablePFSEvent } from "primereact/datatable";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { ILazyParams, handleSortOrder } from "../../../TopSql/components/TraceEventsGrid/TraceEventsGrid";
import { formatTimeSpan } from "../../../../utilities/Formatters";
import { GRID_PAGINATIONROW_OPTIONS } from "../../../../utilities/PrimeReactUtility";
import { ON_PAGE, ON_SORT, ON_FILTER, CLEAR_FILTER } from "../../../../contexts/topSqlContext/types";
import { MultiSelect } from "primereact/multiselect";
import { styles } from "../../../../components/ThemeProvider/grids";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";

export interface ITempDbSessionsGridProps {
  activeRange: IDateRange | null;
  className?: string;
  onActiveRangeChange: (value: IDateRange | null) => void;
  target: ITopologyItemEventSourceConnection;
}

interface ISessionUsageRow {
  /** Unit is converted to MB, but named KB for sorting identification. */
  activeTempdbInternalKB: number | null;
  /** Unit is converted to MB, but named KB for sorting identification. */
  activeTempdbKB: number | null;
  /** Unit is converted to MB, but named KB for sorting identification. */
  activeTempdbUserKB: number | null;
  hostName: string | null;
  id: string;
  loginName: string;
  /** Unit is converted to MB, but named KB for sorting identification. */
  maxGrantedMemoryKB: number | null;
  programName: string | null;
  sessionCount: number;
  totalCpuTime: number | null;
  totalElapsedTime: string | null;
  totalLogicalReads: number | null;
  totalPhysicalWrites: number | null;
  /** Unit is converted to MB, but named KB for sorting identification. */
  totalTempdbInternalKB: number | null;
  /** Unit is converted to MB, but named KB for sorting identification. */
  totalTempdbKB: number | null;
  /** Unit is converted to MB, but named KB for sorting identification. */
  totalTempdbUserKB: number | null;
}

const megabyteFormat: FormatNumberOptions = {
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
  style: "unit",
  unit: "megabyte",
};

const useStyles = makeStyles({
  ...styles,
  clearBtn: {
    height: "36px",
  },
  filterDropDown: {
    width: "17em",
  },
});

function reducer(state: ILazyParams, action: { type: string; event?: DataTablePFSEvent }): ILazyParams {
  switch (action.type) {
    case ON_PAGE:
      return {
        ...state,
        first: action.event?.first ?? state.first,
        page: action.event?.page ?? state.page,
        rows: action.event?.rows ?? state.rows,
      };
    case ON_SORT:
      return {
        ...state,
        sortField: action.event?.sortField ?? state.sortField,
        sortOrder: action.event && handleSortOrder(state, action.event),
      };
    case ON_FILTER:
      return {
        ...state,
        filters: action.event?.filters,
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filters: {
          hostName: {
            constraints: [
              {
                matchMode: FilterMatchMode.STARTS_WITH,
                value: null,
              },
            ],
            operator: "and",
          },
          loginName: {
            constraints: [
              {
                matchMode: FilterMatchMode.EQUALS,
                value: null,
              },
            ],
            operator: "and",
          },
          programName: {
            constraints: [
              {
                matchMode: FilterMatchMode.STARTS_WITH,
                value: null,
              },
            ],
            operator: "and",
          },
        },
        first: 0,
        page: 1,
        sortField: "totalTempdbKB",
        sortOrder: -1,
      };
    default:
      return {
        first: 0,
        page: 1,
        rows: GRID_PAGINATIONROW_OPTIONS[0],
        sortField: "totalTempdbKB",
        sortOrder: -1,
      };
  }
}

const TempDbSessionsGrid: React.FC<ITempDbSessionsGridProps> = ({
  activeRange,
  className,
  onActiveRangeChange,
  target,
}) => {
  const intl = useIntl();
  const { dateRange } = useDateContext();
  const classes = useStyles();
  const [isClearBtnDisabled, setIsClearBtnDisabled] = React.useState(true);

  const [state, dispatch] = React.useReducer(reducer, {
    filters: {
      hostName: {
        constraints: [
          {
            matchMode: FilterMatchMode.STARTS_WITH,
            value: null,
          },
        ],
        operator: "and",
      },
      loginName: {
        constraints: [
          {
            matchMode: FilterMatchMode.EQUALS,
            value: null,
          },
        ],
        operator: "and",
      },
      programName: {
        constraints: [
          {
            matchMode: FilterMatchMode.STARTS_WITH,
            value: null,
          },
        ],
        operator: "and",
      },
    },
    first: 0,
    page: 1,
    rows: GRID_PAGINATIONROW_OPTIONS[0],
    sortField: "totalTempdbKB",
    sortOrder: -1,
  });

  const {
    data: filterData = {
      tempDbFilterDataList: { tempDbFilterDataList: { hostName: [], loginName: [], programName: [] } },
    },
    error: filterError,
    loading: filterLoading,
  } = useQuery<{
    tempDbFilterDataList: { tempDbFilterDataList: { hostName: string[]; loginName: string[]; programName: string[] } };
  }>(GET_FILTER_VALUE, {
    fetchPolicy: "no-cache",
    variables: {
      eventSourceConnectionID: target.itemId,
    },
  });

  const tempDBFilterDropDownData = React.useMemo<any | null>(() => {
    return {
      ...filterData,
      topSqlFilterDataList: {
        ...filterData.tempDbFilterDataList,
        hostName:
          filterData.tempDbFilterDataList &&
          filterData.tempDbFilterDataList.tempDbFilterDataList.hostName.reduce<
            Array<{ id: string | number; name: string }>
          >(function (result, x) {
            if (x != "") result.push({ id: x, name: x });
            return result;
          }, []),
        loginName:
          filterData.tempDbFilterDataList &&
          filterData.tempDbFilterDataList.tempDbFilterDataList.loginName.reduce<
            Array<{ id: string | number; name: string }>
          >(function (result, x) {
            if (x != "") result.push({ id: x, name: x });
            return result;
          }, []),
        programName:
          filterData.tempDbFilterDataList &&
          filterData.tempDbFilterDataList.tempDbFilterDataList.programName.reduce<
            Array<{ id: string | number; name: string }>
          >(function (result, x) {
            if (x != "") result.push({ id: x, name: x });
            return result;
          }, []),
      },
    };
  }, [filterData]);

  const { data = { tempDb: { getSessionUsage: { items: [], rowCount: 0 } } }, error, loading } = useQuery<{
    tempDb: { getSessionUsage: { items: ISessionUsageRow[]; rowCount: number } };
  }>(GET_SESSION_USAGE, {
    fetchPolicy: "no-cache",
    variables: {
      dr: {
        applicationNameFilter: state.filters.programName.constraints[0].value ?? [],
        endDate: dateRange.to.toISOString(),
        eventSourceConnectionId: target.itemId,
        hostNameFilter: state.filters.hostName.constraints[0].value ?? [],
        limit: state.rows,
        loginNameFilter: state.filters.loginName.constraints[0].value ?? [],
        offset: state.first,
        sortIsDescending: state.sortOrder && state.sortOrder > 0 ? false : true,
        sortProperty: state.sortField,
        startDate: dateRange.from.toISOString(),
      },
    },
  });

  const hostFilterTemplate = (options: ColumnFilterElementTemplateOptions): JSX.Element => {
    return (
      <MultiSelect
        className={classes.filterDropDown}
        data-testid="hostName-options"
        onChange={(e) => options.filterCallback(e.value, options.index)}
        options={tempDBFilterDropDownData.topSqlFilterDataList.hostName?.map((action: any) => action.name)}
        placeholder="Select Host Name"
        showClear
        value={options.value}
      />
    );
  };

  const applicationFilterTemplate = (options: ColumnFilterElementTemplateOptions): JSX.Element => {
    return (
      <MultiSelect
        className={classes.filterDropDown}
        data-testid="applicationName-options"
        onChange={(e) => options.filterCallback(e.value, options.index)}
        options={tempDBFilterDropDownData.topSqlFilterDataList.programName?.map((action: any) => action.name)}
        placeholder="Select Application Name"
        showClear
        value={options.value}
      />
    );
  };

  const loginFilterTemplate = (options: ColumnFilterElementTemplateOptions): JSX.Element => {
    return (
      <MultiSelect
        className={classes.filterDropDown}
        data-testid="loginName-options"
        onChange={(e) => options.filterCallback(e.value, options.index)}
        options={tempDBFilterDropDownData.topSqlFilterDataList.loginName?.map((action: any) => action.name)}
        placeholder="Select Login"
        value={options.value}
      />
    );
  };

  const onPage = (event: DataTablePFSEvent): void => {
    dispatch({ event, type: ON_PAGE });
  };
  const onSort = (event: DataTablePFSEvent): void => {
    dispatch({ event, type: ON_SORT });
  };

  const onFilter = (event: DataTablePFSEvent): void => {
    setIsClearBtnDisabled(false);
    dispatch({ event, type: ON_FILTER });
  };

  const clearFilter = (): void => {
    setIsClearBtnDisabled(true);
    dispatch({ type: CLEAR_FILTER });
  };

  const renderHeader = (): JSX.Element => {
    return (
      <div className="flex justify-content-between">
        <Button
          className={`p-button-contained ${classes.clearBtn}`}
          data-testid="clear-all-btn"
          disabled={isClearBtnDisabled}
          icon="pi pi-filter-slash"
          label="Clear"
          onClick={clearFilter}
          type="button"
        />
      </div>
    );
  };
  const header = renderHeader();

  const convertKBtoMB = (x: number | null): number | null => (typeof x === "number" ? x / 1000 : null);

  const formattedData = React.useMemo(
    () =>
      data.tempDb.getSessionUsage.items.map<ISessionUsageRow>((x: ISessionUsageRow): any => {
        return {
          activeTempdbInternalKB: intl.formatNumber(convertKBtoMB(x.activeTempdbInternalKB) as number, megabyteFormat),
          activeTempdbKB: intl.formatNumber(convertKBtoMB(x.activeTempdbKB) as number, megabyteFormat),
          activeTempdbUserKB: intl.formatNumber(convertKBtoMB(x.activeTempdbUserKB) as number, megabyteFormat),
          hostName: x.hostName,
          id: uid(x).toString(),
          loginName: x.loginName,
          maxGrantedMemoryKB: intl.formatNumber(convertKBtoMB(x.maxGrantedMemoryKB) as number, megabyteFormat),
          programName: x.programName,
          sessionCount: x.sessionCount,
          totalCpuTime: x.totalCpuTime && x.totalCpuTime.toLocaleString(),
          totalElapsedTime: x.totalElapsedTime,
          totalLogicalReads: x.totalLogicalReads && x.totalLogicalReads.toLocaleString(),
          totalPhysicalWrites: x.totalPhysicalWrites && x.totalPhysicalWrites.toLocaleString(),
          totalTempdbInternalKB: intl.formatNumber(convertKBtoMB(x.totalTempdbInternalKB) as number, megabyteFormat),
          totalTempdbKB: intl.formatNumber(convertKBtoMB(x.totalTempdbKB) as number, megabyteFormat),
          totalTempdbUserKB: intl.formatNumber(convertKBtoMB(x.totalTempdbUserKB) as number, megabyteFormat),
        };
      }),
    [data, intl],
  );

  data.tempDb.getSessionUsage.items = formattedData;

  return (
    <CardTable
      actions={
        <>{activeRange && <CardFilterChip onRemove={() => onActiveRangeChange(null)} title="" value={activeRange} />}</>
      }
      className={className}
      data-testid="tempDbSessionsGrid"
      title={intl.formatMessage({ id: "tempDbSessionUsage" })}
    >
      <DataTable
        columnResizeMode="expand"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
        dataKey="textData"
        filterDisplay="menu"
        filters={state.filters}
        first={state.first}
        header={header}
        lazy
        loading={loading || filterLoading}
        onFilter={onFilter}
        onPage={onPage}
        onSort={onSort}
        paginator
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        resizableColumns
        responsiveLayout="scroll"
        rows={state.rows}
        rowsPerPageOptions={GRID_PAGINATIONROW_OPTIONS}
        selectionMode="single"
        size="small"
        sortField={state.sortField}
        sortOrder={state.sortOrder}
        totalRecords={data.tempDb.getSessionUsage.rowCount}
        value={[...data.tempDb.getSessionUsage.items]}
      >
        <Column
          field="hostName"
          filter
          filterElement={hostFilterTemplate}
          header={<FormattedMessage id="hostName" />}
          showAddButton={false}
          showFilterMatchModes={false}
          showFilterOperator={false}
          sortable
        />
        <Column
          field="programName"
          filter
          filterElement={applicationFilterTemplate}
          header={<FormattedMessage id="application" />}
          showAddButton={false}
          showFilterMatchModes={false}
          showFilterOperator={false}
          sortable
        />
        <Column
          field="loginName"
          filter
          filterElement={loginFilterTemplate}
          header={<FormattedMessage id="login" />}
          showAddButton={false}
          showFilterMatchModes={false}
          showFilterOperator={false}
          sortable
        />
        <Column field="totalTempdbKB" header={<FormattedMessage id="totalTempDb" />} sortable />
        <Column field="activeTempdbKB" header={<FormattedMessage id="activeTempDb" />} sortable />
        <Column field="maxGrantedMemoryKB" header={<FormattedMessage id="maxGrantedMemory" />} sortable />
        <Column
          body={(alert) => formatTimeSpan(alert.totalElapsedTime)}
          field="totalElapsedTime"
          header={<FormattedMessage id="totalTime" />}
          sortable
        />
        <Column field="totalCpuTime" header={<FormattedMessage id="totalCPU" />} sortable />
        <Column field="totalLogicalReads" header={<FormattedMessage id="totalLogicalReads" />} sortable />
        <Column field="totalPhysicalWrites" header={<FormattedMessage id="totalPhysicalWrites" />} sortable />
        <Column field="totalTempdbUserKB" header={<FormattedMessage id="totalTempDbUser" />} sortable />
        <Column field="totalTempdbInternalKB" header={<FormattedMessage id="totalTempDbInternal" />} sortable />
        <Column field="activeTempdbUserKB" header={<FormattedMessage id="activeTempDbUser" />} sortable />
        <Column field="activeTempdbInternalKB" header={<FormattedMessage id="activeTempDbInternal" />} sortable />
        <Column field="sessionCount" header={<FormattedMessage id="sessionCount" />} sortable />
      </DataTable>
    </CardTable>
  );
};

export default TempDbSessionsGrid;
