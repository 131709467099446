import { makeStyles } from "@material-ui/core/styles";
import { Column, ColumnProps } from "@sentryone/react-datatable";
import classNames from "classnames";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  noWrap: {
    maxWidth: theme.spacing() * 46,
    minWidth: 0,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

type ITextEllipsisColumnProps<T> = ColumnProps<T> & {
  title: (dataItem: T, rowIndex: number) => string | null | undefined;
}

export default function TextEllipsisColumn<T>({
  title,
  ...props
}: ITextEllipsisColumnProps<T>): React.ReactElement | null {
  const classes = useStyles({});
  return (
    <Column<T>
      {...props}
      cellProps={(dataItem, rowIndex) => {
        const cellProps = typeof props.cellProps === "function"
          ? props.cellProps(dataItem, rowIndex)
          : {};
        return {
          ...cellProps,
          className: classNames(classes.noWrap, cellProps.className),
          title: title(dataItem, rowIndex)?.replace(/\t/g, "  "),
        };
      }}
    />
  )
}
