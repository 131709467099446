import { useApolloClient, useQuery } from "@apollo/react-hooks";
import { MoRef } from "../../../utilities/TopologyUtility";
import type {
  IPrincipalSelection,
  IRole,
  IRoleAccessResponse,
  ITargetDeviceResponse,
  ITargetGroupResponse,
  ITargetGroupUnionResponse,
  ITargetParentResponse,
  ITargetUnionResponse,
  IUser,
  IUserGroup,
} from "../types";
import * as GET_PRINCIPAL_LIST from "./getPrincipalList.graphql";
import * as GET_USER_ACCESS from "./getUserFeatureAccess.graphql";
import * as GET_USER_GROUP_ACCESS from "./getUserGroupFeatureAccess.graphql";
import * as GET_AVAILABLE_ROLES from "./getAvailableRoles.graphql";
import * as ADD_ROLE from "./addRoleToPrincipal.graphql";
import * as REMOVE_ROLE from "./removeRoleFromPrincipal.graphql";
import * as ADD_USER from "./addUser.graphql";
import * as UPDATE_USER from "./updateUser.graphql";
import * as DELETE_USER from "./deleteUser.graphql";
import * as ADD_USER_GROUP from "./addUserGroup.graphql";
import * as UPDATE_USER_GROUP from "./updateUserGroup.graphql";
import * as DELETE_USER_GROUP from "./deleteUserGroup.graphql";
import { TopologyDeviceType } from "../../../components/TopologyContext";

// TYPES //

export type IAsyncResult<T> =
  | { data: undefined; error: Error; isLoading: false; state: "error" }
  | { data: undefined; error: null; isLoading: true; state: "loading" }
  | { data: T; error: null; isLoading: false; state: "success" };

export interface IUseAllPrincipalsResponse {
  users: Map<string, IUser>;
  userGroups: Map<string, IUserGroup>;
}

export interface ITopologyItemsQueryResponse {
  name: string;
  moRef: string;
  objectId: string;
  children: Array<{
    moRef: string;
  }>;
}

export interface ITopologyItemsDeviceQueryResponse extends ITopologyItemsQueryResponse {
  __typename: "Device";
  deviceType: string;
}

export interface ITopologyItemsEventSourceConnectionQueryResponse
  extends Omit<ITopologyItemsQueryResponse, "children"> {
  __typename: "EventSourceConnection";
  eventSourceConnectionType: string;
}

export interface ITopologyItemsGroupQueryResponse extends ITopologyItemsQueryResponse {
  __typename: "Group";
}

export type ITopologyItemsUnionQueryResponse =
  | ITopologyItemsDeviceQueryResponse
  | ITopologyItemsGroupQueryResponse
  | ITopologyItemsEventSourceConnectionQueryResponse;

export interface IRoleAccessQueryResponse {
  role: IRole;
  topologyItem: Omit<ITopologyItemsQueryResponse, "children">;
}

interface IUsePrincipalQueryResponse {
  featureSecurity: {
    principal: {
      roleAccess: Array<IRoleAccessQueryResponse>;
      topologyItems: Array<ITopologyItemsUnionQueryResponse>;
    };
  };
}

export interface IUsePrincipalResponse {
  roleAccess: Array<IRoleAccessResponse>;
  targets: Array<ITargetUnionResponse>;
  targetGroups: Array<ITargetGroupUnionResponse>;
}

export interface IUsePermissionActionParams {
  principalId: string;
  roleId: string;
  objectId: string;
}

export interface IUsePermissionActionResponse {
  isSuccess: boolean;
}
export interface IUsePermissionActions {
  addRole: (params: IUsePermissionActionParams) => Promise<IUsePermissionActionResponse>;
  removeRole: (params: IUsePermissionActionParams) => Promise<IUsePermissionActionResponse>;
}

export interface IUsePrincipalActions {
  addUser: (params: IUseAddUserActionParams) => Promise<IUseUserActionResponse>;
  updateUser: (params: IUseUpdateUserActionParams) => Promise<IUseUserActionResponse>;
  deleteUser: (params: IUseDeleteUserActionParams) => Promise<IUseUserActionResponse>;
  addUserGroup: (params: IUseAddUserGroupActionParams) => Promise<IUseUserActionResponse>;
  updateUserGroup: (params: IUseUpdateUserGroupActionParams) => Promise<IUseUserActionResponse>;
  deleteUserGroup: (params: IUseDeleteUserGroupActionParams) => Promise<IUseUserActionResponse>;
}

export interface IUseAddUserActionParams {
  userGroupIds: string[];
  email: string;
  firstName: string;
  lastName: string;
}

export interface IUseUpdateUserActionParams {
  email: string;
  firstName: string;
  lastName: string;
  objectId: string;
  userGroupIds: string[];
}

export interface IUseDeleteUserActionParams {
  objectId: string;
}

export interface IUseAddUserGroupActionParams {
  groupName: string;
  userIds: string[] | null;
  // description: string; may add back in future
}

export interface IUseUpdateUserGroupActionParams {
  groupName: string;
  objectId: string;
  userIds: string[] | null;
  // description: string; may add back in future
}

export interface IUseDeleteUserGroupActionParams {
  objectId: string;
}

export interface IUseUserActionResponse {
  isSuccess: boolean;
}

// QUERIES //

export function useAllPrincipals(): IAsyncResult<IUseAllPrincipalsResponse> {
  const { data = { featureSecurity: { userGroups: [], users: [] } }, error, loading } = useQuery<{
    featureSecurity: {
      users: readonly IUser[];
      userGroups: readonly IUserGroup[];
    };
  }>(GET_PRINCIPAL_LIST);
  if (error) {
    return {
      data: undefined,
      error: error,
      isLoading: false,
      state: "error",
    };
  } else if (loading) {
    return {
      data: undefined,
      error: null,
      isLoading: true,
      state: "loading",
    };
  } else {
    return {
      data: {
        userGroups: new Map<string, IUserGroup>(
          data.featureSecurity.userGroups.map((userGroup) => [userGroup.id, userGroup]),
        ),
        users: new Map<string, IUser>(data.featureSecurity.users.map((user) => [user.id, user])),
      },
      error: null,
      isLoading: false,
      state: "success",
    };
  }
}

export function usePrincipal(principal: IPrincipalSelection): IAsyncResult<IUsePrincipalResponse | null> {
  const {
    data = {
      featureSecurity: {
        principal: {
          roleAccess: [],
          topologyItems: [],
        },
      },
    },
    error,
    loading,
  } = useQuery<IUsePrincipalQueryResponse, { id: string }>(
    principal.category === "users" ? GET_USER_ACCESS : GET_USER_GROUP_ACCESS,
    {
      variables: { id: principal.id },
    },
  );

  if (error) {
    return {
      data: undefined,
      error: error,
      isLoading: false,
      state: "error",
    };
  } else if (loading) {
    return {
      data: undefined,
      error: null,
      isLoading: true,
      state: "loading",
    };
  } else {
    const allTopologyItems: ITargetUnionResponse[] = data.featureSecurity.principal.topologyItems.map(
      (t: ITopologyItemsUnionQueryResponse) => {
        switch (t.__typename) {
          case "Device":
            return {
              //vmware hosts will have their guests as children, but we do not want to represent that relationship in the heirarchy (product AC)
              children: t.deviceType !== TopologyDeviceType.VMWare ? t.children.map((c) => MoRef.parse(c.moRef)) : [],
              deviceType: t.deviceType,
              moRef: MoRef.parse(t.moRef.toString()),
              name: t.name,
              objectId: t.objectId,
            };
          case "EventSourceConnection":
            return {
              eventSourceConnectionType: t.eventSourceConnectionType,
              moRef: MoRef.parse(t.moRef.toString()),
              name: t.name,
              objectId: t.objectId,
            };
          case "Group":
            return {
              children: t.children.map((c) => MoRef.parse(c.moRef)),
              moRef: MoRef.parse(t.moRef.toString()),
              name: t.name,
              objectId: t.objectId,
            };
        }
      },
    );

    // get all the sites and groups with children
    const allTopologyGroups: ITargetUnionResponse[] = allTopologyItems.filter(
      (t) =>
        t.moRef.type === "group" ||
        t.moRef.type === "site" ||
        (t.moRef.type === "device" &&
          (t as ITargetDeviceResponse).children &&
          (t as ITargetDeviceResponse).children.length > 0),
    );

    const targetGroups: ITargetParentResponse[] = allTopologyGroups.map((topologyItem) => {
      if (topologyItem.moRef.type === "group" || topologyItem.moRef.type === "site") {
        return {
          ...topologyItem,
        } as ITargetGroupResponse;
      } else {
        return {
          ...topologyItem,
          deviceType: (topologyItem as ITargetDeviceResponse).deviceType,
        } as ITargetDeviceResponse;
      }
    });

    const targets: ITargetUnionResponse[] = allTopologyItems.filter(
      (topologyItem) =>
        topologyItem.moRef.type === "connection" ||
        (topologyItem.moRef.type === "device" &&
          (topologyItem as ITargetDeviceResponse).children &&
          (topologyItem as ITargetDeviceResponse).children.length === 0),
    );

    const roleAccess = data.featureSecurity.principal.roleAccess.map((access) => ({
      ...access,
      topologyItem: { ...access.topologyItem, moRef: MoRef.parse(access.topologyItem.moRef) },
    }));

    return {
      data: {
        roleAccess,
        targetGroups,
        targets,
      },
      error: null,
      isLoading: false,
      state: "success",
    };
  }
}

export function useAvailableRoles(): IAsyncResult<Map<string, string>> {
  const { data = { featureSecurity: { roles: [] } }, error, loading } = useQuery<{
    featureSecurity: {
      roles: Array<IRole>;
    };
  }>(GET_AVAILABLE_ROLES);
  if (error) {
    return {
      data: undefined,
      error: error,
      isLoading: false,
      state: "error",
    };
  } else if (loading) {
    return {
      data: undefined,
      error: null,
      isLoading: true,
      state: "loading",
    };
  } else {
    return {
      data: new Map<string, string>(data.featureSecurity.roles.map((role) => [role.id, role.name])),
      error: null,
      isLoading: false,
      state: "success",
    };
  }
}

// MUTATIONS //

export function usePermissionActions(principalCategory: "users" | "groups"): IUsePermissionActions {
  const apolloClient = useApolloClient();
  return {
    async addRole({ principalId, roleId, objectId }) {
      await apolloClient.mutate<unknown, { principalId: string; roleId: string; objectId: string }>({
        awaitRefetchQueries: true,
        mutation: ADD_ROLE,
        refetchQueries: [
          {
            query: principalCategory === "users" ? GET_USER_ACCESS : GET_USER_GROUP_ACCESS,
            variables: { id: principalId },
          },
        ],
        variables: {
          objectId,
          principalId,
          roleId,
        },
      });
      return { isSuccess: true };
    },
    async removeRole({ principalId, roleId, objectId }) {
      await apolloClient.mutate<unknown, { objectId: string; principalId: string; roleId: string }>({
        awaitRefetchQueries: true,
        mutation: REMOVE_ROLE,
        refetchQueries: [
          {
            query: principalCategory === "users" ? GET_USER_ACCESS : GET_USER_GROUP_ACCESS,
            variables: { id: principalId },
          },
        ],
        variables: {
          objectId,
          principalId,
          roleId,
        },
      });
      return { isSuccess: true };
    },
  };
}

export function usePrincipalActions(): IUsePrincipalActions {
  const apolloClient = useApolloClient();
  return {
    async addUser({ firstName, lastName, email, userGroupIds }) {
      await apolloClient.mutate<
        unknown,
        { firstName: string; lastName: string; email: string; userGroupIds: string[] }
      >({
        awaitRefetchQueries: true,
        mutation: ADD_USER,
        refetchQueries: [{ query: GET_PRINCIPAL_LIST }],
        variables: {
          email,
          firstName,
          lastName,
          userGroupIds,
        },
      });
      return { isSuccess: true };
    },
    async addUserGroup({ userIds, groupName }) {
      await apolloClient.mutate<unknown, { userIds: string[] | null; groupName: string }>({
        awaitRefetchQueries: true,
        mutation: ADD_USER_GROUP,
        refetchQueries: [{ query: GET_PRINCIPAL_LIST }],
        variables: {
          groupName,
          userIds,
        },
      });
      return { isSuccess: true };
    },
    async deleteUser({ objectId }) {
      await apolloClient.mutate<unknown, { objectId: string }>({
        awaitRefetchQueries: true,
        mutation: DELETE_USER,
        refetchQueries: [{ query: GET_PRINCIPAL_LIST }],
        variables: {
          objectId,
        },
      });
      return { isSuccess: true };
    },
    async deleteUserGroup({ objectId }) {
      await apolloClient.mutate<unknown, { objectId: string }>({
        awaitRefetchQueries: true,
        mutation: DELETE_USER_GROUP,
        refetchQueries: [{ query: GET_PRINCIPAL_LIST }],
        variables: {
          objectId,
        },
      });
      return { isSuccess: true };
    },
    async updateUser({ objectId, firstName, lastName, email, userGroupIds }) {
      await apolloClient.mutate<
        unknown,
        { userGroupIds: string[]; email: string; firstName: string; lastName: string; objectId: string }
      >({
        awaitRefetchQueries: true,
        mutation: UPDATE_USER,
        refetchQueries: [{ query: GET_PRINCIPAL_LIST }],
        variables: {
          email,
          firstName,
          lastName,
          objectId,
          userGroupIds,
        },
      });
      return { isSuccess: true };
    },
    async updateUserGroup({ groupName, objectId, userIds }) {
      await apolloClient.mutate<unknown, { groupName: string; objectId: string; userIds: string[] | null }>({
        awaitRefetchQueries: true,
        mutation: UPDATE_USER_GROUP,
        refetchQueries: [{ query: GET_PRINCIPAL_LIST }],
        variables: {
          groupName,
          objectId,
          userIds,
        },
      });
      return { isSuccess: true };
    },
  };
}
