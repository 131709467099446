import { ITopology, ITopologyItem, TopologyObjectType } from "../components/TopologyContext";

export function getDevice(topologyItem: ITopologyItem | null | undefined, topology: ITopology): ITopologyItem | null {
  if (topologyItem) {
    switch (topologyItem.type) {
      case TopologyObjectType.device:
        return topologyItem;
      case TopologyObjectType.eventSourceConnection:
        return (
          (topologyItem.parentObjectId &&
            topology.findByObjectId(topologyItem.parentObjectId, TopologyObjectType.device)) ||
          null
        );
    }
  }
  return null;
}

export function getEventSourceConnection(topologyItem: ITopologyItem | null | undefined): ITopologyItem | null {
  return topologyItem && topologyItem.type === TopologyObjectType.eventSourceConnection ? topologyItem : null;
}

export function getRelatedEventSourceConnections(topologyItem: ITopologyItem, topology: ITopology): ITopologyItem[] {
  switch (topologyItem.type) {
    case TopologyObjectType.device:
      return topology.findByParentObjectId(topologyItem.objectId, TopologyObjectType.eventSourceConnection);
    case TopologyObjectType.eventSourceConnection:
      return topologyItem.parentObjectId
        ? topology.findByParentObjectId(topologyItem.parentObjectId, TopologyObjectType.eventSourceConnection)
        : [];
    default:
      return [];
  }
}

/**
 * Represents a managed object reference (MoRef).
 * A MoRef is a unique identifier for a topology item.
 */
export class MoRef {
  constructor(
    public readonly type: MoRefType, 
    public readonly id: string, 
  ) {}

  /**
   * Creates a `MoRef` from the specified topology item.
   * @param target The topology item to create a `MoRef` for.
   */
  public static fromTopologyItem(target: ITopologyItem): MoRef {
    switch (target.type) {
      case TopologyObjectType.device:
        return new MoRef("device", target.itemId.toString());
      case TopologyObjectType.eventSourceConnection:
        return new MoRef("connection", target.itemId.toString());
      case TopologyObjectType.group:
        return new MoRef("group", target.itemId.toString());
      case TopologyObjectType.site:
        return new MoRef("site", target.itemId.toString());
    }
  }

  /**
   * Creates a `MoRef` from the specified string value.
   * @param value The string value to parse.
   */
  public static parse(value: string): MoRef {
    const [type, id] = value.split("-");
    if (!type || !id) {
      throw new Error(`Invalid MoRef value: ${value}`);
    } else if (type !== "device" && type !== "connection" && type !== "group" && type !== "site") {
      throw new Error(`Invalid MoRef type: ${type}`);
    } else {
      return new MoRef(type, id);
    }
  }

  public equals(moRef: MoRef): boolean {
    return this.id === moRef.id && this.type === moRef.type;
  }

  public toString(): string {
    return `${this.type}-${this.id}`;
  }
}

export type MoRefType = "device" | "connection" | "group" | "site";
