import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import * as React from "react";
import { FormattedHTMLMessage } from "react-intl";
import LoadingIndicator from "../../../../../../components/LoadingIndicator";
import NoDataIndicator from "../../../../../../components/NoDataIndicator";
import { usePlanContext } from "../../../../../../contexts/planContext";
import DownloadSqlPlanButton from "../DownloadSqlPlanButton";
import PlanDiagramContent from "./PlanDiagramContent";

export interface IPlanDiagramProps {
  animate: boolean;
  className?: string;
  loading: boolean;
}

const useStyles = makeStyles((theme) => ({
  content: {
    flex: 1,
    maxWidth: "100%",
    overflow: "auto",
  },
  download: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(2),
    textAlign: "center",
  },
  downloadBtnNoPlan: {
    position: "relative",
  },
  downloadBtnWithPlan: {
    // can't make plan diagram content area a div with position of relative.
    // which means the button is positioned relative to the tab container.
    // these overrides compensate for the tabs and scrollbar inside the plan
    // diagram content area
    right: theme.spacing(3),
    top: theme.spacing(8),
  },
  loading: {
    alignSelf: "center",
    justifySelf: "center",
  },
}));

export const PlanDiagram: React.FunctionComponent<IPlanDiagramProps> = ({ animate, className, loading }) => {
  const classes = useStyles();
  const { loading: planLoading, model, rawXML } = usePlanContext();

  if (loading || planLoading) {
    return (
      <LoadingIndicator
        className={classNames(classes.content, classes.loading, className)}
        data-testid="loading-indicator"
        variant="generic"
      />
    );
  } else if (model) {
    // Statement's plan is available to display
    return (
      <>
        <DownloadSqlPlanButton className={classes.downloadBtnWithPlan} planXml={rawXML} />
        <PlanDiagramContent animate={animate} className={className} data={model} showColorScale />
      </>
    );
  } else if (rawXML) {
    // Statement was not matched, but full plan XML is available
    return (
      <div className={classNames(classes.download, className)}>
        <Typography variant="body1">
          <FormattedHTMLMessage id="planDidNotContainMatchingStatement" />
        </Typography>
        <DownloadSqlPlanButton className={classes.downloadBtnNoPlan} planXml={rawXML} />
      </div>
    );
  } else {
    // No plan XML
    return <NoDataIndicator className={className} />;
  }
};

export default PlanDiagram;
