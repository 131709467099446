import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { DataDisplay, Grouping, IQueryHistoryOptions, Metric, Mode } from "../types";
import QueryHistoryDropdown from "./QueryHistoryDropdown";

export interface IQueryHistoryOptionsProps {
  value: IQueryHistoryOptions;
  onChange: (value: IQueryHistoryOptions) => void;
}

const useStyles = makeStyles((theme) => ({
  dropdown: {
    flex: 1,
    margin: theme.spacing(),
  },
  dropdownContainer: {
    display: "flex",
  },
}));

const QueryHistoryOptions: React.FunctionComponent<IQueryHistoryOptionsProps> = ({ onChange, value }) => {
  const classes = useStyles({});
  const handleChange = (changes: Partial<IQueryHistoryOptions>):void => {
    onChange({
      ...value,
      ...changes,
    });
  };
  return (
    <div className={classes.dropdownContainer}>
      <QueryHistoryDropdown
        className={classes.dropdown}
        label={<FormattedMessage id="grouping" />}
        onChange={(e) => handleChange({ grouping: e.target.value as Grouping })}
        value={value.grouping}
      >
        <MenuItem value={Grouping.ungrouped}>
          <FormattedMessage id="none" />
        </MenuItem>
        <MenuItem value={Grouping.hour}>
          <FormattedMessage id="hour" />
        </MenuItem>
        <MenuItem value={Grouping.day}>
          <FormattedMessage id="day" />
        </MenuItem>
        <MenuItem value={Grouping.week}>
          <FormattedMessage id="week" />
        </MenuItem>
      </QueryHistoryDropdown>
      <QueryHistoryDropdown
        className={classes.dropdown}
        label={<FormattedMessage id="show" />}
        onChange={(e) => handleChange({ dataDisplay: e.target.value as DataDisplay })}
        value={value.dataDisplay}
      >
        <MenuItem value={DataDisplay.average}>
          <FormattedMessage id="actualAverage" />
        </MenuItem>
        <MenuItem value={DataDisplay.totals}>
          <FormattedMessage id="totals" />
        </MenuItem>
      </QueryHistoryDropdown>
      <QueryHistoryDropdown
        className={classes.dropdown}
        label={<FormattedMessage id="metric" />}
        onChange={(e) => handleChange({ metric: e.target.value as Metric })}
        value={value.metric}
      >
        <MenuItem value={Metric.duration}>
          <FormattedMessage id="duration" />
        </MenuItem>
        <MenuItem value={Metric.cpu}>
          <FormattedMessage id="cpu" />
        </MenuItem>
        <MenuItem value={Metric.io}>
          <FormattedMessage id="io" />
        </MenuItem>
      </QueryHistoryDropdown>
      <QueryHistoryDropdown
        className={classes.dropdown}
        label={<FormattedMessage id="mode" />}
        onChange={(e) => handleChange({ mode: e.target.value as Mode })}
        value={value.mode}
      >
        <MenuItem value={Mode.procedure}>
          <FormattedMessage id="procedure" />
        </MenuItem>
        <MenuItem value={Mode.statement}>
          <FormattedMessage id="statement" />
        </MenuItem>
      </QueryHistoryDropdown>
    </div>
  );
};

export default QueryHistoryOptions;
