import LuxonUtils from "@date-io/luxon";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as Sentry from "@sentry/browser";
import { createBrowserHistory } from "history";
import { SnackbarProvider } from "notistack";
import * as React from "react";
import { render } from "react-dom";
import { Router } from "react-router-dom";
import ApolloEnvironment from "./components/ApolloEnvironment";
import { DateProvider } from "./components/DateContext";
import ErrorBoundary from "./components/ErrorBoundary";
import IntlProvider from "./components/IntlProvider";
import ThemeProvider from "./components/ThemeProvider";
import { TopologyProvider } from "./components/TopologyContext";
import { FeatureFlagProvider } from "./contexts/featuresContext";
import { SortContextProvider } from "./contexts/sortContext";
import { UserContextProvider } from "./contexts/userContext";
import Layout from "./features/Layout";
import "./styles";

if ((window as any).SENTRY_DSN) {
  Sentry.init({
    dsn: (window as any).SENTRY_DSN,
    environment: (window as any).SENTRY_ENVIRONMENT,
  });
}

const history = createBrowserHistory({ basename: "" });

const App: React.FunctionComponent = () => {
  return (
    <ThemeProvider>
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <IntlProvider>
          <ErrorBoundary variant="dialog">
            <SnackbarProvider dense maxSnack={3}>
              <Router history={history}>
                <UserContextProvider>
                  <ApolloEnvironment>
                    <FeatureFlagProvider>
                      <TopologyProvider>
                        <DateProvider>
                          <SortContextProvider>
                            <Layout />
                          </SortContextProvider>
                        </DateProvider>
                      </TopologyProvider>
                    </FeatureFlagProvider>
                  </ApolloEnvironment>
                </UserContextProvider>
              </Router>
            </SnackbarProvider>
          </ErrorBoundary>
        </IntlProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

render(<App />, document.getElementById("root"));
