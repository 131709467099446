import BaseService from "../BaseService";

export interface IUserInfo {
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly username: string;
  readonly role: "User" | "Admin";
  readonly tenants: readonly IUserTenant[];
  readonly userSignInUrl?: string;
}

export interface IUserTenant {
  readonly id: string;
  readonly name: string;
  readonly sentryOneClientUri?: string | null;
  readonly state: "initializing" | "ready" | "inactive" | "failedToConnect";
}

export interface IAccountService {
  fetchUserInfo(signal?: AbortSignal): Promise<IUserInfo>;
}

export default class AccountService extends BaseService implements IAccountService {
  constructor() {
    super("/account");
  }

  public fetchUserInfo(signal?: AbortSignal): Promise<IUserInfo> {
    return this.get<IUserInfo>("userInfo", {
      signal,
    });
  }
}
