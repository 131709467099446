import * as React from "react";
import MetricChart, { IMetricChartSeriesOptions, useMetrics } from "../../../../components/MetricChart";
import { IPerformanceAnalysisChartProps } from "../../types";
import { usePerformanceAnalysisContext } from "../../context";

const ametrics = Object.freeze<IMetricChartSeriesOptions>([
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#98FB98",
    instance: "Total",
    label: "ms/Read",
    metric: "vmware.host.disk.read.ms",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#20B2AA",
    instance: "Total",
    label: "ms/Write",
    metric: "vmware.host.disk.write.ms",
  }),
]);

const bmetrics = Object.freeze<IMetricChartSeriesOptions>([
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#FF8C00",
    instance: "Total",
    label: "Read",
    metric: "vmware.host.disk.read.throughput",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#FF4500",
    instance: "Total",
    label: "Write",
    metric: "vmware.host.disk.write.throughput",
  }),
]);

const DiskIOChart: React.FC<IPerformanceAnalysisChartProps> = ({ className }) => {

  const { contextMenuItems, dateRange, selectedRange, setSelectedRange, target } = usePerformanceAnalysisContext();
  const ametricData = useMetrics({
    dateRange,
    metrics: ametrics,
    target,
  });

  const bmetricData = useMetrics({
    dateRange,
    metrics: bmetrics,
    target,
  });

  return (
    <>
      <MetricChart
        className={className}
        contextMenuItems={contextMenuItems}
        dateRange={dateRange}
        metricData={ametricData}
        onSelectedRangeChange={setSelectedRange}
        selectedRange={selectedRange}
        target={target}
      />
      <MetricChart
        className={className}
        contextMenuItems={contextMenuItems}
        dateRange={dateRange}
        metricData={bmetricData}
        onSelectedRangeChange={setSelectedRange}
        selectedRange={selectedRange}
        target={target}
      />
    </>
  );
};

export default DiskIOChart;
