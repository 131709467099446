import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";

export interface INoteConfirmationDialogProps {
  handleClose: (confirmed: boolean) => void;
  open: boolean;
}

const useStyles = makeStyles((theme) => ({
  confirmationText: {
    marginBottom: `${theme.spacing(1)}px`,
  },
  principalNameText: {
    fontWeight: "bold",
    marginBottom: `${theme.spacing(4)}px`,
  },
  textContent: {
    paddingBottom: `${theme.spacing(1)}px`,
  },
}));

const NoteConfirmationDialog: React.FC<INoteConfirmationDialogProps> = ({ handleClose, open }) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle>{intl.formatMessage({ id: "deleteNote" })}</DialogTitle>
      <DialogContent className={classes.textContent}>
        <DialogContentText className={classes.confirmationText}>
          {intl.formatMessage({ id: "areYouSureDeleteNote" })}
        </DialogContentText>
        <DialogContentText className={classes.confirmationText}>
          {intl.formatMessage({ id: "thisActionCannotBeUndone" })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>{intl.formatMessage({ id: "cancel" })}</Button>
        <Button color="secondary" onClick={() => handleClose(true)} variant="contained">
          {intl.formatMessage({ id: "delete" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NoteConfirmationDialog;
