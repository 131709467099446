export type { ISelectedTraceEvent } from "../../../../contexts/topSqlContext";

export enum Grouping {
  ungrouped = "ungrouped",
  hour = "hour",
  day = "day",
  week = "week",
}

export interface IDateRange {
  from: Date;
  to: Date;
}

export interface IDateTimestampRange {
  from: number;
  to: number;
}

export interface IQueryHistoryOptions {
  dateRange: IDateRange;
  grouping: Grouping;
  metric: Metric;
  mode: Mode;
  dataDisplay: DataDisplay;
}

export interface ISelectedTotal {
  databaseName: string;
  deviceId: number;
  textMd5: string;
  type: string;
}

export interface ISelectedStatement {
  textMd5: string;
  type: string;
}

export enum Metric {
  duration = "duration",
  cpu = "cpu",
  io = "io",
}

export enum Mode {
  procedure = "procedure",
  statement = "statement",
}

export enum DataDisplay {
  average = "average",
  totals = "totals",
}
