import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import DataDisplay, { DataDisplayGroup } from "../../../../components/DataDisplay";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { useIntl } from "react-intl";
import useStorageEntityStats from "./useStorageEntityStats";
import { useStorageContext } from "../../../../contexts/storageContext";
import classNames from "classnames";
import { StorageEntityType } from "../../types";
import StorageEntityWarning from "../StorageEntityWarning";
import StorageActivityMetricGroup from "./StorageActivityMetricGroup";
import ErrorBoundary from "../../../../components/ErrorBoundary/ErrorBoundary";

interface IStorageEntityDetailsProps {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  activitySection: {
    display: "flex",
    flex: 2,
    flexDirection: "column",
    gap: `${theme.spacing(4)}px`,
    overflow: "auto",
  },
  activitySectionContent: {
    display: "flex",
    flexDirection: "row",
    gap: `${theme.spacing(2)}px`,
    overflow: "auto",
    paddingBottom: `${theme.spacing(2)}px`,
  },
  capacitySection: {
    display: "flex",
    flex: "0 0 55%",
    flexDirection: "column",
    gap: `${theme.spacing(4)}px`,
    width: "55%",
  },
  container: {
    display: "flex",
    gap: `${theme.spacing(5)}px`,
  },
  emphasisAndStandardStats: {
    display: "flex",
    flexWrap: "wrap",
    gap: `${theme.spacing(4)}px`,
  },
  filledStats: {
    display: "flex",
    gap: `${theme.spacing(2)}px`,
  },
  sectionHeader: {
    fontWeight: theme.typography.h3.fontWeight,
    marginRight: theme.spacing(2),
    textTransform: "uppercase",
  },
  warningSection: {
    display: "flex",
    flexDirection: "column",
    gap: `${theme.spacing(2)}px`,
    maxHeight: `calc(100vh - ${theme.spacing(71.5)}px)`,
  },
  warningSectionContent: {
    display: "flex",
    flexWrap: "wrap",
    gap: `${theme.spacing(2)}px`,
    overflowY: "auto",
  },
}));

const StorageEntityDetails = ({ className }: IStorageEntityDetailsProps): React.ReactElement => {
  const classes = useStyles();
  const intl = useIntl();
  const { selectedEntity, getUrlForEntity } = useStorageContext();
  const { emphasisStats, filledStats, standardStats } = useStorageEntityStats(selectedEntity);

  return (
    <div className={classNames(classes.container, className)}>
      <section className={classes.capacitySection}>
        {(emphasisStats || filledStats || standardStats) && (
          <div>
            <Typography className={classes.sectionHeader} component="h3" display="inline">
              {intl.formatMessage({ id: "capacity" })}
            </Typography>
            <Typography color="textSecondary" component="span" variant="body2">
              {selectedEntity.name}
            </Typography>
          </div>
        )}
        {filledStats && (
          <DataDisplayGroup className={classes.filledStats} variant="filled">
            {Array.from(filledStats, ([label, value]) => (
              <DataDisplay
                key={`${label}-${value}`}
                label={intl.formatMessage({ id: label })}
                value={value.toString()}
              />
            ))}
          </DataDisplayGroup>
        )}
        {emphasisStats && (
          <DataDisplayGroup className={classes.emphasisAndStandardStats} variant="emphasis">
            {Array.from(emphasisStats, ([label, value]) => (
              <DataDisplay
                key={`${label}-${value}`}
                label={intl.formatMessage({ id: label })}
                value={value.toString()}
              />
            ))}
          </DataDisplayGroup>
        )}
        {(filledStats || emphasisStats) && <Divider />}
        {standardStats && (
          <DataDisplayGroup className={classes.emphasisAndStandardStats} variant="standard">
            {Array.from(standardStats, ([label, value]) => (
              <DataDisplay
                key={`${label}-${value}`}
                label={intl.formatMessage({ id: label })}
                value={value.toString()}
              />
            ))}
          </DataDisplayGroup>
        )}

        {selectedEntity.warnings && (
          <section className={classes.warningSection} data-testid="warning-section-content">
            <ErrorBoundary variant="generic">
              <Typography
                aria-label={intl.formatMessage({ id: "warnings" })}
                className={classes.sectionHeader}
                component="h3"
                display="inline"
              >
                {intl.formatMessage({ id: "warnings" })}
              </Typography>
              <div className={classes.warningSectionContent}>
                {selectedEntity.warnings.map((warning) => {
                  return (
                    <StorageEntityWarning
                      aria-label={intl.formatMessage({ id: "warningCard" })}
                      entityLink={getUrlForEntity(warning.warningEntity.id)}
                      key={`${warning.warningEntity.id}-${warning.warningType}-${warning.warningValue}`}
                      warningData={warning}
                    />
                  );
                })}
              </div>
            </ErrorBoundary>
          </section>
        )}
      </section>

      {(selectedEntity.type === StorageEntityType.PhysicalDisk ||
        selectedEntity.type === StorageEntityType.SqlServerDataFile ||
        selectedEntity.type === StorageEntityType.SqlServerLogFile) && (
        <>
          <Divider flexItem orientation="vertical" />
          <ErrorBoundary variant="generic">
            <section className={classes.activitySection}>
              <Typography className={classes.sectionHeader} component="h3" display="inline">
                {intl.formatMessage({ id: "activity" })}
              </Typography>
              <div className={classes.activitySectionContent}>
                <StorageActivityMetricGroup entity={selectedEntity} metricType="reads" />
                <StorageActivityMetricGroup entity={selectedEntity} metricType="writes" />
              </div>
            </section>
          </ErrorBoundary>
        </>
      )}
    </div>
  );
};

export default StorageEntityDetails;
