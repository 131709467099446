import * as React from "react";
import MetricChart, { ALL_INSTANCES, IMetricChartSeries, useMetrics } from "../../../../components/MetricChart";
import { IPerformanceAnalysisChartProps } from "../../types";
import { usePerformanceAnalysisContext } from "../../context";

const knownColorMap: ReadonlyMap<string | null, string> = new Map<string, string>([["Other", "Cyan"]]);

/**
 * Creates a new generator for generic chart colors that resets automatically when reaching the end.
 * This is used instead of an array since not every wait group will use a generic color.
 * Incrementing to the next color will only occur when a color is consumed with `next()` rather than based on an index.
 */
function* getColorGenerator(): Generator<string, never, void> {
  while (true) {
    yield "#5F9EA0";
    yield "#FF7F50";
    yield "#808000";
    yield "#DEB887";
    yield "#B8860B";
    yield "#8B008B";
    yield "#E9967A";
    yield "#556B2F";
    yield "#CD853F";
    yield "#ADD8E6";
    yield "#FF6347";
    yield "#6495ED";
    yield "#8B4513";
    yield "#DB7093";
    yield "#2E8B57";
    yield "#FFEFD5";
    yield "#BDB76B";
    yield "#FFA500";
    yield "#B22222";
    yield "#008080";
    yield "#6A5ACD";
    yield "#C71585";
    yield "#4682B4";
    yield "#FFDAB9";
  }
}

function getSortOrder(series: IMetricChartSeries): number {
  switch (series.instance) {
    case "Other":
      return Number.POSITIVE_INFINITY;
    default:
      return 0;
  }
}

function sort(a: IMetricChartSeries, b: IMetricChartSeries): number {
  return getSortOrder(a) - getSortOrder(b);
}

const NetworkChart: React.FC<IPerformanceAnalysisChartProps> = ({ className }) => {
  const { contextMenuItems, dateRange, selectedRange, setSelectedRange, target } = usePerformanceAnalysisContext();
  const outColors = getColorGenerator();
  const outData = useMetrics({
    dateRange,
    metrics: [
      {
        axisLabel: "out",
        chartType: "stackedArea",
        color(s) {
          return knownColorMap.get(s) ?? outColors.next().value;
        },
        enableDefaultScale: false,
        instance: ALL_INSTANCES,
        label(s) {
          return s ?? "os.network.out.sqlInstances.pct";
        },
        metric: "os.network.out.sqlInstances.pct",
        sort,
      },
    ],
    target,
  });
  const inColors = getColorGenerator();
  const inData = useMetrics({
    dateRange,
    metrics: [
      {
        axisLabel: "in",
        chartType: "stackedArea",
        color(s) {
          return knownColorMap.get(s) ?? inColors.next().value;
        },
        enableDefaultScale: false,
        instance: ALL_INSTANCES,
        label(s) {
          return s ?? "os.network.in.sqlInstances.pct";
        },
        metric: "os.network.in.sqlInstances.pct",
        sort,
      },
    ],
    target,
  });
  return (
    <>
      <MetricChart
        className={className}
        contextMenuItems={contextMenuItems}
        dateRange={dateRange}
        hideLegend
        metricData={outData}
        onSelectedRangeChange={setSelectedRange}
        selectedRange={selectedRange}
        target={target}
      />
      <MetricChart
        className={className}
        contextMenuItems={contextMenuItems}
        dateRange={dateRange}
        hideLegend
        metricData={inData}
        onSelectedRangeChange={setSelectedRange}
        selectedRange={selectedRange}
        target={target}
      />
    </>
  );
};

export default NetworkChart;
