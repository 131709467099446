import { createContext, useContext } from "react";

interface IFocusedDialogContext {
  onClose: () => void;
}

const FocusedDialogContext = createContext<IFocusedDialogContext | undefined>(undefined);

const useFocusedDialog = (): IFocusedDialogContext => {
  const focusedDialogContext = useContext(FocusedDialogContext);
  if (!focusedDialogContext)
    throw Error(
      "Looks like you tried to use a FocusedDialog component outside of a FocusedDialog. FocusedDialog provides context to its components so it must be the top level component.",
    );
  else return focusedDialogContext;
};

export { FocusedDialogContext as default, useFocusedDialog };
