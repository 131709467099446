import * as React from "react";
import MetricChart, { IMetricChartSeriesOptions, useMetrics } from "../../../../components/MetricChart";
import { IPerformanceAnalysisChartProps } from "../../types";
import { usePerformanceAnalysisContext } from "../../context";

const aMetrics = Object.freeze<IMetricChartSeriesOptions>([
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#3CB371",
    instance: null,
    label: "transactions",
    metric: "sqlserver.transactions",
  }),
]);

const bMetrics = Object.freeze<IMetricChartSeriesOptions>([
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#6495ED",
    instance: null,
    label: "backupRestoreMb",
    metric: "sqlserver.backupRestore",
  }),
]);

const Activity: React.FC<IPerformanceAnalysisChartProps> = ({ className }) => {
  const { contextMenuItems, dateRange, selectedRange, setSelectedRange, target } = usePerformanceAnalysisContext();
  const aData = useMetrics({
    dateRange,
    metrics: aMetrics,
    target,
  });
  const bData = useMetrics({
    dateRange,
    metrics: bMetrics,
    target,
  });
  return (
    <>
      <MetricChart
        className={className}
        contextMenuItems={contextMenuItems}
        dateRange={dateRange}
        metricData={aData}
        onSelectedRangeChange={setSelectedRange}
        selectedRange={selectedRange}
        target={target}
      />
      <MetricChart
        className={className}
        contextMenuItems={contextMenuItems}
        dateRange={dateRange}
        metricData={bData}
        onSelectedRangeChange={setSelectedRange}
        selectedRange={selectedRange}
        target={target}
      />
    </>
  );
};

export default Activity;
