import * as React from "react";
import { IDateRange } from "../../../../components/DateContext";
import MetricChart, { useMetrics } from "../../../../components/MetricChart";
import { useDateContext } from "../../../../components/DateContext";
import { ITopologyItemDevice, ITopologyItemEventSourceConnection } from "../../../../components/TopologyContext";
import { useSelection } from "../../../../contexts/selectionContext";
import MenuItem from "@material-ui/core/MenuItem";
import { useIntl } from "react-intl";
import { IMetricChartSeriesOptions } from "../../../../components/MetricChart";

export interface IDashboardWidgetChartProps {
  metrics: IMetricChartSeriesOptions[];
  className?: string;
  dateRange: Readonly<IDateRange>;
  style?: React.CSSProperties;
  target: ITopologyItemDevice | ITopologyItemEventSourceConnection;
}

const DashboardWidgetChart: React.FC<IDashboardWidgetChartProps> = ({
  metrics,
  className,
  dateRange,
  style,
  target,
}) => {
  const { setDateRange } = useDateContext();
  const intl = useIntl();
  const { selection, setSelection } = useSelection();

  // If the user navigates to the same dashboard using the NavigationContextMenu submenu, reset the selected area to null
  React.useEffect(
    () => {
      if (
        selection[0] &&
        selection[1] &&
        dateRange.from.getTime() === selection[0].getTime() &&
        dateRange.to.getTime() === selection[1].getTime()
      ) {
        setSelection([null, null]);
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [dateRange],
  );

  const metricData = useMetrics({
    dateRange,
    isCustomizeChart: true,
    metrics,
    target,
  });

  return (
    <MetricChart
      className={className}
      contextMenuItems={[
        <MenuItem
          key="zoom"
          onClick={() => {
            if (selection[0] && selection[1]) {
              setDateRange({ from: selection[0], to: selection[1] });
              setSelection([null, null]);
            }
          }}
        >
          {intl.formatMessage({ id: "zoom" })}
        </MenuItem>,
      ]}
      dateRange={dateRange}
      // key is used to re-render Metric Chart when user navigates to the same dashboard using the
      // NavigationContextMenu submenu
      key={`${dateRange.to.getTime()}-${dateRange.from.getTime()}`}
      metricData={metricData}
      onSelectedRangeChange={setSelection}
      selectedRange={selection}
      style={style}
      target={target}
    />
  );
};

export default DashboardWidgetChart;
