import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
import { IconProps } from "./types";
import { getClass } from "./utils";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  large: {
    height: "24px",
    width: "24px",
  },
  medium: {
    height: "20px",
    width: "20px",
  },
  small: {
    height: "14px",
    width: "14px",
  },
}));

const VMWareIcon: React.FunctionComponent<IconProps> = ({className, size}) => {
  const classes = useStyles();

  return (
    <SvgIcon
      className={getClass(classes, size) + " " + className}
      data-testid="vmware-target-icon"
      height="9"
      viewBox="0 0 24 9"
      width="14"
    >
      <path
        d="M2.45448 0.70524C2.18766 0.107135 1.52814 -0.163785 0.912343 0.115999C0.295788 0.396168 0.0689138 1.09524 0.347419 1.69373L2.9331 7.4397C3.33974 8.34033 3.76824 8.81242 4.57248 8.81242C5.43173 8.81242 5.80634 8.29832 6.2126 7.4397C6.2126 7.4397 8.46777 2.41824 8.49039 2.36429C8.51337 2.31111 8.58573 2.14886 8.81525 2.1504C9.00933 2.15156 9.17252 2.30995 9.17252 2.52268V7.43238C9.17252 8.18926 9.58292 8.8101 10.3728 8.8101C11.162 8.8101 11.5886 8.18926 11.5886 7.43238V3.4129C11.5886 2.63791 12.1317 2.13499 12.8718 2.13499C13.612 2.13499 14.1046 2.65602 14.1046 3.4129V7.43238C14.1046 8.18926 14.5165 8.8101 15.3049 8.8101C16.0941 8.8101 16.5229 8.18926 16.5229 7.43238V3.4129C16.5229 2.63791 17.0637 2.13499 17.8043 2.13499C18.5433 2.13499 19.0378 2.65602 19.0378 3.4129V7.43238C19.0378 8.18926 19.4493 8.8101 20.2385 8.8101C21.0272 8.8101 21.4554 8.18926 21.4554 7.43238V2.85796C21.4554 1.17733 20.1337 2.26501e-07 18.5433 2.26501e-07C16.9548 2.26501e-07 15.9599 1.12376 15.9599 1.12376C15.4311 0.422759 14.7023 0.00154236 13.4688 0.00154236C12.1671 0.00154236 11.0278 1.12376 11.0278 1.12376C10.4987 0.422759 9.598 0.00154236 8.85218 0.00154236C7.69859 0.00154236 6.78242 0.52026 6.22353 1.82707L4.57248 5.80802L2.45448 0.70524Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default VMWareIcon;
