import * as React from "react";
import { v4 as uuid } from "uuid";
import MetricChart, {
  ALL_INSTANCES,
  IMetricChartSeriesOptions,
  TOTAL_INSTANCE,
  useMetrics,
} from "../../../../components/MetricChart";
import { usePerformanceAnalysisContext } from "../../context";
import { IPerformanceAnalysisChartProps } from "../../types";

const freeSizePatternId = `free-space-pattern-${uuid()}`;
const aMetrics = Object.freeze<IMetricChartSeriesOptions>([
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "stackedArea",
    color: "#A7A1F1",
    instance: TOTAL_INSTANCE,
    label: "buffer",
    metric: "sqlserver.bufferNode.database.size",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "stackedArea",
    color: "#B34700",
    instance: TOTAL_INSTANCE,
    label: "stolenBuffer",
    metric: "sqlserver.memoryNode.stolen.size",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "stackedArea",
    color: "#FF7F50",
    instance: TOTAL_INSTANCE,
    label: "inMemOltp",
    metric: "sqlserver.memoryClerks.inMemoryOltp.size",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "stackedArea",
    color: `url(#${freeSizePatternId})`,
    instance: TOTAL_INSTANCE,
    label: "free",
    metric: "sqlserver.memoryNode.free.size",
    strokeColor: "#194537",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "stackedArea",
    color: "#4169E1",
    instance: "SQL Plans",
    label: "planSql",
    metric: "sqlserver.planCache.size",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "stackedArea",
    color: "#00BFFF",
    instance: "Object Plans",
    label: "planObjects",
    metric: "sqlserver.planCache.size",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "stackedArea",
    color: "#FFA500",
    instance: TOTAL_INSTANCE,
    label: "columnStore",
    metric: "sqlserver.memoryClerks.columnstore.size",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "stackedArea",
    color: "#808080",
    instance: null,
    label: "other",
    metric: "sqlserver.memory.other",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#FF0000",
    instance: null,
    label: "queryGrants",
    metric: "sqlserver.memoryManager.grants.size",
  }),
]);

const bMetrics = Object.freeze<IMetricChartSeriesOptions>([
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#7B68EE",
    instance: ALL_INSTANCES,
    label: (x) => `PLE - Node ${x}`,
    metric: "sqlserver.bufferNode.pageLifeExpectancySec",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#4169E1",
    instance: "SQL Plans",
    label: "Plan (SQL)",
    metric: "sqlserver.planCache.cacheHitRatio.pct",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#00BFFF",
    instance: "Object Plans",
    label: "Plan (Objects)",
    metric: "sqlserver.planCache.cacheHitRatio.pct",
  }),
]);

const cMetrics = Object.freeze<IMetricChartSeriesOptions>([
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#FF8C00",
    instance: null,
    label: "pagesReads",
    metric: "sqlserver.bufferManager.readPages.ps",
  }),
  Object.freeze<IMetricChartSeriesOptions>({
    axisLabel: null,
    chartType: "line",
    color: "#FF4500",
    instance: null,
    label: "pagesWrites",
    metric: "sqlserver.bufferManager.writePages.ps",
  }),
]);

const SqlServerMemoryChart: React.FC<IPerformanceAnalysisChartProps> = ({ className }) => {
  const { contextMenuItems, dateRange, selectedRange, setSelectedRange, target } = usePerformanceAnalysisContext();
  const aData = useMetrics({
    dateRange,
    metrics: aMetrics,
    target,
  });
  const bData = useMetrics({
    dateRange,
    metrics: bMetrics,
    target,
  });
  const cData = useMetrics({
    dateRange,
    metrics: cMetrics,
    target,
  });
  return (
    <>
      <MetricChart
        className={className}
        contextMenuItems={contextMenuItems}
        dateRange={dateRange}
        metricData={aData}
        onSelectedRangeChange={setSelectedRange}
        selectedRange={selectedRange}
        target={target}
      >
        <defs>
          <pattern height="6" id={freeSizePatternId} patternUnits="userSpaceOnUse" width="6" x="0" y="0">
            <circle cx="2" cy="2" fill="#194537" r="1" stroke="none" />
            <circle cx="5" cy="5" fill="#194537" r="1" stroke="none" />
          </pattern>
        </defs>
      </MetricChart>
      <MetricChart
        className={className}
        contextMenuItems={contextMenuItems}
        dateRange={dateRange}
        metricData={bData}
        onSelectedRangeChange={setSelectedRange}
        selectedRange={selectedRange}
        target={target}
      />
      <MetricChart
        className={className}
        contextMenuItems={contextMenuItems}
        dateRange={dateRange}
        metricData={cData}
        onSelectedRangeChange={setSelectedRange}
        selectedRange={selectedRange}
        target={target}
      />
    </>
  );
};

export default SqlServerMemoryChart;
