import * as React from "react";
import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import SeverityMultipleSelect, { Severitys } from "./SeverityMultipleSelect";
import { useGetHealthScore } from "./useEnvironmentHealth";
import {
  ITopologyItem,
  ITopologyItemDevice,
  ITopologyItemEventSourceConnection,
  useTopology,
} from "../../components/TopologyContext";
import { useDateContext } from "../../components/DateContext";
import { useHealthContext } from "../../features/EnvironmentHealth/context";
import { EventGroupHealthType, IHealthStatusItem, TopologyObjectType } from "../../api/PerformanceAdvisor";
import TargetScoreCard from "./TargetScoreCard";
import { Skeleton } from "@material-ui/lab";
import { useCurrentTarget } from "../../hooks/useCurrentTarget";
import ErrorBoundary, { Throw } from "../../components/ErrorBoundary";

const useStyles = makeStyles((theme) => ({
  dataGridContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: `${theme.spacing(1.4)}px`,
    margin: "10px 6px 0px 6px",
    maxHeight: "140px",
    overflowY: "auto",
  },
  filterContent: {
    display: "flex",
    flexDirection: "column",
  },
  noDataContainer: {
    paddingBottom: theme.spacing(9),
    paddingTop: theme.spacing(7),
    textAlign: "center",
  },
}));

export interface IEnvironmentScoreSheetProps {
  topLevelObject?: ITopologyItem;
}

const EnvironmentScoreSheet: React.FC<IEnvironmentScoreSheetProps> = ({ topLevelObject }) => {
  const intl = useIntl();
  const classes = useStyles();
  const [calculateDelta, setCalculateDelta] = React.useState<boolean>(false);
  const [selectedSeverities, setSelectedSeverities] = React.useState<string[] | null>([
    Severitys.Critical,
    Severitys.Unhealthy,
  ]);

  const topology = useTopology();
  const { dateRange } = useDateContext();
  const { groupHealthType, rollupLevelMinutes, targetsFilter } = useHealthContext();

  const scoreThreshold =
    groupHealthType === EventGroupHealthType.Waits ? targetsFilter.minWaitTimePerSession : targetsFilter.maxScore;

  function getAllChildTopologyNodes(
    topologyNode: ITopologyItem,
    childNodes: ITopologyItem[],
  ): ITopologyItem[] | undefined {
    const children = topology.findByParentObjectId(topologyNode.objectId);
    children.forEach((child) => {
      if (![TopologyObjectType.global, TopologyObjectType.site, TopologyObjectType.group].includes(child.type)) {
        childNodes.push(child);
      }
      getAllChildTopologyNodes(child, childNodes);
    });
    return childNodes;
  }

  const allChildTargets = topLevelObject && getAllChildTopologyNodes(topLevelObject, []);

  const childWindowsTarget =
    allChildTargets &&
    allChildTargets
      .filter((child) => {
        if (child.type === TopologyObjectType.device) {
          return child;
        }
      })
      .map((child) => child as ITopologyItemDevice);

  const childSqlTarget =
    allChildTargets &&
    allChildTargets
      .filter((child) => {
        if (child.type === TopologyObjectType.eventSourceConnection) {
          return child;
        }
      })
      .map((child) => child as ITopologyItemEventSourceConnection);

  //if group or site is not selected, use global list of devices\eventsourceconnections
  const windowsTargets = childWindowsTarget ?? topology.devices;
  const sqlTargets = childSqlTarget ?? topology.eventSourceConnections;

  // available targets for the target selector
  const availableTargets: Array<ITopologyItemDevice | ITopologyItemEventSourceConnection> = [
    // filter out 'fake' devices and devices that have children connections
    ...windowsTargets.filter((d) => !!d.description && !sqlTargets.some((conn) => conn.parentObjectId === d.objectId)),
    ...sqlTargets,
  ].filter((d) => d.isWatched);

  const { data: targetsHealth = [], error, isLoading } = useGetHealthScore({
    calculateDelta: calculateDelta,
    endDateUtc: dateRange.to,
    eventGroupHealthType: groupHealthType,
    objectId: topLevelObject ? topLevelObject.itemId : undefined,
    objectType: topLevelObject ? topLevelObject.type : TopologyObjectType.global,
    returnAllChildrenOfType: [TopologyObjectType.device, TopologyObjectType.eventSourceConnection], // get only device and event source connection type's children
    rollupLevelMinutes,
    scoreSeverities: selectedSeverities,
    scoreThreshold: scoreThreshold ?? undefined,
    startDateUtc: dateRange.from,
  });

  const targetAndScore = React.useMemo(() => {
    const healthData = new Map<string, IHealthStatusItem>(targetsHealth.map((x) => [x.objectId, x]));
    // only return the target which has the health score
    return availableTargets.reduce<
      Array<[ITopologyItemDevice | ITopologyItemEventSourceConnection, IHealthStatusItem]>
    >(function (result, target) {
      const targetHealthData = healthData.get(target.objectId);
      if (targetHealthData) result.push([target, targetHealthData]);
      return result;
    }, []);
  }, [availableTargets, targetsHealth]);

  const sortScore = (
    a: readonly [ITopologyItemDevice | ITopologyItemEventSourceConnection, IHealthStatusItem],
    b: readonly [ITopologyItemDevice | ITopologyItemEventSourceConnection, IHealthStatusItem],
  ): number => {
    return calculateDelta
      ? a[1].scoreDelta - b[1].scoreDelta || a[0].name.localeCompare(b[0].name, intl.locale)
      : (a[1].score === null ? Infinity : a[1].score) - (b[1].score === null ? Infinity : b[1].score) ||
          a[0].name.localeCompare(b[0].name, intl.locale);
  };

  return (
    <Card>
      <ErrorBoundary variant="generic">
        {/* Throwing the error inside of the ErrorBoundary so that the outer Card is kept in the DOM */}
        {error && <Throw error={error} />}
        <CardContent className={classes.filterContent}>
          <div style={{ display: "flex" }}>
            <SeverityMultipleSelect
              handleCalculateDelta={setCalculateDelta}
              setSelectedSeverities={setSelectedSeverities}
            />
          </div>
          {isLoading ? (
            <div className={classes.dataGridContainer}>
              {Array.from({ length: 6 }, () => (
                <Skeleton height="4rem" key={Math.random()} role="progressbar" variant="rect" width="23.7rem" />
              ))}
            </div>
          ) : targetAndScore && targetAndScore.length > 0 ? (
            <div className={classes.dataGridContainer}>
              {targetAndScore
                .sort((a, b) => sortScore(a, b))
                .map(([target, targetHealth]) => (
                  <TargetScoreCard
                    calculateDelta={calculateDelta}
                    health={targetHealth}
                    key={target.objectId}
                    target={target}
                  />
                ))}
            </div>
          ) : (
            // If there’s nothing in the selected bucket, show message
            <div className={classes.noDataContainer}>
              <Typography component="span" variant="body1">
                {intl.formatMessage({ id: "nothingToSeeHere" })}
              </Typography>
            </div>
          )}
        </CardContent>
      </ErrorBoundary>
    </Card>
  );
};
export default EnvironmentScoreSheet;
