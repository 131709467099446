import * as React from "react";
import MetricChart, { ALL_INSTANCES, IMetricChartSeries, useMetrics } from "../../../../components/MetricChart";
import { usePerformanceAnalysisContext } from "../../context";
import { IPerformanceAnalysisChartProps } from "../../types";

const knownColorMap: ReadonlyMap<string | null, string> = new Map<string, string>([
  ["CPU", "#00C853"],
  ["Disk", "#FF8C00"],
  ["Memory", "#4169E1"],
  ["Network", "#00FFFF"],
  ["Other", "#808080"],
]);

/**
 * Creates a new generator for generic chart colors that resets automatically when reaching the end.
 * This is used instead of an array since not every wait group will use a generic color.
 * Incrementing to the next color will only occur when a color is consumed with `next()` rather than based on an index.
 */
function* getColorGenerator(): Generator<string, never, void> {
  while (true) {
    yield "#5f9ea0";
    yield "#FF7F50";
    yield "#808000";
    yield "#DEB887";
    yield "#B8860B";
    yield "#8B008B";
    yield "#E9967A";
    yield "#556B2F";
    yield "#CD853F";
    yield "#ADD8E6";
    yield "#FF6347";
    yield "#6495ED";
    yield "#8B4513";
    yield "#DB7093";
    yield "#2E8B57";
    yield "#FFEFD5";
    yield "#BDB76B";
    yield "#FFA500";
    yield "#B22222";
    yield "#008080";
    yield "#6A5ACD";
    yield "#C71585";
    yield "#4682B4";
    yield "#FFDAB9";
  }
}

function getSortOrder(series: IMetricChartSeries): number {
  switch (series.instance) {
    case "Disk":
      return 1;
    case "Other":
      return 2;
    case "Memory":
      return 3;
    case "CPU":
      return 4;
    case "Network":
      return 5;
    default:
      return 6;
  }
}

const WaitsChart: React.FC<IPerformanceAnalysisChartProps> = ({ className }) => {
  const { contextMenuItems, dateRange, selectedRange, setSelectedRange, target } = usePerformanceAnalysisContext();
  const colors = getColorGenerator();
  const metricData = useMetrics({
    dateRange,
    metrics: [
      {
        axisLabel: null,
        chartType: "stackedArea",
        color(s) {
          return knownColorMap.get(s) ?? colors.next().value;
        },
        instance: ALL_INSTANCES,
        label(s) {
          return s ?? "sqlserver.waits.category.ms";
        },
        metric: "sqlserver.waits.category.ms",
        sort(a, b) {
          return getSortOrder(a) - getSortOrder(b);
        },
      },
    ],
    target,
  });
  return (
    <MetricChart
      className={className}
      contextMenuItems={contextMenuItems}
      dateRange={dateRange}
      metricData={metricData}
      onSelectedRangeChange={setSelectedRange}
      selectedRange={selectedRange}
      target={target}
    />
  );
};

export default WaitsChart;
