import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import { HealthRegionKey } from "../../api/PerformanceAdvisor";
import { HealthScoreRange } from "../../features/Layout/components/Sidebar/types";

const healthRegions = {
  [HealthRegionKey.CPU.toLowerCase()]: "#5FAB7B",
  [HealthRegionKey.Critical.toLowerCase()]: "#D91C24",
  [HealthRegionKey.Disk.toLowerCase()]: "#DE7632",
  [HealthRegionKey.High.toLowerCase()]: "#F7931E",
  [HealthRegionKey.Info.toLowerCase()]: "#BDBDBD",
  [HealthRegionKey.Low.toLowerCase()]: "#94A8C8",
  [HealthRegionKey.Medium.toLowerCase()]: "#F2F221",
  [HealthRegionKey.Memory.toLowerCase()]: "#4C8FBB",
  [HealthRegionKey.Network.toLowerCase()]: "#8F3B18",
  [HealthRegionKey.Other.toLowerCase()]: "#BDBDBD",
};

const healthScoreRanges = {
  [HealthScoreRange.atRisk]: "#F1C40F",
  [HealthScoreRange.critical]: "#D91C24",
  [HealthScoreRange.healthy]: "#4CA03D",
};

const genericChartColors = [
  "#5F9EA0",
  "#FF7F50",
  "#808000",
  "#DEB887",
  "#B8860B",
  "#8B008B",
  "#E9967A",
  "#556B2F",
  "#CD853F",
  "#ADD8E6",
  "#FF6347",
  "#6495ED",
  "#8B4513",
  "#DB7093",
  "#2E8B57",
  "#FFEFD5",
  "#BDB76B",
  "#FFA500",
  "#B22222",
  "#008080",
  "#6A5ACD",
  "#C71585",
  "#4682B4",
  "#FFDAB9",
  "#2196F3",
];

/**
 * Creates a new generator for generic chart colors that resets automatically when reaching the end.
 * This is used instead of an array since not every wait group will use a generic color.
 * Incrementing to the next color will only occur when a color is consumed with `next()` rather than based on an index.
 */
function* getGenericColorGenerator(): Generator<string, never, void> {
  while (true) {
    yield "#5F9EA0";
    yield "#FF7F50";
    yield "#808000";
    yield "#DEB887";
    yield "#B8860B";
    yield "#8B008B";
    yield "#E9967A";
    yield "#556B2F";
    yield "#CD853F";
    yield "#ADD8E6";
    yield "#FF6347";
    yield "#6495ED";
    yield "#8B4513";
    yield "#DB7093";
    yield "#2E8B57";
    yield "#FFEFD5";
    yield "#BDB76B";
    yield "#FFA500";
    yield "#B22222";
    yield "#008080";
    yield "#6A5ACD";
    yield "#C71585";
    yield "#4682B4";
    yield "#FFDAB9";
    yield "#2196F3";
  }
}

export const paletteOptions: PaletteOptions = {
  background: {
    default: "#f0f0f0",
  },
  genericChartColors,
  getColorForHealthRegion: (healthRegion: HealthRegionKey) => {
    return healthRegions[healthRegion.toLowerCase()];
  },
  getColorForHealthScore: (score: number) => {
    if (score <= 10) {
      return "#B40019";
    } else if (score <= 20) {
      return "#D91C24";
    } else if (score <= 30) {
      return "#F15A24";
    } else if (score <= 40) {
      return "#F38301";
    } else if (score <= 50) {
      return "#FFC836";
    } else if (score <= 60) {
      return "#F1C40F";
    } else if (score <= 70) {
      return "#CBDB2A";
    } else if (score <= 80) {
      return "#85C441";
    } else if (score <= 90) {
      return "#4CA03D";
    } else {
      return "#1B7C2B";
    }
  },
  getColorForHealthScoreRange: (healthScoreRange: HealthScoreRange) => {
    return healthScoreRanges[healthScoreRange];
  },
  getGenericColorGenerator,
};
