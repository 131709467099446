import FormControlLabel from "@material-ui/core/FormControlLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { OverrideProps } from "@material-ui/core/OverridableComponent";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { TypographyTypeMap } from "@material-ui/core/Typography/Typography";
import TargetsIcon from "@material-ui/icons/MyLocation";
import AlertIcon from "@material-ui/icons/Warning";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useRouteMatch } from "react-router-dom";
import CustomDashboardIcon from "../../../../../components/icons/CustomDashboardIcon";
import HealthOverviewIcon from "../../../../../components/icons/HealthOverviewIcon";
import { useTopology } from "../../../../../components/TopologyContext";
import { useFeatureFlag } from "../../../../../contexts/featuresContext";
import { DarkModeDivider } from "../SidebarContent/SidebarContent";
import FeatureLinkButton from "./FeatureLinkButton";

export interface IFeatureNavigationProps {
  showHierarchy: boolean;
  sidebarOpen: boolean;
  setHierarchy: (showHierarchy: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  hierarchyToggle: {
    "& .MuiSwitch-root": {
      "& .MuiSwitch-switchBase.Mui-checked": {
        color: "var(--sidebar-dark-switch-color)",
      },
      "& .MuiSwitch-track": {
        backgroundColor: "var(--sidebar-dark-switch-color)",
      },
    },
    paddingRight: theme.spacing(2),
  },
  hierarchyToggleLabel: {
    color: "var(--sidebar-dark-text-grey)",
    fontSize: "0.9rem",
    fontWeight: "inherit",
  },
  monitorNavButton: {
    "&:hover": {
      backgroundColor: "var(--sidebar-dark-item-hover-background)",
    },
    fontWeight: "normal",
    padding: "13px 12px",
  },
  monitorNavContainer: {
    display: "flex",
    flexDirection: "column",
  },
  monitorNavIcon: {
    color: "var(--sidebar-dark-text-white)",
    minWidth: "40px",
  },
  targetsHeader: {
    borderLeft: "solid 4px transparent",
    fontWeight: "normal",
    padding: "13px 12px 0px 12px",
  },
}));

const FeatureNavigationGroup: React.FunctionComponent<IFeatureNavigationProps> = ({
  sidebarOpen,
  showHierarchy,
  setHierarchy,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const match = useRouteMatch();
  const { shouldShowFeature, devices, eventSourceConnections } = useTopology();
  React.useEffect(() => {
    localStorage.setItem("showHierarchy", JSON.stringify(showHierarchy));
  });

  const listItemTypographyProps: Partial<OverrideProps<TypographyTypeMap<unknown, "span">, "span">> = {
    noWrap: true,
    style: {
      color: !sidebarOpen ? "transparent" : "var(--sidebar-dark-text-white)",
    },
    variant: "h6",
  };

  const [showAlerts, showCustomDashboards, showNoTargetPage] = React.useMemo(() => {
    return [
      shouldShowFeature("alerts"),
      shouldShowFeature("performance"),
      [...devices, ...eventSourceConnections].length === 0,
    ];
  }, [shouldShowFeature, devices, eventSourceConnections]);

  return (
    <List className={classes.monitorNavContainer} disablePadding>
      <FeatureLinkButton
        className={classes.monitorNavButton}
        icon={<HealthOverviewIcon />}
        iconClassName={classes.monitorNavIcon}
        label={<FormattedMessage id="healthOverview" />}
        listItemTypographyProps={listItemTypographyProps}
        to={`${match.url}/health`}
      />
      {showAlerts && !showNoTargetPage && (
        <FeatureLinkButton
          className={classes.monitorNavButton}
          icon={<AlertIcon />}
          iconClassName={classes.monitorNavIcon}
          label={<FormattedMessage id="alertsLog" />}
          listItemTypographyProps={listItemTypographyProps}
          to={`${match.url}/alerts`}
        />
      )}
      {showCustomDashboards && !showNoTargetPage && (
        <FeatureLinkButton
          className={classes.monitorNavButton}
          icon={<CustomDashboardIcon />}
          iconClassName={classes.monitorNavIcon}
          label={<FormattedMessage id="dashboards" />}
          listItemTypographyProps={listItemTypographyProps}
          to={`${match.url}/dashboards`}
        />
      )}
      <DarkModeDivider />
      <ListItem className={classes.targetsHeader} id="targets-section">
        <ListItemIcon className={classes.monitorNavIcon}>
          <TargetsIcon />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={listItemTypographyProps}>
          <FormattedMessage id="targets" />
        </ListItemText>
        <FormControlLabel
          classes={{
            label: classes.hierarchyToggleLabel,
            root: classes.hierarchyToggle,
          }}
          control={<Switch checked={showHierarchy} />}
          label={<FormattedMessage id="showHierarchy" />}
          labelPlacement="start"
          onChange={(e, showHierarchy) => setHierarchy(showHierarchy)}
          title={intl.formatMessage({ id: "showHierarchy" })}
        />
      </ListItem>
    </List>
  );
};
export default FeatureNavigationGroup;
