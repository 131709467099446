import { CSSProperties } from "react";
import { IDateRange } from "../../../../components/DateContext";
import { ITranslation } from "../../../../intl/types";

export enum PerformanceAnalysisAggregateType {
  execCount = "execCount",
  duration = "duration",
  cpu = "cpu",
  reads = "reads",
  writes = "writes",
  waits = "waits",
}

export enum QueryAggregateType {
  execCount = "execCount",
  duration = "duration",
  cpu = "cpu",
  readsL = "readsL",
  readsP = "readsP",
  writesL = "writesL",
}

export enum TopSqlChartType {
  Waits = "waits",
  Resources = "resources",
  Queries = "queries",
  ByApp = "byApp",
  ByHost = "byHost",
  ByLogin = "byLogin",
  ByDatabase = "byDatabase",
}

export type IChartSelection =
  | { chart: TopSqlChartType.Queries; subMenu: QueryAggregateType }
  | {
      chart: TopSqlChartType.ByApp | TopSqlChartType.ByDatabase | TopSqlChartType.ByHost | TopSqlChartType.ByLogin;
      subMenu: PerformanceAnalysisAggregateType;
    }
  | { chart: TopSqlChartType.Resources | TopSqlChartType.Waits; subMenu: null };

export interface IChartProps {
  currentFilter: Readonly<IDateRange> | null;
  eventSourceConnectionId: number;
  deviceId?: number;
  onSeriesClick: (filter: IDateRange) => void;
  renderLoading: () => React.ReactElement;
  renderNoData: () => React.ReactElement;
  showAxisLabels: boolean;
  style?: Readonly<CSSProperties>;
  visibleDates: Readonly<IDateRange>;
}

export const PerformanceAnalysisSubMenu: ReadonlyArray<{
  readonly label: keyof ITranslation;
  readonly value: PerformanceAnalysisAggregateType;
}> = [
  { label: "cpu", value: PerformanceAnalysisAggregateType.cpu },
  { label: "duration", value: PerformanceAnalysisAggregateType.duration },
  { label: "execCount", value: PerformanceAnalysisAggregateType.execCount },
  { label: "readsL", value: PerformanceAnalysisAggregateType.reads },
  { label: "writesP", value: PerformanceAnalysisAggregateType.writes },
  { label: "waits", value: PerformanceAnalysisAggregateType.waits },
];

export const QueriesChartSubMenu: ReadonlyArray<{
  readonly label: keyof ITranslation;
  readonly value: QueryAggregateType;
}> = [
  { label: "cpu", value: QueryAggregateType.cpu },
  { label: "duration", value: QueryAggregateType.duration },
  { label: "execCount", value: QueryAggregateType.execCount },
  { label: "readsL", value: QueryAggregateType.readsL },
  { label: "readsP", value: QueryAggregateType.readsP },
  { label: "writesL", value: QueryAggregateType.writesL },
];
