import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { IntlShape, useIntl } from "react-intl";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  RechartsFunction,
  ResponsiveContainer,
  Tooltip,
  TooltipFormatter,
  XAxis,
  YAxis,
} from "recharts";
import NoDataIndicator from "../../../../components/NoDataIndicator";
import { IBlockingFilter, useBlockingContext } from "../../../../contexts/blockingContext";
import { formatAbbreviatedNumber } from "../../../../utilities/Formatters";
import { BlockingReportType } from "../../types";

export interface IReportingChartProps {
  aggregateBy: BlockingReportType;
  data: readonly IReportingChartPointColor[];
  title: React.ReactNode;
}

export interface IReportingChartPointColor {
  readonly color: string;
  readonly name: string;
  readonly value: number;
}

const useStyles = makeStyles((theme) => ({
  header: {
    padding: 0,
    padingLeft: theme.spacing(1),
    width: "inherit",
  },
  wrapper: {
    flex: 1,
    height: "inherit",
    maxHeight: "175px",
    position: "relative",
  },
}));

function getFilterKeyForAggregateBy(aggregateBy: BlockingReportType): keyof IBlockingFilter {
  switch (aggregateBy) {
    case BlockingReportType.application:
      return "selectedApplications";
    case BlockingReportType.resource:
      return "selectedResource";
    case BlockingReportType.waitType:
      return "selectedResourceType";
  }
}

function makeTooltipFormatter(intl: IntlShape): TooltipFormatter {
  return (value) => {
    const name = intl.formatMessage({ id: "value" });
    if (typeof value === "number") {
      return [intl.formatNumber(value), name];
    } else {
      return [value, name];
    }
  };
}

const ReportingChart: React.FC<IReportingChartProps> = ({ aggregateBy, data, title }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { setBlockingFilter } = useBlockingContext();

  const handleClick: RechartsFunction = (chart) => {
    const value = chart.payload.name;
    if (value) {
      const filterKey = getFilterKeyForAggregateBy(aggregateBy);
      setBlockingFilter({
        [filterKey]: [value],
      });
    }
  };

  if (data.length === 0) {
    return <NoDataIndicator />;
  } else {
    return (
      <div className={classes.wrapper}>
        <Typography align="center" classes={{ root: classes.header }} variant="h5">
          {title}
        </Typography>
        <ResponsiveContainer>
          <BarChart data={data} layout="vertical">
            <CartesianGrid horizontal={false} />
            <XAxis dataKey="value" tickFormatter={formatAbbreviatedNumber} type="number" />
            <YAxis dataKey="name" hide type="category" />
            <Tooltip
              // Reset the inline styles to the default global styles
              contentStyle={{ backgroundColor: "inherit", borderColor: "inherit", borderRadius: "inherit" }}
              cursor={false}
              formatter={makeTooltipFormatter(intl)}
              isAnimationActive={false}
              itemStyle={{ color: "inherit" }}
              labelFormatter={(value) => {
                if (typeof value === "string" || typeof value === "undefined" || value === null) {
                  return intl.formatMessage({ defaultMessage: value ?? undefined, id: value || "(unknown)" });
                } else {
                  return value;
                }
              }}
            />
            <Bar dataKey="value" onClick={handleClick}>
              {data.map((x) => (
                <Cell fill={x.color} key={x.name} style={{ cursor: "pointer" }} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
};

export default ReportingChart;
