import * as React from "react";
import { RechartsFunction, Tooltip, TooltipProps } from "recharts";
import { useTheme } from "@material-ui/core/styles";

export interface StickyTooltipRef {
  props: TooltipProps;
  wrapperNode: HTMLElement;
}
export interface IStickyTooltipProps extends TooltipProps {
  sticky: TooltipProps;
  tooltipRef: React.RefObject<StickyTooltipRef> | null;
}

export const StickyTooltip: React.FC<IStickyTooltipProps> = ({ sticky, tooltipRef, ...props }) => {
  const theme = useTheme();

  return (
    <>
      {/* Sticky tooltip */}
      <Tooltip wrapperStyle={{ zIndex: theme.zIndex.modal - 2 }} {...props} {...sticky} />

      {/* Moving tooltip */}
      {/* ref exposed by recharts does not have prop 'wrapperNode' needed in MetricChart */}
      <Tooltip ref={tooltipRef as any} wrapperStyle={{ zIndex: theme.zIndex.modal - 1 }} {...props} />
    </>
  );
};
// displayName must be set to Tooltip for recharts to render it
StickyTooltip.displayName = "Tooltip";

export interface IUseStickyTooltipResult {
  closeTooltip: () => void;
  onClick: RechartsFunction;
  stickyProps: TooltipProps;
}

const defaultStickyProps: TooltipProps = {
  active: false,
};

export function useStickyTooltip(): IUseStickyTooltipResult {
  const [stickyProps, setStickyProps] = React.useState<TooltipProps>(defaultStickyProps);

  const onClick: RechartsFunction = (chart, _event, additionalProps: Partial<TooltipProps>) => {
    if (chart) {
      setStickyProps({
        active: true,
        coordinate: chart.activeCoordinate,
        label: chart.activeLabel,
        payload: chart.activePayload,
        ...additionalProps,
      });
    } else {
      setStickyProps(defaultStickyProps);
    }
  };

  return {
    closeTooltip: () => setStickyProps(defaultStickyProps),
    onClick,
    stickyProps,
  };
}
