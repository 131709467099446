import * as React from "react";
import { IDateRange } from "../../components/DateContext";
import { IMetricChartProps } from "../../components/MetricChart";
import { ITopologyItemDevice, ITopologyItemEventSourceConnection } from "../../components/TopologyContext";

export interface IPerformanceAnalysisContext {
  readonly contextMenuItems: IMetricChartProps["contextMenuItems"];
  readonly dateRange: IDateRange;
  readonly selectedRange: IMetricChartProps["selectedRange"];
  readonly setSelectedRange: IMetricChartProps["onSelectedRangeChange"];
  readonly target: ITopologyItemDevice | ITopologyItemEventSourceConnection;
}

const context = React.createContext<IPerformanceAnalysisContext | undefined>(undefined);
context.displayName = "PerformanceAnalysisContext";

export const PerformanceAnalysisProvider = context.Provider;

export function usePerformanceAnalysisContext(): IPerformanceAnalysisContext {
  const value = React.useContext(context);
  if (!value) {
    throw new Error("usePerformanceAnalysisContext can only be used in a descendant of PerformanceAnalysis");
  }
  return value;
}
