import { TypographyOptions } from "@material-ui/core/styles/createTypography";

export const typographyOptions: TypographyOptions = {
  body2: {
    fontWeight: "bold",
  },
  button: {
    fontWeight: "bold",
  },
  caption: {
    fontWeight: "normal",
    letterSpacing: 0,
  },
  fontFamily: `'Roboto', sans-serif`,
  h2: {
    fontWeight: "bold",
  },
  h3: {
    fontWeight: "bold",
  },
  h5: {
    fontWeight: "bold",
  },
  htmlFontSize: 14,
  overline: {
    textTransform: "capitalize",
  },
  queryText: {
    fontSize: "0.857rem",
    letterSpacing: "0.05em",
  },
};
