import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import * as React from "react";
import { useIntl } from "react-intl";
import { IModalCloseOptions } from "../../../features/Permissions/types";
import { INotesGridResponse } from "../../../api/models/AdvisoryConditionEvents";
import { useUserContext } from "../../../contexts/userContext";
import { useAlertsLogActions } from "./useAlertsLogActions";

export interface INoteModalProps {
  alertLogId: number;
  handleNoteModalClose: (options: IModalCloseOptions) => void;
  openState: boolean;
  selectedNote: INotesGridResponse | null;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const NoteModal: React.FC<INoteModalProps> = ({
  alertLogId,
  handleNoteModalClose,
  openState,
  selectedNote,
  setIsLoading,
}) => {
  const intl = useIntl();
  const { addUpdateNote } = useAlertsLogActions();
  const { userInfo } = useUserContext();
  const [noteText, setNoteText] = React.useState<string>(selectedNote?.noteText ?? "");
  const [includeInNotification, setIncludeInNotification] = React.useState<boolean>(
    selectedNote?.includeInNotification ?? false,
  );

  const clearForm = (): void => {
    setNoteText("");
    setIncludeInNotification(false);
  };

  const handleClose = (options: IModalCloseOptions): void => {
    clearForm();
    handleNoteModalClose(options);
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    const ModalCloseOption: IModalCloseOptions = { id: null, status: "CLOSED" };
    setIsLoading(true);
    try {
      const { isSuccess } = await addUpdateNote({
        alertLogId,
        includeInNotification: includeInNotification,
        noteId: selectedNote?.noteId ?? 0,
        noteText: noteText.trim(),
        userName: userInfo?.username ?? userInfo?.email
      });
      ModalCloseOption.status = isSuccess ? "SUCCESS" : "ERROR";
      ModalCloseOption.id = isSuccess && selectedNote ? `${selectedNote?.noteId}` : null;
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      ModalCloseOption.status = "ERROR";
    }
    handleClose(ModalCloseOption);
  };

  const validateForm = (): boolean => {
    return !noteText.trim();
  };

  return (
    <Dialog
      aria-label={intl.formatMessage({ id: "noteTextField" })}
      fullWidth
      maxWidth="xs"
      onClose={() => handleClose({ id: null, status: "CLOSED" })}
      open={openState}
    >
      <DialogTitle disableTypography>
        <Typography component="h1" variant="h6">
          {intl.formatMessage({ id: `${selectedNote ? "editNote" : "addNote"}` })}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form id="alertNoteForm" method="POST" onSubmit={handleSubmit}>
          <TextField
            data-testid="NoteText"
            fullWidth
            inputProps={{
              "aria-label": intl.formatMessage({ id: "typeNoteHere" }),
            }}
            label={intl.formatMessage({ id: "typeNoteHere" })}
            margin="normal"
            multiline
            onChange={(event) => setNoteText(event.target.value)}
            rows={5}
            value={noteText}
            variant="filled"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={includeInNotification}
                color="primary"
                onChange={(e) => setIncludeInNotification(e.target.checked)}
              />
            }
            label={intl.formatMessage({ id: "includeInNotifications" })}
          />
          <DialogActions>
            <Button onClick={() => handleClose({ id: null, status: "CLOSED" })} size="small" variant="text">
              {intl.formatMessage({ id: "cancel" })}
            </Button>
            <Button
              color="primary"
              disabled={validateForm()}
              form="alertNoteForm"
              size="small"
              type="submit"
              variant="contained"
            >
              {intl.formatMessage({ id: `${selectedNote ? "save" : "create"}` })}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};
export default NoteModal;
