import { MoRef } from "../../utilities/TopologyUtility";
import { ICustomChartConfig, ICustomDashboardWidgetPosition } from "./types";
import { ICustomDashboard, ICustomDashboardWidget } from "./useDashboards";

/**
 * Checks to see if the dashboard format will be valid if the new widget is added
 * @param dashboard - the dashboard to be updated
 * @param widget - the widget to be added
 */
export function validateDashboardFormat(
  dashboard: ICustomDashboard,
  newWidgetPosition?: ICustomDashboardWidgetPosition,
): boolean {
  if (newWidgetPosition) {
    const relevantWidgets = dashboard.widgets.filter((w) => w.position.row === newWidgetPosition.row); //get the list of widgets on the row being added to
    return relevantWidgets.length < 4; //if there are less than 4 widgets, we have space for the new widget
  } else {
    return true; //if position is undefined, we add it to the bottom of the grid, which is always valid
  }
}

/**
 * Gets the highest row value. I.E. if there are 3 rows of widgets then this will return 3
 * @param dashboard - the dashboard being evaluated
 */
export function getHighestRowValue(dashboard: ICustomDashboard): number {
  if (dashboard.widgets.length > 0) {
    const lastWidgetRow = dashboard.widgets.reduce((prev, curr) =>
      prev.position.row > curr.position.row ? prev : curr,
    );
    return lastWidgetRow.position.row;
  } else return 0;
}

/**
 * This function removes any empty rows and expands widgets to fill any newly freed open space
 * @param dashboard - the dashboard being evaluated
 * @param maxRowNum - the row with highest number
 */
export function positionAndExpandWidgets(dashboard: ICustomDashboard, maxRowNum: number): ICustomDashboard | null {
  const adjustedRows: ICustomDashboardWidget[] = [];
  for (let i = 1, rowDecrement = 0; i <= maxRowNum; i++) {
    // We only want the widgets in the current row iteration
    const itemsInRow = dashboard.widgets.filter((row) => row.position.row === i);
    // if the length is 0 that means there are no widgets in this row
    if (itemsInRow.length === 0) rowDecrement = 1;
    else if (itemsInRow.length > 4) return null;
    else {
      // we want to keep the widgets order consistent so we sort to make sure they're in the right order
      itemsInRow.sort((a, b) => a.position.column - b.position.column);
      const widthPerWidget = 12 / itemsInRow.length;
      itemsInRow.forEach((row, index) => {
        adjustedRows.push({
          ...row,
          position: {
            ...row.position,
            column: index * widthPerWidget + 1,
            row: row.position.row - rowDecrement,
            width: widthPerWidget,
          },
        });
      });
    }
  }
  return { ...dashboard, widgets: adjustedRows };
}

/**
 * Shifts all of the rows that come after the newly added row down one spot
 * @param dashboard - the dashboard to be updated
 * @param newlyPlacedWidget - the widget that was just placed on a new row
 */
export function shiftRowsBelowWidget(
  dashboard: ICustomDashboard,
  newlyPlacedWidget: ICustomDashboardWidget,
): ICustomDashboard {
  return {
    ...dashboard,
    widgets: dashboard.widgets.map((row) => {
      // We don't need to update the row of the widget we just added
      if (row.id === newlyPlacedWidget.id) return row;
      // We only need to update the row if the new row comes before newly added row
      else if (row.position.row >= newlyPlacedWidget.position.row)
        return { ...row, position: { ...row.position, row: row.position.row + 1 } };
      else return row;
    }),
  };
}

/**
 * Updates a specified widget
 * @param dashboard - the dashboard with the widget to be udpated
 * @param id - the id of the widget we want to update
 * @param newChartConfig - the chart config with information that will be updated in the widget
 */
export function updateWidgetInformation(
  dashboard: ICustomDashboard,
  id: number,
  newChartConfig: ICustomChartConfig,
): ICustomDashboard {
  const updatedDashboard = {
    ...dashboard,
    widgets: dashboard.widgets.map((x) => {
      if (x.id === id) {
        return {
          ...x,
          options: {
            customTimeRange: newChartConfig.customTimeRange,
            series: newChartConfig.performanceCounters.map((series) => ({ ...series, moRef: series.moRef })),
          },
          title: newChartConfig.chartName,
        };
      }
      return x;
    }),
  };
  return updatedDashboard;
}

/**
 * removeds the specified widget from the dashboard and returns a formatted dashboard without that widget
 * @param dashboard the dashboard with the widget to be removed
 * @param widgetId the id of the widget to be removed
 */
export function removeWidget(dashboard: ICustomDashboard, widgetId: number): ICustomDashboard | null {
  const updatedDashboard = { ...dashboard, widgets: dashboard.widgets.filter((x) => x.id !== widgetId) };
  if (updatedDashboard.widgets.length === dashboard.widgets.length) {
    return null;
  }
  // don't know if widget was on it's own row or not so check to see what the new highest row number is
  const highestRowForNewRows = getHighestRowValue(updatedDashboard);
  return positionAndExpandWidgets(updatedDashboard, highestRowForNewRows);
}
