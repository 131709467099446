import * as React from "react";
import Badge from "@material-ui/core/Badge";
import SiteIcon from "@material-ui/icons/GroupWork";
import TenantIcon from "@material-ui/icons/Storage";
import { Link } from "react-router-dom";
import TreeItem from "@material-ui/lab/TreeItem";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { ITreeItem } from "./types";
import Tooltip from "@material-ui/core/Tooltip";
import { DarkModeDivider } from "../../../SidebarContent/SidebarContent";
import TargetIcon from "../../../../../../../components/icons/TargetIcon";

interface ITopologyTreeItemProps {
  treeItem: ITreeItem;
  childItems: Array<JSX.Element>;
}

const useStyles = makeStyles((theme) => ({
  greyText: {
    color: "var(--sidebar-dark-text-grey)",
  },
  parentOfSelectedItem: {
    fontWeight: theme.typography.h2.fontWeight,
  },
  statusDot: {
    bottom: "2px",
    boxShadow: "0 0 0 1.5px var(--sidebar-dark-background)", // the ring around the status indicator
    right: "1px",
  },
  statusDotError: {
    backgroundColor: theme.palette.error.main,
  },
  statusDotReady: {
    backgroundColor: theme.palette.success.main,
  },
  treeItemContent: {
    height: "30px",
  },
  treeItemLabel: {
    alignItems: "center",
    display: "flex",
    gap: theme.spacing(1),
    height: "100%",
    whiteSpace: "nowrap",
  },
  treeItemLabelContainer: {
    "&:hover": {
      backgroundColor: "var(--sidebar-dark-item-hover-soft-background)",
    },
    borderRadius: theme.shape.borderRadius,
    height: "100%",
    overflow: "hidden",
    padding: theme.spacing(0, 1),
    textOverflow: "ellipsis",
    transition: "background-color 100ms",
    userSelect: "none",
  },
  treeItemLabelText: {
    fontSize: theme.typography.body2.fontSize,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  treeItemRoot: {
    // this mess styles a selected item including when it is hovered or focused
    "&$treeItemSelected > $treeItemContent $treeItemLabelContainer,\
       &$treeItemSelected:focus > $treeItemContent $treeItemLabelContainer, \
       &$treeItemSelected > $treeItemContent $treeItemLabelContainer:hover": {
      backgroundColor: "var(--sidebar-dark-treeItem-active-background)",
    },
    // this adds an accessiblity ring for focused items to be expanded and collapsed using the keyboard
    "&:focus-visible > $treeItemContent": {
      boxShadow: "0px 0px 0px 3px var(--sidebar-dark-light-blue)",
    },
  },
  treeItemSelected: {},
  unwatchedTarget: {
    color: "var(--sidebar-dark-text-grey)",
    fontStyle: "italic",
  },
  whiteText: {
    color: "var(--sidebar-dark-text-white)",
  },
}));

const TopologyTreeItem = ({ treeItem, childItems }: ITopologyTreeItemProps): React.ReactElement => {
  const classes = useStyles();

  let itemIcon: JSX.Element | null = null;

  switch (treeItem.type) {
    case "tenant":
      itemIcon = (
        <Badge
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          classes={{
            dot: classNames(classes.statusDot, treeItem.hasErrors ? classes.statusDotError : classes.statusDotReady),
          }}
          overlap="rectangular"
          variant="dot"
        >
          <TenantIcon fontSize="inherit" />
        </Badge>
      );
      break;
    case "site":
    case "group":
      itemIcon = <SiteIcon fontSize="inherit" />;
      break;
    case "device":
    case "esc":
      itemIcon = (
        <TargetIcon
          activeTargetType={treeItem.type === "device" ? treeItem.deviceType : treeItem.eventSourceConnectionType}
          fontSize="inherit"
        />
      );
  }

  const treeItemClasses = [classes.treeItemLabel];
  switch (treeItem.type) {
    case "tenant":
    case "site":
    case "group":
      treeItemClasses.push(treeItem.isSelected ? classes.whiteText : classes.greyText);
      if (treeItem.type === "tenant") {
        treeItemClasses.push(classes.parentOfSelectedItem);
      }
      break;
    case "device":
    case "esc":
      treeItemClasses.push(treeItem.isWatched ? classes.whiteText : classes.unwatchedTarget);
      break;
  }

  return (
    <TreeItem
      classes={{
        content: classes.treeItemContent,
        label: classes.treeItemLabelContainer,
        root: classes.treeItemRoot,
        selected: classes.treeItemSelected,
      }}
      label={
        // random key makes sure tooltip is closed when selecting an item
        <Tooltip arrow enterDelay={500} key={Math.random()} placement="right" title={treeItem.name}>
          {treeItem.isSelected ? (
            <div className={classNames(treeItemClasses)}>
              {itemIcon}
              <span className={classes.treeItemLabelText}>{treeItem.name}</span>
            </div>
          ) : (
            <Link className={classNames(treeItemClasses)} to={treeItem.to}>
              {itemIcon}
              <span className={classes.treeItemLabelText}>{treeItem.name}</span>
            </Link>
          )}
        </Tooltip>
      }
      nodeId={treeItem.id}
      // allows nodes with children to be tabbed to and allow for expanding and collapsing using the keyboard
      // if a node does not have children then we skip the node and focus the link if applicable
      tabIndex={childItems.length > 0 ? 0 : -1}
    >
      {/* only add the divider if there are children */}
      {childItems.length > 0 ? (
        <>
          <DarkModeDivider />
          {childItems}
        </>
      ) : (
        childItems
      )}
    </TreeItem>
  );
};

export default TopologyTreeItem;
