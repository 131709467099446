import { makeStyles } from "@material-ui/core/styles";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

const useStyles = makeStyles({
  "@keyframes pulse": {
    "0%": {
      opacity: 0,
    },
    "50%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0,
    },
  },
  speedLines: {
    animationDuration: "1s",
    animationIterationCount: "infinite",
    animationName: "$pulse",
    animationTimingFunction: "linear",
  },
});

const ErrorIcon: React.FunctionComponent<SvgIconProps> = (props) => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 80 80" {...props}>
      <path
        d="M15.391 61L12.0895 21.0824C12.0413 20.4996 12.5013 20 13.0861 20H21.0792H27.8166C28.1814 20 28.5173 20.1987 28.693 20.5185L31.3138 25.2887C31.4895 25.6085 31.8254 25.8072 32.1902 25.8072H69.0302C69.5825 25.8072 70.0302 26.2549 70.0302 26.8072V33.4157M15.391 61L24.0285 34.1099C24.1613 33.6963 24.5461 33.4157 24.9805 33.4157H70.0302M15.391 61H69.3038C69.737 61 70.1209 60.7211 70.2548 60.3092L78.5743 34.725C78.7844 34.0788 78.3027 33.4157 77.6233 33.4157H70.0302"
        stroke="currentColor"
        strokeWidth="2"
      />
      <ellipse cx="45.131" cy="42.8166" fill="currentColor" rx="1.5" ry="2.5" transform="rotate(16 45.131 42.8166)" />
      <ellipse cx="60.131" cy="42.8166" fill="currentColor" rx="1.5" ry="2.5" transform="rotate(16 60.131 42.8166)" />
      <path
        d="M42.5 38C46 38.5 47 39.4541 49.5 40.4542M57 40.4542C59.1783 39.1362 60.6169 38.3123 63.5 38.5M40 50C42 52 43.5 55 52 54M39 52L40 49.5H42.5"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        className={classes.speedLines}
        d="M10.5 35H5.5M10.5 40.5H0M10.5 46H4"
        stroke="currentColor"
        strokeWidth="2"
      />
    </SvgIcon>
  );
};

export default ErrorIcon;
