import { useQuery } from "@apollo/react-hooks";
import * as React from "react";
import BlockingContext from "./blockingContext";
import * as GET_BLOCKERS from "./filterQuery.graphql";
import { IBlockersByType, IBlockingFilter, IBlockingQuery } from "./types";
import { useCurrentTarget } from "../../hooks/useCurrentTarget";
import { TopologyObjectType } from "../../api/PerformanceAdvisor";

const BlockingContextProvider: React.FunctionComponent = ({ children }) => {
  const target = useCurrentTarget();
  if (!target) {
    throw Error("target was undefined when determining blocking filters");
  }
  if (target.type != TopologyObjectType.eventSourceConnection) {
    throw Error("target was not an event source connection when determining blocking filters");
  }
  const { loading, error, data } = useQuery<IBlockingQuery>(GET_BLOCKERS, {
    variables: {
      esID: target.itemId,
    },
  });

  const [blockingFilter, setBlockingFilter] = React.useState<IBlockingFilter>({
    duration: {
      unit: "Minutes",
      value: 15,
    },
    selectedApplications: null,
    selectedDatabases: null,
    selectedHosts: null,
    selectedResource: null,
    selectedResourceType: null,
  });

  const handleSetFilter = (filter: Partial<IBlockingFilter>): void => {
    setBlockingFilter((prev) => ({
      ...prev,
      ...filter,
    }));
  };

  const [blockersByType, setBlockersByType] = React.useState<IBlockersByType>({
    byApplications: [],
    byDatabases: [],
    byHosts: [],
  });

  React.useMemo(() => {
    if (data) {
      const byApplications = data.blocking.programNames.filter((name) => !!name);
      const byHosts = data.blocking.hostNames.filter((name) => !!name);
      const byDatabases = data.blocking.databaseNames.filter((name) => !!name);

      setBlockersByType({
        byApplications,
        byDatabases,
        byHosts,
      });
    } else {
      setBlockersByType({
        byApplications: [],
        byDatabases: [],
        byHosts: [],
      });
    }
  }, [data]);

  const filterApplied =
    !!blockingFilter.selectedApplications ||
    !!blockingFilter.selectedDatabases ||
    !!blockingFilter.selectedHosts ||
    !!blockingFilter.selectedResource ||
    !!blockingFilter.selectedResourceType;
  return (
    <BlockingContext.Provider
      value={{
        blockersByType,
        blockingFilter,
        data,
        error,
        filterApplied,
        loading,
        setBlockingFilter: handleSetFilter,
      }}
    >
      {children}
    </BlockingContext.Provider>
  );
};

export default BlockingContextProvider;
