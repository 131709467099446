import { AsyncOptions, PromiseFn, useAsync, AsyncState, AbstractState } from "react-async";
export * from "react-async";

function useAsyncCustom<T>(arg1: AsyncOptions<T> | PromiseFn<T>, arg2?: AsyncOptions<T>): AsyncState<T, AbstractState<T>> {
  if (typeof arg1 === "function") {
    return useAsync(arg1, arg2); // eslint-disable-line react-hooks/rules-of-hooks
  } else {
    return useAsync(arg1); // eslint-disable-line react-hooks/rules-of-hooks
  }
}

export { useAsyncCustom as useAsync };
