import { ApolloProvider } from "@apollo/react-hooks";
import ApolloClient from "apollo-boost";
import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import * as React from "react";
import { useRouteMatch } from "react-router-dom";

/**
 * Regex for matching and extracting the tenant ID from the route.
 */
const tenantRoutePartRegex = /^\/(.*?)(\/.*)?$/;

// Apollo needs to know how the schema defines union types.
// This could be autogenrated by schema introspection at build-time.
// Apollo Client 3 simplifies this a bit as well.
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: "UNION",
          name: "TargetSettings",
          possibleTypes: [{ name: "AzureSqlDbTargetSettings" }, { name: "SqlServerTargetSettings" }],
        },
      ],
    },
  },
});

/**
 * Exported for tests using MockedProvider to consume the same cache.
 * Primarily needed for tests that rely on the fragmentMatcher's configuration.
 */
export function makeApolloCache(): InMemoryCache {
  return new InMemoryCache({ fragmentMatcher });
}

function useTenantId(): string {
  const match = useRouteMatch<{ tenantId: string }>("/:tenantId");
  if (match) {
    return match.params.tenantId;
  } else {
    return "";
  }
}

const ApolloEnvironment: React.FunctionComponent = ({ children }) => {
  const tenantId = useTenantId();
  const client = React.useMemo(() => {
    return new ApolloClient({
      cache: makeApolloCache(),
      headers: {
        "X-Tenant-ID": tenantId,
      },
      uri: "/api/graphql",
    });
  }, [tenantId]);
  (window as any).__APOLLO_CLIENT__ = client; // Used by the Apollo Chrome extension
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloEnvironment;
