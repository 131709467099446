import Button from "@material-ui/core/Button";
import * as React from "react";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
import DocumentTitle from "../../../../components/DocumentTitle";
import NoDataIcon from "../../../../components/icons/NoDataIcon";

const useStyles = makeStyles((theme) => ({
  content: {
    textAlign: "center",
  },
  link: {
    fontSize: theme.typography.h5.fontSize,
  },
  placeholder: {
    fill: "none",
    height: "25rem",
    margin: "auto",
    maxHeight: "100%",
    width: "25rem",
  },
  root: {
    alignItems: "center",
    display: "grid",
    gridColumnGap: theme.spacing(2),
    gridTemplateColumns: "repeat(auto-fit, 50rem)",
    justifyContent: "center",
    marginTop: theme.spacing(3),
  },
  text: {
    fontSize: "1.5rem",
  },
  title: {
    fontWeight: theme.typography.h2.fontWeight,
  },
}));

const NoTargets: React.FC = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      <DocumentTitle title="noTargetsAreBeingMonitored" />
      <div className={classes.root}>
        <div className={classes.content}>
          <Typography className={classes.title} component="h1" gutterBottom variant="h2">
            {intl.formatMessage({ id: "noTargetsAreBeingMonitored" })}
          </Typography>
          <Typography className={classes.text} component="p" gutterBottom>
            {intl.formatMessage({ id: "addTargetsByFollowingThisLink" })}
          </Typography>
          <Button
            className={classes.link}
            color="primary"
            href="https://docs.sentryone.com/help/monitor-additional-targets"
            rel="noopener noreferrer"
            startIcon={<OpenInNewIcon />}
            target="_blank"
            variant="text"
          >
            {intl.formatMessage({ id: "viewDocumentation" })}
          </Button>
        </div>
        <NoDataIcon className={classes.placeholder} />
      </div>
    </>
  );
};

export default NoTargets;
