import { TopologyObjectType } from "../../api/PerformanceAdvisor";
import { ITargetFeatures, ITopology } from "./types";

export function makeTopology({
  devices,
  eventSourceConnections,
  groups,
  ...rest
}: Pick<ITopology, "devices" | "eventSourceConnections" | "groups"> & Partial<ITopology>): ITopology {
  return {
    devices,
    eventSourceConnections,
    findByItemId(id, type) {
      switch (type) {
        case TopologyObjectType.device:
          return devices.find((d) => d.itemId === id);
        case TopologyObjectType.eventSourceConnection:
          return eventSourceConnections.find((e) => e.itemId === id);
        case TopologyObjectType.group:
          return groups.find((g) => g.type === type && g.itemId === id);
        case TopologyObjectType.site:
          return groups.find((g) => g.type === type && g.itemId === id);
        default:
          throw new Error(`Cannot find topology item by item ID for object type ${type}`);
      }
    },
    findByMoRef(moRef) {
      const id = Number(moRef.id);
      switch (moRef.type) {
        case "connection":
          return eventSourceConnections.find((e) => e.itemId === id) ?? null;
        case "device":
          return devices.find((d) => d.itemId === id) ?? null;
        case "group":
        case "site":
          return groups.find((d) => d.itemId === id) ?? null;
      }
    },
    findByObjectId(id, type) {
      switch (type) {
        case TopologyObjectType.device:
          return devices.find((d) => d.objectId === id);
        case TopologyObjectType.eventSourceConnection:
          return eventSourceConnections.find((e) => e.objectId === id);
        case TopologyObjectType.group:
          return groups.find((g) => g.type === type && g.objectId === id);
        case TopologyObjectType.site:
          return groups.find((g) => g.type === type && g.objectId === id);
        default:
          return (
            devices.find((f) => f.objectId === id) ||
            eventSourceConnections.find((e) => e.objectId === id) ||
            groups.find((g) => g.objectId === id)
          );
      }
    },
    findByParentObjectId(
      id: string,
      type?:
        | TopologyObjectType.device
        | TopologyObjectType.eventSourceConnection
        | TopologyObjectType.group
        | TopologyObjectType.site,
    ) {
      switch (type) {
        case TopologyObjectType.device:
          return devices.filter((d) => d.parentObjectId === id);
        case TopologyObjectType.eventSourceConnection:
          return eventSourceConnections.filter((e) => e.parentObjectId === id);
        case TopologyObjectType.group:
        case TopologyObjectType.site:
          return groups.filter((g) => g.type === type && g.parentObjectId === id);
        default:
          return [
            ...devices.filter((d) => d.parentObjectId === id),
            ...eventSourceConnections.filter((e) => e.parentObjectId === id),
            ...groups.filter((g) => g.parentObjectId === id),
          ];
      }
    },
    groups,
    loading: false,
    reload() {
      return;
    },
    shouldShowFeature(feature: keyof ITargetFeatures): boolean {
      return [...devices, ...eventSourceConnections, ...groups].some((x) => x.features[feature]);
    },
    tenantErrors: new Map(),
    ...rest,
  };
}
