import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { LoadingIndicator } from "@sentryone/material-ui";
import * as React from "react";
import { useIntl } from "react-intl";
import { useDeadlocksContext, IDeadlockResource, IDeadlockProcess } from "../../../../contexts/deadlocksContext";

const useStyles = makeStyles((theme) => ({
  ellipsis: {
    maxWidth: theme.spacing() * 46,
    minWidth: 0,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  placeholder: {
    flexGrow: 1,
    height: 0,
    maxWidth: "100%",
    minHeight: "1rem",
    textAlign: "center",
  },
  spid: {
    verticalAlign: "inherit",
    whiteSpace: "nowrap",
  },
  tableRoot: {
    "& td": {
      verticalAlign: "middle",
    },
  },
}));

const isNodeProcess = (node: IDeadlockProcess | IDeadlockResource): node is IDeadlockProcess => {
  return (node as IDeadlockProcess).spid !== undefined;
};

const DeadlockDetailRow: React.FC = () => {
  const classes = useStyles();
  const intl = useIntl();

  const { loading, selectedDeadlock, loadingDeadlock, selectedNode } = useDeadlocksContext();

  if (!selectedNode || !selectedDeadlock) {
    return (
      <Table aria-label="Lock Information" className={classes.tableRoot} size="small">
        <TableHead>
          <TableRow>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.placeholder}>
              {loading || loadingDeadlock ? <LoadingIndicator /> : intl.formatMessage({ id: "noDataToDisplay" })}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  } else if (isNodeProcess(selectedNode)) {
    return (
      <TableContainer>
        <Table aria-label="Lock Information" className={classes.tableRoot} size="small">
          <TableHead>
            <TableRow>
              <TableCell>{`${intl.formatMessage({ id: "spid" })} [${intl.formatMessage({ id: "ecid" })}]`}</TableCell>
              <TableCell>{intl.formatMessage({ id: "host" })}</TableCell>
              <TableCell>{intl.formatMessage({ id: "application" })}</TableCell>
              <TableCell>{intl.formatMessage({ id: "database" })}</TableCell>
              <TableCell>{intl.formatMessage({ id: "login" })}</TableCell>
              <TableCell>{intl.formatMessage({ id: "logUsed" })}</TableCell>
              <TableCell>{intl.formatMessage({ id: "deadlockPriority" })}</TableCell>
              <TableCell>{intl.formatMessage({ id: "waitTime" })}</TableCell>
              <TableCell>{intl.formatMessage({ id: "lockMode" })}</TableCell>
              <TableCell>{intl.formatMessage({ id: "isolationLevel" })}</TableCell>
              <TableCell>{intl.formatMessage({ id: "transName" })}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading || loadingDeadlock ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <LoadingIndicator className={classes.placeholder} variant="table" />
                </TableCell>
              </TableRow>
            ) : (
              <TableRow key={selectedNode.id}>
                <TableCell className={classes.spid}>{`${selectedNode.spid} [${selectedNode.ecid}]`}</TableCell>
                <TableCell className={classes.ellipsis} title={selectedNode.hostName}>
                  {selectedNode.hostName}
                </TableCell>
                <TableCell className={classes.ellipsis} title={selectedNode.clientApp}>
                  {selectedNode.clientApp}
                </TableCell>
                <TableCell className={classes.ellipsis} title={selectedNode.databaseName}>
                  {selectedNode.databaseName}
                </TableCell>
                <TableCell className={classes.ellipsis} title={selectedNode.loginName}>
                  {selectedNode.loginName}
                </TableCell>
                <TableCell>{intl.formatNumber(selectedNode.logUsed)}</TableCell>
                <TableCell>{intl.formatNumber(selectedNode.priority)}</TableCell>
                <TableCell>{intl.formatNumber(selectedNode.waitTime)}</TableCell>
                <TableCell>{selectedNode.lockMode}</TableCell>
                <TableCell className={classes.ellipsis} title={selectedNode.isolationLevel ?? ""}>
                  {selectedNode.isolationLevel}
                </TableCell>
                <TableCell className={classes.ellipsis} title={selectedNode.transactionName}>
                  {selectedNode.transactionName}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return (
      <TableContainer>
        <Table aria-label="Lock Information" className={classes.tableRoot} size="small">
          <TableHead>
            <TableRow>
              <TableCell>{intl.formatMessage({ id: "lockType" })}</TableCell>
              <TableCell>{intl.formatMessage({ id: "object" })}</TableCell>
              <TableCell>{intl.formatMessage({ id: "index" })}</TableCell>
              <TableCell>{intl.formatMessage({ id: "waitResource" })}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading || loadingDeadlock ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <LoadingIndicator className={classes.placeholder} variant="table" />
                </TableCell>
              </TableRow>
            ) : (
              <TableRow key={selectedNode.id}>
                <TableCell className={classes.noWrap}>
                  {intl.formatMessage({ defaultMessage: selectedNode.type, id: selectedNode.type })}
                </TableCell>
                <TableCell className={classes.ellipsis} title={selectedNode.objectName}>
                  {selectedNode.objectName}
                </TableCell>
                <TableCell className={classes.ellipsis} title={selectedNode.indexName ?? undefined}>
                  {selectedNode.indexName}
                </TableCell>
                <TableCell className={classes.ellipsis} title={selectedNode.waiters[0]?.process.waitResource}>
                  {selectedNode.waiters[0]?.process.waitResource}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
};

export default DeadlockDetailRow;
