import { DateTime } from "luxon";
import * as React from "react";
import { formatDate } from "../../../../../components/FormattedDate";
import NoUiSlider from "../../../../../components/NoUiSlider";
import { IDateRange, IQueryHistoryOptions } from "../types";
const { useCallback, useMemo } = React;

interface IRangeSliderProps {
  availableDateRange: IDateRange;
  className?: string;
  disabled: boolean;
  onChange: (value: Readonly<IQueryHistoryOptions>) => void;
  value: Readonly<IQueryHistoryOptions>;
}

const format = {
  from: (value: string) => {
    return DateTime.fromString(value, "yyyy-MM-dd", {
      zone: "local",
    }).toMillis();
  },
  to: (value: number) => {
    const date = new Date(value);
    return formatDate(date, {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  },
};

const tooltipFormat = {
  from: (value: string) => {
    return DateTime.fromString(value, "yyyy-MM-dd", {
      zone: "local",
    }).toMillis();
  },
  to: (value: number) => {
    const date = new Date(value);
    return formatDate(date, {
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  },
};

const tooltips = [tooltipFormat, tooltipFormat];

const RangeSlider: React.FunctionComponent<IRangeSliderProps> = ({
  availableDateRange,
  className,
  disabled,
  onChange,
  value,
}) => {
  const { from: min, to: max } = availableDateRange;
  const { from: valueFrom, to: valueTo } = value.dateRange;

  const handleChange = useCallback((values: [number, number]) => {
    onChange({
      ...value,
      dateRange: {
        from: new Date(values[0]),
        to: new Date(values[1]),
      },
    });
  }, [onChange, value]);
  const noUiValues = useMemo<[number, number]>(() => {
    return [valueFrom.getTime(), valueTo.getTime()];
  }, [valueFrom, valueTo]);
  const range = useMemo(() => {
    return {
      max: max.getTime(),
      min: min.getTime(),
    };
  }, [max, min]);

  return (
    <NoUiSlider
      behaviour="drag"
      className={className}
      connect
      disabled={disabled}
      format={format}
      onChange={handleChange}
      range={range}
      tooltips={tooltips}
      value={noUiValues}
    />
  );
};

export default RangeSlider;
