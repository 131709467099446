import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useIntl } from "react-intl";
import { useLocalStorageState } from "../../../../../hooks/useLocalStorageState";
import FeatureNavigationGroup from "../FeatureNavigation";
import TopologyList from "../TopologyList";

export interface ISidebarContentProps {
  sidebarOpen?: boolean;
}

const useStyles = makeStyles((theme) => ({
  version: {
    "& p": {
      color: "var(--sidebar-dark-text-grey)",
    },
    alignItems: "center",
    display: "grid",
    fontWeight: "normal",
    height: theme.spacing(9),
    padding: theme.spacing(0, 4),
  },
}));

export const DarkModeDivider = withStyles({
  root: {
    backgroundColor: "var(--sidebar-dark-divider)",
  },
})(Divider);

const SidebarContent: React.FunctionComponent<ISidebarContentProps> = ({ sidebarOpen = true }) => {
  const intl = useIntl();
  const classes = useStyles();
  const [hierarchy, setHierarchy] = useLocalStorageState<boolean>("showHierarchy", false);

  const version = (window as any).APPLICATION_VERSION as string;

  return (
    <>
      <FeatureNavigationGroup setHierarchy={setHierarchy} showHierarchy={hierarchy} sidebarOpen={sidebarOpen} />
      {sidebarOpen && (
        <>
          <TopologyList showHierarchy={hierarchy} />

          {version && (
            <>
              <DarkModeDivider />
              <div className={classes.version}>
                <Typography component="p">{intl.formatMessage({ id: "applicationVersion" }, { version })}</Typography>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default SidebarContent;
