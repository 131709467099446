if (typeof require.context !== "function") {
  require.context = () => {
    return {
      keys() {
        return [] as string[];
      },
    } as __WebpackModuleApi.RequireContext;
  };
}

const req = require.context("./", false, /\.png/i); // Needed because GoJS templates can't run async methods
const iconMap = req.keys().reduce<Record<string, string>>((prev, curr) => {
  const key = curr.toLowerCase().substr(2, curr.length - 6);
  prev[key] = req(curr);
  return prev;
}, {});

export default function getPlanDiagramIconPath(key: string): string {
  return iconMap[key.toLowerCase()];
}
