import { distance } from "fastest-levenshtein";
import { IMatchedPlanStatement, IPlanData, IPlanStatement } from "../../../../../api/TopSqlService/models/PlanData";

export interface IStatementInfo {
  queryHash?: string | null;
  textData: string;
}

/**
 * Finds the matching plan statement for the specified statement information.
 * @param statementInfo Information about the statement being matched.
 * @param planData Plan data containing statements.
 */
export function findMatchingStatement(
  { queryHash, textData }: IStatementInfo,
  planData: IPlanData,
): IMatchedPlanStatement | null {
  const statementsWithPlan = planData.hybridStatements.flatMap(getAllStatementsWithPlan);
  const match =
    // Only one plan statement, so always return it
    findBySingleStatement(statementsWithPlan) ??
    // Query hash exists, which is the highest confidence match
    (queryHash ? findByQueryHash(queryHash, statementsWithPlan) : null) ??
    // Match by statement text as fallback
    (textData ? findByStatementText(textData, statementsWithPlan) : null);
  return match ? { planStatement: match, planToken: planData.planToken } : null;
}

/**
 * Attempts to find a matching plan statement by query hash.
 * @param queryHash The query hash to search for.
 * @param statementsWithPlan Plan statements to search.
 * @returns The matching plan statement if found, otherwise null.
 */
function findByQueryHash(queryHash: string, statementsWithPlan: readonly IPlanStatement[]): IPlanStatement | null {
  const planStatement = statementsWithPlan.find((x) => x.queryHash === queryHash) ?? null;
  /* istanbul ignore if */
  if (process.env.NODE_ENV === "development") {
    // Log that query hash was used for debugging
    // This gets removed from the production bundle by the if above
    if (planStatement) {
      console.info(`findByQueryHash: Query hash ${queryHash} used. Found matching plan with ID ${planStatement.id}.`);
    } else {
      console.info(
        `findByQueryHash: Query hash ${queryHash} not found in [${statementsWithPlan
          .map((x) => x.queryHash)
          .join(", ")}]. Plan could not be matched.`,
      );
    }
  }
  return planStatement;
}

/**
 * Checks if the list of statements contains only a single usable plan and returns it.
 * @param statementsWithPlan Plan statements to search.
 * @returns The matching plan statement if found, otherwise null.
 */
function findBySingleStatement(statementsWithPlan: readonly IPlanStatement[]): IPlanStatement | null {
  const planStatement = statementsWithPlan.length === 1 ? statementsWithPlan[0] : null;
  /* istanbul ignore if */
  if (process.env.NODE_ENV === "development") {
    // Log that single-statement was used for debugging
    // This gets removed from the production bundle by the if above
    if (planStatement) {
      console.info(
        `findBySingleStatement: Single plan statement available. Found matching plan with ID ${planStatement.id}.`,
      );
    } else {
      console.info(
        `findBySingleStatement: ${statementsWithPlan.length} plan statements available. Plan could not be matched.`,
      );
    }
  }
  return planStatement;
}

/**
 * Attempts to find a matching plan statement by statement text.
 * The statement text is normalzied and the plan statements are ranked based on confidence of the match.
 * @param statementText The statement text to search for.
 * @param statementsWithPlan Plan statements to search.
 * @returns The matching plan statement if found, otherwise null.
 */
function findByStatementText(
  statementText: string,
  statementsWithPlan: readonly IPlanStatement[],
): IPlanStatement | null {
  const normalizedStatement = normalizeStatement(statementText);
  // Determine scores for all the plans for the selected statement.
  // Scores represent the "distance" (number of changes needed to match), so lower is better.
  // Confidence is determined by the score compared to the length of the strings.
  const scores = statementsWithPlan
    .filter((x): x is IPlanStatement & { text: string } => !!x.text)
    .map<[IPlanStatement, number, number, boolean]>((x) => {
      const normalizedPlanStatement = normalizeStatement(x.text);
      const score = distance(normalizedStatement, normalizedPlanStatement);
      const confidence = 1 - score / Math.max(normalizedStatement.length, normalizedPlanStatement.length);
      const acceptable = confidence > 0.8;
      return [x, score, confidence, acceptable];
    })
    .sort((a, b) => a[1] - b[1]);
  const match = scores.find(([, , , acceptable]) => acceptable);
  /* istanbul ignore if */
  if (process.env.NODE_ENV === "development") {
    // Log the scoring for help in debugging
    // This gets removed from the production bundle by the if above
    console.info("findByStatementText: Using ranked text matching to find matching plan statement.");
    console.table(
      scores.map(([x, score, confidence, acceptable]) => {
        return {
          acceptable,
          confidence,
          normalizedText: normalizeStatement(x.text ?? ""),
          score,
          text: x.text,
        };
      }),
      // Set the order of the columns so that is easier to scan
      ["text", "normalizedText", "score", "confidence", "acceptable"],
    );
  }
  return match ? match[0] : null;
}

/**
 * Recursively explores a statement tree and returns statements with plans from all depths.
 * @param statement
 */
function getAllStatementsWithPlan(statement: IPlanStatement): readonly IPlanStatement[] {
  const children = (statement.children ?? []).flatMap((x) => getAllStatementsWithPlan(x));
  return statement.hasPlan ? [statement, ...children] : children;
}

/**
 * Normalizes a statement text to remove/replace common differences that result in identical statements not matching.
 * @param statementText The statement text to normalize.
 * @returns Normalized statement text.
 */
function normalizeStatement(statementText: string): string {
  let result = statementText;
  // Condense excess whitespace
  result = result.replace(/\s\s+/g, " ");
  // Replace multiline comments
  result = result.replace(/\/\*(?:.|\r|\n)*\*\//g, "");
  // Replace GUID literals
  result = result.replace(/'[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}'/g, "$");
  // Replace string literals
  result = result.replace(/'(?:.|\r|\n)*?'/g, "$");
  // Replace numeric literals
  result = result.replace(/\b[0-9]+\b/g, "#");
  return result;
}
