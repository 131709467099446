import { makeStyles } from "@material-ui/core/styles";
import SettingsIcon from "@material-ui/icons/Settings";
import LockIcon from "@material-ui/icons/Lock";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import WarningIcon from "@material-ui/icons/Warning";
import classNames from "classnames";
import * as React from "react";
import { useIntl } from "react-intl";
import { DeadlockResourceType, IDeadlockProcess, IDeadlockResource } from "../../../../contexts/deadlocksContext";

interface IDeadlockDiagramNodeProcessProps extends React.SVGProps<SVGSVGElement> {
  isSelected: boolean;
  process: IDeadlockProcess;
}

interface IDeadlockDiagramNodeResourceProps extends React.SVGProps<SVGSVGElement> {
  isSelected: boolean;
  resource: IDeadlockResource;
}

type IDeadlockDiagramNodeProps = IDeadlockDiagramNodeProcessProps | IDeadlockDiagramNodeResourceProps;

function isLockOrParallel(type: DeadlockResourceType | string): "lock" | "parallel" {
  if (type === DeadlockResourceType.ExchangeEvent || type === DeadlockResourceType.Threadpool) {
    return "parallel";
  } else {
    return "lock";
  }
}

const useStyles = makeStyles((theme) => ({
  background: {},
  icon: {},
  process: {
    "& $background": {
      fill: theme.palette.common.white,
      stroke: theme.palette.grey[500],
    },
    "& $icon": {
      color: theme.palette.success.main,
    },
    "& $spid": {
      fill: theme.palette.getContrastText(theme.palette.common.white),
    },
    "& $title": {
      fill: theme.palette.grey[600],
    },
  },
  resource: {
    "& $background": {
      fill: theme.palette.grey[400],
    },
  },
  root: {
    cursor: "pointer",
  },
  selected: {
    "& $background": {
      stroke: theme.palette.primary.main,
      strokeWidth: 0.5,
    },
  },
  spid: {},
  title: {
    textTransform: "capitalize",
  },
  victim: {
    "& $background": {
      fill: theme.palette.error.dark,
    },
    "& $icon": {
      color: theme.palette.getContrastText(theme.palette.error.dark),
    },
    "& $spid, & $title": {
      fill: theme.palette.getContrastText(theme.palette.error.dark),
    },
  },
}));

const DeadlockDiagramNode: React.FC<IDeadlockDiagramNodeProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles();

  if ("process" in props) {
    const { className, isSelected, process, ...svgProps } = props;
    return (
      <svg
        {...svgProps}
        aria-current={isSelected}
        className={classNames(classes.root, classes[process.type], { [classes.selected]: isSelected }, className)}
        data-testid={`deadlock_process_${process.id}`}
      >
        <rect className={classes.background} height={9.5} rx={1} strokeWidth={0.25} width={23.5} x={0.25} y={0.25} />
        {process.type === "process" && <SettingsIcon className={classes.icon} height={5} width={5} x={1} y={2.5} />}
        {process.type === "victim" && <WarningIcon className={classes.icon} height={5} width={5} x={1} y={2.5} />}
        <text className={classes.title} fontSize={2.5} x={7} y={4}>
          {intl.formatMessage({ id: process.type })}
        </text>
        <text className={classes.spid} fontSize={2.5} x={7} y={7.5}>{`${process.spid} [${process.ecid}]`}</text>
      </svg>
    );
  } else {
    const { className, isSelected, resource, ...svgProps } = props;
    const typeMessage = intl.formatMessage({ defaultMessage: resource.type, id: resource.type });
    return (
      <svg
        {...svgProps}
        aria-current={isSelected}
        className={classNames(classes.root, classes.resource, { [classes.selected]: isSelected }, className)}
        data-testid={`deadlock_resource_${resource.id}`}
      >
        <rect className={classes.background} height={9.5} rx={1} strokeWidth={0.25} width={23.5} x={0.25} y={0.25} />
        {isLockOrParallel(resource.type) === "parallel" && (
          <SyncAltIcon className={classes.icon} height={5} width={5} x={1} y={2.5} />
        )}
        {isLockOrParallel(resource.type) === "lock" && (
          <LockIcon className={classes.icon} height={5} width={5} x={1} y={2.5} />
        )}
        {typeMessage.includes(" ") && typeMessage.length > 10 ? (
          typeMessage.split(" ").map((m, i) => (
            <text className={classes.title} fontSize={2.5} key={i} x={7} y={4 + i * 3.5}>
              {m}
            </text>
          ))
        ) : (
          <text className={classes.title} fontSize={2.5} x={7} y={5.75}>
            {typeMessage}
          </text>
        )}
      </svg>
    );
  }
};

export default DeadlockDiagramNode;
