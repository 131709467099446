import { DateTime } from "luxon";
import IDateTimeFormatOptions from "./IDateTimeFormatOptions";

// We use a custom formatting technique because the format requested from product does not match any standard format.
// The desired full-length format is `yyyy-MM-dd hh:mm:ss a`.
// To standardize this, most of the custom format parts are set to return 2-digit variations.

function getDayFormat(options: IDateTimeFormatOptions): string | undefined {
  switch (options.day) {
    case undefined:
      return undefined;
    case "2-digit":
    case "numeric":
      return "dd";
    default:
      throw new RangeError(`invalid value ${options.day} for option day`);
  }
}

function getHourFormat(options: IDateTimeFormatOptions): string | undefined {
  switch (options.hour) {
    case undefined:
      return undefined;
    case "2-digit":
    case "numeric":
      // Strict equality to miss undefined value
      return options.hour12 === false ? "HH" : "hh";
    default:
      throw new RangeError(`invalid value ${options.hour} for option hour`);
  }
}

function getMinuteFormat(options: IDateTimeFormatOptions): string | undefined {
  switch (options.minute) {
    case undefined:
      return undefined;
    case "2-digit":
    case "numeric":
      return "mm";
    default:
      throw new RangeError(`invalid value ${options.minute} for option minute`);
  }
}

function getMonthFormat(options: IDateTimeFormatOptions): string | undefined {
  switch (options.month) {
    case undefined:
      return undefined;
    case "2-digit":
    case "numeric":
      return "MM";
    case "long":
      return "MMMM";
    case "narrow":
      return "MMMMM";
    case "short":
      return "MMM";
    default:
      throw new RangeError(`invalid value ${options.month} for option month`);
  }
}

function getSecondFormat(options: IDateTimeFormatOptions): string | undefined {
  switch (options.second) {
    case undefined:
      return undefined;
    case "2-digit":
    case "numeric":
      return "ss";
    default:
      throw new RangeError(`invalid value ${options.second} for option second`);
  }
}

function getMillisecondFormat(options: IDateTimeFormatOptions): string | undefined {
  switch (options.millisecond) {
    case undefined:
      return undefined;
    case "numeric":
    case "3-digit":
      return "SSS";
    default:
      throw new RangeError(`invalid value ${options.second} for option second`);
  }
}

function getYearFormat(options: IDateTimeFormatOptions): string | undefined {
  switch (options.year) {
    case undefined:
      return undefined;
    case "2-digit":
      return "yy";
    case "numeric":
      return "yyyy";
    default:
      throw new RangeError(`invalid value ${options.year} for option year`);
  }
}

function isEmptyObject(object: any): boolean {
  return Object.keys(object).length === 0;
}

export default function formatDate(
  value: Date = new Date(),
  options: IDateTimeFormatOptions = {},
): string {
  const date = DateTime.fromJSDate(value);

  if (isEmptyObject(options)) {
    return date.toFormat("yyyy-MM-dd");
  } else {
    const day = getDayFormat(options);
    const month = getMonthFormat(options);
    const year = getYearFormat(options);

    const hour = getHourFormat(options);
    const minute = getMinuteFormat(options);
    const second = getSecondFormat(options);
    const millisecond = getMillisecondFormat(options);

    const dateFormat = [year, month, day].filter(Boolean).join("-");
    const hourMinSecFormat = [hour, minute, second].filter(Boolean).join(":");
    const timeFormat = [hourMinSecFormat, millisecond].filter(Boolean).join(".");
    const amPm = timeFormat && options.hour12 !== false ? "a" : undefined;
    const formatString = [dateFormat, timeFormat, amPm].filter(Boolean).join(" ");

    return date.toFormat(formatString);
  }
}
